import React, { useCallback, useContext, useEffect } from 'react';
import type { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import isEqual from 'lodash/isEqual';

import { usePagination } from '@confluence/space-permissions/entry-points/Pagination';
import type { TableDataType } from '@confluence/space-permissions/entry-points/PermissionsTable';
import {
	encodeChangeKey,
	PermissionsTable,
	SpacePermissionsTabKey,
} from '@confluence/space-permissions/entry-points/PermissionsTable';
import { useChangeStager, HeaderWithChangeStagerControls } from '@confluence/change-stager';
import {
	ExperienceSuccess,
	ExperienceTrackerContext,
	VIEW_DEFAULT_SPACE_PERMISSIONS,
} from '@confluence/experience-tracker';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';

import type { spacePermissionsDefaults as SpacePermissionDefaultsType } from '../../graphql/__types__/SpacePermissionsDefaults';
import { SpacePermissionType } from '../../graphql/__types__/SpacePermissionsDefaults';
import { PermissionDisplayType } from '../../graphql/__types__/UpdateDefaultSpacePermissions';
import { SpacePermissionsDefaults } from '../../graphql/SpacePermissionsDefaults.graphql';

import type { PrincipalOption } from './GroupPicker';
import { GroupPicker } from './GroupPicker';

type DefaultPermissionsTableProps = {
	isMutationLoading: boolean;
};

const getTableRowObject = (
	name: string,
	id: string,
	permissions?: (SpacePermissionType | null)[],
): TableDataType => ({
	name,
	id,
	permissions: permissions || [],
	permissionDisplayType: PermissionDisplayType.GROUP,
});

const getTableData = (data: SpacePermissionDefaultsType | undefined): (TableDataType | null)[] =>
	data?.defaultSpacePermissions?.groupsWithDefaultSpacePermissions?.nodes?.map((node) =>
		getTableRowObject(
			node?.subjectKey?.displayName || '',
			node?.subjectKey?.id || '',
			node?.permissions?.filter(Boolean) || ([] as SpacePermissionType[]),
		),
	) || [];

export const DefaultPermissionsTableComponent: FC<DefaultPermissionsTableProps> = ({
	isMutationLoading,
}) => {
	const { isEditing, changes, setChanges } = useChangeStager();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { currentPageCursor, setHasNextPage, setHasPreviousPage, setNextPageCursor } =
		usePagination();

	const {
		data,
		loading: isQueryLoading,
		error: queryError,
	} = useQuery<SpacePermissionDefaultsType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpacePermissionsDefaults,
		{
			onCompleted: () => {
				experienceTracker.succeed({ name: VIEW_DEFAULT_SPACE_PERMISSIONS });
			},
			onError: (error) => {
				experienceTracker.stopOnError({
					name: VIEW_DEFAULT_SPACE_PERMISSIONS,
					error,
				});
			},
			variables: {
				after: currentPageCursor,
			},
		},
	);
	const queryGroups = getTableData(data);
	const [tableGroups, setTableGroups] = React.useState<(TableDataType | null)[]>([]);

	useEffect(() => {
		if (!isEditing && !isEqual(tableGroups, queryGroups)) setTableGroups(queryGroups);
	}, [isEditing, tableGroups, queryGroups]);

	const onAdd = useCallback(
		(groupsAdded: PrincipalOption[]) => {
			const groupsIntroduced = groupsAdded
				?.filter((group) => !tableGroups.some((tableGroup) => tableGroup?.id === group.extra?.id))
				.map((group) => getTableRowObject(group.name || '', group.extra?.id || ''));
			if (groupsIntroduced.length === 0) return;

			setTableGroups((curr: (TableDataType | null)[]) => [...curr, ...groupsIntroduced]);

			setChanges({
				...changes,
				...groupsIntroduced.reduce(
					(acc, group) => ({
						...acc,
						[encodeChangeKey(SpacePermissionType.VIEW_SPACE, group.id)]: true,
					}),
					{},
				),
			});
		},

		[tableGroups, setChanges, changes],
	);

	useEffect(() => {
		const pageInfo = data?.defaultSpacePermissions?.groupsWithDefaultSpacePermissions?.pageInfo;
		if (pageInfo) {
			setHasNextPage(Boolean(pageInfo.hasNextPage));
			setNextPageCursor(pageInfo.endCursor);
			setHasPreviousPage(Boolean(pageInfo.hasPreviousPage));
		}
	}, [
		data?.defaultSpacePermissions?.groupsWithDefaultSpacePermissions?.pageInfo,
		setHasNextPage,
		setNextPageCursor,
		setHasPreviousPage,
	]);

	return (
		<>
			<HeaderWithChangeStagerControls />
			<PermissionsTable
				tab={SpacePermissionsTabKey.Groups}
				data={
					//An empty list shouldn't ever be passed since we enforce at least one admin group
					tableGroups as TableDataType[]
				}
				isLoading={isQueryLoading || isMutationLoading}
			/>
			{!Boolean(queryError) && !isQueryLoading && (
				<ExperienceSuccess name={VIEW_DEFAULT_SPACE_PERMISSIONS} />
			)}
			{isEditing && <GroupPicker onAdd={onAdd} />}
		</>
	);
};

export const DefaultPermissionsTable = withErrorBoundary({
	attribution: Attribution.PERMISSIONS_EXPERIENCE,
})(DefaultPermissionsTableComponent);
