import { type NestedSuggestion, type Suggestion } from '../../components/Suggestions/types';

export function reduceSuggestionsIntoNestedSuggestions(
	output: NestedSuggestion[],
	suggestion: Suggestion,
) {
	const { nestingConfig } = suggestion;

	if (nestingConfig) {
		const existingIndex = output.findIndex(
			(s) => s.nestingConfig?.parentTitle === nestingConfig.parentTitle,
		);

		if (existingIndex === -1) {
			const parentNestingConfig = { ...nestingConfig };
			delete parentNestingConfig.shortTitle;

			output.push({
				...suggestion,
				testId: nestingConfig.parentTestId,
				label: nestingConfig.parentTitle || '',
				nestingConfig: parentNestingConfig,
				childSuggestions: [suggestion],
				value: suggestion.nestingConfig?.parentTitle || '',
			});
		} else {
			output[existingIndex].childSuggestions?.push(suggestion);
		}
	} else {
		output.push(suggestion);
	}

	return output;
}
