/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxfrag */
import type { ReactNode } from 'react';
import React, { useCallback, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { getEmptyADF, isEmpty } from '@atlaskit/adf-utils/empty-adf';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import { token } from '@atlaskit/tokens';

import { useElementBreakpoints } from '../../hooks/useElementBreakpoints';
import { commonTextStyles } from '../../styles/text';
import { AtlassianIntelligenceLogo } from '../AtlassianIntelligenceLogo/AtlassianIntelligenceLogo';
import { BackIconButton } from '../BackIconButton/BackIconButton';
import { PromptEditorWrapper } from '../PromptEditorWrapper/PromptEditorWrapper';
import type { PromptEditor } from '../PromptEditorWrapper/PromptEditorWrapper';
import { SubmitButton } from '../SubmitButton/SubmitButton';

import messages from './messages';
import {
	buttonWrapperStyles,
	formContainerStyles,
	formInnerPaddingTopStyles,
	formInnerStyles,
	formPaddingTopStyles,
	formStyles,
	logoContainerStyles,
} from './styles';
import type {
	BlurInputRefType,
	ClearInputMutableRefObject,
	FocusInputMutableRefObject,
} from './useSetInputRef';

const formGapStyles = css({
	gap: token('space.050', '4px'),
});

// Make form height equals the height of its parent to prevent the form from growing out of the parent
const formHeightStyles = css({
	height: '100%',
});

// Prevent form inner from growing out of form
const formInnerHeightStyles = css({
	minHeight: '0',
});

// input's line-height is 20px, logo/button's height is 24px, need to adjust the height
const nextToInputStyles = css({
	marginTop: token('space.negative.025', '-2px'),
	marginBottom: token('space.negative.025', '-2px'),
});

export type PromptFormProps = {
	type?: 'user-input' | 'interrogate';
	onFormSubmit: (inputValue: string) => void;
	showButtons?: boolean;
	tag?: ReactNode & { props?: Record<string, unknown> };
	// When using PromptEditor as input, both value AND adfValue will be set.
	value?: string;
	adfValue?: JSONDocNode;
	autoFocus?: boolean;
	onInputFocus?: () => void;
	onInputBlur?: (e: React.FocusEvent) => void;
	onInputChange?: (inputValue: string) => void;
	onADFChange?: (inputValue: JSONDocNode) => void;
	placeholder?: string;
	focusInputRef?: FocusInputMutableRefObject;
	clearInputRef?: ClearInputMutableRefObject;
	showLogo?: boolean;
	onInputKeyDown?: (event: React.KeyboardEvent) => void;
	onInputKeyUp?: (event: React.KeyboardEvent) => void;
	PromptEditor: PromptEditor;
	enableLinks?: boolean;
	showBack?: boolean;
	onBack?: () => unknown;
	fireParentActionButton?: () => void;
	focusParentActionButton?: (value: boolean) => void;
	refinementTagRef?: React.MutableRefObject<HTMLDivElement | null>;
};

export const PromptForm = ({
	type = 'user-input',
	onFormSubmit,
	value = '',
	showButtons = true,
	tag,
	autoFocus = true,
	onInputChange,
	onADFChange,
	onInputFocus,
	onInputBlur,
	placeholder,
	focusInputRef,
	clearInputRef,
	showLogo = true,
	onInputKeyDown,
	adfValue,
	enableLinks,
	PromptEditor,
	showBack,
	onBack,
	onInputKeyUp,
	fireParentActionButton,
	focusParentActionButton,
	refinementTagRef,
}: PromptFormProps) => {
	const { formatMessage } = useIntl();

	const [setBreakpointElement, { breakpoint }] = useElementBreakpoints();
	const blurInputRef = useRef<BlurInputRefType>(undefined);
	const [isComposing, setIsComposing] = useState(false);
	const [isLozengeSelected, setIsLozengeSelected] = useState(false);

	const lastAdfValue = useRef<JSONDocNode>(getEmptyADF());

	const isPromptInputEmpty: boolean = Boolean(
		isEmpty(lastAdfValue?.current) && adfValue && isEmpty(adfValue),
	);

	const handleOnCompositionStart = useCallback(() => {
		setIsComposing(true);
	}, []);

	const handleOnCompositionEnd = useCallback(() => {
		setIsComposing(false);
	}, []);

	const handleKeyUp = React.useCallback(
		(event: React.KeyboardEvent) => {
			if (isPromptInputEmpty && focusParentActionButton) {
				focusParentActionButton(true);
			}

			onInputKeyUp && onInputKeyUp(event);
		},
		[focusParentActionButton, onInputKeyUp, isPromptInputEmpty],
	);

	const handleInputFocus = React.useCallback(() => {
		setIsLozengeSelected(false);

		if (onInputFocus) {
			onInputFocus();
		}
	}, [onInputFocus]);

	const handleKeyDown = React.useCallback(
		(event: React.KeyboardEvent) => {
			/**
			 * If the key is printable or escape, we should remove the focus ring
			 * that is applied to the parent insert or replace button
			 */
			if ((event.key.length === 1 || event.key === 'Escape') && focusParentActionButton) {
				focusParentActionButton(false);
			}

			if (event.key === 'Backspace' && onBack) {
				// This shortcuts the default functionality by placing focus on the refinement tag
				// instead of removing the tag
				if (!isLozengeSelected && isPromptInputEmpty && refinementTagRef?.current) {
					refinementTagRef.current.getElementsByTagName('button')[0].focus();
					setIsLozengeSelected(true);
					return;
				}

				event.stopPropagation();

				if (isPromptInputEmpty) {
					onBack();
				}
			}

			if (
				event.key === 'Enter' &&
				[event.altKey, event.metaKey, event.ctrlKey].every((isPressed) => !isPressed)
			) {
				event.stopPropagation();
				// eslint-disable-next-line @atlaskit/editor/no-as-casting
				const target = event.target as HTMLElement;

				if (isComposing) {
					return;
				}

				// If the prompt input is empty, we should fire the parent action button
				// on Enter, which will either be replace or insert
				if (isPromptInputEmpty && fireParentActionButton) {
					fireParentActionButton();
					return;
				}

				// Handles a case where the user presses enter if refinement tag's close button or the
				// cancel button is focused
				if (
					[
						'close-button-refinement-tag',
						'cancel-icon-button',
						'cancel-esc-button',
						'back-icon-button',
					].includes(target.getAttribute('data-testid') ?? '')
				) {
					return;
				}
				// Handles every other case that is not a "shift + enter"
				if (!event.shiftKey) {
					event.preventDefault();
					onFormSubmit(value);
				}
			}

			// Return focus back to the input if the user is typing
			// and the refinement tag removing button is focused.
			if (
				!['Enter', 'Backspace'].includes(event.key) &&
				refinementTagRef?.current &&
				refinementTagRef.current.contains(document.activeElement)
			) {
				document.getElementById('ak-editor-textarea')?.focus();
			}
		},
		[
			focusParentActionButton,
			onBack,
			refinementTagRef,
			isLozengeSelected,
			isPromptInputEmpty,
			isComposing,
			fireParentActionButton,
			onFormSubmit,
			value,
		],
	);

	const onADFChange_ = React.useCallback(
		(input: JSONDocNode) => {
			lastAdfValue.current = adfValue ?? getEmptyADF();

			if (onADFChange) {
				onADFChange(input);
			}
		},
		[onADFChange, adfValue],
	);

	const onInputChange_ = React.useCallback(
		(newValue: string) => {
			if (onInputChange) {
				onInputChange(newValue);
			}
		},
		[onInputChange],
	);

	const handleSubmitClick = React.useCallback(() => {
		onFormSubmit(value);
	}, [onFormSubmit, value]);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			// ED-19496: We avoid propagating enter keypresses
			// since products like Bitbucket might have parent forms
			// that listen for keydown events to trigger form submission
			onKeyDown={handleKeyDown}
			onKeyUp={handleKeyUp}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			css={formContainerStyles}
			ref={setBreakpointElement}
		>
			{showLogo && (
				<div
					css={[
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
						logoContainerStyles(breakpoint),
						nextToInputStyles,
					]}
				>
					<AtlassianIntelligenceIcon
						color={token('color.icon')}
						label={formatMessage(messages.logo)}
						spacing="spacious"
						LEGACY_fallbackIcon={AtlassianIntelligenceLogo}
						LEGACY_size="medium"
					/>
				</div>
			)}

			{showBack && <BackIconButton inPromptHeader stopPropagation onClick={onBack} />}

			<div
				css={[
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					formStyles,
					formGapStyles,
					showLogo &&
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
						formPaddingTopStyles,
					formHeightStyles,
				]}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="ai-modal-prompt-form"
				data-testid="ai-modal-prompt-form"
			>
				{tag}
				{/**
				 * @see packages/editor/editor-core/src/ui/Appearance/FullPage/StyledComponents.ts
				 * The line-height from editorContentArea seems to add some extra pixels (less than 1px)
				 * to the tooltip div around the buttons. Changing the style to match the common text styles
				 * fixes this and prevents height from changing when the submit and cancel buttons appear.
				 */}
				<div
					css={[
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
						commonTextStyles,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
						formInnerStyles,
						!!tag &&
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							formInnerPaddingTopStyles,
						formInnerHeightStyles,
					]}
				>
					<PromptEditorWrapper
						PromptEditor={PromptEditor}
						type={type}
						focusInputRef={focusInputRef}
						blurInputRef={blurInputRef}
						clearInputRef={clearInputRef}
						autoFocus={autoFocus}
						defaultValue={adfValue}
						onInputChange={onInputChange_}
						onADFChange={onADFChange_}
						placeholder={placeholder}
						onFocus={handleInputFocus}
						onBlur={onInputBlur}
						onKeyDown={onInputKeyDown}
						onCompositionStart={handleOnCompositionStart}
						onCompositionEnd={handleOnCompositionEnd}
						enableLinks={enableLinks}
					/>
					{showButtons && (
						<div
							css={[
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
								buttonWrapperStyles,
							]}
						>
							<SubmitButton disabled={!value} onClick={handleSubmitClick} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
