import React from 'react';

import { useMachine } from '@xstate/react';

import { type JSONDocNode } from '@atlaskit/editor-json-transformer';

import type { AdvancedPromptValue, EditorPluginAIConfigItem } from '../config-items/config-items';

import type {
	CommandPaletteContext,
	CommandPaletteEvent,
	CommandPaletteState,
} from './command-palette-state-machine';
import { commandPaletteStateMachine } from './command-palette-state-machine';

type UseCommandPaletteStateMachineOptions = {
	/**
	 * initial preset/ command to prefill the command palette with
	 */
	startingConfig: EditorPluginAIConfigItem;
	/**
	 * initial preset prompt label
	 */
	startingPresetPromptLabel?: string;
	/**
	 * default AI generation config
	 */
	baseGenerate?: EditorPluginAIConfigItem;
	/**
	 * userInput to prefill the command palette with
	 */
	startingUserInput?: string;

	startingUserADFInput?: JSONDocNode;

	startingAdvancedPromptValue?: AdvancedPromptValue;
};

/**
 * Initializes the command palette state machine, and returns the
 * state, context and send function.
 */
export function useCommandPaletteStateMachine({
	startingConfig,
	startingPresetPromptLabel,
	baseGenerate,
	startingUserInput,
	startingUserADFInput,
	startingAdvancedPromptValue,
}: UseCommandPaletteStateMachineOptions): {
	/**
	 * current state of the command palette state machine
	 */
	state: CommandPaletteState;
	/**
	 * current context or data stored in the command palette state machine
	 */
	context: CommandPaletteContext;
	/**
	 * function to send events to the command palette state machine
	 */
	send: (event: CommandPaletteEvent) => void;
} {
	const machineRef = React.useRef<ReturnType<typeof commandPaletteStateMachine> | null>(null);

	if (machineRef.current === null) {
		machineRef.current = commandPaletteStateMachine({
			context: {
				textInput: startingUserInput,
				adfInput: startingUserADFInput,
				advancedPromptValue: startingAdvancedPromptValue,
				configItem: startingConfig,
				presetPromptLabel: startingPresetPromptLabel,
				baseGenerate,
			},
		});
	}

	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const [state, send] = useMachine(machineRef.current!);

	return {
		// In a later version of xstate -- the type gen means the machine
		// has good types, so these assertions are not required
		state: state.value as CommandPaletteState,
		context: state.context as CommandPaletteContext,
		// Out of the box -- the type of the event received by the xstate send
		// will allow any "payload".
		// ie. `send("correct event type", {incorrect: "event payload"})`
		//
		// To avoid related issues -- we've limited the type to only allow
		// sending the full event.
		// ie. `send({type: "correct event type", correct: "event payload"})`
		send: send as (event: CommandPaletteEvent) => void,
	};
}
