import { useCallback, useMemo } from 'react';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';
import { fg } from '@confluence/feature-gating';

export type DestinationType = 'space-roles-tab' | 'default-roles-tab';

export const useSpaceRolesLozenge = (destination: DestinationType) => {
	const { PERSISTED_RBAC_TRACKING } = PERSISTED_KEYS_ON_SERVER;

	const getLocalStorageItem = useCallback(() => {
		return JSON.parse(localStorage.getItem(PERSISTED_RBAC_TRACKING)) ?? {};
	}, [PERSISTED_RBAC_TRACKING]);

	const shouldShowLozenge = useMemo(() => {
		try {
			return !(destination in getLocalStorageItem()) && fg('cc_perms_exp_rbac_fe_milestone_2');
		} catch (e) {
			return false;
		}
	}, [destination, getLocalStorageItem]);

	const hideLozenge = useCallback(() => {
		try {
			localStorage.setItem(
				PERSISTED_RBAC_TRACKING,
				JSON.stringify({
					...getLocalStorageItem(),
					[destination]: 1,
				}),
			);
		} catch (e) {
			return;
		}
	}, [destination, getLocalStorageItem, PERSISTED_RBAC_TRACKING]);

	return {
		shouldShowLozenge,
		hideLozenge,
	};
};
