import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import Checkbox from '@atlaskit/checkbox';
import CheckCircleIcon from '@atlaskit/icon/core/migration/success--check-circle';
import CrossCircleIcon from '@atlaskit/icon/core/migration/cross-circle';
import { G300, N0, N40, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { useChangeStager } from '@confluence/change-stager';

type PermissionIconProps = {
	value: boolean;
	changeKey?: string;
	isDisabled?: boolean;
	centered?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CircleIconDiv = styled.div<{ centered?: boolean }>({
	height: token('space.300', '24px'),
	// eslint incorrectly warns about token use when using a style function
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingTop: ({ centered }) => (centered ? token('space.0', '0') : token('space.150', '12px')),
});

const i18n = defineMessages({
	permissionGranted: {
		id: 'site-permissions.permission-table.permission-icon.granted',
		defaultMessage: 'Permission granted',
		description: 'Aria label for the Permission Granted icon',
	},
	permissionDenied: {
		id: 'site-permissions.permission-table.permission-icon.denied',
		defaultMessage: 'Permission denied',
		description: 'Aria label for the Permission Denied icon',
	},
});

export const PermissionIcon = ({
	value: savedValue,
	changeKey,
	isDisabled,
	centered,
}: PermissionIconProps) => {
	const { isEditing, changes, setChanges } = useChangeStager();
	const { formatMessage } = useIntl();

	if (isEditing && changeKey) {
		// Check if a staged change is overriding the saved value, noting that an
		// absence of any staged changes is recorded as (changes === null).

		let currentValue = savedValue;
		if (changes && changes[changeKey] !== undefined) {
			currentValue = changes[changeKey];
		}

		return (
			<CircleIconDiv centered={centered}>
				<Checkbox
					testId={`editor-${currentValue}-${changeKey}`}
					isChecked={currentValue}
					isDisabled={isDisabled}
					onChange={() => {
						// If a value is being changed back to its saved value, then we
						// remove it from the changes object. And then, if there are no
						// remaining changes in that object, we'll write null as its value.

						const newChanges = { ...(changes || {}) };
						const newValue = !currentValue;

						if (newValue === savedValue) {
							delete newChanges[changeKey];
						} else {
							newChanges[changeKey] = newValue;
						}

						setChanges(Object.keys(newChanges).length ? newChanges : null);
					}}
				/>
			</CircleIconDiv>
		);
	}

	return (
		<CircleIconDiv centered={centered}>
			{savedValue ? (
				<CheckCircleIcon
					spacing="spacious"
					color={token('color.icon.success', G300)}
					LEGACY_secondaryColor={token('color.icon.inverse', N0)}
					label={formatMessage(i18n.permissionGranted)}
					testId={`viewer-true-${changeKey}`}
				/>
			) : (
				<CrossCircleIcon
					spacing="spacious"
					color={token('color.icon.accent.gray', N40)}
					LEGACY_secondaryColor={token('color.text', N800)}
					label={formatMessage(i18n.permissionDenied)}
					testId={`viewer-false-${changeKey}`}
				/>
			)}
		</CircleIconDiv>
	);
};
