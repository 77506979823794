import { type EditorView } from '@atlaskit/editor-prosemirror/view';

import { type InvokedFrom } from '../analytics/analytics-flow/analyticsFlowTypes';
import type { EditorPluginAIConfigItem } from '../config-items/config-items';
import { createOpenAIModalCommand } from '../pm-plugins/decoration/actions';
import type { AIGlobalOptIn } from '../types';

export const openAIModal = ({
	invokedFrom,
	aiOptIn,
	configItem,
	editorView,
}: {
	invokedFrom: InvokedFrom;
	aiOptIn: AIGlobalOptIn;
	configItem: EditorPluginAIConfigItem;
	editorView?: EditorView;
}) => {
	if (!editorView) {
		return;
	}

	const openAIModalCommand = createOpenAIModalCommand({
		state: editorView.state,
		configItem,
		lastTriggeredFrom: invokedFrom,
		aiGlobalOptIn: aiOptIn,
	});

	openAIModalCommand(editorView.state, editorView.dispatch);
};
