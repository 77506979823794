import { addFeatureFlagAccessed as addFeatureFlagAccessedForUFO } from '@atlaskit/react-ufo/feature-flags-accessed';

import { initializeStorageManager } from '@confluence/storage-manager';
import { applyPersistedOverrides, updateSessionData } from '@confluence/session-data';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';
import { fg } from '@confluence/feature-gating';
import {
	ALLOWLIST_FFS_CUSTOM_EVENTS,
	FF_ALLOWLIST_FOR_PERF_METRICS,
} from '@confluence/browser-metrics';

export const postProcessSessionData = async (sessionData) => {
	const { userId, featureFlags, isLicensed } = sessionData;

	// Local and session storage
	initializeStorageManager({ userId, isLicensed }).then(() => {
		// Load and process FF overrides persisted by storage-manager
		updateSessionData(sessionData, applyPersistedOverrides(featureFlags));
	});

	// To add allowed feature flags to UFO payload
	[...FF_ALLOWLIST_FOR_PERF_METRICS, ...ALLOWLIST_FFS_CUSTOM_EVENTS].forEach((flag) => {
		if (featureFlags.hasOwnProperty(flag) && typeof featureFlags[flag] !== 'object') {
			addFeatureFlagAccessedForUFO(flag, featureFlags[flag]);
			return;
		}
		const ssrFeatureFlag = getSSRFeatureFlag(flag);
		if (
			ssrFeatureFlag !== null &&
			ssrFeatureFlag !== undefined &&
			typeof ssrFeatureFlag !== 'object'
		) {
			addFeatureFlagAccessedForUFO(flag, ssrFeatureFlag);
			return;
		}

		// eslint-disable-next-line confluence-feature-gating/static-feature-gates, confluence-feature-gating/inline-usage
		const statsigFlag = fg(flag);

		if (typeof statsigFlag !== 'object') {
			addFeatureFlagAccessedForUFO(flag, statsigFlag);
		}
	});

	return sessionData;
};
