import React from 'react';

import { FormattedMessage, type MessageDescriptor, useIntl } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';

import { type StepName } from '../../../analytics/analytics-flow/analyticsFlowTypes';
import globalMessages from '../../../messages';

import { FAILURE_REASON } from './errors';

export type ErrorInfo = {
	errorKey?: string | undefined;
	statusCode?: number | undefined;
	errorContent?: string | undefined;
	apiName?: string | undefined;
	failureReason?: FAILURE_REASON | undefined;
};

const knownErrorMessages: { [key in FAILURE_REASON]: MessageDescriptor } = {
	[FAILURE_REASON.UNKNOWN]: globalMessages.documentInsertError,
	[FAILURE_REASON.TOKEN_LIMIT]: globalMessages.tokenLimitExceeded,
	[FAILURE_REASON.INSERT_FAILURE]: globalMessages.documentInsertError,
	[FAILURE_REASON.INPUT_TOO_SHORT]: globalMessages.inputTooShortError,
	[FAILURE_REASON.HIPAA_CONTENT]: globalMessages.hipaaContentError,
	[FAILURE_REASON.RATE_LIMITED]: globalMessages.rateLimitEnforced,
	[FAILURE_REASON.AUP_VIOLATION]: globalMessages.aupViolationMessage,
	[FAILURE_REASON.ELEVATE_DISABLED_GENERATE]: globalMessages.elevateDisabledGenerateError,
	[FAILURE_REASON.INTERNAL_SERVER_ERROR]: globalMessages.internalServerError,
	[FAILURE_REASON.API_FAIL]: globalMessages.apiError,
};

export const documentationUrls: { [key in FAILURE_REASON]?: string } = {
	[FAILURE_REASON.INTERNAL_SERVER_ERROR]: 'https://status.atlassian.com/',
	[FAILURE_REASON.AUP_VIOLATION]: 'https://www.atlassian.com/legal/acceptable-use-policy',
	[FAILURE_REASON.RATE_LIMITED]:
		'https://support.atlassian.com/organization-administration/docs/excessive-use-in-atlassian-intelligence/',
};

// Use of links in messages is based on guidance at https://developer.atlassian.com/platform/localization/i18n-formatting/avoiding-concatenation/#links
const LinkedMessage = ({
	error,
	documentationUrl,
}: {
	error: FAILURE_REASON;
	documentationUrl: string | undefined;
}) => (
	<FormattedMessage
		// Ignored via go/ees005
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...knownErrorMessages[error]}
		values={{
			link: (message: React.ReactNode[]) => (
				<a href={documentationUrl} target="_blank" rel="noopener noreferrer">
					{message}
				</a>
			),
		}}
	/>
);

// 500 status codes should not be retryable
const is5xxErrorRange = (statusCode: number) => statusCode >= 500 && statusCode < 600;

export const retryEnabled = ({ failureReason, statusCode = 500 }: ErrorInfo) =>
	failureReason === FAILURE_REASON.API_FAIL && !is5xxErrorRange(statusCode) && statusCode !== 9001;

export const getStepName = ({ apiName, failureReason }: ErrorInfo): StepName => {
	return apiName === 'assistance-service' ||
		failureReason === FAILURE_REASON.AUP_VIOLATION ||
		failureReason === FAILURE_REASON.API_FAIL
		? 'apiError'
		: 'failed';
};

export const ErrorMessage = ({ errorKey, failureReason, statusCode }: ErrorInfo): JSX.Element => {
	const { formatMessage } = useIntl();

	const isConvoAIServerError = [
		'FEATURE_DISABLED',
		'FEATURE_DISABLED_ON_SITE',
		'NO_AGENT',
	].includes(errorKey || '');

	// 9001 is a special case for elevate where we disable generate until Q3
	if (statusCode === 9001) {
		return <Text>{formatMessage(knownErrorMessages['elevate disabled generate'])}</Text>;
	}

	if (
		(statusCode && is5xxErrorRange(statusCode)) ||
		isConvoAIServerError ||
		failureReason === FAILURE_REASON.INTERNAL_SERVER_ERROR
	) {
		return (
			<LinkedMessage
				error={FAILURE_REASON.INTERNAL_SERVER_ERROR}
				documentationUrl={documentationUrls[FAILURE_REASON.INTERNAL_SERVER_ERROR]}
			/>
		);
	}

	if (!failureReason) {
		return <Text>{formatMessage(knownErrorMessages['unknown fail'])}</Text>;
	}

	if (failureReason === FAILURE_REASON.AUP_VIOLATION) {
		return (
			<LinkedMessage
				error={FAILURE_REASON.AUP_VIOLATION}
				documentationUrl={documentationUrls[FAILURE_REASON.AUP_VIOLATION]}
			/>
		);
	}

	if (failureReason === FAILURE_REASON.RATE_LIMITED) {
		return (
			<LinkedMessage
				error={FAILURE_REASON.RATE_LIMITED}
				documentationUrl={documentationUrls[FAILURE_REASON.RATE_LIMITED]}
			/>
		);
	}

	return <Text>{formatMessage(knownErrorMessages[failureReason])}</Text>;
};
