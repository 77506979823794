import { useMemo } from 'react';

import type { Suggestion } from '@atlassian/generative-ai-modal/screens/UserInputCommandPalette';

import { messages } from './messages';
import { useAIExperienceCommonDataContext } from './useAIExperienceCommonData';

/**
 * The use page knowledge toggle should only be visible when:
 * - there are agent conversation starters
 * - there is user input or ADF input but no general AI suggestions
 */
export const usePageKnowledgeToggle = ({
	hasInput,
	suggestions,
	agentVisible,
	nestedMenuSelected,
}: {
	suggestions: Suggestion[];
	hasInput: boolean;
	agentVisible: boolean;
	nestedMenuSelected: boolean;
}) => {
	const { sendToAIExperienceMachine, includeKnowledgeFromCurrentPage } =
		useAIExperienceCommonDataContext();

	const pageKnowledgeIsVisible = useMemo(() => {
		// In non-nesting scenarios, the first suggestion group is always the AI suggestions group
		const hasGeneralAiSuggestions =
			suggestions[0]?.groupHeading === messages.atlassianIntelligenceGroupHeading.defaultMessage;

		return !!(agentVisible || (hasInput && !hasGeneralAiSuggestions && !nestedMenuSelected));
	}, [agentVisible, nestedMenuSelected, hasInput, suggestions]);

	const pageKnowledgeProps = useMemo(() => {
		return {
			isEnabled: !!includeKnowledgeFromCurrentPage,
			isVisible: pageKnowledgeIsVisible,
			onToggle: () => sendToAIExperienceMachine({ type: 'toggle use page knowledge' }),
		};
	}, [includeKnowledgeFromCurrentPage, pageKnowledgeIsVisible, sendToAIExperienceMachine]);

	return pageKnowledgeProps;
};
