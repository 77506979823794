import React, { useCallback, useEffect } from 'react';

import { useIntl } from 'react-intl-next';

import {
	ACTION,
	ACTION_SUBJECT,
	ACTION_SUBJECT_ID,
	EVENT_TYPE,
} from '@atlaskit/editor-common/analytics';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { ExtractInjectionAPI } from '@atlaskit/editor-common/types';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { fg } from '@atlaskit/platform-feature-flags';
import { createUnifiedAnalyticsPayload } from '@atlassian/editor-ai-common/analytics/create-unified-analytics-payload';
import { copyPMFragmentToClipboard } from '@atlassian/editor-ai-common/utils/clipboard';
import { PreviewScreen, type PreviewButton } from '@atlassian/generative-ai-modal/screens/Preview';

import type { AIPlugin } from '../../editor-plugin-ai';
import {
	clearSelectRecommendation,
	insertRecommendation,
	removeRecommendation,
} from '../../pm-plugins/ai-proactive/commands';
import type { AIProactivePluginState } from '../../pm-plugins/ai-proactive/states';
import { getBlockFromRecommendationId } from '../../pm-plugins/ai-proactive/utils';
import type {
	EditorPluginAIProvider,
	FeedbackMetadata,
	ProactiveFeedbackMetadata,
} from '../../types';
import { convertPMFragmentToPreview } from '../../ui/convert-markdown-to-preview';
import { launchProactiveFeedbackDialog } from '../../utils/proactive/feedback';
import { getTransformActionDescriptors } from '../../utils/proactive/get-transform-action-descriptors';
import { messages } from '../messages';

type ProactivePreviewScreenWithLogicProps = {
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	aiProactivePluginState: AIProactivePluginState;
	editorView: EditorView;
	providerFactory: ProviderFactory;
	editorPluginAIProvider: EditorPluginAIProvider;
};

export function ProactivePreviewScreenWithLogic({
	api,
	aiProactivePluginState,
	editorView,
	providerFactory,
	editorPluginAIProvider,
}: ProactivePreviewScreenWithLogicProps) {
	const analyticsApi = api?.analytics?.actions;
	const { formatMessage, locale } = useIntl();
	const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false);

	const { selectedRecommendationId, analyticsAIInteractionId } = aiProactivePluginState;
	const { handleFeedbackSubmission, product } = editorPluginAIProvider;

	const { block, recommendation } = selectedRecommendationId
		? getBlockFromRecommendationId(aiProactivePluginState, selectedRecommendationId)
		: { block: undefined, recommendation: undefined };

	useEffect(() => {
		if (recommendation?.transformAction) {
			// don't use the local analyticsApi, it changes many times, and will trigger the useEffect/event many times
			api?.analytics?.actions?.fireAnalyticsEvent(
				createUnifiedAnalyticsPayload(
					ACTION.VIEWED,
					analyticsAIInteractionId,
					recommendation.transformAction,
					true,
					{},
				),
			);
		}
	}, [api, analyticsAIInteractionId, recommendation?.transformAction]);

	const onClose = useCallback(() => {
		clearSelectRecommendation()(editorView.state, editorView.dispatch);
	}, [editorView.dispatch, editorView.state]);

	if (!selectedRecommendationId || !block || !recommendation) {
		return null;
	}

	const pmFragment = recommendation.transformContent.content;
	const { title } = getTransformActionDescriptors(recommendation.transformAction);

	const preview = convertPMFragmentToPreview({
		schema: editorView.state.schema,
		pmFragment,
		showTelepointer: false,
		providerFactory: providerFactory,
		reduceMargins: true,
	});

	const proactiveRecommendationButtons: PreviewButton[] = [
		{
			label: formatMessage(messages.dismissProactiveRecommentation),
			appearance: 'cancel',
			onClick: () => {
				if (!recommendation) {
					return null;
				}
				removeRecommendation({
					analyticsApi,
					recommendationId: recommendation.id,
					triggeredFrom: 'preview',
					feedback: {
						feedbackSubmitted,
						product,
						locale,
						editorView,
						handleFeedbackSubmission,
					},
				})(editorView.state, editorView.dispatch);
			},
		},
		{
			label: formatMessage(messages.insertBelowProactiveRecommentation),
			appearance: 'secondary',
			onClick: () => {
				if (!recommendation) {
					return null;
				}
				insertRecommendation({
					analyticsApi,
					recommendationId: recommendation.id,
					triggeredFrom: 'preview',
					insertionMethod: 'insertBelow',
				})(editorView.state, editorView.dispatch);
			},
		},
		{
			label: formatMessage(messages.replaceProactiveRecommentation),
			appearance: 'primary',
			onClick: () => {
				if (!recommendation) {
					return null;
				}
				insertRecommendation({
					analyticsApi,
					recommendationId: recommendation.id,
					triggeredFrom: 'preview',
					insertionMethod: 'replace',
				})(editorView.state, editorView.dispatch);
			},
		},
	];

	const fireResponseFeedbackEvent = (sentiment: FeedbackMetadata['sentiment']) => {
		analyticsApi?.fireAnalyticsEvent({
			eventType: EVENT_TYPE.UI,
			action: ACTION.CLICK,
			actionSubject: ACTION_SUBJECT.EDITOR_PLUGIN_AI,
			actionSubjectId: ACTION_SUBJECT_ID.RESPONSE_FEEDBACK_BUTTON,
			attributes: {
				sentiment,
				product,
				configItemTitle: 'proactive-recommendation',
			},
		});
	};

	const handleFeedbackButtonClick = async (sentiment: ProactiveFeedbackMetadata['sentiment']) => {
		fireResponseFeedbackEvent(sentiment);
		setFeedbackSubmitted(true);

		if (fg('platform_editor_ai_aggressive_feedback_proactive')) {
			launchProactiveFeedbackDialog({
				recommendationId: recommendation.id,
				aiProactivePluginState,
				editorView,
				locale,
				sentiment,
				product,
				handleFeedbackSubmission,
				triggeredFrom: 'preview',
				fireAnalyticsEvent: analyticsApi?.fireAnalyticsEvent,
			});
		} else {
			if (!handleFeedbackSubmission) {
				return false;
			}
			const jsonTransformer = new JSONTransformer(editorView.state.schema);
			await handleFeedbackSubmission({
				sentiment,
				getAIExperience: (hasUserConsent) => ({
					browserLocale: window.navigator.languages,
					userLocale: locale,
					...(hasUserConsent
						? {
								originalParagraph: block.text,
								suggestedParagraph: jsonTransformer.encode(recommendation.transformContent).content,
								recommendationId: recommendation.id,
							}
						: {}),
				}),
				editorAttributes: { product },
			});
		}
	};

	return (
		<PreviewScreen
			PromptEditor={editorPluginAIProvider.PromptEditor}
			staticPromptTitle={formatMessage(title)}
			hideHorizontalRule
			showBetaLozenge
			preview={preview}
			disableActions={false}
			disableInterrogation
			schema={editorView.state.schema}
			providerFactory={providerFactory}
			onCopy={() => {
				analyticsApi?.fireAnalyticsEvent({
					eventType: EVENT_TYPE.UI,
					action: ACTION.CLICK,
					actionSubject: ACTION_SUBJECT.EDITOR_PLUGIN_AI,
					actionSubjectId: ACTION_SUBJECT_ID.COPY_BUTTON,
					attributes: {
						experienceType: 'proactive-recommendation',
					},
				});

				copyPMFragmentToClipboard({ editorView, pmFragment });
			}}
			showBack={false}
			onGoodResponseButtonClick={() => handleFeedbackButtonClick('good-recommendation')}
			onBadResponseButtonClick={() => handleFeedbackButtonClick('bad-recommendation')}
			onClose={onClose}
			applications={proactiveRecommendationButtons}
			isInputActive={false}
			isClearTitle
		/>
	);
}
