// Source: https://www.figma.com/design/2do7MeD1hEmSftXLGER1Xy/AI-Patterns-2024?node-id=82-12683&t=bPIR0f4r8UiwwCr1-4
export const AI_BREAKPOINTS = {
	medium: 328,
	large: 624,
};

// Source: https://www.figma.com/design/6hkbGemcndZKl5CQLZ9f2d/Rovo-Patterns-2024?node-id=1-5429&t=xaW7daw0idocNVsm-4
export const ROVO_BREAKPOINTS = {
	medium: 300,
	large: 480,
};
