import debounce from 'lodash/debounce';

import {
	ACTION,
	ACTION_SUBJECT,
	ACTION_SUBJECT_ID,
	type EditorAnalyticsAPI,
	EVENT_TYPE,
} from '@atlaskit/editor-common/analytics';
import type { Command, HigherOrderCommand } from '@atlaskit/editor-common/types';
import type { EditorState, Transaction } from '@atlaskit/editor-prosemirror/state';
import { safeInsert } from '@atlaskit/editor-prosemirror/utils';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { type MentionNameDetails } from '@atlaskit/mention';
import { fg } from '@atlaskit/platform-feature-flags';
import { createUnifiedAnalyticsPayload } from '@atlassian/editor-ai-common/analytics/create-unified-analytics-payload';

import type { EditorPluginAIProvider, ProactiveAIConfig } from '../../types';
import { type ParagraphChunk } from '../../utils/diff-match-patch/utils';
import { setProactiveAISettings } from '../../utils/local-storage';
import {
	type LaunchProactiveFeedbackDialogParams,
	launchProactiveFeedbackDialog,
	isNthDismiss,
} from '../../utils/proactive/feedback';

import { fetchAIParagraphs } from './api';
import {
	fireAPIReceivedAnalytics,
	updateFailedChunksWithAnalytics,
} from './commands-with-analytics';
import {
	regenerateAllDecorations,
	regenerateHoverDecorations,
	regenerateSelectionDecorations,
} from './decorations';
import { createCommand, getPluginState } from './plugin-factory';
import type { ProactiveAIBlock, SettingDisplayType } from './states';
import { ACTIONS } from './states';
import { getNewSettings } from './suggestion-setting-map';
import {
	filterRecommendationsByToggle,
	getBlockFromRecommendationId,
	hasRecommendation,
	removeRecommendationFromBlocks,
	syncProactiveRecommendations,
} from './utils';

const withoutAddingToHistory: HigherOrderCommand =
	(command: Command): Command =>
	(state, dispatch, view) =>
		command(
			state,
			(tr) => {
				tr.setMeta('addToHistory', false);
				if (dispatch) {
					dispatch(tr);
				}
			},
			view,
		);

export interface TriggerContext {
	view: EditorView;
	locale: string;
	getMentionNameDetails?: (id: string) => Promise<MentionNameDetails | undefined>;
}

export const insertRecommendation = ({
	analyticsApi,
	recommendationId,
	triggeredFrom,
	insertionMethod,
}: {
	analyticsApi: EditorAnalyticsAPI | undefined;
	recommendationId: string;
	triggeredFrom: 'contextPanel' | 'preview';
	insertionMethod: 'replace' | 'insertBelow';
}) =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { decorationSet, insertionCount, proactiveAIBlocks } = pluginState;

			if (!proactiveAIBlocks?.length || !hasRecommendation(pluginState, recommendationId)) {
				return false;
			}

			const updatedProactiveAIBlocks = removeRecommendationFromBlocks(
				proactiveAIBlocks,
				recommendationId,
			);

			// TODO: We need to determine if have should close the context panel (if it's open) if we have no more recommendations
			// left after this action is performed.

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					// Upon inserting a suggestion we need to ensure that that the insertion widget is removed.
					decorationSet: regenerateAllDecorations({
						decorationSet,
						tr: state.tr,
						pluginState: {
							...pluginState,
							proactiveAIBlocks: updatedProactiveAIBlocks,
						},
					}),
					proactiveAIBlocks: updatedProactiveAIBlocks,
					insertionCount: insertionCount + 1,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			const pluginState = getPluginState(state);

			if (hasRecommendation(pluginState, recommendationId)) {
				const { block, recommendation } = getBlockFromRecommendationId(
					pluginState,
					recommendationId,
				);

				if (block && recommendation) {
					const { from, to } = block;
					const { transformContent, transformAction, transformType } = recommendation;

					if (transformContent) {
						analyticsApi?.attachAnalyticsEvent(
							createUnifiedAnalyticsPayload(
								ACTION.ACTIONED,
								pluginState.analyticsAIInteractionId,
								recommendation.transformAction,
								true,
								{
									aiResultAction: 'recommendationAccepted',
								},
							),
						)(tr);

						analyticsApi?.attachAnalyticsEvent({
							eventType: EVENT_TYPE.TRACK,
							action: ACTION.INSERTED,
							actionSubject: ACTION_SUBJECT.EDITOR_PLUGIN_AI,
							actionSubjectId: ACTION_SUBJECT_ID.PROACTIVE_SUGGESTION,
							attributes: {
								aiInteractionID: pluginState.analyticsAIInteractionId,
								triggeredFrom,
								transformAction,
								transformType,
								insertionMethod,
							},
						})(tr);

						tr.setMeta('suggestionAccepted', true);

						switch (insertionMethod) {
							case 'replace':
								return tr.replaceWith(from, to, transformContent.content);
							case 'insertBelow':
								return safeInsert(transformContent.content, to)(tr);
						}
					}
				}
			}

			return tr;
		},
	);

interface Feedback {
	editorView: EditorView;
	locale: string;
	product: EditorPluginAIProvider['product'];
	handleFeedbackSubmission: EditorPluginAIProvider['handleFeedbackSubmission'];
	feedbackSubmitted?: boolean;
}

type RemoveRecommendationParams = {
	recommendationId: string;
	analyticsApi?: EditorAnalyticsAPI;
	triggeredFrom: 'contextPanel' | 'preview';
	feedback: Feedback;
};

export const removeRecommendation = ({
	analyticsApi,
	recommendationId,
	triggeredFrom,
	feedback,
}: RemoveRecommendationParams) =>
	withoutAddingToHistory(
		createCommand(
			(state) => {
				const pluginState = getPluginState(state);
				const { decorationSet, proactiveAIBlocks, dismissedCount } = pluginState;
				if (!proactiveAIBlocks?.length || !hasRecommendation(pluginState, recommendationId)) {
					return false;
				}

				const updatedProactiveAIBlocks = removeRecommendationFromBlocks(
					proactiveAIBlocks,
					recommendationId,
				);

				// TODO: We need to determine if have should close the context panel (if it's open) if we have no more recommendaitons
				// left after this action is performed.

				return {
					type: ACTIONS.UPDATE_PLUGIN_STATE,
					data: {
						// dismissedWords: dismissedWords.add(originalText),
						decorationSet: regenerateAllDecorations({
							decorationSet,
							tr: state.tr,
							pluginState: {
								...pluginState,
								proactiveAIBlocks: updatedProactiveAIBlocks,
							},
						}),
						proactiveAIBlocks: updatedProactiveAIBlocks,
						dismissedCount: dismissedCount + 1,
						hasNoMoreSuggestions: false,
					},
				};
			},
			(tr: Transaction, state: EditorState) => {
				const pluginState = getPluginState(state);

				const { recommendation } = getBlockFromRecommendationId(pluginState, recommendationId);

				if (recommendation) {
					const { transformAction, transformType } = recommendation;

					analyticsApi?.attachAnalyticsEvent(
						createUnifiedAnalyticsPayload(
							ACTION.DISMISSED,
							pluginState.analyticsAIInteractionId,
							recommendation.transformAction,
							true,
						),
					)(tr);

					analyticsApi?.attachAnalyticsEvent({
						eventType: EVENT_TYPE.TRACK,
						action: ACTION.DISMISSED,
						actionSubject: ACTION_SUBJECT.EDITOR_PLUGIN_AI,
						actionSubjectId: ACTION_SUBJECT_ID.PROACTIVE_SUGGESTION,
						attributes: {
							aiInteractionID: pluginState.analyticsAIInteractionId,
							triggeredFrom,
							transformAction,
							transformType,
						},
					})(tr);
				}

				if (fg('platform_editor_ai_aggressive_feedback_proactive')) {
					const dialogArgs: LaunchProactiveFeedbackDialogParams = {
						recommendationId,
						sentiment: 'dismiss-recommendation',
						editorView: feedback.editorView,
						locale: feedback.locale,
						handleFeedbackSubmission: feedback.handleFeedbackSubmission,
						aiProactivePluginState: pluginState,
						product: feedback.product,
						triggeredFrom,
						fireAnalyticsEvent: analyticsApi?.fireAnalyticsEvent,
					};
					if (triggeredFrom === 'preview' && !feedback.feedbackSubmitted) {
						requestAnimationFrame(() => {
							launchProactiveFeedbackDialog(dialogArgs);
						});
					} else if (triggeredFrom === 'contextPanel' && isNthDismiss(pluginState, 3)) {
						requestAnimationFrame(() => {
							launchProactiveFeedbackDialog(dialogArgs);
						});
					}
				}

				return tr;
			},
		),
	);

export const toggleProactiveAISuggestionDisplay = () =>
	withoutAddingToHistory(
		createCommand((state) => {
			const pluginState = getPluginState(state);
			const { isProactiveEnabled, isProactiveContextPanelOpen, documentChecker, toggleCount } =
				pluginState;
			if (
				isProactiveEnabled &&
				!isProactiveContextPanelOpen &&
				toggleCount === 0 &&
				!documentChecker?.isActive()
			) {
				// When the panel is open for the first time we will run a full doc scan once. From then on individual edits
				// will be checked as there made.
				documentChecker?.reset();
				documentChecker?.start();
			}
			return { type: ACTIONS.TOGGLE_PROACTIVE_CONTEXT_PANEL };
		}),
	);

export const toggleSetting = (settingKey: keyof SettingDisplayType) =>
	createCommand((state) => {
		const pluginState = getPluginState(state);
		const { decorationSet, proactiveAIBlocks, settings } = pluginState;

		const newSettings = getNewSettings(settings, settingKey);

		const updatedProactiveAIBlocks = filterRecommendationsByToggle(
			proactiveAIBlocks,
			newSettings as SettingDisplayType,
		);

		setProactiveAISettings(newSettings);
		return {
			type: ACTIONS.TOGGLE_DISPLAY_CONTEXT_PANEL_SETTINGS,
			data: {
				decorationSet: regenerateAllDecorations({
					decorationSet,
					tr: state.tr,
					pluginState: {
						...pluginState,
						proactiveAIBlocks: updatedProactiveAIBlocks,
					},
				}),
				proactiveAIBlocks: updatedProactiveAIBlocks,
				settings: {
					...newSettings,
				},
			},
		};
	});

/**
 * This command will perform the initial full document scanner if it has been enabled. This can only be
 * run once and then will lock up. If you want to trigger this more then once then you will need to reset the
 * hasRunFullDocumentScanOnReplaceDocument plugin state to false before executing this command.
 */
export const startWholeDocumentInitialScan = () =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const {
				isProactiveEnabled,
				documentChecker,
				hasRunFullDocumentScanOnReplaceDocument,
				proactiveAIBlocks,
			} = pluginState;

			if (
				isProactiveEnabled &&
				!hasRunFullDocumentScanOnReplaceDocument &&
				!documentChecker?.isActive() &&
				proactiveAIBlocks.length > 0 &&
				fg('platform_editor_ai_proactive_full_scan_on_load')
			) {
				// When the panel is open for the first time we will run a full doc scan once. From then on individual edits
				// will be checked as there made.
				documentChecker?.reset();
				documentChecker?.start();

				return {
					type: ACTIONS.UPDATE_PLUGIN_STATE,
					data: {
						hasRunFullDocumentScanOnReplaceDocument: true,
					},
				};
			}

			return false;
		},
		(tr: Transaction, state: EditorState) => {
			return tr.setMeta('addToHistory', false);
		},
	);

export const closeProactiveAISuggestionDisplay = () =>
	withoutAddingToHistory(
		createCommand((state) => {
			const pluginState = getPluginState(state);
			const { isProactiveContextPanelOpen } = pluginState;
			// A close command will only toggle the context panel shut if it is already open. This is to provide controls
			// for auto-closing the menu and only firing analytics if the action occurred.
			if (!isProactiveContextPanelOpen) {
				return false;
			}
			return { type: ACTIONS.TOGGLE_PROACTIVE_CONTEXT_PANEL };
		}),
	);

export const hoverRecommendation = (recommendationId: string) =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const {
				decorationSet,
				proactiveAIBlocks,
				allowInlineHoverHighlightWhileRecommendationSelected,
				selectedRecommendationId,
			} = pluginState;

			if (!proactiveAIBlocks?.length || !hasRecommendation(pluginState, recommendationId)) {
				return false;
			}

			if (!allowInlineHoverHighlightWhileRecommendationSelected && !!selectedRecommendationId) {
				return false;
			}

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					// Upon inserting a suggestion we need to ensure that that the insertion widget is removed.
					decorationSet: regenerateHoverDecorations({
						decorationSet,
						tr: state.tr,
						pluginState: {
							...pluginState,
							hoveredRecommendationId: recommendationId,
						},
					}),
					hoveredRecommendationId: recommendationId,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			return tr.setMeta('addToHistory', false);
		},
	);

export const clearHoverRecommendation = () =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { decorationSet, hoveredRecommendationId } = pluginState;

			if (!hoveredRecommendationId) {
				return false;
			}

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					// Upon inserting a suggestion we need to ensure that that the insertion widget is removed.
					decorationSet: regenerateHoverDecorations({
						decorationSet,
						tr: state.tr,
						pluginState: {
							...pluginState,
							hoveredRecommendationId: undefined,
						},
					}),
					hoveredRecommendationId: undefined,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			return tr.setMeta('addToHistory', false);
		},
	);

/**
 * This will move the users text selection to the block containing the recommendation. We don't need to bother
 * updated the selection decorations because the plugin-factory handleSelectionChange will take care of that.
 */
export const selectRecommendation = (recommendationId: string, analyticsAIInteractionId: string) =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { decorationSet, proactiveAIBlocks, selectedRecommendationId } = pluginState;

			if (
				recommendationId === selectedRecommendationId ||
				!proactiveAIBlocks?.length ||
				!hasRecommendation(pluginState, recommendationId)
			) {
				return false;
			}

			return {
				type: ACTIONS.SELECT_PROACTIVE_RECOMMENDATION,
				data: {
					analyticsAIInteractionId,
					selectedRecommendationId: recommendationId,
					decorationSet: regenerateSelectionDecorations({
						decorationSet,
						tr: state.tr,
						pluginState: {
							...pluginState,
							selectedRecommendationId: recommendationId,
						},
					}),
				},
			};
		},
		(tr: Transaction) => tr.setMeta('addToHistory', false),
	);

/**
 * Clear the selected recommendation if any.
 */
export const clearSelectRecommendation = () =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { decorationSet, selectedRecommendationId } = pluginState;

			if (!selectedRecommendationId) {
				return false;
			}

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					selectedRecommendationId: undefined,
					decorationSet: regenerateSelectionDecorations({
						decorationSet,
						tr: state.tr,
						pluginState: {
							...pluginState,
							selectedRecommendationId: undefined,
						},
					}),
				},
			};
		},
		(tr: Transaction) => tr.setMeta('addToHistory', false),
	);

export const fireAPIError = (transform?: (tr: Transaction, state: EditorState) => Transaction) =>
	createCommand(ACTIONS.API_ERROR, (tr: Transaction, state: EditorState) => {
		if (transform) {
			return transform(tr, state).setMeta('addToHistory', false);
		}
		return tr.setMeta('addToHistory', false);
	});

/**
 * This command is used to show a notification about new recommendations.
 */
export const updateNewRecommendationsNotificationState = (showNotification: boolean) =>
	createCommand(
		(state) => {
			const { showNewRecommendationsNotification } = getPluginState(state);

			// Avoid a transaction if notification state is not changing.
			if (!!showNewRecommendationsNotification === showNotification) {
				return false;
			}

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					showNewRecommendationsNotification: showNotification,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			return tr.setMeta('addToHistory', false);
		},
	);

/**
 * This command will "purge" all invalid blocks. This essentially means the blocks that were waiting to be checked
 * will now only be checked if the user edits them again after this purge event.
 */
export const disableNeedProactiveRecommendations = () =>
	withoutAddingToHistory(
		createCommand((state) => {
			const { proactiveAIBlocks, rateLimiter } = getPluginState(state);

			rateLimiter?.reset();

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					proactiveAIBlocks: proactiveAIBlocks?.map((block) => {
						return {
							...block,
							invalidated: false,
							invalidatedForDocChecker: false,
						};
					}),
				},
			};
		}),
	);

export const updateChunksWithRecommendations = async ({
	analyticsApi,
	chunks,
	context,
}: {
	analyticsApi: EditorAnalyticsAPI | undefined;
	chunks: ParagraphChunk[];
	context: TriggerContext;
}) => {
	const { view } = context;
	const pluginState = getPluginState(view.state);
	const { proactiveAIApiUrl, product, rateLimiter } = pluginState;

	if (!!rateLimiter?.isActive()) {
		// NOP - if there's an active rate limiter running we can ignore this attempt to update the chunks
		// the rate limiter will eventually complete and retry this operation when its ready.
		return;
	}

	const requestGenerator = fetchAIParagraphs(
		proactiveAIApiUrl,
		product,
		context,
		rateLimiter,
	)(chunks);

	if (!requestGenerator) {
		return;
	}

	updateIsLoadingState(true)(view.state, view.dispatch);

	for await (const responseState of requestGenerator) {
		switch (responseState.state) {
			case 'cached':
			case 'parsed': {
				const recommendations = responseState.recommendations;

				const newPluginState = getPluginState(view.state);
				const { decorationSet, proactiveAIBlocks, isProactiveContextPanelOpen } = newPluginState;

				if (!proactiveAIBlocks?.length) {
					break;
				}

				// This is a collection of chunk ids which are missing from the recommendations.
				const chunkIdsMissingRecommendations = chunks.reduce((acc, chunk) => {
					if (!recommendations.some((r) => r.chunkId === chunk.id)) {
						acc.add(chunk.id);
					}
					return acc;
				}, new Set<string>());

				const updatedProactiveAIBlocks = syncProactiveRecommendations(
					newPluginState,
					recommendations,
					chunkIdsMissingRecommendations,
				);

				const updatePluginCommand = createCommand(
					() => {
						return {
							type: ACTIONS.UPDATE_PLUGIN_STATE,
							data: {
								proactiveAIBlocks: updatedProactiveAIBlocks,
								// Show a notification when we receive new recommendations.
								// Only trigger a 'notification' if the panel is closed
								showNewRecommendationsNotification:
									!isProactiveContextPanelOpen && recommendations.length > 0,
								// isFirstInitiatedEventSent: true,
								decorationSet: regenerateAllDecorations({
									decorationSet,
									tr: view.state.tr,
									pluginState: {
										...newPluginState,
										proactiveAIBlocks: updatedProactiveAIBlocks,
									},
								}),
							},
						};
					},
					(tr: Transaction, state: EditorState) => {
						return tr.setMeta('addToHistory', false);
					},
				);
				updatePluginCommand(view.state, view.dispatch);
				break;
			}
			case 'trackedDuration': {
				const newPluginState = getPluginState(view.state);
				const { insertionCount, dismissedCount, availableRecommendationIds } = newPluginState;
				// Below is analytics for receiving step from S+G API.
				fireAPIReceivedAnalytics({
					analyticsApi,
					view,
					duration: responseState.duration,
					totalSuggestions: availableRecommendationIds.size,
					totalAcceptedSuggestions: insertionCount,
					totalDismissedSuggestions: dismissedCount,
				});
				break;
			}
			// case 'rate-limited': {
			// 	// XXX: if there's anything we should do when we get a rate limited response which is not already handled by the limiter
			// 	break;
			// }
			case 'failed': {
				if (responseState.failedChunkIds.length > 0) {
					updateFailedChunksWithAnalytics(analyticsApi)(
						responseState.failedChunkIds,
						responseState,
					)(view.state, view.dispatch);
				}
				break;
			}
			// case 'purged': {
			// 	if (responseState.purgedChunkIds.length) {
			// 		disableCheckForPurgedChunksWithAnalytics({
			// 			purgedChunkIds: responseState.purgedChunkIds,
			// 			totalParts: responseState.totalParts,
			// 			totalPurgedParts: responseState.totalPurgedParts,
			// 		})(view.state, view.dispatch);
			// 	}
			// }
		}
	}

	// Stop the notifications soon after the last notification arrives.
	stopNewRecommendationsNotification(view);
	updateIsLoadingState(false)(view.state, view.dispatch);
};

/**
 * Short, extra delay since new recommendations are only added at end of response
 * This prevents showing the loading animation for a fraction of a second when
 * API responds quickly, which can be jarring.
 */
const stopNewRecommendationsNotification = debounce(
	(view: EditorView) => updateNewRecommendationsNotificationState(false)(view.state, view.dispatch),
	1000,
);

const isSelectionInBlock = (from: number, to: number, block: ProactiveAIBlock) => {
	return (block.from <= from && block.to >= from) || (block.from <= to && block.to >= to);
};

const getBlocksNeedingCheck = (state: EditorState, currentBlocks: boolean) => {
	const { from, to } = state.selection;
	const { proactiveAIBlocks } = getPluginState(state);

	return proactiveAIBlocks
		.filter((block) => !!block.invalidated)
		.filter((block) => {
			const selectionInBlock = isSelectionInBlock(from, to, block);
			return currentBlocks ? selectionInBlock : !selectionInBlock;
		});
};

/**
 * Wait Y (Y > X) seconds before triggering S+G prompt for current blocks.
 * That means,
 *  Author starts updating block and keeps updating for sometime.
 *  Then Author pauses for Y (Y > X) seconds then trigger S+G prompt.
 */
const triggerCheckForCurrentBlocks = async ({
	analyticsApi,
	context,
}: {
	analyticsApi: EditorAnalyticsAPI | undefined;
	context: TriggerContext;
}) => {
	/**
	 * If selection is overlap with block and it's been more than Y seconds
	 *  since it was last updated then send it for S+G prompt.
	 * Ignore non current blocks, as they will be handled separately.
	 */
	const chunks = getBlocksNeedingCheck(context.view.state, true);

	if (chunks.length) {
		await updateChunksWithRecommendations({ analyticsApi, chunks, context });
	}
};

/**
 * Wait X seconds before triggering S+G prompt for non current blocks.
 * That means,
 *  Author starts updating block, till cursor (or selection) is in the block,
 *    and S+G prompt hasn't been run since last update then,
 *    trigger S+G prompts after X seconds.
 */
const triggerCheckForNonCurrentBlocks = async ({
	analyticsApi,
	context,
}: {
	analyticsApi: EditorAnalyticsAPI | undefined;
	context: TriggerContext;
}) => {
	const chunks = getBlocksNeedingCheck(context.view.state, false);

	if (chunks.length) {
		await updateChunksWithRecommendations({ analyticsApi, chunks, context });
	}
};

export const createTriggerProactiveCheck = ({
	analyticsApi,
	timings,
}: {
	analyticsApi: EditorAnalyticsAPI | undefined;
	timings: ProactiveAIConfig['timings'];
}) => {
	// XXX: This way a throttle, however i've changed this to a debounce for proactive because they want it to be less responsive
	const triggerForNonCurrentBlocksThrottled = debounce(
		triggerCheckForNonCurrentBlocks,
		timings.nonCurrentChunks,
	);

	const triggerForCurrentBlocksDebounced = debounce(
		triggerCheckForCurrentBlocks,
		timings.currentChunks,
		{ maxWait: timings.currentChunksMaxWait },
	);

	const triggerProactiveCheck = (context: TriggerContext) => {
		timings.nonCurrentChunks >= 0 && triggerForNonCurrentBlocksThrottled({ analyticsApi, context });
		timings.currentChunks >= 0 && triggerForCurrentBlocksDebounced({ analyticsApi, context });
	};

	const cancelDebouncedAndThrottledCheck = () => {
		triggerForNonCurrentBlocksThrottled.cancel();
		triggerForCurrentBlocksDebounced.cancel();
	};

	const triggerProactiveCheckImmediately = (context: TriggerContext) => {
		cancelDebouncedAndThrottledCheck();
		triggerCheckForNonCurrentBlocks({ analyticsApi, context });
		triggerCheckForCurrentBlocks({ analyticsApi, context });
	};

	return {
		triggerProactiveCheck,
		cancelDebouncedAndThrottledCheck,
		triggerProactiveCheckImmediately,
	};
};

export const resetChunksForDocChecker = () =>
	createCommand(
		(state) => {
			const { proactiveAIBlocks } = getPluginState(state);
			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					proactiveAIBlocks: proactiveAIBlocks.map((chunk) => ({
						...chunk,
						invalidatedForDocChecker: true,
					})),
					hasNoMoreSuggestions: true,
				},
			};
		},
		(tr: Transaction) => {
			return tr.setMeta('addToHistory', false);
		},
	);

export const updateIsLoadingState = (isLoading: boolean) =>
	createCommand(
		() => {
			return {
				type: ACTIONS.UPDATE_IS_LOADING_STATE,
				data: isLoading,
			};
		},
		(tr: Transaction) => {
			return tr.setMeta('addToHistory', false);
		},
	);

/**
 * failedResponseState contains the failedChunkIds but making it optional to keep it generic
 * in case we ever want to validate chunks without firing error analytics
 */
export const updateFailedChunks = (chunkIds: string[]) =>
	withoutAddingToHistory(
		createCommand((state) => {
			const { proactiveAIBlocks } = getPluginState(state);
			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					proactiveAIBlocks: proactiveAIBlocks.map((chunk) =>
						chunkIds.includes(chunk.id)
							? {
									...chunk,
									invalidatedForDocChecker: false,
								}
							: chunk,
					),
				},
			};
		}),
	);
