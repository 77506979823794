import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.makeLonger.title',
		defaultMessage: 'Make longer',
		description:
			'Describes an option to use Atlassian Intelligence to make the content longer currently selected by the user',
	},
	description: {
		id: 'fabric.editor.ai.config.item.makeLonger.description',
		defaultMessage: 'Expands the content based on the context you provide',
		description: 'Description for the the Atlassian Intelligence "Make longer".',
	},
});
