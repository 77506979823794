import gql from 'graphql-tag';

export const SpacePermissionListQuery = gql`
	query SpacePermissionListQuery($after: String) {
		spacePermissionsAll(after: $after, first: 100) {
			nodes {
				id
				displayName
				description
				priority
				group {
					displayName
					priority
				}
				requiredSpacePermissions
			}
			pageInfo {
				endCursor
				hasNextPage
			}
		}
	}
`;
