// The full list of languages that can potentially be supported is found in
// https://prismjs.com/#supported-languages
export const SUPPORTED_LANGUAGES = [
	{
		name: 'PHP',
		alias: ['php', 'php3', 'php4', 'php5'],
		value: 'php',
	},
	{
		name: 'Java',
		alias: ['java'],
		value: 'java',
	},
	{
		name: 'CSharp',
		alias: ['csharp', 'c#'],
		value: 'cs',
	},
	{
		name: 'Python',
		alias: ['python', 'py'],
		value: 'python',
	},
	{
		name: 'JavaScript',
		alias: ['javascript', 'js'],
		value: 'javascript',
	},
	{
		name: 'Html',
		alias: ['html'],
		value: 'xml',
	},
	{
		name: 'C++',
		alias: ['c++', 'cpp', 'clike'],
		value: 'cpp',
	},
	{
		name: 'Ruby',
		alias: ['ruby', 'rb', 'duby'],
		value: 'ruby',
	},
	{
		name: 'Objective-C',
		alias: ['objective-c', 'objectivec', 'obj-c', 'objc'],
		value: 'objectivec',
	},
	{
		name: 'C',
		alias: ['c'],
		value: 'cpp',
	},
	{
		name: 'Swift',
		alias: ['swift'],
		value: 'swift',
	},
	{
		name: 'TeX',
		alias: ['tex', 'latex'],
		value: 'tex',
	},
	{
		name: 'Shell',
		alias: ['shell', 'bash', 'sh', 'ksh', 'zsh'],
		value: 'shell',
	},
	{
		name: 'Scala',
		alias: ['scala'],
		value: 'scala',
	},
	{
		name: 'Go',
		alias: ['go'],
		value: 'go',
	},
	{
		name: 'ActionScript',
		alias: ['actionscript', 'actionscript3', 'as'],
		value: 'actionscript',
	},
	{
		name: 'ColdFusion',
		alias: ['coldfusion'],
		value: 'xml',
	},
	{
		name: 'JavaFX',
		alias: ['javafx', 'jfx'],
		value: 'java',
	},
	{
		name: 'VbNet',
		alias: ['vbnet', 'vb.net', 'vfp', 'clipper', 'xbase'],
		value: 'vbnet',
	},
	{
		name: 'JSON',
		alias: ['json'],
		value: 'json',
	},
	{
		name: 'MATLAB',
		alias: ['matlab'],
		value: 'matlab',
	},
	{
		name: 'Groovy',
		alias: ['groovy'],
		value: 'groovy',
	},
	{
		name: 'SQL',
		alias: [
			'sql',
			'postgresql',
			'postgres',
			'plpgsql',
			'psql',
			'postgresql-console',
			'postgres-console',
			'tsql',
			't-sql',
			'mysql',
			'sqlite',
		],
		value: 'sql',
	},
	{
		name: 'R',
		alias: ['r'],
		value: 'r',
	},
	{
		name: 'Perl',
		alias: ['perl', 'pl'],
		value: 'perl',
	},
	{
		name: 'Lua',
		alias: ['lua'],
		value: 'lua',
	},
	{
		name: 'Pascal',
		alias: ['pas', 'pascal', 'objectpascal', 'delphi'],
		value: 'pascal',
	},
	{
		name: 'XML',
		alias: ['xml'],
		value: 'xml',
	},
	{
		name: 'TypeScript',
		alias: ['typescript', 'ts'],
		value: 'typescript',
	},
	{
		name: 'CoffeeScript',
		alias: ['coffeescript', 'coffee-script', 'coffee'],
		value: 'coffeescript',
	},
	{
		name: 'Haskell',
		alias: ['haskell', 'hs'],
		value: 'haskell',
	},
	{
		name: 'Puppet',
		alias: ['puppet'],
		value: 'puppet',
	},
	{
		name: 'Arduino',
		alias: ['arduino'],
		value: 'arduino',
	},
	{
		name: 'Fortran',
		alias: ['fortran'],
		value: 'fortran',
	},
	{
		name: 'Erlang',
		alias: ['erlang', 'erl'],
		value: 'erlang',
	},
	{
		name: 'PowerShell',
		alias: ['powershell', 'posh', 'ps1', 'psm1'],
		value: 'powershell',
	},
	{
		name: 'Haxe',
		alias: ['haxe', 'hx', 'hxsl'],
		value: 'haxe',
	},
	{
		name: 'Elixir',
		alias: ['elixir', 'ex', 'exs'],
		value: 'elixir',
	},
	{
		name: 'Verilog',
		alias: ['verilog', 'v'],
		value: 'verilog',
	},
	{
		name: 'Rust',
		alias: ['rust'],
		value: 'rust',
	},
	{
		name: 'VHDL',
		alias: ['vhdl'],
		value: 'vhdl',
	},
	{
		name: 'Sass',
		alias: ['sass'],
		value: 'less',
	},
	{
		name: 'OCaml',
		alias: ['ocaml'],
		value: 'ocaml',
	},
	{
		name: 'Dart',
		alias: ['dart'],
		value: 'dart',
	},
	{
		name: 'CSS',
		alias: ['css'],
		value: 'css',
	},
	{
		name: 'reStructuredText',
		alias: ['restructuredtext', 'rst', 'rest'],
		value: 'rest',
	},
	{
		name: 'Kotlin',
		alias: ['kotlin'],
		value: 'kotlin',
	},
	{
		name: 'D',
		alias: ['d'],
		value: 'd',
	},
	{
		name: 'Octave',
		alias: ['octave'],
		value: 'matlab',
	},
	{
		name: 'QML',
		alias: ['qbs', 'qml'],
		value: 'qml',
	},
	{
		name: 'Prolog',
		alias: ['prolog'],
		value: 'prolog',
	},
	{
		name: 'FoxPro',
		alias: ['foxpro', 'purebasic'],
		value: 'purebasic',
	},
	{
		name: 'Scheme',
		alias: ['scheme', 'scm'],
		value: 'scheme',
	},
	{
		name: 'CUDA',
		alias: ['cuda', 'cu'],
		value: 'cpp',
	},
	{
		name: 'Julia',
		alias: ['julia', 'jl'],
		value: 'julia',
	},
	{
		name: 'Racket',
		alias: ['racket', 'rkt'],
		value: 'lisp',
	},
	{
		name: 'Ada',
		alias: ['ada', 'ada95', 'ada2005'],
		value: 'ada',
	},
	{
		name: 'Tcl',
		alias: ['tcl'],
		value: 'tcl',
	},
	{
		name: 'Mathematica',
		alias: ['mathematica', 'mma', 'nb'],
		value: 'mathematica',
	},
	{
		name: 'Autoit',
		alias: ['autoit'],
		value: 'autoit',
	},
	{
		name: 'StandardML',
		alias: ['standardmL', 'sml', 'standardml'],
		value: 'sml',
	},
	{
		name: 'Objective-J',
		alias: ['objective-j', 'objectivej', 'obj-j', 'objj'],
		value: 'objectivec',
	},
	{
		name: 'Smalltalk',
		alias: ['smalltalk', 'squeak', 'st'],
		value: 'smalltalk',
	},
	{
		name: 'Vala',
		alias: ['vala', 'vapi'],
		value: 'vala',
	},
	{
		name: 'ABAP',
		alias: ['abap'],
		value: 'sql',
	},
	{
		name: 'LiveScript',
		alias: ['livescript', 'live-script'],
		value: 'livescript',
	},
	{
		name: 'XQuery',
		alias: ['xquery', 'xqy', 'xq', 'xql', 'xqm'],
		value: 'xquery',
	},
	{
		name: 'PlainText',
		alias: ['text', 'plaintext'],
		value: 'text',
	},
	{
		name: 'Yaml',
		alias: ['yaml', 'yml'],
		value: 'yaml',
	},
	{
		name: 'GraphQL',
		alias: ['graphql'],
		value: 'graphql',
	},
	{
		name: 'AppleScript',
		alias: ['applescript'],
		value: 'applescript',
	},
	{
		name: 'Clojure',
		alias: ['clojure'],
		value: 'clojure',
	},
	{
		name: 'Diff',
		alias: ['diff'],
		value: 'diff',
	},
	{
		name: 'VisualBasic',
		alias: ['visualbasic'],
		value: 'visual-basic',
	},
	{
		name: 'JSX',
		alias: ['jsx'],
		value: 'jsx',
	},
	{
		name: 'TSX',
		alias: ['tsx'],
		value: 'tsx',
	},
	{
		name: 'SplunkSPL',
		alias: ['splunk-spl'],
		value: 'splunk-spl',
	},
	{
		name: 'Dockerfile',
		alias: ['docker', 'dockerfile'],
		value: 'dockerfile',
	},
	{
		name: 'HCL',
		alias: ['hcl', 'terraform'],
		value: 'hcl',
	},
	{
		name: 'NGINX',
		alias: ['nginx'],
		value: 'nginx',
	},
	{
		name: 'Protocol Buffers',
		alias: ['protobuf', 'proto'],
		value: 'protobuf',
	},
] as const;

export { CODE_BLOCK_SELECTOR } from './internal/theme/constants';

export type Language = (typeof SUPPORTED_LANGUAGES)[number];

export type LanguageAlias = (typeof SUPPORTED_LANGUAGES)[number]['alias'][number];
type LanguageName = (typeof SUPPORTED_LANGUAGES)[number]['name'];

export type SupportedLanguages = LanguageName | LanguageAlias;
