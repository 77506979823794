import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

const i18n = defineMessages({
	name: {
		id: 'default-space-permissions.table.allspaces.header.name',
		description: "Title of the column 'Name' of the current space",
		defaultMessage: 'Name',
	},
	key: {
		id: 'default-space-permissions.table.allspaces.header.key',
		description: "Title of the column 'Key'",
		defaultMessage: 'Key',
	},
	operations: {
		id: 'default-space-permissions.table.allspaces.header.operations',
		description: "Title of the column 'Operations'",
		defaultMessage: 'Actions',
	},
});

export enum ColumnKeys {
	NAME = 'name',
	KEY = 'key',
	OPERATIONS = 'operations',
}

export const individualSpacesTableHeaders = {
	cells: [
		{
			key: ColumnKeys.NAME,
			content: <FormattedMessage {...i18n.name} />,
		},
		{
			key: ColumnKeys.KEY,
			content: <FormattedMessage {...i18n.key} />,
		},
		{
			key: ColumnKeys.OPERATIONS,
			content: <FormattedMessage {...i18n.operations} />,
		},
	],
};
