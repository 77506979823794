import { defineMessages } from 'react-intl-next';

export default defineMessages({
	removeLabel: {
		id: 'fabric.editor.ai.screens.promptWithSelectedOption.removeLabel',
		defaultMessage: 'Remove',
		description:
			'Label text for the ‘Remove’ button within the tag UI, that is used to unset the selected option in the Prompt screen',
	},
	promptSelected: {
		id: 'fabric.editor.ai.screens.promptWithSelectedOption.promptSelected',
		defaultMessage: '{promptName} - Selected',
		description:
			'This message is read to users when a preset ai prompt is selected, the prompt name is dynamic an example is "Brainstorm"',
	},
});
