import { defineMessages } from 'react-intl-next';

export default defineMessages({
	defaultAgentName: {
		id: 'ai-mate.agent-selector.default-agent-name',
		defaultMessage: 'Chat',
		description: 'Default agent name',
	},
	defaultAgentDescription: {
		id: 'ai-mate.agent-selector.default-agent-description',
		defaultMessage: 'Get answers, generate new ideas, and accelerate everyday work.',
	},
});
