import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	improveWritingTitle: {
		id: 'fabric.editor.ai.config.item.enhance.improveWriting.title',
		defaultMessage: 'Improve writing',
		description:
			'Describes an option to use Atlassian Intellgience to improve the writing of content currently selected by the user',
	},
	improveWritingSelectionToolbarDropdownItemTitle: {
		id: 'fabric.editor.ai.config.item.enhance.improveWriting.selectionToolbar.dropdownItem.title',
		defaultMessage: 'Improve writing',
		description:
			'Title for Atlassian Intelligence "improve writing" in editor selection floating toolbar',
	},
	fixSpellingGrammarTitle: {
		id: 'fabric.editor.ai.config.item.enhance.fixSpellingGrammar.title',
		defaultMessage: 'Fix spelling & grammar',
		description:
			'Describes an option to use Atlassian Intellgience to improve the writing of content currently selected by the user',
	},
	improveWritingDescription: {
		id: 'fabric.editor.ai.config.item.enhance.improveWriting.description',
		defaultMessage: 'Enhances clarity, structure, and tone for better engagement and comprehension',
		description: 'Description for the the Atlassian Intelligence "Improve Writing".',
	},
	fixSpellingGrammarDescription: {
		id: 'fabric.editor.ai.config.item.enhance.fixSpellingGrammar.description',
		defaultMessage: 'Fixes spelling and grammar mistakes',
		description: 'Description for the the Atlassian Intelligence "Fix Spelling & Grammar".',
	},
	fixSpellingGrammarSelectionToolbarDropdownItemTitle: {
		id: 'fabric.editor.ai.config.item.enhance.fixSpellingGrammar.selectionToolbar.dropdownItem.title',
		defaultMessage: 'Spelling & grammar',
		description:
			'Title for Atlassian Intelligence "fix spelling grammar" in editor selection floating toolbar',
	},
});
