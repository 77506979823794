import { defineMessages } from 'react-intl-next';

export default defineMessages({
	iconAltText: {
		id: 'fabric.editor.ai.screens.loading.iconAltText',
		defaultMessage: 'Generating icon',
		description: 'Icon alt text of the Loading screen',
	},
	liveRegionDescription: {
		id: 'fabric.editor.ai.screens.loading.adfContentAriaLiveRegionDescription',
		defaultMessage: 'Live updating content:',
		description:
			'Description for content that may update frequently and asynchronously. It is hidden and exists for accessibility',
	},
	loadingAriaTitle: {
		id: 'fabric.editor.ai.experience.loadingAriaTitle',
		defaultMessage: 'Generating',
		description:
			'The title string that appears on the "Loading" screen when the user is waiting for Atlassian Intelligence to generate content',
	},
});
