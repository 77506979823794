import type {
	MentionResource,
	MentionResourceConfig,
	TeamMentionResourceConfig,
} from '@atlaskit/mention';
import { PresenceResource, DefaultMentionNameResolver } from '@atlaskit/mention';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next/withAnalyticsEvents';

import { expValEquals } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';
import { getExternalCollaboratorEntitlementFromQuery } from '@confluence/external-collab-ui';
import { cfetch } from '@confluence/network';

import { MentionNameClient } from './MentionNameClient';
import type { MentionsProviderQuery as MentionsProviderQueryType } from './__types__/MentionsProviderQuery';
import { UserTeamMentionProvider } from './UserTeamMentionProvider';
import type { MentionContext, MentionsConfig } from './types';
import { fetchMentionsProviderData } from './graphql-helpers';

export const USER_MENTION_SERVICE_URL = '/gateway/api/mentions';
export const TEAM_MENTION_SERVICE_URL = '/gateway/api/teams/mentions';
const X_PRODUCT_USER_INVITE_URL = '/gateway/api/v3/invitations/invite';

export async function createFabricMentionResource(
	cloudId: string,
	spaceId: string | undefined,
	accountId: string | null,
	withAnalyticsEventProps?: WithAnalyticsEventsProps,
	onInviteItemClick?: () => void,
	userRole?: 'admin' | 'trusted' | 'basic',
	focusedMentionId?: string,
	isEligibleXProductUserInvite?: () => Promise<boolean>,
	onXProductUserInviteClick?: (name: string, isSuccess: boolean) => void,
): Promise<MentionResource> {
	const userMentionConfig: MentionResourceConfig = {
		url: `${USER_MENTION_SERVICE_URL}/${cloudId}`,
		productId: 'micros-group/confluence',
		containerId: spaceId || undefined,
		shouldHighlightMention: (mention) => mention.id === accountId,
		debounceTime: 150,
	};

	const shouldEnableInvite = !!onInviteItemClick;

	if (shouldEnableInvite) {
		userMentionConfig.productName = 'Confluence';
		userMentionConfig.shouldEnableInvite = shouldEnableInvite;
		userMentionConfig.onInviteItemClick = onInviteItemClick;
		userMentionConfig.userRole = userRole;
	}

	userMentionConfig.inviteXProductUser = async (userId, mentionName) => {
		let isSuccess: boolean = false;
		try {
			if (isEligibleXProductUserInvite && !(await isEligibleXProductUserInvite())) {
				return;
			}
			const response = await cfetch(X_PRODUCT_USER_INVITE_URL, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					users: [
						{
							id: userId,
						},
					],
					resources: [`ari:cloud:confluence::site/${cloudId}`],
				}),
			});
			if (response.ok) {
				const data = await response.json();
				isSuccess = data.every((item: any) => {
					const key = Object.keys(item.results)[0];
					return key.includes('confluence') && item.results[key] === 'INVITED';
				});
			}
		} catch (error) {
			isSuccess = false;
		}
		onXProductUserInviteClick?.(mentionName, isSuccess);
	};

	const teamMentionConfig: TeamMentionResourceConfig = {
		url: TEAM_MENTION_SERVICE_URL,
		productId: 'micros-group/confluence',
		shouldHighlightMention: (mention) => mention.id === accountId,
	};

	const scrollToMention = () => {
		if (
			// eslint-disable-next-line confluence-feature-gating/no-preconditioning
			focusedMentionId &&
			expValEquals('confluence_frontend_anchored_mentions_exp', 'cohort', 'experiment') &&
			fg('confluence_frontend_anchored_mentions')
		) {
			const mentionElement = document.querySelector(`[data-local-id="${focusedMentionId}"]`);
			if (mentionElement) {
				mentionElement.scrollIntoView({
					behavior: 'instant',
					inline: 'center',
					block: 'center',
				});
			}
		}
	};

	userMentionConfig.mentionNameResolver = new DefaultMentionNameResolver(
		new MentionNameClient(),
		withAnalyticsEventProps,
		scrollToMention,
	);

	return userTeamMentionProvider(
		userMentionConfig,
		teamMentionConfig,
		isEligibleXProductUserInvite,
	);
}

async function userTeamMentionProvider(
	userMentionConfig: MentionResourceConfig,
	teamMentionConfig: TeamMentionResourceConfig,
	isEligibleXProductUserInvite?: () => Promise<boolean>,
) {
	let includeExternalCollaborators = false;

	const data: MentionsProviderQueryType | undefined = await fetchMentionsProviderData();

	// Entitlement check to determine if tenant is premium/enabled for Guests
	includeExternalCollaborators = getExternalCollaboratorEntitlementFromQuery(data) === true;
	return new UserTeamMentionProvider(
		'/gateway/api/v1/recommendations',
		userMentionConfig,
		teamMentionConfig,
		includeExternalCollaborators,
		isEligibleXProductUserInvite,
	);
}

export async function getMentionsConfig(
	cloudId: string,
	accountId: string | null,
	mentionContext?: MentionContext,
	withAnalyticsEventProps?: WithAnalyticsEventsProps,
	onInviteItemClick?: () => void,
	userRole?: 'admin' | 'trusted' | 'basic',
	isEligibleXProductUserInvite?: () => Promise<boolean>,
	onXProductUserInviteClick?: (name: string, isSuccess: boolean) => void,
): Promise<MentionsConfig> {
	if (!cloudId) {
		throw new Error('MentionResource cannot be configured without a cloud id');
	}

	const spaceId = mentionContext?.spaceId || undefined;

	const mentionResource: MentionResource = await createFabricMentionResource(
		cloudId,
		spaceId,
		accountId,
		withAnalyticsEventProps,
		onInviteItemClick,
		userRole,
		undefined,
		isEligibleXProductUserInvite,
		onXProductUserInviteClick,
	);

	return {
		mentionResource,
		presenceResource: new PresenceResource({
			url: '/gateway/api/directory/graphql',
			cloudId,
			productId: 'confluence',
		}),
		// Required for the TinyMCE mentions plugin, should be removed when TinyMCE is removed
		mentionServiceUrl: `${USER_MENTION_SERVICE_URL}/${cloudId}`,
		contextIdentifier: {
			containerId: spaceId || '',
			objectId: mentionContext?.contentId || '',
			childObjectId: mentionContext?.commentId || '',
		},
	};
}
