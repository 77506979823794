import gql from 'graphql-tag';

export const GroupCountsQuery = gql`
	query GroupCountsQuery($groupIds: [String]) {
		groupCounts(groupIds: $groupIds) {
			groupCounts {
				key
				value
			}
		}
	}
`;
