import type { ComponentType, FC } from 'react';
import React from 'react';
import { css } from '@compiled/react';

import Avatar from '@atlaskit/avatar';
import type { GlyphProps, NewCoreIconProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import { xcss, Box } from '@atlaskit/primitives';

const getCellStyle = (hasLeftBorder: boolean) =>
	hasLeftBorder ? cellContainerLeftBorderStyle : cellContainerBorderlessStyle;

const cellContainerLeftBorderStyle = xcss({
	display: 'flex',
	alignItems: 'center',
	overflowWrap: 'anywhere',
	// eslint-disable-next-line prefer-template
	borderLeft: token('space.025', '2px') + ' solid ' + token('color.background.selected.bold'),
	paddingLeft: 'space.100',
});

const cellContainerBorderlessStyle = xcss({
	display: 'flex',
	alignItems: 'center',
	overflowWrap: 'anywhere',
	paddingLeft: 'space.100',
});

const contentStyle = xcss({
	marginLeft: 'space.100',
	textAlign: 'left',
});

const iconStyle = css({
	borderRadius: '50%',
	width: '34px',
	height: '34px',
	backgroundColor: token('color.background.accent.gray.subtler'),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	margin: '1px',
});

const lozengeStyle = xcss({
	display: 'inline',
	lineHeight: 'normal',
	paddingLeft: 'space.100',
});

const nameAndLozengeStyle = xcss({
	width: '100%',
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'flex-start',
	alignItems: 'center',
});

const nameStyle = xcss({
	color: 'color.text',
	font: token('font.body'),
	wordBreak: 'break-all',
});

const subTextStyle = xcss({
	color: 'color.text.subtlest',
	font: token('font.body.small'),
});

type PrincipalContentProps = {
	name: string;
	subText?: React.ReactNode;
	lozenge?: React.ReactNode;
	hasProfileBorder?: boolean;
};

const PrincipalContent = ({ name, subText, lozenge }: PrincipalContentProps) => (
	<Box xcss={contentStyle}>
		<Box xcss={nameAndLozengeStyle}>
			<Box xcss={nameStyle}>{name}</Box>
			{lozenge ? <Box xcss={lozengeStyle}>{lozenge}</Box> : null}
		</Box>
		<Box xcss={subTextStyle}>{subText}</Box>
	</Box>
);

type AvatarProps = { avatarSrc: string };
type IconProps = { Icon: ComponentType<GlyphProps | NewCoreIconProps> | null };

type PrincipalTableCellProps = PrincipalContentProps & (AvatarProps | IconProps);

export const PrincipalTableCell: FC<PrincipalTableCellProps> = ({
	name,
	lozenge,
	subText,
	hasProfileBorder,
	...avatarOrIconProps
}) => {
	const { Icon } = avatarOrIconProps as IconProps;
	const { avatarSrc } = avatarOrIconProps as AvatarProps;
	return (
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
		<Box key={name} xcss={getCellStyle(!!hasProfileBorder)}>
			{Icon ? (
				<div css={iconStyle}>
					<Icon testId="principal-icon" label="" LEGACY_size="medium" />
				</div>
			) : (
				<Avatar appearance="circle" src={avatarSrc} size="medium" />
			)}
			<PrincipalContent name={name} subText={subText} lozenge={lozenge} />
		</Box>
	);
};
