import { defineMessages } from 'react-intl-next';

export default defineMessages({
	submitLabel: {
		id: 'fabric.editor.ai.screens.prompt.submitLabel',
		defaultMessage: 'Submit',
		description: 'Submit button label within the Prompt screen',
	},
	submitTooltip: {
		id: 'fabric.editor.ai.screens.prompt.submitTooltip',
		defaultMessage: 'Send to AI',
		description: 'Submit button tooltip within the Prompt screen',
	},
});
