import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	nestingParentTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.nestingParentTitle',
		defaultMessage: 'Change tone',
		description:
			'Describes a (nested) option to use Atlassian Intelligence to change the tone of content currently selected by the user',
	},
	nestingParentTitlePostGA: {
		id: 'fabric.editor.ai.config.item.changeTone.nestingParentTitlePostGA',
		defaultMessage: 'Change tone to ...',
		description:
			'Describes a (nested) option to use Atlassian Intelligence to change the tone of content currently selected by the user',
	},
	neutralToneTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.neutralTone.title',
		defaultMessage: 'Change tone to neutral',
		description:
			'Describes an option to use Atlassian Intellgience to change the tone of content currently selected by the user',
	},
	neutralToneShortTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.neutralTone.shortTitle',
		defaultMessage: 'Neutral',
		description:
			'Describes an option to use Atlassian Intellgience to change the tone of content currently selected by the user',
	},
	neutralToneDescription: {
		id: 'fabric.editor.ai.config.item.changeTone.neutralTone.description',
		defaultMessage: 'Changes the tone of the content to neutral',
		description: 'Desciption for the Atlassian Intellgience "Change tone to neutral" option',
	},
	neutralToneSelectionToolbarDropdownItemTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.neutralTone.selectionToolbar.dropdownItem.title',
		defaultMessage: 'Neutral',
		description:
			'Title for Atlassian Intelligence "Change tone to neutral" in editor selection floating toolbar',
	},
	professionalToneTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.professionalTone.title',
		defaultMessage: 'Change tone to professional',
		description:
			'Describes an option to use Atlassian Intellgience to change the tone of content currently selected by the user',
	},
	professionalToneShortTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.professionalTone.shortTitle',
		defaultMessage: 'Professional',
		description:
			'Describes an option to use Atlassian Intellgience to change the tone of content currently selected by the user',
	},
	professionalToneDescription: {
		id: 'fabric.editor.ai.config.item.changeTone.professionalTone.description',
		defaultMessage: 'Changes the tone of the content to professional',
		description: 'Desciption for the Atlassian Intellgience "Change tone to professional" option',
	},
	professionalToneSelectionToolbarDropdownItemTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.professionalTone.selectionToolbar.dropdownItem.title',
		defaultMessage: 'Professional',
		description:
			'Title for Atlassian Intelligence "Change tone to professional" in editor selection floating toolbar',
	},
	casualToneTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.casualTone.title',
		defaultMessage: 'Change tone to casual',
		description:
			'Describes an option to use Atlassian Intellgience to change the tone of content currently selected by the user',
	},
	casualToneShortTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.casualTone.shortTitle',
		defaultMessage: 'Casual',
		description:
			'Describes an option to use Atlassian Intellgience to change the tone of content currently selected by the user',
	},
	casualToneDescription: {
		id: 'fabric.editor.ai.config.item.changeTone.casualTone.description',
		defaultMessage: 'Changes the tone of the content to casual',
		description: 'Desciption for the Atlassian Intellgience "Change tone to casual" option',
	},
	casualToneSelectionToolbarDropdownItemTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.casualTone.selectionToolbar.dropdownItem.title',
		defaultMessage: 'Casual',
		description:
			'Title for Atlassian Intelligence "Change tone to casual" in editor selection floating toolbar',
	},
	educationalToneTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.educationalTone.title',
		defaultMessage: 'Change tone to educational',
		description:
			'Describes an option to use Atlassian Intellgience to change the tone of content currently selected by the user',
	},
	educationalToneShortTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.educationalTone.shortTitle',
		defaultMessage: 'Educational',
		description:
			'Describes an option to use Atlassian Intellgience to change the tone of content currently selected by the user',
	},
	educationalToneDescription: {
		id: 'fabric.editor.ai.config.item.changeTone.educationalTone.description',
		defaultMessage: 'Changes the tone of the content to educational',
		description: 'Desciption for the Atlassian Intellgience "Change tone to educational" option',
	},
	educationalToneSelectionToolbarDropdownItemTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.educationalTone.selectionToolbar.dropdownItem.title',
		defaultMessage: 'Educational',
		description:
			'Title for Atlassian Intelligence "Change tone to educational" in editor selection floating toolbar',
	},
	empatheticToneTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.empatheticTone.title',
		defaultMessage: 'Change tone to empathetic',
		description:
			'Describes an option to use Atlassian Intellgience to change the tone of content currently selected by the user',
	},
	empatheticToneShortTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.empatheticTone.shortTitle',
		defaultMessage: 'Empathetic',
		description:
			'Describes an option to use Atlassian Intellgience to change the tone of content currently selected by the user',
	},
	empatheticToneDescription: {
		id: 'fabric.editor.ai.config.item.changeTone.empatheticTone.description',
		defaultMessage: 'Changes the tone of the content to empathetic',
		description: 'Desciption for the Atlassian Intellgience "Change tone to empathetic" option',
	},
	empatheticToneSelectionToolbarDropdownItemTitle: {
		id: 'fabric.editor.ai.config.item.changeTone.empatheticTone.selectionToolbar.dropdownItem.title',
		defaultMessage: 'Empathetic',
		description:
			'Title for Atlassian Intelligence "Change tone to empathetic" in editor selection floating toolbar',
	},
});
