import React from 'react';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

// TODO swap to @atlaskit/icon once available
const IconMakeLonger = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5 5.75L19 5.75M5 9.91667L19 9.91667M5 14.0833L19 14.0833M5 18.25L12 18.25"
				stroke="#44546F"
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<IconMakeLonger />
		</RainbowBorder>
	);
};
