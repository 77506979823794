import React from 'react';

import {
	PRODUCT_HOME_ID,
	COMPANY_HUB_SETTINGS_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { COMPANY_HUB_SETTINGS } from '@confluence/named-routes';
import { fg } from '@confluence/feature-gating';
import { NotFound } from '@confluence/not-found';
import { CompanyHubSettingsPage } from '@confluence/company-hub/entry-points/CompanyHubSettingsPage';

export const CompanyHubSettingsRoute = () => {
	if (!fg('company_hub_consolidated_settings')) {
		return <NotFound />;
	}

	return <CompanyHubSettingsPage />;
};

CompanyHubSettingsRoute.NAVIGATION_PARAMS = () => {
	const navView =
		fg('company_hub_consolidated_settings') && fg('confluence_nav_4')
			? COMPANY_HUB_SETTINGS_ID
			: PRODUCT_HOME_ID;

	return {
		Screen: {
			screenEvent: {
				name: 'companyHubSettingsScreen',
				attributes: {
					// Company Hub hides its space from the user, doesn't refer to its page
					// as such, is rather presented to the user as "a new surface", and thus
					// doesn't constitute space usage from the perspective of the user.
					screenIsInSpace: false,
				},
			},
			pageState: {
				routeName: COMPANY_HUB_SETTINGS.name,
				spaceKey: getCompanyHubSpaceKey() || '',
			},
		},
		MainLayout: {
			navView,
		},
	};
};
