import { defineMessages } from 'react-intl-next';

export default defineMessages({
	labelCancel: {
		id: 'fabric.editor.ai.components.cancelEscButton.cancelLabel',
		defaultMessage: 'Cancel',
		description: 'CancelEsc\'s "Cancel" button label',
	},
	labelEsc: {
		id: 'fabric.editor.ai.components.cancelEscButton.escLabel',
		defaultMessage: 'Esc',
		description: 'CancelEsc\'s "Esc" button label',
	},
});
