import React from 'react';

import { Inline, Text, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';

type SettingItemProps = {
	icon: JSX.Element;
	title: string;
	description: string;
	defaultValue: boolean;
	toggle: () => void;
	indented?: boolean;
	display?: boolean;
};

const itemContainerStyle = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
});

const indentedItemContainerStyle = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingLeft: 'space.500',
});

const iconContainerStyle = xcss({
	paddingTop: 'space.025',
});

const textContainerStyle = xcss({
	display: 'block',
	paddingLeft: 'space.150',
});

const titleStyle = xcss({
	fontWeight: 'font.weight.bold',
	paddingBottom: 'space.025',
	color: 'color.text',
});

export const SettingItem = (props: SettingItemProps) => {
	const { icon, title, description, toggle, defaultValue, indented, display } = props;

	return display ? (
		<Inline
			spread="space-between"
			alignBlock="center"
			xcss={indented ? indentedItemContainerStyle : itemContainerStyle}
		>
			<Inline>
				<Inline xcss={iconContainerStyle}>{icon}</Inline>
				<Inline xcss={textContainerStyle}>
					<Inline xcss={titleStyle}>{title}</Inline>
					<Text color="color.text.subtle">{description}</Text>
				</Inline>
			</Inline>
			<Toggle id="toggle-default" onChange={toggle} defaultChecked={defaultValue} />
		</Inline>
	) : null;
};
