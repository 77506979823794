import { copyHTMLToClipboard } from '@atlaskit/editor-common/clipboard';
import type { Fragment, Node as PMNode, Schema } from '@atlaskit/editor-prosemirror/model';
import { DOMSerializer } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

const toDOM = (fragment: Fragment, schema: Schema): Node => {
	return DOMSerializer.fromSchema(schema).serializeFragment(fragment);
};

/**
 * Copied and amended from platform/packages/editor/editor-plugin-paste/src/pm-plugins/clipboard-text-serializer.ts
 */
function clipboardTextSerializer(fragment: Fragment) {
	let text = '';
	const blockSeparater = '\n\n';
	fragment.nodesBetween(0, fragment.size, (node: PMNode) => {
		if (node.type.isBlock) {
			text += blockSeparater;
		}
		if (node.type.name === 'paragraph') {
			return true;
		} else if (node.type.name === 'hardBreak') {
			text += '\n';
		} else if (node.type.name === 'text') {
			text += node.text;
		} else if (node.type.name === 'inlineCard') {
			text += node.attrs.url;
		} else if (node.type.name === 'blockCard') {
			text += node.attrs.url;
		} else {
			text += node.textBetween(0, node.content.size, '\n\n');
		}
		return false;
	});
	return text.trim();
}

export function copyPMFragmentToClipboard({
	editorView,
	pmFragment,
}: {
	editorView: EditorView;
	pmFragment: Fragment;
}) {
	const transaction = editorView.state.tr;
	const domNode = toDOM(pmFragment, editorView.state.schema);
	if (domNode) {
		const div = document.createElement('div');
		div.appendChild(domNode);

		// The "0 0" refers to the start and end depth of the slice
		// since we're copying the block node only, it will always be 0 0
		// https://github.com/ProseMirror/prosemirror-view/blob/master/src/clipboard.ts#L32
		// Ignored via go/ees005
		// eslint-disable-next-line @atlaskit/editor/no-as-casting
		(div.firstChild as HTMLElement).setAttribute('data-pm-slice', '0 0 []');

		/**
		 * ED-18062: override the plain text in
		 * copyHTMLToClipboard using clipboardTextSerializer(pmFragment)
		 * because HTML to plain text is formatted without new lines
		 * in certain nodes such as lists
		 */
		copyHTMLToClipboard(div, clipboardTextSerializer(pmFragment));
	}

	transaction.setMeta('scrollIntoView', false);
	editorView.dispatch(transaction);
}
