import type { TeamMentionResourceConfig } from '@atlaskit/mention';
import { MentionResource } from '@atlaskit/mention';
import { SLI_EVENT_TYPE } from '@atlaskit/mention/resource';
import type {
	MentionContextIdentifier,
	MentionResourceConfig,
	MentionsResult,
} from '@atlaskit/mention/types';
import { UserAccessLevel, UserType } from '@atlaskit/mention/types';

import { fg } from '@confluence/feature-gating';
import { cfetch } from '@confluence/network';
import { getSessionData } from '@confluence/session-data';
import { ExternalUserLozengeProps } from '@confluence/external-collab-ui/entry-points/ExternalUserLozengeProps';

import { MENTIONS_QUERY_METRIC } from '../perf.config';

import { OfflineCache } from './offlineCache';

const INITIAL_STATE = 'initialState';
const SEARCH = 'searchUser';
const SUCCEEDED = 'succeeded';
const FAILED = 'failed';
const CONTEXT_TYPE = 'Mentions';
const MAX_RESULTS = 20;

/**
 * This is an implementation which calls URS to provide a list of recommended users/teams to mention.
 *
 * It is different from `@atlaskit/mention/TeamMentionResource` which combines results from separate calls to
 * pf-mentions for users and legion for teams. Teams are appended after users and therefore will always show up at the
 * bottom of the mention list. Instead, this implem calls URS for a list of users/teams interspersed and ranked.
 * Therefore, if you are likely to mention a team, it will show up in your top results.
 *
 * This extends `MentionResource` to keep the <a href="https://bitbucket.org/atlassian/atlassian-frontend/src/0884032d85f11f43c13532cd21f13f696b0d28a7/packages/elements/mention/src/api/MentionResource.ts#lines-491">mention selection call to pf-mentions</a>. This will remain until we move to
 * using ui events instead. The missing fields preventing this have been merged and will take a few weeks to release
 * and be added to Confluence. See https://product-fabric.atlassian.net/browse/UR-710. Then, those relying
 * on the pf-mention recorded event will also need to be migrated. Once the blockers are resolved, this implem will be
 * updated to remove the record selection call to pf-mentions.
 *
 * The entry point for a `MentionProvider` is its <a href="https://bitbucket.org/atlassian/atlassian-frontend/src/0884032d85f11f43c13532cd21f13f696b0d28a7/packages/editor/editor-core/src/plugins/mentions/index.tsx#lines-219">filter method</a>.
 * The `MentionResource` then eventually calls <a href="https://bitbucket.org/atlassian/atlassian-frontend/src/0884032d85f11f43c13532cd21f13f696b0d28a7/packages/elements/mention/src/api/MentionResource.ts#lines-352">`remoteInitialState`</a> for bootstrap/empty queries,
 * and <a href="https://bitbucket.org/atlassian/atlassian-frontend/src/0884032d85f11f43c13532cd21f13f696b0d28a7/packages/elements/mention/src/api/MentionResource.ts#lines-436">`remoteSearch`</a> for non-empty queries.
 * This implem extends `MentionResource` and overrides those methods to call URS.
 */
export class UserTeamMentionProvider extends MentionResource {
	private userTeamEndpoint: string;
	private teamMentionConfig: TeamMentionResourceConfig;
	private includeExternalCollaborators: boolean;
	private offlineCache: OfflineCache<MentionsResult> | undefined;
	private isEligibleXProductUserInvite?: () => Promise<boolean>;

	constructor(
		userTeamEndpoint: string,
		userMentionConfig: MentionResourceConfig,
		teamMentionConfig: TeamMentionResourceConfig,
		includeExternalCollaborators: boolean,
		isEligibleXProductUserInvite?: () => Promise<boolean>,
	) {
		super(userMentionConfig);
		this.teamMentionConfig = teamMentionConfig;
		this.userTeamEndpoint = userTeamEndpoint;
		this.includeExternalCollaborators = includeExternalCollaborators;
		this.isEligibleXProductUserInvite = isEligibleXProductUserInvite;
		this.offlineCache = fg('platform_editor_cache_mentions_offline')
			? new OfflineCache<MentionsResult>()
			: undefined;
	}

	/**
	 * Overrides `remoteInitialState` from `MentionResource`
	 * This is invoked for bootstrap/empty queries,
	 */
	async remoteInitialState(contextIdentifier?: MentionContextIdentifier): Promise<MentionsResult> {
		try {
			const promise = await this.doSearch('', contextIdentifier);
			// Set initial cache
			if (fg('platform_editor_cache_mentions_offline')) {
				this.offlineCache?.set(this.userTeamEndpoint, promise);
			}
			this._notifyAnalyticsListeners(SLI_EVENT_TYPE, INITIAL_STATE, SUCCEEDED);
			return promise;
		} catch (e) {
			this._notifyAnalyticsListeners(SLI_EVENT_TYPE, INITIAL_STATE, FAILED);
			throw e;
		}
	}

	/**
	 * Overrides `remoteSearch` from `MentionResource`
	 * This is invoked for non-empty queries.
	 */
	async remoteSearch(query, contextIdentifier?: MentionContextIdentifier): Promise<MentionsResult> {
		try {
			const promise = await this.doSearch(query, contextIdentifier);
			this._notifyAnalyticsListeners(SLI_EVENT_TYPE, SEARCH, SUCCEEDED);
			return promise;
		} catch (e) {
			this._notifyAnalyticsListeners(SLI_EVENT_TYPE, SEARCH, FAILED);
			throw e;
		}
	}

	private async getCachedValue(query: string): Promise<MentionsResult | undefined> {
		const cachedValue = this.offlineCache?.get(this.userTeamEndpoint);
		if (!cachedValue) {
			return undefined;
		}
		if (query === '') {
			return Promise.resolve(cachedValue);
		} else {
			// If we're offline allow basic filtering of the cached results by name.
			if (window?.navigator.onLine === false) {
				return Promise.resolve({
					query,
					mentions: cachedValue.mentions?.filter(
						(v) =>
							v?.name?.toLowerCase().includes(query.toLowerCase()) ||
							v?.mentionName?.toLowerCase().includes(query.toLowerCase()) ||
							v?.nickname?.toLowerCase().includes(query.toLowerCase()),
					),
				});
			}
		}
	}

	private async doSearch(query, contextIdentifier?): Promise<MentionsResult> {
		if (fg('platform_editor_cache_mentions_offline')) {
			const cachedValue = await this.getCachedValue(query);
			// Use stale-while-revalidate - if the cached value exists use it first and
			// update the cache value in the background so that it's up to date when the
			// next call is made
			if (cachedValue) {
				if (query === '') {
					this.doNetworkSearch(query, contextIdentifier)
						.then((results) => {
							this.offlineCache?.set(this.userTeamEndpoint, results);
						})
						.catch(() => {
							// Pass, it's okay if the cache doesn't update
						});
				}
				return cachedValue;
			}

			return this.doNetworkSearch(query, contextIdentifier);
		} else {
			return this.doNetworkSearch(query, contextIdentifier);
		}
	}

	private async doNetworkSearch(query, contextIdentifier?): Promise<MentionsResult> {
		MENTIONS_QUERY_METRIC.start();

		const response = await this.post(
			await this.cloudId(),
			await this.orgId(),
			query,
			contextIdentifier,
		);

		if (!response.ok) {
			const errorBody = await response.json();

			MENTIONS_QUERY_METRIC.stop({
				customData: {
					isExCoEnabled: this.includeExternalCollaborators,
					success: false,
				},
			});

			throw new Error(JSON.stringify(errorBody));
		}

		const body = await response.json();

		const mentions = body.recommendedUsers.map((recommended) => {
			if (this.isUser(recommended)) return this.convertToUserMention(recommended);
			else this.isTeam(recommended);
			return this.convertToTeamMention(recommended);
		});

		let jiraMentions;

		if (
			mentions.length < MAX_RESULTS &&
			this.isEligibleXProductUserInvite &&
			(await this.isEligibleXProductUserInvite())
		) {
			const jiraResponse = await this.searchXproductUsers(
				await this.cloudId(),
				await this.orgId(),
				query,
				contextIdentifier,
			);
			if (!jiraResponse.ok) {
				const errorBody = await jiraResponse.json();
				MENTIONS_QUERY_METRIC.stop({
					customData: {
						isExCoEnabled: this.includeExternalCollaborators,
						success: false,
					},
				});
				throw new Error(JSON.stringify(errorBody));
			}
			const jiraBody = await jiraResponse.json();
			jiraMentions = jiraBody.recommendedUsers.map((recommended) => {
				return { ...this.convertToUserMention(recommended), isXProductUser: true };
			});
		}

		// Remove objects from jiraMentions if an object with the same id is present in mentions
		const mentionIds = new Set(mentions.map((mention) => mention.id));
		jiraMentions = (jiraMentions || [])
			.filter((mention) => !mentionIds.has(mention?.id))
			.slice(0, MAX_RESULTS - mentions.length);

		MENTIONS_QUERY_METRIC.stop({
			customData: {
				isExCoEnabled: this.includeExternalCollaborators,
				success: true,
			},
		});
		return { query, mentions: [...mentions, ...jiraMentions] };
	}

	private async cloudId() {
		const { cloudId } = await getSessionData();
		return cloudId;
	}

	private async orgId() {
		const { orgId } = await getSessionData();
		return orgId;
	}

	private async post(cloudId, orgId, query, contextIdentifier?) {
		return await cfetch(this.userTeamEndpoint, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify({
				context: {
					contextType: CONTEXT_TYPE,
					principalId: 'context',
					productKey: (contextIdentifier && contextIdentifier.product) || 'confluence', // defaults to 'confluence' to work with old editor
					siteId: cloudId,
					containerId: contextIdentifier && contextIdentifier.containerId,
					objectId: contextIdentifier && contextIdentifier.objectId,
					childObjectId: contextIdentifier && contextIdentifier.childObjectId,
					mentionsSessionId: contextIdentifier && contextIdentifier.sessionId,
					...(orgId ? { organizationId: orgId } : {}),
					...(this.includeExternalCollaborators
						? {
								productAttributes: {
									isEntitledConfluenceExternalCollaborator: true,
								},
							}
						: {}),
				},
				searchQuery: {
					queryString: query,
					...(this.includeExternalCollaborators
						? {
								productAccessPermissionIds: ['write', 'external-collaborator-write'],
							}
						: {}),
				},
				maxNumberOfResults: MAX_RESULTS,
				includeTeams: true,
			}),
		});
	}

	private async searchXproductUsers(cloudId, orgId, query, contextIdentifier?) {
		return await cfetch(this.userTeamEndpoint, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify({
				context: {
					contextType: CONTEXT_TYPE,
					principalId: 'context',
					productKey: 'jira', // fetch only jira users
					siteId: cloudId,
					containerId: contextIdentifier && contextIdentifier.containerId,
					objectId: contextIdentifier && contextIdentifier.objectId,
					childObjectId: contextIdentifier && contextIdentifier.childObjectId,
					mentionsSessionId: contextIdentifier && contextIdentifier.sessionId,
					...(orgId ? { organizationId: orgId } : {}),
				},
				searchQuery: {
					queryString: query,
					productAccessPermissionIds: ['write'],
				},
				maxNumberOfResults: MAX_RESULTS,
				includeTeams: false, // Do not include teams
			}),
		});
	}

	private isUser(recommended) {
		return recommended.entityType === 'USER';
	}

	private convertToUserMention(recommended) {
		return {
			id: recommended.id,
			name: recommended.name || recommended.nickname || '',
			mentionName: recommended.nickname || '',
			avatarUrl: recommended.avatarUrl,
			accessLevel: recommended.accessLevel,
			userType: recommended.userType,
			...(recommended?.attributes?.isConfluenceExternalCollaborator
				? {
						lozenge: ExternalUserLozengeProps,
					}
				: {}),
		};
	}

	private isTeam(recommended) {
		return recommended.entityType === 'TEAM';
	}

	private convertToTeamMention(recommended) {
		const { teamLinkResolver } = this.teamMentionConfig;
		let teamLink = '';
		const defaultTeamLink = `${window.location.origin}/people/team/${recommended.id}`;
		if (typeof teamLinkResolver === 'function') {
			teamLink = teamLinkResolver(recommended.id);
		}
		return {
			id: recommended.id,
			name: recommended.displayName,
			mentionName: recommended.displayName,
			avatarUrl: recommended.smallAvatarImageUrl,
			accessLevel: UserAccessLevel[UserAccessLevel.CONTAINER],
			userType: UserType[UserType.TEAM],
			context: {
				members: recommended.members,
				includesYou: recommended.includesYou,
				memberCount: recommended.memberCount,
				teamLink: teamLink || defaultTeamLink,
			},
		};
	}
}
