import type { ApolloError } from 'apollo-client';
import memoize from 'lodash/memoize';
import type { GraphQLError } from 'graphql';

import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getStatusCode } from '@confluence/error-boundary';

import type {
	ExternalShareMediaDownloadTokenQuery as ExternalShareMediaDownloadTokenQueryT,
	ExternalShareMediaDownloadTokenQueryVariables,
} from './__types__/ExternalShareMediaDownloadTokenQuery';
import type {
	PublicLinkMediaDownloadTokenQuery as PublicLinkMediaDownloadTokenQueryT,
	PublicLinkMediaDownloadTokenQueryVariables,
} from './__types__/PublicLinkMediaDownloadTokenQuery';
import type {
	FileUploadMutation as FileUploadMutationT,
	FileUploadMutationVariables,
} from './__types__/FileUploadMutation';
import type {
	MediaDownloadTokenQuery as MediaDownloadTokenQueryT,
	MediaDownloadTokenQueryVariables,
} from './__types__/MediaDownloadTokenQuery';
import type {
	MediaUploadTokenQuery as MediaUploadTokenQueryT,
	MediaUploadTokenQueryVariables,
} from './__types__/MediaUploadTokenQuery';
import { ExternalShareMediaDownloadTokenQuery } from './ExternalShareMediaDownloadTokenQuery.graphql';
import { PublicLinkMediaDownloadTokenQuery } from './PublicLinkMediaDownloadTokenQuery.graphql';
import { FileUploadMutation } from './FileUploadMutation.experimentalgraphql';
import { MediaDownloadTokenQuery } from './MediaDownloadTokenQuery.graphql';
import { MediaUploadTokenQuery } from './MediaUploadTokenQuery.graphql';

type setAttachmentRequestBody = {
	size: number;
	contentType: string;
	mimeType: string;
	fileName: string;
	pageId: string;
	fileStoreId: string;
	minorEdit: boolean;
	renderEditorHTML: boolean;
	collectionName: string;
};

let isFirstLoad = true;

export const setAttachment = (requestBody: setAttachmentRequestBody) =>
	getApolloClient().mutate<FileUploadMutationT, FileUploadMutationVariables>({
		mutation: FileUploadMutation,
		variables: requestBody,
	});

export const fetchMediaToken = (contentId: string) =>
	getApolloClient()
		.query<MediaDownloadTokenQueryT, MediaDownloadTokenQueryVariables>({
			query: MediaDownloadTokenQuery,
			variables: {
				contentId,
				status: ['current', 'draft', 'archived'],
				missingContentId: !contentId,
			},
			context: {
				allowOnExternalPage: true,
			},
			// If SSR has populated the cache then we want to use the cache when rendering SPA-over-SSR
			fetchPolicy: isFirstLoad ? 'cache-first' : 'network-only',
		})
		.then(
			/* onFulfilled */ (value) => {
				isFirstLoad = false;

				try {
					throttleFetchMediaTokenErrors(contentId)(value?.errors);
				} catch {}

				return value;
			},
			/* onRejected */ (reason: ApolloError) => {
				try {
					throttleFetchMediaTokenErrors(contentId)(reason?.graphQLErrors, reason?.networkError);
				} catch {}

				throw reason;
			},
		);

/**
 * `fetchMediaToken` is called repetitively to renew the token before it
 * expires. If an error condition arises (because of frontend or backend), the
 * same error(s) may be reported as unhandled to our analytics and monitoring at
 * a very high rate/volume. Such repetitive reporting fron one user in one tab
 * is noisy. `throttleFetchMediaTokenErrors` attempts to reduce the noise.
 */
const throttleFetchMediaTokenErrors = memoize((_cacheKey: string) => {
	/**
	 * The errors that happened last time. The `GraphQLError` instances are big
	 * and are not trivial to compare so `prevErrors` caches partial info.
	 */
	let prevErrors: Record<string, string> | undefined = undefined;

	return (
		graphQLErrors?: ApolloError['graphQLErrors'],
		networkError?: ApolloError['networkError'],
	) => {
		// Each of `graphQLErrors` and `networkError` are reported to analytics and
		// monitoring so type of `Error` does not matter to what follows and
		// `GraphQLError` is the most convenient TypeScript type to deal with.
		if (networkError) {
			graphQLErrors = (graphQLErrors || []).concat(networkError as GraphQLError);
		}

		if (graphQLErrors) {
			/**
			 * The new/current errors which will become `prevErrors` at the end of
			 * this iteration.
			 */
			const nextErrors = {};

			for (const graphQLError of graphQLErrors) {
				// Extract the partial, easy-to-compare, cachable info from the big
				// `GraphQLError` instance:
				const message =
					graphQLError.message ||
					String(graphQLError) ||
					graphQLError.originalError?.message ||
					String(graphQLError.originalError);
				const { path } = graphQLError;
				const key = (Array.isArray(path) && path.join('.')) || message;
				if (key) {
					const value = getStatusCode(graphQLError) || message;
					if (value) {
						nextErrors[key] = value;
						// If a similar error happened in the previous iteration, do not
						// report it to analytics and monitoring again:
						if (prevErrors?.[key] === value) {
							markErrorAsHandled(graphQLError);
						}
					}
				}
			}

			prevErrors = nextErrors;
		} else {
			// If `MediaDownloadTokenQuery` succeeds, report subsequent/future errors
			// even if similar ones occurred and were reported before the current
			// success - there is a meaningful change in state and it may be useful in
			// debugging.
			prevErrors = undefined;
		}
	};
});

type ExternalMediaTokenQuery =
	| ExternalShareMediaDownloadTokenQueryT
	| PublicLinkMediaDownloadTokenQueryT;
type ExternalMediaTokenQueryVariables =
	| ExternalShareMediaDownloadTokenQueryVariables
	| PublicLinkMediaDownloadTokenQueryVariables;

export const fetchExternalShareMediaToken = (contentId: string, isUsingPublicLinkInfo?: boolean) =>
	getApolloClient().query<ExternalMediaTokenQuery, ExternalMediaTokenQueryVariables>({
		query: isUsingPublicLinkInfo
			? PublicLinkMediaDownloadTokenQuery
			: ExternalShareMediaDownloadTokenQuery,
		variables: {
			contentId,
			publicLinkId: isUsingPublicLinkInfo ? contentId : undefined,
		},
		context: {
			allowOnExternalPage: true,
		},
		fetchPolicy: 'network-only',
	});

export const getUploadToken = (contentId: string) =>
	getApolloClient().query<MediaUploadTokenQueryT, MediaUploadTokenQueryVariables>({
		query: MediaUploadTokenQuery,
		variables: {
			contentId,
		},
		fetchPolicy: 'no-cache',
	});
