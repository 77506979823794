import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.convertToBulletList.title',
		defaultMessage: 'Convert to bulleted list',
		description:
			'Describes an option to use Atlassian Intelligence to reformat content currently selected by the user to a bulleted list',
	},
	description: {
		id: 'fabric.editor.ai.config.item.convertToBulletList.description',
		defaultMessage: 'Reformats content to a bulleted list',
		description: 'Description for the the Atlassian Intelligence "Convert to bulleted list".',
	},
});
