import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { Node as PMNode, Slice } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { convertProsemirrorToMarkdown } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';
import type { MentionMap } from '@atlassian/ai-model-io/utils/mention-map';
import type { ContentStatistics } from '@atlassian/ai-model-io/utils/prosemirror-content-statistics';
import { ErrorUtils } from '@atlassian/editor-ai-common/utils/errors';

import { addAnalytics } from '../analytics/utils';

import type { UpdateIdMap } from './config-items';
import { getContextSelectionScope } from './utils';

type SliceToMarkdownProps = {
	editorView: EditorView;
	/**
	 * @default markdown
	 */
	convertTo: 'markdown-plus' | 'markdown';
	existingIdMap?: IDMap;
	updateIdMap: UpdateIdMap;
	node?: undefined;
	slice: Slice;
	mentionMap?: MentionMap;
};
type NodeToMarkdownProps = {
	editorView: EditorView;
	/**
	 * @default markdown
	 */
	convertTo: 'markdown-plus' | 'markdown';
	existingIdMap?: IDMap;
	updateIdMap: UpdateIdMap;
	node: PMNode;
	slice?: undefined;
	mentionMap?: MentionMap;
};
type SliceOrNodeToMarkdownProps = SliceToMarkdownProps | NodeToMarkdownProps;

/**
 * Takes a prosemirror schema and (slice or node) and returns it as
 * markdown / markdown plus.
 * ```
 */
export function sliceOrNodeToMarkdown({
	slice,
	node,
	editorView,
	convertTo,
	existingIdMap,
	updateIdMap,
	mentionMap,
}: SliceOrNodeToMarkdownProps): {
	idMap: IDMap;
	markdown: string;
	contentStatistics?: ContentStatistics;
} {
	const fragment = (slice || node).content;
	try {
		// we track whether the context is a whole document or a partial selection
		const contextSelectionScope = getContextSelectionScope({
			editorView,
			slice,
			node,
		});
		if (convertTo === 'markdown-plus') {
			const { markdown, idMap, fallbackState, contentStatistics } = convertProsemirrorToMarkdown({
				editorView,
				fragment,
				featureToggles: {
					markdownPlus: true,
					markdownPlusExtensions: true,
					markdownPlusPanels: true,
					markdownPlusDecisions: true,
				},
				mentionMap: mentionMap,
				idMap: existingIdMap,
			});

			if (!!Object.keys(fallbackState).length) {
				const tr = addAnalytics({
					editorState: editorView.state,
					tr: editorView.state.tr,
					payload: {
						action: 'fallback',
						actionSubject: 'editorPluginAI',
						actionSubjectId: 'markdownSerializer',
						attributes: {
							fallbackState,
						},
						eventType: EVENT_TYPE.OPERATIONAL,
					},
				});

				tr.setMeta('addToHistory', false);

				editorView.dispatch(tr);
			}

			// This ID map is used to map the markdown back to the original ProseMirror
			// when we receive transformed content back from the llm.
			updateIdMap({
				idMap,
			});

			return {
				idMap,
				markdown,
				contentStatistics: { ...contentStatistics, contextSelectionScope },
			};
		}

		const { markdown, idMap, contentStatistics } = convertProsemirrorToMarkdown({
			editorView,
			fragment,
			featureToggles: {},
			mentionMap: mentionMap,
			idMap: existingIdMap,
		});

		return {
			idMap,
			markdown,
			contentStatistics: { ...contentStatistics, contextSelectionScope },
		};
	} catch (e) {
		const tr = addAnalytics({
			editorState: editorView.state,
			tr: editorView.state.tr,
			payload: {
				action: 'failed',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'markdownSerializer',
				attributes: {
					errorType: 'markdownSerializer',
					errorMessage: ErrorUtils.extractErrorMessage(e),
				},
				eventType: EVENT_TYPE.OPERATIONAL,
			},
		});

		editorView.dispatch(tr);

		return { idMap: {}, markdown: '', contentStatistics: undefined };
	}
}
