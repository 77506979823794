/* eslint-disable @repo/internal/react/no-class-components */

/* There is no good way to replace ErrorBoundary class components with a
 * functional component; currently no functional hook that can replace
 * 'componentDidCatch'.
 * Source: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 *
 * But I needed to get 'createAnalyticsEvent' via a hook hence
 * the wrapped class component.
 */

import React from 'react';

import type { IntlShape, MessageDescriptor } from 'react-intl-next';
import { useIntl } from 'react-intl-next';

import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import { ErrorUtils } from '@atlassian/editor-ai-common/utils/errors';
import { UnhandledErrorScreen } from '@atlassian/generative-ai-modal/screens/UnhandledError';

import { useFireAIAnalyticsEvent } from '../../../analytics/utils';
import type { MarkdownConversionError } from '../../../ui/MarkdownConversionError';
import { markdownConversionErrorName } from '../../../ui/MarkdownConversionError';

import errorMessages from './messages';

export const ModalRegionErrorBoundary = (props: {
	onCloseFallback: () => void;
	children: React.ReactNode;
}) => {
	const fireAIAnalyticsEvent = useFireAIAnalyticsEvent();
	const { formatMessage } = useIntl();

	return (
		<ExperienceApplicationErrorBoundaryClass
			// Ignored via go/ees005
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			fireAIAnalyticsEvent={fireAIAnalyticsEvent}
			formatMessage={formatMessage}
		/>
	);
};

interface ErrorBoundaryProps {
	formatMessage: IntlShape['formatMessage'];
	onCloseFallback: () => void;
	fireAIAnalyticsEvent: ReturnType<typeof useFireAIAnalyticsEvent>;
	children: React.ReactNode;
}

interface ErrorBoundaryState {
	errorCaptured: boolean;
	fallbackMessage?: string;
}

class ExperienceApplicationErrorBoundaryClass extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	private getFallbackMessage = (descriptor: MessageDescriptor) => {
		return this.props.formatMessage(descriptor);
	};

	state = {
		errorCaptured: false,
		fallbackMessage: this.getFallbackMessage(errorMessages.unhandledErrorMessage),
	};

	private shouldRecover() {
		return this.state.errorCaptured;
	}

	componentDidCatch(error: Error | MarkdownConversionError, errorInfo: React.ErrorInfo) {
		if (error.name === markdownConversionErrorName) {
			const errorHasTypeProperty = 'type' in error;
			const errorSubType = errorHasTypeProperty ? error.type : undefined;

			this.props.fireAIAnalyticsEvent({
				payload: {
					action: 'unhandledErrorCaught',
					actionSubject: 'editorPluginAI',
					actionSubjectId: 'experienceApplication',
					attributes: {
						componentStack: errorInfo.componentStack || '',
						errorType: 'markdownToProseMirrorError',
						errorSubType,
						errorMessage: ErrorUtils.extractErrorMessage(error),
					},
					eventType: EVENT_TYPE.OPERATIONAL,
				},
			});
			this.setState({
				errorCaptured: true,
				fallbackMessage: this.getFallbackMessage(errorMessages.markdownErrorMessage),
			});
		} else {
			this.props.fireAIAnalyticsEvent({
				payload: {
					action: 'unhandledErrorCaught',
					actionSubject: 'editorPluginAI',
					actionSubjectId: 'experienceApplication',
					attributes: {
						componentStack: errorInfo.componentStack || '',
						errorMessage: ErrorUtils.extractErrorMessage(error),
					},
					eventType: EVENT_TYPE.OPERATIONAL,
				},
			});
			this.setState({
				errorCaptured: true,
				fallbackMessage: this.getFallbackMessage(errorMessages.unhandledErrorMessage),
			});
		}
	}

	render() {
		if (this.shouldRecover()) {
			return (
				<UnhandledErrorScreen
					text={this.state.fallbackMessage}
					onCancel={this.props.onCloseFallback}
				/>
			);
		}
		return this.props.children;
	}
}
