import type { FC } from 'react';
import React from 'react';

import { Box } from '@atlaskit/primitives';
import { FlagsProvider } from '@atlaskit/flag';

import { DefaultPermsPublicLinksSettings } from '@confluence/public-links/entry-points/DefaultPermsPublicLinkSettings';

import { DefaultRolesView } from '../../space-roles/page/DefaultRolesView';

export const DefaultSettingsTabV2: FC = () => {
	return (
		<Box>
			<FlagsProvider>
				<DefaultRolesView />
			</FlagsProvider>
			<DefaultPermsPublicLinksSettings />
		</Box>
	);
};
