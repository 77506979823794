import type { VFC } from 'react';
import React from 'react';

import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { getMacroQueryVariables } from '@confluence/fabric-extension-queries';

import { LegacyMacroRendererShadow } from './LegacyMacroRendererShadow';

const THROTTLE_MACROS_QUERY_THRESHOLD = 8;

export const FabricLegacyMacrosServerLoader: VFC<ExtensionHandlerProps> = ({
	node,
	mode,
	contentId,
}) => {
	if (process.env.REACT_SSR) {
		if (!window['__LEGACY_MACRO_PARAMS__']) {
			window['__LEGACY_MACRO_PARAMS__'] = [];
		}

		// This loosely matches the logic in LegacyMacroRendererQuery that prevents from
		// overwhelming the BE when page contains a bunch of legacy macros. Threshold for
		// throttling is the same as in LegacyMacroRendererQuery.
		if (window['__LEGACY_MACRO_PARAMS__'].length < THROTTLE_MACROS_QUERY_THRESHOLD) {
			window['__LEGACY_MACRO_PARAMS__'].push(
				getMacroQueryVariables({
					node,
					mode,
					contentId,
				}),
			);
		}
	}

	return <LegacyMacroRendererShadow node={node} />;
};
