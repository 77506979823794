/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import React from 'react';

import { token } from '@atlaskit/tokens';

export const ErrorIllustration = () => {
	return (
		<svg
			width="42"
			height="53"
			viewBox="0 0 164 212"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0)">
				<path
					d="M0.649902 0H163.93V212H0.649902V0Z"
					fill={token('elevation.surface.overlay', 'white')}
					fillOpacity="0.01"
				/>
				<path
					d="M95.4299 74.1603L161.87 189.24C167.71 199.36 160.41 212 148.73 212H15.8499C4.16994 212 -3.13006 199.35 2.70994 189.24L69.1499 74.1603C74.9899 64.0403 89.5899 64.0403 95.4299 74.1603ZM87.8699 157.71L90.7499 113.36C91.0899 108.07 86.8899 103.58 81.5899 103.58C76.2799 103.58 72.0799 108.06 72.4299 113.36L75.3099 157.71C75.5299 161.02 78.2699 163.6 81.5899 163.6C84.8999 163.6 87.6499 161.02 87.8699 157.71ZM72.0299 181.25C72.0299 186.91 76.7899 191.35 82.4199 190.83C87.2699 190.38 91.1499 186.33 91.2499 181.46C91.3599 176.01 86.9399 171.52 81.5199 171.52C76.2899 171.51 72.0299 175.89 72.0299 181.25Z"
					fill="url(#paint0_linear)"
				/>
				<g opacity="0.6">
					<path
						opacity="0.6"
						d="M93.3498 27.1702L85.9398 0.450216C85.7698 -0.169784 84.8698 -0.129784 84.7498 0.500216L80.0898 25.6002L74.2898 24.5202C73.8498 24.4402 73.4698 24.8502 73.5898 25.2802L80.9998 52.0002C81.1698 52.6202 82.0698 52.5802 82.1898 51.9502L86.8498 26.8502L92.6498 27.9302C93.0998 28.0102 93.4698 27.6002 93.3498 27.1702Z"
						fill="#C1C7D0"
					/>
					<path
						opacity="0.6"
						d="M66.7699 41.8103L55.8999 33.2603C55.6799 33.0803 55.3699 33.3303 55.4899 33.5903L61.0199 45.0203L58.3599 46.3103C58.1799 46.4003 58.1599 46.6403 58.3099 46.7603L69.1799 55.3103C69.3999 55.4903 69.7099 55.2403 69.5899 54.9803L64.0599 43.5503L66.7199 42.2603C66.8899 42.1803 66.9199 41.9303 66.7699 41.8103Z"
						fill="#C1C7D0"
					/>
				</g>
				<g opacity="0.6">
					<g opacity="0.6">
						<path
							opacity="0.6"
							d="M102.78 56.7501C102.57 56.7501 102.37 56.6901 102.19 56.5601C101.81 56.2801 101.68 55.7801 101.86 55.3701C101.62 54.6001 99.57 52.5201 98.32 51.2601C95.35 48.2501 93.39 46.2601 94.48 44.7701C95.57 43.2901 98.06 44.5501 101.83 46.4701C103.15 47.1401 105.18 48.1701 106.28 48.5001C105.64 47.5501 104.04 45.9201 103 44.8701C100.03 41.8601 98.07 39.8701 99.16 38.3801C100.24 36.9001 102.74 38.1601 106.5 40.0801C107.82 40.7501 109.84 41.7801 110.95 42.1101C110.31 41.1601 108.71 39.5401 107.67 38.4901C104.7 35.4801 102.74 33.4901 103.83 32.0001C104.91 30.5201 107.4 31.7801 111.17 33.7001C112.75 34.5001 115.35 35.8301 116.15 35.8301C116.49 35.5401 117 35.5101 117.39 35.7901C117.84 36.1201 117.93 36.7401 117.61 37.1901C116.52 38.6701 114.04 37.4101 110.27 35.4901C108.95 34.8201 106.93 33.7901 105.83 33.4601C106.47 34.4101 108.07 36.0301 109.11 37.0801C112.08 40.0901 114.04 42.0801 112.95 43.5701C111.87 45.0501 109.38 43.7901 105.61 41.8701C104.29 41.2001 102.26 40.1701 101.16 39.8401C101.8 40.7901 103.4 42.4101 104.44 43.4701C107.41 46.4801 109.37 48.4701 108.28 49.9601C107.19 51.4401 104.7 50.1801 100.93 48.2601C99.61 47.5901 97.58 46.5501 96.48 46.2301C97.12 47.1801 98.72 48.8101 99.76 49.8601C102.73 52.8701 104.69 54.8601 103.6 56.3501C103.39 56.6101 103.09 56.7501 102.78 56.7501Z"
							fill="#B3BAC5"
						/>
					</g>
				</g>
				<g opacity="0.6">
					<path
						opacity="0.6"
						d="M108.34 71.0702C107.99 71.0702 107.65 70.8802 107.46 70.5502C106.42 68.6702 105.24 66.5302 106.55 65.3402C107.86 64.1402 109.89 65.5202 112.04 66.9702C112.91 67.5602 114.3 68.5002 114.97 68.6802C114.85 68.0002 114.04 66.5302 113.52 65.6102C112.26 63.3402 111.07 61.2002 112.38 60.0002C113.69 58.8002 115.72 60.1802 117.87 61.6302C118.83 62.2802 120.41 63.3502 120.97 63.3702C121.38 63.0702 121.95 63.1202 122.3 63.5002C122.67 63.9102 122.65 64.5402 122.24 64.9102C120.93 66.1102 118.9 64.7402 116.75 63.2802C115.88 62.6902 114.49 61.7502 113.82 61.5702C113.94 62.2502 114.75 63.7202 115.27 64.6402C116.53 66.9102 117.72 69.0502 116.41 70.2502C115.1 71.4502 113.07 70.0702 110.92 68.6202C110.05 68.0302 108.66 67.0902 107.99 66.9102C108.11 67.5902 108.92 69.0502 109.21 69.5802C109.48 70.0602 109.3 70.6702 108.82 70.9402C108.67 71.0302 108.5 71.0702 108.34 71.0702Z"
						fill="#B3BAC5"
					/>
				</g>
				<g opacity="0.6">
					<path
						opacity="0.6"
						d="M54.02 68.1797C53.8 68.1797 53.58 68.1397 53.35 68.0397C51.71 67.3497 52.11 64.9297 52.54 62.3697C52.71 61.3297 52.99 59.6697 52.87 58.9897C52.3 59.3797 51.31 60.7397 50.69 61.5897C49.16 63.6797 47.7 65.6597 46.07 64.9697C45.56 64.7597 45.32 64.1697 45.54 63.6597C45.74 63.1797 46.27 62.9397 46.75 63.0897C47.27 62.8797 48.4 61.3397 49.09 60.4097C50.62 58.3197 52.07 56.3297 53.71 57.0297C55.35 57.7197 54.95 60.1397 54.52 62.6997C54.35 63.7397 54.07 65.3997 54.19 66.0797C54.76 65.6897 55.75 64.3297 56.37 63.4797C57.9 61.3897 59.35 59.3997 60.99 60.0997C62.62 60.7897 62.23 63.1897 61.88 65.3097C61.79 65.8497 61.28 66.2197 60.73 66.1397C60.19 66.0497 59.81 65.5397 59.9 64.9897C60 64.3997 60.27 62.7497 60.16 62.0697C59.59 62.4597 58.6 63.8197 57.98 64.6697C56.65 66.4597 55.39 68.1797 54.02 68.1797Z"
						fill="#B3BAC5"
					/>
				</g>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="26.1692"
					y1="228.621"
					x2="138.408"
					y2="116.382"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#C1C7D0" />
					<stop offset="0.297" stopColor="#C6CBD4" stopOpacity="0.8812" />
					<stop offset="0.6301" stopColor="#D3D7DE" stopOpacity="0.748" />
					<stop offset="0.9795" stopColor="#E9EBEF" stopOpacity="0.6082" />
					<stop offset="1" stopColor="#EBECF0" stopOpacity="0.6" />
				</linearGradient>
				<clipPath id="clip0">
					<path d="M0.649902 0H163.93V212H0.649902V0Z" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
