/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PermissionDisplayType {
  ANONYMOUS = "ANONYMOUS",
  GROUP = "GROUP",
  GUEST_USER = "GUEST_USER",
  LICENSED_USER = "LICENSED_USER",
}

export enum SpacePermissionType {
  ADMINISTER_SPACE = "ADMINISTER_SPACE",
  ARCHIVE_PAGE = "ARCHIVE_PAGE",
  COMMENT = "COMMENT",
  CREATE_ATTACHMENT = "CREATE_ATTACHMENT",
  CREATE_EDIT_PAGE = "CREATE_EDIT_PAGE",
  EDIT_BLOG = "EDIT_BLOG",
  EXPORT_PAGE = "EXPORT_PAGE",
  EXPORT_SPACE = "EXPORT_SPACE",
  REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT",
  REMOVE_BLOG = "REMOVE_BLOG",
  REMOVE_COMMENT = "REMOVE_COMMENT",
  REMOVE_MAIL = "REMOVE_MAIL",
  REMOVE_OWN_CONTENT = "REMOVE_OWN_CONTENT",
  REMOVE_PAGE = "REMOVE_PAGE",
  SET_PAGE_PERMISSIONS = "SET_PAGE_PERMISSIONS",
  VIEW_SPACE = "VIEW_SPACE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpacePermissionsUsers
// ====================================================

export interface SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions_nodes_filteredPrincipalSubjectKey_user_profilePicture {
  path: string;
}

export interface SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions_nodes_filteredPrincipalSubjectKey_user {
  accountId: string | null;
  email: string | null;
  profilePicture: SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions_nodes_filteredPrincipalSubjectKey_user_profilePicture | null;
}

export interface SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions_nodes_filteredPrincipalSubjectKey {
  id: string | null;
  displayName: string | null;
  permissionDisplayType: PermissionDisplayType;
  user: SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions_nodes_filteredPrincipalSubjectKey_user | null;
}

export interface SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions_nodes {
  permissions: (SpacePermissionType | null)[] | null;
  filteredPrincipalSubjectKey: SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions_nodes_filteredPrincipalSubjectKey | null;
}

export interface SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions_pageInfo {
  hasNextPage: boolean | null;
  hasPreviousPage: boolean | null;
  endCursor: string | null;
  startCursor: string | null;
}

export interface SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions {
  nodes: (SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions_nodes | null)[] | null;
  pageInfo: SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions_pageInfo | null;
}

export interface SpacePermissionsUsers_spacePermissions {
  filteredSubjectsWithPermissions: SpacePermissionsUsers_spacePermissions_filteredSubjectsWithPermissions;
}

export interface SpacePermissionsUsers {
  spacePermissions: SpacePermissionsUsers_spacePermissions;
}

export interface SpacePermissionsUsersVariables {
  spaceKey: string;
  after?: string | null;
}
