import React from 'react';
import { styled } from '@compiled/react';

import Checkbox from '@atlaskit/checkbox';

import { useChangeStager } from '@confluence/change-stager';

import type { SpacePermissionType } from '../graphql/__types__/SpacePermissionsPrincipalsMutation';

import type { TableDataType } from './PermissionsTable';
import type { StagedChanges } from './changeUtils';
import { decodeChangeKey, encodeChangeKey } from './changeUtils';

const makeCombinedRow = (
	item: TableDataType,
	changes: StagedChanges,
	allPermissions: SpacePermissionType[],
): StagedChanges => {
	const combinedRow = allPermissions.reduce((permsValues, perm) => {
		permsValues[perm] = item.permissions.includes(perm);
		return permsValues;
	}, {});
	if (!changes) return combinedRow;

	Object.entries(changes).forEach(([changeKey, value]) => {
		const { permission, id } = decodeChangeKey(changeKey);
		if (id === item.id) {
			combinedRow[permission] = value;
		}
	});
	return combinedRow;
};

const isWholeRowChecked = (
	item: TableDataType,
	changes: StagedChanges,
	allPermissions: SpacePermissionType[],
): boolean => {
	const combinedRow = makeCombinedRow(item, changes, allPermissions);
	return !Object.values(combinedRow).some((permValue) => permValue === false);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
});

export const AllCheckbox = ({
	item,
	allPermissions,
}: {
	item: TableDataType;
	allPermissions: SpacePermissionType[];
}) => {
	const { changes, setChanges } = useChangeStager();
	const isChecked = isWholeRowChecked(item, changes, allPermissions);

	const handleChange = () => {
		const newChanges = { ...changes };
		for (const permission of allPermissions) {
			newChanges[encodeChangeKey(permission, item.id)] = !isChecked;
		}
		setChanges(newChanges);
	};

	return (
		<CheckboxWrapper>
			<Checkbox testId={item.id} isChecked={isChecked} onChange={handleChange} />
		</CheckboxWrapper>
	);
};
