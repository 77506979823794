/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import { ReactNode } from 'react';

import { css, jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled

import Form from '@atlaskit/form';
import { Stack, Box, Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const form = css({
	padding: 0,
	margin: 0,
});

const divider = css({
	borderTop: `1px solid ${token('color.border')}`,
});

import { SubmitButton } from './SubmitButton/SubmitButton';

interface Props<FormValue extends object> {
	children: ReactNode;
	onSubmit: (value: FormValue) => void;
}

export function PromptBuilderForm<FormValue extends object>({
	children,
	onSubmit,
}: Props<FormValue>) {
	return (
		<Form<FormValue> onSubmit={onSubmit}>
			{({ formProps }) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<form {...formProps} css={form}>
					<Stack grow="fill">
						<Box
							paddingInlineStart="space.150"
							paddingInlineEnd="space.150"
							paddingBlockStart="space.100"
							paddingBlockEnd="space.200"
						>
							<Stack space="space.100">{children}</Stack>
						</Box>

						<div css={divider} />

						<Box padding="space.150">
							<Flex justifyContent="end">
								<SubmitButton />
							</Flex>
						</Box>
					</Stack>
				</form>
			)}
		</Form>
	);
}
