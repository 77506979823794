/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PermissionDisplayType {
  ANONYMOUS = "ANONYMOUS",
  GROUP = "GROUP",
  GUEST_USER = "GUEST_USER",
  LICENSED_USER = "LICENSED_USER",
}

export enum SpacePermissionType {
  ADMINISTER_SPACE = "ADMINISTER_SPACE",
  ARCHIVE_PAGE = "ARCHIVE_PAGE",
  COMMENT = "COMMENT",
  CREATE_ATTACHMENT = "CREATE_ATTACHMENT",
  CREATE_EDIT_PAGE = "CREATE_EDIT_PAGE",
  EDIT_BLOG = "EDIT_BLOG",
  EXPORT_PAGE = "EXPORT_PAGE",
  EXPORT_SPACE = "EXPORT_SPACE",
  REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT",
  REMOVE_BLOG = "REMOVE_BLOG",
  REMOVE_COMMENT = "REMOVE_COMMENT",
  REMOVE_MAIL = "REMOVE_MAIL",
  REMOVE_OWN_CONTENT = "REMOVE_OWN_CONTENT",
  REMOVE_PAGE = "REMOVE_PAGE",
  SET_PAGE_PERMISSIONS = "SET_PAGE_PERMISSIONS",
  VIEW_SPACE = "VIEW_SPACE",
}

export interface UpdateDefaultSpacePermissionsInput {
  permissionsToAdd: (SpacePermissionType | null)[];
  permissionsToRemove: (SpacePermissionType | null)[];
  subjectKeyInput: UpdatePermissionSubjectKeyInput;
}

export interface UpdatePermissionSubjectKeyInput {
  permissionDisplayType: PermissionDisplayType;
  subjectId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDefaultSpacePermissions
// ====================================================

export interface UpdateDefaultSpacePermissions_updateSpacePermissionDefaults_errors_extensions {
  errorType: string | null;
  statusCode: number | null;
}

export interface UpdateDefaultSpacePermissions_updateSpacePermissionDefaults_errors {
  message: string | null;
  extensions: (UpdateDefaultSpacePermissions_updateSpacePermissionDefaults_errors_extensions | null)[] | null;
}

export interface UpdateDefaultSpacePermissions_updateSpacePermissionDefaults {
  success: boolean;
  errors: UpdateDefaultSpacePermissions_updateSpacePermissionDefaults_errors[] | null;
}

export interface UpdateDefaultSpacePermissions {
  updateSpacePermissionDefaults: UpdateDefaultSpacePermissions_updateSpacePermissionDefaults | null;
}

export interface UpdateDefaultSpacePermissionsVariables {
  input: UpdateDefaultSpacePermissionsInput[];
}
