import React, { useMemo } from 'react';

import { AgentAvatar } from '@atlaskit/rovo-agent-components';
import type { AppearanceType } from '@atlaskit/tag';

import type { EditorAgent } from '../../utils/agents';

type AgentRefinementTagProps =
	| { elemBefore: React.ReactNode; appearance: AppearanceType }
	| undefined;

export function useAgentRefinementTagProps(agent?: EditorAgent) {
	const props = useMemo<AgentRefinementTagProps>(() => {
		if (agent) {
			return {
				elemBefore: (
					<span data-testId="command-palette-rovo-agent-icon">
						<AgentAvatar
							agentId={agent.id}
							agentNamedId={agent.externalConfigReference ?? agent.namedId}
							agentIdentityAccountId={agent.identityAccountId}
							isForgeAgent={agent.creatorType === 'FORGE'}
							forgeAgentIconUrl={agent.icon}
							label={agent.name}
							size="xsmall"
						/>
					</span>
				),
				appearance: 'rounded',
			};
		} else {
			return undefined;
		}
	}, [agent]);

	return props;
}
