import type { DocNode, NonNestableBlockContent } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { doc, bodiedExtension } from '@atlaskit/adf-utils/builders';
import { validator } from '@atlaskit/adf-utils/validator';
import type { ValidationError } from '@atlaskit/adf-utils/validatorTypes';
import type { Schema } from 'prosemirror-model';
import { z } from 'zod';
import { type ZodIssue, type ZodError, fromError } from 'zod-validation-error';

import { EXTENSION_NAMESPACE } from '../utils/constants';
import { fg } from '@atlaskit/platform-feature-flags';

export type ForgeConfigPayload = {
	config: Record<string, unknown>;
	/** @deprecated Replaced with `body` */
	insertBody?: DocNode;
	body?: DocNode;
	keepEditing?: boolean;
};

type ErrorMessage = {
	message: string;
	path: Array<string | number>;
};

export function validateForgeConfigPayload(
	data: unknown,
	{
		isBodiedExtension,
		isInitialInsertion,
		schema,
		useStrictParameterValidation,
	}: {
		isBodiedExtension: boolean;
		isInitialInsertion: boolean;
		schema?: Schema;
		useStrictParameterValidation?: boolean;
	},
): ForgeConfigPayload {
	if (!isObject(data)) {
		throw new ForgeConfigError('Must provide payload object', 'INVALID_PAYLOAD');
	}

	const { config, keepEditing } = data;
	const body = data.body || data.insertBody;

	if (!isObject(config)) {
		throw new ForgeConfigError('Invalid "config" provided. Expected object', 'INVALID_CONFIG');
	}

	if (useStrictParameterValidation) {
		try {
			validateMacroConfig(config);
		} catch (error) {
			throw new ForgeConfigError(processMacroConfigError(error), 'INVALID_CONFIG');
		}
	}

	// Only worth validating the body if it's actually going to be used
	const allowBodyUpdate = isInitialInsertion || fg('forge_macro_allow_edit_body_after_insert');
	if (body && allowBodyUpdate) {
		if (!isBodiedExtension) {
			throw new ForgeConfigError(
				'Cannot set "body" for non bodied extension',
				'INVALID_EXTENSION_TYPE',
			);
		}
		// We need to do basic validation of the body before we can safely do proper ADF validation
		if (!isDocNodeShape(body)) {
			throw new ForgeConfigError(
				'Invalid ADF "body" provided. Expected node of type doc with content',
				'INVALID_BODY',
			);
		}

		const { valid, errors } = validateAdfBody(body, schema);
		if (!valid || errors.length) {
			throw new ForgeConfigError(
				`Invalid ADF "body" provided.\n${errors.map((e) => `${e.code}: ${e.message}`).join('\n')}`,
				'INVALID_BODY',
			);
		}
	}
	return {
		config,
		keepEditing: Boolean(keepEditing),
		body: body && allowBodyUpdate ? (body as DocNode) : undefined,
	};
}

export function handleNodeUpdateError(error: unknown) {
	if (error instanceof Error && error.message.includes('Could not find node')) {
		throw new ForgeConfigError('Macro not found.', 'MACRO_NOT_FOUND');
	}
	throw error;
}

function isObject(obj: any): obj is Record<string, unknown> {
	return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

function isDocNodeShape(body: unknown): body is DocNodeShape {
	return isObject(body) && body.type === 'doc' && Array.isArray(body.content);
}

type DocNodeShape = Pick<DocNode, 'type'> & {
	content: unknown[];
};

export class ForgeConfigError extends Error {
	code: string;
	constructor(message: string, code: string) {
		super(prefixMessage(message));
		this.code = code;
	}
}

/**
 * Recursively finds the deepest "invalid_union" issue in the unionErrors array.
 * @param unionErrors The unionErrors array to search
 * @returns The error message and path of the deepest "invalid_union" issue
 */
const findDeepestUnionError = (unionErrors: Array<ZodError>): ErrorMessage => {
	// Check if there are nested union errors:
	const nestedIssues = unionErrors.filter((error) =>
		error?.issues.some((issue) => issue.code === 'invalid_union'),
	);
	if (nestedIssues.length) {
		// Grab the first issue (it doesn't matter for union errors which one we pick)
		const firstNestedIssue = nestedIssues[0].issues[0];
		if (firstNestedIssue.code === 'invalid_union') {
			return findDeepestUnionError(firstNestedIssue.unionErrors);
		}
	}

	// This works due to the way zod generates error messages for union types:
	// For a union type, zod generates one error message for each entry in the union
	// So we know that there will be at least one entry in the "issues" array
	return {
		message: unionErrors[0].issues[0].message,
		path: unionErrors[0].issues[0].path,
	};
};

/**
 * Builds a user-friendly error message from the zod validation issues.
 * @param issues The issues generated by the zod validation
 * @returns A list of user-friendly error messages
 */
function simplifyErrorMessage(issues: Array<ZodIssue>): string {
	// There are two problems with the way that zod creates error messages
	// 1. For nested entries, one error message is generated for each level of nesting.
	// 2. For union types, one error message is generated for each entry in the union.

	// This code attempts to improve the error message by:
	// 1. For nested entries, only using the error message for the deepest level of nesting.
	// 2. For union types, only using the error message for the first entry in the union.

	return issues
		.reduce((acc, issue) => {
			// We're only interested in changing the format for "invalid_union" issues
			if (issue.code === 'invalid_union') {
				const deepestError = findDeepestUnionError(issue.unionErrors as Array<ZodError>);
				acc.push({ message: deepestError.message, path: deepestError.path });
			} else {
				acc.push({ message: issue.message, path: issue.path });
			}
			return acc;
		}, [] as Array<ErrorMessage>)
		.reduce(
			(prev: string, { message, path }: ErrorMessage) =>
				prev + `${message} at "${path.join('.')}"\n`,
			'',
		);
}

/**
 * Processes the error thrown by the zod validation and returns a user-friendly error message.
 * @param error The error thrown by the zod validation
 * @returns A user-friendly error message
 */
function processMacroConfigError(error: unknown): string {
	const validationError = fromError(error, {
		messageBuilder: simplifyErrorMessage,
		prefix: null,
	});
	return validationError.message;
}

/**
 * Validates the macro config object using zod.
 * @param config The macro config object to validate
 * @throws If the config object is invalid
 * @returns void
 */
function validateMacroConfig(config: unknown) {
	// These are the rules for the zod schema:
	// 1. Allowed values are: string, number, boolean, array, object
	// 2. When arrays are used, all entries must contain the same data type
	// 3. Objects can be nested and contain any of the allowed values
	// 4. Arrays can not be nested

	// Define a recursive schema to handle nested objects and arrays
	const jsonSchema: z.ZodType<any> = z.lazy(() =>
		z.union([
			z.undefined(),
			z.string(),
			z.number(),
			z.boolean(),
			z.object({}).catchall(jsonSchema), // Objects can contain any of the allowed values, including nested objects
			z
				.array(
					z.union([z.string(), z.number(), z.boolean(), z.object({}).catchall(jsonSchema)]),
					// This is needed for checking the content of the array
				)
				.superRefine((value, ctx) => {
					// Check if all items in the array have the same type
					if (value.length > 0) {
						const typeOfFirstItem = typeof value[0];
						if (!value.every((item) => typeof item === typeOfFirstItem)) {
							ctx.addIssue({
								code: 'custom', // using "z.ZodIssueCode.custom" here breaks the "yarn workspace @af/mono-typecheck" task somehow ¯\_(ツ)_/¯
								message: 'All array items must be of the same type',
							});
						}
					}
				}),
		]),
	);
	const configSchema = z.record(jsonSchema);
	configSchema.parse(config);
}

function prefixMessage(message: string) {
	return `view.submit(): ${message}`;
}

function validateAdfBody(
	body: DocNodeShape,
	editorSchema?: Schema,
): {
	valid: boolean;
	errors: ValidationError[];
} {
	const schema = editorSchema || defaultSchema;
	const marks = Object.keys(schema.marks);
	const nodes = Object.keys(schema.nodes);
	const validate = validator(nodes, marks, {
		mode: 'strict',
	});

	const { content } = body;
	// Make sure we're validating in the context of the extension node, to prevent invalid nested extensions
	const extension = bodiedExtension({
		extensionType: EXTENSION_NAMESPACE,
		extensionKey: 'dummy-validate-only',
	})(...(content as NonNestableBlockContent[]));

	const errors: ValidationError[] = [];
	const { valid } = validate(doc(extension), (_entity, error) => {
		errors.push(error);
		return undefined;
	});
	return {
		valid,
		errors,
	};
}
