import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.suggest.title',
		defaultMessage: 'Suggest a title',
		description:
			'Describes an option to use Atlassian Intellgience to suggest an appropriate title for the content currently selected by the user',
	},
	description: {
		id: 'fabric.editor.ai.config.item.suggest.description',
		defaultMessage: 'Suggests a title for content you provide',
		description:
			'Describes "Suggest a Title" Atlassian Intelligence feature to suggest an appropriate title for the content currently selected by the user',
	},
});
