import React, { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { PrincipalRoleAssignment, SpaceRole } from '../../model/space-roles-types';

import type {
	NormalizedCheckboxesType,
	UpdatePermissionsAndPermissionKeysArgs,
	UpdateRoleAndPermissionKeysArgs,
} from './UpdatePrincipalModal';
import { RemovalFrictionModal } from './RemovalFrictionModal';

const i18n = defineMessages({
	cancel: {
		id: 'space-roles.update-principal-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for the update principal permissions modal',
	},
	save: {
		id: 'space-roles.update-principal-modal.save',
		defaultMessage: 'Save',
		description: 'Save button text for the update principal permissions modal',
	},
	remove: {
		id: 'space-roles.update-principal-modal.remove',
		defaultMessage: 'Remove',
		description: 'Remove button text for the update principal permissions modal',
	},
	done: {
		id: 'space-roles.update-principal-modal.done',
		defaultMessage: 'Done',
		description: 'Done button text for the update principal permissions modal',
	},
	saveSuccess: {
		id: 'space-roles.update-principal-modal.save-success',
		defaultMessage: 'Access updated',
		description: 'Success message when permissions are saved successfully',
	},
});

const getDeltas = ({
	initialCheckboxState,
	checkboxes,
}: {
	initialCheckboxState: Record<string, NormalizedCheckboxesType>;
	checkboxes: Record<string, NormalizedCheckboxesType>;
}) => {
	const permissionsToAdd: string[] = [];
	const permissionsToRemove: string[] = [];

	Object.entries(checkboxes).forEach(([permissionsGroup, checkboxGroup]) => {
		const initialCheckboxGroup = initialCheckboxState[permissionsGroup];
		checkboxGroup.group.checkboxes.forEach((checkbox) => {
			const initialCheckbox = initialCheckboxGroup.group.checkboxes.find(
				(initialCheckbox) => initialCheckbox.id === checkbox.id,
			);
			if (initialCheckbox?.isChecked && !checkbox.isChecked) {
				permissionsToRemove.push(checkbox.id);
			} else if (!initialCheckbox?.isChecked && checkbox.isChecked) {
				permissionsToAdd.push(checkbox.id);
			}
		});
	});

	return {
		permissionsToAdd,
		permissionsToRemove,
	};
};

const getButtonLabel = (areChangesStaged: boolean, isRemoving: boolean) => {
	if (isRemoving) {
		return i18n.remove;
	}
	return areChangesStaged ? i18n.save : i18n.done;
};

// This permission needs to exist for the user to remain in the space permissions table
const PREREQUISITE_PERMISSION = 'VIEWSPACE';

export const FooterButtons = ({
	principalRoleAssignment,
	initialPermissionKeys,
	currentPermissionKeys,
	deltaAttributes,
	areChangesStaged,
	onUpdateRole,
	onUpdatePermissions,
	refreshTableData,
	onClose,
	onCancel,
	isUpdating,
	isDefaultsView,
}: {
	principalRoleAssignment: PrincipalRoleAssignment;
	initialPermissionKeys: string[];
	currentPermissionKeys: string[];
	deltaAttributes: {
		roleInfo: {
			initialRole: SpaceRole | null | undefined;
			role: SpaceRole | null | undefined;
		};
		permissionsInfo: {
			initialNormalizedCheckboxes: Record<string, NormalizedCheckboxesType>;
			normalizedCheckboxes: Record<string, NormalizedCheckboxesType>;
		};
	};
	areChangesStaged: boolean;
	onUpdateRole: (args: UpdateRoleAndPermissionKeysArgs) => Promise<void>;
	onUpdatePermissions: (args: UpdatePermissionsAndPermissionKeysArgs) => Promise<void>;
	refreshTableData?: () => void | Promise<void>;
	onClose: () => void;
	onCancel: () => void;
	isUpdating: boolean;
	isDefaultsView: boolean;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { permissionsInfo, roleInfo } = deltaAttributes;

	const [showRemovalFrictionModal, setShowRemovalFrictionModal] = useState(false);

	const isRemoving =
		!currentPermissionKeys.includes(PREREQUISITE_PERMISSION) &&
		// This modal could be opened for an access class that persists in the table,
		// but has no permissions. In which case, it wouldn't be a removal.
		initialPermissionKeys.includes(PREREQUISITE_PERMISSION);

	const onCancelUpdates = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'cancel',
				source: 'spaceRoles-updatePrincipalModal',
			},
		}).fire();
		onCancel();
	}, [createAnalyticsEvent, onCancel]);

	const onConfirmUpdates = useCallback(
		async (shouldRefreshTableData: boolean) => {
			if (!areChangesStaged) {
				onCancel();
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						actionSubject: 'button',
						action: 'clicked',
						actionSubjectId: 'done',
						source: 'spaceRoles-updatePrincipalModal',
					},
				}).fire();
				return;
			}
			if (Boolean(roleInfo.role)) {
				await onUpdateRole({
					roleId: roleInfo.role?.id || '',
					roleDisplayName: roleInfo.role?.name || '',
					newPermissionKeys: currentPermissionKeys,
					principalId: principalRoleAssignment.principal.id,
				});
			} else {
				const deltas = [
					{
						principalInput: {
							principalId: principalRoleAssignment.principal.id,
							principalType: principalRoleAssignment.principal.type,
						},
						...getDeltas({
							initialCheckboxState: permissionsInfo.initialNormalizedCheckboxes,
							checkboxes: permissionsInfo.normalizedCheckboxes,
						}),
					},
				];
				await onUpdatePermissions({
					newPermissionKeys: currentPermissionKeys,
					subjectPermissionDeltasListV2: deltas,
				});
			}
			if (shouldRefreshTableData) {
				void refreshTableData?.();
			}
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					actionSubject: 'button',
					action: 'clicked',
					actionSubjectId: 'confirm',
					source: 'spaceRoles-updatePrincipalModal',
					attributes: {
						...deltaAttributes,
					},
				},
			}).fire();
			onClose();
		},
		[
			areChangesStaged,
			currentPermissionKeys,
			deltaAttributes,
			onCancel,
			onClose,
			onUpdatePermissions,
			onUpdateRole,
			permissionsInfo.initialNormalizedCheckboxes,
			permissionsInfo.normalizedCheckboxes,
			principalRoleAssignment.principal.id,
			roleInfo.role,
			refreshTableData,
			createAnalyticsEvent,
			principalRoleAssignment.principal.type,
		],
	);

	const onRemovalVerificationCancel = useCallback(() => {
		setShowRemovalFrictionModal(false);
	}, []);

	const onRemovalVerificationConfirm = useCallback(() => {
		setShowRemovalFrictionModal(false);
		void onConfirmUpdates(true);
	}, [onConfirmUpdates]);

	const onConfirmClick = useCallback(() => {
		if (isRemoving) {
			setShowRemovalFrictionModal(true);
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					actionSubject: 'button',
					action: 'clicked',
					actionSubjectId: 'remove',
					source: 'spaceRoles-updatePrincipalModal',
				},
			}).fire();
		} else {
			void onConfirmUpdates(false);
		}
	}, [isRemoving, onConfirmUpdates, createAnalyticsEvent]);

	return (
		<>
			<ButtonGroup>
				<Button onClick={onCancelUpdates} appearance="subtle">
					{formatMessage(i18n.cancel)}
				</Button>
				<Button
					isLoading={isUpdating}
					onClick={onConfirmClick}
					appearance={isRemoving ? 'danger' : 'primary'}
				>
					{formatMessage(getButtonLabel(areChangesStaged, isRemoving))}
				</Button>
			</ButtonGroup>
			{showRemovalFrictionModal && (
				<RemovalFrictionModal
					onCancel={onRemovalVerificationCancel}
					onConfirm={onRemovalVerificationConfirm}
					isDefaultsView={isDefaultsView}
				/>
			)}
		</>
	);
};
