/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import Icon, { type CustomGlyphProps, type IconProps } from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';

const CustomGlyph = (props: CustomGlyphProps) => (
	// Ignored via go/ees005
	// eslint-disable-next-line react/jsx-props-no-spreading
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path d="M14.5 14.5L17.5 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
		<path d="M9.5 14.5L6.5 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
		<path d="M9.5 9.5L6.5 6.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
		<path d="M14.5 9.5L17.5 6.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="square" />
		<path d="M12.0001 16V19" stroke="currentColor" strokeWidth="1.5" />
		<path d="M8.00012 12L5.00012 12" stroke="currentColor" strokeWidth="1.5" />
		<path d="M12.0001 8.00002L12.0001 4.99999" stroke="currentColor" strokeWidth="1.5" />
		<path d="M16.0002 12L19.0002 12" stroke="currentColor" strokeWidth="1.5" />
	</svg>
);

export const AtlassianIntelligenceLogo = (props: Omit<IconProps, 'glyph'>) => (
	// Ignored via go/ees005
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Icon glyph={CustomGlyph} primaryColor={token('color.icon.brand')} {...props} />
);
