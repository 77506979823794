import { useMemo } from 'react';

import { di } from 'react-magnetic-di';

import { ProfileClient } from '@atlaskit/profilecard';

const DIRECTORY_URL = '/gateway/api/directory/graphql';

let client: ProfileClient;

export const useProfileClient = () => {
	di(ProfileClient);

	const profileClient = useMemo(() => {
		if (!client) {
			// To support di
			client = new ProfileClient({
				url: DIRECTORY_URL,
				cacheSize: 1000,
				// 1 day
				cacheMaxAge: 1000 * 60 * 60 * 24,
			});
		}

		return client;
	}, []);

	return profileClient;
};
