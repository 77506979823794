import React, { useMemo } from 'react';

import type { IntlShape } from 'react-intl-next';
import { useIntl } from 'react-intl-next';

import { ACTION } from '@atlaskit/editor-common/analytics';
import { createUnifiedAnalyticsPayload } from '@atlassian/editor-ai-common/analytics/create-unified-analytics-payload';
import { DiscardScreen } from '@atlassian/generative-ai-modal/screens/Discard';

import { convertExperienceName } from '../../analytics/analytics-flow/analyticsFlow';
import { useAnalyticsFlowEventsQueue } from '../../analytics/analytics-flow/analyticsFlowUtils';
import { useFireAIAnalyticsEvent } from '../../analytics/utils';
import type { AIExperienceMachineContext, HistoryEvent } from '../get-ai-experience-service';
import { messages as experienceMessages } from '../messages';
import { useAIExperienceCommonDataContext } from '../useAIExperienceCommonData';

export const getDiscardMessage = ({
	formatMessage,
	experienceState,
}: {
	formatMessage: IntlShape['formatMessage'];
	experienceState: string | Record<string, string>;
}): string => {
	const discardPrompt = formatMessage(experienceMessages.discardMessagePrompt);
	const discardResponse = formatMessage(experienceMessages.discardMessageResponse);

	if (typeof experienceState === 'object' && 'user input state' in experienceState) {
		return discardPrompt;
	}

	if (
		experienceState === 'review state' ||
		(typeof experienceState === 'object' && 'loading state' in experienceState)
	) {
		return discardResponse;
	}

	return discardResponse;
};

export function DiscardScreenWithLogic({
	state,
	editCount,
	retryPromptCount,
	history,
	onceDiscard,
	channelId,
}: {
	channelId?: string;
	state: string | Record<string, string>;
	editCount: number;
	retryPromptCount: number;
	history: {
		positionFromEnd: number;
		entries: HistoryEvent[];
		totalSize: number;
		traversals: number;
	};
	onceDiscard?: AIExperienceMachineContext['onceDiscard'];
}) {
	const aiExperienceCommonData = useAIExperienceCommonDataContext();
	const { endExperience, sendToAIExperienceMachine, configItem, includeKnowledgeFromCurrentPage } =
		aiExperienceCommonData;

	const inputOutputDiffRatio = useMemo(() => {
		const lastHistoryEvent = history.entries[history.positionFromEnd];
		return history.entries.length === 0 ? '' : lastHistoryEvent.inputOutputDiffRatio;
	}, [history]);

	const { formatMessage } = useIntl();
	const fireAIAnalyticsEvent = useFireAIAnalyticsEvent();
	const analyticsFlow = useAnalyticsFlowEventsQueue({
		stepName: 'discard',
		attributes: {
			editCount,
			retryPromptCount,
			inputOutputDiffRatio,
			includeKnowledgeFromCurrentPage,
		},
	});

	return (
		<DiscardScreen
			text={getDiscardMessage({ formatMessage, experienceState: state })}
			onCancel={function () {
				sendToAIExperienceMachine({ type: 'disable discard' });
			}}
			onDiscard={function (): void {
				/**
				 * Introduced in EDF-1443 to track discard event confirmations
				 * separately to normal discard events which get tracked
				 * earlier in this file
				 */
				fireAIAnalyticsEvent({
					payload: createUnifiedAnalyticsPayload(
						ACTION.DISMISSED,
						channelId ?? analyticsFlow.getLastAiInteractionId(),
						convertExperienceName(configItem.key.toString()),
						false,
						analyticsFlow.getAiLifeCycleDynamicAttributes({ init: false }),
					),
				});

				if (onceDiscard) {
					sendToAIExperienceMachine({ type: 'disable discard' });
					onceDiscard();
				} else {
					endExperience();
				}
			}}
		/>
	);
}
