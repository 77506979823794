import React from 'react';

import type { NewCoreIconProps } from '@atlaskit/icon';
import SVG from '@atlaskit/icon/svg';

// TODO: Replace with @atlaskit/icon-lab/suggested-edit once it is available.
const SuggestedEditIcon = (props: NewCoreIconProps) => {
	const { color, label } = props;
	return (
		<SVG primaryColor={color} label={label}>
			<path
				d="M13.75 10L14.8661 8.88388C15.3543 8.39573 15.3543 7.60427 14.8661 7.11612L14.1339 6.38388C13.6457 5.89573 12.8543 5.89573 12.3661 6.38388L11.25 7.5M13.75 10L9.72904 14.021C9.57883 14.1712 9.39281 14.2806 9.18856 14.339L6 15.25L6.91102 12.0614C6.96937 11.8572 7.07883 11.6712 7.22904 11.521L11.25 7.5M13.75 10L11.25 7.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinejoin="round"
				transform="translate(4,4)"
			/>
			<path
				d="M4.75 0.75L5.87 3.63L8.75 4.75L5.87 5.87L4.75 8.75L3.63 5.87L0.75 4.75L3.63 3.63L4.75 0.75Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinejoin="round"
				transform="translate(4,4)"
			/>
		</SVG>
	);
};

export default SuggestedEditIcon;
