import type { MessageDescriptor } from 'react-intl-next';

import { messages } from '../../messages';

export const mapLoadingStatusToMessageTemplate = (
	messageTemplate: string | undefined,
): MessageDescriptor | undefined => {
	switch (messageTemplate) {
		case 'ANALYSING_QUERY':
			return messages.messageTemplateAnalysingQuery;
		case 'CONTENT_SEARCH':
			return messages.messageTemplateContentSearch;
		case 'PAGE_HYDRATION':
			return messages.messageTemplatePageHydration;
		case 'NEXT_BEST_TASK':
			return messages.messageTemplateNextBestTask;
		case 'WRITING':
			return messages.loadingTitle;
		default:
			return;
	}
};
