import { defineMessages } from 'react-intl-next';

export default defineMessages({
	cancelLabel: {
		id: 'fabric.editor.ai.screens.discard.cancelLabel',
		defaultMessage: 'Back',
		description:
			'Text for the "Back" button on the "Discard Warning" screen, used to prevent discarding changes and return to the previous screen',
	},
	discardLabel: {
		id: 'fabric.editor.ai.screens.discard.discardLabel',
		defaultMessage: 'Discard',
		description:
			'Text for the "Discard" button on the "Discard Warning" screen, used to confirm the user intention to discard unsaved changes, whether in relation to prompt input or a generated response. The term should convey the meaning of intentionally discarding or abandoning the modifications made or the generated content.',
	},
	warningIconLabel: {
		id: 'fabric.editor.ai.screens.discard.warningIconLabel',
		defaultMessage: 'Warning',
		description: 'Aria label for the warning icon on the "Discard Warning" screen',
	},
});
