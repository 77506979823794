import type { ReactNode } from 'react';
import React, { useState, useCallback, useContext, useMemo, useRef } from 'react';

import type { InviteeListOption } from '@atlassian/invite-people/types';
import type { DirectoryInvitePeopleSource } from '@atlassian/ptc-embeddable-directory';

import { useChoreographerAssets } from '@confluence/choreographer-services';
import { CORE_INVITES_EXPERIENCE, ExperienceTrackerContext } from '@confluence/experience-tracker';
import { useSessionData } from '@confluence/session-data';
import { TransparentErrorBoundary, Attribution } from '@confluence/error-boundary';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { useOnboardingTrackerAction } from '@confluence/experiment-onboarding-tracker/entry-points/hooks/useOnboardingTrackerAction';
import { ONBOARDING_TRACKER_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { fg } from '@confluence/feature-gating';

import type { CoreInvitesContextType, OpenCoreInvitesFunction } from './CoreInvitesContext';
import { CoreInvitesContext } from './CoreInvitesContext';
import { CoreInvitesLoadable } from './CoreInvitesLoadable';
import { CoreInvitesSource } from './constants';

type Cohort = 'control' | 'variation';
export type CoreInvitesProviderProps = {
	children?: ReactNode;
};

const noop = (): void => {
	return undefined;
};

export function CoreInvitesProvider({ children }: CoreInvitesProviderProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [initialInvitees, setInitialInvitees] = useState<InviteeListOption[]>([]);
	const [source, setSource] = useState<CoreInvitesSource | DirectoryInvitePeopleSource>(
		CoreInvitesSource.UNKNOWN,
	);
	const [showSpotlight, setShowSpotlight] = useState(false);
	const { markOnboardingTrackerItemCompleted } = useOnboardingTrackerAction();
	const stablePropsRef = useRef<{
		onInviteCompleted(): void;
		onModalClose(): void;
	}>({ onInviteCompleted: noop, onModalClose: noop });

	const { useChoreographer } = useChoreographerAssets();

	const contextualInviteNudgeChoreographer = useChoreographer('cc-contextual-invite-nudge', {
		start: () => setShowSpotlight(true),
		stop: () => setShowSpotlight(false),
	});

	const { orgId } = useSessionData();

	const handleModalOpen: OpenCoreInvitesFunction = useCallback(
		(
			touchPoint,
			{ onModalClosed = noop, onInviteCompleted = noop, getContextualInviteNudgeProps } = {},
			initialInvitees = [],
		) => {
			setInitialInvitees(initialInvitees);
			setSource(touchPoint || CoreInvitesSource.UNKNOWN);
			stablePropsRef.current.onModalClose = onModalClosed;
			stablePropsRef.current.onInviteCompleted = onInviteCompleted;
			setIsOpen(true);

			const shouldShow = getContextualInviteNudgeProps?.()?.showSpotlight;

			if (shouldShow) {
				void contextualInviteNudgeChoreographer.startMessage();
			}
		},
		[contextualInviteNudgeChoreographer, setInitialInvitees, setSource, setIsOpen],
	);

	const handleModalClose = useCallback(() => {
		setSource(CoreInvitesSource.UNKNOWN);
		// remember the current value for later invocation, since we're about to override it
		const onClosed = stablePropsRef.current.onModalClose;
		stablePropsRef.current.onModalClose = noop;
		stablePropsRef.current.onInviteCompleted = noop;
		setIsOpen(false);
		onClosed();
		void contextualInviteNudgeChoreographer.stopMessage();
	}, [contextualInviteNudgeChoreographer, setSource, setIsOpen]);

	const inviteCompletedHandler = useCallback(() => {
		stablePropsRef.current.onInviteCompleted();
		markOnboardingTrackerItemCompleted(ONBOARDING_TRACKER_STATE_KEYS.HAS_USER_INVITED);
	}, [stablePropsRef, markOnboardingTrackerItemCompleted]);

	const experienceTracker = useContext(ExperienceTrackerContext);
	const failExperienceOnError = useCallback(
		(error: Error) => {
			experienceTracker.stopOnError({
				name: CORE_INVITES_EXPERIENCE,
				error,
			});
		},
		[experienceTracker],
	);

	const coreInviteContext: CoreInvitesContextType = useMemo(
		() => ({
			openCoreInvites: handleModalOpen,
		}),
		[handleModalOpen],
	);

	const coreInvitesDriveMultiProductInvites =
		source != CoreInvitesSource.MENTION && fg('core_invites_drive_multi_product_invites_gate');
	const displayInfiSpotlight: boolean = true;

	const experimentalFeatures = useMemo(() => {
		return {
			inviteThirdPartyTeams: 'variation' as Cohort,
			coreInvitesDriveMultiProductInvites,
		};
	}, [coreInvitesDriveMultiProductInvites]);

	return (
		<CoreInvitesContext.Provider value={coreInviteContext}>
			{children}
			{isOpen && (
				<TransparentErrorBoundary attribution={Attribution.GROWTH} onError={failExperienceOnError}>
					<CoreInvitesLoadable
						initialInvitees={initialInvitees}
						onInviteCompletedHandler={inviteCompletedHandler}
						onSendHandler={handleModalClose}
						onCancelHandler={handleModalClose}
						source={source}
						orgId={orgId}
						experimentalFeatures={experimentalFeatures}
						displayInfiSpotlight={displayInfiSpotlight}
						showSpotlight={showSpotlight}
					/>
				</TransparentErrorBoundary>
			)}
		</CoreInvitesContext.Provider>
	);
}
