import { fg } from '@confluence/feature-gating';
import { expVal } from '@confluence/feature-experiments';

import { getNav4OptOutCache } from './useNav4OptOut';

/**
 * YOU SHOULD PROBABLY CALL `useIsNav4Enabled` INSTEAD OF THIS FUNCTION.
 * Please check with #cc-disco before using.
 */
// eslint-disable-next-line confluence-feature-gating/inline-usage
export const getIsNav4Enabled = () => {
	if (getNav4OptOutCache()) {
		return false;
	}

	return (
		fg('confluence_nav_4') ||
		expVal('confluence_jira_nav4_new_customers', 'willShowNav4', false) ||
		expVal('confluence_nav_4_free', 'isEnabled', false)
	);
};
