/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import { token } from '@atlaskit/tokens';

import { styleIconBackground } from '../../../prebuilt/assets/icons/styles';
import { Border } from '../border';

const Icon = ({ alt }: { alt: string }) => {
	return (
		<svg
			width="38"
			height="38"
			viewBox="0 0 38 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			css={styleIconBackground}
		>
			<title>{alt}</title>;
			<rect
				x="0.5"
				y="0.5"
				width="37"
				height="37"
				rx="1.5"
				stroke="url(#paint0_radial_2130_45344)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.17 19C24.17 18.4477 24.6178 18 25.17 18H27C27.5523 18 28 18.4477 28 19C28 19.5523 27.5523 20 27 20H25.17C24.6178 20 24.17 19.5523 24.17 19Z"
				fill={token('color.icon', '#44546F')}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19 10C19.5523 10 20 10.4477 20 11V12.83C20 13.3823 19.5523 13.83 19 13.83C18.4477 13.83 18 13.3823 18 12.83V11C18 10.4477 18.4477 10 19 10Z"
				fill={token('color.icon', '#44546F')}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 19C10 18.4477 10.4477 18 11 18H12.83C13.3823 18 13.83 18.4477 13.83 19C13.83 19.5523 13.3823 20 12.83 20H11C10.4477 20 10 19.5523 10 19Z"
				fill={token('color.icon', '#44546F')}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19 24.17C19.5523 24.17 20 24.6177 20 25.17V27C20 27.5523 19.5523 28 19 28C18.4477 28 18 27.5523 18 27V25.17C18 24.6177 18.4477 24.17 19 24.17Z"
				fill={token('color.icon', '#44546F')}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.2929 21.2929C21.6834 20.9024 22.3166 20.9024 22.7071 21.2929L26.7071 25.2929C27.0976 25.6834 27.0976 26.3166 26.7071 26.7071C26.3166 27.0976 25.6834 27.0976 25.2929 26.7071L21.2929 22.7071C20.9024 22.3166 20.9024 21.6834 21.2929 21.2929Z"
				fill={token('color.icon', '#44546F')}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.7071 11.2929C27.0976 11.6834 27.0976 12.3166 26.7071 12.7071L22.7071 16.7071C22.3166 17.0976 21.6834 17.0976 21.2929 16.7071C20.9024 16.3166 20.9024 15.6834 21.2929 15.2929L25.2929 11.2929C25.6834 10.9024 26.3166 10.9024 26.7071 11.2929Z"
				fill={token('color.icon', '#44546F')}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L11.2929 12.7071C10.9024 12.3166 10.9024 11.6834 11.2929 11.2929Z"
				fill={token('color.icon', '#44546F')}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.7071 21.2929C17.0976 21.6834 17.0976 22.3166 16.7071 22.7071L12.7071 26.7071C12.3166 27.0976 11.6834 27.0976 11.2929 26.7071C10.9024 26.3166 10.9024 25.6834 11.2929 25.2929L15.2929 21.2929C15.6834 20.9024 16.3166 20.9024 16.7071 21.2929Z"
				fill={token('color.icon', '#44546F')}
			/>
			<defs>
				<radialGradient
					id="paint0_radial_2130_45344"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(1 1.5) rotate(44.5937) scale(49.8523)"
				>
					<Border />
				</radialGradient>
			</defs>
		</svg>
	);
};

export default Icon;
