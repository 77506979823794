import { mapToAssistanceServiceProduct } from './assistance-service';

// Ignored via go/ees005
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type XProductHeader = { 'X-Product': string } | {};

// Ignored via go/ees005
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type XChannelIdHeader = { 'X-Channel-Id': 'editor' } | {};

/**
 * Only send the X-Product header if the request is to assistance-service
 * Exported for testing purposes
 */
export function getXProductHeader(product: string): XProductHeader {
	return {
		'X-Product': mapToAssistanceServiceProduct(product),
	};
}

export function getXChannelIdHeader(experienceId?: string): XChannelIdHeader {
	// Only send the X-Channel-Id header if the request is to Rovo (ai-mate)
	return experienceId === 'ai-mate' ? { 'X-Channel-Id': 'editor' } : {};
}
