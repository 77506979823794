import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

export interface PaginationContextType {
	currentPageCursor: string | null;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	nextPageCursor: string | null;
	cursorStack: (string | null)[];
	pushToCursorStack: () => void;
	popFromCursorStack: () => void;
	clearCursorStack: () => void;
	setNextPageCursor: (cursor: string | null) => void;
	setHasNextPage: (hasNextPage: boolean) => void;
	setHasPreviousPage: (hasPreviousPage: boolean) => void;
}

export const PaginationContext = createContext<PaginationContextType | undefined>(undefined);

export const PaginationProvider: React.FC = ({ children }) => {
	const [nextPageCursor, setNextPageCursor] = useState<string | null>(null);
	const [cursorStack, setCursorStack] = useState<(string | null)[]>([]);
	const [hasNextPage, setHasNextPage] = useState<boolean>(false);
	const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);

	const pushToCursorStack = useCallback(() => {
		setCursorStack((prevStack) => [...prevStack, nextPageCursor]);
	}, [nextPageCursor]);

	const popFromCursorStack = useCallback(() => {
		setCursorStack((prevStack) => prevStack.slice(0, -1));
	}, []);

	const clearCursorStack = useCallback(() => {
		setCursorStack([]);
	}, []);

	const providerValue = useMemo(
		() => ({
			currentPageCursor: cursorStack[cursorStack.length - 1] || null,
			hasNextPage,
			hasPreviousPage,
			nextPageCursor,
			cursorStack,
			pushToCursorStack,
			popFromCursorStack,
			clearCursorStack,
			setNextPageCursor,
			setHasNextPage,
			setHasPreviousPage,
		}),
		[
			cursorStack,
			hasNextPage,
			hasPreviousPage,
			nextPageCursor,
			pushToCursorStack,
			popFromCursorStack,
			clearCursorStack,
		],
	);

	return <PaginationContext.Provider value={providerValue}>{children}</PaginationContext.Provider>;
};

export const usePagination = () => {
	const context = useContext(PaginationContext);
	if (context === undefined) {
		throw new Error('usePagination must be used within a PaginationProvider');
	}
	return context;
};
