import { type Fragment } from '@atlaskit/editor-prosemirror/model';
import { getXChannelIdHeader, getXProductHeader } from '@atlassian/editor-ai-common/api/request';

import { type SelectionType } from '../config-items/config-items';
import { FAILURE_REASON } from '../experience-application/screens-with-logic/utils/errors';
import type {
	ConvoAIResponseRovoAction,
	EditorPluginAIPromptResponseMeta,
	EditorPluginAIProvider,
} from '../types';

import type { RequestOptions, ResponseObject } from './types';

type Callback = (event: ResponseObject) => void;
type StreamContentProcessor = (streamContent: string) => { markdown: string; fragment?: Fragment };
type IsComplete = () => boolean;

function parsedHasExpectedKey<Key extends string>(
	key: Key,
	parsed: unknown,
): parsed is { [key in Key]: string } {
	if (typeof parsed !== 'object' || parsed === null || !(key in parsed)) {
		return false;
	}

	return true;
}

// Ignored via go/ees005
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleRequestError(error: any): ResponseObject {
	const isErrorUnknown =
		error.name === 'AbortError' || error.cause === 'ConvoAIChannelCreationError';

	// This can be due to code crashes too, but falls back to network errors
	if (!isErrorUnknown) {
		// eslint-disable-next-line no-console
		console.error('Editor AI: startStreamingResponse()', error);
	}

	return {
		type: 'error',
		errorInfo: {
			failureReason: FAILURE_REASON.API_FAIL,
			statusCode: error.status,
			errorContent: error,
		},
	};
}

// For non 200 status codes
// See details https://hello.atlassian.net/wiki/spaces/CA3/pages/2485466879/Generative+AI+API+Specs#Error-codes
async function handleResponseNotOK({
	cacheKey,
	selection,
	response,
}: {
	cacheKey: string;
	selection?: string;
	response: Response;
}): Promise<ResponseObject> {
	if (response.status === 400) {
		try {
			// Errors with special handling
			// We have soft alignment with the BE that for inputs that are not suitable
			// for prompts -- we want to rely on openai providing a suitable message for
			// the user. And not have manually maintained guards/user feedback messages.
			// https://product-fabric.atlassian.net/wiki/spaces/EUXQ/pages/3573548013/Introducing+AI+Dev+Documentation+TODO+AIFOLLOWUP+audit
			const knownErrors = [
				'INPUT_TOO_SHORT_TO_SUMMARIZE',
				'INPUT_TOO_SHORT_TO_PROCESS',
				'INPUT_EXCEEDS_TOKEN_LIMIT',
			] as const;
			const { error } = await response.json();
			if (error) {
				if (typeof error === 'object' && 'key' in error && knownErrors.includes(error.key)) {
					if (error.key === 'INPUT_EXCEEDS_TOKEN_LIMIT') {
						return {
							type: 'error',
							errorInfo: {
								failureReason: FAILURE_REASON.TOKEN_LIMIT,
								statusCode: response.status,
								errorKey: error.key,
							},
						};
					}
					if (
						error.key === 'INPUT_TOO_SHORT_TO_SUMMARIZE' ||
						error.key === 'INPUT_TOO_SHORT_TO_PROCESS'
					) {
						return {
							type: 'error',
							errorInfo: {
								failureReason: FAILURE_REASON.INPUT_TOO_SHORT,
								statusCode: response.status,
								errorKey: error.key,
							},
						};
					}
				}
				return {
					type: 'error',
					errorInfo: {
						failureReason: FAILURE_REASON.API_FAIL,
						statusCode: response.status,
						errorContent: 'Unhandled error response received',
					},
				};
			}
		} catch (backendError) {
			return {
				type: 'error',
				errorInfo: {
					failureReason: FAILURE_REASON.API_FAIL,
					statusCode: response.status,
					errorContent: backendError as string,
				},
			};
		}
	}

	// The BE are unable to add the header due to constraints with the service proxy
	// There is a ticket to add this via feature request
	// https://hello.jira.atlassian.cloud/browse/SPSVC-28
	// For now we are just hard coding the value
	if (response.status === 429) {
		const retryAfter = response.headers.get('Retry-After') || '60';
		return {
			type: 'error',
			retryAfter: Number(retryAfter),
			errorInfo: {
				failureReason: FAILURE_REASON.RATE_LIMITED,
				statusCode: response.status,
			},
		};
	}

	// This status code is being used to indicate that the request was unable to be handled
	// due to the content not meeting the Acceptable Use Policy.
	if (response.status === 451) {
		return {
			type: 'error',
			cacheKey,
			modelInput: {
				selection,
			},
			errorInfo: {
				failureReason: FAILURE_REASON.AUP_VIOLATION,
				statusCode: 451,
			},
		};
	}

	return {
		type: 'error',
		errorInfo: {
			failureReason: FAILURE_REASON.API_FAIL,
			statusCode: response.status,
			errorContent: `unexpected response status: ${response.status}`,
		},
	};
}

function handleNoResponseBody(response: Response): ResponseObject {
	return {
		type: 'error',
		errorInfo: {
			failureReason: FAILURE_REASON.API_FAIL,
			statusCode: response.status,
			errorContent: 'response.body missing',
		},
	};
}

function handleLineNoGeneratedContent({
	cacheKey,
	selection,
	data,
}: {
	cacheKey: string;
	selection?: string;
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
}): ResponseObject {
	if (data.error?.statusCode === 429) {
		return {
			type: 'error',
			retryAfter: 60,
			errorInfo: {
				failureReason: FAILURE_REASON.RATE_LIMITED,
				statusCode: 429,
			},
		};
	}

	if (data.error?.key === 'RESPONSE_TOO_SIMILAR') {
		const inputOutputDiffRatio =
			parsedHasExpectedKey('meta', data) && parsedHasExpectedKey('inputOutputDiffRatio', data.meta)
				? data['meta']['inputOutputDiffRatio']
				: '';
		return {
			type: 'response too similar',
			cacheKey,
			modelInput: {
				selection,
			},
			inputOutputDiffRatio,
		};
	}

	return {
		type: 'error',
		errorInfo: {
			failureReason: FAILURE_REASON.API_FAIL,
		},
	};
}

function handleLineGeneratedContent({
	streamContentProcessor,
	partial,
	data,
}: {
	streamContentProcessor?: StreamContentProcessor;
	partial: LinePartial;
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
}): ResponseObject {
	partial.content += data['generatedContent'];
	if (
		parsedHasExpectedKey('meta', data) &&
		parsedHasExpectedKey('inputOutputDiffRatio', data.meta)
	) {
		partial.meta = {
			inputOutputDiffRatio: data['meta']['inputOutputDiffRatio'],
			loadingStatus: '',
		};
	}

	return {
		type: 'stream',
		loadingStatus: partial.meta.loadingStatus,
		markdown: partial.content,
		...streamContentProcessor?.(partial.content),
	};
}

// Ignored via go/ees005
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleLineConvoAITrace({ partial, data }: { partial: LinePartial; data: any }): void {
	const loadingStatus =
		parsedHasExpectedKey('message', data) && parsedHasExpectedKey('message_template', data.message)
			? data.message.message_template
			: '';
	partial.meta = {
		inputOutputDiffRatio: '',
		loadingStatus,
	};
}

// Ignored via go/ees005
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleLineConvoAIAnswerPart({ partial, data }: { partial: LinePartial; data: any }): void {
	partial.content += data.message.content;
	partial.meta = {
		inputOutputDiffRatio: '',
		loadingStatus: '',
	};
}

function handleLineConvoAIFinalResponse({
	cacheKey,
	selection,
	partial,
	data,
}: {
	cacheKey: string;
	selection?: string;
	partial: LinePartial;
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
}): ResponseObject | undefined {
	const { message } = data;

	// if response too similar, early break/return
	if (
		parsedHasExpectedKey('message_metadata', message.message) &&
		parsedHasExpectedKey('response_too_similar', message.message.message_metadata) &&
		message.message.message_metadata.response_too_similar
	) {
		return {
			type: 'response too similar',
			cacheKey,
			modelInput: {
				selection,
			},
			inputOutputDiffRatio: message.message?.message_metadata?.diff_ratio || '',
		};
	}

	partial.content = message.message.content;
	partial.rovoActions = message.message.actions || [];
	partial.meta = {
		inputOutputDiffRatio: message.message?.message_metadata?.diff_ratio || '',
		loadingStatus: '',
	};
}

function handleLineConvoAIError({
	cacheKey,
	selection,
	data,
}: {
	cacheKey: string;
	selection?: string;
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
}): ResponseObject {
	let statusCode;
	let guard = 'UNHANDLED_ERROR';
	let error;
	let retryAfter: number | undefined;

	if (parsedHasExpectedKey('message', data)) {
		// Get status code
		if (
			parsedHasExpectedKey('status_code', data.message) &&
			typeof data.message.status_code === 'number'
		) {
			statusCode = data.message.status_code;
		}

		// Get reason
		if (parsedHasExpectedKey('message_template', data.message)) {
			guard = data.message.message_template;
		}

		// Get error
		if (parsedHasExpectedKey('content', data.message)) {
			error = data.message.content;
		}

		// Get retryAfter
		if (
			parsedHasExpectedKey('headers', data) &&
			parsedHasExpectedKey('Retry-After', data.headers)
		) {
			retryAfter = Number(data.headers['Retry-After']);
		}
	}

	let failureReason = FAILURE_REASON.API_FAIL;

	if (['RATE_LIMIT', 'OPENAI_RATE_LIMIT_USER_ABUSE'].includes(guard) && retryAfter) {
		failureReason = FAILURE_REASON.RATE_LIMITED;
	} else {
		retryAfter = undefined;
	}

	if (guard === 'HIPAA_CONTENT_DETECTED') {
		failureReason = FAILURE_REASON.HIPAA_CONTENT;
	}

	if (guard === 'EXCEEDING_CONTEXT_LENGTH_ERROR') {
		failureReason = FAILURE_REASON.TOKEN_LIMIT;
	}

	if (guard === 'ACCEPTABLE_USE_VIOLATIONS') {
		return {
			type: 'error',
			cacheKey,
			modelInput: {
				selection: selection,
			},
			errorInfo: {
				failureReason: FAILURE_REASON.AUP_VIOLATION,
				statusCode,
				errorKey: guard,
				apiName: 'assistance-service',
				errorContent: error,
			},
		};
	}

	if (retryAfter) {
		return {
			type: 'error',
			retryAfter,
			errorInfo: {
				apiName: 'assistance-service',
				failureReason,
				statusCode,
				errorKey: guard,
				errorContent: error,
			},
		};
	}

	return {
		type: 'error',
		errorInfo: {
			apiName: 'assistance-service',
			failureReason,
			statusCode,
			errorKey: guard,
			errorContent: error,
		},
	};
}

function handleLineConvoAI({
	cacheKey,
	selection,
	partial,
	data,
}: {
	cacheKey: string;
	selection?: string;
	partial: LinePartial;
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
}): ResponseObject {
	if (parsedHasExpectedKey('type', data)) {
		switch (data.type) {
			case 'TRACE':
				handleLineConvoAITrace({ partial, data });
				break;
			case 'ANSWER_PART':
				handleLineConvoAIAnswerPart({ partial, data });
				break;
			case 'FINAL_RESPONSE':
				const error = handleLineConvoAIFinalResponse({ cacheKey, selection, partial, data });
				if (error) {
					return error;
				}
				break;
			case 'ERROR':
				return handleLineConvoAIError({ cacheKey, selection, data });
		}
	}
	// If there are any unexpected loading responses, we should return the current data
	return {
		type: 'stream',
		loadingStatus: partial.meta.loadingStatus,
		markdown: partial.content,
	};
}

function handleLine({
	cacheKey,
	selection,
	isConvoAI,
	streamContentProcessor,
	partial,
	data,
}: {
	cacheKey: string;
	selection?: string;
	isConvoAI?: boolean;
	streamContentProcessor?: StreamContentProcessor;
	partial: LinePartial;
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
}): ResponseObject {
	if (parsedHasExpectedKey('generatedContent', data)) {
		return handleLineGeneratedContent({ streamContentProcessor, partial, data });
	} else if (isConvoAI) {
		return handleLineConvoAI({ cacheKey, selection, partial, data });
	} else {
		// if not expected response from the API the we have an error
		return {
			type: 'error',
			errorInfo: { failureReason: FAILURE_REASON.API_FAIL },
		};
	}
}

function handleLoaded({
	cacheKey,
	selectionType,
	selection,
	streamContentProcessor,
	isComplete,
	partial,
}: {
	cacheKey: string;
	selectionType: SelectionType;
	selection?: string;
	streamContentProcessor?: StreamContentProcessor;
	isComplete?: IsComplete;
	partial: LinePartial;
}): ResponseObject {
	const rovoActions = partial.rovoActions || [];
	let markdown = partial.content;
	let processedResponse: ReturnType<StreamContentProcessor> = { markdown };

	if (rovoActions.length > 0) {
		const idealSuggestion = selectionType === 'range' ? 'replace' : 'insert';
		const action = rovoActions.find((x) => x.data.suggestion === idealSuggestion) ?? rovoActions[0];

		// There is a scenario where we are in selectionType === 'empty'
		// but AI returns replace. In this case, we override the replace
		// suggestion with insert.
		if (selectionType === 'empty' && action.data.suggestion === 'replace') {
			// This should also update the value by reference in rovoActions
			action.data.suggestion = 'insert';
		}
		// The opposite can happen too
		else if (selectionType === 'range' && action.data.suggestion === 'insert') {
			// This should also update the value by reference in rovoActions
			action.data.suggestion = 'replace';
		}

		markdown += `\n\n---\n\n${action.data.content}`;
	} else if (streamContentProcessor) {
		processedResponse = streamContentProcessor(partial.content);
	}

	if (isComplete) {
		const isCompleted = isComplete();
		if (!isCompleted) {
			return {
				type: 'stream',
				...processedResponse,
				loadingStatus: partial.meta?.loadingStatus,
			};
		}
	}

	return {
		type: 'complete',
		...processedResponse,
		cacheKey,
		modelInput: { selection },
		inputOutputDiffRatio: partial.meta?.inputOutputDiffRatio,
		rovoActions,
	};
}

interface LinePartial {
	type: 'markdown';
	content: string;
	meta: EditorPluginAIPromptResponseMeta;
	rovoActions: ConvoAIResponseRovoAction[];
}

async function* streamingBody({
	cacheKey,
	selectionType,
	selection,
	isConvoAI,
	streamContentProcessor,
	isComplete,
	response,
	body,
}: {
	cacheKey: string;
	selectionType: SelectionType;
	selection?: string;
	isConvoAI?: boolean;
	streamContentProcessor?: StreamContentProcessor;
	isComplete?: IsComplete;
	response: Response;
	body: ReadableStream<Uint8Array>;
}): AsyncGenerator<ResponseObject> {
	try {
		const reader = body.getReader();
		const decoder = new TextDecoder('utf-8');
		let buffer = '';
		let done = false;

		const partial: LinePartial = {
			type: 'markdown',
			content: '',
			meta: { inputOutputDiffRatio: '' },
			rovoActions: [],
		};

		while (!done) {
			const { value, done: doneReading } = await reader.read();
			done = doneReading;
			const chunkValue = decoder.decode(value);
			buffer = buffer + chunkValue;
			// Split the buffer by line breaks
			const lines = buffer.split('\n');
			// Process all complete lines, except for the last one (which might be incomplete)
			while (lines.length > 1) {
				// Ignored via go/ees005
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const line = lines.shift()!;
				const data = JSON.parse(line);

				if (data.generatedContent === null) {
					return yield handleLineNoGeneratedContent({ cacheKey, selection, data });
				}

				yield handleLine({
					cacheKey,
					selection,
					isConvoAI,
					streamContentProcessor,
					partial,
					data,
				});
			}
			// Keep the last (potentially incomplete) line in the buffer
			buffer = lines[0];
		}

		return yield handleLoaded({
			cacheKey,
			selectionType,
			selection,
			streamContentProcessor,
			isComplete,
			partial,
		});
	} catch (parsingError) {
		return yield {
			type: 'error',
			errorInfo: {
				failureReason: FAILURE_REASON.API_FAIL,
				statusCode: response.status,
				errorContent: parsingError as string,
			},
		};
	}
}

function makeEndpoint({ endpoint, channelId }: { endpoint: string; channelId?: string }) {
	let actualEndpoint = endpoint;

	// Using a stateful endpoint
	if (channelId) {
		actualEndpoint = `${endpoint}/${channelId}/message/stream`;
	}

	return actualEndpoint;
}

function makeHeaders({
	product,
	experienceId,
}: {
	product: EditorPluginAIProvider['product'];
	experienceId: string;
}): Record<string, string> {
	return {
		'Content-Type': 'application/json;charset=UTF-8',
		'X-Experience-Id': experienceId,
		...getXChannelIdHeader(experienceId),
		...getXProductHeader(product),
	};
}

export interface StartStreamingOptions {
	abortController: AbortController;
	cacheKey: string;
	selectionType: SelectionType;
	product: EditorPluginAIProvider['product'];
	getFetchCustomHeaders: EditorPluginAIProvider['getFetchCustomHeaders'];
	channelId?: string | undefined;
	callback: Callback;
	streamContentProcessor?: StreamContentProcessor;
	isComplete?: IsComplete;
	requestOptions: RequestOptions;
}

// experienceId and channelId are for convoai/assistance-service
export async function startStreaming(options: StartStreamingOptions) {
	const {
		abortController,
		cacheKey,
		selectionType,
		product,
		getFetchCustomHeaders,
		channelId,
		callback,
		streamContentProcessor,
		isComplete,
		requestOptions,
	} = options;

	if ('response' in requestOptions) {
		callback(requestOptions.response);
		return;
	}

	const { selection, isConvoAI, experienceId, endpoint, payload } = requestOptions;

	try {
		const actualEndpoint = makeEndpoint({ endpoint, channelId });
		const fetchInit: RequestInit = {
			method: 'POST',
			headers: makeHeaders({ product, experienceId }),
			body: payload,
			credentials: 'include',
			signal: abortController.signal,
			mode: 'cors',
		};
		const fetchCustomHeaders = getFetchCustomHeaders?.(endpoint, fetchInit) || {};
		const response = await fetch(actualEndpoint, {
			...fetchInit,
			headers: { ...fetchInit.headers, ...fetchCustomHeaders },
		});

		if (!response.ok) {
			return callback(await handleResponseNotOK({ cacheKey, selection, response }));
		}

		if (!response.body) {
			callback(handleNoResponseBody(response));
			return;
		}

		const streaming = streamingBody({
			cacheKey,
			selectionType,
			selection,
			isConvoAI,
			streamContentProcessor,
			isComplete,
			response,
			body: response.body,
		});
		for await (const item of streaming) {
			callback(item);
			// If we have a response other than stream (which means there is an error), we should stop the streaming
			if (item.type !== 'stream') {
				return;
			}
		}
		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		callback(handleRequestError(error));
	}
}
