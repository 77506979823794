import React, { useRef, useEffect } from 'react';

import { _useFireAIAnalyticsEvent } from './_useFireAIAnalyticsEvent';
import type { AnalyticsFlow } from './analyticsFlow';
import { createAnalyticsFlow } from './analyticsFlow';
import type { StepName, UserFlowStepAttributes } from './analyticsFlowTypes';

/*
 * create single AnalyticsFlow instance per component lifecycle
 * needs to be used in the parent component of the user flow
 * to get this instance in child components use useAnalyticsFlow
 */
export function useCreateAnalyticsFlow({
	invokeAttributes,
}: {
	// allowing partial here because mandatory attributes are added automatically via createAnalyticsFlow
	invokeAttributes: Partial<UserFlowStepAttributes>;
}): AnalyticsFlow {
	const fireAIAnalyticsEvent = _useFireAIAnalyticsEvent();

	const analyticsFlowRef = React.useRef<AnalyticsFlow>();
	if (!analyticsFlowRef.current) {
		analyticsFlowRef.current = createAnalyticsFlow({
			fireAIAnalyticsEvent,
			invokeAttributes,
		});
	}
	return analyticsFlowRef.current;
}

const AnalyticsFlowContext = React.createContext<AnalyticsFlow | undefined>(undefined);

// provides AnalyticsFlow instance for child components
export const AnalyticsFlowContextProvider = AnalyticsFlowContext.Provider;

//
/**
 * Provides access to the AnalyticsFlow.
 *
 * @example
 * ```tsx
 * const analyticsFlow = useAnalyticsFlow();
 * ```
 */
export const useAnalyticsFlow = (): AnalyticsFlow => {
	const analyticsFlowInstance = React.useContext(AnalyticsFlowContext);
	if (!analyticsFlowInstance) {
		throw new Error(
			'No analyticsFlowInstance available in the context, make sure to have container wrapped with AnalyticsFlowContextProvider',
		);
	}
	return analyticsFlowInstance;
};

/**
 * Fires the queued userflow event from the previous screen on mount.
 *
 * And on dismount enqueues a userflow event to be fired on next screens mount.
 *
 * @example
 * ```tsx
 * export function DiscardScreen({}) {
 *   useAnalyticsFlowEventsQueue({ stepName: 'discard' });
 *   return <button>exit</button>
 * }
 * ```
 */
export function useAnalyticsFlowEventsQueue({
	stepName,
	attributes,
	needUpdatedArgs,
}: {
	stepName: StepName;
	attributes?: Partial<UserFlowStepAttributes>;
	/**
	 * Default false. If true, will use updated stepName/attributes stored in useRef
	 * - For Loading screen
	 *   - the attributes (modelIO.contextStatistics) will be updated async by streaming service by firing storeContextStatistics
	 *   - So if rendering happens first, the contextStatistics will be undefined.
	 */
	needUpdatedArgs?: boolean;
}) {
	const analyticsFlow = useAnalyticsFlow();
	const argsRef = useRef<{
		stepName: undefined | StepName;
		attributes: undefined | Partial<UserFlowStepAttributes>;
	}>({ stepName: undefined, attributes: undefined });

	useEffect(() => {
		argsRef.current = { stepName, attributes };
	});

	React.useEffect(() => {
		analyticsFlow.fireQueuedStep(stepName);
		return () => {
			if (needUpdatedArgs && argsRef.current.stepName) {
				analyticsFlow.enqueueStep({
					stepName: argsRef.current.stepName,
					attributes: argsRef.current.attributes,
				});
			} else {
				analyticsFlow.enqueueStep({
					stepName,
					attributes,
				});
			}
		};
		// expecting to enqueue step only once on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return analyticsFlow;
}
