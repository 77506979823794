import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIntl } from 'react-intl-next';

import { ACTION, EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import { Fragment } from '@atlaskit/editor-prosemirror/model';
import { fg } from '@atlaskit/platform-feature-flags';
import { Text } from '@atlaskit/primitives';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { ProseMirrorContentStatistics } from '@atlassian/ai-model-io/utils/prosemirror-content-statistics';
import { stripMatchingOuterQuotes } from '@atlassian/ai-model-io/utils/strip-matching-outer-quotes';
import { createUnifiedAnalyticsPayload } from '@atlassian/editor-ai-common/analytics/create-unified-analytics-payload';
import { copyPMFragmentToClipboard } from '@atlassian/editor-ai-common/utils/clipboard';
import { ErrorUtils } from '@atlassian/editor-ai-common/utils/errors';
import {
	PreviewScreen,
	type PreviewButton,
	type PreviewSecondaryActionButton,
	type RefineDropdownItem,
} from '@atlassian/generative-ai-modal/screens/Preview';

import { convertExperienceName } from '../../analytics/analytics-flow/analyticsFlow';
import type { ModelIO } from '../../analytics/analytics-flow/analyticsFlowTypes';
import { useAnalyticsFlow } from '../../analytics/analytics-flow/analyticsFlowUtils';
import type {
	CopyButtonClickAEP,
	EditPromptClickAEP,
	ResponseFeedbackButtonClickAEP,
	RetryPromptButtonClickAEP,
} from '../../analytics/types';
import { useFireAIAnalyticsEvent } from '../../analytics/utils';
import {
	getAllEditorPluginAIConfigItems,
	getEditorPluginAIConfigItemActions,
	type EditorPluginAIConfigItem,
	type EditorPluginAIConfigItemAgentAction,
	type EditorPluginAIConfigItemGenericChatAction,
	type EditorPluginAIConfigItemMarkdownAction,
} from '../../config-items/config-items';
import type { BackendModel } from '../../provider/prompt-requests/types';
import type {
	ConvoAIResponseRovoAction,
	FeedbackMetadata,
	PluginFeedbackMetadata,
	RovoPublish,
} from '../../types';
import { PreviewEditorWrapper } from '../../ui/components/PreviewEditorWrapper/PreviewEditorWrapper';
import { convertPMFragmentToPreview } from '../../ui/convert-markdown-to-preview';
import { isConfluenceTitleToolbarSuggestTitle } from '../../utils/confluence-suggest-title/is-title-toolbar-suggest-title';
import type { AIExperienceMachineContext } from '../get-ai-experience-service';
import { getPMFragmentWithFallback } from '../get-pm-fragment-with-fallback';
import { messages as experienceMessages } from '../messages';
import { useAIExperienceCommonDataContext } from '../useAIExperienceCommonData';
import { useOutsideClickHandler } from '../useOutsideClickHandler';

import { shouldDiscardEndExperienceImmediately } from './utils/discard-handling';
import { FAILURE_REASON } from './utils/errors';
import { getHasAcceptableUseWarning } from './utils/get-has-acceptable-use-warning';
import { useAIUsageDisclaimer } from './utils/use-ai-usage-disclaimer';
import { useLocalizedLatestPrompt } from './utils/useLocalizedLatestPrompt';
import { usePromptStatistics } from './utils/usePromptStatistics';

type PreviewScreenWithLogicProps = {
	aiExperienceMachineContext: AIExperienceMachineContext;
	isInputActive: boolean;
	backendModel?: BackendModel;
	rovoAction?: ConvoAIResponseRovoAction['data'];
	rovoPublish?: RovoPublish;
};

export function PreviewScreenWithLogic({
	aiExperienceMachineContext,
	isInputActive,
	backendModel,
	rovoAction,
	rovoPublish,
}: PreviewScreenWithLogicProps) {
	const { formatMessage, locale } = useIntl();

	const {
		isEmpty,
		parentPresetPromptLabel,
		latestPrompt,
		markdown: actualMarkdown,
		configItem,
		userInput: actualUserInput,
		userADFInput: actualUserADFInput,
		editCount,
		retryPromptCount,
		refinementCount,
		idMap,
		responseHistory: history,
		contextStatistics,
		promptTrigger,
		channelId,
	} = aiExperienceMachineContext;

	const aiExperienceCommonData = useAIExperienceCommonDataContext();
	const {
		appearance,
		editorPluginAIProvider,
		editorView,
		endExperience,
		positions,
		providerFactory,
		sendToAIExperienceMachine,
		lastTriggeredFrom,
		modalRef,
		triggeredFor,
		includeKnowledgeFromCurrentPage,
	} = aiExperienceCommonData;

	const disclaimer = useAIUsageDisclaimer({ editorPluginAIProvider });

	/**
	 * Short title for the submenu if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	const childShortTitle =
		parentPresetPromptLabel && configItem.nestingConfig
			? formatMessage(configItem.nestingConfig.shortTitle)
			: '';

	const userInput = actualUserInput || '';
	const userADFInput = actualUserADFInput;

	const schema = editorView.state.schema;
	const product = editorPluginAIProvider.product;
	/**
	 * Please check with Ethan/PM upon removing onExperienceEvent
	 * calls, to confirm it is no longer used in any ongoing experiments.
	 */
	useEffect(() => {
		editorPluginAIProvider.onExperienceEvent?.REVIEW_STATE_ENTERED();
	}, [editorPluginAIProvider]);
	const clearInputRef = useRef<() => boolean>();

	const lastHistoryEvent = history.entries[history.positionFromEnd];
	const responseTooSimilar = lastHistoryEvent.responseTooSimilar;
	const inputOutputDiffRatio = lastHistoryEvent.inputOutputDiffRatio;

	/**
	 * This should not be treated as a fact we are attempting
	 * to match as many responses with acceptable use warnings
	 * however, we may get false positives or false negatives
	 *
	 * TODO POST EAP
	 * Modify 'getHasAcceptableUseWarning' when the backend is
	 * able to return acceptable-use-warning flags to the frontend
	 */
	let markdown: string = actualMarkdown || '';
	const hasAcceptableUseWarning = getHasAcceptableUseWarning({
		markdown,
		locale,
	});
	const fireAIAnalyticsEvent = useFireAIAnalyticsEvent();
	const analyticsFlow = useAnalyticsFlow();
	const enqueuedPreviewStep = useRef<boolean>(false);
	const errorReported = React.useRef<boolean>(false);

	if (markdown && isConfluenceTitleToolbarSuggestTitle({ configItem, lastTriggeredFrom })) {
		markdown = stripMatchingOuterQuotes(markdown);
	}

	const pmFragmentPreviewData = getPMFragmentWithFallback({
		markdown,
		schema,
		source: 'preview',
		fireAIAnalyticsEvent: (event) => {
			if (!errorReported.current) {
				errorReported.current = true;
				fireAIAnalyticsEvent(event);
			}
		},
		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		idMap: idMap!,
	});
	const pmFragment = pmFragmentPreviewData?.pmFragment || Fragment.empty;
	const [updatedFragment, setUpdatedFragment] = useState(pmFragment);
	const onFragmentChange = React.useCallback(
		(fragment: Fragment) => {
			setUpdatedFragment(fragment);
		},
		[setUpdatedFragment],
	);
	const preview = getPreviewElement(pmFragment);

	useOutsideClickHandler({
		modalRef,
		userInput,
		onCancel: () => sendToAIExperienceMachine({ type: 'enable discard' }),
		onDiscard: () => sendToAIExperienceMachine({ type: 'enable discard' }),
	});

	/**
	 * gets preview JSX element based on fragment
	 */
	function getPreviewElement(pmFragment: Fragment): JSX.Element {
		if (responseTooSimilar) {
			const responseTooSimilarMessage = formatMessage(experienceMessages.responseTooSimilarMessage);
			return <Text as="p">{responseTooSimilarMessage}</Text>;
		}

		if (editorExperiment('platform_editor_ai_edit_response_in_preview', true, { exposure: true })) {
			return (
				<PreviewEditorWrapper
					schema={schema}
					pmFragment={pmFragment}
					PromptEditor={editorPluginAIProvider.PromptEditor}
					onFragmentChange={onFragmentChange}
				/>
			);
		} else {
			return convertPMFragmentToPreview({
				schema,
				pmFragment,
				showTelepointer: false,
				providerFactory,
			});
		}
	}

	// preset prompt or base generate / interrogation
	const localizedLatestPrompt = useLocalizedLatestPrompt(latestPrompt);
	const promptStatistics = usePromptStatistics(localizedLatestPrompt);

	useEffect(() => {
		analyticsFlow.fireQueuedStep('preview');

		let modelIO: ModelIO | undefined;
		if (pmFragmentPreviewData) {
			const stats = new ProseMirrorContentStatistics(pmFragment);
			const contentStatistics = stats.collectStatistics();

			modelIO = {
				selectionSize: lastHistoryEvent.modelInput.selection?.length,
				nodesConvertedToHTMLInput: idMap ? Object.keys(idMap).length : undefined,
				outputHTMLConvertedToNodes: pmFragmentPreviewData.htmlConvertedToNodes,
				outputHTMLConvertedToFallbackNodes: pmFragmentPreviewData.htmlConvertedToFallbackNodes,
				isTextFallbackUsed: pmFragmentPreviewData.isTextFallbackUsed,
				previewNodeCounts: contentStatistics.nodeCounts,
				previewMarkCounts: contentStatistics.markCounts,
				previewTotalCharacterCounts: contentStatistics.totalCharacterCount,
				userInputCharacterCount: promptStatistics?.totalCharacterCount,
				userInputNodeCounts: promptStatistics?.nodeCounts,
				userInputLinksCount: promptStatistics?.linksCount,
				userInputMentionsCount: promptStatistics?.mentionsCount,
			};
		}

		analyticsFlow.addAttributes({
			editCount,
			responseTooSimilar,
			modelIO: {
				contextStatistics,
				...modelIO,
			},
			inputOutputDiffRatio,
		});

		return () => {
			// We only want to enqueue the preview step if it hasn't been enqueued
			// already. This is to handle the case where the user clicks on an
			// ai action button where we manually enqueue the analytics event,
			// and then seperately fire a complete step.

			if (enqueuedPreviewStep.current === false) {
				analyticsFlow.enqueueStep({
					stepName: 'preview',
					attributes: undefined,
				});
			}
		};
		// expecting to enqueue step only once on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fireDismissEvent = useCallback(() => {
		// Introduced in EDF-1443 to track Retry events as AI response dismissal
		fireAIAnalyticsEvent({
			payload: createUnifiedAnalyticsPayload(
				ACTION.DISMISSED,
				channelId ?? analyticsFlow.getLastAiInteractionId(),
				convertExperienceName(configItem.key.toString()),
				false,
				analyticsFlow.getAiLifeCycleDynamicAttributes({ init: false }),
			),
		});
	}, [analyticsFlow, channelId, configItem.key, fireAIAnalyticsEvent]);

	const fireResponseFeedbackEvent = (
		sentiment: FeedbackMetadata['sentiment'],
		productSuccessState: ResponseFeedbackButtonClickAEP['attributes']['productSuccessStatus'],
	) => {
		fireAIAnalyticsEvent({
			payload: {
				action: 'click',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'responseFeedbackButton',
				attributes: {
					sentiment,
					hasAcceptableUseWarning,
					appearance,
					product,
					configItemTitle: configItem.agent?.name || configItem.key,
					lastTriggeredFrom,
					productSuccessStatus: productSuccessState,
					inputOutputDiffRatio,
					retryPromptCount,
					responseTooSimilar,
					aiInteractionID: analyticsFlow.getLastAiInteractionId(),
				},
				eventType: EVENT_TYPE.UI,
			} as ResponseFeedbackButtonClickAEP,
		});

		fireAIAnalyticsEvent({
			payload: createUnifiedAnalyticsPayload(
				ACTION.SUBMITTED,
				channelId ?? analyticsFlow.getLastAiInteractionId(),
				convertExperienceName(configItem.key.toString()),
				false,
				{
					aiFeedbackResult: sentiment === 'good' ? 'up' : 'down',
					...analyticsFlow.getAiLifeCycleDynamicAttributes({ init: false }),
				},
			),
		});
	};

	const handleFiringFeedbackEvent = async (feedbackMetadata: FeedbackMetadata) => {
		analyticsFlow.addAttributes({
			userFeedbackSentiment: feedbackMetadata.sentiment,
		});

		// we need to update the state machine to reflect the user feedback
		sendToAIExperienceMachine({
			type: 'feedback submitted',
			sentiment: feedbackMetadata.sentiment as 'good' | 'bad',
		});

		// handleFeedbackSubmission is intended to be implemented
		// by all products -- there will be a period where products won't have
		// it implemented (and will be relying on THUMBS_UP/THUMBS_DOWN)
		// in these cases -- we don't know the product response status
		if (!editorPluginAIProvider.handleFeedbackSubmission) {
			fireResponseFeedbackEvent(feedbackMetadata.sentiment, 'unknown');
			return;
		} else {
			try {
				const productResponse =
					await editorPluginAIProvider.handleFeedbackSubmission(feedbackMetadata);
				fireResponseFeedbackEvent(feedbackMetadata.sentiment, productResponse.status);
			} catch {
				fireResponseFeedbackEvent(feedbackMetadata.sentiment, 'failed');
			}
		}
	};

	const { advancedPromptValue } = latestPrompt ?? {};
	const onEditPromptClick = useCallback(() => {
		const { userInput, userADFInput, presetTitle } = localizedLatestPrompt;

		analyticsFlow.addAttributes({
			actionTaken: 'edit-prompt',
		});

		fireAIAnalyticsEvent({
			payload: {
				action: 'click',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'editPromptButton',
				attributes: {
					experienceType: convertExperienceName(configItem.key.toString()),
					editCount,
				},
				eventType: EVENT_TYPE.UI,
			} as EditPromptClickAEP,
		});

		fireDismissEvent();

		sendToAIExperienceMachine({
			type: 'edit prompt',
			userInput,
			userADFInput,
			advancedPromptValue,
			presetTitle,
		});
	}, [
		configItem.key,
		localizedLatestPrompt,
		analyticsFlow,
		fireAIAnalyticsEvent,
		editCount,
		fireDismissEvent,
		sendToAIExperienceMachine,
		advancedPromptValue,
	]);

	const discardWithoutWarning = useCallback(() => {
		if (fg('platform_editor_ai_instant_discard_4_insta_prompts')) {
			if (shouldDiscardEndExperienceImmediately(promptTrigger, history)) {
				endExperience();
				return;
			}
		}
		const { presetTitle } = localizedLatestPrompt;
		sendToAIExperienceMachine({
			type: 'edit prompt',
			userInput: '',
			userADFInput: undefined,
			presetTitle,
		});
	}, [endExperience, history, localizedLatestPrompt, promptTrigger, sendToAIExperienceMachine]);

	const onBack = useCallback(() => {
		sendToAIExperienceMachine({
			type: 'enable discard',
			context: { onceDiscard: discardWithoutWarning },
		});
	}, [sendToAIExperienceMachine, discardWithoutWarning]);

	const aiExperienceMetadata = {
		hasAcceptableUseWarning,
		configItemTitle: configItem.agent?.name || convertExperienceName(configItem.key.toString()),
		lastTriggeredFrom,
		inputOutputDiffRatio,
		backendModel,
	};

	const getAIExperience: PluginFeedbackMetadata['getAIExperience'] = (
		hasUserConsent?: boolean,
	) => ({
		...aiExperienceMetadata,
		...(hasUserConsent && {
			userPrompt: localizedLatestPrompt.userInput,
			markdownResponse: markdown,
		}),
	});

	React.useEffect(() => {
		/**
		 * When userInput is empty, we need to clear interrogate editor
		 *  as setting empty userInput as defaultValue won't clear editor.
		 */
		if (!userInput) {
			clearInputRef.current?.();
		}
	}, [userInput]);

	const onInputChange = React.useCallback(
		(inputValue: string) => {
			sendToAIExperienceMachine({
				type: 'update user input',
				input: inputValue,
			});
		},
		[sendToAIExperienceMachine],
	);
	const onADFChange = React.useCallback(
		(inputValue: JSONDocNode) => {
			sendToAIExperienceMachine({
				type: 'update user input adf',
				adf: inputValue,
			});
		},
		[sendToAIExperienceMachine],
	);

	const disableInterrogation =
		configItem.disableInterrogation?.() ||
		editorPluginAIProvider.disableInterrogation ||
		history.positionFromEnd > 0;

	const onCancelClick = useCallback(() => {
		if (userInput) {
			fireDismissEvent();
			sendToAIExperienceMachine({ type: 'cancel refinement' });
		} else {
			fireDismissEvent();
			discardWithoutWarning();
		}
	}, [userInput, discardWithoutWarning, fireDismissEvent, sendToAIExperienceMachine]);

	const onRetryPromptClick = useCallback(() => {
		fireAIAnalyticsEvent({
			payload: {
				action: 'click',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'retryPromptButton',
				attributes: {
					experienceType: convertExperienceName(configItem.key.toString()),
				},
				eventType: EVENT_TYPE.UI,
			} as RetryPromptButtonClickAEP,
		});

		fireDismissEvent();

		sendToAIExperienceMachine({
			type: 'retry prompt',
			input: localizedLatestPrompt.userInput,
			adf: localizedLatestPrompt.userADFInput,
		});
	}, [
		configItem.key,
		fireAIAnalyticsEvent,
		fireDismissEvent,
		localizedLatestPrompt.userADFInput,
		localizedLatestPrompt.userInput,
		sendToAIExperienceMachine,
	]);

	const [hasClicked, setHasClicked] = React.useState(false);

	const onActionButtonClick = useCallback(
		({
			runFn,
			analyticsTitle,
			preserveEditorSelectionOnComplete,
		}: {
			runFn: () => void;
			analyticsTitle: string;
			preserveEditorSelectionOnComplete?: boolean;
		}) => {
			// We add this condition to ensure we don't run the action multiple times
			// if the user double-clicks quickly (e.g., on slower machines, the re-render
			// might not happen fast enough to disable the button before the second click).
			// Once hasClicked is true, subsequent clicks in the same component lifetime
			// will be ignored—unless we explicitly reset the state after an error, etc.
			if (!hasClicked) {
				try {
					setHasClicked(true);
					analyticsFlow.addAttributes({
						actionTaken: analyticsTitle,
					});
					analyticsFlow.enqueueStep({
						stepName: 'preview',
					});
					// We set this to true to prevent the preview step from being
					// enqueued again on unmount
					// Note -- in future -- we expect the complete step to be deprecated
					// an replaced with a "content highlighted" step
					enqueuedPreviewStep.current = true;
					analyticsFlow.fireQueuedStep('complete');
					const beforeActionRun = performance.now();
					runFn();
					analyticsFlow.enqueueStep({
						stepName: 'complete',
						attributes: {
							actionTaken: analyticsTitle,
							duration: Math.round(performance.now() - beforeActionRun),
							editCount: editCount,
							retryPromptCount,
							promptType: promptTrigger,
							refinementCount,
							inputOutputDiffRatio,
							includeKnowledgeFromCurrentPage,
						},
					});

					/**
					 * Please check with Ethan/PM upon removing onExperienceEvent
					 * calls, to confirm it is no longer used in any ongoing experiments.
					 */
					editorPluginAIProvider.onExperienceEvent?.EXPERIENCE_COMPLETE();
					endExperience({ preserveEditorSelectionOnComplete });
				} catch (error) {
					setHasClicked(false);
					fireAIAnalyticsEvent({
						payload: {
							action: 'unhandledErrorCaught',
							actionSubject: 'editorPluginAI',
							actionSubjectId: 'experienceApplication',
							attributes: {
								errorType: 'actionRunError',
								actionTitle: analyticsTitle,
								errorMessage: ErrorUtils.extractErrorMessage(error),
							},
							eventType: EVENT_TYPE.OPERATIONAL,
						},
					});
					sendToAIExperienceMachine({
						type: 'error',
						errorInfo: {
							failureReason: FAILURE_REASON.UNKNOWN,
						},
					});
				}
			}
		},
		[
			hasClicked,
			analyticsFlow,
			editCount,
			retryPromptCount,
			promptTrigger,
			refinementCount,
			inputOutputDiffRatio,
			includeKnowledgeFromCurrentPage,
			editorPluginAIProvider.onExperienceEvent,
			endExperience,
			fireAIAnalyticsEvent,
			sendToAIExperienceMachine,
		],
	);

	const mapMarkdownAction = useCallback(
		(action: EditorPluginAIConfigItemMarkdownAction): PreviewButton => {
			// For Rovo actions, we only generate a fragment from the suggested content (without bot pleasantries)
			const contentFragment =
				action.isRovoAgentAction && rovoAction
					? getPMFragmentWithFallback({
							markdown: rovoAction.content || '',
							schema,
							source: 'rovo-action',
							// TODO: https://product-fabric.atlassian.net/browse/EDF-1044
							fireAIAnalyticsEvent: () => {},
							// Won't be expecting Markdown Plus
							idMap: {},
						}).pmFragment
					: updatedFragment;

			const { isDisabled, tooltip } = action.getDisabledState?.({ markdown, formatMessage }) ?? {};

			return {
				label: formatMessage(action.title),
				appearance: action.appearance,
				onClick: () => {
					onActionButtonClick({
						runFn: () => {
							action.run({
								editorView,
								positions,
								markdown,
								pmFragment: contentFragment,
								triggeredFor,
							});

							if (configItem.agent && editorPluginAIProvider.onDocChangeByAgent) {
								editorPluginAIProvider.onDocChangeByAgent(configItem.agent);
							}
						},
						analyticsTitle:
							typeof action.title.defaultMessage === 'string' ? action.title.defaultMessage : '',
					});
				},
				isDisabled: isDisabled || hasClicked,
				tooltip,
			};
		},
		[
			rovoAction,
			schema,
			updatedFragment,
			markdown,
			formatMessage,
			hasClicked,
			onActionButtonClick,
			editorView,
			positions,
			triggeredFor,
			configItem.agent,
			editorPluginAIProvider,
		],
	);

	const mapAgentAction = useCallback(
		(action: EditorPluginAIConfigItemAgentAction): PreviewSecondaryActionButton => {
			return {
				label: formatMessage(action.title),
				appearance: action.appearance,
				icon: action.icon,
				shortcut: action.shortcut,
				onClick: () => {
					onActionButtonClick({
						runFn: () =>
							action.run({
								channelId,
								publish: rovoPublish,
							}),
						analyticsTitle:
							typeof action.title.defaultMessage === 'string' ? action.title.defaultMessage : '',
						preserveEditorSelectionOnComplete: action.preserveEditorSelectionOnComplete,
					});
				},
			};
		},
		[channelId, formatMessage, onActionButtonClick, rovoPublish],
	);

	const mapGenericChatAction = useCallback(
		({
			title,
			appearance,
			icon,
			shortcut,
			run,
			preserveEditorSelectionOnComplete,
		}: EditorPluginAIConfigItemGenericChatAction): PreviewSecondaryActionButton => {
			return {
				label: formatMessage(title),
				appearance,
				icon,
				shortcut: shortcut,
				onClick: () => {
					onActionButtonClick({
						runFn: () =>
							run({
								publish: rovoPublish,
								dialogues: history.entries.map((entry, index) => {
									const formattedTitle = formatMessage(entry.configItem.title);

									let humanMessage = entry.userInput || formattedTitle;
									if (index === 0 && entry.userInput) {
										humanMessage = `${formattedTitle}\n${entry.userInput}`;
									}

									return {
										agent_message: { content: entry.markdown },
										human_message: { content: humanMessage },
									};
								}),
							}),
						analyticsTitle: typeof title.defaultMessage === 'string' ? title.defaultMessage : '',
						preserveEditorSelectionOnComplete,
					});
				},
			};
		},
		[formatMessage, history, onActionButtonClick, rovoPublish],
	);

	// We need to use actions from the first config item in history,
	// because during refinement, the user might have selected a different
	// config item, that will have different actions.
	const firstConfigItemActions = getEditorPluginAIConfigItemActions({
		isEmpty,
		configItem: history.entries[0]?.configItem,
	});
	const applications = useMemo(() => {
		let actions = getEditorPluginAIConfigItemActions({ isEmpty, configItem });
		actions = firstConfigItemActions || actions;

		return actions.map((action) => {
			if (action.type === 'agent') {
				return mapAgentAction(action);
			}

			if (action.type === 'generic-chat') {
				return mapGenericChatAction(action);
			}

			return mapMarkdownAction(action);
		});
	}, [
		isEmpty,
		configItem,
		firstConfigItemActions,
		mapMarkdownAction,
		mapAgentAction,
		mapGenericChatAction,
	]);

	const refineDropdownItems = useMemo(() => {
		const allRefineSuggestions: EditorPluginAIConfigItem[] = getAllEditorPluginAIConfigItems({
			configItemWithOptions: editorPluginAIProvider.configItemWithOptions,
		}).filter((suggestion) => suggestion.showInRefineDropdown);

		const result: RefineDropdownItem[] = [];
		const parentForNesting: { [parentKey: string]: RefineDropdownItem } = {};

		const handleClick = (configItem: (typeof allRefineSuggestions)[0]) => () => {
			sendToAIExperienceMachine({
				type: 'refine',
				configItem,
				// Ignored via go/ees005
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				latestPromptResponse: markdown!,
			});
		};

		for (const suggestion of allRefineSuggestions) {
			const { nestingConfig } = suggestion;
			if (!nestingConfig) {
				result.push({
					title: suggestion.title,
					icon: <>{suggestion.icon?.({ formatMessage, shownAt: 'suggestions' })}</>,
					onClick: handleClick(suggestion),
				});
			} else {
				const parentKey = nestingConfig.parentTitle.id ?? JSON.stringify(nestingConfig.parentTitle);

				if (!parentForNesting[parentKey]) {
					const icon = nestingConfig.icon || suggestion.icon;

					const parent: RefineDropdownItem = {
						icon: <> {icon?.({ formatMessage, shownAt: 'suggestions' })} </>,
						title: nestingConfig.parentTitle,
						children: [],
					};

					result.push(parent);
					parentForNesting[parentKey] = parent;
				}

				parentForNesting[parentKey].children?.push({
					title: suggestion.nestingConfig?.shortTitle ?? suggestion.title,
					onClick: handleClick(suggestion),
				});
			}
		}

		return result;
	}, [
		editorPluginAIProvider.configItemWithOptions,
		formatMessage,
		markdown,
		sendToAIExperienceMachine,
	]);

	return (
		<PreviewScreen
			key={configItem.key}
			parentPresetPromptLabel={parentPresetPromptLabel}
			childShortTitle={childShortTitle}
			latestPrompt={localizedLatestPrompt}
			preview={preview}
			disableActions={!!responseTooSimilar}
			inputValue={userInput}
			inputADFValue={userADFInput}
			isInputActive={isInputActive}
			onCancel={onCancelClick}
			onInputChange={onInputChange}
			onADFChange={onADFChange}
			onInputFocus={() => {
				sendToAIExperienceMachine({ type: 'start refinement' });
			}}
			onInputBlur={() => {
				if (!userInput) {
					sendToAIExperienceMachine({ type: 'cancel refinement' });
				}
			}}
			onSubmit={() => {
				/**
				 * Do we really need to pass userInput and userADFInput
				 *  on firing interrogate event.
				 * We should have latest userInput and userADFInput
				 *  set by onInputChange and onADFChange.
				 */
				sendToAIExperienceMachine({
					type: 'interrogate',
					userInput: userInput,
					userADFInput,
					// Ignored via go/ees005
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					latestPromptResponse: markdown!,
				});
			}}
			onCopy={() => {
				fireAIAnalyticsEvent({
					payload: {
						action: 'click',
						actionSubject: 'editorPluginAI',
						actionSubjectId: 'copyButton',
						attributes: {
							experienceType: convertExperienceName(configItem.key.toString()),
							editCount,
						},
						eventType: EVENT_TYPE.UI,
					} as CopyButtonClickAEP,
				});

				analyticsFlow.addAttributes({
					contentCopied: true,
				});
				copyPMFragmentToClipboard({ editorView, pmFragment });
			}}
			onRetry={!configItem.agent ? onRetryPromptClick : undefined}
			onGoodResponseButtonClick={() => {
				const feedbackMetadata = {
					sentiment: 'good' as const,
					getAIExperience,
					editorAttributes: { appearance, product },
				};

				// Now deprecated - migrate to handleFeedbackSubmission
				editorPluginAIProvider.onExperienceEvent &&
					editorPluginAIProvider.onExperienceEvent.THUMBS_UP();

				handleFiringFeedbackEvent(feedbackMetadata);
			}}
			onBadResponseButtonClick={() => {
				const feedbackMetadata = {
					sentiment: 'bad' as const,
					getAIExperience,
					editorAttributes: { appearance, product },
				};

				// Now deprecated - migrate to handleFeedbackSubmission
				editorPluginAIProvider.onExperienceEvent &&
					editorPluginAIProvider.onExperienceEvent.THUMBS_DOWN();

				handleFiringFeedbackEvent(feedbackMetadata);
			}}
			onEditPromptClick={onEditPromptClick}
			onBack={onBack}
			applications={applications}
			secondaryActions={(configItem.secondaryActions ?? []).map((action) => mapAgentAction(action))}
			PromptEditor={editorPluginAIProvider.PromptEditor}
			clearInputRef={clearInputRef}
			disclaimer={disclaimer}
			providerFactory={providerFactory}
			schema={schema}
			disableInterrogation={disableInterrogation}
			agent={configItem.agent}
			refineDropdownItems={refineDropdownItems}
		/>
	);
}
