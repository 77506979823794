import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@atlaskit/modal-dialog';
import type { Appearance } from '@atlaskit/button';
import Button, { ButtonGroup } from '@atlaskit/button';

type ButtonType = {
	onClick: () => void;
	label: string;
	appearance: Appearance;
	key: string;
};

const unsavedChangesi18n = defineMessages({
	frictionModalTitle: {
		id: 'space-roles.friction-modal.title',
		defaultMessage: 'Unsaved changes',
		description: 'Title for the friction modal',
	},
	frictionModalBody: {
		id: 'space-roles.friction-modal.body',
		defaultMessage:
			'You have unsaved changes that will be lost if you close without selecting <b>Save</b>.',
		description: 'Body text for the friction modal',
	},
	frictionModalBodyAdditional: {
		id: 'space-roles.friction-modal.body-additional',
		defaultMessage: 'Do you want to discard them?',
		description: 'Additional body text for the friction modal',
	},
});

export const FrictionModal: FC<{
	title?: string;
	body?: React.ReactNode;
	width?: string | number;
	buttons: ButtonType[];
	onClose: () => void;
}> = ({ title, body, buttons, onClose, width = 'small' }) => {
	const { formatMessage } = useIntl();

	return (
		<ModalDialog width={width} onClose={onClose}>
			<ModalHeader>
				<ModalTitle appearance="warning">
					{title || formatMessage(unsavedChangesi18n.frictionModalTitle)}
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{body ?? (
					<>
						{formatMessage(unsavedChangesi18n.frictionModalBody, {
							b: (chunks) => <b>{chunks}</b>,
						})}
						<br />
						{formatMessage(unsavedChangesi18n.frictionModalBodyAdditional)}
					</>
				)}
			</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					{buttons.map((button) => (
						<Button key={button.key} onClick={button.onClick} appearance={button.appearance}>
							{button.label}
						</Button>
					))}
				</ButtonGroup>
			</ModalFooter>
		</ModalDialog>
	);
};
