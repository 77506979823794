import type { SpacePermissionType } from '../graphql/__types__/SpacePermissionsPrincipalsMutation';

export const encodeChangeKey = (permission: SpacePermissionType, id: string): string =>
	`${permission}|${id}`;

export const decodeChangeKey = (
	changeKey: string,
): { permission: SpacePermissionType; id: string } => {
	const [permission, id] = changeKey.split('|');
	return {
		permission: permission as SpacePermissionType,
		id,
	};
};

export type StagedChanges = Record<string, boolean>;
