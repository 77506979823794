import { defineMessages } from 'react-intl-next';

export default defineMessages({
	goodResponse: {
		id: 'fabric.editor.ai.components.feedbackForm.goodResponse',
		defaultMessage: 'Good response',
		description: 'The AI response was good',
	},
	badResponse: {
		id: 'fabric.editor.ai.components.feedbackForm.badResponse',
		defaultMessage: 'Bad response',
		description: 'The AI response was bad',
	},
	feedbackSent: {
		id: 'fabric.editor.ai.components.feedbackForm.feedbackSent',
		defaultMessage: 'Feedback sent',
		description:
			'A message that appears to users when they click the thumbs up or thumbs down button',
	},
});
