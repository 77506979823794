import { doc, p } from '@atlaskit/adf-utils/builders';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { MentionMap } from '@atlassian/ai-model-io/utils/mention-map';

import type { EditorPluginAIConfigItem, UpdateIdMap } from '../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../config-items/slice-or-node-to-markdown';
import { convoAIStatefulApiURL } from '../prebuilt/utils';

import type { AIExperienceMachineContextForRequest, RequestOptions } from './types';

function makeAdf({
	userInput = '',
	userADFInput,
}: {
	userInput?: string;
	userADFInput?: JSONDocNode;
}) {
	return userADFInput || doc(p(userInput));
}

type Options = {
	context: AIExperienceMachineContextForRequest;
	configItem: EditorPluginAIConfigItem;
	mentionMap?: MentionMap;
	updateIdMap: UpdateIdMap;
};

/**
 * A function that will make the payload for the Rovo Agent.
 *
 * @see https://hello.atlassian.net/wiki/spaces/EDAI/pages/3753154519/Rovo+Agents+in+Editor
 */
export const getRequestOptions4RovoChat = ({
	context,
	configItem,
	mentionMap,
	updateIdMap,
}: Options): RequestOptions => {
	const { editorView, positions, userInput, userADFInput } = context;
	const { agent } = configItem;
	const idMap = {};

	const needDoc = context.usePageKnowledge;
	const needSelection = true;

	const doc = needDoc
		? sliceOrNodeToMarkdown({
				node: editorView.state.doc,
				editorView,
				convertTo: 'markdown-plus',
				existingIdMap: idMap,
				updateIdMap,
				mentionMap,
			})
		: undefined;

	const selection = needSelection
		? sliceOrNodeToMarkdown({
				slice: editorView.state.doc.slice(positions[0], positions[1]),
				editorView,
				convertTo: 'markdown-plus',
				existingIdMap: doc?.idMap,
				updateIdMap,
				mentionMap,
			})
		: undefined;

	return {
		doc: doc?.markdown,
		selection: selection?.markdown,
		contentStatistics: doc?.contentStatistics || selection?.contentStatistics,
		isConvoAI: true,
		experienceId: 'ai-mate',
		endpoint: convoAIStatefulApiURL,
		// For payload spec, see https://hello.atlassian.net/wiki/spaces/CONVAI/pages/3783312372/Actions+Contract+for+Confluence+Content+Suggestion
		payload: JSON.stringify({
			recipient_agent_named_id: agent?.namedId,
			mimeType: 'text/adf',
			store_message: true,
			content: makeAdf({
				userInput,
				userADFInput,
			}),
			context: {
				// Just the main URL, remove the extra stuff like hashes/query params
				browser_url: window.location.origin + window.location.pathname,
				editor: {
					document: {
						type: 'text/markdown',
						content: doc?.markdown || '',
					},
					selection: {
						type: 'text/markdown',
						content: selection?.markdown || '',
					},
				},
			},
		}),
	};
};
