import { useMutation } from '@apollo/react-hooks';
import { defineMessages, useIntl } from 'react-intl-next';
import type { PureQueryOptions } from 'apollo-client';

import { useResponseHandler } from '@confluence/space-roles/entry-points/graphql';
import { DEFAULT_RBAC_EDIT_EXPERIENCE } from '@confluence/experience-tracker';

import { UpdateLegacyDefaultPermissionsMutation } from '../UpdateLegacyDefaultPermissions.graphql';
import type {
	UpdateLegacyDefaultPermissionsMutation as UpdateLegacyDefaultPermissionsMutationType,
	UpdateLegacyDefaultPermissionsMutationVariables,
} from '../__types__/UpdateLegacyDefaultPermissionsMutation';

const i18n = defineMessages({
	updateGroupSuccess: {
		id: 'default-space-permissions.update-group.save-success.non-final',
		defaultMessage: 'Group updated successfully',
		description: 'Success message when a group is updated in a space',
	},
	updateLastAdminPermsError: {
		id: 'default-space-permissions.update-group.last-admin-error',
		defaultMessage:
			'The last admin within the default configurations must keep their admin permission.',
		description: 'Error message when trying to remove the last admin permission',
	},
});

export const useSetLegacyPermissions = ({
	onResponse,
	refetchQueries,
}: {
	onResponse: () => void;
	refetchQueries?: (string | PureQueryOptions)[];
}) => {
	const { formatMessage } = useIntl();

	const { handleResponse } = useResponseHandler({
		experience: DEFAULT_RBAC_EDIT_EXPERIENCE,
		successMessage: formatMessage(i18n.updateGroupSuccess),
	});

	const [setLegacyPermissions, { loading: isPermissionAssignmentLoading }] = useMutation<
		UpdateLegacyDefaultPermissionsMutationType,
		UpdateLegacyDefaultPermissionsMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UpdateLegacyDefaultPermissionsMutation,
		{
			onError: (error) => {
				handleResponse({ error });
				onResponse();
			},
			onCompleted: (response) => {
				handleResponse({
					errors: response.updateSpacePermissionDefaultsV2?.errors,
					expectedErrors: [
						{
							400: formatMessage(i18n.updateLastAdminPermsError),
						},
					],
				});
				onResponse();
			},
			refetchQueries,
		},
	);

	return {
		setLegacyPermissions,
		isPermissionAssignmentLoading,
	};
};
