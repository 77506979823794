import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getApolloClient } from '@confluence/graphql';
import { CreateContentInlineMutation } from './__types__/CreateWhiteboardMutation';
import { CreateWhiteboardMutation } from './CreateWhiteboard.graphql';
import { getFullWhiteboardUrl } from './urls';

export async function createWhiteboardOverGraphQL(
	pageId: string,
	spaceId: string,
	spaceKey: string,
	templateKey: string,
	createAnalyticsEvent: CreateUIAnalyticsEvent | undefined,
): Promise<string> {
	const mutationResult = await getApolloClient().mutate<CreateContentInlineMutation>({
		mutation: CreateWhiteboardMutation,
		variables: { createdInContentId: pageId, spaceKey },
	});

	const contentId = mutationResult.data?.createContentInline.id;
	if (!contentId) {
		throw Error('no content id present');
	}

	// track event - whiteboard created inline
	const event = createAnalyticsEvent?.({
		type: 'sendTrackEvent',
		data: {
			action: 'createdByFlow',
			actionSubject: 'content',
			objectType: 'whiteboard',
			objectId: contentId,
			containerType: 'space',
			containerId: spaceId,
			source: 'createContentHandler',
			attributes: {
				flow: 'inlineWhiteboardCreation',
				contentType: 'whiteboard',
				collabService: 'canvas-relay',
				templateKey,
			},
		},
	});
	event?.fire();

	// return full URL so user can navigate
	return getFullWhiteboardUrl(contentId, spaceKey);
}
