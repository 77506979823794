/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PrincipalType {
  GROUP = "GROUP",
  USER = "USER",
}

export enum SpacePermissionType {
  ADMINISTER_SPACE = "ADMINISTER_SPACE",
  ARCHIVE_PAGE = "ARCHIVE_PAGE",
  COMMENT = "COMMENT",
  CREATE_ATTACHMENT = "CREATE_ATTACHMENT",
  CREATE_EDIT_PAGE = "CREATE_EDIT_PAGE",
  EDIT_BLOG = "EDIT_BLOG",
  EXPORT_PAGE = "EXPORT_PAGE",
  EXPORT_SPACE = "EXPORT_SPACE",
  REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT",
  REMOVE_BLOG = "REMOVE_BLOG",
  REMOVE_COMMENT = "REMOVE_COMMENT",
  REMOVE_MAIL = "REMOVE_MAIL",
  REMOVE_OWN_CONTENT = "REMOVE_OWN_CONTENT",
  REMOVE_PAGE = "REMOVE_PAGE",
  SET_PAGE_PERMISSIONS = "SET_PAGE_PERMISSIONS",
  VIEW_SPACE = "VIEW_SPACE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: spacePermissionsDefaults
// ====================================================

export interface spacePermissionsDefaults_defaultSpacePermissions_groupsWithDefaultSpacePermissions_nodes_subjectKey {
  id: string | null;
  principalType: PrincipalType;
  displayName: string | null;
}

export interface spacePermissionsDefaults_defaultSpacePermissions_groupsWithDefaultSpacePermissions_nodes {
  subjectKey: spacePermissionsDefaults_defaultSpacePermissions_groupsWithDefaultSpacePermissions_nodes_subjectKey | null;
  permissions: (SpacePermissionType | null)[] | null;
}

export interface spacePermissionsDefaults_defaultSpacePermissions_groupsWithDefaultSpacePermissions_pageInfo {
  hasNextPage: boolean | null;
  hasPreviousPage: boolean | null;
  endCursor: string | null;
}

export interface spacePermissionsDefaults_defaultSpacePermissions_groupsWithDefaultSpacePermissions {
  nodes: (spacePermissionsDefaults_defaultSpacePermissions_groupsWithDefaultSpacePermissions_nodes | null)[] | null;
  pageInfo: spacePermissionsDefaults_defaultSpacePermissions_groupsWithDefaultSpacePermissions_pageInfo | null;
}

export interface spacePermissionsDefaults_defaultSpacePermissions {
  editable: boolean;
  groupsWithDefaultSpacePermissions: spacePermissionsDefaults_defaultSpacePermissions_groupsWithDefaultSpacePermissions;
}

export interface spacePermissionsDefaults {
  defaultSpacePermissions: spacePermissionsDefaults_defaultSpacePermissions;
}

export interface spacePermissionsDefaultsVariables {
  after?: string | null;
}
