import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';
import uuid from 'uuid/v4';

import type { ExtensionModuleAction } from '@atlaskit/editor-common/extensions';
import type { EditorActions } from '@atlaskit/editor-core';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import type { ExtensionPlugin } from '@atlaskit/editor-plugins/extension';

import { expVal } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';
import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

import type { MacroConfig } from '../../extensions-common/types';
import { isBodiedExtension } from '../../extensions-common';

import {
	getJiraHostingOption,
	getConnectAppKey,
	getSelectedBodiedExtensionNode,
	insertMacroPlaceholder,
} from './utils';
import type {
	LegacyMacroManifest,
	ConfluencePageContext,
	OnCompleteCallbackFnType,
} from './extensionTypes';
import {
	prepareMacro,
	shouldUseMacroBrowser,
	needsToForceConfig,
	isMissingAnyRequiredParameters,
} from './manifest-helpers';

export const i18n = defineMessages({
	excerptPlaceholder: {
		id: 'fabric-extension-lib.excerpt.placeholder',
		defaultMessage: 'Type or paste something here to turn it into an excerpt.',
		description: 'Placeholder text shown within an empty excerpt macro',
	},
	pagePropertiesPlaceholder: {
		id: 'fabric-extension-lib.page.properties.placeholder',
		defaultMessage:
			'Add a table with key/value pairs in order to display its data on another page using the Page Properties Report macro.',
		description: 'Placeholder text shown within an empty page properties macro',
	},
	tocZonePlaceholder: {
		id: 'fabric-extension-lib.toc.zone.placeholder',
		defaultMessage: 'A table of contents based on the headings within this frame will appear here.',
		description: 'Placeholder text shown within an empty TOC zone macro',
	},
});

const maybeGetGadgetUrl = (macro: LegacyMacroManifest) =>
	macro.macroName === 'gadget' &&
	macro?.formDetails?.parameters?.[0]?.defaultValue && {
		gadgetUrl: new URL(
			// This is gross, but this is the only way to get the URL of the
			// the gadget. We use the same url in the view event to know
			// which gadget was actually inserted, thus track gadget usage
			macro.formDetails.parameters[0].defaultValue,
			location.origin,
		).pathname,
	};

const createBaseMacroAction =
	(
		macro: LegacyMacroManifest,
		pageContext: ConfluencePageContext,
		intl: IntlShape,
		createAnalyticsEvent?: CreateUIAnalyticsEvent,
		isLivePage: boolean = false,
	) =>
	async () => {
		const node = await prepareMacro({ name: macro.macroName }, pageContext.contentId);

		if (macro.macroName === 'excerpt') {
			insertMacroPlaceholder(node, intl.formatMessage(i18n.excerptPlaceholder));
		}

		if (
			macro.macroName === 'details' &&
			expVal('confluence_frontend_macro_interaction_design', 'isEnabled', false)
		) {
			insertMacroPlaceholder(node, intl.formatMessage(i18n.pagePropertiesPlaceholder));
		}

		if (
			macro.macroName === 'toc-zone' &&
			expVal<boolean>('confluence_frontend_macro_interaction_design', 'isEnabled', false)
		) {
			insertMacroPlaceholder(node, intl.formatMessage(i18n.tocZonePlaceholder));
		}

		const jiraHostingOption = await getJiraHostingOption(
			macro.macroName,
			node.attrs.parameters?.macroParams?.['serverId']?.value,
		);

		if (createAnalyticsEvent) {
			const isSupportedMacro = await getIsSupportedMacro(macro.macroName);

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'inserted',
					actionSubject: 'macro',
					actionSubjectId: macro.macroName,
					source: 'quick insert menu',
					attributes: {
						extensionType: node?.type,
						appKey: getConnectAppKey(macro.moduleKey),
						isLivePage,
						jiraHostingOption,
						...(isLivePage && isSupportedMacro !== undefined && { isSupportedMacro }),
						...maybeGetGadgetUrl(macro),
					},
				},
			}).fire();
		}

		return node;
	};
const createActionForMacrosThatRequireMacroBrowser =
	(
		macro: LegacyMacroManifest,
		openMacroBrowserForInitialConfiguration: any,
		editorAPI: PublicPluginAPI<[ExtensionPlugin]> | undefined,
		macroBrowserConfig?: MacroConfig,
		editorActions?: EditorActions,
		createAnalyticsEvent?: CreateUIAnalyticsEvent,
		onCompleteCallback?: OnCompleteCallbackFnType,
		isLivePage: boolean = false,
	) =>
	async () => {
		if (!macroBrowserConfig) {
			throw new Error(`Macro Browser Config is missing`);
		}

		if (!editorActions) {
			throw new Error(`editorActions is missing`);
		}

		const macroFilter = undefined;
		const extensionAnalyticsInfo = {
			createAnalyticsEvent,
			contentId: macroBrowserConfig.contentId,
		};

		const updateMacro = (node) => {
			const selectedNode = getSelectedBodiedExtensionNode(editorActions);
			if (selectedNode) {
				editorAPI?.extension?.actions?.editSelectedExtension();
			} else {
				// initial insert of the roadmap macro
				editorActions.replaceSelection(node);
				editorAPI?.extension?.actions?.editSelectedExtension();
			}
		};

		const node = await openMacroBrowserForInitialConfiguration(
			macro,
			macroBrowserConfig,
			macroFilter,
			extensionAnalyticsInfo,
			onCompleteCallback,
			updateMacro,
		);

		const jiraHostingOption = await getJiraHostingOption(
			macro.macroName,
			node.attrs.parameters?.macroParams?.['serverId']?.value,
		);

		if (editorActions.replaceSelection(node) && createAnalyticsEvent) {
			const isSupportedMacro = await getIsSupportedMacro(macro.macroName);

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'inserted',
					actionSubject: 'macro',
					actionSubjectId: macro.macroName,
					source: 'quick insert menu',
					attributes: {
						inputMethod: 'macroBrowser',
						extensionType: node?.type,
						appKey: getConnectAppKey(macro.moduleKey),
						isLivePage,
						jiraHostingOption,
						...(isLivePage && isSupportedMacro !== undefined && { isSupportedMacro }),
						...maybeGetGadgetUrl(macro),
					},
				},
			}).fire();
		}
	};

const createActionForMacrosThatCanUseNewConfig =
	(
		macro: LegacyMacroManifest,
		pageContext: ConfluencePageContext,
		intl: IntlShape,
		editorAPI: PublicPluginAPI<[ExtensionPlugin]> | undefined,
		editorActions?: EditorActions,
		createAnalyticsEvent?: CreateUIAnalyticsEvent,
		onCompleteCallback?: OnCompleteCallbackFnType,
		isLivePage: boolean = false,
	) =>
	async () => {
		if (!editorActions) {
			throw new Error(`editorActions is missing`);
		}

		const getNode = createBaseMacroAction(macro, pageContext, intl, undefined, isLivePage);
		const node = await getNode();

		// Default the TOC style to none
		if (node?.attrs?.extensionKey === 'toc') {
			if (node?.attrs?.parameters?.macroParams) {
				node.attrs.parameters.macroParams['style'] = {
					value: 'none',
				};
			}
		}

		// Default the anchor macro name to a random UUID
		if (node?.attrs?.extensionKey === 'anchor' && fg('cc_page_experiences_anchor_macro_refresh')) {
			if (node?.attrs?.parameters?.macroParams) {
				node.attrs.parameters.macroParams[''] = {
					value: uuid(),
				};
			}
		}

		// Add a param to show that the macro is missing required parameters
		const { extensionType, extensionKey, parameters } = node.attrs;
		if (
			isMissingAnyRequiredParameters(macro, {
				extensionType,
				extensionKey,
				parameters,
			})
		) {
			if (node.attrs.parameters?.macroParams) {
				node.attrs.parameters.macroParams['isMissingRequiredParameters'] = {
					// Need to be `string` to be able to persist
					value: 'true',
				};
			}
		}

		if (editorActions.replaceSelection(node) && createAnalyticsEvent) {
			const jiraHostingOption = await getJiraHostingOption(
				macro.macroName,
				node.attrs.parameters?.macroParams?.['serverId']?.value,
			);
			const isSupportedMacro = await getIsSupportedMacro(macro.macroName);

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'inserted',
					actionSubject: 'macro',
					actionSubjectId: macro.macroName,
					source: 'quick insert menu',
					attributes: {
						inputMethod: 'configPanel',
						extensionType: node?.type,
						appKey: getConnectAppKey(macro.moduleKey),
						isLivePage,
						jiraHostingOption,
						...(isLivePage && isSupportedMacro !== undefined && { isSupportedMacro }),
						...maybeGetGadgetUrl(macro),
					},
				},
			}).fire();
		}

		editorAPI?.extension?.actions?.editSelectedExtension();

		onCompleteCallback?.({
			macro: macro.macroName,
			isBodiedExtension: isBodiedExtension(node?.type),
		});
	};

export const getQuickInsertAction = (
	macro: LegacyMacroManifest,
	pageContext: ConfluencePageContext,
	openMacroBrowserForInitialConfiguration: any,
	editorAPI: PublicPluginAPI<[ExtensionPlugin]> | undefined,
	intl: IntlShape,
	macroBrowserConfig?: MacroConfig,
	editorActions?: EditorActions,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	onCompleteCallback?: OnCompleteCallbackFnType,
	isLivePage?: boolean,
): ExtensionModuleAction => {
	const { enabled: isConfigPanelEnabled, optedOut: excludedMacros } =
		pageContext.features.configPanel || {};
	// using the detailed legacy macro manifest list, the attributes `anyParameterRequired` and `alwaysShowConfig` are not available so we need to calculate ourselves
	const hasRequiredParameter =
		macro.anyParameterRequired ||
		macro.formDetails.parameters.some((param) => param.required && param.defaultValue === null);

	const useNewConfigExperience =
		isConfigPanelEnabled && !shouldUseMacroBrowser(macro, excludedMacros);

	const showInitialConfig =
		// always show the config panel if we are using the new config experience
		useNewConfigExperience ||
		hasRequiredParameter ||
		macro.alwaysShowConfig ||
		needsToForceConfig(macro);

	if (showInitialConfig) {
		if (!useNewConfigExperience) {
			return createActionForMacrosThatRequireMacroBrowser(
				macro,
				openMacroBrowserForInitialConfiguration,
				editorAPI,
				macroBrowserConfig,
				editorActions,
				createAnalyticsEvent,
				onCompleteCallback,
				isLivePage,
			);
		} else {
			return createActionForMacrosThatCanUseNewConfig(
				macro,
				pageContext,
				intl,
				editorAPI,
				editorActions,
				createAnalyticsEvent,
				onCompleteCallback,
				isLivePage,
			);
		}
	} else {
		return createBaseMacroAction(macro, pageContext, intl, createAnalyticsEvent, isLivePage);
	}
};

export const getIsSupportedMacro = async (macroKey: string): Promise<boolean | undefined> => {
	if (!macroKey) {
		return undefined;
	}

	const macroFallbackUiAllowlist = StatsigConfigurations.getStringList(
		'confluence_frontend_macro_fallback_ui_allowlist',
		[],
	);

	if (macroFallbackUiAllowlist.length === 0) {
		return undefined;
	} else if (macroFallbackUiAllowlist[0] === 'ALLOW_ALL') {
		return true;
	}

	const macros = macroFallbackUiAllowlist.map((m) => m.trim().toLowerCase());

	return macros.includes(macroKey.toLowerCase());
};
