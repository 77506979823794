import type { Decoration, DecorationSet } from '@atlaskit/editor-prosemirror/view';

function findFirstDeco(decorationSet: DecorationSet, key: string): Decoration | undefined {
	const found = decorationSet.find(undefined, undefined, (spec) => spec.key === key);
	return found.length > 0 ? found[0] : undefined;
}

export function getAIHighlightPositions({
	modalDecorationSet,
}: {
	modalDecorationSet?: DecorationSet;
}): [number, number] | undefined {
	if (!modalDecorationSet) {
		return undefined;
	}

	const inlineDecoration = findFirstDeco(modalDecorationSet, 'inlineDecoration');
	if (inlineDecoration) {
		return [inlineDecoration.from, inlineDecoration.to];
	}

	const startWidget = findFirstDeco(modalDecorationSet, 'generatedStartWidget');
	const endWidget = findFirstDeco(modalDecorationSet, 'generatedEndWidget');

	if (startWidget && endWidget) {
		return [startWidget.from, endWidget.to];
	}

	return undefined;
}
