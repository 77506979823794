import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/summarize';
import {
	createInsertAtCurrentPosition,
	createInsertBelow,
	createReplace,
} from '../../config-item-actions/markdown';
import _messages from '../../messages';

import { messages } from './messages';

export const summarizeWriting: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	showInRefineDropdown: true,
	intentSchemaId: 'summarize_intent_schema.json',
	actions: {
		empty: [createInsertAtCurrentPosition({ appearance: 'primary' })],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const summarizeWritingWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: summarizeWriting,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};

export const summarizeWritingWithOptionsForAtlas: EditorPluginAIConfigItemWithOptions = {
	config: { ...summarizeWriting, description: _messages.atlasSummarizePageConfigItemDescription },
	triggers: { empty: { docMinSize: 1 }, selection: false },
};
