import type { PerformanceEntryBuffer } from '../utils/buffer';

export const MAX_INP_INTERACTIONS = 10;

const getEstimatedP98LongestInteraction = (interactionEvents: PerformanceEventTiming[]) => {
	const sortedInteractions = [...interactionEvents].sort((a, b) => b.duration - a.duration);
	sortedInteractions.splice(MAX_INP_INTERACTIONS);

	// If we have an usually high number of interactions,
	// ignore the longest interaction and take the next one.
	// https://web.dev/articles/inp#what-is-inp -> "Details on how INP is calculated"
	const p98Index = Math.min(
		sortedInteractions.length - 1,
		Math.floor(interactionEvents.length / 50),
	);

	return { p98: sortedInteractions[p98Index], interactionCount: sortedInteractions.length };
};

export const getINP = (start: number, stop: number, buffer: PerformanceEntryBuffer) => {
	const inpData = {
		score: 0,
		interactionCounts: {},
	};

	const allEvents = buffer.getAll() as PerformanceEventTiming[];
	const interactionEvents = allEvents.filter(
		(entry) =>
			entry.startTime >= start && entry.startTime <= stop && entry.entryType !== 'first-input',
	);

	if (interactionEvents.length) {
		const { p98, interactionCount } = getEstimatedP98LongestInteraction(interactionEvents);
		inpData.score = p98.duration;
		inpData.interactionCounts = interactionCount;
	}

	return inpData;
};
