import React, { useContext } from 'react';

import type { RouteMatch } from '@confluence/route';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';

import { SpacePermissionsSettingsRoute } from './SpacePermissionsSettingsRoute';
import {
	ADMIN_HOME_ID,
	PRODUCT_HOME_ID,
	COMPANY_HUB_SETTINGS_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { getCompanyHubSideNav } from '@confluence/breadcrumbs/entry-points/getCompanyHubSideNav';
import {
	CompanyHubSettingsPage,
	CompanyHubSettingsPageTabsType,
} from '@confluence/company-hub/entry-points/CompanyHubSettingsPage';
import { fg } from '@confluence/feature-gating';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { SPAViewContext } from '@confluence/spa-view-context';

const DEFAULT_TAB = 'users';

export const AdminCompanyHubPermissionsRoute = ({ params, ...props }: RouteMatch) => {
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const tab = getPreConsolidatedSettingsTab(params);

	if (isSiteAdmin && shouldShowPremiumUpsell) {
		return <CompanyHomeBuilderUpsellPage featureName="companyHubPermissions" />;
	}

	if (!tab) {
		return <CompanyHubSettingsPage tab={CompanyHubSettingsPageTabsType.USERS} />;
	}

	const spaceKey = getCompanyHubSpaceKey();
	return <SpacePermissionsSettingsRoute {...props} params={{ spaceKey, ...params, tab }} />;
};

AdminCompanyHubPermissionsRoute.NAVIGATION_PARAMS = (routeMatch: RouteMatch) => {
	const { name, params } = routeMatch;

	const tab = getPreConsolidatedSettingsTab(params);
	const spaceKey = getCompanyHubSpaceKey();

	// If consolidated settings is false, we do not want to use the space settings navigation
	const navView = getCompanyHubSideNav(routeMatch)
		? ADMIN_HOME_ID
		: fg('company_hub_consolidated_settings')
			? COMPANY_HUB_SETTINGS_ID
			: PRODUCT_HOME_ID;

	return {
		Screen: {
			screenEvent: {
				name: `${tab || 'consolidated'}HubPermissionsScreen`,
				id: spaceKey,
			},
			pageState: { spaceKey, routeName: name },
		},
		MainLayout: {
			navView,
			spaceKey,
		},
	};
};

function getPreConsolidatedSettingsTab({ tab }: RouteMatch['params']) {
	if (!tab && !fg('company_hub_consolidated_settings')) {
		tab = DEFAULT_TAB;
	}
	return tab;
}
