import type { ApolloQueryResult, ApolloClient } from 'apollo-client';
import type { DocumentNode, GraphQLError } from 'graphql';
import { SSRMeasures } from '@confluence/action-measures';
import { getMonitoringClient } from '@confluence/monitoring';

import { SessionDataQuery } from './SessionDataQuery.graphql';
import type { SessionDataQuery as SessionDataQueryType } from './__types__/SessionDataQuery';
import type { SessionDataType } from './SessionDataTypes';
import { SiteInformationQuery } from './SiteInformationQuery.graphql';
import { FeatureFlagsQuery } from './FeatureFlagsQuery.graphql';
import type { SiteInformationQuery as SiteInformationQueryType } from './__types__/SiteInformationQuery';
import type { FeatureFlagsQuery as FeatureFlagsQueryType } from './__types__/FeatureFlagsQuery';

declare global {
	interface Window {
		__SITEINFORMATION_PROMISE__: Promise<SessionDataType> | null;
		__SITEINFORMATION__:
			| Promise<(SiteInformationQueryType & FeatureFlagsQueryType) | null>
			| (SiteInformationQueryType & FeatureFlagsQueryType)
			| Promise<null>
			| null;
		__LD_FEATURE_FLAGS__?: FeatureFlagsQueryType;
		__ENABLE_SITE_INFORMATION_QUERY__?: boolean;
	}
}

function apolloQuery<Q>({
	query,
	queryName,
	client = window.GLOBAL_APOLLO_CLIENT,
}: {
	query: DocumentNode;
	queryName: string;
	client?: ApolloClient<any>;
}): Promise<{ data: Q | null }> {
	if (!client) {
		return Promise.reject(new Error(`ApolloClient not available, cannot load query ${queryName}`));
	}

	return SSRMeasures.run(`ssr-app/prepare/preloadQuery/fetch:${queryName}`, async () =>
		client.query({
			query,
			errorPolicy: 'all',
			fetchPolicy: 'cache-first',
			context: {
				single: false,
				allowOnExternalPage: true,
			},
		}),
	);
}

function writeQuery<TData>(options: {
	query: DocumentNode;
	data: TData;
	client?: ApolloClient<any>;
}) {
	const client = options.client || window.GLOBAL_APOLLO_CLIENT;
	if (client) {
		client.writeQuery(options);
	}
}

export async function loadSessionData(): Promise<
	(SiteInformationQueryType & FeatureFlagsQueryType) | null
> {
	// __SITEINFORMATION__ is either actual session data
	// or the promise that is resolving the session data
	// In the case when page is rendered by SSR, the __SITEINFORMATION__ will be populated with JSON data.
	if (window['__SITEINFORMATION__']) {
		return window['__SITEINFORMATION__'];
	}

	const queries: any = [];
	const isSiteInformationQueryEnabledValue =
		window['__ENABLE_SITE_INFORMATION_QUERY__'] || process.env.NODE_ENV === 'testing';
	queries.push(
		apolloQuery<SiteInformationQueryType | SessionDataQueryType | null>({
			query: isSiteInformationQueryEnabledValue ? SiteInformationQuery : SessionDataQuery,
			queryName: 'SiteInformationQuery',
		}),
	);

	if (window['__LD_FEATURE_FLAGS__']) {
		writeQuery<FeatureFlagsQueryType>({
			query: FeatureFlagsQuery,
			data: window['__LD_FEATURE_FLAGS__'],
		});
		queries.push({
			data: window['__LD_FEATURE_FLAGS__'],
		});
	} else {
		queries.push(
			apolloQuery<FeatureFlagsQueryType | null>({
				query: FeatureFlagsQuery,
				queryName: 'FeatureFlagsQuery',
			}),
		);
	}

	window['__SITEINFORMATION__'] = Promise.all(queries)
		.then((queriesRes) => {
			const SiteInformationQueryResult =
				queriesRes[0] as ApolloQueryResult<SiteInformationQueryType | null>;
			const featureFlagsQueryResult =
				queriesRes[1] as ApolloQueryResult<FeatureFlagsQueryType | null>;

			const errors: GraphQLError[] = ([] as GraphQLError[]).concat(
				SiteInformationQueryResult.errors ?? [],
				featureFlagsQueryResult.errors ?? [],
			);

			if (errors.length > 0) {
				throw errors;
			}

			let result: (SiteInformationQueryType & FeatureFlagsQueryType) | null = null;

			if (SiteInformationQueryResult.data && featureFlagsQueryResult.data) {
				result = {
					...SiteInformationQueryResult.data,
					...featureFlagsQueryResult.data,
				};
			}
			return result;
		})
		.catch((error) => {
			if (Array.isArray(error)) {
				error.forEach((e) => getMonitoringClient().submitError(e, { attribution: 'FATAL' }));
			} else {
				getMonitoringClient().submitError(error, { attribution: 'FATAL' });
			}

			return null;
		});

	return window['__SITEINFORMATION__'];
}
