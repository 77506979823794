import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import { getEditorViewPluginKey } from './get-editor-view-plugin-key';

/**
 * Plugin used to provide access to the EditorView to the EditorPluginAI
 */
export const createGetEditorViewPlugin = ({
	setEditorView,
}: {
	setEditorView: (editorView: EditorView) => void;
}) =>
	new SafePlugin({
		key: getEditorViewPluginKey,
		view(editorView) {
			setEditorView(editorView);
			return {};
		},
	});
