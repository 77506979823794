import { type IntlShape } from 'react-intl-next';
/**
 * This should not be treated as a fact we are attempting
 * to match as many responses with acceptable use warnings
 * however, we may get false positives or false negatives
 *
 * TODO POST EAP
 * Modify this when the backend is able to return
 * acceptable-use-warning flags to the frontend
 *
 * @markdown string to check for acceptable use warning
 * @returns boolean | undefined if the users locale is not english
 */
export const getHasAcceptableUseWarning = ({
	markdown = '',
	locale,
}: {
	locale: IntlShape['locale'];
	markdown?: string;
}): boolean | undefined => {
	const isEnglishLocale = locale.startsWith('en');

	if (!isEnglishLocale) {
		return;
	}

	const markdownLowercase = markdown.toLowerCase();
	return getHasSearchString(markdownLowercase);
};

const getHasSearchString = (stringToSearch: string): boolean => {
	const acceptableUsePolicySearchStrings = [
		"i'm sorry, but i can't fulfil",
		"i'm sorry, but i cannot fulfil",
		"i am sorry, but i can't fulfil",
		'i am sorry, but i cannot fulfil',
		"atlassian's acceptable use",
		'i apologise, but i cannot fulfil',
		"i apologise, but i can't fulfil",
		'i apologize, but i cannot fulfil',
		"i apologize, but i can't fulfil",
	];

	const hasSearchString = acceptableUsePolicySearchStrings.some((searchString) => {
		return stringToSearch.includes(searchString);
	});

	return hasSearchString;
};
