import React from 'react';

import { useIntl } from 'react-intl-next';

import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import { type Command, type ExtractInjectionAPI } from '@atlaskit/editor-common/types';
import { type EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import type { AIPlugin } from '../editor-plugin-ai';
import { type Recommendation } from '../pm-plugins/ai-proactive/api';
import {
	clearHoverRecommendation,
	hoverRecommendation,
	insertRecommendation,
	removeRecommendation,
	toggleSetting,
	clearSelectRecommendation,
} from '../pm-plugins/ai-proactive/commands';
import {
	selectRecommendationWithAnalytics,
	toggleProactiveAISuggestionDisplayWithAnalytics,
} from '../pm-plugins/ai-proactive/commands-with-analytics';
import { SETTING_KEYS } from '../pm-plugins/ai-proactive/constants';
import { getPluginState as getProactivePluginState } from '../pm-plugins/ai-proactive/plugin-factory';
import type { EditorPluginAIProvider } from '../types';

import { RecommendationPanel } from './recommendation-panel';

export const getContextPanel =
	(editorPluginAIProvider: EditorPluginAIProvider, getEditorView?: () => EditorView | undefined) =>
	(api: ExtractInjectionAPI<AIPlugin> | undefined) =>
	(state: EditorState) => {
		const proactivePluginState = getProactivePluginState(state);
		if (!proactivePluginState) {
			return null;
		}

		const { isProactiveEnabled, isProactiveContextPanelOpen } = proactivePluginState;
		if (!isProactiveEnabled || !isProactiveContextPanelOpen) {
			return null;
		}

		const editorView = getEditorView?.();
		return (
			<ContextPanel
				api={api}
				editorView={editorView}
				editorPluginAIProvider={editorPluginAIProvider}
			/>
		);
	};

type ContextPanelProps = {
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	editorView: EditorView | undefined;
	editorPluginAIProvider: EditorPluginAIProvider;
};

const ContextPanel = ({ api, editorView, editorPluginAIProvider }: ContextPanelProps) => {
	const analyticsApi = api?.analytics?.actions;
	const { locale } = useIntl();
	const { aiExperienceState } = useSharedPluginState(api, ['aiExperience']);
	if (!aiExperienceState || !editorView) {
		return null;
	}

	const proactivePluginState = getProactivePluginState(editorView.state);
	const { documentChecker } = proactivePluginState;

	const {
		recommendations,
		selectedRecommendationId,
		hoveredRecommendationId,
		displayAllSuggestions,
		isLoading,
		isEmptyDocument,
		hasNoMoreSuggestions,
	} = aiExperienceState;

	if (!recommendations) {
		return null;
	}

	const triggeredFrom = 'contextPanel';
	const runCommand = (command: Command) => {
		const { state, dispatch } = editorView;
		command(state, dispatch);
	};

	const onRecommendationApply = ({ recommendation }: { recommendation: Recommendation }) => {
		runCommand(
			insertRecommendation({
				analyticsApi,
				recommendationId: recommendation.id,
				triggeredFrom,
				insertionMethod: 'replace',
			}),
		);
	};

	const onRecommendationDismiss = ({ recommendation }: { recommendation: Recommendation }) => {
		runCommand(
			removeRecommendation({
				analyticsApi,
				recommendationId: recommendation.id,
				triggeredFrom,
				feedback: {
					product: editorPluginAIProvider.product,
					locale,
					editorView,
					handleFeedbackSubmission: editorPluginAIProvider.handleFeedbackSubmission,
				},
			}),
		);
	};

	const onRecommendationSelect = ({ recommendation }: { recommendation: Recommendation }) => {
		runCommand(selectRecommendationWithAnalytics(analyticsApi)(recommendation.id));
	};

	const onRecommendationHover = ({ recommendation }: { recommendation: Recommendation }) => {
		runCommand(hoverRecommendation(recommendation.id));
	};

	const onRecommendationLeave = ({ recommendation }: { recommendation: Recommendation }) => {
		runCommand(clearHoverRecommendation());
	};

	const onPanelClose = () => {
		runCommand(toggleProactiveAISuggestionDisplayWithAnalytics(analyticsApi)(triggeredFrom));
	};

	const onSpellingGrammar = () => {};

	// Ignored via go/ees005
	// eslint-disable-next-line require-await
	const onReloadSuggestions = async () => {
		if (documentChecker) {
			documentChecker.refresh();
		}
	};

	const onToggleSuggestions = () => {
		runCommand(toggleSetting(SETTING_KEYS.ALL_SUGGESTIONS));
	};

	const onClearSelectRecommendation = () => {
		runCommand(clearSelectRecommendation());
	};

	const onAskAI = () => {
		api?.aiExperience?.actions?.openAIModal({ invokedFrom: 'proactiveAIEmptyState' });
		onPanelClose();
	};

	return (
		<RecommendationPanel
			isSpellingGrammarEnabled={false}
			spellingGrammarErrorCount={0}
			recommendations={recommendations}
			hoveredRecommendationId={hoveredRecommendationId}
			selectedRecommendationId={selectedRecommendationId}
			onRecommendationApply={onRecommendationApply}
			onRecommendationDismiss={onRecommendationDismiss}
			onRecommendationSelect={onRecommendationSelect}
			onRecommendationHover={onRecommendationHover}
			onRecommendationLeave={onRecommendationLeave}
			onSpellingGrammar={onSpellingGrammar}
			onReloadSuggestions={onReloadSuggestions}
			isLoading={isLoading}
			onClearSelectRecommendation={onClearSelectRecommendation}
			onPanelClose={onPanelClose}
			editorPluginAIProvider={editorPluginAIProvider}
			editorView={editorView}
			displayAllSuggestions={displayAllSuggestions}
			isEmptyDocument={isEmptyDocument}
			onToggleSuggestions={onToggleSuggestions}
			onAskAI={onAskAI}
			hasNoMoreSuggestions={hasNoMoreSuggestions}
		/>
	);
};
