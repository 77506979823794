/* eslint-disable @typescript-eslint/no-non-null-assertion */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import partition from 'lodash/partition';
import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import { type Command } from '@atlaskit/editor-common/types';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Modal, { ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { Box, Flex, Grid, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { toggleSetting } from '../../pm-plugins/ai-proactive/commands';
import { SETTING_KEYS } from '../../pm-plugins/ai-proactive/constants';
import { getPluginState as getProactivePluginState } from '../../pm-plugins/ai-proactive/plugin-factory';
import {
	getSettingsWithValues,
	type SuggestionItemType,
} from '../../pm-plugins/ai-proactive/suggestion-setting-map';

import messages from './messages';
import { SettingItem } from './setting-item';

const gridStyles = xcss({
	width: '100%',
});

const closeContainerStyles = xcss({
	gridArea: 'close',
});

const titleContainerStyles = xcss({
	gridArea: 'title',
});

const stickyHeaderStyle = xcss({
	position: 'sticky',
	top: 'space.0',
	zIndex: '1', // Added this because toggle is on top
	backgroundColor: 'elevation.surface',
});

const scrollableSuggestionStyle = xcss({
	overflow: 'hidden auto',
	flexShrink: '1',
});

type SuggestionSettingProps = {
	isOpen: boolean;
	toggleModal: () => void;
	editorView: EditorView;
};

const dividerStyles = xcss({
	borderBlockStart: 'solid',
	borderColor: 'color.border',
	borderWidth: 'border.width',
});

const Divider = () => {
	return <Box xcss={dividerStyles} />;
};

export const SuggestionSetting = (props: SuggestionSettingProps) => {
	const { isOpen, toggleModal, editorView } = props;

	const { formatMessage } = useIntl();

	const runCommand = (command: Command) => {
		const { state, dispatch } = editorView;
		command(state, dispatch);
	};

	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const proactivePluginState = getProactivePluginState(editorView!.state);

	const { settings } = proactivePluginState;

	const { displayAllSuggestions } = settings;

	const suggestionSettings = getSettingsWithValues(settings);

	const [allSuggestionSetting, otherSuggestionSettings] = partition(
		suggestionSettings,
		(item: SuggestionItemType) => item.settingKey === SETTING_KEYS.ALL_SUGGESTIONS,
	);

	const renderSettings = (settings: SuggestionItemType[]) => {
		return settings.map(
			({ defaultValue, description, display, Icon, indent, settingKey, title }) => (
				<SettingItem
					key={settingKey}
					icon={<Icon color={token('color.icon.subtle')} />}
					title={formatMessage(title)}
					description={formatMessage(description)}
					// Ignored via go/ees005
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					defaultValue={defaultValue!}
					toggle={() => {
						runCommand(toggleSetting(settingKey));
					}}
					display={display}
					indented={indent}
				/>
			),
		);
	};

	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={toggleModal}>
					<ModalHeader>
						<Grid gap="space.200" templateAreas={['title close']} xcss={gridStyles}>
							<Flex xcss={closeContainerStyles} justifyContent="end">
								<IconButton
									appearance="subtle"
									icon={CrossIcon}
									label="Close Modal"
									onClick={toggleModal}
								/>
							</Flex>
							<Flex xcss={titleContainerStyles} justifyContent="start">
								<ModalTitle>{formatMessage(messages.ManageSuggestionTitle)}</ModalTitle>
							</Flex>
						</Grid>
					</ModalHeader>
					<Box xcss={scrollableSuggestionStyle}>
						<Box xcss={stickyHeaderStyle}>
							<Box paddingInline="space.300">{renderSettings(allSuggestionSetting)}</Box>
							{displayAllSuggestions ? <Divider /> : null}
						</Box>
						<Box paddingInline="space.300" paddingBlockEnd="space.500">
							{renderSettings(otherSuggestionSettings)}
						</Box>
					</Box>
				</Modal>
			)}
		</ModalTransition>
	);
};
