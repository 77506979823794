import { defineMessages } from 'react-intl-next';

export default defineMessages({
	ManageSuggestionTitle: {
		id: 'fabric.editor.ai.proactive.moreMenu.manageSuggestionsTitle.non-final',
		defaultMessage: 'Manage suggestions',
		description:
			'This is the message displayed in the more menu dropdown in the context panel to allow the user to manage suggestions on the suggestions',
	},
});
