import React from 'react';
import uuid from 'uuid/v4';
import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';

import { LoadableAfterPaint } from '@confluence/loadable';

import type { LinkCardsParameters } from '../link-cards/linkCardsTypes';
import { CardSizes, ImagePosition } from '../link-cards/linkCardsTypes';
import { CAROUSEL_VERSION_1 } from './carouselVersion';
import { getExtensionManifest } from '../shared-components/customSitesManifestBuilder';
import type { CustomSitesManifestArguments } from '../shared-components/customSitesManifestBuilder';

import { carouselExtensionType } from './carouselConstants';
import type { CarouselExtensionProps } from './CarouselExtension';

const CarouselExtension = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CarouselExtension" */ './CarouselExtension'))
			.CarouselExtension,
});

const slideImages = [
	{
		src: 'https://images.unsplash.com/photo-1615716272018-7c5b1216e262?auto=format&fit=crop&q=80&w=1080',
		alt: '',
	},
	{
		src: 'https://images.unsplash.com/photo-1615715757448-f8e0cdccf1e2?auto=format&fit=crop&q=80&w=1080',
		alt: '',
	},
	{
		src: 'https://images.unsplash.com/photo-1615716038858-2f9a60c330a6?auto=format&fit=crop&q=80&w=1080',
		alt: '',
	},
];

const i18n = defineMessages({
	carouselMacroTitle: {
		id: 'custom-sites-extensions.carousel.manifest.macro-title',
		defaultMessage: 'Carousel',
		description: 'Title of carousel macro to be displayed in toolbar/shortcut macros dropdown.',
	},
	carouselMacroDescription: {
		id: 'custom-sites-extensions.carousel.manifest.macro-description',
		defaultMessage: 'Create a rotating trio of dynamic frames.',
		description:
			'Description of carousel macro to be displayed in toolbar/shortcut macros dropdown',
	},
	carouselMacroTitleNew: {
		id: 'custom-sites-extensions.carousel.manifest.macro-title.new',
		defaultMessage: 'Carousel [New]',
		description:
			'Title of carousel macro to be displayed in toolbar/shortcut macros dropdown. The [New] text is a text label indicating that this feature is new.',
	},
});

const createSlide = (imageSrc: string, imageAltText: string) => ({
	cardId: uuid(),
	link: '',
	isLinkUserEdited: false,
	title: '',
	isTitleUserEdited: false,
	description: '',
	isDescriptionUserEdited: false,
	imageSrc,
	imagePosition: ImagePosition.MIDDLE,
	imageAltText,
});

let cards: ReturnType<typeof createSlide>[] | null = null;
const getCards = () => {
	if (cards) {
		return cards;
	}
	return (cards = slideImages.map(({ src, alt }) => createSlide(src, alt)));
};

const linkCardsParameters = (extensionTitle: string) => ({
	size: CardSizes.SMALL,
	cards: getCards(),
	isAvatarShown: false,
	isPublishDateShown: false,
	extensionTitle,
	version: CAROUSEL_VERSION_1,
});

type CarouselManifestArguments = {
	intl: IntlShape;
} & Pick<
	CarouselExtensionProps,
	'openCarouselConfigPanel' | 'editorActions' | 'contentId' | 'createAnalyticsEvent'
> &
	Pick<CustomSitesManifestArguments<LinkCardsParameters>, 'setUpsellModalSource'>;

export const carouselExtensionProvider = async ({
	editorActions,
	openCarouselConfigPanel,
	setUpsellModalSource,
	intl,
	...extensionPassThroughProps
}: CarouselManifestArguments) => {
	const title = intl.formatMessage(i18n.carouselMacroTitleNew);
	const type = carouselExtensionType;
	const description = intl.formatMessage(i18n.carouselMacroDescription);
	const render =
		async () =>
		({ node }) => {
			return (
				<CarouselExtension
					extensionNode={node}
					openCarouselConfigPanel={openCarouselConfigPanel}
					editorActions={editorActions}
					{...extensionPassThroughProps}
				/>
			);
		};

	const icons = {
		'48': () =>
			import(
				/* webpackChunkName: "loadable-CarouselMacroIcon" */
				'@confluence/icons/entry-points/CarouselMacroIcon'
			).then((mod) => mod.CarouselMacroIcon),
	};

	const parameters = linkCardsParameters(
		intl.formatMessage(i18n.carouselMacroTitle),
	) as LinkCardsParameters;

	const update = async () => {
		const selectedNode = editorActions?.getSelectedNode()?.toJSON();
		const selectedLocalId = selectedNode?.attrs?.localId;

		if (!!selectedLocalId && openCarouselConfigPanel) {
			openCarouselConfigPanel(selectedLocalId);
		}
	};

	return new DefaultExtensionProvider([
		getExtensionManifest<any>({
			key: 'carousel',
			icons,
			parameters,
			update,
			render,
			title,
			type,
			description,
			setUpsellModalSource,
			source: 'premiumCarouselInsert',
		}),
	]);
};
