// This file can't be exported from generative-ai-modal due to issues
// with Playwright not picking up the imported file.
// It's not clear why this is a problem and needs to be revisited.
import React from 'react';

import { useIntl } from 'react-intl-next';

import { LogoSvg } from '@atlassian/generative-ai-modal/assets/Logo';

import messages from '../../../messages';

export const Logo = () => {
	const { formatMessage } = useIntl();
	return <LogoSvg alt={formatMessage(messages.logoAltText)} />;
};
