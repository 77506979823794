import gql from 'graphql-tag';

export const UpdateLegacyDefaultPermissionsMutation = gql`
	mutation UpdateLegacyDefaultPermissionsMutation($input: UpdateDefaultSpacePermissionsInputV2!) {
		updateSpacePermissionDefaultsV2(input: $input) {
			success
			errors {
				extensions {
					errorType
					statusCode
				}
				message
			}
		}
	}
`;
