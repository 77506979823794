import { EditorView } from '@atlaskit/editor-prosemirror/view';

import { convoAIAuditLogApiURL } from '../../prebuilt/utils';

import { createCommand, getPluginState } from './ai-audit-log-plugin-factory';
import { type AIAuditLogUpdatePluginState, type AuditLogEvents } from './types';

export async function sendAuditLogEvent(event: AuditLogEvents, view: EditorView) {
	/**
	 * Always get pluginState from view.state as view.state is always latest state
	 * 	as sendAuditLogEvent is called in recursion and depend on pluginState
	 * 	updated by previous call.
	 */

	const pluginState = getPluginState(view.state);
	const { eventData, retryDelay } = pluginState;
	const eventState = pluginState[event];
	const entityId = eventData.type === 'page' ? eventData.pageId : eventData.issueId;

	updateAIAuditLogPluginState({
		[event]: {
			retries: eventState.retries + 1,
		},
	})(view.state, view.dispatch);

	function retrySendingEvent() {
		if (eventState.retries < pluginState.maxRetries) {
			setTimeout(() => {
				sendAuditLogEvent(event, view);
			}, retryDelay);
		}
	}

	try {
		// API spec is not finalised yet.
		// We may have one or two separate APIs, don't know yet.
		// So below section will be changed once spec is finalised.
		const response = await fetch(convoAIAuditLogApiURL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				event,
				ai_feature_input: {
					entityId,
				},
			}),
		});

		if (response.ok) {
			updateAIAuditLogPluginState({
				[event]: {
					eventStatus: 'event-sent',
				},
			})(view.state, view.dispatch);
		} else {
			retrySendingEvent();
		}
	} catch (e) {
		retrySendingEvent();
	}
}

export const updateAIAuditLogPluginState = (pluginState: AIAuditLogUpdatePluginState['data']) =>
	createCommand(
		(state) => {
			return {
				type: 'UPDATE_PLUGIN_STATE',
				data: pluginState,
			};
		},
		(tr) => tr.setMeta('addToHistory', false),
	);
