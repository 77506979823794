/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum RoleAssignmentPrincipalType {
  ACCESS_CLASS = "ACCESS_CLASS",
  GROUP = "GROUP",
  TEAM = "TEAM",
  USER = "USER",
}

export interface RoleAssignment {
  principal: RoleAssignmentPrincipalInput;
  roleId: string;
}

export interface RoleAssignmentPrincipalInput {
  principalId: string;
  principalType: RoleAssignmentPrincipalType;
}

export interface SetDefaultSpaceRoleAssignmentsInput {
  spaceRoleAssignmentList: RoleAssignment[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDefaultSpaceRoleAssignments
// ====================================================

export interface SetDefaultSpaceRoleAssignments_setDefaultSpaceRoleAssignments_errors_extensions {
  errorType: string | null;
  statusCode: number | null;
}

export interface SetDefaultSpaceRoleAssignments_setDefaultSpaceRoleAssignments_errors {
  message: string | null;
  extensions: (SetDefaultSpaceRoleAssignments_setDefaultSpaceRoleAssignments_errors_extensions | null)[] | null;
}

export interface SetDefaultSpaceRoleAssignments_setDefaultSpaceRoleAssignments {
  errors: SetDefaultSpaceRoleAssignments_setDefaultSpaceRoleAssignments_errors[] | null;
  success: boolean;
}

export interface SetDefaultSpaceRoleAssignments {
  setDefaultSpaceRoleAssignments: SetDefaultSpaceRoleAssignments_setDefaultSpaceRoleAssignments;
}

export interface SetDefaultSpaceRoleAssignmentsVariables {
  input: SetDefaultSpaceRoleAssignmentsInput;
}
