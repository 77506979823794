/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled
import { useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';

import messages from './messages';

const footerStyles = xcss({
	display: 'flex',
	padding: 'space.075',
	gap: 'space.050',
	borderTopLeftRadius: 'unset',
	borderTopRightRadius: 'unset',
	backgroundColor: 'elevation.surface',
	borderTopWidth: '1px',
	borderTopStyle: 'solid',
	borderTopColor: 'color.border',
	alignItems: 'center',
});

const labelStyles = css({
	color: token('color.text.subtlest'),
});

export function UsePageKnowledgeToggleFooter({
	onChange,
	isChecked,
}: {
	onChange: () => void;
	isChecked: boolean;
}) {
	const { formatMessage } = useIntl();
	const TOGGLE_ID = 'page-knowledge-toggle';

	return (
		<Box xcss={footerStyles}>
			<Toggle id={TOGGLE_ID} testId={TOGGLE_ID} onChange={onChange} isChecked={isChecked} />
			<label htmlFor={TOGGLE_ID} css={labelStyles}>
				{formatMessage(messages.usePageKnowledgeToggleLabel)}
			</label>
		</Box>
	);
}
