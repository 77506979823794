import { useIsCurrentPageLiveRaw } from './LivePagesStore';

/**
 * Returns if the current page is of subtype `live`. We trust that BE only returns `live` subtype only if the feature is enabled.
 * To check if live pages as a feature is enabled, use `isLivePagesFeatureEnabled`.
 * @returns { boolean } if the current page is of subtype `live`
 */
export const useIsCurrentPageLive = (): boolean => {
	const [isLive] = useIsCurrentPageLiveRaw();
	return isLive;
};
