import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

const iconStyles = xcss({
	width: token('space.300'),
	height: token('space.300'),
	cursor: 'pointer',
});

const i18n = defineMessages({
	warningMessage: {
		id: 'space-roles.add-principal-modal.warning-message',
		defaultMessage: 'Guests can’t be assigned this role.',
		description: 'Warning message for incompatible guests role assignment',
	},
	warningMessageExistingSpace: {
		id: 'space-roles.add-principal-modal.warning-message-existing-space',
		defaultMessage: 'This guest is already assigned to a space.',
		description: 'Warning message for incompatible guests role assignment',
	},
});

export const GuestRoleWarningMessageIcon = ({ noRoleOptions }: { noRoleOptions: boolean }) => {
	const { formatMessage } = useIntl();
	return (
		<Tooltip
			content={
				noRoleOptions
					? formatMessage(i18n.warningMessageExistingSpace)
					: formatMessage(i18n.warningMessage)
			}
		>
			<Box xcss={iconStyles}>
				<ErrorIcon
					color={token('color.icon.danger')}
					label="warning-icon"
					testId="guest-role-warning"
				/>
			</Box>
		</Tooltip>
	);
};
