/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ReactNode } from 'react';

import { css, jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled

import { Stack, Flex, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { BackIconButton } from '../BackIconButton/BackIconButton';

const divider = css({
	borderTop: `1px solid ${token('color.border')}`,
});

interface Props {
	tag: ReactNode;
	onBack?: () => void;
	promptBuilder: ReactNode;
}

export function AdvancedPromptForm({ tag, onBack, promptBuilder }: Props) {
	return (
		<Stack grow="fill">
			<Box padding="space.150">
				<Flex alignItems="center">
					{onBack && <BackIconButton inPromptHeader stopPropagation onClick={onBack} />}
					{tag}
				</Flex>
			</Box>

			<div css={divider} />

			{promptBuilder}
		</Stack>
	);
}
