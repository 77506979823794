import memoize from 'memoize-one';
import idx from 'idx';

import {
	VIEW_ALL_MACRO_EXPERIENCE,
	VIEW_JIRA_MACRO_EXPERIENCE,
	ExperienceTracker,
	getExperienceTracker, // eslint-disable-line no-restricted-imports
	ExperienceTimeout,
	ExperienceTimeoutError,
} from '@confluence/experience-tracker';
import type {
	ExperienceEvent,
	SuccessEvent,
	FailEvent,
	AbortEvent,
} from '@confluence/experience-tracker';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { getUniquePageLoadId } from '@confluence/unique-page-load-id';

import type {
	MacroExperienceAttribute,
	MacroExperienceStartAttribute,
	MacroExperienceSuccessAttribute,
	MacroExperienceFailureAttribute,
	MacroExperienceAbortAttribute,
	MacrosExperience,
	MacroAttributes,
	Mode,
	Extension,
} from './types';
import { getExtensionKey, getMacroId, JIRA_EXTENSION_KEY } from './getExtensionKey';

export const RENDERER: Mode = 'renderer';
export const EDITOR: Mode = 'editor';
export const PDF: Mode = 'pdf';

const ACTION = {
	SUCCESS: 'taskSuccess',
	FAIL: 'taskFail',
	ABORT: 'taskAbort',
	START: 'taskStart',
};

export const getMacroName = (name) => name.split('.').slice(1).join('.');

export const getMacroData = (
	macroEventRegister = new Map<string, MacroExperienceAttribute>(),
): MacrosExperience[] => {
	const macros: MacrosExperience[] = [];
	// @ts-ignore
	for (const v of macroEventRegister.values()) {
		const { name } = v;

		if (name !== VIEW_ALL_MACRO_EXPERIENCE) {
			const macro = {
				uniqueHash: getMacroName(name),
				...getMacroAttributesFromEvent(v),
			};

			macros.push(macro);
		}
	}

	return macros;
};

export const getMacroAttributesFromADFNode = (macro: Extension) => {
	return {
		type: idx(macro, (_) => _.type) || '',
		macroId: getMacroId(idx(macro, (_) => _.parameters)),
		extensionKey: getExtensionKey(macro as MacroAttributes),
		extensionType: idx(macro, (_) => _.extensionType) || '',
	};
};

export const getMacroAttributesFromEvent = (macroEvent): MacroAttributes => {
	return {
		extensionKey: getExtensionKey(idx(macroEvent, (_) => _.experienceAttributes)),
		extensionType: idx(macroEvent, (_) => _.experienceAttributes.extensionType) || '',
		type: idx(macroEvent, (_) => _.experienceAttributes.type) || '',
		macroId: idx(macroEvent, (_) => _.experienceAttributes.macroId) || '',
		duration: idx(macroEvent, (_) => _.duration) || '',
		status: idx(macroEvent, (_) => _.status) || '',
		pageId: idx(macroEvent, (_) => _.pageId) || '',
	};
};

export const startMacroExperience = (mode, contentId) => {
	const macroEventRegister = getMacroEventRegister(mode, contentId);

	if (macroEventRegister.has(contentId as string)) return;

	getMacroExperienceTracker(mode, contentId).start({
		attributes: {
			mode,
		},
		name: VIEW_ALL_MACRO_EXPERIENCE,
		timeout: ExperienceTimeout.MACRO_LOAD,
		id: contentId,
	});
};

export const stopMacroExperience = (mode, contentId) => {
	const macroEventRegister = getMacroEventRegister(mode, contentId);

	if (macroEventRegister.has(contentId as string)) {
		if (checkAllMacroLoaded(macroEventRegister)) {
			const macroAttributes = { mode, macros: null };

			if (hasSubExperiences(macroEventRegister)) {
				macroAttributes.macros = getMacroData(macroEventRegister) as any;
			}

			getExperienceTracker().succeed({
				name: VIEW_ALL_MACRO_EXPERIENCE,
				attributes: {
					...macroAttributes,
				},
			});
		} else {
			getExperienceTracker().abort({
				name: VIEW_ALL_MACRO_EXPERIENCE,
				reason: `Some macro aborted before completion in ${ExperienceTimeout.MACRO_LOAD}ms`,
				attributes: {
					macros: getMacroData(macroEventRegister) as any,
					mode,
				},
			});
		}

		const jiraMacro = getJIRAMacro(macroEventRegister);

		jiraMacro.forEach((macro) => {
			const extensionKey = getExtensionKey(macro.experienceAttributes as MacroAttributes);

			const status = macro.status;

			void getAnalyticsWebClient().then((analyticsWebClient) => {
				analyticsWebClient.sendOperationalEvent({
					source: 'ui',
					actionSubject: 'macroTracker',
					action: status,
					attributes: {
						...macro.experienceAttributes,
						extensionKey,
						mode,
						task: `${VIEW_JIRA_MACRO_EXPERIENCE}`,
					},
				});
			});
		});

		// clean up after aborting all event
		macroEventRegister.clear();

		macroEventRegisterHash.set(`${mode}.${contentId}`, macroEventRegister);
	}
};

export const checkAllMacroLoaded = (
	eventRegister = new Map<string, MacroExperienceAttribute>(),
) => {
	if (eventRegister.size === 0) return true;
	// only check whether all sub-experience has finished
	// @ts-ignore
	for (const v of eventRegister.values()) {
		if (v.name !== VIEW_ALL_MACRO_EXPERIENCE && v.status !== ACTION.SUCCESS) {
			return false;
		}
	}

	return true;
};

export const hasSubExperiences = (eventRegister = new Map<string, MacroExperienceAttribute>()) => {
	// @ts-ignore
	for (const v of eventRegister.values()) {
		if (v.name !== VIEW_ALL_MACRO_EXPERIENCE) {
			return true;
		}
	}
	return false;
};

export const isJIRAMacro = (name) => name.includes(JIRA_EXTENSION_KEY);

export const getUnsuceededJIRAMacro = (eventRegister: Map<string, MacroExperienceAttribute>) => {
	const macros: MacroExperienceAttribute[] = [];
	// Type 'IterableIterator<[string, MacroExperienceAttribute]>' is not an array type
	// or a string type. Use compiler option '--downlevelIteration'
	// to allow iterating of iterators
	// @ts-ignore
	for (const v of eventRegister.values()) {
		if (isJIRAMacro(v.name) && v.status !== ACTION.SUCCESS) {
			macros.push(v);
		}
	}
	return macros;
};

export const getJIRAMacro = (eventRegister: Map<string, MacroExperienceAttribute>) => {
	const macros: MacroExperienceAttribute[] = [];
	// Type 'IterableIterator<[string, MacroExperienceAttribute]>' is not an array type
	// or a string type. Use compiler option '--downlevelIteration'
	// to allow iterating of iterators
	// @ts-ignore
	for (const v of eventRegister.values()) {
		if (isJIRAMacro(v.name)) {
			macros.push(v);
		}
	}
	return macros;
};

// perform interval check on all macro and abort the ones that timed out after 10000 ms
export const checkMacroStatuses = (contentId, mode) => {
	(function macroTimeout() {
		setTimeout(() => {
			const macroEventRegister = getMacroEventRegister(mode, contentId);

			if (!macroEventRegister.has(contentId)) return;

			const pageEvent = macroEventRegister.get(contentId) as MacroExperienceStartAttribute;
			const isTimedOut =
				Math.round(window.performance.now() - pageEvent.startTime) > ExperienceTimeout.MACRO_LOAD;

			// 1. all macro loaded or main-experience timeout and have sub-experience
			//    succeed the main experience and report to experience tracker and done
			if (checkAllMacroLoaded(macroEventRegister) && hasSubExperiences(macroEventRegister)) {
				getExperienceTracker().succeed({
					name: VIEW_ALL_MACRO_EXPERIENCE,
					attributes: {
						macros: getMacroData(macroEventRegister) as any,
						mode,
					},
				});

				// 2. timeout
			} else if (isTimedOut) {
				// 2a. timeout does not has sub-experience, succeed the main experience
				if (!hasSubExperiences(macroEventRegister)) {
					getExperienceTracker().succeed({
						name: VIEW_ALL_MACRO_EXPERIENCE,
						attributes: {
							mode,
						},
					});

					// 2b. timeout and has sub-experience, fail the main experience and report to experience tracker
				} else {
					getExperienceTracker().abort({
						name: VIEW_ALL_MACRO_EXPERIENCE,
						reason: `Some macro aborted before completion in ${ExperienceTimeout.MACRO_LOAD}ms`,
						attributes: {
							macros: getMacroData(macroEventRegister) as any,
							mode,
						},
					});

					const unsuceededJIRAMacro = getUnsuceededJIRAMacro(macroEventRegister);

					unsuceededJIRAMacro.forEach((macro) => {
						const extensionKey = getExtensionKey(macro.experienceAttributes as MacroAttributes);

						void getAnalyticsWebClient().then((analyticsWebClient) => {
							analyticsWebClient.sendOperationalEvent({
								source: 'ui',
								actionSubject: 'macroTracker',
								action: 'taskAbort',
								attributes: {
									...macro.experienceAttributes,
									extensionKey,
									mode,
									task: `${VIEW_JIRA_MACRO_EXPERIENCE}`,
									reason: `JIRA macro aborted before completion in ${ExperienceTimeout.MACRO_LOAD}ms`,
								},
							});
						});
					});
				}

				// clean up after aborting all event
				macroEventRegister.clear();

				macroEventRegisterHash.set(`${mode}.${contentId}`, macroEventRegister);
			} else {
				macroTimeout();
			}
		}, 2000);
	})();
};

const macroEventRegisterHash = new Map<string, Map<string, MacroExperienceAttribute>>();

export const getMacroEventRegister = (mode, contentId): Map<string, MacroExperienceAttribute> => {
	if (macroEventRegisterHash.has(`${mode}.${contentId}`)) {
		return macroEventRegisterHash.get(`${mode}.${contentId}`) as Map<
			string,
			MacroExperienceAttribute
		>;
	}

	macroEventRegisterHash.set(`${mode}.${contentId}`, new Map<string, MacroExperienceAttribute>());

	return macroEventRegisterHash.get(`${mode}.${contentId}`) as Map<
		string,
		MacroExperienceAttribute
	>;
};

export const startJIRAMacro = (mode, contentId, name, experienceAttributes) => {
	if (isJIRAMacro(name)) {
		const extensionKey = getExtensionKey(experienceAttributes as MacroAttributes);

		void getAnalyticsWebClient().then((analyticsWebClient) => {
			analyticsWebClient.sendOperationalEvent({
				source: 'ui',
				actionSubject: 'macroTracker',
				action: 'taskStart',
				attributes: {
					...experienceAttributes,
					extensionKey,
					mode,
					pageId: contentId,
					task: `${VIEW_JIRA_MACRO_EXPERIENCE}`,
					pageLoadInfo: getUniquePageLoadId(),
				},
			});
		});
	}
};

export const stopJIRAMacro = (mode, contentId, name, experienceAttributes) => {
	const macroEventRegister = getMacroEventRegister(mode, contentId);
	const macroId = idx(experienceAttributes, (_) => _.macroId) as string;

	if (!macroEventRegister.has(macroId)) return;

	if (isJIRAMacro(name)) {
		const extensionKey = getExtensionKey(experienceAttributes as MacroAttributes);
		const { status } = macroEventRegister.get(macroId) as MacroExperienceAttribute;

		if (status === ACTION.SUCCESS) {
			const { duration } = macroEventRegister.get(macroId) as MacroExperienceSuccessAttribute;

			void getAnalyticsWebClient().then((analyticsWebClient) => {
				analyticsWebClient.sendOperationalEvent({
					source: 'ui',
					actionSubject: 'macroTracker',
					action: 'taskSuccess',
					attributes: {
						...experienceAttributes,
						extensionKey,
						mode,
						duration,
						task: `${VIEW_JIRA_MACRO_EXPERIENCE}`,
						pageLoadInfo: getUniquePageLoadId(),
					},
				});
			});
		} else if (status === ACTION.ABORT) {
			const { duration } = macroEventRegister.get(macroId) as MacroExperienceAbortAttribute;

			void getAnalyticsWebClient().then((analyticsWebClient) => {
				analyticsWebClient.sendOperationalEvent({
					source: 'ui',
					actionSubject: 'macroTracker',
					action: 'taskAbort',
					attributes: {
						...experienceAttributes,
						extensionKey,
						mode,
						duration,
						task: `${VIEW_JIRA_MACRO_EXPERIENCE}`,
						reason: `JIRA macro aborted before completion in ${ExperienceTimeout.MACRO_LOAD}ms`,
					},
				});
			});
		} else if (status === ACTION.FAIL) {
			const { duration } = macroEventRegister.get(macroId) as MacroExperienceFailureAttribute;

			void getAnalyticsWebClient().then((analyticsWebClient) => {
				analyticsWebClient.sendOperationalEvent({
					source: 'ui',
					actionSubject: 'macroTracker',
					action: 'taskFail',
					attributes: {
						...experienceAttributes,
						extensionKey,
						mode,
						duration,
						task: `${VIEW_JIRA_MACRO_EXPERIENCE}`,
						error: new ExperienceTimeoutError(
							`JIRA macro failed to complete in ${ExperienceTimeout.MACRO_LOAD}ms`,
						),
					},
				});
			});
		}
	}
};

export const getMacroExperienceTracker = memoize((mode, contentId) => {
	const macroTracker = new ExperienceTracker();
	const macroEventRegister = getMacroEventRegister(mode, contentId);

	macroTracker.subscribe((event: ExperienceEvent) => {
		const { action, name, attributes: experienceAttributes, ...rest } = event;

		const macroId = idx(experienceAttributes, (_) => _.macroId) as string;

		if (action === ACTION.START) {
			// prevent triggering of event
			const current = macroEventRegister.get(macroId);
			if (current && current.id === macroId) return;

			if (name === VIEW_ALL_MACRO_EXPERIENCE) {
				// prevent firing the same view-all-macro more than once
				if (!macroEventRegister.has(contentId)) {
					// clear the event register, prevent event being re-tracked between SPA transition
					macroEventRegister.clear();

					getExperienceTracker().start({
						name: VIEW_ALL_MACRO_EXPERIENCE,
						timeout: ExperienceTimeout.MACRO_LOAD,
						id: contentId,
						attributes: {
							mode,
						},
					});

					macroEventRegister.set(contentId, {
						status: ACTION.START,
						name,
						mode,
						...rest,
					});

					macroEventRegisterHash.set(`${mode}.${contentId}`, macroEventRegister);
				}
			} else {
				// tracking each macro, report to MacroTracker
				macroEventRegister.set(macroId, {
					status: ACTION.START,
					name,
					experienceAttributes: {
						...experienceAttributes,
						extensionKey: getExtensionKey(experienceAttributes as MacroAttributes),
					},
					pageId: contentId,
					mode,
					...rest,
				});

				startJIRAMacro(mode, contentId, name, experienceAttributes);
			}
		} else if (action === ACTION.SUCCESS) {
			// if event is not yet track
			if (!macroEventRegister.has(macroId)) return;

			const { duration, name } = event as SuccessEvent;

			// tracking each macro when it successfully loads
			macroEventRegister.set(macroId, {
				...macroEventRegister.get(macroId),
				id: macroId,
				name,
				mode,
				status: ACTION.SUCCESS,
				duration,
				pageId: contentId,
			});

			macroEventRegisterHash.set(`${mode}.${contentId}`, macroEventRegister);

			stopJIRAMacro(mode, contentId, name, experienceAttributes);
		} else if (action === ACTION.ABORT) {
			// if event is not yet track
			if (!macroEventRegister.has(macroId)) return;
			const { duration, reason, name } = event as AbortEvent;

			// tracking each macro when it aborts loading
			macroEventRegister.set(macroId, {
				...macroEventRegister.get(macroId),
				id: macroId,
				name,
				mode,
				status: ACTION.ABORT,
				duration,
				pageId: contentId,
				reason,
			});

			macroEventRegisterHash.set(`${mode}.${contentId}`, macroEventRegister);

			// one macro aborts to load, aborts the entire experience and report to ExperienceTracker
			getExperienceTracker().abort({
				name: VIEW_ALL_MACRO_EXPERIENCE,
				reason: 'Macro abort loading',
				attributes: {
					macros: getMacroData(macroEventRegister) as any,
					mode,
				},
			});

			stopJIRAMacro(mode, contentId, name, experienceAttributes);
		} else if (action == ACTION.FAIL) {
			// if event is not yet track
			if (!macroEventRegister.has(macroId)) return;

			const { duration, error, name } = event as FailEvent;

			// tracking each macro when it fails to load
			macroEventRegister.set(macroId, {
				...macroEventRegister.get(macroId),
				id: macroId,
				name,
				mode,
				status: ACTION.FAIL,
				duration,
				pageId: contentId,
				error,
			});

			macroEventRegisterHash.set(`${mode}.${contentId}`, macroEventRegister);

			// one macro fails to load, fails the entire experience and report to ExperienceTracker
			getExperienceTracker().fail({
				name: VIEW_ALL_MACRO_EXPERIENCE,
				error,
				attributes: {
					macros: getMacroData(macroEventRegister) as any,
					mode,
					...experienceAttributes,
				},
			});

			stopJIRAMacro(mode, contentId, name, experienceAttributes);
		}

		checkMacroStatuses(contentId, mode);
	});

	return macroTracker;
});

// macroId prevents simultaneous start/stop in experience tracker
// by distinguishing amongst the macros with the same extensionKey
export const getExperienceName = (mode: Mode, extension: Extension): string => {
	const attributes = getMacroAttributesFromADFNode(extension);

	return `${mode}.${getExtensionKey(attributes as MacroAttributes)}.${attributes.macroId}`;
};
