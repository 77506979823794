import React from 'react';

import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { Stack, Text } from '@atlaskit/primitives';

import ReloadSuggestionsImageDark from '../assets/reload-suggestions/dark.svg';
import ReloadSuggestionsImageLight from '../assets/reload-suggestions/light.svg';
import messages from '../messages';

export const ReloadSuggestionsState = ({
	handleReloadSuggestions,
}: {
	handleReloadSuggestions: () => void;
}) => {
	const { formatMessage } = useIntl();
	<Image src={ReloadSuggestionsImageLight} srcDark={ReloadSuggestionsImageDark} />;

	return (
		<Stack space="space.200" alignInline="center" grow="fill">
			<Image src={ReloadSuggestionsImageLight} srcDark={ReloadSuggestionsImageDark} />
			<Stack space="space.200" alignInline="center">
				<Heading color="color.text" size="small">
					{formatMessage(messages.reloadSuggestionsStateTitle)}
				</Heading>
				<Text align="center" color="color.text" size="medium">
					{formatMessage(messages.reloadSuggestionsStateDescription)}
				</Text>
				<Button appearance="default" onClick={handleReloadSuggestions}>
					{formatMessage(messages.reloadSuggestionsButtonLabel)}
				</Button>
			</Stack>
		</Stack>
	);
};
