import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { ModalBody, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { Stack, xcss } from '@atlaskit/primitives';
import ProgressBar from '@atlaskit/progress-bar';

import { NavigationLockout } from '@confluence/navigation-lockout';

const i18n = defineMessages({
	loadingTitle: {
		id: 'bulk-permissions.space.loading-title',
		defaultMessage: 'We’re processing your change',
		description: 'Loading title text',
	},
	loadingDialogDescription: {
		id: 'bulk-permissions.space.loading-dialog-description',
		defaultMessage: 'Don’t navigate away or refresh the page.',
		description: 'Loading dialog description text warning that a user should not navigate away',
	},
});

const LoadingSubSectionStyle = xcss({
	rowGap: 'space.300',
	paddingBottom: 'space.800',
});

export const LoadingDialog = () => (
	<>
		<ModalHeader>
			<ModalTitle>
				<FormattedMessage {...i18n.loadingTitle} />
			</ModalTitle>
		</ModalHeader>
		<ModalBody>
			<Stack xcss={LoadingSubSectionStyle}>
				<FormattedMessage {...i18n.loadingDialogDescription} />
				<ProgressBar isIndeterminate />
			</Stack>
		</ModalBody>
		<NavigationLockout />
	</>
);
