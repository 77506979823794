/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef } from 'react';
import type { HTMLAttributes, ReactNode } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import type { Interpolation, Theme } from '@emotion/react';

import { useAIThemeColor } from '../../styles/theme';

import {
	defaultPaddingStyles,
	floatingContainerStyles,
	getPaddingAdjustments,
	prismBorderAnimationStyles,
	prismBorderStyles,
} from './styles';
import type { PaddingAdjustments } from './types';

type Props = {
	paddingAdjustments?: PaddingAdjustments;
	rainbowBorder?: boolean;
	rainbowBorderAnimation?: boolean;
	disablePadding?: boolean;
	children: ReactNode;
	css?: Interpolation<Theme>;
} & HTMLAttributes<HTMLDivElement>;

export const FloatingContainer = forwardRef<HTMLDivElement, Props>(
	(
		{
			children,
			paddingAdjustments,
			rainbowBorder = true,
			rainbowBorderAnimation,
			disablePadding,
			css,
			...rest
		}: Props,
		ref: React.Ref<HTMLDivElement>,
	) => {
		const paddingStyles = disablePadding
			? null
			: paddingAdjustments
				? getPaddingAdjustments(paddingAdjustments)
				: defaultPaddingStyles;

		const aiThemeColor = useAIThemeColor();

		const cssStyles = [floatingContainerStyles, paddingStyles, css];

		if (rainbowBorder) {
			cssStyles.push(prismBorderStyles(aiThemeColor));

			if (rainbowBorderAnimation) {
				cssStyles.push(prismBorderAnimationStyles);
			}
		}

		return (
			// Ignored via go/ees005
			// eslint-disable-next-line react/jsx-props-no-spreading, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			<div ref={ref} css={cssStyles} {...rest}>
				{children}
			</div>
		);
	},
);
