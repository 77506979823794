import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	editButtonLabel: {
		id: 'change-stager.edit-button-label',
		description: 'Button label for beginning editing mode',
		defaultMessage: 'Edit',
	},
	saveButtonLabel: {
		id: 'change-stager.save-button-label',
		description: 'Button label for initiating a save',
		defaultMessage: 'Save',
	},
	cancelButtonLabel: {
		id: 'change-stager.cancel-button-label',
		description: 'Button label for canceling edit mode',
		defaultMessage: 'Cancel',
	},
});
