import React from 'react';

import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { Stack, Text } from '@atlaskit/primitives';

import EnableSuggestionsImageDark from '../assets/enable-suggestions/dark.svg';
import EnableSuggestionsImageLight from '../assets/enable-suggestions/light.svg';
import messages from '../messages';

export const EnableSuggestionsState = ({ handleEnable }: { handleEnable: () => void }) => {
	const { formatMessage } = useIntl();

	return (
		<Stack space="space.200" alignInline="center" grow="fill">
			<Image src={EnableSuggestionsImageLight} srcDark={EnableSuggestionsImageDark} />
			<Stack space="space.200" alignInline="center">
				<Heading color="color.text" size="small">
					{formatMessage(messages.enableSuggestionsStateTitle)}
				</Heading>
				<Text align="center" color="color.text" size="medium">
					{formatMessage(messages.enableSuggestionsStateDescription)}
				</Text>
				<Button appearance="primary" onClick={handleEnable}>
					{formatMessage(messages.enableSuggestionsButtonLabel)}
				</Button>
			</Stack>
		</Stack>
	);
};
