import gql from 'graphql-tag';

import { SpaceRoleFragment } from './SpaceRole.fragment';

export const DefaultSpaceRoleAssignments = gql`
	query DefaultSpaceRoleAssignments($after: String) {
		defaultSpaceRoleAssignmentsAll(first: 100, after: $after) {
			nodes {
				principal {
					displayName
					principalId
					__typename
				}
				permissions {
					id
				}
				role {
					...SpaceRoleFragment
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasNextPage
			}
		}
	}
	${SpaceRoleFragment}
`;
