/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import React, { useState, useRef, lazy, type ReactNode, Suspense } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';
import { useForm, AkForm, isActionButton, FormContext } from '../form';
import { useButton, useActionButtons } from '../button/Button';
import { type RenderFn } from '@atlassian/forge-ui-types';
import type {
	ModalDialogProps,
	ModalBodyProps,
	ModalTitleProps,
} from '@atlaskit/modal-dialog/types';
import { type LoadingButtonProps } from '@atlaskit/button/loading-button';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlaskit/platform-feature-flags';

export const AkModalDialog = lazy(() => import('@atlaskit/modal-dialog'));
const AKModalHeader = lazy(() => import('@atlaskit/modal-dialog/modal-header'));
const AKModalTitle = lazy(() => import('@atlaskit/modal-dialog/modal-title'));
const AKModalBody = lazy(() => import('@atlaskit/modal-dialog/modal-body'));
const AKModalFooter = lazy(() => import('@atlaskit/modal-dialog/modal-footer'));
const AKButton = lazy(() => import('@atlaskit/button/loading-button'));

const COMPONENT_TOP_MARGIN = token('space.150', '12px');

function applyTopMargin(element: ReactNode) {
	return (
		<div
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css({
				marginTop: COMPONENT_TOP_MARGIN,
			})}
		>
			{element}
		</div>
	);
}

export function useModalDialog({ forgeDoc, dispatch, render }: Parameters<RenderFn>[0]): {
	akModalDialogProps: ModalDialogProps;
	akModalTitleProps: ModalTitleProps;
	akModalBodyProps: ModalBodyProps;
	actions: LoadingButtonProps[];
} {
	const intl = useIntl();
	const {
		appearance,
		header,
		onClose,
		closeButtonText = intl.formatMessage(messages.closeModalButton),
		width = 'medium',
	} = forgeDoc.props || {};

	const formForgeDoc =
		forgeDoc.children.length === 1 && forgeDoc.children[0].type === 'Form'
			? forgeDoc.children[0]
			: undefined;

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isClosing, setIsClosing] = useState(false);
	const [formValues, setFormValues] = useState({});
	const setFormValue = (name: string, value: any) => {
		setFormValues((prevState) => ({ ...prevState, [name]: value }));
	};

	const {
		children,
		akFormProps,
		htmlFormProps,
		akSubmitButtonProps: akFormSubmitButtonProps,
	} = useForm({
		// This is awkward because we only use values if formForgeDoc exists
		// I want to conditionally call this hook but I can't
		forgeDoc: formForgeDoc || { type: 'Form', children: [] },
		dispatch,
		render,
	});

	const akFormPropsWithOnSubmit: typeof akFormProps = {
		...akFormProps,
		onSubmit: async (...args) => {
			setIsSubmitting(true);
			try {
				await akFormProps.onSubmit(...args);
			} finally {
				setIsSubmitting(false);
			}
		},
	};

	const formId = useRef(`modal-form-id-${Date.now()}`);
	const akSubmitButtonProps = {
		...akFormSubmitButtonProps,
		appearance: appearance || 'primary',
		isLoading: isSubmitting,
		form: formId.current,
	};

	const handleClose = async () => {
		setIsClosing(true);
		try {
			await dispatch({
				type: 'event',
				handler: onClose,
				args: [],
				extensionData: {},
			});
		} finally {
			setIsClosing(false);
		}
	};

	const actions = formForgeDoc ? formForgeDoc.children.filter(isActionButton) : [];
	const { akActionButtonProps } = useActionButtons({
		forgeDoc: actions,
		dispatch,
		render,
	});

	const { akButtonProps: closeButtonProps } = useButton({
		forgeDoc: {
			type: 'Button',
			props: {
				appearance: 'subtle',
				text: closeButtonText,
				onClick: onClose,
			},
			children: [],
		},
		dispatch,
		render,
	});
	const akCloseButtonProps = {
		...closeButtonProps,
		isLoading: isClosing || closeButtonProps.isLoading,
	};

	return {
		akModalDialogProps: {
			onClose: handleClose,
			width,
		},
		akModalTitleProps: {
			appearance,
			children: header,
		},
		akModalBodyProps: {
			children: formForgeDoc ? (
				<AkForm {...akFormPropsWithOnSubmit}>
					{({ formProps }) => (
						<form {...formProps} {...htmlFormProps} id={formId.current}>
							<FormContext.Provider value={{ formValues, setFormValue }}>
								{React.Children.map(children, (child, index) =>
									index === 0 ? child : applyTopMargin(child),
								)}
							</FormContext.Provider>
						</form>
					)}
				</AkForm>
			) : (
				React.Children.map(forgeDoc.children.map(render), (child, index) =>
					index === 0 ? child : applyTopMargin(child),
				)
			),
		},
		actions: formForgeDoc
			? [...akActionButtonProps, akCloseButtonProps, akSubmitButtonProps]
			: [akCloseButtonProps],
	};
}

export function ModalDialog(props: Parameters<RenderFn>[0]) {
	const { akModalDialogProps, akModalTitleProps, akModalBodyProps, actions } =
		useModalDialog(props);

	const ConditionalSuspense = fg('concurrent-rendering-fix-forge-issue-actions')
		? SuspenseWithNullFallback
		: React.Fragment;

	return (
		<Suspense fallback={null}>
			<AkModalDialog {...akModalDialogProps}>
				<ConditionalSuspense>
					<AKModalHeader>
						<AKModalTitle {...akModalTitleProps} />
					</AKModalHeader>
					<AKModalBody {...akModalBodyProps} />
					<AKModalFooter>
						{actions.map((akButtonProps, index) => (
							<AKButton {...akButtonProps} key={index} />
						))}
					</AKModalFooter>
				</ConditionalSuspense>
			</AkModalDialog>
		</Suspense>
	);
}

// Cleanup when concurrent-rendering-fix-forge-issue-actions is removed
function SuspenseWithNullFallback({ children }: { children: ReactNode }) {
	return <Suspense fallback={null}>{children}</Suspense>;
}

const messages = defineMessages({
	closeModalButton: {
		id: 'confluence.modal.dialog.close.modal.button',
		defaultMessage: 'Cancel',
		description: 'The text inside of the button that will cancel the modal operation.',
	},
});
