import type { MessageDescriptor } from 'react-intl-next';

import type { Recommendation, TransformAction } from './api';
import { SETTING_KEYS, getSuggestionSettingArray } from './constants';
import type { SettingDisplayType } from './states';

/**
 * Type for the suggestion setting item
 */
export type SuggestionItemType = {
	Icon: React.ElementType;
	settingKey: keyof SettingDisplayType;
	title: MessageDescriptor;
	description: MessageDescriptor;
	defaultValue?: boolean;
	parentSetting?: SETTING_KEYS;
	transformationType?: TransformAction;
	display?: boolean;
	indent?: boolean;
};

/**
 * Checks if the item is visible based on its parent + grandparent + great grandparent setting.
 */
const isItemVisible = (item: SuggestionItemType, result: SuggestionItemType[]): boolean => {
	const checkParentVisibility = (currentItem: SuggestionItemType): boolean => {
		const parentSettingKey = currentItem.parentSetting;
		if (!parentSettingKey) {
			return true; // No parent means this item is visible
		}
		const parentItem = result.find((i) => i.settingKey === parentSettingKey);
		if (!parentItem) {
			return false; // No parent found, consider it not visible
		}
		// Check the parent setting's default value
		const isParentEnabled = parentItem.defaultValue ?? false;
		// If the parent is not visible, this item is not visible
		if (!isParentEnabled) {
			return false;
		}
		// Recursively check the visibility of the parent
		return isParentEnabled && checkParentVisibility(parentItem);
	};

	// Check the visibility of the item considering its parent and grandparent settings
	return checkParentVisibility(item);
};

/**
 * Get the suggestion settings with their
 * default values
 * display and indent properties
 * in a correct order
 */
export const getSettingsWithValues = (settings: SettingDisplayType | null) => {
	const result: SuggestionItemType[] = [];
	const processed = new Set();

	const suggestionSetting = getSuggestionSettingArray();

	const processSetting = (setting: SuggestionItemType) => {
		if (processed.has(setting.settingKey)) {
			return;
		}

		if (setting.parentSetting && !processed.has(setting.parentSetting)) {
			const parentSetting = suggestionSetting.find((s) => s.settingKey === setting.parentSetting);
			if (parentSetting) {
				processSetting(parentSetting);
			}
		}

		processed.add(setting.settingKey);
		result.push({
			...setting,
			defaultValue: settings?.[setting.settingKey] ?? true,
			display: isItemVisible(setting, result),
			settingKey: setting.settingKey,
			indent:
				setting.parentSetting && setting.parentSetting !== SETTING_KEYS.ALL_SUGGESTIONS
					? true
					: false,
		});
	};

	suggestionSetting.forEach(processSetting);

	return result;
};

/**
 * Type for the return setting object
 */
export type returnSettingType = {
	[key: string]: boolean;
};

/**
 * Get the new settings based on the setting key
 */
export const getNewSettings = (
	settings: SettingDisplayType | null,
	settingKey: keyof SettingDisplayType | null,
) => {
	const suggestionSettingWithValues = getSettingsWithValues(settings);

	const newSettings = suggestionSettingWithValues.reduce<returnSettingType>((suggestion, s) => {
		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		suggestion[s.settingKey] = s.defaultValue!;
		return suggestion;
	}, {});

	if (settingKey) {
		newSettings[settingKey] = !newSettings[settingKey];

		// toggle all child settings recursively
		const toggleChildSettings = (parentKey: keyof SettingDisplayType, newSettingValue: boolean) => {
			suggestionSettingWithValues.forEach((value) => {
				if (value.parentSetting === parentKey) {
					newSettings[value.settingKey] = newSettingValue;
					toggleChildSettings(value.settingKey, newSettingValue);
				}
			});
		};

		toggleChildSettings(settingKey, newSettings[settingKey]);
	}

	return newSettings;
};

/**
 * Sync recommendations based on the settings
 */
export const syncFilteredRecommendations = (
	recommendations: Recommendation[],
	settings: SettingDisplayType,
): Recommendation[] => {
	const suggestionSettingWithValues = getSettingsWithValues(settings);
	const newSettings = suggestionSettingWithValues.reduce<returnSettingType>((suggestion, s) => {
		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		suggestion[s.transformationType!] = s.defaultValue!;
		return suggestion;
	}, {});

	return recommendations.map((recommendation) => {
		return {
			...recommendation,
			filtered: newSettings[recommendation.transformAction] === false,
		};
	});
};

/**
 * Get the transformation with icon
 */
export const getTransformActionIconMap = (): Record<TransformAction, React.ElementType> => {
	const suggestionSetting = getSuggestionSettingArray();

	return suggestionSetting.reduce(
		(acc, item) => {
			if (item.transformationType) {
				acc[item.transformationType] = item.Icon;
			}
			return acc;
		},
		{} as Record<TransformAction, React.ElementType>,
	);
};
