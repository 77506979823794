import {
	ContentUnifiedQuery,
	ContentBlogUnifiedQuery,
	ContentUnifiedWithSpaceKeyQuery,
} from '@confluence/content-unified-query';
import { isNotFoundSpaceError } from '@confluence/content-unified-query/entry-points/errors';
import { query, writeQuery, isUnauthorizedError } from '@confluence/query-preloader-tools';
import type { GraphqlErrorProcessor } from '@confluence/graphql-error-processor';
import type {
	ContentUnifiedQueryType,
	ContentUnifiedQueryVariablesType,
	ContentBlogUnifiedQueryVariablesType,
	ContentUnifiedWithSpaceKeyQueryType,
	ContentUnifiedWithSpaceKeyQueryVariablesType,
} from '@confluence/content-unified-query';

declare global {
	interface Window {
		__GRAPHQL_ERROR_PROCESSOR__?: GraphqlErrorProcessor;
	}
}

type PreloadContentParameters = {
	contentId: string;
	isBlog: boolean;
	spaceKey: string;
	shouldPreloadUsingSpaceKey?: boolean;
	featureFlags: { [key: string]: string | number | boolean };
	versionOverride?: number | null;
};

type PreloadContentResult = {
	result?: any;
	hasErrors: boolean;
};

type ChooseContentUnifiedQueryVariablesType =
	| ContentUnifiedQueryVariablesType
	| ContentBlogUnifiedQueryVariablesType;

export const handleErrors = (result: any) => {
	// NOTES:
	// 1. Preloading is "fire and forget" operation on a client as well, so we can mute
	//    some known errors, but we can't really do anything about them as they're not
	//    make it to the Apollo cache anyway and therefore don't pop at where they can
	//    be handled.
	// 2. We use implicit dependency on GraphqlErrorProcessor from "@confluence/graphql"
	//    to avoid direct import of this package into preloader bundle.
	const hasErrors = result?.errors?.length > 0;
	result?.errors?.forEach((error: any) => {
		// The "Cannot find space" condition is handled by redirecting to the correct space
		// even if error is not propagated to ContentPrerequisites where it is normally handled.
		if (isUnauthorizedError(error) || isNotFoundSpaceError(error)) {
			window?.__GRAPHQL_ERROR_PROCESSOR__?.markErrorAsHandled(error);
		}
	});

	return hasErrors;
};

export const transformContentData = (data: any) => {
	const transformedData = {
		content: data?.spaceHomepage
			? {
					nodes: [data?.spaceHomepage],
					// @ts-ignore
					__typename: 'PaginatedContentListWithChild',
				}
			: null,
		space: data?.space || null,
	};
	return transformedData;
};

export function preloadContent({
	contentId,
	spaceKey,
	isBlog,
	versionOverride = null,
}: PreloadContentParameters): Promise<PreloadContentResult> {
	window.performance.mark('CFP-63.preloadContent.start');
	const isSSR = Boolean(process.env.REACT_SSR);

	// Space overview
	if (spaceKey && !contentId) {
		return query<ContentUnifiedWithSpaceKeyQueryType, ContentUnifiedWithSpaceKeyQueryVariablesType>(
			{
				query: ContentUnifiedWithSpaceKeyQuery,
				variables: {
					spaceKey,
					versionOverride,
					isSSR,
					useNewContentTopper: true,
				},
				errorPolicy: 'all',
			},
		).then((result: any) => {
			const hasErrors = handleErrors(result);

			// Record the end mark and timestamp to track *both* duration of preloading
			// and timestamp of the moment when preloading finished
			window.performance.mark('CFP-63.preloadContent.end');
			window.performance.mark('CFP-63.preloadContentEnd');

			const data = result?.data;
			const homepageId = data?.spaceHomepage?.id;
			if (homepageId) {
				// update result with transformed data
				result.data = transformContentData(data);

				writeQuery<ContentUnifiedQueryType, ContentUnifiedQueryVariablesType>({
					query: ContentUnifiedQuery,
					variables: {
						contentId: homepageId,
						spaceKey,
						versionOverride,
						isSSR,
						useNewContentTopper: true,
					},
					data: { ...result.data },
				});
			}
			return { result, hasErrors };
		});
	}

	let chooseContentUnifiedQuery = ContentUnifiedQuery;
	let queryVariables: ChooseContentUnifiedQueryVariablesType = {
		contentId,
		spaceKey,
		versionOverride,
		isSSR,
		useNewContentTopper: true,
	};

	if (isBlog) {
		chooseContentUnifiedQuery = ContentBlogUnifiedQuery;
	}

	return query<ContentUnifiedQueryType, ContentUnifiedQueryVariablesType>({
		query: chooseContentUnifiedQuery,
		variables: queryVariables,
		errorPolicy: 'all',
	}).then((result: any) => {
		const hasErrors = handleErrors(result);

		// Record the end mark and timestamp to track *both* duration of preloading
		// and timestamp of the moment when preloading finished
		window.performance.mark('CFP-63.preloadContent.end');
		window.performance.mark('CFP-63.preloadContentEnd');

		return { result, hasErrors };
	});
}
