/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { token } from '@atlaskit/tokens';

// no tokens for 3px exist, so using raw values
// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const escTextStyles = css({
	borderRadius: token('border.radius.100', '3px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '3px',
	color: token('color.text.subtle', '#44546F'),
	background: token('color.background.neutral', '#091E420F'),
	fontWeight: token('font.weight.regular', '400'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '5px',
});
