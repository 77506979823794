import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';

import { GetUserStateQuery } from './graphql/GetUserStateQuery.graphql';
import type { GetUserState } from './graphql/__types__/GetUserStateQuery';

export async function preloadUserState() {
	const { userId } = await getPreloaderFnContext();
	if (!userId && fg('confluence_frontend_get_user_state_query_id_check')) {
		return;
	}
	return query<GetUserState, null>({
		query: GetUserStateQuery,
	});
}
