import { query } from '@confluence/query-preloader-tools';

import { FeedContentQuery } from './FeedContentQuery.graphql';
import { FeedTypeSelectorRefreshQuery } from './FeedTypeSelectorRefreshQuery.graphql';
import { FeedPopularFeedQuery } from './FeedPopularFeedQuery.graphql';
import { getInitialPageSize } from './initialPageSizeConstant';

export const preloadFeed = ({ isLoggedIn }) => {
	// If the user is not logged in we show the following feed by default
	// so only preload that feed. If the user is logged in preload both
	// feeds and the feed preference
	const tasks: Array<Promise<any>> = [
		query({
			query: FeedContentQuery,
			variables: {
				first: getInitialPageSize(),
			},
		}),
	];
	if (isLoggedIn) {
		tasks.push(
			query({
				query: FeedPopularFeedQuery,
				variables: {
					first: getInitialPageSize(),
				},
			}),
			query({
				query: FeedTypeSelectorRefreshQuery,
			}),
		);
	}
	return Promise.all(tasks);
};
