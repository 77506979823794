import React from 'react';

import type { DocNode } from '@atlaskit/adf-schema';
import { JSONTransformer, type JSONDocNode } from '@atlaskit/editor-json-transformer';
import { Fragment, Schema } from '@atlaskit/editor-prosemirror/model';
import {
	PreviewEditor,
	type PreviewEditorProps,
} from '@atlassian/generative-ai-modal/components/PreviewEditor';

import { MarkdownConversionError } from '../../MarkdownConversionError';

type PreviewEditorWrapperProps = {
	schema: Schema;
	pmFragment: Fragment;
	PromptEditor: PreviewEditorProps['PromptEditor'];
	onFragmentChange: (pmFragment: Fragment) => void;
};
export const PreviewEditorWrapper = ({
	schema,
	pmFragment,
	PromptEditor,
	onFragmentChange,
}: PreviewEditorWrapperProps) => {
	const jsonTransformer = React.useMemo(() => new JSONTransformer(schema), [schema]);
	const jsonDocument = React.useMemo(() => {
		const prosemirrorDocument = schema.nodes.doc.createAndFill({}, pmFragment);
		// If a failure has occured creating this, we don't know what's happened
		// and so should trigger the error boundary
		if (!prosemirrorDocument) {
			throw new MarkdownConversionError({
				message: 'Unable to transform markdown into a valid prosemirror document',
				type: 'noProseMirrorDoc',
			});
		}
		if (prosemirrorDocument.content.size === 0) {
			throw new MarkdownConversionError({
				message: 'Transforming markdown returns an empty prosemirror document',
				type: 'emptyProseMirrorDoc',
			});
		}
		try {
			// Warning -- this is asserted due to a mismatch in the editor-json-transformer
			// and renderer types.
			// jsonTransformer.encode returns a "JSONDocNode" which _should_ be runtime
			// compatible with DocNode -- but does not have compatible types.
			// See the following files for more details
			// - packages/editor/editor-json-transformer/src/types.ts
			// - packages/editor/adf-schema/src/schema/nodes/doc.ts
			return jsonTransformer.encode(prosemirrorDocument) as DocNode;
		} catch {
			throw new MarkdownConversionError({
				message: 'Unable to transform markdown into a valid prosemirror document',
				type: 'invalidProseMirrorDoc',
			});
		}
	}, [jsonTransformer, pmFragment, schema.nodes.doc]);

	const onADFChange = React.useCallback(
		(inputValue: JSONDocNode) => {
			const document = jsonTransformer.parse(inputValue);
			onFragmentChange(document.content);
		},
		[onFragmentChange, jsonTransformer],
	);

	return (
		<PreviewEditor
			defaultValue={jsonDocument}
			PromptEditor={PromptEditor}
			autoFocus={true}
			onADFChange={onADFChange}
		/>
	);
};
