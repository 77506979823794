/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useRef, Suspense, useMemo } from 'react';
import { type AnalyticsWebClient } from '@atlassian/forge-ui/analytics';
import {
	ForgeErrorBoundary,
	type Extension,
	ForgeUIExtensionAnalyticsContext,
	type ForgeUIRendererProps,
} from '@atlassian/forge-ui/ui';
import type { DocNode } from '@atlaskit/adf-schema';

import { ForgeUIExtensionPointProvider } from '@atlassian/forge-ui/provider';
import {
	type ExtensionConfiguration,
	type ProductEnvironment,
	type ForgeUIExtensionType,
} from '@atlassian/forge-ui-types';
import type {
	ExtensionParams,
	ExtensionManifest,
	Parameters as BaseParameters,
} from '@atlaskit/editor-common/extensions';
import { type ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type ApolloClient from 'apollo-client';
import { token } from '@atlaskit/tokens';
import { dequal } from 'dequal/lite';
import { useIntl } from 'react-intl-next';

import { getConfig } from '../extension-provider/getForgeExtensionProvider';
import { ForgeUIExtension, UIKitOneRenderer, AdfExportRenderer } from './renderers';

import { isPdfExportEntrypoint, isAdfExportEntrypoint } from '../utils/pdfEntrypointUtils';
import type { FlagFunctions } from '../utils/getFlagProvider';

const styles2 = css({
	background: 'transparent',
	cursor: 'not-allowed',
	left: 0,
	position: 'absolute',
	height: '100%',
	top: 0,
	width: '100%',
	zIndex: 1,
});

export interface ExtensionRef {
	openConfig: () => Promise<{ [key: string]: any } | void>;
}

export type ForgeExtensionParameters = BaseParameters & {
	localId: string;
	extensionId: string;
	extension?: ForgeUIExtensionType;
	extensionTitle?: string;
	config?: ExtensionConfiguration;
	guestParams?: ExtensionConfiguration;
};

type ValueObj = {
	value: string;
};

export type ConnectToForgeParameters = Pick<ForgeExtensionParameters, 'guestParams'> & {
	macroParams?: Record<string, ValueObj>;
	macroMetadata?: {
		macroId?: ValueObj;
		schemaVersion?: ValueObj;
		title?: string;
	};
};

export type ForgeExtension = ExtensionParams<ForgeExtensionParameters>;

export type ForgeCreateExtensionProps = {
	type: Required<ExtensionParams<ForgeExtensionParameters>>['type'];
	attrs: {
		extensionKey: string;
		extensionType: string;
		localId: string;
		parameters: {
			localId: string;
			extensionId: string;
			extensionTitle: string;
		};
		text: string;
	};
	content?: DocNode['content'];
};

export type ForgeExtensionManifest = ExtensionManifest<ForgeExtensionParameters> & {
	connectModuleKey?: string;
	migrationKey?: string;
	createExtensionProps: (macroId?: string) => ForgeCreateExtensionProps;
};

const APP_CONTAINER_PADDING = token('space.100', '8px');

export interface ForgeUIExtensionWrapperProps {
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>;
	environment: ProductEnvironment;
	product: string;
	page: string;
	accountId: string;
	cloudId: string;
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	extensionData: Record<string, any>;
	dataProviders: ProviderFactory;
	extension: ForgeExtension;
	isEditing: boolean;
	flags: FlagFunctions;
	hideGlassPane?: boolean;
	customEditContext?: CustomEditContext;
}

export interface CustomEditContext {
	bridge: ForgeUIRendererProps['bridge'];
	modalExtension: ForgeUIRendererProps['modalExtension'];
	isInserting: boolean;
}

export interface ForgeExtensionProps {
	extension: ForgeExtension;
	config?: ExtensionConfiguration;
}

function useMemoedExtensionData(extensionData: Record<string, any>): Record<string, any> {
	const ref = useRef(extensionData);
	if (!dequal(ref.current, extensionData)) {
		ref.current = extensionData;
	}
	return ref.current;
}

const ForgeUIExtensionWrapper = ({
	apolloClient,
	contextIds,
	cloudId,
	environment,
	extensionData,
	accountId,
	dataProviders,
	extension,
	isEditing,
	flags,
	hideGlassPane,
	customEditContext,
}: ForgeUIExtensionWrapperProps) => {
	const {
		localId,
		extension: gqlExtension,
		extensionId,
		extensionTitle,
		// If `parameters` is missing, we'll let that bubble up to the extension's error boundary.
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	} = extension.parameters!;

	const config = getConfig(extension.parameters!);

	const isBodiedMacro = extension.type === 'bodiedExtension';
	const extensionViewData = useMemo(
		() =>
			isBodiedMacro && !!extension.content
				? {
						macro: {
							body: {
								type: 'doc' as const,
								version: 1 as const,
								content: extension.content as DocNode['content'],
							},
						},
					}
				: {},
		[extension.content, isBodiedMacro],
	);

	// fallback to UIKitOneRenderer if it hits the confluence pdf export route 'wiki/pdf' AND has an forge has an export handler
	const fallbackToUIKit = isPdfExportEntrypoint(gqlExtension as Extension);

	const isAdfExport = isAdfExportEntrypoint(gqlExtension as Extension);

	const isHostedResource = Boolean(gqlExtension?.properties?.resource);
	const isUiKitTwo = gqlExtension?.properties?.render === 'native';
	const isCustomUI = isHostedResource && !isUiKitTwo;
	// inline macro doesn't support Custom UI
	const isInline = gqlExtension?.properties?.layout === 'inline' && !isCustomUI;

	const coreData = { localId, cloudId };
	const macroExtensionData = {
		...(customEditContext
			? {
					isConfiguring: true,
					isInserting: customEditContext.isInserting,
				}
			: {}),
		...(isBodiedMacro ? { layout: 'bodied' } : {}),
	};
	const memoedExtensionData = useMemoedExtensionData({
		...extensionData,
		config,
		...(Object.keys(macroExtensionData).length ? { macro: macroExtensionData } : {}),
	});
	const { locale } = useIntl();

	const renderRenderer = () => {
		if (fallbackToUIKit || !isHostedResource) {
			return (
				<UIKitOneRenderer
					accountId={accountId}
					apolloClient={apolloClient}
					contextIds={contextIds}
					coreData={coreData}
					extension={gqlExtension as Extension}
					extensionData={memoedExtensionData}
					extensionId={extensionId}
					extensionTitle={extensionTitle}
				/>
			);
		}
		if (isAdfExport) {
			/**
			 * For frontend macro rendering, we only pass the `extensionViewData` (macro body) to the iframe over the JS bridge.
			 * This is to avoid it being included in the Forge Invocation Token (FIT) for remote endpoint resolver calls.
			 * However, the `AdfExport` is a backend function, so we cannot pass it over the JS bridge.
			 * Nevertheless, it won't be included in the FIT, because the export does not use a remote endpoint resolver.
			 * It also won't be included in the Forge Compute Token (FCT), because the FCT only includes context--not `extensionPayload`.
			 */
			const extensionPayload = extensionViewData;
			return (
				<AdfExportRenderer
					accountId={accountId}
					apolloClient={apolloClient}
					contextIds={contextIds}
					coreData={coreData}
					dataProviders={dataProviders}
					extension={gqlExtension as Extension}
					extensionData={memoedExtensionData}
					extensionPayload={extensionPayload}
					extensionId={extensionId}
					extensionTitle={extensionTitle}
				/>
			);
		}

		return (
			<ForgeUIExtension
				accountId={accountId}
				apolloClient={apolloClient}
				contextIds={contextIds}
				environment={environment}
				extension={gqlExtension as Extension}
				coreData={coreData}
				extensionData={memoedExtensionData}
				extensionViewData={extensionViewData}
				locale={locale}
				flags={flags}
				customEditContext={customEditContext}
			/>
		);
	};

	return (
		<div
			data-testid="ForgeExtensionContainer"
			// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css`
				background: ${isEditing ? token('elevation.surface', '#fff') : 'transparent'};
				padding: ${isEditing && !isInline ? `${APP_CONTAINER_PADDING}px` : 0};
				position: relative;
				display: ${isInline ? 'inline-block' : 'block'};
			`}
		>
			{renderRenderer()}
			{isEditing && !hideGlassPane && <div data-testid="GlassPane" css={styles2} />}
		</div>
	);
};

const ForgeUIExtensionWrapperWithBoundary = (props: ForgeUIExtensionWrapperProps) => {
	const { extension, product, environment, page, analyticsWebClient } = props;

	const children = (
		<ForgeErrorBoundary>
			<ForgeUIExtensionWrapper {...props} />
		</ForgeErrorBoundary>
	);

	return (
		// This Suspense fallback is for the ForgeErrorBoundary
		<Suspense fallback={null}>
			<ForgeUIExtensionPointProvider
				analyticsWebClient={analyticsWebClient}
				product={product}
				environment={environment}
				page={page}
			>
				{extension.parameters ? (
					<ForgeUIExtensionAnalyticsContext
						localId={extension.parameters.localId}
						extensionId={extension.parameters.extensionId}
					>
						{children}
					</ForgeUIExtensionAnalyticsContext>
				) : (
					children
				)}
			</ForgeUIExtensionPointProvider>
		</Suspense>
	);
};

export default ForgeUIExtensionWrapperWithBoundary;
