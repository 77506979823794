import React from 'react';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
	StatusLozengeType,
} from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/brainstorm';
import {
	createInsertAtCurrentPosition,
	createInsertAtTop,
	createInsertBelow,
	createReplace,
} from '../../config-item-actions/markdown';
import { getExperimentalPlaceholderPromptConfiguration } from '../../experimental-placeholder-prompt-configuration';

export const getExperimentalPromptPlaceholderConfigItem = ():
	| undefined
	| (ReturnType<typeof getExperimentalPlaceholderPromptConfiguration> & {
			configItem: EditorPluginAIConfigItem;
	  }) => {
	const configurations = getExperimentalPlaceholderPromptConfiguration();

	if (configurations) {
		const statusLozengeType =
			configurations.statusType.toLowerCase() === 'new' ||
			configurations.statusType.toLowerCase() === 'beta'
				? (configurations.statusType.toLowerCase() as StatusLozengeType)
				: undefined;
		return {
			...configurations,
			configItem: {
				key: CONFIG_ITEM_KEYS.EXPERIMENTAL_PROMPT_PLACEHOLDER,
				title: configurations.label,
				description: configurations.description,
				icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
				statusLozengeType,
				intentSchemaId: configurations.intent,
				actions: {
					empty: [
						createInsertAtCurrentPosition({ appearance: 'secondary' }),
						createInsertAtTop({ appearance: 'primary' }),
					],
					selection: [
						createInsertBelow({ appearance: 'secondary' }),
						createReplace({ appearance: 'primary' }),
					],
				},
				getBackendModel: () => {
					return 'assistance-service';
				},
			},
		};
	}
};

export const getExperimentalPromptPlaceholderConfigItemWithOptions = ():
	| undefined
	| (ReturnType<typeof getExperimentalPlaceholderPromptConfiguration> & {
			configItemWithOptions: EditorPluginAIConfigItemWithOptions;
	  }) => {
	const res = getExperimentalPromptPlaceholderConfigItem();
	if (res) {
		const { configItem, ...others } = res;
		return {
			configItemWithOptions: {
				config: configItem,
				triggers: { empty: { docMinSize: 1 }, selection: true },
			},
			...others,
		};
	}
};
