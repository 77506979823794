/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */
import { type ReactNode } from 'react';

import { css, jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766

import { token } from '@atlaskit/tokens';

import { useIsFlexElementWrapped } from '../../../hooks/useIsFlexElementWrapped';

const container = css({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'flex-end',
	gap: token('space.100', '8px'),
	paddingTop: 0,
});

const secondLevelContainer = css({
	marginLeft: 'auto',
});

const compactSecondaryButtonsContainer = css({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100', '8px'),
});

interface Props {
	/**
	 * The cancel button component.
	 *
	 * Should be a one button.
	 */
	cancelButton: ReactNode;
	/**
	 * The primary (buttons that looks like buttons with text) buttons component.
	 *
	 * Should be a list of buttons in {@link React.Fragment}.
	 * Do not wrap buttons in another container!
	 */
	primaryButtons: ReactNode;
	/**
	 * The secondary (buttons that looks like icon buttons) buttons component.
	 *
	 * Should be a list of buttons in {@link React.Fragment}.
	 * Do not wrap buttons in another container!
	 */
	secondaryButtons: ReactNode;
}

/**
 * Layout for action buttons.
 *
 * Do not put any additional logic besides layout logic here, because the layout logic is kind of complex,
 * and  it will be hard to maintain it in the future!
 */
export function ActionButtonsLayout({ cancelButton, primaryButtons, secondaryButtons }: Props) {
	// The Discard button should be the last when there are two lines of buttons.
	//
	// We can't do it just by CSS (for example, by order prop), because CSS order doesn't
	// affect the tab order.
	// And we can't use tabindex, because tabindex is global and change order of all elements.
	// That's why we need to render the Discard button in different places in the DOM,
	// based on the number of lines. Stupid but works=(
	//
	// Also, we can't determinate the line count based on container width
	// because the amount buttons can be different.
	// That's why we use the useIsFlexElementWrapped hook to determine the line count.
	//
	// See more why CSS order and tabindex don't work: https://tink.uk/flexbox-the-keyboard-navigation-disconnect/

	const [setRootContainerElement, isRootContainerWrapped] = useIsFlexElementWrapped();
	const [setSecondLevelContainerElement, isSecondLevelContainerWrapped] = useIsFlexElementWrapped();

	return (
		<div ref={setRootContainerElement} css={[container]}>
			{!isRootContainerWrapped && (
				<div css={[compactSecondaryButtonsContainer]}>{secondaryButtons}</div>
			)}

			<div ref={setSecondLevelContainerElement} css={[container, secondLevelContainer]}>
				{!isSecondLevelContainerWrapped && cancelButton}

				{primaryButtons && <div css={container}>{primaryButtons}</div>}

				{isSecondLevelContainerWrapped && cancelButton}
			</div>

			{isRootContainerWrapped && (
				<div css={[compactSecondaryButtonsContainer]}>{secondaryButtons}</div>
			)}
		</div>
	);
}
