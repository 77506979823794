import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { ErrorPageComponent } from '@confluence/not-found';

import RestrictedContentIconLight from './RestrictedContentIcon-Light.svg';
import RestrictedContentIconDark from './RestrictedContentIcon-Dark.svg';

const i18n = defineMessages({
	title: {
		id: 'bulk-permissions.restricted.title',
		defaultMessage: 'This is a restricted page',
		description: 'Error title for restricted content',
	},
	message: {
		id: 'bulk-permissions.space-settings.restricted.message',
		defaultMessage:
			'If you think you should have access, try asking the person who sent you here for help.',
		description:
			'Error message for restricted content telling the user to ask for help if this is unexpected',
	},
});

export const RestrictedView = () => {
	const intl = useIntl();
	return (
		<ErrorPageComponent
			errorImage={RestrictedContentIconLight}
			errorImageDark={RestrictedContentIconDark}
			errorTitle={intl.formatMessage(i18n.title)}
			errorMessage={intl.formatMessage(i18n.message)}
		/>
	);
};
