/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { Box } from '@atlaskit/primitives';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { type Disclaimer } from '../types';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const footerInformationStyles = css({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	minWidth: 0,
	minHeight: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > p, & > div:first-child': {
		font: token('font.body.small'),
		color: token('color.text.subtle', N500),
		marginTop: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		marginTop: token('space.050', '4px'),
		marginRight: token('space.050', '4px'),
		marginBottom: token('space.050', '4px'),
	},
});

export interface CustomDisclaimerProps {
	/** Custom disclaimer to be displayed in the footer. */
	disclaimer: Disclaimer;
}

/**
 * Custom disclaimer to be displayed in the footer.
 *
 * @todo: Are we really need custom disclaimer?
 *        We should check if it's used in any product and remove it support if not.
 */
export function CustomDisclaimer({ disclaimer }: CustomDisclaimerProps) {
	return (
		<div css={footerInformationStyles}>
			<Box testId="custom-disclaimer">
				<Tooltip content={disclaimer.tooltip} position="top">
					{disclaimer.url ? (
						<a href={disclaimer.url} target="_blank" rel="noopener noreferrer">
							{disclaimer.text}
						</a>
					) : (
						<span>{disclaimer.text}</span>
					)}
				</Tooltip>
			</Box>
		</div>
	);
}
