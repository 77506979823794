import React from 'react';

import AKIcon, { type IconProps as AKIconProps, type CustomGlyphProps } from '@atlaskit/icon';
import LightbulbIcon from '@atlaskit/icon/core/lightbulb';
import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

const IconGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		role="presentation"
		// Ignored via go/ees005
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		<path d="M7.04101 4.052C8.35608 2.73768 10.1392 1.99937 11.9985 1.99937C13.8578 1.99937 15.6409 2.73768 16.956 4.052C17.581 4.67653 18.0768 5.4181 18.415 6.23433C18.7533 7.05056 18.9274 7.92545 18.9274 8.809C18.9274 9.69254 18.7533 10.5674 18.415 11.3837C18.0768 12.1999 17.581 12.9415 16.956 13.566L16.841 13.678L16.724 13.787L15.998 14.446L16 15.83L15.998 15.862V18.001C15.9976 18.7657 15.7051 19.5014 15.1804 20.0577C14.6557 20.6139 13.9384 20.9489 13.175 20.994L12.998 20.999H10.998C10.2333 20.9988 9.49752 20.7066 8.94104 20.1821C8.38455 19.6576 8.04938 18.9404 8.00401 18.177L7.99801 18.001V14.445L7.27301 13.785C6.01548 12.6426 5.23697 11.0665 5.09394 9.37352C4.95091 7.68057 5.45397 5.99622 6.50201 4.659L6.66401 4.46L6.82001 4.283C6.89182 4.20392 6.96551 4.12756 7.04101 4.052ZM13.998 16.83H9.99801V18.002C9.99831 18.2469 10.0885 18.4832 10.2514 18.666C10.4144 18.8489 10.6387 18.9656 10.882 18.994L10.998 19.001H12.998C13.2431 19.0012 13.4798 18.9114 13.663 18.7486C13.8462 18.5858 13.9633 18.3614 13.992 18.118L13.998 18.002V16.83ZM15.54 5.466C15.0613 4.98771 14.4907 4.61131 13.8626 4.3595C13.2345 4.10769 12.5619 3.98567 11.8854 4.00081C11.2089 4.01595 10.5424 4.16792 9.92623 4.44758C9.31003 4.72724 8.75684 5.12878 8.30001 5.628C7.48334 6.52552 7.04311 7.70273 7.07048 8.91589C7.09784 10.129 7.5907 11.2852 8.44701 12.145L8.61701 12.308L9.67001 13.264C9.84765 13.4255 9.96123 13.6456 9.99001 13.884L9.99701 14.004V14.831H13.997V14.004C13.9971 13.7638 14.0838 13.5316 14.241 13.35L14.325 13.264L15.46 12.231L15.54 12.153C15.9793 11.714 16.3277 11.1928 16.5654 10.6191C16.8032 10.0454 16.9255 9.43049 16.9255 8.80949C16.9255 8.1885 16.8032 7.57358 16.5654 6.99989C16.3277 6.4262 15.9793 5.90497 15.54 5.466Z" />
	</svg>
);

export const Icon = (props: IconProps) => {
	const { shownAt } = props;
	return (
		<RainbowBorder shownAt={shownAt}>
			<LightbulbIcon
				label=""
				color="currentColor"
				spacing="spacious"
				// Ignored via go/ees005
				// eslint-disable-next-line react/jsx-props-no-spreading
				LEGACY_fallbackIcon={(props: AKIconProps) => <AKIcon glyph={IconGlyph} {...props} />}
				LEGACY_secondaryColor={token('color.icon')}
			/>
		</RainbowBorder>
	);
};
