/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled

import { formatShortcut, type Keymap } from '@atlaskit/editor-common/keymaps';
import { token } from '@atlaskit/tokens';

const style = css({
	borderRadius: token('border.radius.100', '3px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '3px',
	color: token('color.text.subtle', '#44546F'),
	background: token('color.background.neutral', '#091E420F'),
	fontWeight: token('font.weight.regular', '400'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '6px',
});

interface Props {
	/** The keymap object containing the shortcut information. */
	shortcut: Keymap;
}

/** Component that displays a formatted keyboard shortcut. */
export function Shortcut({ shortcut }: Props) {
	const formattedShortcut = formatShortcut(shortcut);

	return <span css={style}>{formattedShortcut}</span>;
}
