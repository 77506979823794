import gql from 'graphql-tag';

import { ConfluenceSiteAri, IdentityUserAri } from '@atlassian/ari';

import { getPreloaderFnContext, query } from '@confluence/query-preloader-tools';
import { getAGGClient } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';

export const preloadGlobalNavigationComponent = async () => {
	const { cloudId, userId } = await getPreloaderFnContext();

	if (!fg('confluence_nav_4_beta') || !userId) {
		return;
	}

	return query({
		query: preloadGlobalNavigationComponentQuery,
		variables: {
			entityAri: ConfluenceSiteAri.create({ siteId: cloudId }).toString(),
			ownerAri: IdentityUserAri.create({ userId }).toString(),
		},
		client: getAGGClient(),
	});
};

const preloadGlobalNavigationComponentQuery = gql`
	query preloadGlobalNavigationComponentQuery($entityAri: ID!, $ownerAri: ID!) {
		settings_navigationCustomisation(entityAri: $entityAri, ownerAri: $ownerAri) {
			sidebar {
				nodes {
					menuId
					visible
				}
			}
		}
	}
`;
