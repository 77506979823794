import {
	init,
	type GenericAnalyticWebClientPromise,
} from '@atlaskit/react-ufo/interaction-metrics-init';
import type { Config } from '@atlaskit/react-ufo/config';
import { configure as configureSsrTiming } from '@atlaskit/react-ufo/ssr';

import { fg } from '@confluence/feature-gating';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { getMark } from '@confluence/performance';
import type { SessionDataType } from '@confluence/session-data';
import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

import { additionalPayloadData } from './additionalPayloadData';
import { getSSRDoneTime, getSSRTimings } from './ssr';
import { initUFOTrace } from './ufoTrace';

// If you use any FF during UFO initialization,
// please pass the feature flag to __SSR_INIT_UFO_DATA__ in the confluence/next/packages/ssr-app/src/index.tsx
// and update initUFOWithSsrData as well

const getStatsigConfig = (): Partial<Config> | undefined => {
	try {
		return JSON.parse(StatsigConfigurations.getString('confluence_ufo_config', ''));
	} catch (error) {
		return {};
	}
};

const getLDConfig = (sessionData: SessionDataType): Partial<Config> | undefined => {
	const config = sessionData.featureFlagClient.getRawValue('confluence.frontend.ufo.config', {
		default: '{}',
	});
	try {
		return JSON.parse(String(config));
	} catch (e) {}
};

export const getConfig = (sessionData: SessionDataType): Partial<Config> | undefined => {
	let ufoConfig: Partial<Config> | undefined;

	if (fg('ufo-use-statsig-config-confluence')) {
		ufoConfig = getStatsigConfig();
	} else {
		ufoConfig = getLDConfig(sessionData);
	}
	return ufoConfig;
};

function runConfigureSsrTiming() {
	configureSsrTiming({
		getDoneMark: () => getMark('CFP-63.ssr-ttr') ?? null, // from confluence/next/packages/browser-metrics/src/initializeBrowserMetrics.ts
		// TODO: add correct functions to `getTimings` and `getFeatureFlags`
		getTimings: () => null,
		getFeatureFlags: () => null,
	});
}

function initializeUFO(initData: typeof window.__SSR_INIT_UFO_DATA__) {
	if (!initData) {
		return;
	}

	const {
		shard,
		cloudId,
		config,
		flags: {
			devToolsEnabled,
			stopVCAtInteractionFinish,
			enableTracePropagation,
			postInteractionLogEnabled,
			enableSegmentHighlighting,
			enableAdditionalPerformanceMarks,
			enableExperimentalHolds,
			shouldCalculateLighthouseMetricsFromTTAI,
			manuallyTrackReactProfilerMounts,
		},
	} = initData;

	runConfigureSsrTiming();

	if (enableTracePropagation) {
		initUFOTrace();
	}

	const perfPushFY25ExperienceRates = {
		'view-page': 1,
		'edit-page': 1,
		'space-overview': 1,
		'confluence-home': 1,
		'advanced-search': 1,
	};

	init(getAnalyticsWebClient() as Promise<GenericAnalyticWebClientPromise>, {
		postInteractionLog: {
			enabled: postInteractionLogEnabled,
			rates: perfPushFY25ExperienceRates,
		},
		experimentalInteractionMetrics: {
			enabled: enableExperimentalHolds,
			rates: perfPushFY25ExperienceRates,
		},
		enableSegmentHighlighting,
		enableAdditionalPerformanceMarks,
		shouldCalculateLighthouseMetricsFromTTAI,
		manuallyTrackReactProfilerMounts,
		product: 'confluence',
		region: shard || 'unknown',
		vc: {
			enabled: true,
			heatmapSize: 200,
			oldDomUpdates: false,
			selectorConfig: {
				id: false,
				role: false,
				className: true,
				testId: false,
			},
			devToolsEnabled,
			stopVCAtInteractionFinish,
		},
		...config,
		ssr: { getSSRDoneTime, getSSRTimings },
		additionalPayloadData: (interaction) =>
			additionalPayloadData(interaction, {
				cloudId,
				isShardPreProd: shard ? shard.startsWith('conf-prod-us-preprod') : false,
			}),
	});
}

// This function gets called when ufo data is provided by SSR
export function initUFOWithSsrData(initData: typeof window.__SSR_INIT_UFO_DATA__) {
	if (!initData?.config?.enabled) {
		return false;
	}
	initializeUFO(initData);
	return true;
}

export function initUFO(sessionData: SessionDataType) {
	const config = getConfig(sessionData);
	if (!config?.enabled) {
		return;
	}
	initializeUFO({
		shard: sessionData.shard,
		cloudId: sessionData.cloudId,
		config,
		flags: {
			manuallyTrackReactProfilerMounts: fg('ufo-manually-track-segment-mount-phase-confluence'),
			devToolsEnabled: StatsigConfigurations.isKillSwitchOn('confluence_enable_ttvc_devtool'),
			stopVCAtInteractionFinish: fg('stop_vc_at_interaction_finish'),
			enableTracePropagation: fg('confluence_fe_enable_trace_propagation'),
			postInteractionLogEnabled: fg('ufo_capture_late_rerenders_confluence'),
			enableSegmentHighlighting: fg('ufo_enable_segment_highlighting_confluence'),
			enableAdditionalPerformanceMarks: fg('ufo_enable_segment_highlighting_confluence'),
			enableExperimentalHolds: fg('ufo-enable-experimental-holds-confluence'),
			shouldCalculateLighthouseMetricsFromTTAI: fg('ufo-use-ttai-in-lighthouse-metrics-confluence'),
		},
	});
}
