import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	editPromptHover: {
		id: 'fabric.editor.ai.screens.preview.header.edit.hover',
		defaultMessage: 'Edit',
		description: 'Label for the edit prompt button in the preview screen',
	},
	clearPromptHover: {
		id: 'fabric.editor.ai.screens.preview.header.clear.hover',
		defaultMessage: 'Clear',
		description: 'Label for the clear prompt button in the preview screen',
	},
});
