import React from 'react';
import { getEditorPreloader } from '@confluence-classic/confluence-fabric-editor/src/lib';

import type { RouteMatch } from '@confluence/route';
import { EditPageLoader } from '@confluence/edit-page-loader';
import { EditPageLoaderServer } from '@confluence/edit-page-loader/entry-points/EditPageLoaderServer';
import { PageShownPublicLinksEventLoader } from '@confluence/external-share-utils/entry-points/PageShownPublicLinksEventLoader';
import { useIsEmbeddedConfluence_DO_NOT_USE } from '@confluence/embedded-confluence-check';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import { getUniquePageLoadId } from '@confluence/unique-page-load-id';
import { END } from '@confluence/navdex';
import {
	CONTAINER_HOME_ID,
	PRODUCT_HOME_ID,
	CONTAINER_BLOG_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { AnnotationsProvider } from '@confluence/annotation-provider-store';
import { CommentContentProvider } from '@confluence/comment-context';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { COMPANY_HUB_EDIT } from '@confluence/named-routes';

import { ClassicEditorContextProviderLoader } from '../../ClassicEditorContextProvider';

export const EditPageOrBlogV2Route = ({
	params: { contentId, spaceKey, contentType: editRouteTypeParam },
	query,
	pathname,
}: RouteMatch) => {
	const draftShareId = typeof query?.draftShareId === 'string' ? query.draftShareId : undefined;

	// editRouteTypeParam !== contentType !== pageType
	// editContentType is blog or pages
	// contentType is blogpost or page
	// pageType is blog or page
	const contentType = editRouteTypeParam === 'blog' ? 'blogpost' : 'page';
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
		skip: !isCompanyHub,
	});

	if (shouldShowPremiumUpsell) {
		return <CompanyHomeBuilderUpsellPage featureName="companyHub" />;
	}

	const shouldRedirectCompanyHubEditRoute = isCompanyHub && COMPANY_HUB_EDIT.pattern !== pathname;
	if (shouldRedirectCompanyHubEditRoute) {
		return <Redirection name={COMPANY_HUB_EDIT.name} />;
	}

	return (
		<>
			<AnnotationsProvider>
				<CommentContentProvider>
					<EditPageLoaderServer
						contentId={contentId}
						spaceKey={spaceKey}
						draftShareId={draftShareId}
						contentType={contentType}
					/>
					<EditPageLoader
						contentId={contentId}
						spaceKey={spaceKey}
						draftShareId={draftShareId}
						contentType={contentType}
						ClassicEditorContextProviderLoader={ClassicEditorContextProviderLoader}
					/>
					<PageShownPublicLinksEventLoader
						contentId={contentId}
						isPage={editRouteTypeParam === 'page'}
						isEditPage
					/>
				</CommentContentProvider>
			</AnnotationsProvider>
		</>
	);
};

EditPageOrBlogV2Route.NAVIGATION_PARAMS = ({
	name: routeName,
	params: { contentId, spaceKey, contentType: editRouteTypeParam },
}: RouteMatch) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isEmbeddedConfluence = useIsEmbeddedConfluence_DO_NOT_USE();
	const showPremiumOnboarding = !isEmbeddedConfluence;
	const isPageLikeContent = editRouteTypeParam === 'pages' || editRouteTypeParam === 'blog';
	const isBlog = editRouteTypeParam === 'blog';

	// editRouteTypeParam !== contentType
	// editRouteTypeParam is blog or pages
	// contentType is blogpost or page
	const contentType = editRouteTypeParam === 'blog' ? 'blogpost' : 'page';

	let viewId = CONTAINER_HOME_ID;
	if (isCompanyHubSpaceKey(spaceKey)) {
		viewId = PRODUCT_HOME_ID;
	} else if (isBlog) {
		viewId = CONTAINER_BLOG_ID;
	}

	return {
		Screen: {
			screenEvent: {
				name: 'editPageScreen',
				id: contentId,
				attributes: {
					isPublicView: false,
					pageLoadInfo: getUniquePageLoadId(),
					navdexPointType: END,
				},
			},
			pageState: {
				routeName,
				spaceKey,
				contentId,
				contentType,
			},
			showPremiumOnboarding: isPageLikeContent ? showPremiumOnboarding : false,
		},
		MainLayout: {
			navView: viewId,
			spaceKey,
			editorPreloader: isPageLikeContent ? getEditorPreloader : undefined,
			disableMinWidth: true,
			isViewPage: true,
			isEdit: true,
		},
	};
};
