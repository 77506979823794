import type { ReactNode } from 'react';
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';

export type FormProps = {
	onSubmit: () => void;
	children: ReactNode;
};

export const Form = (props: Parameters<RenderFn>[0]) => {
	const { onSubmit } = props.forgeDoc.props as FormProps;
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit();
			}}
		>
			{props.forgeDoc.children.map(props.render)}
		</form>
	);
};

export const FormContext = React.createContext<{
	formValues: Record<string, any>;
	setFormValue: (name: string, value: any) => void;
} | null>(null);
