/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import { RovoIcon } from '@atlaskit/logo';
import { Box, Inline, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { AtlassianIntelligenceLogo } from '../../AtlassianIntelligenceLogo/AtlassianIntelligenceLogo';
import messages from '../messages';
import { type AIBrand } from '../types';

export interface FooterAIBrandProps {
	/** The brand to display */
	brand: AIBrand;
	/** The appearance of main footer. */
	appearance: 'small' | 'medium' | 'large';
}

export function FooterAIBrand({ brand, appearance }: FooterAIBrandProps) {
	const { formatMessage } = useIntl();

	const shortDisclaimer =
		brand === 'rovo' ? messages.rovoCopyright : messages.atlassianIntelligenceCopyrightShort;
	const fullDisclaimer =
		brand === 'rovo' ? messages.rovoCopyright : messages.atlassianIntelligenceCopyright;

	return (
		<Inline>
			{appearance !== 'small' && (
				<Box>
					{appearance === 'medium' ? (
						<Text size="small" color="color.text.subtlest">
							{formatMessage(shortDisclaimer)}
						</Text>
					) : (
						<Text size="small" color="color.text.subtlest">
							{formatMessage(fullDisclaimer)}
						</Text>
					)}
				</Box>
			)}

			{brand === 'rovo' ? (
				<RovoIcon label={formatMessage(fullDisclaimer)} appearance="neutral" size="small" />
			) : (
				<AtlassianIntelligenceIcon
					label={formatMessage(fullDisclaimer)}
					color={token('color.icon.subtle')}
					spacing="spacious"
					LEGACY_fallbackIcon={AtlassianIntelligenceLogo}
					LEGACY_size="medium"
				/>
			)}
		</Inline>
	);
}
