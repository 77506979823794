import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { UpdatePrincipalModal } from '@confluence/space-roles/entry-points/Dialogs';
import { DEFAULT_RBAC_EDIT_EXPERIENCE } from '@confluence/experience-tracker';

import { useSetLegacyPermissions } from '../graphql/hooks/useSetLegacyPermissions';

type UpdatePrincipalModalProps = ComponentProps<typeof UpdatePrincipalModal>;
export type UpdateGroupModalProps = Pick<
	UpdatePrincipalModalProps,
	'principalRoleAssignment' | 'spaceRoleOptions' | 'allPermissions' | 'onClose' | 'refreshTableData'
> & {
	updateRole: (
		principalId: string,
		roleId: string,
		roleDisplayName: string,
		rolePermissions: string[],
	) => Promise<void>;
	updateCachedPermissions: (principalId: string, permissions: string[]) => void;
};

export const UpdateGroupModal = ({
	principalRoleAssignment,
	spaceRoleOptions,
	allPermissions,
	updateRole,
	updateCachedPermissions,
	refreshTableData,
	onClose,
}: UpdateGroupModalProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { isPermissionAssignmentLoading, setLegacyPermissions } = useSetLegacyPermissions({
		onResponse: onClose,
	});

	const hasRole = principalRoleAssignment.roleId !== null;

	const onUpdateRole: UpdatePrincipalModalProps['onUpdateRole'] = useCallback(
		async ({ roleId, principalId, roleDisplayName, newPermissionKeys }): Promise<void> => {
			await updateRole(principalId, roleId, roleDisplayName, newPermissionKeys);
		},
		[updateRole],
	);

	const onUpdatePermissions: UpdatePrincipalModalProps['onUpdatePermissions'] = useCallback(
		async ({ subjectPermissionDeltasListV2, newPermissionKeys }): Promise<void> => {
			const { principalInput, permissionsToAdd, permissionsToRemove } =
				subjectPermissionDeltasListV2[0];

			await setLegacyPermissions({
				variables: {
					input: {
						subjectPermissionDeltasListV2: [
							{
								principalInput,
								permissionsToRemove,
								// If the user is moving off of a role, we need to grant them the permissions
								// that were granted through that role.
								// https://atlassian.slack.com/archives/C06MN81H0R2/p1726768707192049
								permissionsToAdd: hasRole ? newPermissionKeys : permissionsToAdd,
							},
						],
					},
				},
			});
			updateCachedPermissions(principalRoleAssignment.assignmentId, newPermissionKeys);
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'updateGroupRoles',
					source: 'defaultRolesView',
				},
			}).fire();
		},
		[
			setLegacyPermissions,
			updateCachedPermissions,
			principalRoleAssignment.assignmentId,
			hasRole,
			createAnalyticsEvent,
		],
	);

	return (
		<UpdatePrincipalModal
			onClose={onClose}
			spaceRoleOptions={spaceRoleOptions}
			principalRoleAssignment={principalRoleAssignment}
			allPermissions={allPermissions}
			isUpdating={isPermissionAssignmentLoading}
			onUpdateRole={onUpdateRole}
			onUpdatePermissions={onUpdatePermissions}
			refreshTableData={refreshTableData}
			experience={DEFAULT_RBAC_EDIT_EXPERIENCE}
			isDefaultsView
		/>
	);
};
