import { type QueryOptions } from 'apollo-client';
import type ApolloClient from 'apollo-client';
import { type ContextId, type ForgeUIExtensionType } from '@atlassian/forge-ui-types';
import {
	createExtensionListQueryOptions,
	getExtensionsFromGQLResult,
	type QueryData,
} from '../web-client';
import type { GQLExtensionContextsFilter } from '../web-client';
import { type GraphQLError } from 'graphql';

export type GetForgeUIExtensionsAsyncOptions = {
	client: ApolloClient<object>;
	contextIds: ContextId[];
	moduleType?: string;
	queryOptions?: Omit<QueryOptions, 'query'>;
	expandAppOwner?: boolean;
	extensionsFilter?: GQLExtensionContextsFilter[];
	dataClassificationTagIds?: string[];
	locale?: string;
};

export async function getForgeUIExtensionsAsync({
	client,
	contextIds,
	moduleType,
	queryOptions,
	expandAppOwner,
	extensionsFilter,
	dataClassificationTagIds,
	locale,
}: GetForgeUIExtensionsAsyncOptions): Promise<{
	extensions: ReadonlyArray<ForgeUIExtensionType> | null;
	errors?: ReadonlyArray<GraphQLError>;
}> {
	const requestOptions = createExtensionListQueryOptions<QueryOptions>(
		client,
		contextIds,
		queryOptions as QueryOptions,
		expandAppOwner,
		extensionsFilter,
		moduleType,
		dataClassificationTagIds,
		locale,
	);

	const queryResult = await client.query<QueryData>(requestOptions);
	return {
		extensions: getExtensionsFromGQLResult<ForgeUIExtensionType>(queryResult),
		errors: queryResult.errors,
	};
}
