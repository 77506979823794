/**
 * Checks if a keyboard event matches a given shortcut.
 *
 * @param event - The keyboard event to check.
 * @param shortcut - The shortcut string to match against (e.g., "Ctrl-Shift-A").
 * @returns True if the event matches the shortcut, false otherwise.
 */
export function isKeyboardEventMatchShortcut(
	event: Pick<KeyboardEvent, 'key' | 'ctrlKey' | 'metaKey' | 'shiftKey' | 'altKey'>,
	shortcut: string,
) {
	const parts = shortcut.split('-');
	const key = parts.pop();
	const mods = new Set(parts);

	if (event.key !== key) {
		return false;
	}

	if (mods.has('Mod') !== event.metaKey) {
		return false;
	}

	if (mods.has('Shift') !== event.shiftKey) {
		return false;
	}

	if (mods.has('Alt') !== event.altKey) {
		return false;
	}

	if (mods.has('Ctrl') !== event.ctrlKey) {
		return false;
	}

	return true;
}
