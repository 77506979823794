import { fg } from '@atlaskit/platform-feature-flags';

import { deriveProactiveAIApiUrl } from '../../prebuilt/utils';
import type { AIGlobalOptIn, EditorPluginAIProvider, ProactiveAIConfig } from '../../types';

export const getConfiguration = (
	editorPluginAIProvider: EditorPluginAIProvider,
	aiOptIn: AIGlobalOptIn,
	__livePage?: boolean,
): {
	isProactiveAISupported: boolean;
	proactiveAIConfig: ProactiveAIConfig;
} => {
	const { proactiveAIConfig, isFullPageExperimentsEnabled, allowProactiveAIFeatures } =
		editorPluginAIProvider;

	const isProactiveAISupported =
		!!isFullPageExperimentsEnabled &&
		!!allowProactiveAIFeatures &&
		aiOptIn?.status === 'enabled' &&
		!!fg('platform_editor_ai_proactive_visual_formatting') &&
		!(__livePage && fg('platform_editor_ai_disable_proactive_on_live_pages'));

	return {
		isProactiveAISupported,
		proactiveAIConfig: {
			...proactiveAIConfig,
			enabled: isProactiveAISupported,
			defaultToggledState: false,
			apiUrl: deriveProactiveAIApiUrl(),
			timings: {
				// disabling the current chunk checking
				currentChunks: -1,
				currentChunksMaxWait: 30000,
				nonCurrentChunks: 5000,
			},
			documentChecker: {
				enabled: true,
				blocksPerRequest: 7,
				timings: {
					delayBetweenChecks: 0,
					numberOfRequestsForIncrease: 2,
					delayIncrease: 0,
					maxDelay: 0,
				},
			},
		},
	};
};
