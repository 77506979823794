import { query } from '@confluence/query-preloader-tools';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';

import { DatabaseUnifiedQuery } from './graphql/DatabaseUnifiedQuery.graphql';
import type {
	DatabaseUnifiedQueryVariables,
	DatabaseUnifiedQuery as DatabaseUnifiedQueryType,
} from './graphql/__types__/DatabaseUnifiedQuery';

export async function preloadDatabaseData(spaceKey: string, contentId: string): Promise<any> {
	return Promise.all([
		preloadContentData(contentId, spaceKey),
		preloadContentTree(contentId, spaceKey),
	]);
}

async function preloadContentData(contentId: string, spaceKey: string): Promise<void> {
	await query<DatabaseUnifiedQueryType, DatabaseUnifiedQueryVariables>({
		query: DatabaseUnifiedQuery,
		variables: {
			contentId,
			spaceKey,
			// The DSP query doesn't currently user the content version value, so using a fixed value
			contentVersion: 1,
		},
	});
}

async function preloadContentTree(contentId: string, spaceKey: string): Promise<void> {
	if (process.env.REACT_SSR && contentId) {
		await preloadPageTree({
			spaceKey,
			statuses: PAGE_TREE_STATUSES,
			isSuperAdmin: false,
			contentId,
		});
	}
}
