import { useCallback } from 'react';

import { ONBOARDING_TRACKER_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import {
	useGetOnboardingState,
	deserializeState,
	useSetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';

import { useOnboardingTrackerEvents } from '../OnboardingTrackerEvents';
import {
	TRACKERITEMS_ORGANIZE_SPACE,
	TRACKERITEMS_INVITE_PEOPLE,
} from '../OnboardingTrackerConstants';

import { useOnboardingTrackerEligible } from './useOnboardingTrackerEligible';
import { useTrackerCelebrationsActions } from './useTrackerCelebrations';

export const useOnboardingTrackerAction = () => {
	const { fireCoreActionCompleted } = useOnboardingTrackerEvents();
	const { isOnboardingTrackerExperimentEligible } = useOnboardingTrackerEligible();
	const { setShowOrganizeSpaceCelebration, setShowInvitePeopleCelebration } =
		useTrackerCelebrationsActions();
	const { data: onboardingStateData } = useGetOnboardingState(
		Object.values({
			...ONBOARDING_TRACKER_STATE_KEYS,
		}),
		!isOnboardingTrackerExperimentEligible,
	);
	const { hasUserInvited, hasExploredSpace } = deserializeState(onboardingStateData);
	const { setOnboardingState } = useSetOnboardingState();

	const markOnboardingTrackerItemCompleted = useCallback(
		(key: string) => {
			if (!isOnboardingTrackerExperimentEligible) return;
			switch (key) {
				case ONBOARDING_TRACKER_STATE_KEYS.HAS_EXPLORED_SPACE:
					if (!hasExploredSpace) {
						setOnboardingState({
							key,
							value: 'true',
						});
						void setShowOrganizeSpaceCelebration();
						fireCoreActionCompleted({
							name: TRACKERITEMS_ORGANIZE_SPACE,
							origin: 'pageTree',
						});
					}
					break;
				case ONBOARDING_TRACKER_STATE_KEYS.HAS_USER_INVITED:
					if (!hasUserInvited) {
						setOnboardingState({
							key,
							value: 'true',
						});
						void setShowInvitePeopleCelebration();
						fireCoreActionCompleted({
							name: TRACKERITEMS_INVITE_PEOPLE,
							origin: 'coreInviteModal',
						});
					}
					break;
			}
		},
		[
			fireCoreActionCompleted,
			isOnboardingTrackerExperimentEligible,
			hasExploredSpace,
			hasUserInvited,
			setOnboardingState,
			setShowOrganizeSpaceCelebration,
			setShowInvitePeopleCelebration,
		],
	);

	return {
		markOnboardingTrackerItemCompleted,
	};
};
