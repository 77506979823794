import { useContext } from 'react';

import { fg } from '@confluence/feature-gating';
import { FrontCoverStateEnum } from '@confluence/route-manager/entry-points/companyHubUtils';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';

import { useCompanyHubPremiumGate } from '../upsell/useCompanyHubPremiumGate';

export const useIsCompanyHubEnabled = (): {
	/**
	 * `true` if the current user is allowed (i.e. is entitled to the feature and
	 * has the necessary permissions) to publish (in the sense of
	 * `isCompanyHubPublished`) the company hub.
	 */
	canPublish: boolean;
	isCompanyHubEntryPointEnabled: boolean;
	isCompanyHubPublished: boolean;
	isCompanyHubShownToEditors: boolean;
} => {
	const { frontCoverState, isSiteAdmin } = useContext(SPAViewContext);
	const { accessStatus } = useSessionData();
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate();

	const canPublish = isSiteAdmin || accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;
	const isAdminDiscoverable =
		fg('company_hub_beta') &&
		// 1. If entitled (e.g. Premium), both site admin and product admin have the
		//    entry point in the top nav, because it leads to COMPANY_HUB.
		// 2. If not entitled (e.g. Free or Standard):
		//   a. only site admin has the entry point in the top nav, because it
		//      renders an upsell;
		//   b. product admin doesn't have the entry point in the top nav, because
		//      it automatically redirects to HOME.
		(shouldShowPremiumUpsell ? isSiteAdmin : canPublish);
	const isCompanyHubFeatureAvailable = fg('company_hub_beta') && !shouldShowPremiumUpsell;

	const isCompanyHubPublished = Boolean(
		isCompanyHubFeatureAvailable && frontCoverState === FrontCoverStateEnum.SHOWN,
	);
	const isCompanyHubShownToEditors = Boolean(
		(isCompanyHubFeatureAvailable && frontCoverState === FrontCoverStateEnum.EDITORS_ONLY) ||
			isCompanyHubPublished,
	);
	const isCompanyHubEntryPointEnabled = Boolean(
		(isCompanyHubFeatureAvailable && (isCompanyHubPublished || isCompanyHubShownToEditors)) ||
			isAdminDiscoverable,
	);

	return {
		canPublish: isCompanyHubFeatureAvailable && canPublish,
		isCompanyHubPublished,
		isCompanyHubShownToEditors,
		isCompanyHubEntryPointEnabled,
	};
};
