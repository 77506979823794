import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';
import type Token from 'markdown-it/lib/token';

const replaceBrWithLineBreak = (text: string) => {
	// Matches <br> or <br /> or <br/> with optional trailing space
	// Ignored via go/ees005
	// eslint-disable-next-line require-unicode-regexp
	return text.replace(/<br\s*\/?>\s?/gi, '\n');
};

const processInlineChildren = (children: Token[]) => {
	for (const child of children) {
		if (child.type === 'text') {
			const newText = replaceBrWithLineBreak(child.content);
			if (child.content !== newText) {
				child.content = newText;
			}
		}
	}
};

const brToLineBreak = (state: StateCore) => {
	for (const token of state.tokens) {
		if (token.type === 'inline' && token.children) {
			processInlineChildren(token.children);
		}
	}
};

export default (md: MarkdownIt) => md.core.ruler.after('inline', 'lineBreak', brToLineBreak);
