import type { ComponentType } from 'react';
import React from 'react';

import SuccessIcon from '@atlaskit/icon/core/migration/check-mark--check';
import ErrorIcon from '@atlaskit/icon/core/migration/cross-circle--editor-error';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import SuccessCircleIcon from '@atlaskit/icon/core/migration/success--check-circle';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import type { GlyphProps } from '@atlaskit/icon/types';
import type { NewIconProps } from '@atlaskit/icon';
import { B300, G300, R300, Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { IconColor } from '@atlaskit/tokens/css-type-schema';

function colorizeIcon(
	Icon: ComponentType<GlyphProps> | ComponentType<NewIconProps>,
	color: IconColor,
	label: string,
	isBold: boolean,
) {
	return isBold ? (
		<Icon
			label={label}
			LEGACY_primaryColor="currentColor"
			LEGACY_secondaryColor={color}
			color="currentColor"
			spacing="spacious"
		/>
	) : (
		<Icon label={label} LEGACY_primaryColor={color} color={color} spacing="spacious" />
	);
}

export const FlagIcon = ({
	type,
	isBold,
	customIcon,
}: {
	type?: 'success-circle' | 'success' | 'error' | 'warning' | 'info' | 'custom';
	isBold: boolean;
	customIcon?: JSX.Element;
}) => {
	switch (type) {
		case 'success-circle':
			return colorizeIcon(SuccessCircleIcon, token('color.icon.success', G300), 'Success', isBold);
		case 'success':
			return colorizeIcon(SuccessIcon, token('color.icon.success', G300), 'Success', isBold);
		case 'error':
			return colorizeIcon(ErrorIcon, token('color.icon.danger', R300), 'Error', isBold);
		case 'warning':
			return colorizeIcon(WarningIcon, token('color.icon.warning', Y300), 'Warning', isBold);
		case 'custom':
			return (
				customIcon ?? colorizeIcon(InfoIcon, token('color.icon.information', B300), 'Info', isBold)
			);
		case 'info':
		default:
			return colorizeIcon(InfoIcon, token('color.icon.information', B300), 'Info', isBold);
	}
};
