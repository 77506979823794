/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { SimpleTag } from '@atlaskit/tag';
import { token } from '@atlaskit/tokens';

import { messages } from './messages';

/**
 * The opacity of this button is controlled by the parent component
 */
const containerStyles = css({
	flexShrink: 0,
	marginLeft: 'auto',
	marginTop: token('space.negative.025', '-2px'),
	marginBottom: token('space.negative.025', '-2px'),
	display: 'inline-flex',
	opacity: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		cursor: 'pointer',
		margin: 0,
		padding: `${token('space.025', '2px')} ${token('space.075', '6px')}`,
	},
});

const placeholderStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		cursor: 'default',
	},
});

export const EditPromptHoverTag = ({
	isClearMode = false,
	isPlaceholder,
}: {
	isClearMode?: boolean;
	// if true, will not show pointer cursor, only act as a space placeholder
	isPlaceholder?: boolean;
}) => {
	const { formatMessage } = useIntl();

	const text = React.useMemo(() => {
		return isClearMode
			? formatMessage(messages.clearPromptHover)
			: formatMessage(messages.editPromptHover);
	}, [isClearMode, formatMessage]);

	return (
		<span
			id="edit-prompt-hover-button"
			data-testid="edit-prompt-hover-button"
			css={[containerStyles, isPlaceholder && placeholderStyles]}
		>
			<SimpleTag text={text} />
		</span>
	);
};
