import React from 'react';

import AKIcon, { type IconProps as AKIconProps, type CustomGlyphProps } from '@atlaskit/icon';
import HeartIcon from '@atlaskit/icon/core/heart';
import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

const IconGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		role="presentation"
		// Ignored via go/ees005
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		<path d="M11.121 5.385L11.278 5.535L11.985 6.242L12.692 5.536C13.6168 4.61388 14.8658 4.09021 16.1717 4.07699C17.4777 4.06378 18.7369 4.56205 19.6803 5.46527C20.6236 6.36848 21.1761 7.60494 21.2195 8.91022C21.263 10.2155 20.7941 11.486 19.913 12.45L19.763 12.607L18.352 14.018L12.71 19.704C12.5359 19.8796 12.3028 19.9845 12.0559 19.9983C11.809 20.0122 11.5657 19.934 11.373 19.779L11.292 19.706L5.62 14.02L4.207 12.607C3.27965 11.6833 2.75151 10.4327 2.73597 9.12391C2.72044 7.81509 3.21875 6.55236 4.12391 5.60688C5.02907 4.6614 6.26889 4.10856 7.57714 4.06707C8.8854 4.02559 10.1578 4.49877 11.121 5.385ZM18.349 11.192C18.8975 10.6395 19.2097 9.89545 19.2197 9.11701C19.2297 8.33857 18.9367 7.58673 18.4026 7.02032C17.8686 6.45391 17.1352 6.1173 16.3575 6.0816C15.5798 6.0459 14.8187 6.31391 14.235 6.829L14.107 6.949L12.692 8.364C12.5592 8.49619 12.3921 8.58855 12.2095 8.63062C12.0269 8.67268 11.8362 8.66277 11.659 8.602C11.5158 8.55271 11.3858 8.47129 11.279 8.364L9.863 6.95C9.31051 6.40153 8.56645 6.08931 7.78801 6.0793C7.00958 6.0693 6.25773 6.36228 5.69132 6.89637C5.12492 7.43045 4.7883 8.1638 4.7526 8.94148C4.7169 9.71916 4.98491 10.4803 5.5 11.064L5.621 11.192L7.036 12.607L11.998 17.582L16.932 12.609L18.349 11.192Z" />
	</svg>
);
export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<HeartIcon
				label=""
				color="currentColor"
				spacing="spacious"
				// Ignored via go/ees005
				// eslint-disable-next-line react/jsx-props-no-spreading
				LEGACY_fallbackIcon={(props: AKIconProps) => <AKIcon glyph={IconGlyph} {...props} />}
				LEGACY_secondaryColor={token('color.icon')}
			/>
		</RainbowBorder>
	);
};
