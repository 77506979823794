import { useAnalyticsEvents } from '@atlaskit/analytics-next';

export const useAllSpacesTableAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// (Only for reducing code duplication. Not designed to be reusable.)
	const fireUIButtonClickEvent = (subjectId: string, attributes?: Record<string, string>) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: subjectId,
				source: 'allSpacesTable',
				attributes,
			},
		}).fire();
	};

	return {
		sendEventFilterByKey: () => fireUIButtonClickEvent('filterByKey'),
		sendEventManageAccess: () => fireUIButtonClickEvent('manageAccess'),
		sendEventRecoverPermissionsStart: () => fireUIButtonClickEvent('recoverPermissionsStart'),
		sendEventRecoverPermissionsCancel: () => fireUIButtonClickEvent('recoverPermissionsCancel'),
		sendEventRecoverPermissionsConfirm: () => fireUIButtonClickEvent('recoverPermissionsConfirm'),
	};
};
