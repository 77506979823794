import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import { type RovoPublish } from '../../types';
import { type DecorationPositions } from '../decoration/actions';

import { createCommand } from './plugin-factory';
import { ACTIONS } from './states';

export const updateAgents = ({
	agents,
	defaultAgent,
}: {
	agents: EditorAgent[];
	defaultAgent?: EditorAgent;
}) =>
	createCommand(() => {
		return {
			type: ACTIONS.UPDATE_PLUGIN_STATE,
			data: {
				// Exclude the default agent from the list of agents
				// See https://product-fabric.atlassian.net/browse/EDF-881
				agents: agents.filter((agent) => agent.namedId !== 'ai_mate_agent'),
				defaultAgent,
			},
		};
	});

export const openBrowseAgentsModal = (open: boolean, rawPositions?: number[]) => {
	// Convert rawPositions into a structured format if provided.
	// This ensures that when the browse agents modal is closed,
	// the selection is restored correctly.
	const positions: DecorationPositions | undefined = rawPositions && {
		startPos: rawPositions[0],
		endPos: rawPositions[1],
	};

	return createCommand(() => {
		return {
			type: ACTIONS.UPDATE_PLUGIN_STATE,
			data: {
				isBrowseModalOpen: open,
				positions,
			},
		};
	});
};

export const updatePublish = (publish: RovoPublish) => {
	return createCommand(() => {
		return {
			type: ACTIONS.UPDATE_PLUGIN_STATE,
			data: {
				publish,
			},
		};
	});
};

export const updateEnabled = (enabled: boolean) => {
	return createCommand(() => {
		return {
			type: ACTIONS.UPDATE_PLUGIN_STATE,
			data: {
				enabled,
			},
		};
	});
};
