import React from 'react';
import type { FC, ReactNode } from 'react';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports
import { css } from 'emotion';

const blanketStyle = css`
	width: 100%;
	transition: opacity 0.3s;
`;

const ghostedStyle = css`
	opacity: 0.5;
	pointer-events: none;
`;

type SavingBlanketProps = {
	children: ReactNode;
	isSaving: boolean;
};

export const SavingBlanket: FC<SavingBlanketProps> = ({ isSaving, children }) => {
	const classNames = [blanketStyle, ...(isSaving ? [ghostedStyle] : [])];
	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={classNames.join(' ')}
			onClickCapture={(e) => {
				// The `pointer-events: none` CSS is mostly to kill hover styles.
				// This event capture is a more guaranteed event trap.
				if (isSaving) e.stopPropagation();
			}}
		>
			{
				// Original design called for a spinner here; we'll need to figure out
				// how the scrolling is implemented before that can be incorporated.
			}
			{children}
		</div>
	);
};
