export const TEMPLATE_PICKER_QUERY_PARAM = 'openTemplatePicker';
export const NEW_WHITEBOARD_QUERY_PARAM = 'isNewWhiteboard';
export const INSERT_TEMPLATE_WITH_KEY_QUERY_PARAM = 'insertTemplateWithKey';
export const HEADLESS_QUERY_PARAM = 'headless';
export const SERVER_SPOT_PARAM = 'canvas-server';
export const RELAY_SPOT_PARAM = 'canvas-relay';
export const WHITEBOARD_IFRAME_TITLE = 'whiteboard-frame';
export const WHITEBOARD_LOCALE = 'whiteboard-locale';
export const WHITEBOARD_LINKED_OBJECT = 'whiteboard-linked-object';
export const INITIAL_THEME_PARAM = 'initial-theme';
export const PAYWALL_DEACTIVATION_IDENTIFIER = 'deactivationIdentifier';
export const WHITEBOARD_PAYWALL_UPSELL_ACTION_SUBJECT_ID = 'whiteboardPaywallUpsellButton';
export const WHITEBOARD_PAYWALL_INFO_ACTION_SUBJECT_ID = 'whiteboardPaywallLearnMoreButton';
export const WHITEBOARD_CREATION_PAYWALL_SOURCE_PREFIX = 'whiteboardCreationPaywall-';

export const JIRA_IMPORT_TOOL_QUERY_PARAM = 'openJiraImportTool';
export const CLOUD_ID = 'cloudId';
export const PROJECT_UUID = 'projectUuid';
export const WHITEBOARD_GA_LAUNCH_DATE = new Date('2024-02-29T00:00Z');
export const PARENT_PRODUCT_QUERY_PARAM = 'parentProduct';
export const PARENT_DOMAIN_QUERY_PARAM = 'parentDomain';
export const EMBEDDED_THEME_STATE = 'themeState';
/** Used by the embedded Whiteboard view to show creation time (eg "Created 4 days ago").
 *
 *  Currently this is provided by Whiteboard's smart-link resolver and is passed down to the Whiteboard iframe URL by CFE
 */
export const EMBEDDED_WHITEBOARD_CREATED_AT_QUERY_PARAM = 'createdAt';
export const FOCUSED_COMMENT_ID = 'focusedCommentId';
export const REPLY_TO_COMMENT = 'replyToComment';
export const WHITEBOARD_VERSION_ID = 'version';

export type PaywallButtonActionTypes = 'active' | 'redirect' | 'viewOnly' | 'close';

/**
 * Used to compute the link to return to after creating a whiteboard via `/` command
 */
export const CREATED_FROM_SLUG = 'createdFromSlug';
