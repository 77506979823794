import type {
	ExtractInjectionAPI,
	FloatingToolbarHandler,
	FloatingToolbarSeparator,
} from '@atlaskit/editor-common/types';
import { NodeSelection } from '@atlaskit/editor-prosemirror/state';
import { findParentNode } from '@atlaskit/editor-prosemirror/utils';
import { fg } from '@atlaskit/platform-feature-flags';

import type { AIPlugin } from '../editor-plugin-ai';
import { getAIExperienceButton } from '../pm-plugins/floating-toolbar-button/floating-toolbar-button';
import type { AIGlobalOptIn, EditorPluginAIProvider } from '../types';
import { getSupportedNodesForAIModal } from '../utils/ai-button';

export const getFloatingToolbarConfig =
	({
		aiGlobalOptIn,
		editorPluginAIProvider,
		api,
	}: {
		aiGlobalOptIn: AIGlobalOptIn;
		editorPluginAIProvider: EditorPluginAIProvider;
		api: ExtractInjectionAPI<AIPlugin> | undefined;
	}): FloatingToolbarHandler =>
	(state, intl, providerFactory, processedConfigs) => {
		/**
		 * Add AI button in nodes supported (currently only table is supported).
		 * List of supported node can be found in implementation of getSupportedNodesForAIModal.
		 */
		const { selection } = state;
		const { from, to } = selection;
		const isNodeSelection = selection instanceof NodeSelection;
		const isCellSelection = selection.toJSON().type === 'cell';
		const getParentSupportedForAIButton = () => {
			const supportedNodesForAI = getSupportedNodesForAIModal(state);

			if (isNodeSelection) {
				return supportedNodesForAI.has(selection.node.type) ? selection.node : undefined;
			} else {
				const parentNodeFindResult = findParentNode((node) => supportedNodesForAI.has(node.type))(
					selection,
				);
				return parentNodeFindResult?.node;
			}
		};
		const disabled =
			fg('platform_editor_offline_editing_mvp') &&
			api?.connectivity?.sharedState?.currentState()?.mode === 'offline';
		const parentSupportedForAIButton = getParentSupportedForAIButton();
		if ((from === to || isNodeSelection || isCellSelection) && parentSupportedForAIButton) {
			const canAddAIButton = isNodeSelection || parentSupportedForAIButton;
			const nodeType = parentSupportedForAIButton?.type;
			if (canAddAIButton && nodeType) {
				const aiIconButton = getAIExperienceButton({
					intl,
					rangeBaseGenerate: editorPluginAIProvider.baseGenerate,
					hideTitle: true,
					aiGlobalOptIn,
					triggeredFor: nodeType,
					disabled,
				});

				const addAIButtonToConfig = (title: string) => {
					const toolbarConfig = processedConfigs?.find((config) => config.title === title);
					const items = Array.isArray(toolbarConfig?.items) ? toolbarConfig?.items : [];
					if (toolbarConfig && items.length) {
						const withAIItems = [
							...items.slice(0, items.length - 3),
							aiIconButton,
							{
								type: 'separator',
							} as FloatingToolbarSeparator,
							...items.slice(items.length - 3, items.length),
						];
						/**
						 * Updating items of table, panel, layout or expand config item.
						 * Injecting updated items in same config object.
						 */
						toolbarConfig.items = withAIItems;
						return toolbarConfig;
					}
				};

				/**
				 * Here we are updating items of table, panel, expand and layout
				 * 	floaingToolbarConfig.
				 * This will only work when editor-plugin-ai is added after these plugins.
				 * Proper solution will be implemented when outcome
				 * 	of https://hello.atlassian.net/wiki/spaces/EDITOR/pages/4166466469/Editor+RFC+058+Easier+way+to+insert+items+in+floating+toolbar
				 *  RFC is finalised.
				 */
				const toolbarConfig = addAIButtonToConfig('Table floating controls');

				if (toolbarConfig) {
					return toolbarConfig;
				}
			}
		}

		return;
	};
