import React from 'react';
import { styled } from '@compiled/react';

import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import { R400, R500 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessageWrapper = styled.div({
	display: 'flex',
	alignItems: 'flex-start',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorText = styled.span({
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.accent.red', R500),
});

export const FormFieldErrorMessage = ({ children }) => (
	<ErrorMessageWrapper>
		<ErrorIcon
			label=""
			LEGACY_size="small"
			spacing="none"
			LEGACY_primaryColor={token('color.icon.accent.red', R400)}
		/>
		<ErrorText>{children}</ErrorText>
	</ErrorMessageWrapper>
);
