import gql from 'graphql-tag';
import { fg } from '@atlaskit/platform-feature-flags';

const convertDataClassificationTagsToString = (dataClassificationTagIds: string[]) => {
	return `[
    ${dataClassificationTagIds.map((id) => `"${id}"`).join(', ')}
  ]`;
};

export const getExtensionListQueryOld = (
	expandAppOwner?: boolean,
	moduleTypes?: string[],
	dataClassificationTagIds?: string[],
) => {
	const modules = moduleTypes?.map((m) => `"${m}"`) || ['$type'];
	const userAccessField = fg('platform-forge-ui-useraccess-in-extension-query')
		? `userAccess {
    hasAccess
  }`
		: '';
	const query = `
  query forge_ui_extensionList($contextIds: [ID!]!${moduleTypes ? '' : ', $type: String!'}, $locale: String) {
    extensionContexts(contextIds: $contextIds) {
      id
      ${modules
				.map(
					(m) => `
        extensionsByType(type: ${m}, locale: $locale ) {
          id
          appId
          key
          ${
						expandAppOwner
							? `appOwner {
            name
          }`
							: ''
					}
          environmentId
          environmentType
          environmentKey
          properties
          license {
            active
            type
            supportEntitlementNumber
            trialEndDate
            subscriptionEndDate
            isEvaluation
            billingPeriod
            ccpEntitlementId
            ccpEntitlementSlug
						capabilitySet
          }
          ${userAccessField}
          type
          appVersion
          installationId
          migrationKey
          egress {
            type
            addresses
          }
					consentUrl
					currentUserConsent {
						user {
							aaid
						}
						appEnvironmentVersion {
							id
						}
						consentedAt
					}
					requiresUserConsent
          ${
						dataClassificationTagIds && dataClassificationTagIds.length > 0
							? `
                dataClassificationPolicyDecision(input: {
                  dataClassificationTags: ${convertDataClassificationTagsToString(
										dataClassificationTagIds,
									)}
                }) {
                  status
                }
              `
							: ''
					}
        }`,
				)
				.join('')}
    }
  }`;

	return gql`
		${query}
	`;
};

export const getExtensionListQuery = (
	expandAppOwner?: boolean,
	dataClassificationTagIds?: string[],
) => {
	const userAccessField = fg('platform-forge-ui-useraccess-in-extension-query')
		? `userAccess {
    hasAccess
  }`
		: '';

	const query = `
  query forge_ui_extensionList($contextIds: [ID!]!, $filter: [ExtensionContextsFilter!]!, $locale: String ) {
    extensionContexts(contextIds: $contextIds) {
      id
      extensions(filter: $filter, locale: $locale) @optIn(to: "Access-Narrowing") {
        id
        appId
        key
        ${
					expandAppOwner
						? `appOwner {
          name
        }`
						: ''
				}
        environmentId
        environmentType
        environmentKey
        properties
        license {
          active
          type
          supportEntitlementNumber
          trialEndDate
          subscriptionEndDate
          isEvaluation
          billingPeriod
          ccpEntitlementId
          ccpEntitlementSlug
					capabilitySet
        }
        ${userAccessField}
        type
        appVersion
        installationId
        migrationKey
        egress {
          type
          addresses
        }
				consentUrl
				currentUserConsent {
					user {
						aaid
					}
					appEnvironmentVersion {
						id
					}
					consentedAt
				}
				requiresUserConsent
        ${
					dataClassificationTagIds && dataClassificationTagIds.length > 0
						? `
              dataClassificationPolicyDecision(input: {
                dataClassificationTags: ${convertDataClassificationTagsToString(
									dataClassificationTagIds,
								)}
              }) {
                status
              }
            `
						: ''
				}
      },
    }
  }`;

	return gql`
		${query}
	`;
};
