import { SSRMeasures } from '@confluence/action-measures';

/**
 * @deprecated
 * Use next/packages/query-preloader-tools/src/partialSSRSuccess.ts to set and check if a component was partially SSR'd
 */
export const bestEffortTimeouts: { [key: string]: boolean } = {};

export const bestEffortTask = async (
	queryName: string,
	task: () => Promise<any>,
	force = false,
): Promise<any> => {
	// We are not doing best effort on staging as it can cause flakiness with some elements that we should always test for in Cypress.
	// We will however allow best effort logic to run on jest tests for testing purposes.
	// We are trying to remove the best effort logic from the codebase, so we are adding a feature flag to test it
	if ((process.env.CLOUD_ENV === 'staging' || force) && !process.env.JEST_WORKER_ID) {
		return task();
	} else {
		bestEffortTimeouts[queryName] = true;
		void SSRMeasures.run(`ssr-app/prepare/preloadQuery/bestEffort:${queryName}`, task).then(() => {
			delete bestEffortTimeouts[queryName];
		});
	}
};
