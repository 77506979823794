import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';

import { SpaceRolesBannerBase } from '@confluence/space-roles';

import { OnboardingModal } from './OnboardingModal';
import { useOnboardingModal } from './useOnboardingModal';

const i18n = defineMessages({
	learnMoreBannerButton: {
		id: 'default-space-permissions.learn-more-banner-button.non-final',
		defaultMessage: 'Learn more',
		description: 'Text for the learn more button on a banner that will open an info dialog',
	},
	getStartedBannerButton: {
		id: 'default-space-permissions.get-started-banner-button.non-final',
		defaultMessage: 'Get started',
		description: 'Text for the learn more button on a banner that will open an info dialog',
	},
});

export const SpaceRolesBanner = ({ isDefaultsTab }: { isDefaultsTab: boolean }) => {
	const { formatMessage } = useIntl();
	const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
	const { hasModalDisplayed, setModalDisplayed } = useOnboardingModal();

	useEffect(() => {
		if (isOnboardingModalOpen) {
			setModalDisplayed();
		}
	}, [isOnboardingModalOpen, setModalDisplayed]);

	useEffect(() => {
		if (isDefaultsTab && !hasModalDisplayed) {
			setIsOnboardingModalOpen(true);
		}
	}, [isDefaultsTab, hasModalDisplayed]);

	return (
		<>
			<SpaceRolesBannerBase
				actions={[
					<Button
						appearance="primary"
						key="defaults-banner-button"
						onClick={() => setIsOnboardingModalOpen(true)}
					>
						{formatMessage(
							isDefaultsTab ? i18n.learnMoreBannerButton : i18n.getStartedBannerButton,
						)}
					</Button>,
				]}
				analyticsSource="globalSpacePermissions"
			/>
			{isOnboardingModalOpen && (
				<OnboardingModal
					onClose={() => setIsOnboardingModalOpen(false)}
					isDefaultsTab={isDefaultsTab}
				/>
			)}
		</>
	);
};
