import { ExperienceName } from '@atlassian/conversation-assistant-instrumentation';
import {
	type AssistanceServiceInjectedParams,
	createHookWithAssistanceService,
	useMutation,
} from '@atlassian/conversation-assistant-service';

type UseAgentFavouritesProps = {
	onSuccess?: (params: { favourite: boolean }) => void;
	onError?: (params: { favourite: boolean }) => void;
};

export const useAgentFavourites = createHookWithAssistanceService(
	({
		onError,
		onSuccess,
		assistanceServiceInjectedParams: { assistanceService, experienceTracker },
	}: UseAgentFavouritesProps & AssistanceServiceInjectedParams) => {
		const favouriteAgent = useMutation({
			mutationFn: async (id: string) => {
				experienceTracker.start({
					id: ExperienceName.FAVOURITE_AGENT,
					name: ExperienceName.FAVOURITE_AGENT,
				});

				return await assistanceService.favouriteAgent(id);
			},
			onSuccess: async () => {
				experienceTracker.succeed({
					name: ExperienceName.FAVOURITE_AGENT,
				});

				onSuccess?.({ favourite: true });
			},
			onError: (error) => {
				experienceTracker.fail({
					name: ExperienceName.FAVOURITE_AGENT,
					error,
				});

				onError?.({ favourite: true });
			},
		});

		const unfavouriteAgent = useMutation({
			mutationFn: async (id: string) => {
				experienceTracker.start({
					id: ExperienceName.UNFAVOURITE_AGENT,
					name: ExperienceName.UNFAVOURITE_AGENT,
				});

				return await assistanceService.unfavouriteAgent(id);
			},
			onSuccess: async () => {
				experienceTracker.succeed({
					name: ExperienceName.UNFAVOURITE_AGENT,
				});

				onSuccess?.({ favourite: false });
			},
			onError: (error) => {
				experienceTracker.fail({
					name: ExperienceName.UNFAVOURITE_AGENT,
					error,
				});

				onError?.({ favourite: false });
			},
		});

		return {
			favouriteAgent,
			unfavouriteAgent,
		};
	},
);
