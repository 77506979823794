import gql from 'graphql-tag';

export const SpacePermissionsDefaults = gql`
	query spacePermissionsDefaults($after: String) {
		defaultSpacePermissions {
			editable
			groupsWithDefaultSpacePermissions(first: 500, after: $after, filterText: "") {
				nodes {
					subjectKey {
						id
						principalType
						displayName
					}
					permissions
				}
				pageInfo {
					hasNextPage
					hasPreviousPage
					endCursor
				}
			}
		}
	}
`;
