/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxfrag */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import ThumbsDownIcon from '@atlaskit/icon/core/thumbs-down';
import ThumbsUpIcon from '@atlaskit/icon/core/thumbs-up';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';

import messages from './messages';

type Feedback = 'good' | 'bad';
type Props = {
	onGoodResponseButtonClick: () => void;
	onBadResponseButtonClick: () => void;
	isPressed?: Feedback;
	isDisabled?: boolean;
};

type FeedbackButtonProps = {
	type: Feedback;
	onClick: () => void;
	isPressed?: boolean;
	isDisabled?: boolean;
};

const hideBackgroundStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		backgroundColor: token('color.background.neutral.subtle'),
	},
});

const Announcer = ({ message, shouldAnnounce }: { message: string; shouldAnnounce: boolean }) => {
	return (
		<VisuallyHidden>
			<Box aria-atomic="true" aria-live="polite">
				{shouldAnnounce && message}
			</Box>
		</VisuallyHidden>
	);
};

const FeedbackButton = ({ type, onClick, isPressed, isDisabled }: FeedbackButtonProps) => {
	const { formatMessage } = useIntl();
	const responseLabel =
		type === 'good' ? formatMessage(messages.goodResponse) : formatMessage(messages.badResponse);
	const color = isPressed ? token('color.link.pressed') : 'currentColor';
	const Icon = () =>
		type === 'good' ? (
			<ThumbsUpIcon label={responseLabel} color={color} />
		) : (
			<ThumbsDownIcon label={responseLabel} color={color} />
		);

	const tooltipOptions: IconButtonProps['tooltip'] = {
		position: 'top',
		content: responseLabel,
	};

	return (
		<div css={[(isPressed || isDisabled) && hideBackgroundStyles]}>
			<IconButton
				testId={`${type}-response-feedback-button`}
				type="button"
				isDisabled={isDisabled}
				onClick={onClick}
				icon={Icon}
				label={responseLabel}
				appearance="subtle"
				spacing="compact"
				isTooltipDisabled={false}
				tooltip={tooltipOptions}
			/>
		</div>
	);
};

export const FeedbackForm = ({
	onGoodResponseButtonClick,
	onBadResponseButtonClick,
	isPressed,
	isDisabled,
}: Props) => {
	const { formatMessage } = useIntl();
	const feedbackWasSentLabel = formatMessage(messages.feedbackSent);
	const [feedbackSelected, setFeedbackSelected] = React.useState<Feedback>();
	const handleOnClick = React.useCallback(
		(onClick: () => void, feedback: Feedback) => () => {
			onClick();
			setFeedbackSelected(feedback);
		},
		[setFeedbackSelected],
	);
	const disableButton = !!isPressed || isDisabled || !!feedbackSelected;

	return (
		<React.Fragment>
			<Announcer message={feedbackWasSentLabel} shouldAnnounce={Boolean(feedbackSelected)} />
			<FeedbackButton
				type="good"
				isPressed={isPressed === 'good'}
				isDisabled={disableButton}
				onClick={handleOnClick(onGoodResponseButtonClick, 'good')}
			/>
			<FeedbackButton
				type="bad"
				isPressed={isPressed === 'bad'}
				isDisabled={disableButton}
				onClick={handleOnClick(onBadResponseButtonClick, 'bad')}
			/>
		</React.Fragment>
	);
};
