import type { FC } from 'react';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import EditorWarningIcon from '@atlaskit/icon/core/migration/warning--editor-warning';

import {
	MacroExperienceStop,
	getMacroAttributesFromADFNode,
	getExperienceName,
} from '@confluence/macro-tracker';
import type { Mode, Extension } from '@confluence/macro-tracker';

type LegacyMacroRendererErrorProps = {
	error: ApolloError | undefined;
	mode: Mode;
	extensionKey: string | undefined;
	node: Extension;
	contentId: string;
};

export const LegacyMacroRendererError: FC<LegacyMacroRendererErrorProps> = ({
	error,
	mode,
	extensionKey,
	node,
	contentId,
}) => {
	const attributes = getMacroAttributesFromADFNode(node);

	return (
		<Fragment>
			<MacroExperienceStop
				extensionKey={extensionKey}
				error={error || Error(`Unable to render ${extensionKey}`)}
				mode={mode}
				attributes={attributes}
				contentId={contentId}
				name={getExperienceName(mode, node)}
				source="LegacyMacroRendererError"
			/>
			<div
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					display: 'flex',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					backgroundColor: token('color.background.neutral.subtle', '#F4F5F7'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					borderRadius: 5,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					minWidth: 200,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					padding: token('space.050', '4px'),
				}}
				data-testid="legacy-macro-renderer-error"
			>
				<EditorWarningIcon label="" LEGACY_size="medium" spacing="spacious" />
				<Box padding="space.050">
					<FormattedMessage
						id="fabric-extension-legacy-macro-renderer.legacymacrorenderer.error"
						defaultMessage="Failed to get macro details"
						description="Legacy macro renderer error message"
					/>
				</Box>
			</div>
		</Fragment>
	);
};
