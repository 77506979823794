import { useQuery } from '@apollo/react-hooks';

import {
	BODIED_EXTENSION_TYPE,
	REGULAR_EXTENSION_TYPE,
} from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import type { ContentConverterQueryTypes } from '@confluence/fabric-extension-queries';
import {
	ContentConverterQuery,
	getContentConverterVariables,
} from '@confluence/fabric-extension-queries';

export function useDataMacroBody(
	node: ExtensionHandlerProps['node'],
	contentId: string,
): { value?: string | null; missing?: boolean } {
	const bodyContentParam = node.parameters?.macroParams?.__bodyContent;
	const isPlainText = node.type === REGULAR_EXTENSION_TYPE && Boolean(bodyContentParam);
	const isRichTextMacro = node.type === BODIED_EXTENSION_TYPE;

	const contentConverterQueryResult = useQuery<ContentConverterQueryTypes>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentConverterQuery,
		{
			variables: getContentConverterVariables({
				content: node.content,
				contentId,
			}),
			fetchPolicy: 'cache-only',
			skip: !isRichTextMacro,
		},
	);

	if (isRichTextMacro) {
		return contentConverterQueryResult.data
			? { value: contentConverterQueryResult.data.contentConverter?.value }
			: { missing: true };
	}

	if (isPlainText) {
		return { value: (bodyContentParam?.value as string) || '' };
	}

	return { value: '' };
}
