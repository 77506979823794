import { useState, useEffect } from 'react';

// This hook is used to determine if we should continue to render the server placeholder
// during hydration on the first render to prevent mismatch. If mismatch occurs, hydration
// can break.
export const useRenderServerPlaceholder = () => {
	const [renderServerPlaceholder, setRenderServerPlaceholder] = useState(
		(window as any)?.__HYDRATABLE__ || Boolean(process.env.REACT_SSR),
	);

	useEffect(() => {
		if (!process.env.REACT_SSR) {
			setRenderServerPlaceholder(false);
		}
	}, []);

	return renderServerPlaceholder;
};
