import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

import type { ADNode } from '@atlaskit/editor-common/validator';

import { extensionToADF } from '@confluence/fabric-extension-lib/entry-points/editor-extensions';

import { serializeExtensionADF } from './serializeExtensionADF';

const getContentId = (adf: ADNode, contentId: string) => {
	const parentId = adf?.attrs?.parameters?.macroParams?._parentId?.value;
	return parentId || contentId;
};

type MacroQueryParams = {
	adf?: ADNode;
	node?: any;
	mode?: string;
	contentId: string;
};

export const getMacroQueryVariables = ({
	adf,
	node,
	mode = 'RENDERER',
	contentId,
}: MacroQueryParams) => {
	const extensionADF = adf || extensionToADF(node);
	const isMacroContentAndBody = StatsigConfigurations.getBoolean(
		'confluence_frontend_macro_adf_frontend_render',
		true,
	);

	return {
		adf: serializeExtensionADF(extensionADF),
		mode: mode.toUpperCase(),
		contentId: isMacroContentAndBody ? getContentId(extensionADF, contentId) : contentId,
		isMacroContentAndBody,
	};
};
