import { useAIExperienceCommonDataContext } from './useAIExperienceCommonData';

export function useEngagementPlatform() {
	const { engagementPlatformApi } = useAIExperienceCommonDataContext();

	if (!engagementPlatformApi) {
		return {
			startMessage: () => {
				return Promise.resolve(false);
			},
			stopMessage: () => {
				return Promise.resolve(false);
			},
		};
	}

	return engagementPlatformApi;
}
