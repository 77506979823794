import gql from 'graphql-tag';

export const RecoverSpaceAdminRoleMutation = gql`
	mutation RecoverSpaceAdminRole($spaceId: Long!) {
		recoverSpaceWithAdminRoleAssignment(input: { spaceId: $spaceId }) {
			errors {
				message
			}
			success
		}
	}
`;
