import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

declare const global: any;

export const isDesktop = (): boolean => {
	return !!global?.window?.desktopNativeBridge;
};

type IPCListener = (...args: any[]) => () => void;
export const establishIPCListeners = (): (() => void) => {
	if (!isDesktop()) {
		return () => {};
	}

	const listenersCleanup: (() => void)[] = [];

	listenersCleanup.push(
		(global.window.desktopNativeBridge.onAnalyticsEvent as IPCListener)(
			(eventData: Record<string, any>) => {
				getAnalyticsWebClient().then((client) => {
					if (eventData.type === 'sendTrackEvent' && eventData.data) {
						client.sendTrackEvent(eventData.data);
					}
				});
			},
		),
	);

	return () => {
		listenersCleanup.forEach((cleanUpFn) => cleanUpFn());
	};
};
