import React, { useCallback, useContext } from 'react';
import type { FC } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import { useMutation } from '@apollo/react-hooks';

import { token } from '@atlaskit/tokens';
import { Box, Flex, Stack } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	SpacePermissionsTabKey,
	decodeChangeKey,
} from '@confluence/space-permissions/entry-points/PermissionsTable';
import { DefaultPermsPublicLinksSettings } from '@confluence/public-links/entry-points/DefaultPermsPublicLinkSettings';
import { ChangeStager } from '@confluence/change-stager';
import {
	ExperienceStart,
	VIEW_DEFAULT_SPACE_PERMISSIONS,
	EDIT_DEFAULT_SPACE_PERMISSIONS,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { withFlags } from '@confluence/flags';
import type { WithFlagsProps } from '@confluence/flags';
import { markErrorAsHandled } from '@confluence/graphql-error-processor';
import { usePagination } from '@confluence/space-permissions/entry-points/Pagination';
import { isStatusCodeError } from '@confluence/error-boundary';

import type { SpacePermissionType } from '../../graphql/__types__/SpacePermissionsDefaults';
import type {
	UpdateDefaultSpacePermissionsVariables,
	UpdateDefaultSpacePermissions as UpdateDefaultSpacePermissionsType,
	UpdateDefaultSpacePermissionsInput,
} from '../../graphql/__types__/UpdateDefaultSpacePermissions';
import { PermissionDisplayType } from '../../graphql/__types__/UpdateDefaultSpacePermissions';
import { UpdateDefaultSpacePermissions } from '../../graphql/UpdateDefaultSpacePermissions.graphql';
import { SpacePermissionsDefaults } from '../../graphql/SpacePermissionsDefaults.graphql';

import { DefaultPermissionsTable } from './DefaultPermissionsTable';

const i18n = defineMessages({
	emptyView: {
		id: 'default-space-permissions.default-tab.table-empty-view',
		defaultMessage: 'No groups currently have access rights to this space.',
		description: 'Message shown when there is no data available in the table.',
	},
	defaultSpacePermsTitle: {
		id: 'default-space-permissions.default-tab.title',
		defaultMessage: 'Default space permissions',
		description: 'title of default space permission table.',
	},
	defaultSpacePermsDescription: {
		id: 'default-space-permissions.default-tab.description',
		defaultMessage:
			'These are the default permissions that will be assigned to groups when someone adds a new space.',
		description: 'description of default space permission.',
	},
	defaultSpacePermsTableErrorFlag: {
		id: 'default-space-permissions.default-tab.permission-table.message',
		defaultMessage: 'No space admins are selected. Spaces need at least one space admin.',
		description:
			'Error message that appears when a user tries to save the table without being a space admin.',
	},
	genericDefaultSpacePermsTableErrorFlag: {
		id: 'default-space-permissions.default-tab.permission-table.generic-message',
		defaultMessage: 'An error has occurred with the table.',
		description:
			'Generic error message that appears when there is an unexpected error with the permissions table.',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DefaultSpacePermsTitleStyle = styled.h2({
	font: token('font.heading.medium'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DefaultSpacePermsDescriptionStyle = styled.p({
	margin: '0px',
});

type UpdatesType = Record<
	string,
	{
		permissionsToAdd: SpacePermissionType[];
		permissionsToRemove: SpacePermissionType[];
	}
>;

export const DefaultSettingsTabComponent: FC<WithFlagsProps> = ({ flags }) => {
	const { formatMessage } = useIntl();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { currentPageCursor } = usePagination();

	const onMutationError = useCallback(
		(mutationError) => {
			// This error indicates that the user tried to remove all admins from table
			const isExpectedError = isStatusCodeError(mutationError, '400');
			if (isExpectedError) {
				experienceTracker.abort({
					name: EDIT_DEFAULT_SPACE_PERMISSIONS,
					reason: 'This error is expected and handled',
				});
				void flags.showErrorFlag({
					id: 'default-settings-permission-table-error-flag',
					title: formatMessage(i18n.defaultSpacePermsTableErrorFlag),
					isAutoDismiss: true,
				});
				markErrorAsHandled(mutationError);
			} else {
				void flags.showErrorFlag({
					id: 'default-settings-permission-table-error-flag',
					title: formatMessage(i18n.genericDefaultSpacePermsTableErrorFlag),
					isAutoDismiss: true,
				});
				experienceTracker.stopOnError({
					name: EDIT_DEFAULT_SPACE_PERMISSIONS,
					error: mutationError,
				});
			}
			createAnalyticsEvent({
				source: 'defaultSpacePermissionsScreen',
				actionSubject: 'flag',
				action: 'shown',
				actionSubjectId: 'defaultSpacePermissionsErrorFlag',
				attributes: {
					isExpectedError,
				},
			}).fire();
		},
		[experienceTracker, flags, formatMessage, createAnalyticsEvent],
	);

	const [updateSpacePermissionDefaults, { loading: isMutationLoading }] = useMutation<
		UpdateDefaultSpacePermissionsType,
		UpdateDefaultSpacePermissionsVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UpdateDefaultSpacePermissions,
		{
			refetchQueries: [
				{ query: SpacePermissionsDefaults, variables: { after: currentPageCursor } },
			],

			onError: (errors) => {
				onMutationError(errors);
			},

			onCompleted: () => {
				experienceTracker.succeed({ name: EDIT_DEFAULT_SPACE_PERMISSIONS });
			},
		},
	);

	const formatChanges = (params: Record<string, unknown>): UpdatesType => {
		return Object.entries(params).reduce((groupChanges: UpdatesType, [key, value]) => {
			const { permission, id } = decodeChangeKey(key);
			if (!(id in groupChanges)) {
				groupChanges[id] = {
					permissionsToAdd: [],
					permissionsToRemove: [],
				};
			}
			if (value === true) {
				groupChanges[id].permissionsToAdd.push(permission);
			} else {
				groupChanges[id].permissionsToRemove.push(permission);
			}
			return groupChanges;
		}, {});
	};

	const onSave = async (params: Record<string, unknown>) => {
		experienceTracker.start({ name: EDIT_DEFAULT_SPACE_PERMISSIONS });
		const updates: UpdatesType = formatChanges(params);
		const input: UpdateDefaultSpacePermissionsInput[] = Object.entries(updates).map(
			([id, { permissionsToAdd, permissionsToRemove }]) => ({
				permissionsToAdd,
				permissionsToRemove,
				subjectKeyInput: {
					permissionDisplayType: PermissionDisplayType.GROUP,
					subjectId: id,
				},
			}),
		);

		await updateSpacePermissionDefaults({ variables: { input } });
	};

	return (
		<Stack space="space.800">
			<Box>
				<ChangeStager
					key={SpacePermissionsTabKey.Groups}
					onSave={onSave}
					disableEditing={false}
					analyticsSource="defaultSpacePermissionsScreen"
					getAnalyticsAttributes={formatChanges}
				>
					<Flex direction="column" gap="space.100">
						<DefaultSpacePermsTitleStyle>
							<FormattedMessage {...i18n.defaultSpacePermsTitle} />
						</DefaultSpacePermsTitleStyle>
						<DefaultSpacePermsDescriptionStyle>
							<FormattedMessage {...i18n.defaultSpacePermsDescription} />
						</DefaultSpacePermsDescriptionStyle>
					</Flex>
					<DefaultPermissionsTable isMutationLoading={isMutationLoading} />
				</ChangeStager>
			</Box>
			<Box>
				<DefaultPermsPublicLinksSettings />
			</Box>
			<ExperienceStart name={VIEW_DEFAULT_SPACE_PERMISSIONS} />
		</Stack>
	);
};

export const DefaultSettingsTabWithFlags = withFlags(DefaultSettingsTabComponent);
