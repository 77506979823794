import React from 'react';

import GlobeIcon from '@atlaskit/icon/core/globe';
import TranslateIcon from '@atlaskit/icon/core/translate';
import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<TranslateIcon
				label=""
				color="currentColor"
				spacing="spacious"
				LEGACY_fallbackIcon={() => (
					<GlobeIcon label="" spacing="spacious" color={token('color.icon', '#44546F')} />
				)}
			/>
		</RainbowBorder>
	);
};
