import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';

import { type SelectionPluginState, selectionPluginKey } from './selection-plugin-key';
import { isPseudoSelectionChanged } from './utils';

export const createSelectionPlugin = () => {
	let isMouseDown = false;
	let selectionChangedDuringDrag = false;

	return new SafePlugin<SelectionPluginState>({
		key: selectionPluginKey,
		state: {
			init: (_, _state) => ({ nonCursorSelectionChangeCount: 0 }),
			apply: (tr, pluginState, _oldEditorState) => {
				const meta = tr.getMeta(selectionPluginKey);
				if (meta?.action === 'incrementNonCursorSelectionChangeCount') {
					return {
						...pluginState,
						nonCursorSelectionChangeCount: pluginState.nonCursorSelectionChangeCount + 1,
					};
				}
				return pluginState;
			},
		},
		appendTransaction: (_transactions, oldState, newState) => {
			const oldSel = oldState.selection;
			const newSel = newState.selection;

			const pseudoSelectionChanged = isPseudoSelectionChanged(oldState, newState);

			// Check for pseudo-selection changes first
			if (pseudoSelectionChanged) {
				// If pseudo-selection changed, always count as meaningful
				// unless we are dragging with mouse
				if (isMouseDown) {
					selectionChangedDuringDrag = true;
					return null;
				}
				return newState.tr.setMeta(selectionPluginKey, {
					action: 'incrementNonCursorSelectionChangeCount',
				});
			}

			// If no pseudo-selection change, check actual selection differences
			if (oldSel.eq(newSel)) {
				return null;
			}

			// If both are empty and no pseudo-change
			if (oldSel.empty && newSel.empty) {
				return null;
			}

			// If the mouse is currently down (e.g., during a drag), record that a change occurred
			// but do not increment the counter yet. The increment will happen when the mouse is released.
			if (isMouseDown) {
				selectionChangedDuringDrag = true;
				return null;
			}

			// If the mouse is not down, this change is considered final and should increment
			// the selection change count immediately.
			return newState.tr.setMeta(selectionPluginKey, {
				action: 'incrementNonCursorSelectionChangeCount',
			});
		},
		props: {
			handleDOMEvents: {
				mousedown: (_view) => {
					// When the mouse goes down, we start "dragging" mode.
					isMouseDown = true;
					selectionChangedDuringDrag = false;
					return false; // Returning false to allow other handlers
				},
				mouseup: (view) => {
					// When the mouse is released, if a selection change happened during dragging,
					// we increment the counter once.
					isMouseDown = false;
					if (selectionChangedDuringDrag) {
						const { state, dispatch } = view;
						dispatch(
							state.tr.setMeta(selectionPluginKey, {
								action: 'incrementNonCursorSelectionChangeCount',
							}),
						);
						selectionChangedDuringDrag = false;
					}
					return false; // Returning false to allow other handlers
				},
			},
		},
	});
};
