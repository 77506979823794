import React, { Fragment } from 'react';
import { type RenderFn } from '@atlassian/forge-ui-types';
import { AkFormFooter, AkForm, useForm } from '../form';
import { AkButton, ButtonSet } from '../button';

function Form(props: Parameters<RenderFn>[0]) {
	const { actions, akFormProps, akSubmitButtonProps, children, htmlFormProps } = useForm(props);

	const { key, onSubmit } = akFormProps;
	const {
		children: submitButtonChildren,
		shouldFitContainer,
		iconBefore,
		iconAfter,
		isLoading: loading,
		isDisabled: disabled,
		onClick,
		type,
	} = akSubmitButtonProps;
	// @ts-ignore Ignoring the fact that "data-testid" is not part of the declared type
	const { className, 'data-testid': dataTestId } = htmlFormProps;

	return (
		<AkForm key={key} onSubmit={onSubmit}>
			{({ formProps }) => {
				const { onSubmit, onKeyDown, ref } = formProps;
				return (
					// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
					<form
						onSubmit={onSubmit}
						onKeyDown={onKeyDown}
						ref={ref}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={className}
						data-testid={dataTestId}
					>
						{children}
						<AkFormFooter align="end">
							<ButtonSet>
								{actions}
								<AkButton
									shouldFitContainer={shouldFitContainer}
									iconBefore={iconBefore}
									iconAfter={iconAfter}
									isLoading={loading}
									isDisabled={disabled}
									onClick={onClick}
									type={type}
									appearance="primary"
								>
									{submitButtonChildren}
								</AkButton>
							</ButtonSet>
						</AkFormFooter>
					</form>
				);
			}}
		</AkForm>
	);
}

export function getInlineDialog({ forgeDoc, dispatch, render }: Parameters<RenderFn>[0]) {
	return {
		children:
			forgeDoc.children[0].type === 'Form' ? (
				<Form forgeDoc={forgeDoc.children[0]} dispatch={dispatch} render={render} />
			) : (
				forgeDoc.children.map(render)
			),
	};
}

export const InlineDialog = (props: Parameters<RenderFn>[0]) => {
	const { children } = getInlineDialog(props);
	return <Fragment>{children}</Fragment>;
};
