import React, { useCallback } from 'react';
import { useIntl } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Stack, xcss } from '@atlaskit/primitives';

import { PrincipalCell } from '../../table/PrincipalCell';
import { RoleSelector } from '../../page/RoleSelector';
import type { PrincipalRoleAssignment, SpaceRole } from '../../model/space-roles-types';

import type { NormalizedCheckboxesType } from './UpdatePrincipalModal';

const principalAndRoleStyle = xcss({
	gap: 'space.300',
});

const roleSelectorStyle = xcss({
	gap: 'space.050',
});

export const RoleSelection = ({
	principalRoleAssignment,
	role,
	setRole,
	setNormalizedCheckboxes,
	spaceRoleOptions,
}: {
	principalRoleAssignment?: PrincipalRoleAssignment;
	role?: SpaceRole | null;
	setRole: React.Dispatch<React.SetStateAction<SpaceRole | null | undefined>>;
	setNormalizedCheckboxes: React.Dispatch<
		React.SetStateAction<Record<string, NormalizedCheckboxesType>>
	>;
	spaceRoleOptions: SpaceRole[];
}) => {
	const { formatMessage } = useIntl();
	const { email, groupCount } = principalRoleAssignment?.principal || {};
	const groupCountDisplay = Boolean(groupCount)
		? formatMessage(
				{
					id: 'space-roles.update-principal.group-count',
					description:
						'A subtext of how many users are in a group that is displayed within a modal that allows for group permission updates',
					defaultMessage: '{groupCount, plural, one {# person} other {# people}}',
				},
				{ groupCount },
			)
		: null;

	const onRoleChange = useCallback(
		(newRole: SpaceRole | null) => {
			setRole(newRole);
			setNormalizedCheckboxes((prevState) => {
				const updatedCheckboxes = { ...prevState };
				Object.keys(updatedCheckboxes).forEach((groupName) => {
					updatedCheckboxes[groupName].group.checkboxes = updatedCheckboxes[
						groupName
					].group.checkboxes.map((checkbox) => {
						return {
							...checkbox,
							isChecked: Boolean(newRole?.permissions.includes(checkbox.id)),
						};
					});
				});
				return updatedCheckboxes;
			});
		},
		[setNormalizedCheckboxes, setRole],
	);

	return (
		<Stack xcss={principalAndRoleStyle}>
			<PrincipalCell
				name={principalRoleAssignment?.principal?.displayName ?? ''}
				avatarSrc={principalRoleAssignment?.principal.avatarUrl ?? ''}
				Icon={principalRoleAssignment?.principal.icon}
				hasProfileBorder={false}
				subText={email || groupCountDisplay}
			/>
			<Stack xcss={roleSelectorStyle}>
				<Heading size="small">Role</Heading>
				<RoleSelector
					value={role?.id || null}
					onChange={(newRole) => onRoleChange(newRole)}
					isTableSelect
					spaceRoleOptions={spaceRoleOptions}
				/>
			</Stack>
		</Stack>
	);
};
