import type { DecorationSet } from '@atlaskit/editor-prosemirror/view';
import type { RateLimiter } from '@atlassian/editor-ai-common/api/rate-limiter';

import type { ParagraphChunk } from '../../utils/diff-match-patch/utils';
import type { DocumentChecker } from '../../utils/document-checker';

import type { Recommendation } from './api';

export type ProactiveAIBlock = ParagraphChunk & {
	nodeTypeName: string;
	invalidated: boolean;
	invalidatedForDocChecker?: boolean;
	recommendations?: Recommendation[];
};

export type SettingDisplayType = {
	displayAllSuggestions: boolean;
	[key: string]: boolean;
};

export interface AIProactivePluginState {
	/**
	 * This stores the current product key in editor state for quick access.
	 */
	product: string;

	/**
	 * This flag should only be set once on initialization. It should never be changed/updated as it represents the state
	 * of the toggle when the plugin was initialized.
	 *
	 * This identifies the initial toggle state of isProactiveContextPanelOpen.
	 */
	initialToggleState: boolean;

	/**
	 * This toggle controls whether the proactive ai plugin is actively watching for changes on the document. When changes occur
	 * the content is sent to an AI service for analysis and recommendations are returned.
	 */
	isProactiveEnabled: boolean;

	/**
	 * This toggle controls whether the context panel for proactive ai should be open and displaying recommendations to the user.
	 */
	isProactiveContextPanelOpen: boolean;

	/**
	 * This flag identifies whether or not the side context panel can be opened. This will most likely be used when determining
	 * the disabled state of the suggested edits button.
	 */
	canProactiveContextPanelOpen: boolean;

	/**
	 * This is the id of the recommendation which is actively being selected. Only 1 recommendation can be selected at a
	 * time. The block containing the recommendation will also be highlighted. If a block is selected and it contains many recommendations
	 * then only the 1st recommendation is selected.
	 *
	 * A selected recommendation will automatically display an inline preview.
	 */
	selectedRecommendationId?: string;

	/**
	 * This is the id of the recommendation which is actively being hovered. Only 1 recommendation can be hovered at a
	 * time. The block containing the recommendation will also be highlighted. If a block is hovered and it contains many recommendations
	 * then only the 1st recommendation is selected.
	 *
	 * A hovered recommendation will only highlight the block inline.
	 */
	hoveredRecommendationId?: string;

	/**
	 * If this flag is true then the proactive blue underlines and highlights will be display for all recommendations always
	 * regardless of the state of the context panel. If this flag is false then the underlines and highlights will only display
	 * when the context panel is open.
	 */
	alwaysDisplayProactiveInlineDecorations?: boolean;

	/**
	 * This flag controls whether the context panel should be opened when the blue inline decorations are selected.
	 * This will only work when the blue inline decorations are displayed.
	 */
	alwaysOpenProactiveContextPanelOnInlineSelection?: boolean;

	/**
	 * This toggle controls whether or not invalidated blocks which have been sent to the backend are marked as validated
	 * when the backend responds with no recommendations for the blocks. By default blocks missing from a response will be
	 * left marked as invalid, and will be sent back on the next request.
	 */
	validateBlocksOnMissingRecommendations?: boolean;

	/**
	 * This toggle controls whether other recommendations can be hover highlighted while an existing recommendation is already selected.
	 */
	allowInlineHoverHighlightWhileRecommendationSelected?: boolean;

	/**
	 * This toggle controls whether the document scanner has been run when the editor initialized and replaced the doc.
	 */
	hasRunFullDocumentScanOnReplaceDocument?: boolean;

	/**
	 * This is the collection of all decoration applied to the document for this plugin.
	 */
	decorationSet: DecorationSet;

	/**
	 * This is the URL which will be used when requesting recommendation from the backend
	 */
	proactiveAIApiUrl: string;

	/**
	 * Blocks are small part of the document that will be
	 *  monitored if affected by transaction or not.
	 * If affected by transaction then new block (or blocks) will be
	 *  created based on transaction.
	 * New blocks will have updated positions, text, and diffObjects
	 *  will be removed from it as new block will be checked for
	 *  S+G after sometime.
	 * Once new blocks are checked for S+G, block's diffObjects will
	 *  be stored in it.
	 * Then from all the diffObjects stored in blocks, we will
	 *  update allDiffObjects and decorationSet.
	 */
	proactiveAIBlocks: ProactiveAIBlock[];

	/**
	 * Indicates that AI responses are being loaded - this is slightly different from showNewRecommendationsNotification
	 * as there are different conditions as to when the notification is shown.
	 */
	isLoading: boolean;

	/**
	 * This is a collection containing all recommendation IDs since all ids are globally unique. This is calculated
	 * whenever the plugin state is updated and can be used as a shortcut for identifying id recommendations exist or not
	 */
	availableRecommendationIds: Set<string>;

	/**
	 * This is to indicate that new un-opened recommendations are ready for viewing.
	 */
	showNewRecommendationsNotification?: boolean;

	// dismissedWords: Set<string>;

	/**
	 * Tracking - This is the total amount of times the proactive context panel has been toggled open/closed. If we combine this
	 * count with the initialToggleState, we're able to figure out whether they opened or closed the panel.
	 */
	toggleCount: number;

	/**
	 * Tracking - This is the total amount of times a recommendation was inserted into the document.
	 */
	insertionCount: number;

	/**
	 * Tracking - This is the total amount of times a recommendation was dismissed.
	 */
	dismissedCount: number;

	/**
	 * This is unique id which should be attached to all unified AI analytic events. This is used to group analytic events
	 * based on interaction events. In the case of proactive an interaction starts when you open the context panel and ends
	 * when the panel is closed.
	 */
	analyticsAIInteractionId?: string;

	/**
	 * This is a reference to the document scanner instance which is used for handling the scanning of the document blocks
	 */
	documentChecker?: DocumentChecker;

	/**
	 * A count of how many times the doc has changed up to an arbitrary amount
	 * e.g. up to 5 doc changes, then we stop updating this value in the state
	 */
	docChangeCountUntilLimit: number;

	/**
	 * A flag to indicate if the first initiated event has been sent when the plugin is on by default
	 * if it's true - we don't send it again
	 * if it's false - we send the analytics event
	 */
	isFirstInitiatedEventSent: boolean;

	/**
	 * Context panel settings
	 */
	settings: SettingDisplayType;

	/*
	 * This is an instance of the rate limiter which will be used for controlling when request can be sent.
	 */
	rateLimiter?: RateLimiter;

	/**
	 * Filtered recommendations
	 */
	filteredRecommendations?: Recommendation[];

	hasNoMoreSuggestions: boolean;
}

export enum ACTIONS {
	/**
	 * This general action can be sued for updating any state of this plugin
	 */
	UPDATE_PLUGIN_STATE,

	/**
	 * This toggle controls the functional state of proactiviness. ie whether this plugin is watching and requesting suggestions
	 */
	TOGGLE_PROACTIVE_ENABLED,

	/**
	 * This controls whether the context panel is open or not
	 */
	TOGGLE_PROACTIVE_CONTEXT_PANEL,

	/*
	 * This action is used to select a recommendation from the context panel
	 */
	SELECT_PROACTIVE_RECOMMENDATION,

	API_ERROR,

	/*
	 * This action is used to toggle the context panel settings
	 */
	TOGGLE_DISPLAY_CONTEXT_PANEL_SETTINGS,

	/**
	 * This action is used to change whether Proactive AI is loading requests or not
	 */
	UPDATE_IS_LOADING_STATE,
}

export type ProactiveAction =
	| {
			type: ACTIONS.UPDATE_PLUGIN_STATE;
			data: Partial<AIProactivePluginState>;
	  }
	| {
			type: ACTIONS.TOGGLE_PROACTIVE_ENABLED;
	  }
	| {
			type: ACTIONS.TOGGLE_PROACTIVE_CONTEXT_PANEL;
	  }
	| {
			type: ACTIONS.SELECT_PROACTIVE_RECOMMENDATION;
			data: Partial<AIProactivePluginState>;
	  }
	| {
			type: ACTIONS.API_ERROR;
	  }
	| {
			type: ACTIONS.TOGGLE_DISPLAY_CONTEXT_PANEL_SETTINGS;
			data: Partial<AIProactivePluginState>;
	  }
	| {
			type: ACTIONS.UPDATE_IS_LOADING_STATE;
			data: boolean;
	  };
// | {
// 		type: ACTIONS.DISABLE_NEED_SPELLING_AND_GRAMMAR;
// 		data: {
// 			skippedChunkIds: string[];
// 		};
//   }
// | {
// 		type: ACTIONS.UPDATE_CHUNK_METADATA;
// 		data: {
// 			chunkId: string;
// 			// metadata: ChunkMetadata;
// 		};
//   }
// | {
// 		type: ACTIONS.START_PROACTIVE_AI;
//   };
