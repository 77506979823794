// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { N40A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { Breakpoint } from '../../hooks/useElementBreakpoints';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const floatingContainerStyles = css({
	display: 'flex',
	flexDirection: 'column',
	padding: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const floatingContainerBottomBorderStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> :not(:last-child)': {
		borderBottom: `1px solid ${token('color.border', N40A)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const columnLayout = css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: token('space.050', '4px'),
});

export const footerTextStyles = (breakpoint: Breakpoint) =>
	css({
		color: token('color.text.subtlest', '#626F86'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		display: breakpoint <= Breakpoint.Small ? 'none' : undefined,
	});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const footerSectionStyles = css({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	padding: token('space.150', '12px'),
	gap: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const footerSectionSpacerStyles = css({
	flexGrow: 1,
});
