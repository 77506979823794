import { preloadContentPrerequisites } from '@confluence/content-prerequisites/entry-points/preloadContentPrerequisites';
import type { RouteMatch } from '@confluence/route';

export const preloadContentHistoryRoute = async (match: RouteMatch) => {
	const tasks: Promise<any>[] = [];

	const { spaceKey, contentId } = match.params;

	// Since this query is not called in ContentPrerequisites when contentId is
	// missing, we should follow the same reasoning and skip preload when content is
	// missing.
	if (contentId) {
		tasks.push(preloadContentPrerequisites(contentId, spaceKey));
	}

	return Promise.all(tasks);
};
