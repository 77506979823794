import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button, { LinkButton } from '@atlaskit/button/new';

import { ADMIN_DEFAULT_SPACE_PERMISSIONS } from '@confluence/named-routes';
import { OnboardingModalBase } from '@confluence/space-roles';

const i18n = defineMessages({
	closeButtonText: {
		id: 'default-space-permissions.onboarding-modal.close-button.non-final',
		defaultMessage: 'Close',
		description: 'Text for the close button on an onboarding modal',
	},
	okButtonText: {
		id: 'default-space-permissions.onboarding-modal.ok-button.non-final',
		defaultMessage: 'OK',
		description: 'Text for the OK button on an onboarding modal',
	},
	manageDefaultsButtonText: {
		id: 'default-space-permissions.onboarding-modal.manage-defaults-button.non-final',
		defaultMessage: 'Manage defaults',
		description: 'Text for the manage defaults button on an onboarding modal',
	},
	bulletOneHeader: {
		id: 'default-space-permissions.onboarding-modal.bullet-one.header.non-final',
		defaultMessage: 'Save time and brain power',
		description:
			'Header for a bullet point in the onboarding modal which describes that the feature saves time and brain power',
	},
	bulletOneDescription: {
		id: 'default-space-permissions.onboarding-modal.bullet-one.description.non-final',
		defaultMessage:
			'No more wrestling with a complicated table of permissions. Simply choose a role from one of our preset role types and you‘re done!',
		description:
			'Description for a bullet point in the onboarding modal which describes that the feature saves time and brain power',
	},
	bulletOneLinkText: {
		id: 'default-space-permissions.onboarding-modal.bullet-one.link-text.non-final',
		defaultMessage: 'Learn more about Confluence roles',
		description: 'Text for the link in the first bullet point of the onboarding modal',
	},
	bulletTwoHeader: {
		id: 'default-space-permissions.onboarding-modal.bullet-two.header.non-final',
		defaultMessage: 'Roles simplify your defaults',
		description:
			'Header for a bullet point in the onboarding modal which describes that roles simplify your defaults',
	},
	bulletTwoDescription: {
		id: 'default-space-permissions.onboarding-modal.bullet-two.description.non-final',
		defaultMessage:
			'Save even more time and energy by switching to roles in your default space permissions for all new spaces created.',
		description:
			'Description for a bullet point in the onboarding modal which describes that roles simplify your defaults',
	},
	bulletTwoLinkText: {
		id: 'default-space-permissions.onboarding-modal.bullet-two.link-text.non-final',
		defaultMessage: 'Learn more about defaults',
		description: 'Text for the link in the second bullet point of the onboarding modal',
	},
	bulletThreeHeader: {
		id: 'default-space-permissions.onboarding-modal.bullet-three.header.non-final',
		defaultMessage: 'Easily manage general access with roles',
		description:
			'Header for a bullet point in the onboarding modal which describes that the feature allows users to easily manage general access with roles',
	},
	bulletThreeDescription: {
		id: 'default-space-permissions.onboarding-modal.bullet-three.description.non-final',
		defaultMessage:
			'Assign roles broadly to different classes of users like “all Confluence users” or “all Confluence admins” regardless of group membership.',
		description:
			'Description for a bullet point in the onboarding modal which describes that the feature allows users to assign roles broadly to different classes of users',
	},
	bulletThreeLinkText: {
		id: 'default-space-permissions.onboarding-modal.bullet-three.link-text.non-final',
		defaultMessage: 'Learn more about general access by user type',
		description: 'Text for the link in the third bullet point of the onboarding modal',
	},
});

//During EAP we cannot make SAC links for this specific purpose; therefore we will be using public links created by design to allow EAP users to access help documentation.
const LEARN_MORE_ROLES =
	'https://confluence-permissions-help.atlassian.net/wiki/external/M2JiZjYzMTAwNDZjNDNiNzg4YmY0YWEwY2FkOTY0NWM';
const LEARN_MORE_SET_DEFAULTS =
	'https://confluence-permissions-help.atlassian.net/wiki/external/MTQ4ZDk4OTI3MmYyNGI5MzljYjFkMTE0OGZiMDA1ZDk';
const LEARN_MORE_GENERAL_ACCESS =
	'https://confluence-permissions-help.atlassian.net/wiki/external/OWRhZGEzOGI2YTQ3NDUwMDgwNGNmOTg1MzBkYTZmM2M';

export const OnboardingModal = ({
	isDefaultsTab,
	onClose,
}: {
	isDefaultsTab: boolean;
	onClose: () => void;
}) => {
	const { formatMessage } = useIntl();

	return (
		<OnboardingModalBase
			onClose={onClose}
			bullets={[
				{
					header: formatMessage(i18n.bulletOneHeader),
					description: formatMessage(i18n.bulletOneDescription),
					linkMessage: formatMessage(i18n.bulletOneLinkText),
					linkUrl: LEARN_MORE_ROLES,
				},
				{
					header: formatMessage(i18n.bulletTwoHeader),
					description: formatMessage(i18n.bulletTwoDescription),
					linkMessage: formatMessage(i18n.bulletTwoLinkText),
					linkUrl: LEARN_MORE_SET_DEFAULTS,
				},
				{
					header: formatMessage(i18n.bulletThreeHeader),
					description: formatMessage(i18n.bulletThreeDescription),
					linkMessage: formatMessage(i18n.bulletThreeLinkText),
					linkUrl: LEARN_MORE_GENERAL_ACCESS,
				},
			]}
			actions={
				isDefaultsTab
					? [
							<Button appearance="primary" onClick={onClose} key={i18n.okButtonText.id}>
								{formatMessage(i18n.okButtonText)}
							</Button>,
						]
					: [
							<Button appearance="subtle" key={i18n.closeButtonText.id} onClick={onClose}>
								{formatMessage(i18n.closeButtonText)}
							</Button>,
							<LinkButton
								href={ADMIN_DEFAULT_SPACE_PERMISSIONS.toUrl()}
								key={i18n.closeButtonText.id}
								appearance="primary"
							>
								{formatMessage(i18n.manageDefaultsButtonText)}
							</LinkButton>,
						]
			}
			analyticsSource="globalSpacePermissions"
		/>
	);
};
