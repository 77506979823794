import React from 'react';

import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/close';
import { Flex, xcss } from '@atlaskit/primitives';

import messages from './messages';

const inPromptHeaderStyles = xcss({
	display: 'inline-flex',
	// make room for the focus ring
	marginRight: 'space.050',
});

type Props = {
	onClick?: () => void;
};

const toolTipProps: React.ComponentProps<typeof IconButton>['tooltip'] = { position: 'top' };
export const testId = 'close-preview-icon-button';

export const ClosePreviewIconButton = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Flex xcss={[inPromptHeaderStyles]}>
			<IconButton
				appearance="subtle"
				icon={CrossIcon}
				label={formatMessage(messages.closePreviewLabel)}
				spacing="compact"
				onClick={onClick}
				testId={testId}
				tooltip={toolTipProps}
				isTooltipDisabled={false}
			/>
		</Flex>
	);
};
