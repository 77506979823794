export const stripMatchingOuterQuotes = (markdown: string): string => {
	markdown = markdown.trim(); // Trim whitespace to ensure quotes at start/end are detected
	const firstChar = markdown.charAt(0);
	const lastChar = markdown.charAt(markdown.length - 1);

	// Check if the first and last characters are matching quotes (either ' or ")
	if ((firstChar === '"' && lastChar === '"') || (firstChar === "'" && lastChar === "'")) {
		// Remove the first and last character
		return markdown.substring(1, markdown.length - 1);
	}

	// Return the original string if no matching outer quotes are found
	return markdown;
};
