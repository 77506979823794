import { useEffect } from 'react';

import { type EditorView } from '@atlaskit/editor-prosemirror/view';

// Singleton variable that we use to globally track whether
// a given editor was the last active ('focused') editor (to narrow
// down editor that should be reacting to Rovo pub-sub messages)
let lastActiveEditorView: EditorView | null = null;

export const updateLastActiveEditorView = (editorView: EditorView): void => {
	lastActiveEditorView = editorView;
};

export const isLastActiveEditorView = (editorView: EditorView): boolean =>
	editorView === lastActiveEditorView;

export const useTrackLastActiveEditorView = (editorView: EditorView) => {
	useEffect(() => {
		const handleFocus = () => updateLastActiveEditorView(editorView);
		const editorDom = editorView.dom;
		// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
		editorDom.addEventListener('focus', handleFocus);
		return () => {
			// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
			editorDom.removeEventListener('focus', handleFocus);
		};
	}, [editorView]);
};
