import uuid from 'uuid';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';

import type { EditorActions } from '@atlaskit/editor-core';
import type {
	InlineCommentCreateComponentProps as EditorInlineCommentCreateComponentProps,
	InlineCommentViewComponentProps as EditorInlineCommentViewComponentProps,
} from '@atlaskit/editor-plugins/annotation';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import type {
	InlineCommentSelectionComponentProps,
	InlineCommentViewComponentProps,
	InlineCommentHoverComponentProps,
	AnnotationByMatches,
} from '@atlaskit/editor-common/types';
import type { AddMarkStep } from '@atlaskit/editor-prosemirror/transform';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';

import { CommentCreationLocation } from '@confluence/inline-comments-queries';
import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';

// Copying from @confluence/annotation-provider-inline-comments/src/renderer/ViewComponent.tsx for circular deps
type ViewComponentProps = {
	pageId: string;
	updateDocument?: (doc: JSONDocNode) => void;
};

// Copying from @confluence/annotation-provider-inline-comments/src/renderer/SelectionComponent.tsx for circular deps
type SelectionComponentProps = {
	pageId: string;
	updateDocument?: (doc: JSONDocNode) => void;
	isArchived?: boolean;
};

// Copying from @confluence/annotation-provider-inline-comments/src/renderer/HoverComponent.tsx for circular deps
type HoverComponentProps = {
	pageId: string;
	updateDocument?: (doc: JSONDocNode) => void;
	isArchived?: boolean;
};

// Copying from @confluence/annotation-provider-inline-comments/src/renderer/SelectionComponent.tsx for circular deps
type SelectionOptions = AnnotationByMatches & {
	createdFrom: CommentCreationLocation;
	step?: AddMarkStep;
	inlineNodeTypes?: string[];
	targetNodeType?: string;
};

// Copying from @confluence/annotation-provider-inline-comments/src/editor/CreateComponent.tsx for circular deps
type EditorCreateComponentProps = {
	pageId: string;

	/**
	 * Method to close the comments panel if it is open while creating a new comment in the editor
	 */
	closeObjectSidebar?: () => void;
};

// Copying from @confluence/annotation-provider-inline-comments/src/editor/ViewComponent.tsx for circular deps
type EditorViewComponentProps = {
	pageId: string;
	editorActions?: EditorActions;
};

export type AnnotationsContextType = {
	selectionProps: (InlineCommentSelectionComponentProps & SelectionComponentProps) | null;
	hoverProps: (InlineCommentHoverComponentProps & HoverComponentProps) | null;
	viewProps: (InlineCommentViewComponentProps & ViewComponentProps) | null;
	editorCreateProps: (EditorInlineCommentCreateComponentProps & EditorCreateComponentProps) | null;
	editorViewProps: (EditorInlineCommentViewComponentProps & EditorViewComponentProps) | null;
	showCreateComponent: boolean;
	showHighlightActions: boolean;
	selectionOptions: any;
	stepGenerationError: boolean;
};

export type AnnotationDispatchContextType = {
	setSelectionProps: (
		selectionProps: (InlineCommentSelectionComponentProps & SelectionComponentProps) | null,
	) => void;
	setHoverProps: (
		hoverProps: (InlineCommentHoverComponentProps & HoverComponentProps) | null,
	) => void;
	setViewProps: (viewProps: (InlineCommentViewComponentProps & ViewComponentProps) | null) => void;
	setEditorCreateProps: (
		createProps: (EditorInlineCommentCreateComponentProps & EditorCreateComponentProps) | null,
	) => void;
	setEditorViewProps: (
		viewProps: (EditorInlineCommentViewComponentProps & EditorViewComponentProps) | null,
	) => void;
	setShowCreateComponent: (showCreateComponent: boolean) => void;
	setShowHighlightActions: (showHighlightActions: boolean) => void;
	setSelectionOptions: (selectionOptions: any) => void;
	setStepGenerationError: (stepGenerationError: boolean) => void;
	setSelectionOptionsForCreateEditor: () => void;
	handleSaveSuccess: (annotationId: string) => void;
	handleClose: () => void;
};

export const AnnotationsContext = createContext<AnnotationsContextType>({
	selectionProps: null,
	hoverProps: null,
	viewProps: null,
	editorCreateProps: null,
	editorViewProps: null,
	showCreateComponent: false,
	showHighlightActions: false,
	selectionOptions: undefined,
	stepGenerationError: false,
});
AnnotationsContext.displayName = 'AnnotationsContext';

export const AnnotationsDispatchContext = createContext<AnnotationDispatchContextType>({
	setSelectionProps: () => {},
	setHoverProps: () => {},
	setViewProps: () => {},
	setEditorCreateProps: () => {},
	setEditorViewProps: () => {},
	setShowCreateComponent: () => {},
	setShowHighlightActions: () => {},
	setSelectionOptions: () => {},
	setStepGenerationError: () => {},
	setSelectionOptionsForCreateEditor: () => {},
	handleSaveSuccess: () => {},
	handleClose: () => {},
});
AnnotationsDispatchContext.displayName = 'AnnotationDispatchContext';

type AnnotationProviderProps = {
	children: JSX.Element;
};

export const AnnotationsProvider: FC<AnnotationProviderProps> = ({ children }) => {
	const [selectionProps, setSelectionProps] = useState<any>(null);
	const [hoverProps, setHoverProps] = useState<any>(null);
	const [viewProps, setViewProps] = useState<any>(null);
	const [editorCreateProps, setEditorCreateProps] = useState<any>(null);
	const [editorViewProps, setEditorViewProps] = useState<any>(null);
	const [showCreateComponent, setShowCreateComponent] = useState<boolean>(false);
	const [showHighlightActions, setShowHighlightActions] = useState(false);
	const [selectionOptions, setSelectionOptions] = useState<SelectionOptions | undefined>();
	const [stepGenerationError, setStepGenerationError] = useState(false);

	const handleClose = useCallback(() => {
		setShowCreateComponent(false);
		setStepGenerationError(false);
		setSelectionOptions(undefined);

		selectionProps && selectionProps.onClose();
	}, [setShowCreateComponent, setStepGenerationError, setSelectionOptions, selectionProps]);

	/*
	 * NOTE: This function will not be calling setShowCreateComponent because it's
	 *       consumers will not be refreshing themselves with new selectionOptions.
	 *       It will now be the responsibility of the consumers of this function to call
	 *       setShowCreateComponent(true). See SelectionComponent.tsx (~line 132)
	 */
	const setSelectionOptionsForCreateEditor = useCallback(() => {
		setShowCreateComponent(false); // Hide an existing create component if it exists

		const annotationId = uuid();
		const result = selectionProps.applyDraftMode({
			annotationId,
			keepNativeSelection: false,
		});

		if (result) {
			setSelectionOptions({
				step: result.step as AddMarkStep,
				inlineNodeTypes: result.inlineNodeTypes,
				originalSelection: result.originalSelection,
				numMatches: result.numMatches,
				matchIndex: result.matchIndex,
				createdFrom: CommentCreationLocation.RENDERER,
				pos: result.pos,
				targetNodeType: result.targetNodeType,
			});

			// Tell the renderer to close any currently-open comments
			getRendererAnnotationEventEmitter().emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);

			setShowHighlightActions(false);
		} else {
			setStepGenerationError(true);
		}
	}, [selectionProps, setShowCreateComponent, setShowHighlightActions, setStepGenerationError]);

	const handleSaveSuccess = useCallback(
		(annotationId: string) => {
			setStepGenerationError(false);
			setSelectionOptions(undefined);

			// Since we generated the uuid ourselves, we need to replace the document generated with
			// the annotationId that we received from the backend so things line up correctly
			const result = selectionProps.onCreate(annotationId);

			if (result) {
				const emitter = getRendererAnnotationEventEmitter();

				selectionProps.removeDraftMode();
				selectionProps.updateDocument && selectionProps.updateDocument(result.doc);

				setTimeout(() => {
					// Tell the renderer to set the new comment to active
					if (
						emitter.emit(AnnotationUpdateEvent.ON_ANNOTATION_CLICK, {
							annotationIds: [annotationId],
							// eventTarget: wrapperDOM,
							eventTarget: document.getElementById(annotationId),
						})
					) {
						emitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_FOCUS, {
							annotationId,
						});
					}

					handleClose();
					// We need the timeout to give the document the chance to make the marks properly
				}, 250);
			}
		},
		[setStepGenerationError, setSelectionOptions, selectionProps, handleClose],
	);

	const providerValue = useMemo(
		() => ({
			selectionProps,
			hoverProps,
			viewProps,
			editorCreateProps,
			editorViewProps,
			showCreateComponent,
			showHighlightActions,
			selectionOptions,
			stepGenerationError,
		}),
		[
			selectionProps,
			hoverProps,
			viewProps,
			editorCreateProps,
			editorViewProps,
			showCreateComponent,
			showHighlightActions,
			selectionOptions,
			stepGenerationError,
		],
	);

	const dispatchProviderValue = useMemo(
		() => ({
			setSelectionProps,
			setHoverProps,
			setViewProps,
			setEditorCreateProps,
			setEditorViewProps,
			setShowCreateComponent,
			setShowHighlightActions,
			setSelectionOptions,
			setStepGenerationError,
			setSelectionOptionsForCreateEditor,
			handleSaveSuccess,
			handleClose,
		}),
		[
			setSelectionProps,
			setHoverProps,
			setViewProps,
			setEditorCreateProps,
			setEditorViewProps,
			setShowCreateComponent,
			setShowHighlightActions,
			setSelectionOptions,
			setStepGenerationError,
			setSelectionOptionsForCreateEditor,
			handleSaveSuccess,
			handleClose,
		],
	);

	return (
		<AnnotationsContext.Provider value={providerValue}>
			<AnnotationsDispatchContext.Provider value={dispatchProviderValue}>
				{children}
			</AnnotationsDispatchContext.Provider>
		</AnnotationsContext.Provider>
	);
};

export const useAnnotations = () => {
	return useContext(AnnotationsContext);
};

export const useAnnotationsDispatch = () => {
	return useContext(AnnotationsDispatchContext);
};
