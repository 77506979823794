import React from 'react';
import memoize from 'memoize-one';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import { SessionData, SessionDataContext } from '@confluence/session-data';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiWrapper = styled.span({
	justifyContent: 'center',
	overflow: 'hidden',
	display: 'inline-block',
	margin: 'auto',
	minWidth: '16px',
	font: token('font.body.large'),
});

/*
  This provides the renderers object for SmartCardProvider which includes emoji
*/
export const getSmartCardRenderers = memoize(() => ({
	renderers: {
		emoji: (emojiId, _parentComponent = 'SmartCard', contentType = 'page') => (
			<SessionData>
				{(sessionData) => (
					<SessionDataContext.Provider value={sessionData}>
						<LazyEmojiComponentLoader
							emoji={emojiId}
							height={16}
							context="smartCard"
							wrapper={EmojiWrapper}
							contentType={contentType}
						/>
					</SessionDataContext.Provider>
				)}
			</SessionData>
		),
	},
}));
