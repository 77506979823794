import React, { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import DynamicTable from '@atlaskit/dynamic-table';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';

import type {
	SpacePermission,
	SpaceRole,
} from '@confluence/space-roles/entry-points/space-role-types';
import { useResponseHandler } from '@confluence/space-roles/entry-points/graphql';
import { DEFAULT_RBAC_VIEW_EXPERIENCE } from '@confluence/experience-tracker';

import { useDefaultRolesTableRows } from './useDefaultRolesTableRows';

const i18n = defineMessages({
	accessColumnHeader: {
		id: 'default-space-permissions.table.access-column-header',
		defaultMessage: 'People with default access',
		description:
			'Column header for groups in a table that appears for site admins managing default space permissions',
	},
	typeColumnHeader: {
		id: 'default-space-permissions.table.type-column-header',
		defaultMessage: 'User class',
		description:
			'Column header for types of groups in a table that appears for site admins managing default space permissions',
	},
	roleColumnHeader: {
		id: 'default-space-permissions.table.role-column-header',
		defaultMessage: 'Role',
		description:
			'Column header for the role of groups in a table that appears for site admins managing default space permissions',
	},
	loadMoreButton: {
		id: 'default-space-permissions.table.load-more-button',
		defaultMessage: 'Load more',
		description: 'Button to load more rows in a table',
	},
	loadMoreError: {
		id: 'default-space-permissions.table.load-more-error',
		defaultMessage: 'Error loading more rows',
		description:
			'Error message when loading more rows in a table fails after the user clicked a button to do so',
	},
});

const loadMoreButtonStyle = xcss({
	display: 'flex',
	justifyContent: 'center',
});

export const DefaultRolesTable = ({
	allPermissions,
	roleOptions,
	isRolesOrPermissionsLoading,
}: {
	allPermissions: SpacePermission[];
	roleOptions: SpaceRole[];
	isRolesOrPermissionsLoading: boolean;
}) => {
	const { formatMessage } = useIntl();
	const head = useMemo(
		() => ({
			cells: [
				{
					key: 'principal',
					content: formatMessage(i18n.accessColumnHeader),
					isSortable: false,
					width: 40,
				},
				{
					key: 'type',
					content: formatMessage(i18n.typeColumnHeader),
					shouldTruncate: true,
					isSortable: false,
					width: 15,
				},
				{
					key: 'role',
					content: formatMessage(i18n.roleColumnHeader),
					shouldTruncate: true,
					isSortable: false,
					width: 35,
				},
				{
					key: 'option',
					content: '',
					shouldTruncate: true,
				},
			],
		}),
		[formatMessage],
	);
	const {
		rows,
		isLoading: isDefaultRowsLoading,
		fetchMoreRows,
		hasNextPage,
	} = useDefaultRolesTableRows({
		allPermissions,
		roleOptions,
	});

	const { handleResponse: handleQueryResponse } = useResponseHandler({
		experience: DEFAULT_RBAC_VIEW_EXPERIENCE,
		errorMessage: formatMessage(i18n.loadMoreError),
	});

	const loadMore = useCallback(async () => {
		try {
			await fetchMoreRows();
			handleQueryResponse({});
		} catch (error) {
			handleQueryResponse({ error });
		}
	}, [fetchMoreRows, handleQueryResponse]);

	return (
		<Stack>
			<DynamicTable
				head={head}
				rows={rows}
				testId="space-roles-table"
				isLoading={isDefaultRowsLoading || isRolesOrPermissionsLoading}
			/>
			{hasNextPage && (
				<Box xcss={loadMoreButtonStyle}>
					<Button appearance="primary" onClick={loadMore}>
						{formatMessage(i18n.loadMoreButton)}
					</Button>
				</Box>
			)}
		</Stack>
	);
};
