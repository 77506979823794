import { parse } from 'url';

import { getSingleParam } from '@confluence/route-manager-utils';

import { getMessages } from './messages';
import type { LocalizationMessages } from './types';

function getDefault(obj: any): any {
	return obj.default && typeof obj.default !== 'string' ? obj.default : obj;
}

export const DEFAULT_LOCALE = 'en-US';

type TranslationCache = {
	locale?: string;
	messages?: LocalizationMessages;
};

const translationCache: TranslationCache = {};

function isTranslationCacheSet(cache: TranslationCache): cache is Required<TranslationCache> {
	return Boolean(cache.locale && cache.messages);
}

export function getTranslation(): Required<TranslationCache> {
	if (!isTranslationCacheSet(translationCache)) {
		throw new Error('loadTranslation needs to be called before getTranslation');
	}
	return translationCache;
}

export function loadTranslation(
	loader: (locale: string) => Array<Promise<LocalizationMessages>> = () => [],
	forcedLocale?: string,
) {
	let localeFromQueryParam: string | undefined;
	try {
		localeFromQueryParam = getSingleParam(parse(location.search, true).query, 'locale');
	} catch (error) {
		// Silently handle URI malformed error
	}

	let locale =
		forcedLocale ??
		localeFromQueryParam ??
		window?.__SSR_LOCALE__ ??
		window?.__INITIAL_STATE__?.i18n?.locale ??
		DEFAULT_LOCALE;

	if (locale.includes('_')) {
		// Note: the locale from session data is not standard aa-AA format. It is aa_AA
		locale = locale.replace('_', '-');
	}

	return Promise.all([...getMessages(locale), ...loader(locale)]).then(([...translations]) => {
		// Cache the result in module level.
		translationCache.locale = locale;
		translationCache.messages = translations.reduce<LocalizationMessages>(
			(allTranslations, translation) => {
				// import() with json behaves different in test and runtime.
				translation = getDefault(translation);

				// We've now merged the translations a bit more careful, rather
				// than just blindly use a spread operator because a problem occurred
				// while blindly merging and sending the values to the legacy editor.
				// @see https://ops.internal.atlassian.com/jira/browse/HOT-84998
				for (const key in translation) {
					if (typeof (translation as LocalizationMessages)[key] === 'string') {
						allTranslations[key] = (translation as LocalizationMessages)[key];
					}
				}

				return allTranslations;
			},
			{},
		);
	});
}
