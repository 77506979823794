/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { useIntl } from 'react-intl-next';

import VisuallyHidden from '@atlaskit/visually-hidden';

import { useElementBreakpoints } from '../../hooks/useElementBreakpoints';

import {
	ErrorButton,
	ErrorButtonContainer,
	ErrorFloatingContainer,
	ErrorMessage,
} from './error-screen-parts';
import messages from './messages';

type Props = {
	onCancel: () => void;
	text: string | React.ReactNode;
};

export const UnhandledErrorScreen = ({ onCancel, text }: Props) => {
	const { formatMessage } = useIntl();
	const [setBreakpointsElement, { breakpoint }] = useElementBreakpoints();

	return (
		<ErrorFloatingContainer ref={setBreakpointsElement} breakpoint={breakpoint}>
			<VisuallyHidden>{formatMessage(messages.ariaErrorTitle)}</VisuallyHidden>
			<ErrorMessage breakpoint={breakpoint}>{text}</ErrorMessage>
			<ErrorButtonContainer>
				<ErrorButton autoFocus testId={'cancel'} onClick={onCancel} appearance="primary">
					{formatMessage(messages.cancelLabel)}
				</ErrorButton>
			</ErrorButtonContainer>
		</ErrorFloatingContainer>
	);
};
