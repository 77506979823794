import React from 'react';

import type { IntlShape } from 'react-intl-next';

import failedStateMessages from '../../messages';

export const getErrorMessage = (content: string) => {
	switch (content) {
		case 'INPUT_EXCEEDS_TOKEN_LIMIT':
			return failedStateMessages.tokenLimitExceeded;
		case 'INPUT_TOO_SHORT_TO_SUMMARIZE':
			return failedStateMessages.inputTooShortError;
		case 'INPUT_TOO_SHORT_TO_PROCESS':
			return failedStateMessages.inputTooShortError;
		case 'RATE_LIMIT':
			return failedStateMessages.rateLimitEnforced;
		case 'OPENAI_RATE_LIMIT_USER_ABUSE':
			return failedStateMessages.rateLimitEnforced;
		case 'NO_AGENT':
			return failedStateMessages.internalServerError;
		case 'FEATURE_DISABLED_ON_SITE':
			return failedStateMessages.internalServerError;
		case 'FEATURE_DISABLED':
			return failedStateMessages.internalServerError;
		case 'INTERNAL_SERVER_ERROR':
			return failedStateMessages.internalServerError;
		case 'PLUGIN_ERRORED':
			return failedStateMessages.internalServerError;
		case 'HIPAA_CONTENT_DETECTED':
			return failedStateMessages.hipaaContentError;
		case 'EXCEEDING_CONTEXT_LENGTH_ERROR':
			return failedStateMessages.tokenLimitExceeded;
		default:
			return failedStateMessages.apiError;
	}
};

export const getAupViolationMessage = (
	formatMessage: IntlShape['formatMessage'],
): React.ReactNode => {
	return formatMessage(failedStateMessages.aupViolationMessage, {
		auplink: (chunks: React.ReactNode[]) => (
			// Ignored via go/ees005
			// eslint-disable-next-line react/jsx-no-target-blank
			<a href="https://www.atlassian.com/legal/acceptable-use-policy" target="_blank">
				{chunks}
			</a>
		),
	});
};

export const getInternalServerErrorMessage = (
	formatMessage: IntlShape['formatMessage'],
): React.ReactNode => {
	return formatMessage(failedStateMessages.internalServerError, {
		link: (chunks: React.ReactNode[]) => (
			// Ignored via go/ees005
			// eslint-disable-next-line react/jsx-no-target-blank
			<a href="https://status.atlassian.com/" target="_blank">
				{chunks}
			</a>
		),
	});
};

export const getRateLimitErrorMessage = (
	formatMessage: IntlShape['formatMessage'],
): React.ReactNode => {
	return formatMessage(failedStateMessages.rateLimitEnforced, {
		link: (chunks: React.ReactNode[]) => (
			// Ignored via go/ees005
			// eslint-disable-next-line react/jsx-no-target-blank
			<a
				href="https://support.atlassian.com/organization-administration/docs/excessive-use-in-atlassian-intelligence/"
				target="_blank"
			>
				{chunks}
			</a>
		),
	});
};
