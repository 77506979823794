import React from 'react';

import Heading from '@atlaskit/heading';
import { Box, Stack, Text } from '@atlaskit/primitives';

import type { SpaceRole } from '../../model/space-roles-types';
import { usePermissionsSummary } from '../../page/usePermissionsSummary';

export const PermissionsSummary = ({
	roleId,
	permissions,
	roleLookup,
	isDefaultsView,
}: {
	roleId: string | null;
	permissions: string[];
	roleLookup: SpaceRole[];
	isDefaultsView: boolean;
}) => {
	const { heading, description } = usePermissionsSummary({
		roleId,
		permissions,
		roleLookup,
		isDefaultsView,
	});

	return (
		<Stack>
			<Box>
				<Heading size="large">{heading}</Heading>
			</Box>
			<Text as="p">{description}</Text>
		</Stack>
	);
};
