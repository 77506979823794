export enum FAILURE_REASON {
	API_FAIL = 'api fail',
	AUP_VIOLATION = 'aup violation detected',
	UNKNOWN = 'unknown fail',
	TOKEN_LIMIT = 'token limit fail',
	INSERT_FAILURE = 'insert failure',
	INPUT_TOO_SHORT = 'input too short fail',
	HIPAA_CONTENT = 'hipaa content fail',
	RATE_LIMITED = 'rate limited',
	INTERNAL_SERVER_ERROR = 'internal server error',
	ELEVATE_DISABLED_GENERATE = 'elevate disabled generate',
}
