import { useCallback, useMemo } from 'react';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

export const useOnboardingModal = () => {
	const { PERSISTED_RBAC_TRACKING } = PERSISTED_KEYS_ON_SERVER;
	const MODAL_DISPLAYED_KEY = `${PERSISTED_RBAC_TRACKING}.defaults-onboarding-modal-displayed`;

	const hasModalDisplayed = useMemo(() => {
		try {
			return Boolean(localStorage.getItem(MODAL_DISPLAYED_KEY));
		} catch (e) {
			// Don't show the modal if we can't read from local storage
			return true;
		}
	}, [MODAL_DISPLAYED_KEY]);

	const setModalDisplayed = useCallback(() => {
		try {
			localStorage.setItem(MODAL_DISPLAYED_KEY, 1);
		} catch (e) {
			return;
		}
	}, [MODAL_DISPLAYED_KEY]);

	return {
		hasModalDisplayed,
		setModalDisplayed,
	};
};
