import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.title',
		defaultMessage: 'Advanced prompt',
		description: 'Describes an option to use Atlassian Intelligence to show a more advanced prompt',
	},
	description: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.description',
		defaultMessage: 'Specify tone and target audience',
		description: 'Description for the the Atlassian Intelligence "Advanced prompt".',
	},
	promptLabel: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptLabel',
		defaultMessage: 'Advanced prompt',
		description: 'Label text in the prompt input.',
	},
});
