import type { MessageDescriptor } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';

import type { TransformAction } from '../../pm-plugins/ai-proactive/api';

import messages from './messages';

export type TransformActionDescriptors = {
	description: MessageDescriptor;
	title: MessageDescriptor;
};

const defaultDetails: TransformActionDescriptors = {
	title: messages.recommendationDefaultTitle,
	description: messages.recommendationDefaultDescription,
};

// TODO: when FF: aix_proactive_format_paragraph_feature_enabled is folled out, revert back to non-partial type
const detailsByConversionType: Partial<Record<TransformAction, TransformActionDescriptors>> = {
	CONVERT_TO_TABLE: {
		title: messages.recommendationConvertToTableTitle,
		description: messages.recommendationConvertToTableDescription,
	},
	CONVERT_TO_NOTE_PANEL: {
		title: messages.recommendationConvertToNotePanelTitle,
		description: messages.recommendationConvertToNotePanelDescription,
	},
	CONVERT_TO_INFO_PANEL: {
		title: messages.recommendationConvertToInfoPanelTitle,
		description: messages.recommendationConvertToInfoPanelDescription,
	},
	CONVERT_TO_WARN_PANEL: {
		title: messages.recommendationConvertToWarnPanelTitle,
		description: messages.recommendationConvertToWarnPanelDescription,
	},
	CONVERT_TO_ERROR_PANEL: {
		title: messages.recommendationConvertToErrorPanelTitle,
		description: messages.recommendationConvertToErrorPanelDescription,
	},
	CONVERT_TO_SUCCESS_PANEL: {
		title: messages.recommendationConvertToSuccessPanelTitle,
		description: messages.recommendationConvertToSuccessPanelDescription,
	},
	SPLIT_PARAGRAPH: {
		title: messages.recommendationSplitParagraphTitle,
		description: messages.recommendationSplitParagraphDescription,
	},
};

export const getTransformActionDescriptors = (
	action: TransformAction,
): TransformActionDescriptors => {
	/**
	 * When aix_proactive_format_paragraph_feature_enabled is rolled out move FORMAT_PARAGRAPH into
	 * detailsByConversionType directly
	 */
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	if (fg('aix_proactive_format_paragraph_feature_enabled')) {
		detailsByConversionType.FORMAT_PARAGRAPH = {
			title: messages.recommendationFormatParagraphTitle,
			description: messages.recommendationFormatParagraphDescription,
		};

		delete detailsByConversionType.SPLIT_PARAGRAPH;
	}

	return detailsByConversionType[action] ?? defaultDetails;
};
