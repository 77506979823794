export type ConfluencePageAuditLogEventData = {
	type: 'page';
	pageId: string;
};

export type ConfluenceAuditLogEventData = ConfluencePageAuditLogEventData;

export type JiraIssueAuditLogEventData = {
	type: 'issue';
	issueId: string;
};

export type JiraAuditLogEventData = JiraIssueAuditLogEventData;

/**
 * Tentatively decied to send audit log events for for confluence page
 * and Jira issue description.
 * Structure of AuditLogSettings can be changed once API spec if fianlised.
 */
export type AuditLogEventData = ConfluenceAuditLogEventData | JiraAuditLogEventData;

export type AuditLogSettings =
	| {
			enabled: false;
	  }
	| {
			enabled: true;
			eventData: AuditLogEventData;
	  };

export interface AIAuditLogPluginState {
	// structure of events are not finalised yet.
	/**
	 * Will send one audit log event for all AI generated content insertion per session.
	 *
	 */
	'ai-generated-content-inserted': {
		eventStatus: 'not-sent' | 'send-event' | 'event-in-progress' | 'event-sent';
		retries: number;
	};
	// structure of events are not finalised yet.
	'aup-violation': {
		eventStatus: 'not-sent' | 'send-event' | 'event-in-progress' | 'event-sent';
		retries: number;
	};
	retryDelay: number;
	maxRetries: number;
	eventData: AuditLogEventData;
}

export type AuditLogEvents = keyof Omit<
	AIAuditLogPluginState,
	'eventData' | 'retryDelay' | 'maxRetries'
>;
export const auditLogEvents: AuditLogEvents[] = ['ai-generated-content-inserted', 'aup-violation'];

type ActionData = {
	'ai-generated-content-inserted'?: Partial<AIAuditLogPluginState['ai-generated-content-inserted']>;
	'aup-violation'?: Partial<AIAuditLogPluginState['aup-violation']>;
};
export interface AIAuditLogUpdatePluginState {
	type: 'UPDATE_PLUGIN_STATE';
	data: ActionData;
}
