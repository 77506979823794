import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { useMutation } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { AddPrincipalModal } from '@confluence/space-roles/entry-points/Dialogs';
import { useResponseHandler } from '@confluence/space-roles/entry-points/graphql';
import type { SpaceRole } from '@confluence/space-roles/entry-points/space-role-types';
import { DEFAULT_RBAC_EDIT_EXPERIENCE } from '@confluence/experience-tracker';

import { RoleAssignmentPrincipalType } from '../graphql/__types__/SetDefaultSpaceRoleAssignments';
import type {
	SetDefaultSpaceRoleAssignmentsVariables,
	SetDefaultSpaceRoleAssignments as SetDefaultSpaceRoleAssignmentsType,
} from '../graphql/__types__/SetDefaultSpaceRoleAssignments';
import { SetDefaultSpaceRoleAssignments } from '../graphql/SetDefaultSpaceRoleAssignments.graphql';
import { DefaultSpaceRoleAssignments } from '../graphql/DefaultSpaceRoleAssignments.graphql';

const i18n = defineMessages({
	addGroupSuccess: {
		id: 'default-space-permissions.add-group.save-success',
		defaultMessage: 'Default group added',
		description: 'Success message when a group is added to a space',
	},
});

type AddPrincipalModalProps = ComponentProps<typeof AddPrincipalModal>;

export const AddGroupModal = ({
	onClose,
	roleOptions,
}: {
	onClose: () => void;
	roleOptions: SpaceRole[];
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { handleResponse: handleMutationResponse } = useResponseHandler({
		experience: DEFAULT_RBAC_EDIT_EXPERIENCE,
		successMessage: formatMessage(i18n.addGroupSuccess),
	});

	const [setRoleAssignments] = useMutation<
		SetDefaultSpaceRoleAssignmentsType,
		SetDefaultSpaceRoleAssignmentsVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SetDefaultSpaceRoleAssignments,
		{
			onError: (error) => handleMutationResponse({ error }),
			refetchQueries: [
				{
					query: DefaultSpaceRoleAssignments,
				},
			],
			onCompleted: (response) => {
				handleMutationResponse({ errors: response.setDefaultSpaceRoleAssignments?.errors });
			},
		},
	);

	const onSave: AddPrincipalModalProps['onSave'] = useCallback(
		async (roleAssignments) => {
			await setRoleAssignments({
				variables: {
					input: {
						spaceRoleAssignmentList: roleAssignments.map((roleAssignment) => ({
							principal: {
								principalId: roleAssignment.principalId,
								principalType: RoleAssignmentPrincipalType.GROUP,
							},
							roleId: roleAssignment.roleId,
						})),
					},
				},
			});
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					actionSubject: 'button',
					action: 'clicked',
					actionSubjectId: 'save',
					source: 'default-space-permissions-addGroupModal',
				},
			}).fire();
		},
		[setRoleAssignments, createAnalyticsEvent],
	);

	return (
		<>
			<AddPrincipalModal
				onClose={onClose}
				onSave={onSave}
				allowUsers={false}
				spaceId={null}
				defaultRoleOptionList={roleOptions}
				experience={DEFAULT_RBAC_EDIT_EXPERIENCE}
			/>
		</>
	);
};
