import partition from 'lodash/partition';

import {
	type JSONDocNode,
	type JSONNode,
	JSONTransformer,
} from '@atlaskit/editor-json-transformer';
import type { Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import { getMentionMap } from '@atlassian/ai-model-io/utils/mention-map';
import type { RateLimiter } from '@atlassian/editor-ai-common/api/rate-limiter';
import { getXProductHeader } from '@atlassian/editor-ai-common/api/request';

import { type ParagraphChunk } from '../../../utils/diff-match-patch/utils';
import type { TriggerContext } from '../commands';
import {
	MAX_PARTS,
	PART_MAX_SIZE,
	QUEUE_MAX_ADDITIONAL_ATTEMPTS,
	TOTAL_PARTS_MAX_SIZE,
} from '../constants';

import type { Recommendation, RequestGenerator, TransformAction } from './index';

/**
 * API Spec: https://hello.atlassian.net/wiki/spaces/AM3/pages/4173571632/Proactive+AI+Trigger+API+Design
 */
type ValidRequest = {
	ai_feature_input: {
		triggers: {
			trigger_id: string;
			content_url: string;
			trigger_type: 'PARAGRAPH_COMPLETE';
			paragraph_content: JSONNode;
			before_context?: JSONNode | Object;
			after_context?: JSONNode | Object;
		}[];
	};
};

type ValidResponse = {
	ai_feature_output: {
		triggers: (ResponseTrigger | ResponseTriggerError)[];
	};
};

type ErrorResponse = {
	errorMessage: string;
	errors: string[];
	statusCode: number;
};

type ResponseTrigger = {
	trigger_id: string;
	recommendations: ResponseRecommendation[];
};

type ResponseTriggerError = {
	trigger_id: string;
	errors: {
		error_type: string;
	};
};

type ResponseRecommendation = {
	recommendation_id: string;
	transformation_type: 'REPLACE_PARAGRAPH';
	recommendation_name: TransformAction;
	transformation_details: {
		replacement_adf: JSONNode;
	};
};

function isValidResponse(response: unknown): response is ValidResponse {
	return typeof response === 'object' && response !== null && 'ai_feature_output' in response;
}

function isErrorResponse(response: unknown): response is ErrorResponse {
	return (
		typeof response === 'object' &&
		response !== null &&
		'errorMessage' in response &&
		'errors' in response &&
		'statusCode' in response
	);
}

type ParagraphChunkWithADF = ParagraphChunk & {
	content: JSONNode;
	before?: JSONNode;
	after?: JSONNode;
};

// Ignored via go/ees005
// eslint-disable-next-line require-unicode-regexp
const sanitizeUrl = (url: string) => url.replace(/[^\w\-/.:%?=]+/g, '');

const getParagraphChunkWithADF = async (
	doc: PMNode,
	chunk: ParagraphChunk,
	context: TriggerContext,
): Promise<ParagraphChunkWithADF | undefined> => {
	const { view, getMentionNameDetails } = context;
	const { from, to } = chunk;
	const content = doc.nodeAt(from);

	if (!content) {
		return;
	}

	let mentionMap;
	if (getMentionNameDetails) {
		mentionMap = await getMentionMap({ node: content, getMentionNameDetails });
	}

	const before = doc.resolve(from).nodeBefore;
	const after = doc.resolve(to).nodeAfter;

	const serializer = new JSONTransformer(view.state.schema, mentionMap);

	return {
		...chunk,
		content: serializer.encodeNode(content),
		before:
			before && before.textContent.trim().length !== 0 ? serializer.encodeNode(before) : undefined,
		after:
			after && after.textContent.trim().length !== 0 ? serializer.encodeNode(after) : undefined,
	};
};

async function parseResponse(response: Response): Promise<
	| {
			state: 'success';
			body: ValidResponse;
	  }
	| {
			state: 'failed';
			reason: 'network' | 'backend' | 'parsing' | 'rate-limited';
			errors: string[];
			statusCode: number;
			retryAfter?: number;
	  }
> {
	if (response.status === 429) {
		// The BE are unable to add the header due to constraints with the service proxy
		// There is a ticket to add this via feature request
		// https://hello.jira.atlassian.cloud/browse/SPSVC-28
		// For now we are just hard coding the value

		const retryAfter = parseInt(response.headers.get('Retry-After') ?? '');
		return {
			state: 'failed',
			reason: 'rate-limited',
			// Retry-After is formatted in seconds, normalizing to milliseconds
			retryAfter: !isNaN(retryAfter) ? retryAfter * 1000 : undefined,
			statusCode: response.status,
			errors: ['tooManyRequests'],
		};
	}

	try {
		const body = await response.json();

		if (isValidResponse(body)) {
			return {
				state: 'success',
				body,
			};
		}

		if (isErrorResponse(body)) {
			return {
				state: 'failed',
				reason: 'backend',
				errors: [body.errorMessage, ...body.errors],
				statusCode: body.statusCode,
			};
		}
	} catch (parsingError) {
		return {
			state: 'failed',
			reason: 'parsing',
			errors: ['parsingError'],
			statusCode: response.status,
		};
	}

	if (!response.body) {
		return {
			state: 'failed',
			reason: 'network',
			errors: ['response.body missing'],
			statusCode: response.status,
		};
	}

	return {
		state: 'failed',
		reason: 'backend',
		errors: ['Invalid response'],
		statusCode: response.status,
	};
}

export async function* requestGenerator({
	endpoint,
	paragraphs,
	product,
	context,
	rateLimiter,
}: {
	endpoint: string;
	paragraphs: Array<ParagraphChunk>;
	product: string;
	context: TriggerContext;
	rateLimiter: RateLimiter | undefined;
}): RequestGenerator {
	if (!paragraphs.length) {
		yield { state: 'done' };
		return;
	}

	const { view: editorView, locale } = context;

	// FIXME: Rather then removing all the work we added in S+G around queing limits and purging requests. I'm going to
	// set the limits to max value, this way the limits are not enforced and the BE just gets all changes always. This
	// way in future if they want to have limits we can just reset the limit settings.
	/**
	 * QUEUE CONSTRAINTS:
	 * Input size sum (all parts): 10,000 chars
	 * Input size per part: 5,000 chars
	 * Max parts: 50
	 */

	const doc = editorView.state.doc;
	const abortController = new AbortController();

	const purgedChunkIds: Array<ParagraphChunk['id']> = [];

	const totalParts = paragraphs.length;
	let attemptCount = 0;

	// Partition into parts that are within the part size limit and parts that exceed it
	// This is done due to backend limitations
	const [withinLimitsParagraphChunks, exceededLimitsParagraphChunks] = partition(
		paragraphs,
		(p) => p.text.length <= PART_MAX_SIZE,
	);

	// For all parts that exceed the parts limit, add them to the purged list
	purgedChunkIds.push(...exceededLimitsParagraphChunks.map((p) => p.id));

	while (withinLimitsParagraphChunks.length && attemptCount <= QUEUE_MAX_ADDITIONAL_ATTEMPTS) {
		attemptCount++;
		const queue: ParagraphChunkWithADF[] = [];
		let queueTotalPartsSize = 0;
		/**
		 * Fill the queue with parts up until the constraints have been met or until all parts have been added
		 */
		while (
			withinLimitsParagraphChunks.length &&
			queueTotalPartsSize + withinLimitsParagraphChunks[0].text.length <= TOTAL_PARTS_MAX_SIZE &&
			queue.length <= MAX_PARTS
		) {
			const withinLimitsParagraphChunk = withinLimitsParagraphChunks.shift();
			if (!withinLimitsParagraphChunk) {
				break;
			}
			queueTotalPartsSize += withinLimitsParagraphChunk.text.length;
			const paragraphChunkWithADF = await getParagraphChunkWithADF(
				doc,
				withinLimitsParagraphChunk,
				context,
			);
			if (paragraphChunkWithADF) {
				queue.push(paragraphChunkWithADF);
			}
		}

		if (!queue.length) {
			break;
		}
		const queuedChunkIds = queue.map((p) => p.id);

		try {
			const payload: ValidRequest = {
				ai_feature_input: {
					// Only add uncached paragraphs to requests, since cached ones have already been yielded
					triggers: queue.map((chunk) => ({
						trigger_id: chunk.id,
						content_url: sanitizeUrl(document.location.href),
						trigger_type: 'PARAGRAPH_COMPLETE',
						paragraph_content: chunk.content,
						// FIXME: Remove these fallbacks ie. ?? {} the before/after should be optional however the server is treating them as required
						// once there optional we sould be able to remove them
						before_context: chunk.before ?? {},
						after_context: chunk.after ?? {},
					})),
				},
			};

			// track durations
			const startTime = performance.now();

			const response = await fetch(endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json;charset=UTF-8',
					'X-Experience-Id': 'proactive-paragraph-complete',
					'Accept-Language': locale,
					...getXProductHeader(product),
				},
				body: JSON.stringify(payload),
				credentials: 'include',
				signal: abortController.signal,
				mode: 'cors',
			});

			yield {
				state: 'trackedDuration',
				duration: performance.now() - startTime,
			};

			const result = await parseResponse(response);

			if (result.state === 'failed') {
				const isRetrying =
					result.reason === 'rate-limited' &&
					!!rateLimiter?.enqueueRetry(!!result?.retryAfter ? result.retryAfter : undefined);

				if (isRetrying) {
					yield {
						state: 'rate-limited',
						failedChunkIds: queuedChunkIds,
					};
					break;
				}

				yield {
					...result,
					failedChunkIds: queuedChunkIds,
				};
				break;
			}

			const { triggers } = result.body.ai_feature_output;

			// FIXME: This is not ideal, it checks for any errors in the response and then bails.
			// it would be nice to handle suggestions which are also included with the errors.
			const errors = triggers.reduce<string[]>((acc, trigger) => {
				if ('errors' in trigger) {
					acc.push(trigger.errors.error_type);
				}
				return acc;
			}, []);

			const serializer = new JSONTransformer(editorView.state.schema);

			const recommendations = triggers.reduce<Recommendation[]>((acc, trigger) => {
				if ('recommendations' in trigger) {
					return acc.concat(
						trigger.recommendations.map<Recommendation>((recommendation) => {
							// XXX: We can only parse doc nodes, just in case the backend responds with a raw adf node which isn't encapsulated
							// with a doc node then we will attempt correct this error before we parse it.
							const adf = recommendation.transformation_details.replacement_adf;
							const replacement: JSONDocNode =
								adf.type !== 'doc'
									? {
											version: 1,
											type: 'doc',
											content: [adf],
										}
									: (adf as JSONDocNode);

							return {
								chunkId: trigger.trigger_id,
								rid: recommendation.recommendation_id,
								// XXX: We will not assume that the recommendation id is globally unique. However it should at minimum
								// be unique to other local recommendations. We can make this id globally unique by prepending the
								// trigger id which is gloabally unique.
								id: `${trigger.trigger_id}_${recommendation.recommendation_id}`,
								transformContent: serializer.parse(replacement),
								transformType: recommendation.transformation_type,
								transformAction: recommendation.recommendation_name,
								isViewed: false,
								filtered: false,
							};
						}),
					);
				}
				return acc;
			}, []);

			// Reset the rate limiter when a successful response is returned
			rateLimiter?.reset();

			yield { state: 'parsed', recommendations };

			if (errors.length) {
				yield {
					state: 'failed',
					reason: 'backend',
					errors,
					statusCode: response.status,
					failedChunkIds: queuedChunkIds,
				};
			}
			// Ignored via go/ees005
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error.name === 'AbortError') {
				yield {
					state: 'failed',
					reason: 'aborted',
					errors: ['Streaming aborted'],
					statusCode: error.status,
					failedChunkIds: queuedChunkIds,
				};
			} else {
				yield {
					state: 'failed',
					reason: 'unhandled',
					errors:
						error instanceof Error &&
						['RangeError', 'TypeError', 'TransformError'].includes(error.name)
							? [error.message]
							: ['unhandled'],
					statusCode: error.status,
					failedChunkIds: queuedChunkIds,
				};
			}
		}
	}

	/**
	 * Purge any remaining valid paragraphs
	 */
	if (withinLimitsParagraphChunks.length) {
		purgedChunkIds.push(...withinLimitsParagraphChunks.map((p) => p.id));
	}

	if (purgedChunkIds.length) {
		yield {
			state: 'purged',
			totalParts,
			totalPurgedParts: purgedChunkIds.length,
			purgedChunkIds,
		};
	}

	yield { state: 'done' };
	return;
}
