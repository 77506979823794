import React from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import ModalDialog, {
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalTitle,
} from '@atlaskit/modal-dialog';

interface RecoverPermissionFrictionDialogProps {
	onCancel: () => void;
	onConfirm: () => void;
}

const i18n = defineMessages({
	title: {
		id: 'default-space-permissions.recover-permission-friction-dialog.title',
		defaultMessage: 'Recover Permissions',
		description: 'Title for the recover permission friction dialog',
	},
	content: {
		id: 'default-space-permissions.recover-permission-friction-dialog.content',
		defaultMessage:
			'Are you sure you want to grant yourself space admin permission for this space? This action will be logged.',
		description: 'Content for the recover permission friction dialog',
	},
	cancelButtonText: {
		id: 'default-space-permissions.recover-permission-friction-dialog.cancel-button-text',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for the recover permission friction dialog',
	},
	confirmButtonText: {
		id: 'default-space-permissions.recover-permission-friction-dialog.confirm-button-text',
		defaultMessage: 'Confirm',
		description: 'Confirm button text for the recover permission friction dialog',
	},
});

export const RecoverPermissionFrictionDialog: FC<RecoverPermissionFrictionDialogProps> = ({
	onCancel,
	onConfirm,
}) => {
	const { formatMessage } = useIntl();
	return (
		<ModalDialog onClose={onCancel}>
			<ModalHeader>
				<ModalTitle appearance="warning">{formatMessage(i18n.title)}</ModalTitle>
			</ModalHeader>
			<ModalBody>{formatMessage(i18n.content)}</ModalBody>
			<ModalFooter>
				<Button appearance="subtle" onClick={onCancel}>
					{formatMessage(i18n.cancelButtonText)}
				</Button>
				<Button appearance="warning" onClick={onConfirm}>
					{formatMessage(i18n.confirmButtonText)}
				</Button>
			</ModalFooter>
		</ModalDialog>
	);
};
