import { defineMessages, useIntl } from 'react-intl-next';

import type { SpaceRole } from '../model/space-roles-types';

const i18n = defineMessages({
	customAccessHeading: {
		id: 'space-roles.permissions-summary.custom-access-heading',
		defaultMessage: 'Custom access',
		description: 'Heading for custom access',
	},
	customAccessDescription: {
		id: 'space-roles.permissions-summary.custom-access-description',
		defaultMessage: 'They have permissions in this space that don’t map to a role.',
		description: 'Description for custom access',
	},
	customAccessDefaultDescription: {
		id: 'space-roles.permissions-summary.custom-access-default-description',
		defaultMessage: 'They have default permissions that don’t map to a role.',
		description: 'Description for custom access',
	},
	noGeneralAccessHeading: {
		id: 'space-roles.permissions-summary.no-general-access-heading',
		defaultMessage: 'No general access',
		description: 'Heading for no access',
	},
	noGeneralAccessDescription: {
		id: 'space-roles.permissions-summary.no-general-access-description',
		defaultMessage:
			'Select a role to give all users in this user class access to this space, regardless of group membership.',
		description: 'Description for no access',
	},
	noGeneralAccessDefaultDescription: {
		id: 'space-roles.permissions-summary.no-access-default-description',
		defaultMessage:
			'Select a role to give this user class access to all new spaces created, regardless of group membership.',
		description: 'Description for no access',
	},
});

export const usePermissionsSummary = ({
	roleId,
	permissions,
	roleLookup,
	isDefaultsView,
}: {
	roleId: string | null;
	permissions: string[];
	roleLookup: SpaceRole[];
	isDefaultsView: boolean;
}) => {
	const { formatMessage } = useIntl();

	const role = roleLookup.find((lookup) => lookup.id === roleId);

	// No access
	if (!Boolean(role) && !Boolean(permissions.length)) {
		return {
			heading: formatMessage(i18n.noGeneralAccessHeading),
			description: isDefaultsView
				? formatMessage(i18n.noGeneralAccessDefaultDescription)
				: formatMessage(i18n.noGeneralAccessDescription),
		};
	}
	// Custom access
	if (!Boolean(role)) {
		return {
			heading: formatMessage(i18n.customAccessHeading),
			description: isDefaultsView
				? formatMessage(i18n.customAccessDefaultDescription)
				: formatMessage(i18n.customAccessDescription),
		};
	}
	// Role access
	return {
		heading: role?.name,
		description: role?.description,
	};
};
