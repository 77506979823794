/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx, keyframes } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import Badge from '@atlaskit/badge';
import { TOOLBAR_ACTION_SUBJECT_ID } from '@atlaskit/editor-common/analytics';
import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import { separatorStyles, wrapperStyle } from '@atlaskit/editor-common/styles';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import { ToolbarButton } from '@atlaskit/editor-common/ui-menu';
import type { WidthPlugin } from '@atlaskit/editor-plugin-width';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Loading from '@atlassian/generative-ai-modal/assets/Loading';
import { LogoSvg as AtlassianIntelligenceLogo } from '@atlassian/generative-ai-modal/assets/Logo';
import {
	prismBorderAnimationStyles,
	prismBorderStyles,
} from '@atlassian/generative-ai-modal/styles/FloatingContainer';
import { useAIThemeColor } from '@atlassian/generative-ai-modal/styles/theme';
import sharedMessages from '@atlassian/generative-ai-modal/utils/messages';

import SuggestedEditIcon from '../../../context-panel/setting/assets/SuggestedEditIcon';
import messages from '../../../messages';

import { svgIconStyles } from './styles';
import { useEditorToolbarChangeDetector } from './useEditorToolbarChangeDetector';

const unreadBadgeCornerStyles = xcss({
	position: 'absolute',
	right: '-4px',
	top: '-2px',
});

const buttonWrapperStyles = xcss({
	position: 'relative',
});

type Props = {
	onClick: () => void;
	disabled: boolean;
	selected?: boolean;
	loading?: boolean;
	unreadCount?: number;
	api?: PublicPluginAPI<[WidthPlugin]> | undefined;
	ButtonWrapper?: React.ComponentType<{ children: React.ReactNode }>;
	view?: EditorView;
	proactive?: boolean;
};

// This const is derived from the breakpoint where the toolbar hides its icons. It is used to hide the text in the AI button.
// Derived from values from platform/packages/editor/editor-core/src/ui/Appearance/FullPage/MainToolbar.tsx
const AI_BUTTON_WIDTH_BREAKPOINT = 1076;

// eslint-disable-next-line @repo/internal/deprecations/deprecation-ticket-required -- Ignored via go/ED-25883
/**
 * @deprecated This method is deprecated and should be cleaned up once all products using AI
 * are on composable editor.
 */
const deprecatedGetWidthState = (view: EditorView) => {
	const editorWidthPlugin = view.state.plugins.find(
		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(plugin) => (plugin as any).key === 'widthPlugin$',
	);

	return { widthState: editorWidthPlugin?.getState(view.state) };
};

const shouldShowSeparator = (node: HTMLSpanElement | null) => {
	if (!node) {
		return false;
	}

	const prevSeparator = node.previousElementSibling?.querySelector(
		`:scope > span:last-child:empty`,
	);
	const prevSeparatorVisible = prevSeparator && prevSeparator.clientWidth > 0;

	return !prevSeparatorVisible;
};

const fadeOut = keyframes({
	/**
	 * 0% keyframe is omitted as the blur (&::after) is at 0.5 while the
	 * border (&::before) is at 1
	 */
	'100%': {
		opacity: 0,
	},
});

const fadeOutAnimation = css({
	'&::before, &::after': {
		animation: `${fadeOut} 160ms ease-out forwards`,
		animationDelay: '3000ms',
	},
});

const backgroundStyles = css({
	/**
	 * Override the border radius as the button radius is smaller than the styles from
	 * FloatingContainer
	 */
	'&::before, &::after': {
		borderRadius: `calc(${token('border.radius.100')} + 1px)`,
	},
	backgroundColor: token('elevation.surface.overlay'),
	borderRadius: token('border.radius.100'),
});

/** Prevent count badge clipping at certain toolbar widths by truncating label text with ellipsis. */
const truncatingTextStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: 'inline-block',
});

const LoadingContainer = ({
	isLoading,
	children,
}: {
	isLoading?: boolean;
	children: React.ReactNode;
}) => {
	const [isPersisting, setIsPersisting] = useState(isLoading ?? false);
	const aiThemeColor = useAIThemeColor();

	useEffect(() => {
		if (isLoading) {
			setIsPersisting(true);
		} else {
			const timeoutId = setTimeout(() => setIsPersisting(false), 3600);
			return () => clearTimeout(timeoutId);
		}
	}, [isLoading]);

	if (isLoading || isPersisting) {
		return (
			<div
				css={
					isLoading
						? // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values
							[prismBorderStyles(aiThemeColor), prismBorderAnimationStyles, backgroundStyles]
						: // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values
							[prismBorderStyles(aiThemeColor), backgroundStyles, fadeOutAnimation]
				}
			>
				{children}
			</div>
		);
	}
	return <Box xcss={buttonWrapperStyles}>{children}</Box>;
};

const ProactiveAIButton = ({
	onClick,
	isDisabled,
	isSelected,
	isLoading,
	unreadCount,
	api,
	view,
}: {
	onClick: () => void;
	isDisabled: boolean;
	isSelected?: boolean;
	isLoading?: boolean;
	unreadCount?: number;
	api?: PublicPluginAPI<[WidthPlugin]> | undefined;
	view?: EditorView;
}) => {
	const { formatMessage } = useIntl();

	const { widthState } = useSharedPluginState(api, ['width']);

	let shouldShowLabel = widthState?.width ? widthState.width >= AI_BUTTON_WIDTH_BREAKPOINT : true;

	if (!widthState?.width && view) {
		const { widthState: deprecatedWidthState } = deprecatedGetWidthState(view);
		shouldShowLabel = deprecatedWidthState?.width >= AI_BUTTON_WIDTH_BREAKPOINT;
	}

	const title = isSelected
		? formatMessage(sharedMessages.aiProactiveCloseDescriptor)
		: formatMessage(sharedMessages.aiProactiveDescriptor, {
				count: unreadCount,
			});

	const badge = !isLoading && (unreadCount ?? 0) > 0 && (
		<Badge max={4} appearance="primary">
			{unreadCount}
		</Badge>
	);

	const loadingIcon = <Loading alt={formatMessage(messages.loadingIconAltText)} />;

	const iconBefore =
		!shouldShowLabel && isLoading ? loadingIcon : <SuggestedEditIcon label={title} />;

	const buttonContent = (
		<Inline space={'space.075'} alignBlock="center">
			<Box xcss={truncatingTextStyles}>{formatMessage(sharedMessages.aiProactiveButtonLabel)}</Box>
			{isLoading && loadingIcon}
			{badge}
		</Inline>
	);

	return (
		<LoadingContainer isLoading={isLoading}>
			<ToolbarButton
				onClick={onClick}
				buttonId={TOOLBAR_ACTION_SUBJECT_ID.AI}
				aria-label={title}
				testId="atlassian-intelligence-toolbar-button"
				title={title}
				iconBefore={iconBefore}
				disabled={isDisabled}
				selected={isSelected ?? false}
			>
				{shouldShowLabel && buttonContent}
			</ToolbarButton>
			{!shouldShowLabel && <Box xcss={unreadBadgeCornerStyles}>{badge}</Box>}
		</LoadingContainer>
	);
};

const WriteAIButton = ({
	api,
	view,
	onClick,
	isDisabled,
}: {
	onClick: () => void;
	api?: PublicPluginAPI<[WidthPlugin]> | undefined;
	view?: EditorView;
	isDisabled: boolean;
}) => {
	const { formatMessage } = useIntl();
	const title = formatMessage(sharedMessages.aiUniversalDescriptor);

	const { widthState } = useSharedPluginState(api, ['width']);

	let shouldShowLabel = widthState?.width ? widthState.width >= AI_BUTTON_WIDTH_BREAKPOINT : true;

	if (!widthState?.width && view) {
		const { widthState: deprecatedWidthState } = deprecatedGetWidthState(view);
		shouldShowLabel = deprecatedWidthState?.width >= AI_BUTTON_WIDTH_BREAKPOINT;
	}

	return (
		<ToolbarButton
			onClick={onClick}
			buttonId={TOOLBAR_ACTION_SUBJECT_ID.AI}
			aria-label={title}
			testId="atlassian-intelligence-toolbar-button"
			title={title}
			iconBefore={
				fg('platform-visual-refresh-icons') ? (
					<AtlassianIntelligenceIcon
						label="atlassian-intelligence-toolbar-button-icon"
						spacing="spacious"
					/>
				) : (
					<AtlassianIntelligenceLogo alt={''} isDisabled={isDisabled} />
				)
			}
			disabled={isDisabled}
		>
			{shouldShowLabel && formatMessage(sharedMessages.aiUniversalShortCallToAction)}
		</ToolbarButton>
	);
};

export const AtlassianIntelligenceToolbarButton = ({
	onClick,
	disabled,
	selected,
	loading,
	unreadCount,
	ButtonWrapper,
	api,
	view,
	proactive,
}: Props) => {
	const [showSeparator, setShowSeperator] = useState(false);
	const callbackRef = (node: HTMLSpanElement | null) => {
		const result = shouldShowSeparator(node);
		setShowSeperator(result);
	};

	const aiButton = proactive ? (
		<ProactiveAIButton
			onClick={onClick}
			isDisabled={disabled}
			isSelected={selected}
			isLoading={loading}
			unreadCount={unreadCount}
			api={api}
			view={view}
		/>
	) : (
		<WriteAIButton api={api} view={view} onClick={onClick} isDisabled={disabled} />
	);
	const toolbarMutationCount = useEditorToolbarChangeDetector(ButtonWrapper);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<span ref={callbackRef} css={[wrapperStyle, svgIconStyles]}>
			{showSeparator && (
				/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */
				<span data-testid="ai-toolbar-button-seperator" css={separatorStyles} />
			)}
			{ButtonWrapper ? (
				<ButtonWrapper key={`${toolbarMutationCount}-key-wrapper`}>{aiButton}</ButtonWrapper>
			) : (
				aiButton
			)}
		</span>
	);
};
