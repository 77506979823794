/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import { IconButton } from '@atlaskit/button/new';
import CloseIcon from '@atlaskit/icon/glyph/editor/close';
import { Flex, xcss } from '@atlaskit/primitives';

const announcementStyles = xcss({
	padding: 'space.150',
	borderRadius: '3px',
	backgroundColor: 'color.background.discovery',
	color: 'color.text.discovery',
});

interface Props {
	text: string;
	onClose: () => void;
}

export function AnnouncementBannerItem({ text, onClose }: Props) {
	return (
		<Flex
			xcss={announcementStyles}
			alignItems="center"
			justifyContent="space-between"
			gap="space.050"
		>
			{text}
			<IconButton
				icon={CloseIcon}
				label={'Close'}
				spacing="compact"
				appearance="subtle"
				onClick={onClose}
			/>
		</Flex>
	);
}
