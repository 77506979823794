// Entry file
/* eslint-disable @atlaskit/editor/no-re-export */

/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */
import React, { useCallback, useMemo, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import kebabCase from 'lodash/kebabCase';
import { useIntl } from 'react-intl-next';

import Button, { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import type { Keymap } from '@atlaskit/editor-common/keymaps';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { Schema } from '@atlaskit/editor-prosemirror/model';
import { fg } from '@atlaskit/platform-feature-flags';
import { Stack } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import VisuallyHidden from '@atlaskit/visually-hidden';

import type { EditorAgent } from '../../../utils/agents';
import { AI_MODAL_SCREEN_CLASS } from '../../../utils/constants';
import { ButtonWithShortcut } from '../../components/ButtonWithShortcut/ButtonWithShortcut';
import { CopyIconButton } from '../../components/CopyIconButton/CopyIconButton';
import { FeedbackForm } from '../../components/FeedbackForm/FeedbackForm';
import { FloatingContainer } from '../../components/FloatingContainer/FloatingContainer';
import { defaultPaddingStyles } from '../../components/FloatingContainer/styles';
import { Footer } from '../../components/Footer/Footer';
import { LatestPromptHeader } from '../../components/LatestPromptHeader/LatestPromptHeader';
import type { PromptEditor } from '../../components/PromptEditorWrapper/PromptEditorWrapper';
import { PromptForm } from '../../components/PromptForm/PromptForm';
import type {
	ClearInputMutableRefObject,
	FocusInputRefType,
} from '../../components/PromptForm/useSetInputRef';
import { RetryIconButton } from '../../components/RetryIconButton/RetryIconButton';
import { buttonAutoFocusRingFix, forceFocusRingStyles } from '../../styles/focus';

import { ActionButtonsLayout } from './components/ActionButtonsLayout';
import {
	RefineDropdown,
	type RefineDropdownItem,
} from './components/RefineDropdown/RefineDropdown';
import messages from './messages';
import {
	columnLayout,
	containerLayout,
	interrogationWrapper,
	previewContainer,
	previewContainerBorders,
} from './styles';

export type { RefineDropdownItem };

// Copied from packages/editor/editor-plugin-ai/src/config-items/config-items.tsx
export type ActionAppearance = 'primary' | 'secondary' | 'cancel';

/**
 * Could be either:
 * - userInput only (free form generate / interrogate)
 * - presetTitle only (summarize)
 * - both presetTitle and userInput (brainstorm)
 */
type LatestPrompt =
	| {
			presetTitle?: never;
			userInput: string;
			userADFInput?: JSONDocNode;
	  }
	| {
			presetTitle: string;
			userInput?: string;
			userADFInput?: JSONDocNode;
	  };

export type PreviewButton = {
	label: string;
	onClick: () => void;
	appearance: ActionAppearance;
	/** Optional shortcut keymap for the action. */
	shortcut?: Keymap;
	isDisabled?: boolean;
	tooltip?: string;
};

export type PreviewSecondaryActionButton = {
	label: string;
	onClick: () => void;
	appearance: ActionAppearance;
	/** Optional shortcut keymap for the action. */
	shortcut?: Keymap;
	icon: IconButtonProps['icon'];
	analyticsTitle?: string;
};

type Disclaimer = {
	text: string;
	tooltip: string;
	url?: string;
};

const mapActionAppearanceToButtonAppearance = {
	primary: 'primary',
	secondary: 'default',
	cancel: 'subtle',
} as const;

type Props = {
	/**
	 * Label for the parent if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	parentPresetPromptLabel?: string;
	/**
	 * Short title for the submenu if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	childShortTitle?: string;
	/**
	 * Label for a static prompt
	 */
	staticPromptTitle?: string;
	/**
	 * Label for a static prompt insertion
	 */
	staticInsertedPrompt?: string;
	latestPrompt?: LatestPrompt;
	preview: React.ReactElement;
	disableInterrogation?: boolean;
	/**
	 * Disables user followup actions
	 *
	 * *copy, retry, insert, replace, interrogate*
	 */
	disableActions: boolean;
	onSubmit?: (inputValue: string) => void;
	onCancel?: () => void;
	onCopy?: () => void;
	onRetry?: () => void;
	onInputFocus?: () => void;
	onInputBlur?: () => void;
	onInputChange?: (value: string) => void;
	onADFChange?: (value: JSONDocNode) => void;
	onGoodResponseButtonClick: () => void;
	onBadResponseButtonClick: () => void;
	onEditPromptClick?: () => void;
	onBack?: () => void;
	onClose?: () => void;
	showBack?: boolean;
	/* Applications are buttons that "apply" or include the generated content in the document.
  These may include options for where the user can insert or replace the content. **/
	applications: PreviewButton[];
	secondaryActions?: PreviewSecondaryActionButton[];
	isInputActive: boolean;
	inputValue?: string;
	inputADFValue?: JSONDocNode;
	placeholder?: string;
	disclaimer?: Disclaimer;
	/**
	 * Editor to be used as input in prompt.
	 */
	PromptEditor: PromptEditor;
	clearInputRef?: ClearInputMutableRefObject;
	providerFactory: ProviderFactory;
	schema: Schema;

	agent?: EditorAgent;
	/** List of items to be shown in the Refine button dropdown menu. */
	refineDropdownItems?: RefineDropdownItem[];
	/**
	 * Hides the horizontal rule above and below the preview container.
	 */
	hideHorizontalRule?: boolean;
	/**
	 * Show the beta lozenge in the footer.
	 */
	showBetaLozenge?: boolean;
	/**
	 * a clear title for Preview Screen
	 */
	isClearTitle?: boolean;
};

export const PreviewScreen = ({
	parentPresetPromptLabel,
	childShortTitle,
	staticPromptTitle,
	staticInsertedPrompt,
	latestPrompt,
	preview,
	disableActions = false,
	disableInterrogation = false,
	onSubmit,
	onCancel,
	onCopy,
	onRetry,
	onInputFocus,
	onInputBlur,
	onInputChange,
	onADFChange,
	onGoodResponseButtonClick,
	onBadResponseButtonClick,
	onEditPromptClick,
	onBack,
	onClose,
	showBack = true,
	applications,
	secondaryActions = [],
	isInputActive,
	inputValue,
	inputADFValue,
	PromptEditor,
	clearInputRef,
	providerFactory,
	schema,
	disclaimer,
	agent,
	refineDropdownItems,
	hideHorizontalRule = false,
	showBetaLozenge = false,
	isClearTitle = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const [forceFocusRing, setForceFocusRing] = useState(true);

	const presetTitleString = staticPromptTitle ?? latestPrompt?.presetTitle;

	const promptString = staticInsertedPrompt ?? latestPrompt?.userInput;

	const firePrimaryApplication = () => {
		if (!applications) {
			return;
		}
		setForceFocusRing(false);
		applications[applications.length - 1].onClick();
	};

	const applyButtonFocusStyles = useCallback(
		(index: number) => {
			return index === applications.length - 1 && forceFocusRing
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					forceFocusRingStyles
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					buttonAutoFocusRingFix;
		},
		[forceFocusRing, applications.length],
	);

	const onInterrogateBlur = useCallback(() => {
		setForceFocusRing(false);
	}, []);

	const handleOnInputFocus = useCallback(() => {
		setForceFocusRing(true);
		if (onInputFocus) {
			onInputFocus();
		}
	}, [onInputFocus]);

	const focusInputRef = useRef<FocusInputRefType>(undefined);

	const initialPlaceholder = agent
		? formatMessage(messages.placeholderWithAgent, { agentName: agent.name })
		: formatMessage(messages.defaultInputPlaceholder);

	const enableLinksInPromptEditor = !!fg('platform_editor_ai_interrogate_with_convo_ai');

	// This is used to determine when to disable a number of features to prevent
	// the user from performing actions on previous responses
	const isViewingLatestResponse = !disableActions && !disableInterrogation;

	const actionButtons = useMemo(() => {
		const secondaryButtons = !disableActions && (
			<>
				<FeedbackForm
					onBadResponseButtonClick={onBadResponseButtonClick}
					onGoodResponseButtonClick={onGoodResponseButtonClick}
				/>
				{onRetry && <RetryIconButton spacing="compact" appearance="subtle" onClick={onRetry} />}
				{onCopy && <CopyIconButton spacing="compact" appearance="subtle" onClick={onCopy} />}

				{secondaryActions.map((action) => {
					const id = kebabCase(action.analyticsTitle ?? action.label);
					return (
						<Tooltip key={id} content={action.label} position="top">
							<IconButton
								testId={id}
								type="button"
								onClick={action.onClick}
								icon={action.icon}
								label={action.label}
								appearance="subtle"
								spacing="compact"
							/>
						</Tooltip>
					);
				})}
			</>
		);

		const cancelButton = onCancel ? (
			<Button
				testId="discard-button"
				onClick={onCancel}
				type="button"
				appearance={disableActions ? 'primary' : 'subtle'}
				autoFocus={disableActions || applications.length === 0}
			>
				{formatMessage(messages.discardLabel)}
			</Button>
		) : undefined;

		const primaryButtons =
			!disableActions &&
			applications.map(({ onClick, label, appearance, shortcut, isDisabled, tooltip }, index) => {
				const button = (
					<ButtonWithShortcut
						key={label}
						onClick={onClick}
						type="button"
						shortcut={shortcut}
						autoFocus={appearance === 'primary'}
						css={applyButtonFocusStyles(index)}
						appearance={mapActionAppearanceToButtonAppearance[appearance]}
						isDisabled={isDisabled}
					>
						{label}
					</ButtonWithShortcut>
				);

				if (tooltip) {
					return (
						<Tooltip key={`${label}-tooltip`} content={tooltip} position="top">
							{button}
						</Tooltip>
					);
				}
				return button;
			});

		if (primaryButtons && !agent && refineDropdownItems) {
			primaryButtons.unshift(<RefineDropdown items={refineDropdownItems} />);
		}

		return (
			<ActionButtonsLayout
				cancelButton={cancelButton}
				secondaryButtons={secondaryButtons}
				primaryButtons={primaryButtons}
			/>
		);
	}, [
		disableActions,
		onBadResponseButtonClick,
		onGoodResponseButtonClick,
		onRetry,
		onCopy,
		secondaryActions,
		onCancel,
		applications,
		formatMessage,
		agent,
		refineDropdownItems,
		applyButtonFocusStyles,
	]);

	return (
		<div
			/* eslint-disable @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */
			css={[columnLayout]}
			className="preview-screen"
			/* eslint-enable */
		>
			<FloatingContainer
				disablePadding
				data-testid="preview-screen"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={`preview-screen ${AI_MODAL_SCREEN_CLASS}`}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={containerLayout}
				rainbowBorder={false}
			>
				<VisuallyHidden>{formatMessage(messages.previewScreenLabel)}</VisuallyHidden>
				<LatestPromptHeader
					parentPresetPromptLabel={parentPresetPromptLabel}
					childShortTitle={childShortTitle}
					prompt={promptString}
					promptADF={latestPrompt?.userADFInput}
					presetTitle={presetTitleString}
					agent={agent}
					providerFactory={providerFactory}
					schema={schema}
					onEditPromptClick={onEditPromptClick}
					showBack={showBack}
					onBack={onBack}
					onClose={onClose}
					isClearTitle={isClearTitle}
				/>
				<div
					css={[
						/* eslint-disable @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values */
						previewContainer,
						!hideHorizontalRule && previewContainerBorders,
						defaultPaddingStyles,
						/* eslint-enable @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values */
					]}
				>
					<Stack
						// Ignored via go/ees005
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...{ space: 'space.200' }}
					>
						{preview}
						{actionButtons}
					</Stack>
				</div>
				{isViewingLatestResponse && (
					<div
						css={[
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							defaultPaddingStyles,
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							interrogationWrapper,
						]}
					>
						{isViewingLatestResponse && (
							<PromptForm
								type="interrogate"
								showButtons={isInputActive}
								autoFocus={true}
								placeholder={initialPlaceholder}
								value={inputValue}
								adfValue={inputADFValue}
								// Ignored via go/ees005
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								onFormSubmit={onSubmit!}
								// Ignored via go/ees005
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								onInputChange={onInputChange}
								onADFChange={onADFChange}
								onInputFocus={handleOnInputFocus}
								focusInputRef={focusInputRef}
								clearInputRef={clearInputRef}
								PromptEditor={PromptEditor}
								enableLinks={enableLinksInPromptEditor}
								fireParentActionButton={firePrimaryApplication}
								focusParentActionButton={setForceFocusRing}
								showLogo={false}
								onInputBlur={onInterrogateBlur}
							/>
						)}
					</div>
				)}
				<Footer
					brand={agent ? 'rovo' : 'atlassian-intelligence'}
					disclaimer={disclaimer}
					showDisclaimer={true}
					showBetaLozenge={showBetaLozenge}
				/>
			</FloatingContainer>
		</div>
	);
};
