/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */
import { jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { useIntl } from 'react-intl-next';

import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { token } from '@atlaskit/tokens';

import type { RefineDropdownBaseItem, RefineDropdownSubMenuItem } from './RefineDropdown';

export function RefineDropdownItems({
	items,
	onItemClick,
	currentOpenedSubMenu,
	onSubMenuOpenChanged,
}: {
	items: RefineDropdownBaseItem[];
	onItemClick: () => void;
	currentOpenedSubMenu?: RefineDropdownSubMenuItem | null;
	onSubMenuOpenChanged?: (subMenu: RefineDropdownSubMenuItem, isOpen: boolean) => void;
}) {
	return (
		<DropdownItemGroup>
			{items.map((item) => (
				<Item
					key={item.title.id}
					item={item}
					onItemClick={onItemClick}
					currentOpenedSubMenu={currentOpenedSubMenu}
					onSubMenuOpenChanged={onSubMenuOpenChanged}
				/>
			))}
		</DropdownItemGroup>
	);
}

function Item({
	item,
	onItemClick,
	currentOpenedSubMenu,
	onSubMenuOpenChanged,
}: {
	item: RefineDropdownBaseItem;
	onItemClick: () => void;
	currentOpenedSubMenu?: RefineDropdownSubMenuItem | null;
	onSubMenuOpenChanged?: (subMenu: RefineDropdownSubMenuItem, isOpen: boolean) => void;
}) {
	const { formatMessage } = useIntl();

	if ('children' in item && item.children) {
		const handleOpenChange = ({ isOpen }: { isOpen: boolean }) => {
			onSubMenuOpenChanged?.(item as RefineDropdownSubMenuItem, isOpen);
		};

		return (
			<DropdownMenu
				placement="right-start"
				shouldRenderToParent
				isOpen={currentOpenedSubMenu === item}
				onOpenChange={handleOpenChange}
				trigger={({ triggerRef, ...triggerProps }) => (
					<DropdownItem
						// Ignored via go/ees005
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...triggerProps}
						ref={triggerRef}
						elemBefore={item.icon}
						elemAfter={<ChevronRightIcon primaryColor={token('color.icon.subtle', '')} label="" />}
					>
						{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
						<span>{formatMessage(item.title)}</span>
					</DropdownItem>
				)}
			>
				<DropdownItemGroup>
					<RefineDropdownItems items={item.children} onItemClick={onItemClick} />
				</DropdownItemGroup>
			</DropdownMenu>
		);
	}

	const handleItemClick = () => {
		onItemClick();
		item.onClick?.();
	};

	return (
		<DropdownItem elemBefore={item.icon} onClick={handleItemClick}>
			{formatMessage(item.title)}
		</DropdownItem>
	);
}
