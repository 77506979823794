import React from 'react';

import {
	PRODUCT_HOME_ID,
	COMPANY_HUB_SETTINGS_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { COMPANY_HUB_ANALYTICS } from '@confluence/named-routes';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { CompanyHubAnalyticsPage } from '@confluence/company-hub/entry-points/CompanyHubAnalyticsPage';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import {
	CompanyHubSettingsPage,
	CompanyHubSettingsPageTabsType,
} from '@confluence/company-hub/entry-points/CompanyHubSettingsPage';
import { fg } from '@confluence/feature-gating';

export const CompanyHubAnalyticsRoute = () => {
	const spaceKey = getCompanyHubSpaceKey();
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate();

	if (shouldShowPremiumUpsell) {
		return <CompanyHomeBuilderUpsellPage featureName="companyHub" />;
	}

	if (fg('company_hub_consolidated_settings')) {
		return <CompanyHubSettingsPage tab={CompanyHubSettingsPageTabsType.ANALYTICS} />;
	}

	return <CompanyHubAnalyticsPage spaceKey={spaceKey} />;
};

CompanyHubAnalyticsRoute.NAVIGATION_PARAMS = () => {
	const spaceKey = getCompanyHubSpaceKey();
	const navView =
		fg('company_hub_consolidated_settings') && fg('confluence_nav_4')
			? COMPANY_HUB_SETTINGS_ID
			: PRODUCT_HOME_ID;

	return {
		Screen: {
			screenEvent: {
				name: 'companyHubAnalytics',
			},
			pageState: {
				routeName: COMPANY_HUB_ANALYTICS.name,
				spaceKey,
			},
		},
		MainLayout: {
			navView,
		},
	};
};
