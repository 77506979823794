import React from 'react';
import type { FC, ReactNode } from 'react';

import { Box, Text, xcss } from '@atlaskit/primitives';

import { useIsDisabledContext } from './IsDisabledContext';

type ConfigPanelSubheadingProps = {
	children: ReactNode;
};

const configPanelSubheadingStyles = xcss({
	color: 'color.text.subtlest',
});

const configPanelSubheadingDisabledStyles = xcss({
	color: 'color.text.disabled',
	':hover': {
		cursor: 'not-allowed',
	},
});

export const ConfigPanelSubheading: FC<ConfigPanelSubheadingProps> = ({ children }) => {
	const isDisabled = useIsDisabledContext();

	return (
		<Box xcss={[configPanelSubheadingStyles, isDisabled && configPanelSubheadingDisabledStyles]}>
			<Text size="small" weight="bold" color="inherit">
				{children}
			</Text>
		</Box>
	);
};
