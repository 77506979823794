import { LoadableAfterPaint } from '@confluence/loadable';

export const DefaultPermsPublicLinksSettings = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-DefaultPermsPublicLinksTogglePortal" */ '../src/default-space-perms/PublicLinkSettings'
			)
		).PublicLinkSettings,
	name: 'PublicLinksSettings',
});
