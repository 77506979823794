import { createContext, useContext } from 'react';

type AgentOverriderContext = {
	agentDefaultIdOverride?: string;
	agentIdFiltersOverride?: string[];
};

export interface AgentsConfig {
	/**
	 * filter agents based on the agent id that are available to the user.
	 */
	filter?: string[];
	/**
	 * default agent id to be selected.
	 */
	defaultAgentId?: string;
}

export const agentOverrider = createContext<AgentOverriderContext>({});
export const useAgentOverrider = () => useContext(agentOverrider);
export const AgentOverriderProvider = agentOverrider.Provider;

export { getOverridenAgents } from './utils';

export const createAgentsConfig = (config?: Partial<AgentsConfig>): AgentOverriderContext => ({
	agentDefaultIdOverride: config?.defaultAgentId,
	agentIdFiltersOverride: config?.filter,
});
