import type { CustomPlugin } from '@atlassian/browser-metrics/types';

import { getUniquePageLoadId } from '@confluence/unique-page-load-id';
import { fg } from '@confluence/feature-gating';

import { getCLS, type CLSData } from './lighthouse-metrics/cls';
import { getTBT } from './lighthouse-metrics/tbt';
import { getINP } from './lighthouse-metrics/inp';
import { EntriesBuffer } from './lighthouse-metrics/utils/buffer';
import { PerformanceObserverEntryTypes } from './lighthouse-metrics/const';
import { delay } from './lighthouse-metrics';

const EXTEND_STOP_TIME_MS = 8000;

export const webVitalsEventPlugin: CustomPlugin = async ({ start, stop }) => {
	const webVitalsAttributes: {
		[key: string]: string | number | CLSData['nodeCounts'];
	} = {
		uuid: getUniquePageLoadId().uuid ?? '',
	};

	// wait for 8 seconds to calculate CLS to account for macros causing page layout shifts
	await delay(EXTEND_STOP_TIME_MS);
	const cls = getCLS(
		start,
		stop + EXTEND_STOP_TIME_MS, // add EXTEND_STOP_TIME_MS here so that all layout shifts after TTI are recorded
		EntriesBuffer[PerformanceObserverEntryTypes.LayoutShift],
	);

	// for slow pages, TTI is marked too early which causes TBT numbers to be inaccurate so we extend the stop time
	const tbt = getTBT(
		start,
		stop + EXTEND_STOP_TIME_MS, // add EXTEND_STOP_TIME_MS here so that all long tasks after TTI are recorded
		EntriesBuffer[PerformanceObserverEntryTypes.LongTask],
	);

	if (fg('confluence_frontend_should_measure_inp')) {
		const inp = getINP(
			start,
			stop + EXTEND_STOP_TIME_MS, // add EXTEND_STOP_TIME_MS here so that all interactions after TTI are recorded
			EntriesBuffer[PerformanceObserverEntryTypes.Event],
		);
		webVitalsAttributes['metric:inpForInitialLoad'] = inp.score;
	}

	webVitalsAttributes['metric:clsForInitialLoad'] = cls.score;
	webVitalsAttributes['metric:clsOffendersForInitialLoad'] = cls.nodeCounts;
	webVitalsAttributes['metric:tbtForInitialLoad'] = Math.round(tbt.total); // sum of blocking time for long tasks within the 'start' and 'stop' time

	return webVitalsAttributes;
};
