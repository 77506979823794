/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useRef } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import kebabCase from 'lodash/kebabCase';

import { stickySuggestionsGroupHeadingStyles, suggestionsGroupHeadingStyles } from './styles';

type ListHeadingProps = { heading: string };

/**
 * While this component is a simple heading, it's been integrated in case
 * future needs require it to be more complex.
 */
export const ListHeading = ({ heading }: ListHeadingProps) => {
	const listHeadingRef = useRef<HTMLLIElement>(null);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<li
			css={[
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values
				suggestionsGroupHeadingStyles,
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values
				stickySuggestionsGroupHeadingStyles,
			]}
			ref={listHeadingRef}
		>
			{/* Using h3, as the parent element can potentially put in a h2 heading above suggestions */}
			{/* ignore use-heading rule here as h3 style is overrided with Editor custom font token on higher level */}
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/use-heading -- Ignored via go/DSP-18766 */}
			<h3 className="list-heading-text" data-testid={`list-heading-${kebabCase(heading)}`}>
				{heading}
			</h3>
		</li>
	);
};
