import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/makeShorter';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';

import { messages } from './messages';

export const makeShorter: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.MAKE_SHORTER,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	showInRefineDropdown: true,
	intentSchemaId: 'shorten_intent_schema.json',
	actions: {
		empty: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const makeShorterWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: makeShorter,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};
