import { useCallback, useRef, useState } from 'react';

import { type ConversationStarter } from '@atlaskit/rovo-agent-components';
import {
	ExperienceName,
	useAIMateExperienceTracker,
} from '@atlassian/conversation-assistant-instrumentation';
import {
	type ApiError,
	useAssistanceService,
	type UseAssistanceServiceParams,
	useStreamingMutation,
} from '@atlassian/conversation-assistant-service';

export const useContextualConversationStartersController = (props?: {
	onInit?: () => void;
	onSuccess?: () => void;
	onError?: (error: ApiError) => void;
	assistanceServiceConfig?: UseAssistanceServiceParams;
}) => {
	const experience = useAIMateExperienceTracker();
	const assistanceService = useAssistanceService(props?.assistanceServiceConfig);
	const [didTimeOut, setDidTimeOut] = useState(false);
	const [starters, setStarters] = useState<Array<ConversationStarter | null>>(
		new Array(3).fill(null),
	);
	const timeoutRef = useRef<number | null>(null);

	const cancelTimeout = useCallback(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}, []);
	const beginTimeout = useCallback(
		(
			userDefinedStarters: Array<ConversationStarter>,
			fallbackStarters: Array<ConversationStarter>,
		) => {
			timeoutRef.current = window.setTimeout(() => {
				experience.abort({
					name: ExperienceName.FETCH_CONTEXTUAL_STARTERS,
					reason: 'timeout',
				});
				setDidTimeOut(true);
				setStarters([...userDefinedStarters, ...fallbackStarters].slice(0, 3));
			}, 2000);

			return cancelTimeout;
		},
		[cancelTimeout, experience],
	);

	const {
		mutate: getContextualStarters,
		error,
		isMutating,
	} = useStreamingMutation({
		mutationFn: async ({
			userDefinedStarters = [],
			fallbackStarters,
			locationUrl,
			agentNamedId = 'ai_mate_agent',
		}: {
			userDefinedStarters?: Array<ConversationStarter>;
			fallbackStarters: Array<ConversationStarter>;
			locationUrl: string;
			agentNamedId?: string;
		}) => {
			if (props && props.onInit) {
				props.onInit();
			}

			experience.start({
				id: ExperienceName.FETCH_CONTEXTUAL_STARTERS,
				name: ExperienceName.FETCH_CONTEXTUAL_STARTERS,
			});
			setDidTimeOut(false);
			setStarters([...userDefinedStarters, ...new Array(3).fill(null)].slice(0, 3));

			beginTimeout(userDefinedStarters, fallbackStarters);
			const result = await assistanceService.generateContextualConversationStarters({
				recipient_agent_named_id: agentNamedId,
				context: { browser_url: locationUrl },
			});
			return result.generator;
		},
		onChunkReceived: async (chunk) => {
			if (didTimeOut) {
				return;
			}
			cancelTimeout();

			switch (chunk.type) {
				case 'CONVERSATION_STARTER':
					const index = starters.indexOf(null);
					const starterAlreadyExists = starters.some(
						(starter) => starter?.message === chunk.message.text,
					);
					// If there is a null value in the starters array AND the new starter we get from the backend
					// does not already exist in the array, replace the null value with the new starter
					if (index !== -1 && !starterAlreadyExists) {
						let newStarters = [...starters];
						newStarters[index] = { message: chunk.message.text, type: 'llm-generated' };
						setStarters(newStarters);
					}
					break;
			}
		},

		onSuccess: () => {
			if (props && props.onSuccess) {
				props.onSuccess();
			}

			experience.succeed({
				name: ExperienceName.FETCH_CONTEXTUAL_STARTERS,
			});
		},
		onError: (error) => {
			if (props && props.onError) {
				props.onError(error);
			}

			experience.fail({
				name: ExperienceName.FETCH_CONTEXTUAL_STARTERS,
				error,
			});
		},
	});

	return { getContextualStarters, starters, error, cancelTimeout, isMutating };
};
