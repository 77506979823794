import { useIntl } from 'react-intl-next';

import { type EditorPluginAIProvider } from '../../../types';

export const useAIUsageDisclaimer = ({
	editorPluginAIProvider,
}: {
	editorPluginAIProvider: EditorPluginAIProvider;
}) => {
	const { formatMessage } = useIntl();
	let disclaimer;
	const { aiUsageDisclaimer } = editorPluginAIProvider;
	if (aiUsageDisclaimer) {
		disclaimer = {
			text: formatMessage(aiUsageDisclaimer.text),
			tooltip: formatMessage(aiUsageDisclaimer.tooltip),
			url: aiUsageDisclaimer.url,
		};
	}
	return disclaimer;
};
