import gql from 'graphql-tag';

export const SpaceRoleFragment = gql`
	fragment SpaceRoleFragment on SpaceRole {
		roleId
		roleDisplayName
		roleDescription
		roleType
		spacePermissionList {
			id
		}
	}
`;
