/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-no-bind */
import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import LoadingButton from '@atlaskit/button/loading-button';
import ButtonGroup from '@atlaskit/button/button-group';
import EditFilledIcon from '@atlaskit/icon/core/migration/edit--edit-filled';

import { NavigationLockout } from '@confluence/navigation-lockout';

import { i18n } from './i18n';
import { SavingBlanket } from './SavingBlanket';

type ChangeStagerContextType = {
	isEditing: boolean;
	changes: any;
	setChanges: (changes: any) => void;
	startEditing: () => void;
	// https://atlassian.slack.com/archives/CFH1TJG85/p1603470190036000
	ChangeStagerControls: FC;
};

const defaultContext: ChangeStagerContextType = {
	isEditing: false,
	// TODO: Make changes and setChanges args not be 'any'
	changes: null,
	setChanges: () => undefined,
	startEditing: () => undefined,
	ChangeStagerControls: () => null,
};

type ChangeStagerProps = {
	children: ReactNode;
	onSave?: (changes: any) => Promise<void>;
	onCancel?: () => void;
	disableEditing?: boolean;
	analyticsSource: string;
	disableSaveAndCancel?: boolean;
	getAnalyticsAttributes?: (changes: any) => Object;
};

const ChangeStagerContext = React.createContext(defaultContext);

export const useChangeStager = () => React.useContext(ChangeStagerContext);

/**
 * <Container> and <Panel>: these two make a system where one <div> (the
 * <Container>) takes the width of the widest of many potential children (the
 * <Panel>s) that can go inside it. This way, we can switch between Edit or
 * Save-and-Cancel buttons without reflowing.
 *
 * Only one <Panel> should be visible at once.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'inline-grid',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Panel = styled.div<{ isHidden: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: (props) => (props.isHidden ? 'hidden' : 'visible'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: (props) => (props.isHidden ? 'none' : 'inherit'),
	gridArea: '1 / 1',
	display: 'flex',
	justifyContent: 'flex-end',
});

export const ChangeStager: FC<ChangeStagerProps> = ({
	children,
	onSave,
	onCancel = () => {},
	disableEditing,
	analyticsSource,
	disableSaveAndCancel,
	getAnalyticsAttributes = () => ({}),
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [changes, setChanges] = useState(null);
	const hasChanges = changes !== null;

	const startEditing = () => {
		setIsEditing(true);
	};

	const ChangeStagerControls: FC = () => {
		function onEditClick() {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'changeStagerEdit',
					source: analyticsSource,
				},
			}).fire();

			startEditing();
		}

		async function onSaveClick() {
			try {
				const attributes = getAnalyticsAttributes(changes);

				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'changeStagerSave',
						source: analyticsSource,
						// We only want to track user interactions on globalPermsAnonymousTab
						attributes,
					},
				}).fire();

				setIsSaving(true);

				await onSave?.(changes);

				// If the onSave didn't reject, the save succeeded
				setIsEditing(false);
				setIsSaving(false);
				setChanges(null);
			} catch (e) {
				// We're catching any exception, but most likely the onSave callback
				// rejected, to signify that the save mutation failed.

				setIsSaving(false);
			}
		}

		function onCancelClick() {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'changeStagerCancel',
					source: analyticsSource,
				},
			}).fire();

			setIsEditing(false);
			setChanges(null);

			onCancel();
		}

		return (
			<Container>
				<Panel isHidden={isEditing} data-testid="changestager.buttons-panel">
					<ButtonGroup>
						<Button
							iconBefore={<EditFilledIcon label="" LEGACY_size="small" />}
							onClick={onEditClick}
							isDisabled={Boolean(disableEditing)}
						>
							<FormattedMessage {...i18n.editButtonLabel} />
						</Button>
					</ButtonGroup>
				</Panel>
				<Panel isHidden={!isEditing}>
					<ButtonGroup>
						<Button
							appearance="subtle"
							isDisabled={disableSaveAndCancel}
							onClick={() => onCancelClick()}
						>
							<FormattedMessage {...i18n.cancelButtonLabel} />
						</Button>
						<LoadingButton
							appearance={isSaving ? undefined : 'primary'}
							isDisabled={disableSaveAndCancel ?? !hasChanges}
							onClick={() => {
								void onSaveClick();
							}}
							isLoading={isSaving}
						>
							<FormattedMessage {...i18n.saveButtonLabel} />
						</LoadingButton>
					</ButtonGroup>
				</Panel>
			</Container>
		);
	};

	return (
		<SavingBlanket isSaving={isSaving}>
			{hasChanges ? <NavigationLockout /> : null}
			<ChangeStagerContext.Provider
				value={{
					isEditing,
					changes,
					setChanges:
						// Various lockouts should have prevented interactivity during a
						// save, but just to make absolutely sure...
						isSaving ? () => {} : setChanges,
					startEditing,
					ChangeStagerControls,
				}}
			>
				{children}
			</ChangeStagerContext.Provider>
		</SavingBlanket>
	);
};
