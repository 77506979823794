import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';
import { token } from '@atlaskit/tokens';

const i18n = defineMessages({
	actionSelectionHeader: {
		id: 'bulk-permissions.space.action-selection-header',
		defaultMessage: 'Action to perform',
		description: 'Header text of the action selection section',
	},
	actionSelectionAdd: {
		id: 'bulk-permissions.space.action-selection-add',
		defaultMessage: 'Add or change access',
		description: 'Option to add or change access to all selected spaces',
	},
	actionSelectionRemove: {
		id: 'bulk-permissions.space.action-selection-remove',
		defaultMessage: 'Remove access',
		description: 'Option to remove access from all selected spaces',
	},
});

export enum ActionSelection {
	AddOrChangeAccess = 'add-or-change-access',
	RemoveAccess = 'remove-access',
}

const actionSelectionHeaderStyle = xcss({
	font: token('font.body.large'),
	fontWeight: token('font.weight.medium'),
	paddingBottom: 'space.100',
	paddingTop: 'space.300',
});

type ActionSelectionRadioButtonProps = {
	actionSelection: ActionSelection;
	onRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const ActionSelectionRadioButtons = ({
	actionSelection,
	onRadioChange,
}: ActionSelectionRadioButtonProps) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Box xcss={actionSelectionHeaderStyle}>{formatMessage(i18n.actionSelectionHeader)}</Box>
			<Box>
				<Radio
					value={ActionSelection.AddOrChangeAccess}
					onChange={onRadioChange}
					isChecked={actionSelection === ActionSelection.AddOrChangeAccess}
					name="action-selection"
					label={formatMessage(i18n.actionSelectionAdd)}
				/>
				<Radio
					value={ActionSelection.RemoveAccess}
					onChange={onRadioChange}
					isChecked={actionSelection === ActionSelection.RemoveAccess}
					name="action-selection"
					label={formatMessage(i18n.actionSelectionRemove)}
				/>
			</Box>
		</>
	);
};
