import type { ComponentProps, FC } from 'react';
import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreHorizontalIcon from '@atlaskit/icon/utility/migration/show-more-horizontal--editor-more';

import { RoleAssignmentPrincipalType } from '@confluence/space-roles';

import { RemoveGroupModal } from '../dialogs/RemoveGroupModal';
import { UpdateGroupModal } from '../dialogs/UpdateGroupModal';

type UpdateGroupModalProps = ComponentProps<typeof UpdateGroupModal>;

// These fields are not needed for groups but are required for a reusable child component.
type GroupSpecificPrincipal = Omit<
	UpdateGroupModalProps['principalRoleAssignment']['principal'],
	'email' | 'avatarUrl'
>;

type TableActionsMenuProps = Pick<
	UpdateGroupModalProps,
	'spaceRoleOptions' | 'allPermissions' | 'refreshTableData'
> & {
	principalRoleAssignment: Omit<UpdateGroupModalProps['principalRoleAssignment'], 'principal'> & {
		principal: GroupSpecificPrincipal;
	};
} & {
	onMenuOptionClick?: (dialogType: OpenDialogType) => void;
	updateRole: (
		principalId: string,
		roleId: string,
		roleDisplayName: string,
		rolePermissions: string[],
	) => Promise<void>;
	updateCachedPermissions: (principalId: string, permissions: string[]) => void;
};

type OpenDialogType = 'update' | 'remove' | 'none';

const i18n = defineMessages({
	removeFromSpace: {
		id: 'default-space-permissions.table.table-actions-menu.remove-default',
		defaultMessage: 'Remove default group',
		description:
			'Option in Table Actions Menu to remove the principal from the default space permissions',
	},
	removeAccessClassFromSpace: {
		id: 'default-space-permissions.table.table-actions-menu.remove-access-class-from-defaults.non-final',
		defaultMessage: 'Remove access',
		description:
			'Option in Table Actions Menu to remove the access class from the default space permissions',
	},
	managePermissions: {
		id: 'default-space-permissions.table.table-actions-menu.manage-permissions',
		defaultMessage: 'Manage access',
		description: 'Option in Table Actions Menu to manage the permissions of the principal',
	},
	learnMoreAboutRoles: {
		id: 'default-space-permissions.table.table-actions-menu.learn-more-about-roles',
		defaultMessage: 'Learn more about roles',
		description: 'Option in Table Actions Menu to learn more about roles',
	},
});

//During EAP we cannot make SAC links for this specific purpose; therefore we will be using public links created by design to allow EAP users to access help documentation.
const LEARN_MORE_ROLES =
	'https://confluence-permissions-help.atlassian.net/wiki/external/M2JiZjYzMTAwNDZjNDNiNzg4YmY0YWEwY2FkOTY0NWM';

export const TableActionsMenu: FC<TableActionsMenuProps> = ({
	principalRoleAssignment,
	spaceRoleOptions,
	allPermissions,
	updateRole,
	updateCachedPermissions,
	refreshTableData,
}: TableActionsMenuProps) => {
	const { formatMessage } = useIntl();

	const [openDialog, setOpenDialog] = React.useState<OpenDialogType>('none');

	const onDialogClose = useCallback(() => {
		setOpenDialog('none');
	}, []);

	const isDialogOpen = (dialogType: OpenDialogType) => openDialog === dialogType;
	const onDropdownOptionClick = (dialogType: OpenDialogType) => {
		setOpenDialog(dialogType);
	};

	return (
		<>
			<>
				{isDialogOpen('remove') && (
					<RemoveGroupModal
						principalId={principalRoleAssignment.assignmentId}
						onClose={onDialogClose}
						hasRole={principalRoleAssignment.roleId !== null}
						principalType={principalRoleAssignment.principal.type}
						currentPermissions={principalRoleAssignment.permissions}
					/>
				)}
				{isDialogOpen('update') && (
					<UpdateGroupModal
						principalRoleAssignment={principalRoleAssignment}
						spaceRoleOptions={spaceRoleOptions}
						allPermissions={allPermissions}
						updateRole={updateRole}
						updateCachedPermissions={updateCachedPermissions}
						onClose={onDialogClose}
						refreshTableData={refreshTableData}
					/>
				)}
			</>
			<DropdownMenu<HTMLButtonElement>
				onOpenChange={(isOpen) => isOpen}
				trigger={({ triggerRef, ...props }) => (
					<IconButton
						{...props}
						icon={ShowMoreHorizontalIcon}
						label="more"
						ref={triggerRef}
						testId="table-actions-menu"
					/>
				)}
				shouldRenderToParent
			>
				<DropdownItemGroup>
					<DropdownItem onClick={() => onDropdownOptionClick('remove')}>
						{principalRoleAssignment.principal.type === RoleAssignmentPrincipalType.ACCESS_CLASS
							? formatMessage(i18n.removeAccessClassFromSpace)
							: formatMessage(i18n.removeFromSpace)}
					</DropdownItem>
					<DropdownItem onClick={() => onDropdownOptionClick('update')}>
						{formatMessage(i18n.managePermissions)}
					</DropdownItem>
					<DropdownItem href={LEARN_MORE_ROLES}>
						{formatMessage(i18n.learnMoreAboutRoles)}
					</DropdownItem>
				</DropdownItemGroup>
			</DropdownMenu>
		</>
	);
};
