import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';
import { fg } from '@confluence/feature-gating';

export function getMultiMacroQueryBlocklistFF() {
	const blocklist = StatsigConfigurations.getStringList(
		'confluence_frontend_macros_query_blocklist',
		[
			'include',
			'jirachart',
			'jiraroadmap',
			'ui-button',
			'contentbylabel',
			'excerpt-include',
			'tasks-report-macro',
			'blog-posts',
			'section',
			'table-filter',
			'children',
			'pagetree',
			'detailssummary',
			'decisionreport',
		],
	);

	return String(blocklist).split(',');
}

export function canUseMultiMacroQuery(extensionKey: string | undefined) {
	return (
		fg('confluence_frontend_use_multiple_macros_query') &&
		!getMultiMacroQueryBlocklistFF().includes(extensionKey || '')
	);
}
