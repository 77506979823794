import React, { useCallback, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { useMutation } from '@apollo/react-hooks';

import Button from '@atlaskit/button/new';
import ButtonGroup from '@atlaskit/button/button-group';
import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Text } from '@atlaskit/primitives';

import { useResponseHandler, type HandlerArgs } from '@confluence/space-roles/entry-points/graphql';
import { RoleAssignmentPrincipalType } from '@confluence/space-roles/entry-points/space-role-types';
import { DEFAULT_RBAC_EDIT_EXPERIENCE } from '@confluence/experience-tracker';

import { DeleteDefaultSpaceRoleAssignments } from '../graphql/DeleteDefaultSpaceRoleAssignments.graphql';
import type {
	DeleteDefaultSpaceRoleAssignmentsVariables,
	DeleteDefaultSpaceRoleAssignments as DeleteDefaultSpaceRoleAssignmentsType,
} from '../graphql/__types__/DeleteDefaultSpaceRoleAssignments';
import { DefaultSpaceRoleAssignments } from '../graphql/DefaultSpaceRoleAssignments.graphql';
import { useSetLegacyPermissions } from '../graphql/hooks/useSetLegacyPermissions';

const i18n = defineMessages({
	title: {
		id: 'default-space-permissions.remove-group-modal.title',
		defaultMessage: 'Remove default group?',
		description: 'Title of the remove group modal which is a dialog',
	},
	titleAccessClass: {
		id: 'default-space-permissions.remove-group-modal.title-access-class',
		defaultMessage: 'Remove default access for user class?',
		description:
			'Title of the remove group modal which is a dialog and will confirm removing a set of users',
	},
	body: {
		id: 'default-space-permissions.remove-group-modal.body.non-final',
		defaultMessage:
			'This will remove them from the defaults but they might still have access to spaces.',
		description: 'Body of the remove group modal which is a dialog',
	},
	bodyAccessClass: {
		id: 'default-space-permissions.remove-group-modal.body-access-class',
		defaultMessage:
			'New spaces created won’t automatically allow any general access for this user class.',
		description:
			'Body of the remove group modal which is a dialog and will confirm removing a set of users',
	},
	cancel: {
		id: 'default-space-permissions.remove-group-modal.cancel.non-final',
		defaultMessage: 'Cancel',
		description: 'Cancel button confirmation text within a dialog',
	},
	remove: {
		id: 'default-space-permissions.remove-group-modal.remove.non-final',
		defaultMessage: 'Remove',
		description: 'Remove button confirmation text within a dialog',
	},
	removeAccessClassSuccessButton: {
		id: 'default-space-permissions.remove-group-modal.remove-access-class-success-button',
		defaultMessage: 'Remove default access',
		description:
			'Remove button confirmation text within a dialog and will confirm removing a set of users',
	},
	removeSuccess: {
		id: 'default-space-permissions.remove-group-modal.remove-success',
		defaultMessage: 'Default group removed',
		description: 'Success message when group is removed successfully within a dialog',
	},
	removeAccessClassSuccess: {
		id: 'default-space-permissions.remove-group-modal.remove-access-class-success',
		defaultMessage: 'General access updated',
		description: 'Success message when access class is removed successfully within a dialog',
	},
	removeLastAdminError: {
		id: 'default-space-permissions.remove-group-modal.remove-last-admin-error',
		defaultMessage: 'The last admin cannot be removed from the default configurations.',
		description:
			'Error message when trying to remove the last admin from default space permissions that will show in an error flag',
	},
});

export const RemoveGroupModal = ({
	onClose,
	principalId,
	principalType,
	hasRole,
	currentPermissions,
}: {
	onClose: () => void;
	principalId: string;
	principalType: RoleAssignmentPrincipalType;
	hasRole: boolean;
	currentPermissions: string[];
}) => {
	const isAccessClass = principalType === RoleAssignmentPrincipalType.ACCESS_CLASS;
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { handleResponse, startExperience, abortExperience } = useResponseHandler({
		experience: DEFAULT_RBAC_EDIT_EXPERIENCE,
		successMessage: isAccessClass
			? formatMessage(i18n.removeAccessClassSuccess)
			: formatMessage(i18n.removeSuccess),
	});

	const handleAssignmentDeletion = useCallback(
		({ error, errors }: HandlerArgs) => {
			handleResponse({
				error,
				errors,
				expectedErrors: [
					{
						400: formatMessage(i18n.removeLastAdminError),
					},
				],
			});
			onClose();
		},
		[handleResponse, onClose, formatMessage],
	);

	const { isPermissionAssignmentLoading, setLegacyPermissions } = useSetLegacyPermissions({
		onResponse: onClose,
		refetchQueries: [
			{
				query: DefaultSpaceRoleAssignments,
			},
		],
	});

	const [deleteAssignments, { loading }] = useMutation<
		DeleteDefaultSpaceRoleAssignmentsType,
		DeleteDefaultSpaceRoleAssignmentsVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DeleteDefaultSpaceRoleAssignments,
		{
			onCompleted: (response) =>
				handleAssignmentDeletion({ errors: response.deleteDefaultSpaceRoleAssignments?.errors }),
			refetchQueries: [
				{
					query: DefaultSpaceRoleAssignments,
				},
			],
			onError: (error) => handleAssignmentDeletion({ error }),
		},
	);

	const onCancel = useCallback(() => {
		abortExperience();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'cancel',
				source: 'default-space-permissions-removePrincipalModal',
			},
		}).fire();
		onClose();
	}, [onClose, createAnalyticsEvent, abortExperience]);

	const onConfirm = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'remove',
				source: 'default-space-permissions-removePrincipalModal',
			},
		}).fire();

		if (hasRole) {
			void deleteAssignments({
				variables: {
					input: {
						principalsList: [
							{
								principalId,
								principalType,
							},
						],
					},
				},
			});
		} else {
			// If a principal doesn't have a role, we need to remove their access through
			// granular space permissions.
			// https://atlassian.slack.com/archives/C06MN81H0R2/p1726504675742049?thread_ts=1726504390.964249&cid=C06MN81H0R2
			void setLegacyPermissions({
				variables: {
					input: {
						subjectPermissionDeltasListV2: [
							{
								principalInput: {
									principalId,
									principalType,
								},
								permissionsToRemove: currentPermissions,
								permissionsToAdd: [],
							},
						],
					},
				},
			});
		}
	}, [
		deleteAssignments,
		setLegacyPermissions,
		principalId,
		principalType,
		hasRole,
		createAnalyticsEvent,
		currentPermissions,
	]);

	useEffect(
		() => {
			startExperience();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<ModalTransition>
			<ModalDialog onClose={onCancel} width="medium">
				<ModalHeader>
					<ModalTitle>
						{formatMessage(isAccessClass ? i18n.titleAccessClass : i18n.title)}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Text>{formatMessage(isAccessClass ? i18n.bodyAccessClass : i18n.body)}</Text>
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<Button onClick={onCancel} appearance="subtle">
							{formatMessage(i18n.cancel)}
						</Button>
						<Button
							onClick={onConfirm}
							isLoading={loading || isPermissionAssignmentLoading}
							appearance="primary"
						>
							{formatMessage(isAccessClass ? i18n.removeAccessClassSuccessButton : i18n.remove)}
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</ModalDialog>
		</ModalTransition>
	);
};
