/**
 * This function will return the next suggestion to highlight based on the
 * direction requested.
 *
 * It determines the next suggestion based off the position of the current
 * suggestion in the suggestions list and the direction requested.
 *
 * If the current suggestion is the first item in the list and the requested
 * direction is up, it will wrap around and return the last item. Similarly,
 * requesting down from the last item will wrap return the first item.
 *
 * - If the current suggestion is not found in the suggestions list, it will
 * default to the first suggestion.
 * - If the suggestions list is empty, it will return an empty string.
 * - If the direction is not recognised, it will throw an error.
 *
 * @param suggestions An array of suggestion values to cycle through.
 * @param current Value of the current suggestion highlighted.
 * @param direction Are we moving up or down the list of suggestions?
 * @returns string The next suggestion value to highlight, depending on the direction requested.
 */
export const getNextSuggestion = (
	suggestions: string[],
	current: string,
	direction: 'ArrowUp' | 'ArrowDown',
): string => {
	const currentIndex = suggestions.indexOf(current);
	const { length } = suggestions;

	// Got nothing to do
	if (length === 0) {
		return '';
	}

	if (direction === 'ArrowUp') {
		/**
     * Visualisation of what the scenarios are covering
     * - Left = out of bounds negative indexes
     * - Right = out of bounds positive indexes
     * - 0-9 = example of valid suggestion indexes
     *       Array items
     * -----[0123456789]++++++
     * <----DCB------>A-->
             Scenarios
     */
		// A: Out of bounds
		if (currentIndex > length - 1) {
			return suggestions[0];
		}

		// B: Move up the list
		else if (currentIndex > 0) {
			return suggestions[currentIndex - 1];
		}

		// C: First item loops back to end of list
		else if (currentIndex === 0) {
			return suggestions[length - 1];
		}

		// D: Default to first item
		else {
			return suggestions[0];
		}
	} else if (direction === 'ArrowDown') {
		/**
     * Visualisation of what the scenarios are covering
     * - Left = out of bounds negative indexes
     * - Right = out of bounds positive indexes
     * - 0-9 = example of valid suggestion indexes
     *       Array items
     * -----[0123456789]++++++
     * <----BC------->A-->
             Scenarios
     */
		// A: Last item or out of bounds
		if (currentIndex >= length - 1) {
			return suggestions[0];
		}

		// B: Default to first item
		else if (currentIndex < 0) {
			return suggestions[0];
		}

		// C: Move down the list
		else if (currentIndex < length - 1) {
			return suggestions[currentIndex + 1];
		}
	} else {
		throw new Error('Unknown direction');
	}

	return '';
};
