/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import RetryIcon from '@atlaskit/icon/core/retry';

import messages from './messages';

type Props = {
	onClick: (event: React.MouseEvent) => void;
	tabIndex?: number;
	appearance?: IconButtonProps['appearance'];
	spacing?: IconButtonProps['spacing'];
	isDisabled?: boolean;
};

export const RetryIconButton = ({
	onClick,
	tabIndex,
	appearance,
	spacing,
	isDisabled = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const tooltip = formatMessage(messages.retryPrompt);

	const tooltipOptions: IconButtonProps['tooltip'] = {
		position: 'top',
		hideTooltipOnClick: true,
		content: tooltip,
	};

	return (
		<IconButton
			testId="retry-prompt-button"
			type="button"
			onClick={onClick}
			icon={RetryIcon}
			label={tooltip}
			appearance={appearance}
			spacing={spacing}
			tabIndex={tabIndex}
			isTooltipDisabled={false}
			tooltip={tooltipOptions}
			isDisabled={isDisabled}
		/>
	);
};
