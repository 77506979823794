/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import InfoIcon from '@atlaskit/icon/glyph/info';
import { Anchor, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import messages from '../messages';

import { CustomDisclaimer, type CustomDisclaimerProps } from './CustomDisclaimer';

const linkStyles = xcss({
	font: 'font.body.small',
	color: 'color.text.subtlest',
	textDecoration: 'none',

	':visited': {
		color: 'color.text.subtlest',
	},

	':hover': {
		color: 'color.text.subtlest',
		textDecoration: 'underline',
	},
});

export interface FooterDisclaimerProps {
	/**
	 * Custom disclaimer to be displayed in the footer.
	 *
	 * @todo: Are we really need custom disclaimer?
	 *        We should check if it's used in any product and remove it support if not.
	 */
	disclaimer: CustomDisclaimerProps['disclaimer'] | undefined;
	/** The appearance of main footer. */
	appearance: 'small' | 'medium' | 'large';
}

export function FooterDisclaimer({ disclaimer, appearance }: FooterDisclaimerProps) {
	const { formatMessage } = useIntl();

	return disclaimer ? (
		<CustomDisclaimer disclaimer={disclaimer} />
	) : (
		<Inline space="space.050" alignBlock="center">
			<InfoIcon
				label={formatMessage(messages.infoIconLabel)}
				primaryColor={token('color.text.subtlest')}
				size="small"
			/>

			<Anchor
				color="color.text.subtlest"
				href="https://www.atlassian.com/trust/atlassian-intelligence"
				xcss={linkStyles}
			>
				{formatMessage(
					appearance === 'large' ? messages.disclaimerText : messages.disclaimerTextShort,
				)}
			</Anchor>
		</Inline>
	);
}
