import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, Stack, xcss, Text } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import Button, { LinkButton } from '@atlaskit/button/new';

import type { SpaceRole } from '@confluence/space-roles/entry-points/space-role-types';
import { usePermissionsSummary } from '@confluence/space-roles/entry-points/TableRow';

const i18n = defineMessages({
	manageAccessLabel: {
		id: 'default-space-permissions.popup.manage-permissions-label',
		defaultMessage: 'Manage access',
		description:
			'Label for the manage permissions button in the popup that is shown to an admin on popup',
	},
	managePermissionsLabel: {
		id: 'default-space-permissions.popup.manage-roles-label',
		defaultMessage: 'Manage permissions',
		description:
			'Label for the view permissions button in the popup that is shown to an admin on popup',
	},
	customAccessLearnMore: {
		id: 'default-space-permissions.popup.custom-access-learn-more',
		defaultMessage: 'Learn more',
		description: 'Link to learn more about custom access that is shown to an admin on popup',
	},
});

const contentStyle = xcss({
	width: '315px',
	padding: 'space.300',
});

const headerStyle = xcss({
	marginBottom: 'space.100',
});

const buttonStyle = xcss({
	paddingTop: 'space.150',
	display: 'flex',
	alignItems: 'center',
});

//During EAP we cannot make SAC links for this specific purpose; therefore we will be using public links created by design to allow EAP users to access help documentation.
const LEARN_MORE_CUSTOM_ACCESS =
	'https://confluence-permissions-help.atlassian.net/wiki/external/MTllYzg0YjViNDUzNDMxYjgxZGM4Y2IwNDg4NmE4YzI';

export const PopupContent = ({
	onCtaClick,
	roleId,
	availableRoles,
	permissions,
}: {
	onCtaClick: () => void;
	roleId: string | null;
	availableRoles: SpaceRole[];
	permissions: string[];
}) => {
	const { formatMessage } = useIntl();

	const { heading, description } = usePermissionsSummary({
		roleId,
		permissions,
		roleLookup: availableRoles,
		isDefaultsView: true,
	});

	return (
		<Stack xcss={contentStyle}>
			<Box xcss={headerStyle}>
				<Heading size="small">{heading}</Heading>
			</Box>
			<Text>{description}</Text>
			<Box xcss={buttonStyle}>
				<Button onClick={onCtaClick}>
					{formatMessage(Boolean(roleId) ? i18n.manageAccessLabel : i18n.managePermissionsLabel)}
				</Button>
				<LinkButton appearance="subtle" href={LEARN_MORE_CUSTOM_ACCESS}>
					{formatMessage(i18n.customAccessLearnMore)}
				</LinkButton>
			</Box>
		</Stack>
	);
};
