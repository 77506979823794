import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	actionItemsPageTitle: {
		id: 'fabric.editor.ai.ai-panels.actionItemsPageTitle',
		defaultMessage: `Action items panel (Beta)`,
		description: 'Title for AI action items panel',
	},
	actionItemsPageDescription: {
		id: 'fabric.editor.ai.ai-panels.actionItemsPageDescription',
		defaultMessage: `Use Atlassian Intelligence to find action items and display in a panel`,
		description: 'Description for AI action items panel',
	},
});
