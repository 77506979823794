import uuid from 'uuid/v4';

import type { NodeType, Node as PMNode, Schema } from '@atlaskit/editor-prosemirror/model';
import type {
	EditorState,
	ReadonlyTransaction,
	Transaction,
} from '@atlaskit/editor-prosemirror/state';
import { Mapping, ReplaceAroundStep, ReplaceStep } from '@atlaskit/editor-prosemirror/transform';

import type { IgnoredRange, ParagraphChunk } from '../../utils/diff-match-patch/utils';

import type { Recommendation } from './api';
import type { AIProactivePluginState, ProactiveAIBlock, SettingDisplayType } from './states';
import {
	getSettingsWithValues,
	syncFilteredRecommendations,
	type returnSettingType,
} from './suggestion-setting-map';

/**
 * This is a generalised collection of explicit included node types which proactive AI will operate on.
 * Types not listed here will be excluded by default.
 * If you want proactive ai to look inside a node then it's type must be listed here, otherwise the node will be skipped.
 * This is to avoid unneccessary searching through nodes which shouldn't have spelling + grammar run on them.
 */
export const getIncludedProactiveAINodeTypes = ({ schema: { nodes } }: EditorState) =>
	new Set<NodeType>([nodes.bulletList, nodes.listItem, nodes.orderedList, nodes.paragraph]);

/**
 * This is a refined collection of node types which will be used to determine whether proactive ai blocks will be created
 * to keep track of the state nodes which have proactive AI run over them.
 */
export const getProactiveAIBlockNodeTypes = ({ schema: { nodes } }: EditorState) =>
	new Set<NodeType>([nodes.bulletList, nodes.orderedList, nodes.paragraph]);

const getContextualNodeTypes = ({ nodes }: Schema) =>
	new Set<NodeType>([nodes.heading, nodes.taskItem, nodes.decisionItem]);

const getContextualParentNodeTypes = ({ nodes }: Schema) =>
	new Set<NodeType>([nodes.listItem, nodes.tableHeader, nodes.blockquote]);

export const isFullStopOmittedInContainerType = (schema: Schema, containerType: NodeType) => {
	if (
		getContextualNodeTypes(schema).has(containerType) ||
		getContextualParentNodeTypes(schema).has(containerType)
	) {
		return true;
	}
	return false;
};

export const getContainerType = (schema: Schema, node: PMNode, parent: PMNode | null) => {
	if (getContextualNodeTypes(schema).has(node.type)) {
		return node.type;
	}

	if (
		node.type === schema.nodes.paragraph &&
		parent &&
		getContextualParentNodeTypes(schema).has(parent.type)
	) {
		return parent.type;
	}

	return node.type;
};

/**
 * This is a collection of mark types which proactive AI will ignore suggestions for.
 */
const getIgnoredMarkRangesPredicate =
	({ schema: { marks } }: EditorState) =>
	(node: PMNode) => {
		if (node.isText) {
			if (node.marks.some((mark) => mark.type === marks.code)) {
				return true;
			}
			if (node.marks.some((mark) => mark.type === marks.link && node.text === mark.attrs?.href)) {
				return true;
			}
		}
		return false;
	};

export function updateAvailableRecommendationIds(
	blocks: ProactiveAIBlock[] | undefined,
	pluginState: AIProactivePluginState,
) {
	return new Set<string>(
		(blocks ?? pluginState.proactiveAIBlocks).reduce<string[]>(
			(acc, block) => acc.concat(block.recommendations?.map((r) => r.id) ?? []),
			[],
		),
	);
}

export function findProactiveAIBlockToUpdate(
	tr: Transaction | ReadonlyTransaction,
	pluginState: AIProactivePluginState,
	newEditorState: EditorState,
) {
	const { proactiveAIBlocks, isProactiveEnabled } = pluginState;
	const shouldIgnoreChanges =
		!isProactiveEnabled ||
		tr.getMeta('isRemote') ||
		tr.getMeta('replaceDocument') ||
		tr.getMeta('suggestionAccepted') ||
		tr.getMeta('isAiContentTransformation');
	/**
	 * Don't register block with Document SG cheker when;
	 * - Realtime S+G check is enabled
	 * - AI generated content.
	 * In all other cases we want to register block with Document SG Checker.
	 */
	const invalidatedForDocChecker =
		tr.getMeta('replaceDocument') ||
		(!isProactiveEnabled &&
			!tr.getMeta('suggestionAccepted') &&
			!tr.getMeta('isAiContentTransformation'));

	const nodeTypes = getProactiveAIBlockNodeTypes(newEditorState);
	const includedNodeTypes = getIncludedProactiveAINodeTypes(newEditorState);
	const ignoredMarkRangesPredicate = getIgnoredMarkRangesPredicate(newEditorState);

	/**
	 * Transaction is collection of different steps.
	 * Each step can update different part of the doc.
	 * So we need to go through each step to find block updated/added by each step.
	 * For that, we need to look at doc after apply step.
	 *
	 * That's why here we are creating list of docs created after applying each step.
	 * tr.docs contain list that has doc created before each step.
	 * tr.doc is final version of doc created after applying last step.
	 *
	 * We need doc created after applying step.
	 * So for doc after applying first step (step 0) will be trDocsWithFinalDoc[1].
	 */
	const trDocsWithFinalDoc = tr.docs.concat([tr.doc]);
	const replaceStepsWithDocs = tr.steps.reduce<
		Array<{ step: ReplaceStep | ReplaceAroundStep; doc: PMNode; prevDoc: PMNode }>
	>((acc, step, index) => {
		if (step instanceof ReplaceStep || step instanceof ReplaceAroundStep) {
			acc.push({ step, doc: trDocsWithFinalDoc[index + 1], prevDoc: trDocsWithFinalDoc[index] });
		}
		return acc;
	}, []);

	if (proactiveAIBlocks && replaceStepsWithDocs.length > 0) {
		let updatedProactiveAIBlock: Array<ProactiveAIBlock> = Array.from(proactiveAIBlocks);

		/**
		 * Iterate through each step and step doc to find blocks to replace or add new blocks.
		 */
		replaceStepsWithDocs.forEach(({ step, doc, prevDoc }) => {
			const newProactiveAIBlocks: Array<ProactiveAIBlock> = [];
			const nodeMap: WeakMap<PMNode, ProactiveAIBlock> = new WeakMap();
			const mapping = new Mapping();
			const stepMap = step.getMap();
			mapping.appendMap(stepMap);

			/**
			 * First find new nodes that needs to be checked against
			 * proactive AI prompt.
			 */
			stepMap.forEach((oldStart, oldEnd, newStart, newEnd) => {
				doc.nodesBetween(newStart, Math.min(newEnd, doc.content.size), (node, pos, parent) => {
					if (
						nodeTypes.has(node.type) &&
						/**
						 * When new table is created, tableCell nodes created for first row (non header one)
						 * is reused for other rows.
						 * So only checking "newProactiveAIBlock.node === node" condition is not enough.
						 * We need to reuse same node to create block (as it has been reused in all rows)
						 * 	if position is different.
						 */
						!(nodeMap.get(node)?.from === pos)
					) {
						const ignoredRanges = getIgnoredRanges({ node, ignoredMarkRangesPredicate });

						const from = pos,
							to = pos + node.nodeSize;

						const containerType = getContainerType(newEditorState.schema, node, parent);
						const newBlock = {
							id: uuid(),
							from,
							to,
							text: node.textContent,
							nodeTypeName: node.type.name,
							/**
							 * If it's remote transaction, then we don't want new blocks
							 * created from that transaction to be checked for S+G,
							 * because they must be checked where it's originated.
							 *
							 * Also when document is loaded in confluence, collab service
							 *  fetches document and fires transaction to replace whole document.
							 * In that case as well, we don't want to trigger S+G for whole document.
							 */
							invalidated: !shouldIgnoreChanges,
							invalidatedForDocChecker,
							ignoredRanges,
							containerType,
						};

						newProactiveAIBlocks.push(newBlock);
						nodeMap.set(node, newBlock);
						return false;
					}
					return includedNodeTypes.has(node.type);
				});
			});

			// What we're trying to do here is to reuse old block recommendations/state if the new block hasn't actually changed
			// The problem with the above nodesBetween scan is it will see a block as a new block if all the user did was
			// hit enter at the start/end of it. So this is attempting to recover the old block state and merge it into the new block.
			const refurbishedBlocks = newProactiveAIBlocks.map((newBlock) => {
				// Try to quickly locate "possible" existing blocks which are closely identical to the new block.
				const possibleMatch = updatedProactiveAIBlock.find(
					(block) =>
						block.nodeTypeName === newBlock.nodeTypeName &&
						((block.from === newBlock.from && block.to === newBlock.to) ||
							(mapping.map(block.from + 1) === newBlock.from + 1 &&
								mapping.map(block.to - 1) === newBlock.to - 1)) &&
						block.text === newBlock.text,
				);

				if (!possibleMatch) {
					return newBlock;
				}

				// Check for a perfect node match between old/new blocks
				const prevNode = prevDoc.nodeAt(possibleMatch.from);
				const curNode = doc.nodeAt(newBlock.from);
				if (!prevNode || !curNode || !prevNode.eq(curNode)) {
					return newBlock;
				}

				return {
					// Update the block id to the new uuid just to signal that the block has change (event a little)
					id: newBlock.id,
					// We need to take the from/to values from the new block to avoid having to remap them later.
					from: newBlock.from,
					to: newBlock.to,
					text: newBlock.text,
					nodeTypeName: newBlock.nodeTypeName,
					// We need to inherit the invalidation state from the block we're replacing, since it's possible it might be
					// waiting on a proactive check.
					invalidated: possibleMatch.invalidated,
					invalidatedForDocChecker,
					ignoredRanges: newBlock.ignoredRanges,
					containerType: newBlock.containerType,
					// We need to migrate the recommendations over from the old block, and ensure their id mapping is updated
					recommendations: possibleMatch.recommendations?.map((r) => ({
						...r,
						chunkId: newBlock.id,
						// Even though we're keeping the old recommendations, we're going to ensure their ids are updated.
						id: `${newBlock.id}_${r.rid}`,
					})),
				};
			});

			/**
			 * Now find existing range of proactiveAIBlock that needs to be
			 * replaced with new blocks found.
			 *
			 * ASSUMPTIONS:
			 * 1. We have assumed here that new proactiveAIBlocks will be in
			 *    continous range.
			 *    For example: New 4 blocks will be from positions 350 to 550.
			 *                 Let's say there are 3 blocks before 350 and
			 *                 2 blocks after 550. And there were 2 blocks in 350 to 550 before.
			 *                 That means existing Array is
			 *                 [b1, b2, b3, -- b4, b5 --, b6, b7 )], affected blocks are b4, b5.
			 *                 So they will be replaced with new 3 blocks.
			 *                 Final array will look like
			 *                 [old_b1, old_b2, old_b3, -- new_b4, new_b5, new_b6 --, old_b6, old_b7 )]
			 */
			let startIndex = -1;
			let endIndex = -1;
			for (let index = 0; index < updatedProactiveAIBlock.length; index++) {
				const block = updatedProactiveAIBlock[index];
				const blockInnerFrom = block.from + 1;
				const blockInnerTo = block.to - 1;

				// If step range is before first block then it's new blocks at the beginning of the document.
				// We know that exact placement of new blocks, so breaking loop here.
				if (index === 0 && step.from < blockInnerFrom && step.to < blockInnerTo) {
					startIndex = 0;
					endIndex = 0;
					break;
				}

				/**
				 * When step changes are across multiple nodes;
				 * we will find startIndex and endIndex here.
				 */
				if (
					(blockInnerFrom <= step.from && blockInnerTo >= step.from) ||
					(blockInnerFrom <= step.to && blockInnerTo >= step.to)
				) {
					if (startIndex === -1) {
						startIndex = index;
						endIndex = index + 1;
					} else if (startIndex > -1) {
						endIndex = index + 1;
					}
				}

				/**
				 * or if block is within step range.
				 * but when step changes are across multiple blocks, this condition will be
				 * 	true for all the middle blocks except first and last one.
				 * So can't break here.
				 */
				if (blockInnerFrom >= step.from && blockInnerTo <= step.to) {
					if (startIndex === -1) {
						startIndex = index;
						endIndex = index + 1;
					} else if (startIndex > -1) {
						endIndex = index + 1;
					}
				}

				// if range is between current and next block,
				//	thus neigher current or next block should be replaced.
				// We know that exact placement of new blocks, so breaking loop here.
				if (index < updatedProactiveAIBlock.length - 1) {
					const nextBlock = updatedProactiveAIBlock[index + 1];
					if (step.from > blockInnerTo && step.to < nextBlock.from) {
						/**
						 * We want to add new block after current block.
						 * So startIndex has to be "index + 1".
						 * Also we don't want to delete any block.
						 * So expression "endIndex - startIndex" must be 0.
						 * So setting endIndex to "index + 1".
						 */
						startIndex = index + 1;
						endIndex = index + 1;
						break;
					}
				}

				// if step range is outside last block then it's new blocks
				// This is anyway last block, so no need to break;
				if (
					index === updatedProactiveAIBlock.length - 1 &&
					step.from > blockInnerTo &&
					step.to > blockInnerTo
				) {
					startIndex = index + 1;
					endIndex = index + 1;
				}
			}

			if (startIndex > -1 && endIndex > -1) {
				updatedProactiveAIBlock.splice(startIndex, endIndex - startIndex, ...refurbishedBlocks);
			}

			updatedProactiveAIBlock = updatedProactiveAIBlock.map((block, i) => {
				// If there were new blocks and they were inserted, then we must ensure we skip over them and not attempt to
				// remap their positions.
				if (
					!!refurbishedBlocks.length &&
					i >= startIndex &&
					i < startIndex + refurbishedBlocks.length
				) {
					return block;
				}

				// Lastly update positions of blocks not affected by step with step's mapping.
				// So that when we apply next step we have updated positions.
				return updateBlockPositions(mapping, block);
			}) as ProactiveAIBlock[];
		});

		return {
			originProactiveAIBlock: proactiveAIBlocks,
			updatedProactiveAIBlock,
		};
	}

	return {
		originProactiveAIBlock: proactiveAIBlocks,
		updatedProactiveAIBlock: proactiveAIBlocks,
	};
}

export function updateBlockPositions(mapping: Mapping, block: ProactiveAIBlock): ProactiveAIBlock {
	return {
		id: block.id,
		text: block.text,
		ignoredRanges: block.ignoredRanges,
		nodeTypeName: block.nodeTypeName,
		invalidated: block.invalidated,
		invalidatedForDocChecker: block.invalidatedForDocChecker,
		from: mapping.map(block.from),
		to: mapping.map(block.to),
		containerType: block.containerType,
		recommendations: block.recommendations,
	};
}

const getIgnoredRanges = ({
	node,
	ignoredMarkRangesPredicate,
}: {
	node: PMNode;
	ignoredMarkRangesPredicate?: (node: PMNode) => boolean;
}) => {
	const ignoredRanges: Array<IgnoredRange> = [];
	node.descendants((node, pos) => {
		if (!node.isText && node.isInline) {
			ignoredRanges.push({ pos, size: node.nodeSize, type: 'inlineNode' });
		} else if (ignoredMarkRangesPredicate && ignoredMarkRangesPredicate(node)) {
			ignoredRanges.push({ pos, size: node.nodeSize, type: 'mark' });
		}
		return false;
	});
	return ignoredRanges;
};

export function initializeAllProactiveAIBlocks(state: EditorState) {
	const nodeTypes = getProactiveAIBlockNodeTypes(state);
	const includedNodeTypes = getIncludedProactiveAINodeTypes(state);
	const proactiveAIBlocks: ProactiveAIBlock[] = [];
	const ignoredMarkRangesPredicate = getIgnoredMarkRangesPredicate(state);

	state.doc.descendants((node, pos, parent) => {
		if (nodeTypes.has(node.type)) {
			const ignoredRanges = getIgnoredRanges({ node, ignoredMarkRangesPredicate });
			const from = pos,
				to = pos + node.nodeSize;

			const containerType = getContainerType(state.schema, node, parent);
			proactiveAIBlocks.push({
				id: uuid(),
				text: node.textContent,
				from,
				to,
				nodeTypeName: node.type.name,
				ignoredRanges,
				invalidated: false,
				invalidatedForDocChecker: true,
				containerType,
			});
			return false;
		}
		return includedNodeTypes.has(node.type);
	});

	return { proactiveAIBlocks };
}

export function getSelectedBlock(pluginState: AIProactivePluginState, pos: number) {
	const { proactiveAIBlocks } = pluginState;

	if (proactiveAIBlocks?.length) {
		for (let i = 0; i < proactiveAIBlocks.length; i++) {
			const block = proactiveAIBlocks[i];
			if (pos >= block.from && pos <= block.to) {
				return block;
			}
		}
	}
	return undefined;
}

export function getSelectedRecommendations(pluginState: AIProactivePluginState, pos: number) {
	return getSelectedBlock(pluginState, pos)?.recommendations;
}

/**
 * This will remove the specific recommendation from across all blocks
 */
export function removeRecommendationFromBlocks(
	blocks: ProactiveAIBlock[],
	recommendationId: string,
): ProactiveAIBlock[] {
	return blocks.map((block) => {
		return {
			...block,
			recommendations: block.recommendations?.filter(
				(recommendation) => recommendation.id !== recommendationId,
			),
		};
	});
}

/**
 * This will mark the specific recommendation as viewed.
 */
export function markRecommendationViewed(
	pluginState: AIProactivePluginState,
	recommendationId: string | undefined,
): ProactiveAIBlock[] {
	const { proactiveAIBlocks } = pluginState;

	return proactiveAIBlocks?.map((block) => {
		return {
			...block,
			recommendations: block.recommendations?.map((recommendation) =>
				recommendation.id === recommendationId
					? { ...recommendation, isViewed: true }
					: recommendation,
			),
		};
	});
}

/**
 *
 * This will mark all recommendations as viewed.
 */
export function markAllRecommendationsViewed(
	pluginState: AIProactivePluginState,
): ProactiveAIBlock[] {
	const { proactiveAIBlocks } = pluginState;

	return proactiveAIBlocks?.map((block) => {
		return {
			...block,
			recommendations: block.recommendations?.map((recommendation) => ({
				...recommendation,
				isViewed: true,
			})),
		};
	});
}

/**
 * This will map the filtered based on the setting toggle
 */
export function filterRecommendationsByToggle(
	blocks: ProactiveAIBlock[],
	settings: SettingDisplayType,
): ProactiveAIBlock[] {
	const suggestionWithSettingValues = getSettingsWithValues(settings);

	const mappedSettings = suggestionWithSettingValues.reduce<returnSettingType>((suggestion, s) => {
		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		suggestion[s.transformationType!] = s.defaultValue!;
		return suggestion;
	}, {});

	return blocks.map((block) => {
		return {
			...block,
			recommendations: block.recommendations?.map((recommendation) => {
				return {
					...recommendation,
					/**
					 * if mappedSettings[recommendation.transformAction] is undefined then it will considered not filtered
					 * since it doesn't exist in the filtering setting list, meaning either it's an unsupported recommendation
					 * or it's a recommendation that should be displayed by default.
					 */
					filtered: !(mappedSettings[recommendation.transformAction] ?? true),
				};
			}),
		};
	});
}

/**
 * Returns the count of unread recommendations.
 */
export function getUnreadRecommendationsCount(pluginState: AIProactivePluginState): number {
	const { proactiveAIBlocks } = pluginState;

	return (
		proactiveAIBlocks
			?.flatMap((block) => block.recommendations ?? [])
			.filter((recommendation) => !recommendation.isViewed && !recommendation.filtered).length ?? 0
	);
}

export function generateParagraphChunkMap(blocks: ProactiveAIBlock[]) {
	const chunks = blocks;
	return chunks.reduce(
		(accumulator, chunk) => {
			accumulator.set(chunk.id, chunk);
			return accumulator;
		},
		new Map() as Map<string, ParagraphChunk>,
	);
}

export function getRecommendationsFromBlocks(blocks: ProactiveAIBlock[]) {
	const sortable = Array.from(blocks);
	sortable.sort((a, b) => a.from - b.from);
	return (
		sortable.reduce((accumulator, block) => {
			if (block.recommendations) {
				accumulator = accumulator.concat(block.recommendations);
			}
			return accumulator;
		}, [] as Recommendation[]) || []
	);
}

export function getBlockFromRecommendationId(
	pluginState: AIProactivePluginState,
	recommendationId: string,
): {
	block?: ProactiveAIBlock;
	recommendation?: Recommendation;
} {
	const { proactiveAIBlocks } = pluginState;
	const n = proactiveAIBlocks.length;
	for (let i = 0; i < n; i++) {
		const block = proactiveAIBlocks[i];
		const recommendation = block.recommendations?.find((r) => r.id === recommendationId);

		if (block && recommendation) {
			return {
				block,
				recommendation,
			};
		}
	}

	return {
		block: undefined,
		recommendation: undefined,
	};
}

export function getAllRecommendations(pluginState: AIProactivePluginState): Recommendation[] {
	const { proactiveAIBlocks } = pluginState;

	return proactiveAIBlocks ? getRecommendationsFromBlocks(proactiveAIBlocks) : [];
}

/**
 * This will filter out the recommendations based on the context panel settings at a given position
 */
export function getFilteredRecommendationsAtPosition(
	pluginState: AIProactivePluginState,
	pos: number,
) {
	const recommendations = getSelectedBlock(pluginState, pos)?.recommendations;
	const { settings } = pluginState;
	const suggestionWithSettingValues = getSettingsWithValues(settings).filter(
		(setting) => setting.transformationType,
	);

	const filteredRecommendations = recommendations?.filter((recommendation) => {
		// We want to filter out recommendations that are not in the context panel settings
		return !suggestionWithSettingValues.some(({ transformationType, defaultValue }) => {
			// We want recommendations only if the setting is false (not on) &&
			// if the recommendation matches the transform action
			return !defaultValue && recommendation.transformAction === transformationType;
		});
	});

	return filteredRecommendations;
}

/**
 * This will filter recommendations based on the context panel settings
 */
export function getRecommendationByFilters(pluginState: AIProactivePluginState): Recommendation[] {
	const recommendations = getAllRecommendations(pluginState) ?? [];

	const filteredRecommendations = pluginState?.settings?.displayAllSuggestions
		? recommendations
		: [];

	return filteredRecommendations.filter((recommendation) => !recommendation.filtered);
}

export function hasRecommendations(pluginState: AIProactivePluginState): boolean {
	return pluginState.availableRecommendationIds.size > 0;
}

export function hasRecommendation(
	pluginState: AIProactivePluginState,
	recommendationId: string,
): boolean {
	return pluginState.availableRecommendationIds.has(recommendationId);
}

export function syncProactiveRecommendations(
	pluginState: AIProactivePluginState,
	recommendations: Recommendation[],
	blocksIdsMissingRecommendations: Set<string>,
) {
	const { proactiveAIBlocks, validateBlocksOnMissingRecommendations, settings } = pluginState;

	return proactiveAIBlocks?.map((block) => {
		const updatedRecommendations = syncFilteredRecommendations(recommendations, settings);

		const newRecommendations = updatedRecommendations.filter(
			(recommendation) => recommendation.chunkId === block.id,
		);

		return {
			...block,
			...(newRecommendations.length && {
				// FIXME: We need to fix how dismiss recommendations are being filtered out, previously this was a text based
				// solution however this may no longer work, and we instead may need to ignore based on the block id
				// recommendations: newRecommendations.filter((diffObject) => !dismissedWords.has(diffObject.id)),
				recommendations: newRecommendations,
				invalidated: false,
				invalidatedForDocChecker: false,
			}),
			...(validateBlocksOnMissingRecommendations &&
				blocksIdsMissingRecommendations.has(block.id) && {
					invalidated: false,
					invalidatedForDocChecker: false,
				}),
		};
	});
}
