import { type AssistanceServiceProduct } from '@atlassian/conversation-assistant-service';

// Supported products in ConvoAI: https://bitbucket.org/atlassian/assistance-service/src/858045b3f075c307787f393c34807eec5a04260b/src/schema/base.py#lines-21
export const mapToAssistanceServiceProduct = (product: string): AssistanceServiceProduct => {
	switch (product) {
		case 'CONFLUENCE':
			return 'confluence';
		case 'JSW':
			return 'jira-software';
		case 'JWM':
			return 'jira-core';
		case 'JSM':
			return 'jsm';
		case 'JPD':
			return 'jpd';
		case 'ATLAS':
			return 'atlas';
		case 'JIRA_FAMILY':
			return 'jira';
		case 'BITBUCKET':
			return 'bitbucket';
		default:
			return 'unknown';
	}
};
