import React from 'react';

import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { FullPageLoadingScreen } from '@confluence/full-page-loading-screen';
import { usePageContentId } from '@confluence/page-context';
import type { RouteMatch } from '@confluence/route';
import { END } from '@confluence/navdex';

import { EditPageRoute } from './EditPageRoute';

export function getCompanyHubEditRoute(component) {
	const CompanyHubEditRoute = (routeProps: RouteMatch) => {
		const spaceKey = getCompanyHubSpaceKey() ?? '';
		const [contentId] = usePageContentId();

		// If there is no contentId in SSR then we will just see the FullPageLoadingScreen being rendered
		// so we skip the loading screen component to ensure the EditPageRoute is rendered instead
		if (!contentId && !process.env.REACT_SSR) {
			return <FullPageLoadingScreen />;
		}

		const Component = component;
		return (
			<Component
				{...routeProps}
				params={{ contentId: contentId ?? '', spaceKey, contentType: 'pages' }}
			/>
		);
	};

	CompanyHubEditRoute.NAVIGATION_PARAMS = () => {
		const spaceKey = getCompanyHubSpaceKey() ?? '';
		return {
			Screen: {
				screenEvent: {
					name: 'companyHubEditScreen',
					id: spaceKey,
					attributes: {
						navdexPointType: END,
						// Company Hub hides its space from the user, doesn't refer to its page
						// as such, is rather presented to the user as "a new surface", and thus
						// doesn't constitute space usage from the perspective of the user.
						screenIsInSpace: false,
					},
				},
				// When no contentId is passed, Screen.js handles querying it and updating pageState with it.
				pageState: { spaceKey },
			},
			MainLayout: {
				navView: PRODUCT_HOME_ID,
			},
		};
	};

	return CompanyHubEditRoute;
}

export const CompanyHubEditRouteForSSR = getCompanyHubEditRoute(EditPageRoute);
