/** @jsxFrag */
/** @jsx jsx */
import React, { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import isEqual from 'lodash/isEqual';
import { css, jsx } from '@compiled/react';

import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Heading from '@atlaskit/heading';

import type {
	PrincipalRoleAssignment,
	SpacePermission,
	SpaceRole,
} from '../../model/space-roles-types';
import { FrictionModal } from '../FrictionModal';
import type { SubjectPermissionDeltasV2 } from '../../graphql/__types__/UpdateLegacyPermissionsMutation';
import { useResponseHandler } from '../../graphql/hooks/useResponseHandler';

import { RoleSelection } from './RoleSelection';
import { LegacyPermissionsView } from './LegacyPermissionsView';
import { PermissionsSummary } from './PermissionsSummary';
import { FooterButtons } from './FooterButtons';

const i18n = defineMessages({
	accessTitle: {
		id: 'space-roles.update-principal-modal.title',
		defaultMessage: 'Manage access',
		description: 'Title for an update user permissions modal',
	},
	frictionModalConfirmButton: {
		id: 'space-roles.update-principal-modal.friction-modal.confirm-button',
		defaultMessage: 'Discard',
		description: 'Confirm button text for a friction modal',
	},
	frictionModalCancelButton: {
		id: 'space-roles.update-principal-modal.friction-modal.cancel-button',
		defaultMessage: 'Keep editing',
		description: 'Cancel button text for a friction modal',
	},
});

const verticalSeparatorStyle = css({
	border: `0.5px solid ${token('color.border')}`,
});

const modalFlexStyle = xcss({
	gap: 'space.300',
	paddingRight: 'space.300',
});

const summaryAndPermissionsStyle = xcss({
	gap: 'space.300',
});

const modalHeaderStyle = xcss({
	marginBottom: 'space.150',
});

type CheckboxType = {
	id: string;
	displayName: string;
	priority: number;
	description: string;
	isChecked: boolean;
	isDisabled: boolean;
};

export type NormalizedCheckboxesType = {
	group: {
		name: string;
		priority: number;
		checkboxes: CheckboxType[];
	};
};

export type UpdatePermissionsAndPermissionKeysArgs = {
	subjectPermissionDeltasListV2: SubjectPermissionDeltasV2[];
} & {
	newPermissionKeys: string[];
};

export type UpdateRoleAndPermissionKeysArgs = {
	roleId: string;
	roleDisplayName: string;
	newPermissionKeys: string[];
	principalId: string;
};

const getNormalizedCheckboxes = (
	permissions: string[] | undefined,
	allSpacePermissions: SpacePermission[],
) => {
	// Permission groups are sorted here, then checkboxes of permissions are sorted below
	const sortedSpacePermissions = allSpacePermissions.sort(
		(a, b) => a.groupPriority - b.groupPriority,
	);
	return Object.values(sortedSpacePermissions).reduce(
		(acc, permission) => {
			const isChecked = Boolean(permissions?.includes(permission.key));
			const checkbox: CheckboxType = {
				id: permission.key,
				displayName: permission.actionName,
				priority: permission.priority,
				description: permission.description || '',
				isChecked,
				isDisabled: false,
			};
			const group = {
				groupName: permission.groupName,
				groupPriority: permission.groupPriority,
			};
			if (!acc[group.groupName]) {
				acc[group.groupName] = {
					group: {
						name: group.groupName,
						priority: group.groupPriority,
						checkboxes: [],
					},
				};
			}
			acc[group.groupName].group.checkboxes.push(checkbox);
			acc[group.groupName].group.checkboxes.sort(
				(a: CheckboxType, b: CheckboxType) => a.priority - b.priority,
			);
			return acc;
		},
		{} as Record<number, NormalizedCheckboxesType>,
	);
};

const getPermissionKeys = (normalizedCheckboxes: Record<string, NormalizedCheckboxesType>) => {
	return Object.values(normalizedCheckboxes)
		.flatMap((group) => group.group.checkboxes)
		.filter((checkbox) => checkbox.isChecked)
		.map((checkbox) => checkbox.id);
};

export const UpdatePrincipalModal = ({
	principalRoleAssignment,
	spaceRoleOptions,
	allPermissions,
	isUpdating,
	experience,
	isDefaultsView,
	onUpdateRole,
	onUpdatePermissions,
	refreshTableData,
	onClose,
}: {
	principalRoleAssignment: PrincipalRoleAssignment;
	spaceRoleOptions: SpaceRole[];
	allPermissions: SpacePermission[];
	isUpdating: boolean;
	experience: string;
	isDefaultsView: boolean;
	onUpdateRole: (args: UpdateRoleAndPermissionKeysArgs) => Promise<void>;
	onUpdatePermissions: (args: UpdatePermissionsAndPermissionKeysArgs) => Promise<void>;
	refreshTableData?: () => void | Promise<void>;
	onClose: () => void;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [showStagedChangesWarningModal, setShowStagedChangesWarningModal] = useState(false);
	const { startExperience, abortExperience } = useResponseHandler({
		experience,
	});

	const initialRole = spaceRoleOptions.find((role) => role?.id === principalRoleAssignment?.roleId);
	const initialNormalizedCheckboxes = getNormalizedCheckboxes(
		principalRoleAssignment?.permissions,
		allPermissions,
	);
	const [normalizedCheckboxes, setNormalizedCheckboxes] = useState<
		Record<string, NormalizedCheckboxesType>
	>(initialNormalizedCheckboxes);
	const [role, setRole] = useState<SpaceRole | undefined | null>(initialRole);

	const initialPermissionKeys = getPermissionKeys(initialNormalizedCheckboxes);
	const currentPermissionKeys = getPermissionKeys(normalizedCheckboxes);

	const areChangesStaged = !isEqual(initialPermissionKeys, currentPermissionKeys);

	const onCancel = useCallback(() => {
		if (areChangesStaged) {
			setShowStagedChangesWarningModal(true);
		} else {
			abortExperience();
			onClose();
		}
	}, [areChangesStaged, onClose, abortExperience]);

	useEffect(
		() => {
			startExperience();
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'updatePrincipalModal',
					attributes: {
						source: 'spaceRoles-updatePrincipalModal',
					},
				},
			}).fire();
		},
		// Only send on initial mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<ModalTransition>
			<>
				<ModalDialog onClose={onCancel} width="1000px" testId="space-roles.update-modal">
					<ModalHeader>
						<Box xcss={modalHeaderStyle}>
							<Heading size="large">{formatMessage(i18n.accessTitle)}</Heading>
						</Box>
					</ModalHeader>
					<ModalBody>
						<Flex xcss={modalFlexStyle}>
							<RoleSelection
								principalRoleAssignment={principalRoleAssignment}
								role={role}
								setRole={setRole}
								setNormalizedCheckboxes={setNormalizedCheckboxes}
								spaceRoleOptions={spaceRoleOptions}
							/>
							<hr css={verticalSeparatorStyle} />
							<Stack xcss={summaryAndPermissionsStyle}>
								<PermissionsSummary
									roleId={role?.id ?? null}
									permissions={currentPermissionKeys}
									roleLookup={spaceRoleOptions}
									isDefaultsView={isDefaultsView}
								/>
								<LegacyPermissionsView
									normalizedCheckboxes={normalizedCheckboxes}
									currentPermissionKeys={currentPermissionKeys}
									initialRole={initialRole}
									allPermissions={allPermissions}
									allRoles={spaceRoleOptions}
									setRole={setRole}
									setNormalizedCheckboxes={setNormalizedCheckboxes}
								/>
							</Stack>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<FooterButtons
							onClose={onClose}
							onCancel={onCancel}
							areChangesStaged={areChangesStaged}
							principalRoleAssignment={principalRoleAssignment}
							initialPermissionKeys={initialPermissionKeys}
							currentPermissionKeys={currentPermissionKeys}
							deltaAttributes={{
								roleInfo: { initialRole, role },
								permissionsInfo: { initialNormalizedCheckboxes, normalizedCheckboxes },
							}}
							onUpdateRole={onUpdateRole}
							onUpdatePermissions={onUpdatePermissions}
							refreshTableData={refreshTableData}
							isUpdating={isUpdating}
							isDefaultsView={isDefaultsView}
						/>
					</ModalFooter>
				</ModalDialog>
				{showStagedChangesWarningModal && (
					<FrictionModal
						buttons={[
							{
								onClick: () => setShowStagedChangesWarningModal(false),
								label: formatMessage(i18n.frictionModalCancelButton),
								appearance: 'subtle',
								key: 'update-modal-staged-cancel',
							},
							{
								onClick: onClose,
								label: formatMessage(i18n.frictionModalConfirmButton),
								appearance: 'warning',
								key: 'update-modal-staged-confirm',
							},
						]}
						onClose={() => setShowStagedChangesWarningModal(false)}
					/>
				)}
			</>
		</ModalTransition>
	);
};
