import React from 'react';

import { bind } from 'bind-event-listener';

type Props = {
	/** If the handler should be disabled */
	disabled?: boolean;
	userInput?: string;
	onCancel: () => void;
	onDiscard: () => void;
	modalRef: React.RefObject<HTMLDivElement>;
};

/**
 * Check if the element has a modal above it.
 *
 * This implementation is dangerous because it relies on the DOM structure of the modal.
 */
function isElementHasModalAbove(element: HTMLElement | null): boolean {
	if (!element) {
		return false;
	}

	const elementPortal = element.closest('.atlaskit-portal');
	if (!elementPortal) {
		// If element is not in the modal, we can safely assume that the modal is above
		// if there is a focus guard in the document.
		return !!document.querySelector('[data-focus-guard="true"]');
	}

	// Check all siblings of the element to see if there is a focus guard.
	// If there is a focus guard, it means that the modal is above the current modal.
	let sibling = elementPortal.nextElementSibling;
	while (sibling) {
		if (sibling.querySelector('[data-focus-guard="true"]')) {
			return true;
		}

		sibling = sibling.nextElementSibling;
	}

	return false;
}

/**
 * If the user clicks outside of the modal
 * - if they have not entered any text we end the experience
 * - if they have entered text we move them to the discard screen
 * - if they click on the feedback form we do nothing
 */
export const useOutsideClickHandler = ({
	disabled,
	userInput,
	onCancel,
	onDiscard,
	modalRef,
}: Props) => {
	React.useEffect(() => {
		if (disabled) {
			return;
		}

		if (!modalRef) {
			return;
		}

		const handleOutsideClick = (event: MouseEvent) => {
			// It's a really bad idea to check DOM, but we don't have any better way to determinate if
			// the Feedback modal is open or not.
			// FIXME: It should be removed and replaced with solution based on the state of the modal and `disabled` prop.
			if (isElementHasModalAbove(modalRef.current)) {
				return;
			}

			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				if (!userInput) {
					onCancel();
				} else {
					onDiscard();
				}
			}
		};

		return bind(document, {
			type: 'mousedown',
			listener: handleOutsideClick,
		});
	}, [disabled, userInput, onCancel, onDiscard, modalRef]);
};
