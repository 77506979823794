import { useEffect, useMemo, useState } from 'react';

/** Get the size of the element. */
export function useElementSize(): [
	(element: HTMLElement | null) => void,
	{
		width: number;
		height: number;
	},
] {
	const [element, setElement] = useState<HTMLElement | null>(null);
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (!element) {
			return;
		}

		// Do no use here ResizeObserverEntry.contentRect as it is only a content size (without padding and border)
		// and we need the size of the whole element.
		const updateSize = () => {
			const { width, height } = element.getBoundingClientRect();

			setWidth(width);
			setHeight(height);
		};

		const resizeObserver = new ResizeObserver(updateSize);
		resizeObserver.observe(element);

		return () => {
			resizeObserver.disconnect();
		};
	}, [element]);

	return useMemo(() => [setElement, { width, height }], [width, height]);
}
