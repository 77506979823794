import React from 'react';

import uuid from 'uuid';

import Skeleton from '@atlaskit/skeleton';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { RovoAgentIcon } from '@atlassian/conversation-assistant-ui-components';
import type { Suggestion } from '@atlassian/generative-ai-modal/screens/UserInputCommandPalette';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

export const getDummySuggestions = (agent: EditorAgent, length = 3): Suggestion[] => {
	return new Array(length).fill(null).map((_, index) => {
		return {
			value: `agent:${agent.id}:${uuid()}:dummy`,
			label: (
				<Skeleton
					width={`${index % 2 !== 0 ? 205 : 180}px`}
					height="18px"
					borderRadius={2}
					isShimmering
				/>
			),
			icon: (
				<RovoAgentIcon
					label={''}
					excludeOverlay
					size="large"
					width={32}
					height={32}
					primaryColor={token('color.skeleton', N20A)}
				/>
			),
			groupHeading: '',
			onSelect: () => {},
			onSuggestionFocused: () => {},
		};
	});
};
