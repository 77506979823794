import { type AIAuditLogPluginState, type AIAuditLogUpdatePluginState } from './types';

export function reducer(
	pluginState: AIAuditLogPluginState,
	action: AIAuditLogUpdatePluginState,
): AIAuditLogPluginState {
	switch (action.type) {
		case 'UPDATE_PLUGIN_STATE':
			return {
				...pluginState,
				'ai-generated-content-inserted': {
					...pluginState['ai-generated-content-inserted'],
					...action.data['ai-generated-content-inserted'],
				},
				'aup-violation': {
					...pluginState['aup-violation'],
					...action.data['aup-violation'],
				},
			};

		default:
			return pluginState;
	}
}
