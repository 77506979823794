import { defineMessages } from 'react-intl-next';

// These messages have been copied to packages/editor/generative-ai-modal/examples/screen-errors-unhandled.tsx
export default defineMessages({
	unhandledErrorMessage: {
		id: 'fabric.editor.ai.experience-application.unhandledErrorMessage',
		defaultMessage: "We're having trouble. Close the dialog and try again.",
		description: 'Message to users that displays when an unexpected error happens',
	},
	markdownErrorMessage: {
		id: 'fabric.editor.ai.experience-application.error-boundary.markdownErrorMessage',
		defaultMessage: "We're having trouble generating the preview. Close the dialog and try again.",
		description: 'Message to users that displays when an error occurs while generating a preview.',
	},
});
