import { preloadContentHeader } from '@confluence/content-header/entry-points/preloadContentHeader';
import { preloadCustomHeaderAndFooter } from '@confluence/custom-header-footer/entry-points/preloadCustomHeaderAndFooter';
import { preloadPageTitleContentProperties } from '@confluence/content-topper/entry-points/preloadPageTitleContentProperties';
import { preloadMediaToken } from '@confluence/fabric-media-support/entry-points/preloadMediaToken';
import { fg } from '@confluence/feature-gating';
import { preloadFocusedInlineComment } from '@confluence/inline-comments-queries/entry-points/preloadFocusedInlineComment';
import { preloadInlineComments } from '@confluence/inline-comments-queries/entry-points/preloadInlineComments';
import { preloadInlineHighlights } from '@confluence/inline-highlights-query/entry-points/preloadInlineHighlights';
import { preloadEditorData } from '@confluence/load-edit-page/entry-points/preloadEditorData';
import {
	getPreloaderFnContext,
	prepareGuardExceptionTask,
} from '@confluence/query-preloader-tools';
import { preloadPageReactions } from '@confluence/reactions/entry-points/preloadPageReactions';
import type { RouteMatch } from '@confluence/route';
import { preloadContentPrerequisites } from '@confluence/content-prerequisites/entry-points/preloadContentPrerequisites';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { preloadPageStatusQuery } from '@confluence/view-page-common/entry-points/preloadPageStatusQuery';
import { preloadLegacyBridge } from '@confluence/view-page/entry-points/preloadLegacyBridge';
import {
	ATL_PAGE_CONTENT_FOOTER_ACTIONS,
	ATL_GENERAL,
} from '@confluence/web-item-location/entry-points/WEB_FRAGMENT_LOCATIONS';
import { preloadWebPanelLocation } from '@confluence/web-panel-location/entry-points/preloadWebPanelLocation';
import { preloadSuggestedTemplateQuery } from '@confluence/blank-page-quick-actions/entry-points/preloadSuggestedTemplateQuery';

import { getIsEmbeddedConfluence, getUsername, hasEmbeddedAllowedFeatures } from './matchHelpers';
import { matchBlogPage } from './matchRoutes';
import { preloadNavigationTasks } from './preloadNavigationTasks';

/*
 * preloadLivePageRoute preload queries are currently arranged in order of latency, with slowest
 * queries pushed to the tasks array first.
 */
export const preloadLivePageRouteSSR = async (match: RouteMatch, url: string) => {
	const { isLicensed, userId, edition, cloudId } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { spaceKey, contentId } = match.params;
	const username = getUsername(match);
	const isEmbeddedConfluence = getIsEmbeddedConfluence(match);
	const isBlog = Boolean(matchBlogPage(url));
	const shouldPreloadNavigation = !isEmbeddedConfluence;

	if (process.env.REACT_SSR) {
		tasks.push(
			prepareGuardExceptionTask('EditorData', () =>
				preloadEditorData({
					spaceKey,
					userId,
					contentId,
					preloadNativeCollabData: true,
					shouldPreloadMentionsProvider: true,
					shouldPreloadNativeEditorData: false,
					cloudId,
					isBlog,
					isTransition: false,
					isLivePage: true,
				}),
			),
		);
	}

	// 1. Content Header and possibly content prerequisites
	tasks.push(
		preloadContentHeader({
			spaceKey,
			contentId,
			isLicensed,
			userId,
			username,
			edition,
			isPrevEditRoute: false,
			useNewContentTopper: true,
			isBlog: false,
			isLivePageRoute: true,
		}).then(({ results, hasErrors }) => {
			if (!hasErrors) {
				return results;
			}

			return preloadContentPrerequisites(contentId, spaceKey);
		}),
		preloadPageStatusQuery(contentId),
		preloadLegacyBridge({ contentId, isBlog }),
	);

	// 2. Page title
	tasks.push(preloadPageTitleContentProperties({ contentId, spaceKey }));

	// 3. Space details
	tasks.push(preloadSpaceDetail(spaceKey));

	// 4. Navigation and page tree
	if (shouldPreloadNavigation) {
		tasks.push(...preloadNavigationTasks(spaceKey, contentId, isLicensed, isBlog));
	}

	// 5. Inline Comments
	if (match?.query.focusedCommentId) {
		tasks.push(preloadFocusedInlineComment(match?.query.focusedCommentId as string, contentId));
	}

	if (!isEmbeddedConfluence || hasEmbeddedAllowedFeatures('inline-comments', match)) {
		// when rendering an embedded confluence page, we do not want to preload inline comments if they are not requested via the allowlist features
		tasks.push(preloadInlineHighlights(contentId));
		tasks.push(preloadInlineComments({ pageId: contentId }));
	}

	// 6. Reactions
	if (!isEmbeddedConfluence || hasEmbeddedAllowedFeatures('page-reactions', match)) {
		// Preload whether the server has enabled reactions at all to show placeholder
		tasks.push(
			preloadWebPanelLocation({
				contentId,
				location: ATL_PAGE_CONTENT_FOOTER_ACTIONS,
			}),
		);
		// Preload reactions asynchronously so SSR won't wait for it
		// it's best effort preloading so if it preloads then we have the data, otherwise SPA can load it
		if (fg('confluence_ssr_reactions_preload')) {
			tasks.push(preloadPageReactions(contentId));
		}
	}

	if (spaceKey && fg('confluence_preload_blank_page_quick_actions')) {
		tasks.push(preloadSuggestedTemplateQuery({ spaceKey }));
	}

	// 10. Stuff only needed for initial load
	tasks.push(
		// We only refresh token when it is about to expire.
		preloadMediaToken(contentId),
		preloadCustomHeaderAndFooter(spaceKey),
	);

	if (fg('confluence_preload_webpanel_atl_general')) {
		tasks.push(
			preloadWebPanelLocation({
				contentId,
				location: ATL_GENERAL,
			}),
		);
	}

	return Promise.all(tasks);
};
