import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/new';

const i18n = defineMessages({
	title: {
		id: 'space-roles.removal-friction-modal.title',
		defaultMessage: 'Remove from space?',
		description: 'Title for the friction modal',
	},
	body: {
		id: 'space-roles.removal-friction-modal.body',
		defaultMessage: 'They‘ll no longer be able to view content in this space.',
		description: 'Body text for the friction modal',
	},
	defaultsBody: {
		id: 'space-roles.removal-friction-modal.defaults-body',
		defaultMessage: 'They‘ll no longer have default access to spaces.',
		description: 'Body text for the friction modal',
	},
	confirmButton: {
		id: 'space-roles.removal-friction-modal.confirm-button',
		defaultMessage: 'Remove',
		description: 'Confirm button text for a friction modal',
	},
	cancelButton: {
		id: 'space-roles.removal-friction-modal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for a friction modal',
	},
});

export const RemovalFrictionModal: FC<{
	onCancel: () => void;
	onConfirm: () => void;
	isDefaultsView: boolean;
}> = ({ onCancel, onConfirm, isDefaultsView }) => {
	const { formatMessage } = useIntl();

	return (
		<ModalDialog width="medium" onClose={onCancel}>
			<ModalHeader>
				<ModalTitle>{formatMessage(i18n.title)}</ModalTitle>
			</ModalHeader>
			<ModalBody>{formatMessage(isDefaultsView ? i18n.defaultsBody : i18n.body)}</ModalBody>
			<ModalFooter>
				<Button appearance="subtle" onClick={onCancel}>
					{formatMessage(i18n.cancelButton)}
				</Button>
				<Button appearance="primary" onClick={onConfirm}>
					{formatMessage(i18n.confirmButton)}
				</Button>
			</ModalFooter>
		</ModalDialog>
	);
};
