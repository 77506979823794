import { isEmptyDocument, pluginFactory } from '@atlaskit/editor-common/utils';
import type { EditorState, ReadonlyTransaction } from '@atlaskit/editor-prosemirror/state';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';
import { fg } from '@atlaskit/platform-feature-flags';
import type { RateLimiter } from '@atlassian/editor-ai-common/api/rate-limiter';

import type { DocumentChecker } from '../../utils/document-checker';
import { getProactiveAISettings } from '../../utils/local-storage';

import { aiProactivePluginKey } from './ai-proactive-plugin-key';
// import { DOC_CHANGE_COUNT_LIMIT } from './constants';
import { regenerateAllDecorations, regenerateSelectionDecorations } from './decorations';
import { reducer } from './reducer';
import type { AIProactivePluginState } from './states';
import { getNewSettings } from './suggestion-setting-map';
import {
	findProactiveAIBlockToUpdate,
	getSelectedRecommendations,
	initializeAllProactiveAIBlocks,
	updateAvailableRecommendationIds,
} from './utils';

const handleDocChanged = (
	tr: ReadonlyTransaction,
	pluginState: AIProactivePluginState,
	newEditorState: EditorState,
): AIProactivePluginState => {
	const { updatedProactiveAIBlock } = findProactiveAIBlockToUpdate(tr, pluginState, newEditorState);

	const { decorationSet, documentChecker, hasRunFullDocumentScanOnReplaceDocument } = pluginState;

	// This is a special handle for situations where the editor is loaded with a blank document and then at a later
	// stage is populated with the entire document contents, if the full document check hasn't run and is expected to be,
	// then we will start the scanner process.
	const shouldRunWholeDocScan =
		!hasRunFullDocumentScanOnReplaceDocument &&
		tr.getMeta('replaceDocument') &&
		fg('platform_editor_ai_proactive_full_scan_on_load');

	if (shouldRunWholeDocScan) {
		documentChecker?.reset();
		documentChecker?.start();
	}

	const availableRecommendationIds = updateAvailableRecommendationIds(
		updatedProactiveAIBlock,
		pluginState,
	);

	return {
		...pluginState,
		hasRunFullDocumentScanOnReplaceDocument:
			hasRunFullDocumentScanOnReplaceDocument || shouldRunWholeDocScan,
		decorationSet: regenerateAllDecorations({
			decorationSet,
			tr,
			pluginState: {
				...pluginState,
				proactiveAIBlocks: updatedProactiveAIBlock,
			},
		}),
		availableRecommendationIds,
		proactiveAIBlocks: updatedProactiveAIBlock,
		...(isEmptyDocument(newEditorState.doc) && {
			hasNoMoreSuggestions: true,
		}),
	};
};

const handleSelectionChanged = (
	tr: ReadonlyTransaction,
	pluginState: AIProactivePluginState,
): AIProactivePluginState => {
	const { decorationSet, selectedRecommendationId } = pluginState;

	const selectedRecommendations =
		tr.selection.from === tr.selection.to
			? getSelectedRecommendations(pluginState, tr.selection.from)
			: undefined;

	const hasSelectedId = !!selectedRecommendations?.find((r) => r.id === selectedRecommendationId)
		?.id;

	const nextState = {
		...pluginState,
		selectedRecommendationId: hasSelectedId ? selectedRecommendationId : undefined,
	};

	return {
		...nextState,
		decorationSet: regenerateSelectionDecorations({
			decorationSet,
			tr,
			pluginState: nextState,
		}),
	};
};

export const createInitialState =
	({
		proactiveAIApiUrl,
		documentChecker,
		rateLimiter,
		defaultToggledState,
		product,
	}: {
		proactiveAIApiUrl: string;
		documentChecker?: DocumentChecker;
		rateLimiter?: RateLimiter;
		defaultToggledState?: boolean;
		product: string;
	}) =>
	(state: EditorState): AIProactivePluginState => {
		const { proactiveAIBlocks } = initializeAllProactiveAIBlocks(state);
		const contextPanelSettings = getProactiveAISettings(getNewSettings(null, null));
		const initialToggleState = !!defaultToggledState;
		return {
			product,
			proactiveAIBlocks,
			initialToggleState,
			isProactiveEnabled: true,
			isProactiveContextPanelOpen: false, // was: initialToggleState
			canProactiveContextPanelOpen: false,
			alwaysDisplayProactiveInlineDecorations: false,
			alwaysOpenProactiveContextPanelOnInlineSelection: false,
			validateBlocksOnMissingRecommendations: true,
			allowInlineHoverHighlightWhileRecommendationSelected: true,
			hasRunFullDocumentScanOnReplaceDocument: false,
			decorationSet: DecorationSet.empty,
			proactiveAIApiUrl,
			// dismissedWords: new Set<string>(),
			availableRecommendationIds: new Set<string>(),
			toggleCount: initialToggleState ? 1 : 0,
			insertionCount: 0,
			dismissedCount: 0,
			documentChecker,
			rateLimiter,
			docChangeCountUntilLimit: 0,
			isFirstInitiatedEventSent: false,
			settings: contextPanelSettings,
			isLoading: false,
			filteredRecommendations: [],
			hasNoMoreSuggestions: true,
		};
	};

export const { createPluginState, createCommand, getPluginState } = pluginFactory(
	aiProactivePluginKey,
	reducer,
	{
		onDocChanged: handleDocChanged,
		onSelectionChanged: handleSelectionChanged,
	},
);
