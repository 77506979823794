import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { PMPluginFactoryParams } from '@atlaskit/editor-common/types';

import { createPluginState } from './plugin-factory';
import { rovoAgentsPluginKey } from './plugin-key';

export function createRovoAgentsPlugin(options: PMPluginFactoryParams) {
	return new SafePlugin({
		key: rovoAgentsPluginKey,
		state: createPluginState(
			options.dispatch,
			// Initial state
			{
				agents: [],
				enabled: false,
				publish: () => {},
				isBrowseModalOpen: false,
				positions: undefined,
			},
		),
	});
}
