import React, { useState } from 'react';
import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import PeopleGroupIcon from '@atlaskit/icon/core/migration/people-group';

import type { SpaceRole } from '@confluence/space-roles/entry-points/space-role-types';

import { AddGroupModal } from '../dialogs/AddGroupModal';

export const AddGroupsButton = ({ roleOptions }: { roleOptions: SpaceRole[] }) => {
	const { formatMessage } = useIntl();
	const [openAddDialog, setOpenAddDialog] = useState(false);

	return (
		<>
			<Button iconBefore={PeopleGroupIcon} onClick={() => setOpenAddDialog(true)}>
				{formatMessage({
					id: 'default-space-permissions.default-roles-view.add-group-button',
					defaultMessage: 'Add groups',
					description:
						'Button to add a group to a table of default space permissions which will be seen by site admins',
				})}
			</Button>
			{openAddDialog === true && (
				<AddGroupModal onClose={() => setOpenAddDialog(false)} roleOptions={roleOptions} />
			)}
		</>
	);
};
