import { query } from '@confluence/query-preloader-tools';

import { TopNavigationQuery } from './TopNavigationQuery.graphql';

export function preloadTopNavigation() {
	const topNavigationPromises = [
		query({
			query: TopNavigationQuery,
		}),
	];

	return Promise.all(topNavigationPromises);
}
