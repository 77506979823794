import FeatureGates, { PerimeterType } from '@atlaskit/feature-gate-js-client';
import type { Identifiers, CustomAttributes } from '@atlaskit/feature-gate-js-client';
import type { ValuesPayload } from '@atlaskit/config-common-libs';

import type { SessionDataType } from '@confluence/session-data';
import { getMonitoringClient } from '@confluence/monitoring';

import { getStatsigEnvironment } from './getStatsigEnvironment';
import StatsigConfigurations from './StatsigConfigurations';

// https://bitbucket.org/atlassian/feature-flag-service/src/a6adccc26a95818c8128c63e4eb817414793b3c2/src/main/resources/application.yml#lines-386
const TARGET_APP = 'confluence_web';

export async function initializeClientFromValues({
	experimentValues,
	clientSdkKey,
	identifiers,
	environment: confluenceEnvironment,
	customAttributes,
	configCollectionValues,
}: {
	experimentValues?: Record<string, unknown>;
	clientSdkKey?: string;
	identifiers: {
		userId: SessionDataType['userId'];
		tenantId: Identifiers['tenantId'];
		orgId?: Identifiers['atlassianOrgId'];
	};
	environment: SessionDataType['environment'] | string;
	customAttributes?: CustomAttributes;
	configCollectionValues?: ValuesPayload;
}) {
	if (FeatureGates.initializeCompleted()) {
		return;
	}

	const { userId, tenantId, orgId } = identifiers;
	if (clientSdkKey) {
		window.__STATSIG_BOOTSTRAP__ = {
			experimentValues,
			clientSdkKey,
			configCollectionValues,
		};
	}

	try {
		await FeatureGates.initializeFromValues(
			{
				sdkKey: clientSdkKey,
				environment: getStatsigEnvironment(confluenceEnvironment),
				targetApp: TARGET_APP,
				perimeter:
					process.env.MICROS_PERIMETER === 'fedramp-moderate'
						? PerimeterType.FEDRAMP_MODERATE
						: PerimeterType.COMMERCIAL,
			},
			{
				atlassianAccountId: userId || undefined,
				tenantId,
				atlassianOrgId: orgId,
			},
			customAttributes,
			experimentValues,
		);

		// Initialize StatsigConfigurations after FeatureGates.initializeFromValues	so that
		// we can get the overrides from FeatureGates
		StatsigConfigurations.initializeFromValues(configCollectionValues || '{}');
	} catch (error: unknown) {
		if (error instanceof Error) {
			getMonitoringClient().submitError(error, {
				attribution: 'statsig',
			});
		}
	}
}
