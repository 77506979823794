export enum PostOfficeErrorCodes {
	MessageTemplatePlacementError = 'MessageTemplatePlacementError',
	NotAbleToProcessRecipientExpansionInputError = 'NotAbleToProcessRecipientExpansionInputError',
	RecipientExpansionManagerError = 'RecipientExpansionManagerError',
	RecipientExpansionProcessingError = 'RecipientExpansionProcessingError',
	RecipientExpansionMethodInvocationError = 'RecipientExpansionMethodInvocationError',
	MessageConsumptionFailure = 'MessageConsumptionFailure',
	ReceiverInputValidationError = 'ReceiverInputValidationError',
	HandlerValidationError = 'HandlerValidationError',
	InvalidMessageTemplateIdError = 'InvalidMessageTemplateIdError',
	MessageTemplateNotFoundError = 'MessageTemplateNotFoundError',
	MessageTemplateNotEnabledError = 'MessageTemplateNotEnabledError',
	MessageTemplateActionValidationError = 'MessageTemplateActionValidationError',
	MessageTemplateActionHandlerTimeoutError = 'MessageTemplateActionHandlerTimeoutError',
	MessageRendererEmptyValidationResponseError = 'MessageRendererEmptyValidationResponseError',
	IngestionHandlerFailedSendError = 'IngestionHandlerFailedSendError',
	OrchestrationHandlerFailedSendError = 'OrchestrationHandlerFailedSendError',
	DigestEngineFailedSendError = 'DigestEngineFailedSendError',
	UnableToGetUserDetailsError = 'UnableToGetUserDetailsError',
	InvalidPayloadError = 'InvalidPayloadError',
	UnsupportedAttachmentTypeError = 'UnsupportedAttachmentType',
	UnsupportedEmailError = 'UnsupportedEmailError',
	InvalidEmailRequestHeader = 'InvalidEmailRequestHeader',
	NonProdEmailDomainError = 'NonProdEmailDomainError',
	UnsupportedMessageAtPlacementError = 'UnsupportedMessageAtPlacementError',
	HydrationFailureAtPlacementError = 'HydrationFailureAtPlacementError',
	AllMessagesFailureHydrationAtPlacementError = 'AllMessagesFailureHydrationAtPlacementError',
	UnusedValidateError = 'UnusedValidateError',
	RecipientExpanderMapRetrievalError = 'RecipientExpanderMapRetrievalError',
	GrowthRecRecommendationsError = 'GrowthRecRecommendationsError',
	RichRecipientError = 'RichRecipientError',
	PlacementNotFoundError = 'PlacementNotFoundError',
	UnexpectedMobileNotificationsError = 'UnexpectedMobileNotificationsError',
	MobilePushPlacementError = 'MobilePushPlacementError',
	MissingIngestionSourceError = 'MissingIngestionSourceError',
	RenderRedactedNotFoundError = 'RenderRedactedNotFoundError',
	UnexpectedAtlassianMailError = 'UnexpectedAtlassianMailError',
	MessageEventServiceError = 'MessageEventServiceError',
	HydrationDeliveryNoDataFoundError = 'HydrationDeliveryNoDataFoundError',
}

// errors that should not impact the reliability SLOs should extend this class
export class MessageTemplatePlacementError extends Error {}
export class MessageEventServiceError extends Error {}

export abstract class PostOfficeError extends Error {
	constructor(
		message: string,
		public readonly code: PostOfficeErrorCodes,
	) {
		super(message);
	}
}

export class NotAbleToProcessRecipientExpansionInputError extends PostOfficeError {
	constructor(
		message: string,
		public readonly input: unknown,
	) {
		super(message, PostOfficeErrorCodes.NotAbleToProcessRecipientExpansionInputError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class RecipientExpansionProcessingError extends PostOfficeError {
	constructor(
		message: string,
		public readonly input: unknown,
	) {
		super(message, PostOfficeErrorCodes.RecipientExpansionProcessingError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class RecipientExpansionMethodInvocationError extends PostOfficeError {
	constructor(
		message: string,
		public readonly input: unknown,
	) {
		super(message, PostOfficeErrorCodes.RecipientExpansionMethodInvocationError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class RecipientExpansionManagerError extends PostOfficeError {
	constructor(message: string) {
		super(message, PostOfficeErrorCodes.RecipientExpansionManagerError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class MissingRecipientsFromRecipientExpansion extends PostOfficeError {
	constructor(
		message: string,
		public readonly input: unknown,
	) {
		super(message, PostOfficeErrorCodes.NotAbleToProcessRecipientExpansionInputError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class ReceiverInputValidationError extends PostOfficeError {
	constructor(message: string) {
		super(message, PostOfficeErrorCodes.ReceiverInputValidationError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class HandlerValidationError extends PostOfficeError {
	constructor(message: string) {
		super(message, PostOfficeErrorCodes.HandlerValidationError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class InvalidMessageTemplateIdError extends PostOfficeError {
	constructor(message: string) {
		super(message, PostOfficeErrorCodes.InvalidMessageTemplateIdError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class MessageTemplateNotFoundError extends PostOfficeError {
	constructor() {
		super('Message Template not found', PostOfficeErrorCodes.MessageTemplateNotFoundError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class MessageTemplateActionValidationError extends PostOfficeError {
	constructor() {
		super(
			'Message Template action handler validation error',
			PostOfficeErrorCodes.MessageTemplateActionValidationError,
		);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class MessageTemplateActionHandlerTimeoutError extends PostOfficeError {
	constructor(timeoutInSeconds = 5) {
		super(
			`Message Template action handler timed out after ${timeoutInSeconds} seconds`,
			PostOfficeErrorCodes.MessageTemplateActionHandlerTimeoutError,
		);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class PlacementNotFoundError extends PostOfficeError {
	constructor() {
		super('Placement not found', PostOfficeErrorCodes.PlacementNotFoundError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class MessageRendererEmptyValidationResponseError extends PostOfficeError {
	constructor() {
		super(
			'Message Renderer received empty response from validator',
			PostOfficeErrorCodes.MessageRendererEmptyValidationResponseError,
		);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class IngestionHandlerFailedSendError extends PostOfficeError {
	constructor() {
		super('Failed to send some events', PostOfficeErrorCodes.IngestionHandlerFailedSendError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class OrchestrationHandlerFailedSendError extends PostOfficeError {
	constructor() {
		super('Failed to send some events', PostOfficeErrorCodes.OrchestrationHandlerFailedSendError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class DigestEngineFailedSendError extends PostOfficeError {
	errorCause?: string;
	constructor(errorCause?: string) {
		super(
			'Failed to send digest event to orchestration queue',
			PostOfficeErrorCodes.DigestEngineFailedSendError,
		);
		this.name = this.constructor.name;
		this.errorCause = errorCause;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class UnableToGetUserDetailsError extends PostOfficeError {
	constructor(public accountId: string) {
		super(
			'Unable to get user details for the given account ID',
			PostOfficeErrorCodes.UnableToGetUserDetailsError,
		);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class InvalidPayloadError extends PostOfficeError {
	payloadSize?: number;
	constructor(message: string, payloadSize?: number) {
		super(message, PostOfficeErrorCodes.InvalidPayloadError);
		this.name = this.constructor.name;
		this.payloadSize = payloadSize;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class WorkspaceIdTemplateError extends MessageTemplatePlacementError {
	workspaceId?: string;
	constructor(message: string, workspaceId?: string) {
		super(message);
		this.name = this.constructor.name;
		this.workspaceId = workspaceId;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class UnsupportedAttachmentTypeError extends PostOfficeError {
	fileType: string;
	constructor(fileType: string) {
		super(
			'The attachment file is not supported',
			PostOfficeErrorCodes.UnsupportedAttachmentTypeError,
		);
		this.name = this.constructor.name;
		this.fileType = fileType;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class UnsupportedEmailError extends PostOfficeError {
	email: string;
	constructor(message: string, email: string) {
		super(message, PostOfficeErrorCodes.UnsupportedEmailError);
		this.name = this.constructor.name;
		this.email = email;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class InvalidEmailRequestHeader extends PostOfficeError {
	error: string;
	constructor(message: string, error: string) {
		super(message, PostOfficeErrorCodes.InvalidEmailRequestHeader);
		this.name = this.constructor.name;
		this.error = error;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class NonProdEmailDomainError extends PostOfficeError {
	constructor(message: string) {
		super(message, PostOfficeErrorCodes.NonProdEmailDomainError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class UnsupportedMessageAtPlacementError extends PostOfficeError {
	messageTemplateId: string;
	placementId: string;
	constructor({
		messageTemplateId,
		placementId,
	}: {
		messageTemplateId: string;
		placementId: string;
	}) {
		super(
			'messageTemplateId not supported by placement',
			PostOfficeErrorCodes.UnsupportedMessageAtPlacementError,
		);
		this.name = this.constructor.name;
		this.messageTemplateId = messageTemplateId;
		this.placementId = placementId;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class HydrationFailureAtPlacementError extends PostOfficeError {
	messageTemplateId: string;
	messageInstanceId: string;
	placementId: string;
	constructor(
		error: unknown,
		{
			messageTemplateId,
			messageInstanceId,
			placementId,
		}: { messageTemplateId: string; messageInstanceId: string; placementId: string },
	) {
		super(
			(error as Error)?.message ?? 'unknown error',
			PostOfficeErrorCodes.HydrationFailureAtPlacementError,
		);
		this.name = this.constructor.name;
		this.messageTemplateId = messageTemplateId;
		this.messageInstanceId = messageInstanceId;
		this.placementId = placementId;
		this.stack = (error as Error)?.stack;
	}
}

export class AllMessagesFailHydrationAtPlacementError extends PostOfficeError {
	placementId: string;
	constructor({ placementId }: { placementId: string }) {
		super(
			'All messages failed at placement',
			PostOfficeErrorCodes.AllMessagesFailureHydrationAtPlacementError,
		);
		this.name = this.constructor.name;
		this.placementId = placementId;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class UnusedValidateError extends PostOfficeError {
	constructor() {
		super(
			'Unexpected invocation of validate() function for push delivery channel',
			PostOfficeErrorCodes.UnusedValidateError,
		);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class RecipientExpanderMapRetrievalError extends PostOfficeError {
	constructor() {
		super('Recipient expander not found for mapper', PostOfficeErrorCodes.UnusedValidateError);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class GrowthRecRecommendationsError extends PostOfficeError {
	errors?: string[];

	constructor(message: string, errors?: string[]) {
		super(message, PostOfficeErrorCodes.GrowthRecRecommendationsError);
		this.errors = errors;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class RichRecipientError extends PostOfficeError {
	recipient: string;
	constructor(message: string, recipient: string) {
		super(message, PostOfficeErrorCodes.RichRecipientError);
		this.name = this.constructor.name;
		this.recipient = recipient;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class UnexpectedMobileNotificationsError extends Error {
	constructor(public status: number) {
		super('Posting Mobile Notifications message failed');
		this.status = status;
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class MobilePushPlacementError extends MessageTemplatePlacementError {
	constructor(message: string) {
		super(message);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class MessageTemplateGenericError extends MessageTemplatePlacementError {
	constructor(
		message: string,
		public error?: Error,
	) {
		super(message, {
			...(error ? { cause: error } : {}),
		});
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class MissingIngestionSourceError extends Error {
	constructor() {
		super('Ingestion source is required when custom profile is specified');
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class RenderRedactedNotFoundError extends Error {
	constructor() {
		super('A renderRedacted function is required when redactionLevel is set to a non-null value');
		this.name = this.constructor.name;
	}
}

export class UnexpectedAtlassianMailError extends Error {
	constructor(public status: number) {
		super('Enqueueing email failed');
		this.status = status;
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class PortalUserLevelError extends Error {
	constructor(
		override message: string,
		public status: number,
	) {
		super(message);
		this.name = this.constructor.name;
		Error.captureStackTrace(this, this.constructor);
	}
}

export class MessageInstanceFiltersBypassError extends Error {
	public msg: string;
	public messageInstanceId: string;
	constructor(msg: string, messageInstanceId: string, error?: unknown) {
		super(msg);
		this.msg = msg;
		this.messageInstanceId = messageInstanceId;

		if (error instanceof Error) {
			this.stack = error.stack;
		} else {
			Error.captureStackTrace(this, this.constructor);
		}

		this.name = this.constructor.name;
	}
}

export class HydrationDeliveryNoDataFoundError extends PostOfficeError {
	ipmNumber: string;
	variantFromStatsig: string;
	contentfulLocale: string;
	constructor({
		ipmNumber,
		variantFromStatsig,
		contentfulLocale,
	}: {
		ipmNumber: string;
		variantFromStatsig: string;
		contentfulLocale: string;
	}) {
		super(
			'hydrate delivery marketing-screen-space-flag-contentful-usecase No data found for the user',
			PostOfficeErrorCodes.HydrationDeliveryNoDataFoundError,
		);
		this.name = this.constructor.name;
		this.ipmNumber = ipmNumber;
		this.variantFromStatsig = variantFromStatsig;
		this.contentfulLocale = contentfulLocale;
		Error.captureStackTrace(this, this.constructor);
	}
}
