// import React from 'react';

import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import { CONFIG_ITEM_KEYS } from '../../config-items/config-item-keys';
import { type EditorPluginAIConfigItem } from '../../config-items/config-items';
import { type EditorPluginAIProvider } from '../../types';
import { createContinueInChatForAgent } from '../config-item-actions/chat';
import { createInsertAtCurrentPosition, createReplace } from '../config-item-actions/markdown';

import { messages } from './messages';

export const createAgentConfigItem = (
	agent: EditorAgent,
	editorPluginAIProvider: EditorPluginAIProvider,
): EditorPluginAIConfigItem => {
	const continueInChat = createContinueInChatForAgent({
		appearance: 'secondary',
		agent,
		product: editorPluginAIProvider.product,
		actionSideEffect: editorPluginAIProvider.actionSideEffects?.['continueInChat'],
		actionOverride: editorPluginAIProvider.actionOverrides?.['continueInChat'],
	});

	return {
		key: CONFIG_ITEM_KEYS.ROVO_AGENT,
		title: messages.title,
		description: messages.description,
		statusLozengeType: 'beta',
		promptHint: messages.promptHint,
		// Required, since hasInitialPreset guard in palette state machine
		// checks this property for whether to transition to 'preset label'
		// (agent palette mode) during initialisation.
		promptLabel: {
			defaultMessage: agent?.name,
			// Ignored via go/ees005
			// eslint-disable-next-line require-unicode-regexp
			id: `fabric.editor.ai.config.item.range.agent.${agent?.name.toLocaleLowerCase().replace(/\s+/g, '.') || 'label'}`,
		},
		disableInterrogation: () => false,
		agent,
		intentSchemaId: 'DISABLED',
		getBackendModel: () => 'assistance-service',
		actions: {
			empty: [
				continueInChat,
				createInsertAtCurrentPosition({ appearance: 'primary', isRovoAgentAction: true }),
			],
			selection: [
				continueInChat,
				createReplace({ appearance: 'primary', isRovoAgentAction: true }),
			],
		},
		secondaryActions: [],
	};
};
