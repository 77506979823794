import {
	ACTION,
	ACTION_SUBJECT,
	ACTION_SUBJECT_ID,
	EVENT_TYPE,
	type EditorAnalyticsAPI,
} from '@atlaskit/editor-common/analytics';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { type EditorView } from '@atlaskit/editor-prosemirror/view';
import { ErrorUtils } from '@atlassian/editor-ai-common/utils/errors';

import { type AIProactivePluginState } from '../../pm-plugins/ai-proactive/states';
import { getBlockFromRecommendationId } from '../../pm-plugins/ai-proactive/utils';
import type {
	EditorPluginAIProvider,
	ProactiveFeedbackMetadata,
	ProactiveTriggerSource,
} from '../../types';

export const isNthDismiss = (pluginState: AIProactivePluginState, nth: number): boolean => {
	return pluginState.dismissedCount % nth === 0;
};

export type LaunchProactiveFeedbackDialogParams = {
	recommendationId?: string;
	aiProactivePluginState: AIProactivePluginState;
	editorView: EditorView;
	locale?: string;
	sentiment: ProactiveFeedbackMetadata['sentiment'];
	product: EditorPluginAIProvider['product'];
	handleFeedbackSubmission: EditorPluginAIProvider['handleFeedbackSubmission'];
	triggeredFrom?: ProactiveTriggerSource;
	fireAnalyticsEvent?: EditorAnalyticsAPI['fireAnalyticsEvent'];
};

export async function launchProactiveFeedbackDialog({
	recommendationId,
	aiProactivePluginState,
	editorView,
	locale,
	sentiment,
	product,
	handleFeedbackSubmission,
	triggeredFrom,
	fireAnalyticsEvent,
}: LaunchProactiveFeedbackDialogParams) {
	if (!recommendationId || !handleFeedbackSubmission) {
		return;
	}

	// Fetch block & recommendation from state
	const { block, recommendation } = recommendationId
		? getBlockFromRecommendationId(aiProactivePluginState, recommendationId)
		: { block: undefined, recommendation: undefined };

	// Build the feedback payload
	const jsonTransformer = new JSONTransformer(editorView.state.schema);

	const feedbackPayload = {
		sentiment,
		getAIExperience: (hasUserConsent?: boolean) => ({
			browserLocale: window.navigator.languages,
			userLocale: locale,
			...(hasUserConsent && recommendation && block
				? {
						originalParagraph: block.text,
						suggestedParagraph: JSON.stringify(
							jsonTransformer.encode(recommendation.transformContent).content,
						),
						recommendationId: recommendation.id,
						triggeredFrom,
					}
				: {}),
		}),
		editorAttributes: { product },
	};

	// Submit & handle error
	try {
		await handleFeedbackSubmission(feedbackPayload);
	} catch (error) {
		fireAnalyticsEvent?.({
			action: ACTION.ERRORED,
			actionSubject: ACTION_SUBJECT.EDITOR,
			actionSubjectId: ACTION_SUBJECT_ID.AI_PROACTIVE_FEEDBACK_DIALOG,
			eventType: EVENT_TYPE.OPERATIONAL,
			attributes: {
				errorMessage: ErrorUtils.extractErrorMessage(error),
			},
		});
	}
}
