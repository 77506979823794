/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useRef } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { bind } from 'bind-event-listener';

import type { DocNode } from '@atlaskit/adf-schema';
import { ProviderFactory, type ProviderHandler } from '@atlaskit/editor-common/provider-factory';
import { ReactRenderer } from '@atlaskit/renderer';
import { RendererActionsContext } from '@atlaskit/renderer/actions';
import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import {
	isPartialCopy,
	isSelectionWithinElement,
} from '@atlassian/editor-ai-common/utils/renderer';

import { useAnalyticsFlow } from '../../../analytics/analytics-flow/analyticsFlowUtils';

type PreviewRendererProps = {
	jsonDocument: DocNode;
	showTelepointer: boolean;
	providerFactory: ProviderFactory;
	reduceMargins?: boolean;
};

export const PreviewRenderer = ({
	jsonDocument,
	showTelepointer,
	providerFactory,
	reduceMargins,
}: PreviewRendererProps) => {
	const analyticsFlow = useAnalyticsFlow();

	const previewRendererRef = useRef<HTMLDivElement>(null);
	const copiedOnceRef = useRef(false);

	// IMPORTANT: the renderer will call providerFactory.destroy() when it unmounts. This is why we need to duplicate
	// the providerFactory and add a heap of subscribers to it. This is to avoid this duplicate provider gettting out of
	// sync with the original providerFactory.
	const dupedProviderFactory = useRef(new ProviderFactory());
	useEffect(() => {
		const mentionHandler: ProviderHandler<'mentionProvider'> = (name, provider) => {
			dupedProviderFactory.current.setProvider(name, provider);
		};
		const emojiHandler: ProviderHandler<'emojiProvider'> = (name, provider) => {
			dupedProviderFactory.current.setProvider(name, provider);
		};
		const mediaHandler: ProviderHandler<'mediaProvider'> = (name, provider) => {
			dupedProviderFactory.current.setProvider(name, provider);
		};
		providerFactory.subscribe('mentionProvider', mentionHandler);
		providerFactory.subscribe('emojiProvider', emojiHandler);
		providerFactory.subscribe('mediaProvider', mediaHandler);

		return () => {
			providerFactory.unsubscribe('mentionProvider', mentionHandler);
			providerFactory.unsubscribe('emojiProvider', emojiHandler);
			providerFactory.unsubscribe('mediaProvider', mediaHandler);
		};
	}, [providerFactory]);

	// Ignored via go/ees005
	// eslint-disable-next-line require-await
	const onCopyHandler = useCallback(async () => {
		const previewRendererElement = previewRendererRef.current;
		if (!analyticsFlow || !previewRendererElement) {
			return;
		}

		if (!isSelectionWithinElement(previewRendererElement)) {
			return;
		}

		if (isPartialCopy(previewRendererRef.current)) {
			analyticsFlow.addAttributes({
				contentCopiedPartially: true,
			});
		}

		// exit if copied already for this preview
		if (copiedOnceRef.current) {
			return;
		}

		analyticsFlow.addAttributes({
			contentCopied: true,
		});
		copiedOnceRef.current = true;
	}, [analyticsFlow]);

	useEffect(() => {
		const unbind = bind(document, {
			type: 'copy',
			listener: onCopyHandler,
		});
		return unbind;
	}, [onCopyHandler, analyticsFlow]);

	// override renderer's syntax highlighting styles so it appears consistent with the editor
	const styles = [
		css({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.code-block .token.token': {
				fontStyle: 'normal',
				fontWeight: token('font.weight.regular'),
				color: token('color.text', N800),
			},
		}),
	];

	const reduceMarginStyles = css({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'.ak-renderer-document >:first-child': {
			marginTop: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'.ak-renderer-document >:last-child': {
			marginBottom: 0,
		},
	});

	if (reduceMargins) {
		styles.push(reduceMarginStyles);
	}

	return (
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<div data-testid="preview-renderer" css={styles} ref={previewRendererRef}>
			<RendererActionsContext>
				<ReactRenderer
					document={jsonDocument}
					media={{
						// We don't currently support images with captions
						allowLinking: false,
					}}
					// By default -- the renderer uses a "default schema" which supports all
					// the nodes and marks the markdown transformer can create.
					// see packages/editor/adf-schema/src/schema/default-schema.ts for details.
					useSpecBasedValidator
					addTelepointer={showTelepointer}
					dataProviders={dupedProviderFactory.current}
					allowPlaceholderText
				/>
			</RendererActionsContext>
		</div>
	);
};
