import React from 'react';

import type { ExtractInjectionAPI, ToolbarUIComponentFactory } from '@atlaskit/editor-common/types';
import { WithPluginState } from '@atlaskit/editor-common/with-plugin-state';

import { type AIPlugin } from '../editor-plugin-ai';
import type { AIButtonPluginState } from '../pm-plugins/ai-button/ai-button-plugin';
import { aiButtonPluginKey } from '../pm-plugins/ai-button/ai-button-plugin-key';
import { aiProactivePluginKey } from '../pm-plugins/ai-proactive/ai-proactive-plugin-key';
import { toggleProactiveAISuggestionDisplayWithAnalytics } from '../pm-plugins/ai-proactive/commands-with-analytics';
import { type AIProactivePluginState } from '../pm-plugins/ai-proactive/states';
import { getUnreadRecommendationsCount } from '../pm-plugins/ai-proactive/utils';
import { createOpenAIModalCommand } from '../pm-plugins/decoration/actions';
import { type AIGlobalOptIn, type EditorPluginAIProvider } from '../types';
import { AtlassianIntelligenceToolbarButton } from '../ui/components/AtlassianIntelligenceToolbarButton/AtlassianIntelligenceToolbarButton';

export const getPrimaryToolbarLegacyComponent =
	({
		api,
		editorPluginAIProvider,
		aiGlobalOptIn,
		isProactiveAISupported,
	}: {
		api: ExtractInjectionAPI<AIPlugin> | undefined;
		editorPluginAIProvider: EditorPluginAIProvider;
		aiGlobalOptIn: AIGlobalOptIn;
		isProactiveAISupported: boolean;
	}): ToolbarUIComponentFactory =>
	({ editorView }) => {
		return (
			<WithPluginState
				plugins={{
					pluginState: aiButtonPluginKey,
					proactivePluginState: aiProactivePluginKey,
				}}
				render={({ pluginState, proactivePluginState }) => {
					const aiButtonPluginState: AIButtonPluginState = pluginState;
					const aiProactivePluginState: AIProactivePluginState = proactivePluginState;

					const onClick = () => {
						const { state, dispatch } = editorView;
						const configItem = editorPluginAIProvider.baseGenerate;
						const openAIModalCommand = createOpenAIModalCommand({
							state,
							configItem,
							lastTriggeredFrom: 'mainToolbar',
							aiGlobalOptIn,
							triggeredFor: aiButtonPluginState.triggeredFor,
						});

						openAIModalCommand(state, dispatch);
					};

					const onProactiveClick = () => {
						const { state, dispatch } = editorView;
						toggleProactiveAISuggestionDisplayWithAnalytics(api?.analytics?.actions)('mainToolbar')(
							state,
							dispatch,
						);
					};

					const showLoading =
						!aiProactivePluginState?.isProactiveContextPanelOpen &&
						!!aiProactivePluginState?.showNewRecommendationsNotification;

					const unreadCount = !!aiProactivePluginState
						? getUnreadRecommendationsCount(aiProactivePluginState)
						: undefined;

					return (
						<>
							{isProactiveAISupported ? (
								<AtlassianIntelligenceToolbarButton
									ButtonWrapper={editorPluginAIProvider?.AIButtonWrapper}
									onClick={onProactiveClick}
									disabled={
										!aiProactivePluginState?.canProactiveContextPanelOpen &&
										!aiProactivePluginState?.isProactiveContextPanelOpen
									}
									loading={showLoading}
									unreadCount={unreadCount}
									view={editorView}
									proactive
								/>
							) : (
								<AtlassianIntelligenceToolbarButton
									ButtonWrapper={editorPluginAIProvider?.AIButtonWrapper}
									onClick={onClick}
									disabled={!aiButtonPluginState?.isSelectionValidForAIExperience}
									view={editorView}
								/>
							)}
						</>
					);
				}}
			/>
		);
	};
