import { useMemo } from 'react';

import type { EditorState } from '@atlaskit/editor-prosemirror/state';

import { getRovoAgentsPluginState } from '../../pm-plugins/rovo-agents/plugin-factory';

type UseAgentProps = {
	editorState: EditorState;
};

/**
 * A memoized getter for rovo plugin state (enabled, agents, etc).
 */
export function useAgents({ editorState }: UseAgentProps) {
	const pluginState = useMemo(() => {
		const rovoAgentsPluginState = getRovoAgentsPluginState(editorState);
		return rovoAgentsPluginState;
	}, [editorState]);

	return pluginState;
}
