import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import ChevronLeftIcon from '@atlaskit/icon/utility/migration/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { Flex, xcss } from '@atlaskit/primitives';

import { useChangeStager } from '@confluence/change-stager';

import { usePagination } from './usePagination';

const i18n = defineMessages({
	nextPageLabel: {
		id: 'space-permissions.next-page.aria-label',
		defaultMessage: 'Next page',
		description: 'Aria label for a next page button within a table',
	},
	previousPageLabel: {
		id: 'space-permissions.previous-page.aria-label',
		defaultMessage: 'Previous page',
		description: 'Aria label for a previous page button within a table',
	},
});

const arrowButtonStyle = xcss({
	justifyContent: 'center',
});

export const PaginationArrowsWrapper: React.FC = ({ children }) => {
	const { formatMessage } = useIntl();
	const { hasNextPage, hasPreviousPage, pushToCursorStack, popFromCursorStack } = usePagination();
	const { isEditing } = useChangeStager();
	const shouldPaginate = (hasNextPage || hasPreviousPage) && !isEditing;

	return (
		<>
			{children}
			{shouldPaginate && (
				<Flex xcss={arrowButtonStyle}>
					<Button
						onClick={popFromCursorStack}
						aria-label={formatMessage(i18n.previousPageLabel)}
						iconBefore={<ChevronLeftIcon label="" />}
						isDisabled={!hasPreviousPage}
					/>
					<Button
						onClick={pushToCursorStack}
						aria-label={formatMessage(i18n.nextPageLabel)}
						iconBefore={<ChevronRightIcon label="" />}
						isDisabled={!hasNextPage}
					/>
				</Flex>
			)}
		</>
	);
};
