import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@atlaskit/primitives';

import { ManagePermissionsInfoButton } from '@confluence/space-roles/entry-points/TableRow';

import type { UpdateGroupModalProps } from '../../dialogs/UpdateGroupModal';
import { UpdateGroupModal } from '../../dialogs/UpdateGroupModal';

import { PopupContent } from './PopupContent';

export const TableRowInfoButton = ({
	principalRoleAssignment,
	allPermissions,
	updateRole,
	updateCachedPermissions,
	refreshTableData,
}: Omit<UpdateGroupModalProps, 'onClose'>) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
	const { availableRoles } = principalRoleAssignment;

	const onInfoButtonClick = useCallback(() => {
		setIsPopupOpen(!isPopupOpen);
	}, [isPopupOpen]);

	const onPopupContentCtaClick = useCallback(() => {
		setIsPopupOpen(false);
		setIsUpdateModalOpen(true);
	}, []);

	const popupRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (popupRef.current) {
			const handleClickOutside = (e: MouseEvent) => {
				if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
					setIsPopupOpen(false);
				}
			};

			document.addEventListener('click', handleClickOutside);
			return () => document.removeEventListener('click', handleClickOutside);
		}
	});

	return (
		<>
			{isUpdateModalOpen && (
				<UpdateGroupModal
					principalRoleAssignment={principalRoleAssignment}
					spaceRoleOptions={availableRoles}
					allPermissions={allPermissions}
					updateRole={updateRole}
					updateCachedPermissions={updateCachedPermissions}
					onClose={() => setIsUpdateModalOpen(false)}
					refreshTableData={refreshTableData}
				/>
			)}
			<Box ref={popupRef}>
				<ManagePermissionsInfoButton
					isOpen={isPopupOpen}
					onClick={onInfoButtonClick}
					content={
						<PopupContent
							onCtaClick={onPopupContentCtaClick}
							roleId={principalRoleAssignment.roleId}
							availableRoles={availableRoles}
							permissions={principalRoleAssignment.permissions}
						/>
					}
				/>
			</Box>
		</>
	);
};
