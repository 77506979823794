// The below lint rules are disabled because Box relies on building styles at runtime from in Forge apps
/* eslint-disable @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */
/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */
/* eslint-disable @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */

import { xcss as platformXCSS } from '@atlaskit/primitives';
import { makeXCSSValidator } from '../../../utils';

type PlatformBoxProps = React.ComponentProps<typeof PlatformBox>;

const xcssValidator = makeXCSSValidator({
	// color related props
	color: true,
	boxShadow: true,
	opacity: true,
	backgroundColor: true,
	borderColor: true,
	borderBlockColor: true,
	borderBlockEndColor: true,
	borderBlockStartColor: true,
	borderBottomColor: true,
	borderInlineColor: true,
	borderInlineEndColor: true,
	borderInlineStartColor: true,
	borderLeftColor: true,
	borderRightColor: true,
	borderTopColor: true,

	// layout and space related props
	display: {
		supportedValues: ['block', 'inline-block', 'inline', 'none'],
	},
	flexGrow: {
		allowCSS: true,
	},
	width: {
		allowCSS: true,
	},
	height: {
		allowCSS: true,
	},
	minWidth: {
		allowCSS: true,
	},
	maxWidth: {
		allowCSS: true,
	},
	minHeight: {
		allowCSS: true,
	},
	maxHeight: {
		allowCSS: true,
	},
	margin: true,
	marginBlock: true,
	marginBlockEnd: true,
	marginBlockStart: true,
	marginBottom: true,
	marginInline: true,
	marginInlineEnd: true,
	marginInlineStart: true,
	marginLeft: true,
	marginRight: true,
	marginTop: true,
	padding: true,
	paddingBlock: true,
	paddingBlockEnd: true,
	paddingBlockStart: true,
	paddingBottom: true,
	paddingInline: true,
	paddingInlineEnd: true,
	paddingInlineStart: true,
	paddingLeft: true,
	paddingRight: true,
	paddingTop: true,

	// other box related props
	borderRadius: { supportedValues: ['border.radius'] },
	borderBottomLeftRadius: { supportedValues: ['border.radius'] },
	borderBottomRightRadius: { supportedValues: ['border.radius'] },
	borderTopLeftRadius: { supportedValues: ['border.radius'] },
	borderTopRightRadius: { supportedValues: ['border.radius'] },
	borderEndEndRadius: { supportedValues: ['border.radius'] },
	borderEndStartRadius: { supportedValues: ['border.radius'] },
	borderStartEndRadius: { supportedValues: ['border.radius'] },
	borderStartStartRadius: { supportedValues: ['border.radius'] },
	borderWidth: { supportedValues: ['border.width'] },
	borderBlockWidth: { supportedValues: ['border.width'] },
	borderBlockEndWidth: { supportedValues: ['border.width'] },
	borderBlockStartWidth: { supportedValues: ['border.width'] },
	borderBottomWidth: { supportedValues: ['border.width'] },
	borderInlineWidth: { supportedValues: ['border.width'] },
	borderInlineEndWidth: { supportedValues: ['border.width'] },
	borderInlineStartWidth: { supportedValues: ['border.width'] },
	borderLeftWidth: { supportedValues: ['border.width'] },
	borderRightWidth: { supportedValues: ['border.width'] },
	borderTopWidth: { supportedValues: ['border.width'] },

	// other props not in tokens based props
	borderTopStyle: {
		supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
	},
	borderBottomStyle: {
		supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
	},
	borderRightStyle: {
		supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
	},
	borderLeftStyle: {
		supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
	},
	borderStyle: {
		supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
	},
	position: {
		supportedValues: ['relative', 'static'],
	},
});

type XCSSProp = ReturnType<typeof xcssValidator>;

/**
 * A box is a generic container that provides managed access to design tokens.
 */
export const componentPropsConfig = {}

export type BoxProps = Pick<
	PlatformBoxProps,
	| 'children'
	| 'ref'
	| 'testId'
> & {
	/**
	 * A shorthand for `paddingBlock` and `paddingInline` together.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	padding?: PlatformBoxProps['padding'];

	/**
	 * The logical block start and end padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingBlock?: PlatformBoxProps['paddingBlock'];

	/**
	 * The logical block start padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingBlockStart?: PlatformBoxProps['paddingBlockStart'];

	/**
	 * The logical block end padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingBlockEnd?: PlatformBoxProps['paddingBlockEnd'];

	/**
	 * The logical inline start and end padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingInline?: PlatformBoxProps['paddingInline'];

	/**
	 * The logical inline end padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingInlineEnd?: PlatformBoxProps['paddingInlineEnd'];

	/**
	 * The logical inline start padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingInlineStart?: PlatformBoxProps['paddingInlineStart'];

	/**
	 * A token alias for background color. See: [Design tokens](https://atlassian.design/components/tokens/all-tokens)
	 * for a list of available colors.
	 *
	 * When the background color is set to a surface token, the current surface CSS variable
	 * will also be set to this value in the `Box` styles.
	 *
	 * @type [Background color tokens](https://atlassian.design/components/tokens/all-tokens#color-background)
	 */
	backgroundColor?: PlatformBoxProps['backgroundColor'];

	/**
	 * Apply a subset of permitted styles, powered by Atlassian Design System tokens.
	 * For a list of supported style properties on this component, see [here](/platform/forge/ui-kit/components/xcss).
	 *
	 * @type XCSSProp
	 */
	xcss?: XCSSProp;

	/**
	 * @type string
	 */
	role?: PlatformBoxProps['role'];

	/**
	 * @type ForgeComponent
	 */
	children?: PlatformBoxProps['children'];
};

type PlatformXCSSParam = Parameters<typeof platformXCSS>[0];

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::361864e071364186c69d61776aec6d81>>
 * @codegenId #box
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen box
 * @codegenParams { "defineOwnType": true, "props": { "as": false, "padding": true, "paddingBlock": true, "paddingBlockStart": true, "paddingBlockEnd": true, "paddingInline": true, "paddingInlineStart": true, "paddingInlineEnd": true, "backgroundColor": true, "children": true, "ref": true, "testId": true, "data-testid": false, "xcss": { "value": "xcss && platformXCSS(xcssValidator(xcss) as PlatformXCSSParam)" }, "role": true, "style": false } }
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::9529629bd4dfcf934c9dfc41516de683>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/box.tsx <<SignedSource::d494e9639c15f8d2e963619e4f6d4bf9>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/utils/surface-provider.tsx <<SignedSource::b2f47f7dfba2a18d1e5f39f5b93f4959>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/utils/types.tsx <<SignedSource::6aae50141ed52478c88909359e21c67b>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::764fa2a11181a03d6d87ec2b92e13239>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::4f9c0f527ba99a4723d89b2126ae70b1>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Box as PlatformBox } from '@atlaskit/primitives';

// Define the type for Box as BoxProps at the top of this file

/**
 * A box is a generic container that provides managed access to design tokens.
 */
export const Box = (props: Parameters<RenderFn>[0]) => {
  const {
    padding,
    paddingBlock,
    paddingBlockStart,
    paddingBlockEnd,
    paddingInline,
    paddingInlineStart,
    paddingInlineEnd,
    backgroundColor,
    ref,
    testId,
    xcss,
    role,
  } = props.forgeDoc.props as BoxProps;
  return (
    <PlatformBox
      padding={padding}
      paddingBlock={paddingBlock}
      paddingBlockStart={paddingBlockStart}
      paddingBlockEnd={paddingBlockEnd}
      paddingInline={paddingInline}
      paddingInlineStart={paddingInlineStart}
      paddingInlineEnd={paddingInlineEnd}
      backgroundColor={backgroundColor}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      ref={ref}
      testId={testId}
      xcss={xcss && platformXCSS(xcssValidator(xcss) as PlatformXCSSParam)}
      role={role}
    />
  );
};
/**
 * @codegenEnd
 */
