import uuid from 'uuid/v4';

import { ACTIONS } from './states';
import type { AIProactivePluginState, ProactiveAction } from './states';
import {
	markRecommendationViewed,
	getRecommendationByFilters,
	updateAvailableRecommendationIds,
	markAllRecommendationsViewed,
} from './utils';

export const reducer = (
	pluginState: AIProactivePluginState,
	action: ProactiveAction,
): AIProactivePluginState => {
	switch (action.type) {
		case ACTIONS.UPDATE_PLUGIN_STATE: {
			const availableRecommendationIds = updateAvailableRecommendationIds(
				action.data?.proactiveAIBlocks,
				pluginState,
			);
			return {
				...pluginState,
				...action.data,
				canProactiveContextPanelOpen: availableRecommendationIds.size > 0,
				availableRecommendationIds,
				filteredRecommendations: getRecommendationByFilters(pluginState),
			};
		}
		case ACTIONS.TOGGLE_PROACTIVE_ENABLED: {
			return {
				...pluginState,
				toggleCount: pluginState.toggleCount + 1,
				isProactiveEnabled: !pluginState.isProactiveEnabled,
				...(pluginState.isProactiveEnabled && {
					docChangeCountUntilLimit: 0,
				}),
			};
		}
		case ACTIONS.TOGGLE_PROACTIVE_CONTEXT_PANEL: {
			const isClosingPanel = pluginState.isProactiveContextPanelOpen;

			const updatedState = {
				...pluginState,
				isProactiveContextPanelOpen: !pluginState.isProactiveContextPanelOpen,
				analyticsAIInteractionId: uuid(),
				toggleCount: pluginState.toggleCount + 1,
				selectedRecommendationId: pluginState.isProactiveContextPanelOpen
					? undefined
					: pluginState.selectedRecommendationId,
			};

			if (isClosingPanel) {
				updatedState.proactiveAIBlocks = markAllRecommendationsViewed(pluginState);
			}

			return updatedState;
		}
		case ACTIONS.SELECT_PROACTIVE_RECOMMENDATION: {
			return {
				...pluginState,
				...action.data,
				proactiveAIBlocks: markRecommendationViewed(
					pluginState,
					action.data.selectedRecommendationId,
				),
				hoveredRecommendationId: !pluginState.allowInlineHoverHighlightWhileRecommendationSelected
					? undefined
					: pluginState.hoveredRecommendationId,
			};
		}
		case ACTIONS.TOGGLE_DISPLAY_CONTEXT_PANEL_SETTINGS: {
			return {
				...pluginState,
				...action.data,
				filteredRecommendations: getRecommendationByFilters({
					...pluginState,
					...action.data,
					settings: {
						...pluginState.settings,
						...action.data.settings,
					},
				}),
				settings: {
					...pluginState.settings,
					...action.data.settings,
				},
			};
		}
		case ACTIONS.UPDATE_IS_LOADING_STATE: {
			return {
				...pluginState,
				isLoading: action.data,
			};
		}
		default:
			return pluginState;
	}
};
