import { createActor } from 'xstate';

import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import { editorPluginAIMachine } from './editor-plugin-ai-machine';

/**
 * Weakmap cache for caching an [actor](https://xstate.js.org/docs/guides/actors.html#actor-api)
 * per EditorView.
 *
 * It contains a convenience function for returning an actor if it exists in the cache (`getActor`)
 */
class AIActorCache extends WeakMap<EditorView, ReturnType<typeof createActor>> {
	/**
	 * Returns an actor if it exists in the cache or creates and starts a new one.
	 *
	 * @param editorView
	 * @returns [actor](https://xstate.js.org/docs/guides/actors.html#actor-api)
	 */
	getActor(editorView: EditorView) {
		if (this.has(editorView)) {
			return this.get(editorView);
		}
		const editorPluginAIActor = createActor(editorPluginAIMachine);
		editorPluginAIActor.start();
		aiActorCache.set(editorView, editorPluginAIActor);
		return editorPluginAIActor;
	}
}

/**
 * Global cache used to manage an an [actor](https://xstate.js.org/docs/guides/actors.html#actor-api) per
 * EditorView for the AI Editor Plugin.
 */
export const aiActorCache = new AIActorCache();
