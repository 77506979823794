import React from 'react';
import { styled } from '@compiled/react';

import type { GlyphProps } from '@atlaskit/icon';
import PeopleGroupIcon from '@atlaskit/icon/core/people-group';
import GlobeIcon from '@atlaskit/icon/core/globe';

import { PermissionIcon } from '@confluence/site-permissions/entry-points/PermissionIcon';
import { useChangeStager } from '@confluence/change-stager';

import { SpacePermissionType } from '../graphql/__types__/SpacePermissionsUsersQuery';
import { PermissionDisplayType } from '../graphql/__types__/SpacePermissionsPrincipalsMutation';

import { PrincipalTableCell } from './PrincipalTableCell';
import { CELL_BORDER, ColumnKeys } from './usePermissionsTableHeaders';
import type { TableDataType } from './PermissionsTable';
import { AllCheckbox } from './AllCheckbox';
import { encodeChangeKey } from './changeUtils';

const makePermissionsArray = (itemsToRemove: SpacePermissionType[], arr: SpacePermissionType[]) => {
	return arr.filter((item) => !itemsToRemove.includes(item));
};

// Remove EXPORT_PAGE and REMOVE_MAIL for ALL_PERMISSIONS
export const ALL_PERMISSIONS: SpacePermissionType[] = makePermissionsArray(
	[SpacePermissionType.EXPORT_PAGE, SpacePermissionType.REMOVE_MAIL],
	Object.values(SpacePermissionType),
);
// Further remove ADMINISTER_SPACE, EXPORT_SPACE, and RESTRICTIONS for GUEST_PERMISSIONS
const GUEST_PERMISSIONS: SpacePermissionType[] = makePermissionsArray(
	[
		SpacePermissionType.ADMINISTER_SPACE,
		SpacePermissionType.EXPORT_SPACE,
		SpacePermissionType.SET_PAGE_PERMISSIONS,
	],
	ALL_PERMISSIONS,
);
// For Anonymous users, remove ADMINISTER_SPACE, ARCHIVE_PAGE, and RESTRICTIONS
export const ANONYMOUS_PERMISSIONS: SpacePermissionType[] = makePermissionsArray(
	[
		SpacePermissionType.ADMINISTER_SPACE,
		SpacePermissionType.ARCHIVE_PAGE,
		SpacePermissionType.SET_PAGE_PERMISSIONS,
	],
	ALL_PERMISSIONS,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const getIconValue = (
	permissionDisplayType: PermissionDisplayType,
): React.ComponentType<GlyphProps> | null => {
	if (permissionDisplayType === PermissionDisplayType.GROUP) {
		return PeopleGroupIcon;
	} else if (permissionDisplayType === PermissionDisplayType.ANONYMOUS) {
		return GlobeIcon;
	} else {
		return null;
	}
};

const shouldDisableCheckbox = (item: TableDataType, changes) => {
	// For new users
	if (item.permissions.length === 0)
		return Boolean(!changes || !changes[encodeChangeKey(SpacePermissionType.VIEW_SPACE, item.id)]);

	// For existing users
	return Boolean(
		changes && changes[encodeChangeKey(SpacePermissionType.VIEW_SPACE, item.id)] === false,
	);
};

const createTableRow = (isEditing: boolean, changes: any) => (item: TableDataType) => {
	const Icon = getIconValue(item.permissionDisplayType);
	const isGuest = item.permissionDisplayType === PermissionDisplayType.GUEST_USER;
	const isAnonymous = item.permissionDisplayType === PermissionDisplayType.ANONYMOUS;
	const isCheckboxDisabled = shouldDisableCheckbox(item, changes);

	const cells = [
		{
			key: ColumnKeys.PRINCIPAL,
			content: (
				<PrincipalTableCell
					Icon={Icon}
					avatarSrc={item.avatarSrc}
					name={item.name}
					subText={item.subtext}
					lozenge={item.lozenge}
					hasProfileBorder={item.hasProfileBorder}
				/>
			),
			role: 'rowheader',
		},
		{
			key: ColumnKeys.SELECT_ALL,
			// Adding this ternary here instead of at the object level reserves this space in the table,
			// ensuring that the other columns do not shift when switching between view and edit modes
			content: isEditing ? (
				<AllCheckbox
					item={item}
					allPermissions={
						isGuest ? GUEST_PERMISSIONS : isAnonymous ? ANONYMOUS_PERMISSIONS : ALL_PERMISSIONS
					}
				/>
			) : null,
			'aria-hidden': !isEditing,
		},
		{
			key: ColumnKeys.ALL_VIEW,
			content: (
				<IconWrapper>
					<PermissionIcon
						centered
						value={item.permissions.includes(SpacePermissionType.VIEW_SPACE)}
						changeKey={encodeChangeKey(SpacePermissionType.VIEW_SPACE, item.id)}
					/>
				</IconWrapper>
			),
			style: CELL_BORDER,
		},
		{
			key: ColumnKeys.ALL_DELETE_OWN,
			content: (
				<IconWrapper>
					<PermissionIcon
						centered
						value={item.permissions.includes(SpacePermissionType.REMOVE_OWN_CONTENT)}
						changeKey={encodeChangeKey(SpacePermissionType.REMOVE_OWN_CONTENT, item.id)}
						isDisabled={isCheckboxDisabled}
					/>
				</IconWrapper>
			),
		},
		{
			key: ColumnKeys.CONTENT_ADD,
			content: (
				<IconWrapper>
					<PermissionIcon
						centered
						value={item.permissions.includes(SpacePermissionType.CREATE_EDIT_PAGE)}
						changeKey={encodeChangeKey(SpacePermissionType.CREATE_EDIT_PAGE, item.id)}
						isDisabled={isCheckboxDisabled}
					/>
				</IconWrapper>
			),
			style: CELL_BORDER,
		},
		...(isAnonymous
			? []
			: [
					{
						key: ColumnKeys.CONTENT_ARCHIVE,
						content: (
							<IconWrapper>
								<PermissionIcon
									centered
									value={item.permissions.includes(SpacePermissionType.ARCHIVE_PAGE)}
									changeKey={encodeChangeKey(SpacePermissionType.ARCHIVE_PAGE, item.id)}
									isDisabled={isCheckboxDisabled}
								/>
							</IconWrapper>
						),
					},
				]),
		{
			key: ColumnKeys.CONTENT_DELETE,
			content: (
				<IconWrapper>
					<PermissionIcon
						centered
						value={item.permissions.includes(SpacePermissionType.REMOVE_PAGE)}
						changeKey={encodeChangeKey(SpacePermissionType.REMOVE_PAGE, item.id)}
						isDisabled={isCheckboxDisabled}
					/>
				</IconWrapper>
			),
		},
		{
			key: ColumnKeys.BLOG_ADD,
			content: (
				<IconWrapper>
					<PermissionIcon
						centered
						value={item.permissions.includes(SpacePermissionType.EDIT_BLOG)}
						changeKey={encodeChangeKey(SpacePermissionType.EDIT_BLOG, item.id)}
						isDisabled={isCheckboxDisabled}
					/>
				</IconWrapper>
			),
			style: CELL_BORDER,
		},
		{
			key: ColumnKeys.BLOG_DELETE,
			content: (
				<IconWrapper>
					<PermissionIcon
						centered
						value={item.permissions.includes(SpacePermissionType.REMOVE_BLOG)}
						changeKey={encodeChangeKey(SpacePermissionType.REMOVE_BLOG, item.id)}
						isDisabled={isCheckboxDisabled}
					/>
				</IconWrapper>
			),
		},
		{
			key: ColumnKeys.COMMENTS_ADD,
			content: (
				<IconWrapper>
					<PermissionIcon
						centered
						value={item.permissions.includes(SpacePermissionType.COMMENT)}
						changeKey={encodeChangeKey(SpacePermissionType.COMMENT, item.id)}
						isDisabled={isCheckboxDisabled}
					/>
				</IconWrapper>
			),
			style: CELL_BORDER,
		},
		{
			key: ColumnKeys.COMMENTS_DELETE,
			content: (
				<IconWrapper>
					<PermissionIcon
						centered
						value={item.permissions.includes(SpacePermissionType.REMOVE_COMMENT)}
						changeKey={encodeChangeKey(SpacePermissionType.REMOVE_COMMENT, item.id)}
						isDisabled={isCheckboxDisabled}
					/>
				</IconWrapper>
			),
		},
		{
			key: ColumnKeys.ATTACHMENTS_ADD,
			content: (
				<IconWrapper>
					<PermissionIcon
						centered
						value={item.permissions.includes(SpacePermissionType.CREATE_ATTACHMENT)}
						changeKey={encodeChangeKey(SpacePermissionType.CREATE_ATTACHMENT, item.id)}
						isDisabled={isCheckboxDisabled}
					/>
				</IconWrapper>
			),
			style: CELL_BORDER,
		},
		{
			key: ColumnKeys.ATTACHMENTS_DELETE,
			content: (
				<IconWrapper>
					<PermissionIcon
						centered
						value={item.permissions.includes(SpacePermissionType.REMOVE_ATTACHMENT)}
						changeKey={encodeChangeKey(SpacePermissionType.REMOVE_ATTACHMENT, item.id)}
						isDisabled={isCheckboxDisabled}
					/>
				</IconWrapper>
			),
		},
		...(isGuest || isAnonymous
			? []
			: [
					{
						key: ColumnKeys.RESTRICTIONS_ADD_DELETE,
						content: (
							<IconWrapper>
								<PermissionIcon
									centered
									value={item.permissions.includes(SpacePermissionType.SET_PAGE_PERMISSIONS)}
									changeKey={encodeChangeKey(SpacePermissionType.SET_PAGE_PERMISSIONS, item.id)}
									isDisabled={isCheckboxDisabled}
								/>
							</IconWrapper>
						),
						style: CELL_BORDER,
					},
				]),
		...(isGuest
			? []
			: [
					{
						key: ColumnKeys.SPACE_EXPORT,
						content: (
							<IconWrapper>
								<PermissionIcon
									centered
									value={item.permissions.includes(SpacePermissionType.EXPORT_SPACE)}
									changeKey={encodeChangeKey(SpacePermissionType.EXPORT_SPACE, item.id)}
									isDisabled={isCheckboxDisabled}
								/>
							</IconWrapper>
						),
						style: CELL_BORDER,
					},
				]),
		...(isGuest || isAnonymous
			? []
			: [
					{
						key: ColumnKeys.SPACE_ADMIN,
						content: (
							<IconWrapper>
								<PermissionIcon
									centered
									value={item.permissions.includes(SpacePermissionType.ADMINISTER_SPACE)}
									changeKey={encodeChangeKey(SpacePermissionType.ADMINISTER_SPACE, item.id)}
									isDisabled={isCheckboxDisabled}
								/>
							</IconWrapper>
						),
					},
				]),
	];

	return {
		key: item.id,
		cells,
	};
};

export const usePermissionsTableRows = ({ data }) => {
	const { isEditing, changes } = useChangeStager();
	if (!data) return [];

	const curriedCreateTableRow = createTableRow(isEditing, changes);
	return data.map((item: TableDataType) => curriedCreateTableRow(item));
};
