import { useCallback, useRef, useState } from 'react';

import { type ApiError, mapErrorToApiError } from '../api-error';

type MutateFunction<TPayload, TResponse> = (
	payload: TPayload,
	callbacks?: {
		onSuccess?: (data: TResponse) => void;
		onError?: (error: ApiError) => void;
	},
) => Promise<void>;

type UseMutationProps<TPayload, TResponse> = {
	mutationFn: (payload: TPayload) => Promise<TResponse>;
	onSuccess?: (data: TResponse) => void | Promise<void>;
	onError?: (error: ApiError) => void;
};

export const useMutation = <TPayload, TResponse = void>({
	mutationFn,
	onSuccess,
	onError,
}: UseMutationProps<TPayload, TResponse>): {
	data: TResponse | null;
	error: ApiError | null;
	isSubmitting: boolean;
	mutate: MutateFunction<TPayload, TResponse>;
} => {
	const mutationFnRef = useRef(mutationFn);
	mutationFnRef.current = mutationFn;

	const onSuccessRef = useRef(onSuccess);
	onSuccessRef.current = onSuccess;

	const onErrorRef = useRef(onError);
	onErrorRef.current = onError;

	const [data, setData] = useState<TResponse | null>(null);
	const [error, setError] = useState<ApiError | null>(null);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const mutate: MutateFunction<TPayload, TResponse> = useCallback(
		async (payload: TPayload, mutateCallback) => {
			setIsSubmitting(true);
			try {
				const response = await mutationFnRef.current(payload);
				setData(response);
				onSuccessRef.current?.(response);
				mutateCallback?.onSuccess?.(response);
			} catch (error: any) {
				const apiError = mapErrorToApiError(error);
				setError(apiError);
				onErrorRef.current?.(apiError);
				mutateCallback?.onError?.(apiError);
			} finally {
				setIsSubmitting(false);
			}
		},
		[],
	);

	return { data, error, isSubmitting, mutate };
};
