import React from 'react';

import { useIntl } from 'react-intl-next';

import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import Loading from '@atlassian/generative-ai-modal/assets/Loading';

import { type Recommendation } from '../pm-plugins/ai-proactive/api';
import type { EditorPluginAIProvider } from '../types';

import { HeadingItem } from './heading-item';
import messages from './messages';
import { RecommendationItem, SpellingGrammarItem } from './recommendation-item';
import { EnableSuggestionsState } from './states/enable-suggestions';
import { NoSuggestionsState } from './states/no-suggestions';
import { ReloadSuggestionsState } from './states/reload-suggestions';

type RecommendationPanelProps = {
	recommendations: Recommendation[];
	isLoading: boolean;
	hoveredRecommendationId: string | undefined;
	selectedRecommendationId: string | undefined;
	isSpellingGrammarEnabled: boolean;
	spellingGrammarErrorCount?: number;
	onRecommendationApply: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationDismiss: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationSelect: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationHover: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationLeave: ({ recommendation }: { recommendation: Recommendation }) => void;
	onClearSelectRecommendation: () => void;
	onPanelClose: () => void;
	onSpellingGrammar: () => void;
	onReloadSuggestions: () => void;
	onToggleSuggestions: () => void;
	onAskAI: () => void;
	editorPluginAIProvider: EditorPluginAIProvider;
	editorView: EditorView;
	displayAllSuggestions: boolean;
	isEmptyDocument: boolean;
	hasNoMoreSuggestions: boolean;
};

type EmptyStateProps = {
	isLoading: boolean;
	displayAllSuggestions: boolean;
	onReloadSuggestions: () => void;
	onToggleSuggestions: () => void;
	onAskAI: () => void;
	isEmptyDocument: boolean;
	hasNoMoreSuggestions: boolean;
};
const loadingStyles = xcss({
	paddingBlock: 'space.200',
	color: 'color.text.subtlest',
});
const panelContainerStyles = xcss({
	paddingInlineStart: 'space.0',
	height: '100%',
	width: '100%',
});

const emptyStateStyles = xcss({
	flex: 4,
});
// Add padding to the empty state to center it's center vertically (4/4+1)/2 = 40% from the top.
const padEmptyStateStyles = xcss({
	flex: 1,
});

const EmptyState = ({
	isLoading,
	displayAllSuggestions,
	onReloadSuggestions,
	isEmptyDocument,
	onToggleSuggestions,
	onAskAI,
	hasNoMoreSuggestions,
}: EmptyStateProps) => {
	const { formatMessage } = useIntl();
	if (!displayAllSuggestions) {
		return <EnableSuggestionsState handleEnable={onToggleSuggestions} />;
	}

	if (isLoading) {
		return (
			<Inline space="space.100" alignInline="center" grow="fill" xcss={loadingStyles}>
				<Loading alt="" />
				{formatMessage(messages.findingSuggestionsLoadingLabel)}
			</Inline>
		);
	}

	if (isEmptyDocument || hasNoMoreSuggestions) {
		return <NoSuggestionsState handleAskAI={onAskAI} />;
	}

	return <ReloadSuggestionsState handleReloadSuggestions={onReloadSuggestions} />;
};

export const RecommendationPanel = (props: RecommendationPanelProps) => {
	const {
		recommendations,
		isLoading,
		hoveredRecommendationId,
		selectedRecommendationId,
		isSpellingGrammarEnabled,
		spellingGrammarErrorCount,
		onRecommendationApply,
		onPanelClose,
		onRecommendationDismiss,
		onRecommendationSelect,
		onSpellingGrammar,
		onRecommendationHover,
		onRecommendationLeave,
		onReloadSuggestions,
		onToggleSuggestions,
		onAskAI,
		onClearSelectRecommendation,
		editorPluginAIProvider,
		editorView,
		displayAllSuggestions,
		isEmptyDocument,
		hasNoMoreSuggestions,
	} = props;

	const { formatMessage } = useIntl();

	return (
		<Stack xcss={panelContainerStyles} space="space.200">
			<HeadingItem
				onClose={onPanelClose}
				editorPluginAIProvider={editorPluginAIProvider}
				editorView={editorView}
				onClearSelectRecommendation={onClearSelectRecommendation}
			/>
			<Stack grow="fill">
				{recommendations?.length > 0 || (spellingGrammarErrorCount ?? 0) > 0 ? (
					<>
						{isSpellingGrammarEnabled && (spellingGrammarErrorCount ?? 0) > 0 && (
							<SpellingGrammarItem
								errorCount={spellingGrammarErrorCount ?? 0}
								onClick={onSpellingGrammar}
							/>
						)}
						{recommendations?.map((r) => (
							<RecommendationItem
								key={r.id}
								id={r.id}
								action={r.transformAction}
								isViewed={r.isViewed}
								isHovered={r.id === hoveredRecommendationId}
								isSelected={r.id === selectedRecommendationId}
								onApply={() => onRecommendationApply({ recommendation: r })}
								onDismiss={() => onRecommendationDismiss({ recommendation: r })}
								onClick={() => onRecommendationSelect({ recommendation: r })}
								onMouseEnter={() => onRecommendationHover({ recommendation: r })}
								onMouseLeave={() => onRecommendationLeave({ recommendation: r })}
								onPreview={() => onRecommendationSelect({ recommendation: r })}
							/>
						))}
						{isLoading && (
							<Inline space="space.100" grow="fill" xcss={loadingStyles}>
								<Loading alt="" />
								{formatMessage(messages.findingMoreSuggestionsLoadingLabel)}
							</Inline>
						)}
					</>
				) : (
					<>
						<Inline xcss={emptyStateStyles} alignBlock="center">
							<EmptyState
								isLoading={isLoading}
								displayAllSuggestions={displayAllSuggestions}
								onReloadSuggestions={onReloadSuggestions}
								onToggleSuggestions={onToggleSuggestions}
								onAskAI={onAskAI}
								isEmptyDocument={isEmptyDocument}
								hasNoMoreSuggestions={hasNoMoreSuggestions}
							/>
						</Inline>
						<Box xcss={padEmptyStateStyles}></Box>
					</>
				)}
			</Stack>
		</Stack>
	);
};
