import { useMemo } from 'react';

import { useElementSize } from './useElementSize';

export enum Breakpoint {
	Small = 320,
	Large = Infinity,
}

/** Get the current breakpoint of the element. */
export function useElementBreakpoints(smallBreakpoint: number = Breakpoint.Small): [
	(element: HTMLElement | null) => void,
	{
		breakpoint: Breakpoint;
		isSmall: boolean;
		isLarge: boolean;
	},
] {
	const [setElement, { width }] = useElementSize();

	const breakpoint = width < smallBreakpoint ? smallBreakpoint : Breakpoint.Large;

	return useMemo(
		() => [
			setElement,
			{
				breakpoint,
				isSmall: breakpoint <= smallBreakpoint,
				isLarge: breakpoint > smallBreakpoint,
			},
		],
		[breakpoint, setElement, smallBreakpoint],
	);
}
