import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	instructionsLabel: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.instructions.label',
		defaultMessage: 'Instructions',
		description: 'Label for the Instructions input in the prompt builder.',
	},
	instructionsPlaceholder: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.instructions.placeholder',
		defaultMessage: 'Write instructions, @mention someone',
		description: 'Placeholder for the Instructions input in the prompt builder.',
	},
	instructionsRequired: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.instructions.error.required',
		defaultMessage: 'Please provide instructions',
		description: 'Error message for the Instructions input in the prompt builder.',
	},
	audienceLabel: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.audience.label',
		defaultMessage: 'Audience',
		description: 'Label for the Audience input in the prompt builder.',
	},
	audiencePlaceholder: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.audience.placeholder',
		defaultMessage: 'e.g. Product designers',
		description: 'Placeholder for the Audience input in the prompt builder.',
	},
	audienceRequired: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.audience.error.required',
		defaultMessage: 'Please provide an audience',
		description: 'Error message for the Audience input in the prompt builder.',
	},
	toneLabel: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.tone.label',
		defaultMessage: 'Tone',
		description: 'Label for the Tone input in the prompt builder.',
	},
	toneProfessional: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.tone.option.professional',
		defaultMessage: 'Professional',
		description: 'Option for the Tone input in the prompt builder.',
	},
	toneEmpathetic: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.tone.option.empathetic',
		defaultMessage: 'Empathetic',
		description: 'Option for the Tone input in the prompt builder.',
	},
	toneCasual: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.tone.option.casual',
		defaultMessage: 'Casual',
		description: 'Option for the Tone input in the prompt builder.',
	},
	toneNeutral: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.tone.option.neutral',
		defaultMessage: 'Neutral',
		description: 'Option for the Tone input in the prompt builder.',
	},
	toneEducational: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.tone.option.educational',
		defaultMessage: 'Educational',
		description: 'Option for the Tone input in the prompt builder.',
	},
	creativityLabel: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.creativity.label',
		defaultMessage: 'Creativity',
		description: 'Label for the Creativity input in the prompt builder.',
	},
	creativityLow: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.creativity.option.low',
		defaultMessage: 'Low',
		description: 'Option for the Creativity input in the prompt builder.',
	},
	creativityMedium: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.creativity.option.medium',
		defaultMessage: 'Medium',
		description: 'Option for the Creativity input in the prompt builder.',
	},
	creativityHigh: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.promptBuilder.creativity.option.high',
		defaultMessage: 'High',
		description: 'Option for the Creativity input in the prompt builder.',
	},
});
