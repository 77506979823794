import gql from 'graphql-tag';

export const RecoverSpaceAdminPermissionMutation = gql`
	mutation RecoverSpaceAdminPermission($input: RecoverSpaceAdminPermissionInput!) {
		recoverSpaceAdminPermission(input: $input) {
			success
			errors {
				message
			}
		}
	}
`;
