import { defineMessages } from 'react-intl-next';

export default defineMessages({
	copy: {
		id: 'fabric.editor.ai.config.item.action.copyResponse',
		defaultMessage: 'Copy',
		description: 'Copy the response to the clipboard',
	},
	copied: {
		id: 'fabric.editor.ai.config.item.action.responseCopied',
		defaultMessage: 'Copied!',
		description: 'Copied the response to the clipboard',
	},
	copiedToClipboard: {
		id: 'fabric.editor.ai.config.item.action.responseCopiedToClipboard',
		defaultMessage: 'Copied to clipboard',
		description: 'Copied the response to the clipboard',
	},
});
