import React from 'react';

import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { useFormState } from '@atlaskit/form';

import messages from './messages';

export function SubmitButton() {
	const { formatMessage } = useIntl();

	const formState = useFormState<object>({
		valid: true,
	});

	return (
		<Button appearance="primary" type="submit" isDisabled={!formState?.valid}>
			{formatMessage(messages.button)}
		</Button>
	);
}
