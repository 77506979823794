import { LoadableLazy } from '@confluence/loadable';

export { useSpaceRolesLozenge } from './lozenges/useSpaceRolesLozenge';

export { RoleAssignmentPrincipalType } from './model/space-roles-types';

export const SpaceRolesLozenge = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceRolesLozenge" */ './lozenges/SpaceRolesLozenge'
			)
		).SpaceRolesLozenge,
});

export const SpaceRolesBannerBase = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceRolesBannerBase" */ './banners/SpaceRolesBannerBase'
			)
		).SpaceRolesBannerBase,
});

export const OnboardingModalBase = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-OnboardingModalBase" */ './onboarding-modal/OnboardingModalBase'
			)
		).OnboardingModalBase,
});
