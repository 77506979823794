import React from 'react';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/convertToBulletList';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';

import { messages } from './messages';

const intentSchemaId = 'convert_to_bullet_points.json';

export const convertToBulletList: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.CONVERT_TO_BULLETED_LIST,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	statusLozengeType: 'beta',
	intentSchemaId,
	actions: {
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
};

export const convertToBulletListWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: convertToBulletList,
	triggers: { empty: false, selection: true },
};
