/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import VisuallyHidden from '@atlaskit/visually-hidden';

import { useElementBreakpoints } from '../../hooks/useElementBreakpoints';

import {
	ErrorButton,
	ErrorButtonContainer,
	ErrorFloatingContainer,
	ErrorMessage,
} from './error-screen-parts';
import messages from './messages';

type Props = {
	onCancel: () => void;
	onRetry?: () => void;
	showRetryButton?: boolean;
	text: React.ReactNode;
};

export const ErrorScreen = ({ onCancel, onRetry, text, showRetryButton = true }: Props) => {
	const { formatMessage } = useIntl();
	const [setBreakpointsElement, { breakpoint }] = useElementBreakpoints();

	return (
		<ErrorFloatingContainer ref={setBreakpointsElement} breakpoint={breakpoint}>
			<VisuallyHidden>{formatMessage(messages.ariaErrorTitle)}</VisuallyHidden>
			<ErrorMessage breakpoint={breakpoint}>{text}</ErrorMessage>
			<ErrorButtonContainer>
				<ErrorButton
					autoFocus
					testId="cancel-button"
					onClick={onCancel}
					appearance={onRetry && showRetryButton ? 'subtle' : 'primary'}
				>
					{formatMessage(messages.cancelLabel)}
				</ErrorButton>
				{onRetry && showRetryButton && (
					<ErrorButton testId={'retry'} onClick={onRetry} appearance="primary">
						{formatMessage(messages.retryLabel)}
					</ErrorButton>
				)}
			</ErrorButtonContainer>
		</ErrorFloatingContainer>
	);
};
