import type { ReactNode } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { useChangeStager } from './ChangeStager';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledFlexBox = styled.div({
	paddingTop: token('space.300', '24px'),
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLeftPanel = styled.div({
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledRightPanel = styled.div({
	flexGrow: 0,
	marginLeft: token('space.050', '4px'),
});

export const HeaderWithChangeStagerControls = ({ children }: { children?: ReactNode }) => {
	const { ChangeStagerControls } = useChangeStager();
	return (
		<StyledFlexBox>
			<StyledLeftPanel>{children || null}</StyledLeftPanel>
			<StyledRightPanel>
				<ChangeStagerControls />
			</StyledRightPanel>
		</StyledFlexBox>
	);
};
