import { fg } from '@confluence/feature-gating';
import { getLogger } from '@confluence/logger';
import { preloadEditorPageCoverPicture } from '@confluence/page-cover-picture/entry-points/preloadEditorPageCoverPicture';
import { preloadEmojiTitle } from '@confluence/emoji-title/entry-points/preloadEmojiTitle';
import { preloadEditorContentState } from '@confluence/content-state/entry-points/preloadEditorContentState';
import { getDraftShareIdFromQueryParam } from '@confluence/route-manager/entry-points/getDraftShareIdFromQueryParam';
import { preloadDraftContentAppearance } from '@confluence/content-appearance/entry-points/preloadDraftContentAppearance';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { preloadLastUpdated } from '@confluence/content-header/entry-points/preloadLastUpdated';
import { preloadEditorPageTitleContentProperties } from '@confluence/content-topper/entry-points/preloadEditorPageTitleContentProperties';
import {
	preloadMentionsProvider,
	preloadMentionsUsersFromADF,
} from '@confluence/fabric-media-support/entry-points/preloadMentions';
import { preloadWebItemLocation } from '@confluence/web-item-location/entry-points/preloadWebItemLocation';
import { SYSTEM_CONTENT_BYLINE } from '@confluence/web-item-location';
import { preloadUseExtensionList } from '@confluence/forge-ui/entry-points/preloadUseExtensionList';
import { FORGE_MODULE_BYLINE } from '@confluence/forge-ui';

import {
	preloadCollabDraftContent,
	updateCollabDraftContent,
	preloadCollabServiceProperty,
	preloadNativeCollab,
	preloadNativeCollabProvider,
} from './preloadNativeEditor';
import type { IPreloadEditorData } from './types';

const logger = getLogger('load-edit-page');

export async function preloadEditorData({
	spaceKey,
	userId,
	contentId,
	preloadNativeCollabData = false,
	shouldPreloadMentionsProvider = false,
	shouldPreloadNativeEditorData = false, // use cache for native editor data
	catchFn,
	isLivePage = false,
}: IPreloadEditorData): Promise<void> {
	// note: explicitly casting to Promise<any> to avoid typescript incorrectly inferring tuple types; although we don't use the return value of this function regardless
	logger.debug`Calling preloadEditorData ${performance.now() / 1000} for ${{
		preloadNativeCollabData,
	}}`;
	const draftShareId = getDraftShareIdFromQueryParam();
	// preload queries shared by Synchrony and Native Collab editors
	const immediateResultsPromises: Array<Promise<any> | undefined> = [
		preloadDraftContentAppearance(contentId, shouldPreloadNativeEditorData),
	];

	if (preloadNativeCollabData) {
		// specific native collab editor queries
		if (!!contentId) {
			const draftPromise = preloadCollabDraftContent(contentId, shouldPreloadNativeEditorData).then(
				async (draft) => {
					const originalDocument = draft?.data?.collabDraft?.document;
					if (!originalDocument) {
						return;
					}

					// IMPORTANT: previously, Confluence draft had mentions populated, which allowed to
					// render them in the editor without additional requests. This is no longer the case
					// with NCS-based drafts, so we need to preload mentions and mutate the draft to match
					// the old behavior. This is temporary until we "hydrate" mentions at cc-graphql level.
					const document = await preloadMentionsUsersFromADF(originalDocument);
					if (document === originalDocument) {
						return;
					}

					return updateCollabDraftContent(contentId, {
						...draft.data,
						collabDraft: {
							...draft.data?.collabDraft,
							document,
						},
					});
				},
			);

			immediateResultsPromises.push(draftPromise);

			immediateResultsPromises.push(
				preloadCollabServiceProperty(contentId, draftShareId, shouldPreloadNativeEditorData),
				preloadNativeCollab(contentId, draftShareId, shouldPreloadNativeEditorData),
				preloadNativeCollabProvider(userId, shouldPreloadNativeEditorData),
			);
		}
	}

	immediateResultsPromises.push(
		preloadEmojiTitle(contentId, draftShareId, shouldPreloadNativeEditorData),
		preloadEditorPageCoverPicture(contentId, draftShareId, shouldPreloadNativeEditorData),
		preloadEditorContentState(contentId, shouldPreloadNativeEditorData),
	);

	if (isLivePage && fg('confluence_frontend_extension_points_in_live_pages')) {
		// Pre-loading apps in the byline area for live pages. Currently only live pages have extension point apps and not classic editor.
		immediateResultsPromises.push(
			preloadWebItemLocation({ contentId, location: SYSTEM_CONTENT_BYLINE }),
			// For some reason the addition of the filter (uses new Forge query) is causing SSR to timeout - workaround by skipping the filter
			preloadUseExtensionList({
				spaceKey: spaceKey || '',
				moduleType: FORGE_MODULE_BYLINE,
				skipFilter: true,
			}),
		);
	}

	if (isLivePage && fg('confluence_frontend_preload_last_updated')) {
		immediateResultsPromises.push(preloadLastUpdated(contentId, shouldPreloadNativeEditorData));
	}

	if (shouldPreloadMentionsProvider) {
		immediateResultsPromises.push(preloadMentionsProvider());
	}

	immediateResultsPromises.push(
		preloadEditorPageTitleContentProperties({
			contentId,
			draftShareId,
			useCache: shouldPreloadNativeEditorData,
		}),
	);

	await Promise.all(immediateResultsPromises.map((p) => (!!catchFn ? p?.catch(catchFn) : p)));

	logger.debug`preloadEditorData complete ${performance.now() / 1000}`;
}
