import { Decoration } from '@atlaskit/editor-prosemirror/view';

import {
	type DecorationTransformerParams,
	filterDecorationByKey,
	ProactiveDecorations,
} from './index';

export const removeSelectedRecommendationDecoration = ({
	decorationSet,
	pluginState,
}: DecorationTransformerParams) => {
	return decorationSet.remove(
		filterDecorationByKey(ProactiveDecorations.RECOMMENDATION_SELECTED, decorationSet),
	);
};

export const addSelectedRecommendationDecoration = ({
	decorationSet,
	tr,
	pluginState,
}: DecorationTransformerParams) => {
	const { proactiveAIBlocks, selectedRecommendationId } = pluginState;

	if (!selectedRecommendationId || !proactiveAIBlocks.length) {
		return decorationSet;
	}

	const selectedBlock = proactiveAIBlocks.find(
		(block) => !!block.recommendations?.some((r) => r.id === selectedRecommendationId),
	);

	if (!!selectedBlock) {
		const { from, to } = selectedBlock;

		return decorationSet.add(tr.doc, [
			Decoration.inline(
				from,
				to,
				{
					class: `ai-proactive-recommendation-selected`,
				},
				{
					key: ProactiveDecorations.RECOMMENDATION_SELECTED,
				},
			),
		]);
	}

	return decorationSet;
};
