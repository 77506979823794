import debounce from 'lodash/debounce';

import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { ExtractInjectionAPI, PMPluginFactoryParams } from '@atlaskit/editor-common/types';
import { countNodes } from '@atlaskit/editor-common/utils';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';
import { type MentionNameDetails } from '@atlaskit/mention';
import { RateLimiter } from '@atlassian/editor-ai-common/api/rate-limiter';

import type { AIPlugin } from '../../editor-plugin-ai';
import type { ProactiveAIConfig } from '../../types';
import { DocumentChunkScanner } from '../../utils/document-checker';

import { aiProactivePluginKey } from './ai-proactive-plugin-key';
import {
	clearSelectRecommendation,
	createTriggerProactiveCheck,
	resetChunksForDocChecker,
	startWholeDocumentInitialScan,
	updateChunksWithRecommendations,
} from './commands';
import {
	closeProactiveAISuggestionDisplayWithAnalytics,
	disableNeedProactiveRecommendationsWithAnalytics,
	selectRecommendationWithAnalytics,
} from './commands-with-analytics';
import { RATE_LIMITER_ATTEMPTS, RATE_LIMITER_DELAY, RATE_LIMITER_JITTER } from './constants';
import { createInitialState, createPluginState, getPluginState } from './plugin-factory';
import { getFilteredRecommendationsAtPosition } from './utils';

/**
 * Plugin factory
 */
export function createProactiveAIPlugin(options: {
	dispatch: PMPluginFactoryParams['dispatch'];
	getIntl: PMPluginFactoryParams['getIntl'];
	proactiveAIConfig: ProactiveAIConfig;
	product: string;
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	getMentionNameDetails?: (id: string) => Promise<MentionNameDetails | undefined>;
}) {
	const { dispatch, getIntl, proactiveAIConfig, product, api, getMentionNameDetails } = options;
	const analyticsApi = api?.analytics?.actions;

	return new SafePlugin({
		key: aiProactivePluginKey,
		state: createPluginState(
			dispatch,
			createInitialState({
				proactiveAIApiUrl: proactiveAIConfig.apiUrl,
				documentChecker: proactiveAIConfig.documentChecker?.enabled
					? new DocumentChunkScanner(proactiveAIConfig.documentChecker, {
							getChunks: (state) => {
								const pluginState = getPluginState(state);
								return (pluginState?.proactiveAIBlocks ?? []).filter(
									(chunk) => chunk.text && !!chunk.invalidatedForDocChecker,
								);
							},
							triggerCallback: (chunks, view) =>
								updateChunksWithRecommendations({
									analyticsApi,
									chunks,
									context: {
										view,
										locale: getIntl().locale,
										getMentionNameDetails: getMentionNameDetails,
									},
								}),
							resetChunksForDocChecker: (view) =>
								resetChunksForDocChecker()(view.state, view.dispatch),
							onScanComplete: (view, duration) => {
								const { availableRecommendationIds, insertionCount, dismissedCount } =
									getPluginState(view.state);
								api?.analytics?.actions.fireAnalyticsEvent({
									// @ts-expect-error - Temporarily added analytic event for proactive document scanner completion event
									action: 'docScanCompleted',
									// @ts-expect-error - Temporarily added analytic event for proactive document scanner completion event
									actionSubject: 'editorPluginAI',
									// @ts-expect-error - Temporarily added analytic event for proactive document scanner completion event
									actionSubjectId: 'proactiveSuggestion',
									attributes: {
										duration,
										documentSize: view.state.doc.nodeSize,
										nodeCount: countNodes(view.state).nodeCount,
										totalSuggestions: availableRecommendationIds.size,
										totalAcceptedSuggestions: insertionCount,
										totalDismissedSuggestions: dismissedCount,
									},
									eventType: EVENT_TYPE.TRACK,
								});
							},
						})
					: undefined,
				rateLimiter: new RateLimiter(
					RATE_LIMITER_DELAY,
					RATE_LIMITER_JITTER,
					RATE_LIMITER_ATTEMPTS,
				),
				defaultToggledState: proactiveAIConfig.defaultToggledState,
				product: product,
			}),
		),
		view(view: EditorView) {
			const { documentChecker, rateLimiter } = getPluginState(view.state);
			const { locale } = getIntl();
			const {
				triggerProactiveCheck,
				cancelDebouncedAndThrottledCheck,
				triggerProactiveCheckImmediately,
			} = createTriggerProactiveCheck({
				analyticsApi,
				timings: proactiveAIConfig.timings,
			});

			if (documentChecker) {
				// If view updates then reset document checker. That will stop checking for S+G for existing blocks.
				documentChecker.setView(view);
			}

			const cleanupRateLimiter = rateLimiter?.init({
				onRetry: () =>
					triggerProactiveCheckImmediately({
						view,
						locale,
						getMentionNameDetails: getMentionNameDetails,
					}),
				onRetryEnqueue: () => {
					documentChecker?.stop();
					cancelDebouncedAndThrottledCheck();
				},
				onRetriesExhausted: () => {
					// When the rate limiter has reached its max attempts we need to disable all blocks which are pending a server check
					disableNeedProactiveRecommendationsWithAnalytics(analyticsApi)('rateLimiterMaxRetries')(
						view.state,
						view.dispatch,
					);
				},
			});

			const unsubscribeViewModeChange = api?.editorViewMode?.sharedState.onChange((sharedState) => {
				if (
					sharedState.nextSharedState?.mode !== sharedState.prevSharedState?.mode &&
					sharedState.nextSharedState?.mode !== 'edit'
				) {
					closeProactiveAISuggestionDisplayWithAnalytics(analyticsApi)('viewModeChanged')(
						view.state,
						view.dispatch,
					);
				}
			});

			const debounceStartWholeDocumentInitialScan = debounce(startWholeDocumentInitialScan(), 1000);
			if (view.dispatch) {
				debounceStartWholeDocumentInitialScan(view.state, view.dispatch);
			}

			return {
				update: (view: EditorView, prevState: EditorState) => {
					const { isProactiveEnabled } = getPluginState(view.state);
					if (
						isProactiveEnabled &&
						(!prevState.doc.eq(view.state.doc) || !prevState.selection.eq(view.state.selection))
					) {
						triggerProactiveCheck({
							view,
							locale,
							getMentionNameDetails: getMentionNameDetails,
						});
					}
				},
				destroy: () => {
					unsubscribeViewModeChange?.();
					cancelDebouncedAndThrottledCheck();
					/**
					 * Here we will reset regardless of documentCheckerEnabled or not.
					 * In case if proactiveAIConfig is changed between when view is destroyed
					 * 	and we goes from enabled documentChecker to disabled one.
					 */
					documentChecker?.reset();

					cleanupRateLimiter?.();

					debounceStartWholeDocumentInitialScan?.cancel();
				},
			};
		},
		props: {
			decorations: (state): DecorationSet | undefined => {
				const {
					isProactiveEnabled,
					decorationSet,
					alwaysDisplayProactiveInlineDecorations,
					isProactiveContextPanelOpen,
					settings: { displayAllSuggestions },
				} = getPluginState(state);

				if (
					!isProactiveEnabled ||
					api?.editorViewMode?.sharedState.currentState()?.mode !== 'edit' ||
					!displayAllSuggestions
				) {
					return DecorationSet.empty;
				}

				if (alwaysDisplayProactiveInlineDecorations || isProactiveContextPanelOpen) {
					return decorationSet;
				}

				return DecorationSet.empty;
			},
			handleClick: (view, pos, event) => {
				const { state, dispatch } = view;
				if (state.selection.from !== state.selection.to) {
					return false;
				}

				const pluginState = getPluginState(state);

				const { isProactiveEnabled, selectedRecommendationId, isProactiveContextPanelOpen } =
					pluginState;

				if (!isProactiveEnabled || !isProactiveContextPanelOpen) {
					return false;
				}

				if (selectedRecommendationId) {
					return clearSelectRecommendation()(state, dispatch);
				} else {
					const filteredRecommendations = getFilteredRecommendationsAtPosition(pluginState, pos);
					const currentSelectedRecommendationId = filteredRecommendations?.[0]?.id;
					if (currentSelectedRecommendationId) {
						return selectRecommendationWithAnalytics(analyticsApi)(currentSelectedRecommendationId)(
							state,
							dispatch,
						);
					}
				}

				return false;
			},
		},
	});
}
