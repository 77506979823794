import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Text, Box, Stack, xcss } from '@atlaskit/primitives';

import { Link } from '@confluence/route-manager';

const i18n = defineMessages({
	sectionTitle: {
		id: 'default-space-permissions.section-title',
		defaultMessage: 'Users',
		description:
			'Title of the default space permissions section that site admins will see when they visit the default space permissions page.',
	},
	sectionBody: {
		id: 'default-space-permissions.section-body',
		defaultMessage: 'These groups of people will have access by default to any new space created.',
		description:
			'Body of the default space permissions section that site admins will see when they visit the default space permissions page.',
	},
	learnMoreLink: {
		id: 'default-space-permissions.learn-more-link',
		defaultMessage: 'Learn more',
		description:
			'Link to learn more about default space permissions that site admins will see when they visit the default space permissions page.',
	},
});

const LEARN_MORE_DEFAULT_ROLES =
	'https://confluence-permissions-help.atlassian.net/wiki/external/MTQ4ZDk4OTI3MmYyNGI5MzljYjFkMTE0OGZiMDA1ZDk';

const sectionBodyStyle = xcss({
	display: 'flex',
	gap: 'space.050',
	alignItems: 'center',
});

export const SectionContent = () => {
	const { formatMessage } = useIntl();

	return (
		<Stack>
			<Box>
				<Heading size="large">{formatMessage(i18n.sectionTitle)}</Heading>
			</Box>
			<Box xcss={sectionBodyStyle}>
				<Text as="p">{formatMessage(i18n.sectionBody)}</Text>
				<Link href={LEARN_MORE_DEFAULT_ROLES}>{formatMessage(i18n.learnMoreLink)}</Link>
			</Box>
		</Stack>
	);
};
