import type { Node as PMNode } from '@atlaskit/editor-prosemirror/model';

import type { MarkdownSerializerState } from './serializer';

const isHeaderRow = (row: PMNode): boolean => row.child(0).type.name === 'tableHeader';

const isHeaderRowPresent = (table: PMNode): boolean => {
	let headerRowPresent = false;
	table.content.forEach((row: PMNode) => {
		if (isHeaderRow(row)) {
			headerRowPresent = true;
		}
	});
	return headerRowPresent;
};

const renderNode = (state: MarkdownSerializerState, node: PMNode, index: number): void => {
	if (index > 0) {
		state.write(' ');
	}
	node.content.forEach((child: PMNode, i: number) => {
		if (child.isTextblock || child.type.name === 'mediaSingle') {
			if (i > 0) {
				state.write(' ');
			}
			// Ignored via go/ees005
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(state as any).context.insideTable = true;
			state.renderInline(child);
			// Ignored via go/ees005
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(state as any).context.insideTable = false;
		} else {
			renderNode(state, child, i);
		}
	});
};

const renderInlineContent = (
	state: MarkdownSerializerState,
	node: PMNode,
	_parent: PMNode,
	_index: number,
	options: { headerRowPresent: boolean },
) => {
	options.headerRowPresent && state.write('| ');
	renderNode(state, node, 0);
	state.write(' ');
};

export default {
	table(state: MarkdownSerializerState, node: PMNode) {
		const headerRowPresent = isHeaderRowPresent(node);
		node.content.forEach((child, i) => {
			try {
				state.render(child, node, i, {
					headerRowPresent,
					skipFallback: true,
				});
			} catch (error) {
				state.updateFallbackState(error, node);
				state.nodes.fallback(state, node);
			}
		});
		state.closeBlock(node);
	},
	tableRow(
		state: MarkdownSerializerState,
		node: PMNode,
		_parent: PMNode,
		_index: number,
		options: { headerRowPresent: boolean },
	) {
		node.content.forEach((child, i) => {
			try {
				state.render(child, node, i, { ...options, skipFallback: true });
			} catch (error) {
				state.updateFallbackState(error, node);
				state.nodes.fallback(state, node);
			}
		});
		if (options.headerRowPresent) {
			state.write('|');
			state.ensureNewLine();
			if (isHeaderRow(node)) {
				for (let i = 0; i < node.childCount; i++) {
					state.write('| --- ');
				}
				state.write('|');
				state.ensureNewLine();
			}
		}
	},
	tableHeader(
		state: MarkdownSerializerState,
		node: PMNode,
		_parent: PMNode,
		_index: number,
		options: {
			headerRowPresent: boolean;
		},
	) {
		renderInlineContent(state, node, _parent, _index, options);
	},
	tableCell(
		state: MarkdownSerializerState,
		node: PMNode,
		_parent: PMNode,
		_index: number,
		options: {
			headerRowPresent: boolean;
		},
	) {
		renderInlineContent(state, node, _parent, _index, options);
	},
};
