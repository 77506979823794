import React from 'react';

import type { NewCoreIconProps } from '@atlaskit/icon';
import SVG from '@atlaskit/icon/svg';

// TODO: Replace with @atlaskit/icon-lab/suggested-panel once it is available.
const SuggestedPanelIcon = (props: NewCoreIconProps) => {
	const { color, label } = props;
	return (
		<SVG primaryColor={color} label={label}>
			<path
				d="M2.5 4.5H8M2.5 7.5H13.5M15.25 9.25V2.75C15.25 1.64543 14.3546 0.75 13.25 0.75H2.75C1.64543 0.75 0.75 1.64543 0.75 2.75V9.25C0.75 10.3546 1.64543 11.25 2.75 11.25H13.25C14.3546 11.25 15.25 10.3546 15.25 9.25Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinejoin="round"
				transform="translate(4,4)"
			/>
		</SVG>
	);
};

export default SuggestedPanelIcon;
