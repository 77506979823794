import gql from 'graphql-tag';

export const DeleteDefaultSpaceRoleAssignments = gql`
	mutation DeleteDefaultSpaceRoleAssignments($input: DeleteDefaultSpaceRoleAssignmentsInput!) {
		deleteDefaultSpaceRoleAssignments(input: $input) {
			errors {
				message
				extensions {
					errorType
					statusCode
				}
			}
			success
		}
	}
`;
