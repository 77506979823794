import { fg } from '@confluence/feature-gating';
import { expVal } from '@confluence/feature-experiments';

import { useNav4OptOut } from './useNav4OptOut';

// eslint-disable-next-line confluence-feature-gating/inline-usage
export const useIsNav4Enabled = () => {
	const { nav4OptOut } = useNav4OptOut();

	if (nav4OptOut) {
		return false;
	}

	return (
		fg('confluence_nav_4') ||
		expVal('confluence_jira_nav4_new_customers', 'willShowNav4', false) ||
		expVal('confluence_nav_4_free', 'isEnabled', false)
	);
};
