import { useContext } from 'react';
import type { EgressFilteringService } from '@forge/egress';
import { useEgressPermissionService } from '../../utils/useEgressPermissionService';
import { RendererContext } from '../../context';

export const isLocalImage = (url: string, iframeDomainName: string | undefined): boolean => {
	if (!iframeDomainName) {
		return false;
	}

	let imageSrcUrl;
	try {
		imageSrcUrl = new URL(url);
		// Image is valid if it comes from the same domain as the iframe OR if it's being served from localhost (when using forge tunnel)
		return iframeDomainName === imageSrcUrl.host || imageSrcUrl.hostname === 'localhost';
	} catch (e) {
		return false;
	}
};

export const useIsValidImageUrl = (url: string): boolean => {
	const { egress, appDomainName } = useContext(RendererContext);

	const egressService = useEgressPermissionService('IMAGES', egress);

	return isValidImageUrl(url, egressService, appDomainName);
};

export const isValidImageUrl = (
	url: string,
	egressService: EgressFilteringService,
	appDomainName: string | undefined,
): boolean => {
	const dataImageUrlRegex = /^data:image/;

	try {
		const isValidUrl =
			isLocalImage(url, appDomainName) ||
			dataImageUrlRegex.test(url) ||
			egressService.isValidUrl(url);

		return isValidUrl;
	} catch (e) {
		return false;
	}
};
