import { ACTIONS } from './states';
import type { RovoAgentAction, RovoAgentsPluginState } from './states';

export const reducer = (
	pluginState: RovoAgentsPluginState,
	action: RovoAgentAction,
): RovoAgentsPluginState => {
	switch (action.type) {
		case ACTIONS.UPDATE_PLUGIN_STATE:
			return {
				...pluginState,
				...action.data,
			};

		default:
			return pluginState;
	}
};
