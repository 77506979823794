import type { ExtractInjectionAPI } from '@atlaskit/editor-common/types';
import type { ReleaseHiddenDecoration } from '@atlaskit/editor-plugin-selection-marker';
import type { PluginSpec } from '@atlaskit/editor-prosemirror/state';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import type { AIPlugin } from '../../../editor-plugin-ai';
import type { getPluginState as getDecorationPluginState } from '../decoration-plugin-factory';

export const manageSelectionMarkerView: (
	getPluginState: typeof getDecorationPluginState,
	api: ExtractInjectionAPI<AIPlugin> | undefined,
) => PluginSpec<unknown>['view'] = (getPluginState, api) => () => {
	// If the plugin doesn't exist let's avoid running this code.
	if (!api?.selectionMarker) {
		return {};
	}
	let releaseSelectionMarker: ReleaseHiddenDecoration | undefined;

	return {
		update: (editorView) => {
			const pluginState = getPluginState(editorView.state);
			const modalIsOpen = pluginState.modalDecorationSet !== DecorationSet.empty;

			if (modalIsOpen && !releaseSelectionMarker) {
				releaseSelectionMarker = api?.selectionMarker?.actions.hideDecoration();
			} else if (!modalIsOpen && releaseSelectionMarker) {
				releaseSelectionMarker();
				releaseSelectionMarker = undefined;
			}
		},
		destroy: () => {
			releaseSelectionMarker?.();
		},
	};
};
