/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useMemo } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

import { type JSONDocNode } from '@atlaskit/editor-json-transformer';

// The types PromptEditorType should mirror what is in the editor-ai-injected-editors package
// at `platform/packages/editor/editor-ai-injected-editors/src/editors/types.ts.
export type PromptEditorType = 'user-input' | 'interrogate' | 'advanced-prompt' | 'preview-editor';

// The types PromptEditorProps and PromptEditor should mirror what is in the editor-ai-injected-editors package
// at `packages/editor/editor-ai-injected-editors/src/editors/prompt.tsx`.
type PromptEditorProps = {
	type?: PromptEditorType;
	isInvalid?: boolean;
	autoFocus?: boolean;
	defaultValue?: JSONDocNode;
	placeholder?: string;
	onInputChange?: (input: string) => void;
	onADFChange?: (input: JSONDocNode) => void;
	onBlur?: () => void;
	onFocus?: () => void;
	setFocusHandlers?: (focusFn?: () => boolean, blurFn?: () => boolean) => void;
	/**
	 * Using separate setter function for clear as focus and blur
	 *  is set from editorApi while clear from editorActions.
	 * They both can be available at different time.
	 * So it's safest to have separate setter function.
	 */
	setClearHandler?: (clearFn?: () => boolean) => void;
	/**
	 * Having links in Editor is giving false impression to customers that
	 *  we have started supporting links, but we haven't yet as backend will
	 *  still point to xp-gen-ai and leading to hallucinations.
	 * So we have tied enabling links to ConvoAI FF through this prop.
	 */
	enableLinks?: boolean;
};
export type PromptEditor = (props: PromptEditorProps) => JSX.Element;

export type PreviewEditorProps = {
	PromptEditor: PromptEditor;
	autoFocus: boolean;
	defaultValue?: JSONDocNode;
	onADFChange?: (inputValue: JSONDocNode) => void;
};

export const PreviewEditor = ({
	PromptEditor,
	defaultValue,
	autoFocus,
	onADFChange,
}: PreviewEditorProps) => {
	const componentId = useMemo(() => `preview-editor-${uuidv4()}`, []);

	return (
		// eslint-disable-next-line @atlaskit/design-system/prefer-primitives, jsx-a11y/no-static-element-interactions
		<div css={[]} data-testid="ai-experience-preview-editor-wrapper">
			<PromptEditor
				type={'preview-editor'}
				key={componentId}
				autoFocus={autoFocus}
				defaultValue={defaultValue}
				onADFChange={onADFChange}
				enableLinks={true}
			/>
		</div>
	);
};
