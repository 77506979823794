import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.rephrase.title',
		defaultMessage: 'Rephrase',
		description:
			'Describes an option to use Atlassian Intelligence to rephrase the content currently selected by the user',
	},
	description: {
		id: 'fabric.editor.ai.config.item.rephrase.description',
		defaultMessage: 'Creates a suggestion for rewriting your sentences',
		description: 'Description for the the Atlassian Intelligence "Rephrase".',
	},
});
