/* eslint-disable react/jsx-props-no-spreading */
/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import React, { useCallback, useMemo } from 'react';

import { jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled
import { useIntl } from 'react-intl-next';

import { Field, ErrorMessage } from '@atlaskit/form';
import { Grid, Flex } from '@atlaskit/primitives';
import Select, { type ValueType } from '@atlaskit/select';
import TextField from '@atlaskit/textfield';

import type {
	AdvancedPromptFieldAdfValue,
	EditorPluginAIPromptBuilderProps,
} from '../../../../config-items/config-items';
import { PromptBuilderForm } from '../../../../ui/components/PromptBuilderFormLayout/PromptBuilderForm';

import { messages } from './messages';

type Tone = 'professional' | 'empathetic' | 'casual' | 'neutral' | 'educational';
type Creativity = 'high' | 'medium' | 'low';

type PromptValue = {
	instructions: AdvancedPromptFieldAdfValue;
	audience: string;
	tone: Tone;
	creativity: Creativity;
};

interface Option<Value> {
	label: string;
	value: Value;
}

interface FormValue {
	instructions: AdvancedPromptFieldAdfValue;
	audience: string;
	tone: Option<Tone> | undefined;
	creativity: Option<Creativity> | undefined;
}

const defaultTone: Tone = 'neutral';
const defaultCreativity: Creativity = 'medium';
const emptyAdfValue: AdvancedPromptFieldAdfValue = {
	adf: { version: 1, type: 'doc', content: [] },
	text: '',
};

export function PromptBuilder({
	promptEditor: PromptEditor,
	advancedPromptValue,
	onSubmit,
}: EditorPluginAIPromptBuilderProps<PromptValue>) {
	const { formatMessage } = useIntl();

	const toneOptions = useMemo(() => {
		return [
			{ label: formatMessage(messages.toneProfessional), value: 'professional' },
			{ label: formatMessage(messages.toneEmpathetic), value: 'empathetic' },
			{ label: formatMessage(messages.toneCasual), value: 'casual' },
			{ label: formatMessage(messages.toneNeutral), value: 'neutral' },
			{ label: formatMessage(messages.toneEducational), value: 'educational' },
		] satisfies Option<Tone>[];
	}, [formatMessage]);
	const selectedToneOption = useMemo(() => {
		const value = advancedPromptValue?.tone ?? defaultTone;

		return toneOptions.find((option) => option.value === value);
	}, [advancedPromptValue?.tone, toneOptions]);

	const creativityOptions = useMemo(() => {
		return [
			{ label: formatMessage(messages.creativityHigh), value: 'high' },
			{ label: formatMessage(messages.creativityMedium), value: 'medium' },
			{ label: formatMessage(messages.creativityLow), value: 'low' },
		] satisfies Option<Creativity>[];
	}, [formatMessage]);
	const selectedCreativityOption = useMemo(() => {
		const value = advancedPromptValue?.creativity ?? defaultCreativity;

		return creativityOptions.find((option) => option.value === value);
	}, [advancedPromptValue?.creativity, creativityOptions]);

	const handleSubmit = useCallback(
		(value: FormValue) => {
			const prompt: PromptValue = {
				...value,
				tone: value.tone ? value.tone.value : defaultTone,
				creativity: value.creativity ? value.creativity.value : defaultCreativity,
			};

			onSubmit(prompt);
		},
		[onSubmit],
	);

	return (
		<PromptBuilderForm<FormValue> onSubmit={handleSubmit}>
			<Field<FormValue['instructions']>
				name="instructions"
				defaultValue={advancedPromptValue?.instructions ?? emptyAdfValue}
				label={formatMessage(messages.instructionsLabel)}
				isRequired
				validate={(value) => {
					if (!value?.text?.trim()) {
						return formatMessage(messages.instructionsRequired);
					}
				}}
			>
				{({ fieldProps, error }) => (
					<>
						<PromptEditor
							type="advanced-prompt"
							ariaLabel={formatMessage(messages.instructionsLabel)}
							placeholder={formatMessage(messages.instructionsPlaceholder)}
							autoFocus
							defaultValue={fieldProps.value.adf}
							isInvalid={fieldProps.isInvalid}
							onBlur={fieldProps.onBlur}
							onFocus={fieldProps.onFocus}
							onChange={fieldProps.onChange}
						/>
						{error && <ErrorMessage>{error}</ErrorMessage>}
					</>
				)}
			</Field>

			<Field
				name="audience"
				defaultValue={advancedPromptValue?.audience ?? ''}
				label={formatMessage(messages.audienceLabel)}
				isRequired
				validate={(value) => {
					if (!value?.trim()) {
						return formatMessage(messages.audienceRequired);
					}
				}}
			>
				{({ fieldProps, error }) => (
					<>
						<TextField
							{...fieldProps}
							autoComplete="off"
							placeholder={formatMessage(messages.audiencePlaceholder)}
						/>
						{error && <ErrorMessage>{error}</ErrorMessage>}
					</>
				)}
			</Field>

			<Grid templateColumns="1fr 1fr">
				<Flex>
					<Field<ValueType<Option<Tone>>>
						name="tone"
						defaultValue={selectedToneOption}
						label={formatMessage(messages.toneLabel)}
					>
						{({ fieldProps }) => <Select {...fieldProps} options={toneOptions} />}
					</Field>
				</Flex>

				<Flex>
					<Field<ValueType<Option<Creativity>>>
						name="creativity"
						defaultValue={selectedCreativityOption}
						label={formatMessage(messages.creativityLabel)}
					>
						{({ fieldProps }) => <Select {...fieldProps} options={creativityOptions} />}
					</Field>
				</Flex>
			</Grid>
		</PromptBuilderForm>
	);
}
