import {
	AtlBrowserStorageLocal,
	AtlBrowserStorageSession,
} from '@atlassian/browser-storage-controls';
import { fg } from '@confluence/feature-gating';

import { StorageManager } from './StorageManager';
import {
	STORAGE_MANAGER_PREFIX,
	STORAGE_MANAGER_ID,
	LOCAL_STORAGE_MANAGER,
	SESSION_STORAGE_MANAGER,
} from './storageKeys';
import { ssrLocalStorage, ssrSessionStorage } from './SSRStorage';
import type { StorageManagerInitContext } from './StorageManagerInitContext';
import { getRemoteLocalStorage } from './getRemoteLocalStorage';

// check if local and session storage are valid
const isStorageValid = (storage: any): storage is Storage => {
	try {
		return (
			typeof storage !== 'undefined' &&
			'setItem' in storage &&
			// in IE8 typeof localStorage.setItem === 'object'
			!!storage.setItem
		);
	} catch (e) {
		return false;
	}
};

const getStorage = (type: 'localStorage' | 'sessionStorage'): Storage | null => {
	if (typeof window === 'object' && isStorageValid(window[type])) {
		return window[type];
	}
	return null;
};

const confluenceLocalStorage = process.env.REACT_SSR ? ssrLocalStorage : getStorage('localStorage');
const confluenceSessionStorage = process.env.REACT_SSR
	? ssrSessionStorage
	: getStorage('sessionStorage');

let confluenceLocalStorageInstance: StorageManager;
let confluenceSessionStorageInstance: StorageManager;

export const initializeStorageManager = async (initializationData: StorageManagerInitContext) => {
	const localStorageWithBrowserControls = fg('confluence_browser_storage_controls')
		? AtlBrowserStorageLocal
		: confluenceLocalStorage;
	const sessionStorageWithBrowserControls = fg('confluence_browser_storage_controls')
		? AtlBrowserStorageSession
		: confluenceSessionStorage;

	confluenceLocalStorageInstance = new StorageManager(
		localStorageWithBrowserControls,
		STORAGE_MANAGER_PREFIX,
		STORAGE_MANAGER_ID,
		LOCAL_STORAGE_MANAGER,
		initializationData,
	);

	confluenceSessionStorageInstance = new StorageManager(
		sessionStorageWithBrowserControls,
		STORAGE_MANAGER_PREFIX,
		STORAGE_MANAGER_ID,
		SESSION_STORAGE_MANAGER,
		initializationData,
	);

	const result = await getRemoteLocalStorage(initializationData);
	if (result) {
		confluenceLocalStorageInstance.initializeFromServer(result);
	}
};

export { keys, PERSISTED_KEYS_ON_SERVER } from './LocalStorageKeys';
export { patchLocalStorage } from './patchLocalStorage';
export { confluenceLocalStorageInstance, confluenceSessionStorageInstance };
