import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { PMPluginFactoryParams } from '@atlaskit/editor-common/types';
import { EditorView } from '@atlaskit/editor-prosemirror/view';

import {
	createInitialState,
	createPluginState,
	getPluginState,
} from './ai-audit-log-plugin-factory';
import { aiAuditLogsPluginKey } from './ai-audit-log-plugin-key';
import { sendAuditLogEvent, updateAIAuditLogPluginState } from './commands';
import { type AuditLogEventData, auditLogEvents } from './types';

export const createAIAuditLogPlugin = (options: {
	dispatch: PMPluginFactoryParams['dispatch'];
	eventData: AuditLogEventData;
}): SafePlugin => {
	const { dispatch, eventData } = options;

	return new SafePlugin({
		key: aiAuditLogsPluginKey,
		state: createPluginState(dispatch, createInitialState(eventData)),
		view(_view: EditorView) {
			return {
				update: (view: EditorView) => {
					const pluginState = getPluginState(view.state);
					auditLogEvents.forEach((event) => {
						const eventState = pluginState[event];
						if (eventState.eventStatus === 'send-event') {
							updateAIAuditLogPluginState({
								[event]: {
									eventStatus: 'event-in-progress',
								},
							})(view.state, view.dispatch);
							sendAuditLogEvent(event, view);
						}
					});
				},
			};
		},
	});
};
