import Cookies from 'js-cookie';

import { fg } from '@atlaskit/platform-feature-flags';

import { type CategorizedStorageControlsCache, CategoryStorageType } from '../../../types';
import { CacheLoader } from '../load-storage-controls-data';
import { Logger } from '../logger';

let localAnalyticsEnabled: boolean | undefined;
let localProduct: string | undefined;
let webStorageControlsEnabled: boolean | undefined;
let localConsentModeEnabled: boolean | undefined;
let packageDebugModeEnabled: boolean | undefined;
let localAllowUncategorizedCookiesInOverrides: boolean | undefined;

let cookiesCacheLoader: CacheLoader | undefined;
let localStorageCacheLoader: CacheLoader | undefined;
let sessionStorageCacheLoader: CacheLoader | undefined;

let loadCache: (
	storageType: CategoryStorageType,
) => Promise<CategorizedStorageControlsCache | undefined> = async (
	storageType: CategoryStorageType,
) => {
	switch (storageType) {
		case CategoryStorageType.Cookies:
			return await cookiesCacheLoader?.loadStorageControlsData();
		case CategoryStorageType.LocalStorage:
			return await localStorageCacheLoader?.loadStorageControlsData();
		case CategoryStorageType.SessionStorage:
			return await sessionStorageCacheLoader?.loadStorageControlsData();
		default:
			return undefined;
	}
};

interface InitializeBscGlobalStateParams {
	analyticsEnabled?: boolean;
	product?: string;
	webStorageEnabled?: boolean;
	localConsentMode?: boolean;
	allowUncategorizedCookiesInOverrides?: boolean;
}

interface OverrideBscGlobalStateParams {
	loadCache?: typeof loadCache;
	product?: typeof localProduct;
}

export interface BscGlobalState {
	analyticsEnabled?: boolean;
	product?: string;
	webStorageEnabled?: boolean;
	localConsentMode?: boolean;
	packageDebugMode?: boolean;
	allowUncategorizedCookiesInOverrides?: boolean;

	cookiesCacheLoader?: CacheLoader;
	localStorageCacheLoader?: CacheLoader;
	sessionStorageCacheLoader?: CacheLoader;

	loadCache: (
		storageType: CategoryStorageType,
	) => Promise<CategorizedStorageControlsCache | undefined>;
}

export const getBscGlobalState: () => BscGlobalState = () => {
	if (localAnalyticsEnabled == null) {
		localAnalyticsEnabled = true;
	}

	// Unless specified, localConsentMode is turned off
	localConsentModeEnabled = localConsentModeEnabled ?? false;

	return {
		analyticsEnabled: localAnalyticsEnabled,
		product: localProduct,
		webStorageEnabled: webStorageControlsEnabled,
		localConsentMode: localConsentModeEnabled,
		packageDebugMode: packageDebugModeEnabled,
		allowUncategorizedCookiesInOverrides: localAllowUncategorizedCookiesInOverrides,
		cookiesCacheLoader,
		localStorageCacheLoader,
		sessionStorageCacheLoader,
		loadCache,
	};
};

export const initializeGlobalState = ({
	analyticsEnabled = true,
	product = 'all',
	localConsentMode = false,
	webStorageEnabled,
	allowUncategorizedCookiesInOverrides,
}: InitializeBscGlobalStateParams = {}) => {
	Logger.debug('Initialize global state invoked');

	localAnalyticsEnabled = analyticsEnabled;
	localProduct = product;
	localConsentModeEnabled = localConsentMode;

	// Only set webStorageControlsEnabled if webStorageEnabled is defined
	if (webStorageEnabled !== undefined) {
		webStorageControlsEnabled =
			webStorageEnabled &&
			!fg('platform_moonjelly_bsc_master_killswitch') &&
			!fg('platform_moonjelly_bsc_web_storage_killswitch');
	}

	if (allowUncategorizedCookiesInOverrides !== undefined) {
		localAllowUncategorizedCookiesInOverrides = allowUncategorizedCookiesInOverrides;
	}

	cookiesCacheLoader = new CacheLoader(CategoryStorageType.Cookies);
	localStorageCacheLoader = new CacheLoader(CategoryStorageType.LocalStorage);
	sessionStorageCacheLoader = new CacheLoader(CategoryStorageType.SessionStorage);

	if (fg('platform_moonjelly_bsc_debug_mode')) {
		packageDebugModeEnabled = Boolean(Cookies.get('atl-bsc-debug-mode'));
	}
};

export const testing_overrideGlobalState = ({
	loadCache: overrideLoadCache,
	product: overrideProduct,
}: OverrideBscGlobalStateParams) => {
	if (overrideLoadCache) {
		loadCache = overrideLoadCache;
	}
	if (overrideProduct) {
		localProduct = overrideProduct;
	}
};

export const clearGlobalState = () => {
	localAnalyticsEnabled = undefined;
	localProduct = undefined;
	webStorageControlsEnabled = undefined;
	localConsentModeEnabled = undefined;
	packageDebugModeEnabled = undefined;
	localAllowUncategorizedCookiesInOverrides = undefined;

	cookiesCacheLoader = undefined;
	localStorageCacheLoader = undefined;
	sessionStorageCacheLoader = undefined;
};
