import { type DocNode } from '@atlaskit/adf-schema';

import { type PluginInvocationMessage } from '../plugins';
import {
	type AgentResponse,
	type AssistantMessage,
	type Features,
	type HumanMessage,
	isAgentResponse,
	type StreamAnswerPart,
	type StreamPluginInvocation,
	type StreamResponse,
} from '../types';

// ****************************************************************************
// Getters and Setters
// ****************************************************************************

export const getId = (streamResponse: StreamResponse): string => streamResponse.message.message.id;

const updatePluginInvocationAssistantMessage = (
	response: AssistantMessage,
	streamPluginInvocation: StreamPluginInvocation,
): AssistantMessage => {
	const { message: pluginInvocation } = streamPluginInvocation;
	const pluginInvocationMessage = {
		message_id: response.id,
		...pluginInvocation,
	};
	const updatedMessage = { ...response };

	const updatedPluginInvocations = [...updatedMessage.plugin_invocations];
	const currentIndex = updatedPluginInvocations.findIndex(
		(pi) => pi.plugin_name === pluginInvocationMessage.plugin_name,
	);
	if (currentIndex === -1) {
		updatedPluginInvocations.push(pluginInvocationMessage);
	} else {
		updatedPluginInvocations[currentIndex] = pluginInvocationMessage;
	}
	updatedMessage.plugin_invocations = updatedPluginInvocations;
	return updatedMessage;
};

const updatePluginInvocationAgentResponse = (
	response: AgentResponse,
	streamPluginInvocation: StreamPluginInvocation,
): AgentResponse => ({
	...response,
	message: updatePluginInvocationAssistantMessage(response.message, streamPluginInvocation),
});

export const updatePluginInvocation = (
	response: StreamResponse,
	streamPluginInvocation: StreamPluginInvocation,
): StreamResponse => ({
	...response,
	message: updatePluginInvocationAgentResponse(response.message, streamPluginInvocation),
});

const addAnswerPartAssistantMessage = (
	response: AssistantMessage,
	answerPart: StreamAnswerPart,
): AssistantMessage => {
	const content = `${response.content}${answerPart.message.content}`;

	// Remove source indexes, e.g [^1^] from the content
	const cleanedContent = content.replace(/\[\^\d+\^\]/g, '');

	return {
		...response,
		content: cleanedContent,
	};
};

const addAnswerPartAgentResponse = (
	response: AgentResponse,
	answerPart: StreamAnswerPart,
): AgentResponse => ({
	...response,
	message: addAnswerPartAssistantMessage(response.message, answerPart),
});

export const addAnswerPart = (
	response: StreamResponse,
	answerPart: StreamAnswerPart,
): StreamResponse => ({
	...response,
	message: addAnswerPartAgentResponse(response.message, answerPart),
});

const removeSensitiveDataAssistantMessage = (message: AssistantMessage): AssistantMessage => {
	const { plugin_invocations, ...rest } = message;
	return {
		...rest,
		plugin_invocations: plugin_invocations.map(
			(pluginInvocation): PluginInvocationMessage => ({
				...pluginInvocation,
				plugin_output: '--PRIVATE--',
			}),
		),
	};
};

const removeSensitiveDataAgentResponse = (message: AgentResponse): AgentResponse => ({
	...message,
	message: removeSensitiveDataAssistantMessage(message.message),
});

export const removeSensitiveData = (message: StreamResponse): StreamResponse => ({
	...message,
	message: removeSensitiveDataAgentResponse(message.message),
});

// ****************************************************************************
// Constructors
// ****************************************************************************

export const createHumanMessage = (
	id: string,
	content: string | DocNode,
	isFromSeededConversation?: boolean,
	requestId?: string,
): HumanMessage => {
	return {
		role: 'HUMAN',
		id,
		content,
		time_created: new Date().toISOString(),
		user_ari: 'unknown',
		plugin_invocations: [],
		isFromSeededConversation,
		requestId,
	};
};

export const toStreamResponse = (message: AssistantMessage | AgentResponse): StreamResponse => {
	if (isAgentResponse(message)) {
		return { type: 'FINAL_RESPONSE', message };
	}
	return { type: 'FINAL_RESPONSE', message: { message } };
};

export const createEmptyFeatures = (): Features => ({
	account_id: '',
	cloud_id: '',
	env: '',
	env_type: '',
	model: '',
	ai_enabled_on_site: false,
	assistance_feature_enabled: false,
	debug_enabled: false,
});
