import gql from 'graphql-tag';

export const UpdateDefaultSpacePermissions = gql`
	mutation UpdateDefaultSpacePermissions($input: [UpdateDefaultSpacePermissionsInput!]!) {
		updateSpacePermissionDefaults(input: $input) {
			success
			errors {
				message
				extensions {
					errorType
					statusCode
				}
			}
		}
	}
`;
