import uuid from 'uuid/v4';

import {
	type ExtractInjectionAPI,
	type PMPluginFactoryParams,
} from '@atlaskit/editor-common/types';
import { type EditorView } from '@atlaskit/editor-prosemirror/view';
import { fg } from '@atlaskit/platform-feature-flags';

import type { EditorPluginAIConfigItem } from '../../../config-items/config-items';
import { type AIPlugin } from '../../../editor-plugin-ai';
import { streaming } from '../../../experience-application/streaming';
import type { AIExperienceMachineContextForRequest } from '../../../request/types';
import type { EditorPluginAIProvider } from '../../../types';
import { type StreamingCallback } from '../types';
import { shouldReturnFallbackSuggestedTitle } from '../utils/config-utils';

import {
	fireFallbackSuggestedTitleReturnedEvent,
	fireSuggestedTitleCompletedEvent,
	isSuggestTitle,
} from './analytic-utils';
import { messages } from './messages';

const handleFallbackSuggestedTitle = (
	streamingCallback: StreamingCallback,
	api: ExtractInjectionAPI<AIPlugin> | undefined,
	analyticSourceId: string,
	fallbackSuggestedTitle: string,
): void => {
	streamingCallback({
		type: 'complete',
		result: fallbackSuggestedTitle,
	});
	fireFallbackSuggestedTitleReturnedEvent({ api, analyticSourceId });
};

export const handleStreamingService = ({
	getIntl,
	editorPluginAIProvider,
	currentEditorView,
	configItem,
	streamingCallback,
	api,
	analyticSourceId,
}: {
	getIntl: PMPluginFactoryParams['getIntl'];
	editorPluginAIProvider: EditorPluginAIProvider;
	currentEditorView: EditorView;
	configItem: EditorPluginAIConfigItem;
	streamingCallback: StreamingCallback;
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	analyticSourceId: string;
}) => {
	const intl = getIntl();
	const context: AIExperienceMachineContextForRequest = {
		aiProvider: editorPluginAIProvider,
		editorView: currentEditorView,
		configItem: configItem,
		intl: intl,
		positions: [0, 0],
		isEmpty: true,
		selectionType: 'empty',
		usePageKnowledge: true,
		analyticsContext: {
			aiSessionId: uuid(),
		},
		responseHistory: { positionFromEnd: 0, entries: [], totalSize: 0, traversals: 0 },
	};

	streamingCallback({
		type: 'initialising',
	});

	// For proactive title generation project: We carve out a specific scenario when to
	// return a 'fallback title' if no context will be sent with the suggest a title prompt
	// (which would otherwise generate training data gibberish)
	if (shouldReturnFallbackSuggestedTitle(configItem, currentEditorView)) {
		const fallbackSuggestedTitle = intl.formatMessage(messages.fallbackSuggestedTitle);
		handleFallbackSuggestedTitle(streamingCallback, api, analyticSourceId, fallbackSuggestedTitle);
		return;
	}

	const callbackHandler = streaming(context);
	callbackHandler((streamData) => {
		if (streamData) {
			// @ts-ignore callbackHandler's type expectation is too wide, need to narrow down
			switch (streamData.type) {
				case 'stream': {
					if (streamData.loadingStatus === 'ANALYSING_QUERY') {
						streamingCallback({
							type: 'analysingQuery',
						});
					} else {
						streamingCallback({
							type: 'streaming',
							result: streamData.markdown,
						});
					}
					break;
				}
				case 'complete': {
					streamingCallback({
						type: 'complete',
						result: streamData.markdown,
					});
					if (fg('platform_editor_ai_suggest_title_fingerprint')) {
						if (isSuggestTitle(configItem)) {
							fireSuggestedTitleCompletedEvent({
								api,
								analyticSourceId,
								suggestedTitle: streamData.markdown,
							});
						}
					}
					break;
				}
				case 'error':
					streamingCallback({
						type: streamData.type,
						errorInfo: streamData.errorInfo,
					});
					break;
				case 'response too similar': {
					streamingCallback({
						type: streamData.type,
					});
					break;
				}
				default: {
					break;
				}
			}
		}
	});
};
