import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import {
	UPSELL_EDITION_UPFLOW_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { TransparentErrorBoundary, Attribution } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';

import { ConfluenceEdition } from './__types__/waitForEditionChangeQuery';
import { UpFlowCommerceRouterLoadable } from './UpFlowCommerceRouterLoadable';

type ShowUpFlowProps = {
	touchpointId: string;
	targetEdition: ConfluenceEdition;
	flow: string;
	epMessageId: string;
};

type UpFlowContextType = {
	shouldShowUpFlow: boolean;
	isSiteAdmin: boolean;
	cloudId: string;
	currentEdition?: ConfluenceEdition;
	showUpFlow: (props: ShowUpFlowProps) => void;
	hideUpFlow: () => void;
};

type UpFlowProviderDataType = ShowUpFlowProps & {
	shouldShowUpFlow: boolean;
};

/**
 * @deprecated Please reach out to #cc-editions for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export const UpFlowContext = createContext<UpFlowContextType>({
	shouldShowUpFlow: false,
	isSiteAdmin: false,
	cloudId: '',
	currentEdition: undefined,
	showUpFlow: () => {},
	hideUpFlow: () => {},
});

const UPFLOW_PROVIDER_INITIAL_DATA: UpFlowProviderDataType = {
	shouldShowUpFlow: false,
	touchpointId: '',
	targetEdition: ConfluenceEdition.STANDARD,
	flow: '',
	epMessageId: '',
};

/**
 * @deprecated Please reach out to #cc-editions for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export const UpFlowProvider = ({ children }) => {
	const [upFlowProviderData, setUpFlowProviderData] = useState<UpFlowProviderDataType>(
		UPFLOW_PROVIDER_INITIAL_DATA,
	);
	const upFlowProviderDataRef = useRef<UpFlowProviderDataType>(upFlowProviderData);
	upFlowProviderDataRef.current = upFlowProviderData;
	const { shouldShowUpFlow, targetEdition, flow, touchpointId, epMessageId } = upFlowProviderData;
	const { isSiteAdmin, tenantId } = useContext(SPAViewContext);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { edition: currentEdition, userId } = useSessionData();

	const showUpFlow = useCallback(
		(data: ShowUpFlowProps) => {
			experienceTracker.start({
				name: UPSELL_EDITION_UPFLOW_EXPERIENCE,
				attributes: {
					currentEdition: currentEdition || undefined,
					targetEdition: data.targetEdition,
					experience: UPSELL_EDITION_UPFLOW_EXPERIENCE,
					experienceId: data.touchpointId,
				},
			});

			setUpFlowProviderData({
				...data,
				shouldShowUpFlow: true,
			});
		},
		[setUpFlowProviderData, currentEdition, experienceTracker],
	);

	const hideUpFlow = useCallback(() => {
		// UpFlow calls the experience fail/abort, so we should not do it here.
		setUpFlowProviderData({
			...upFlowProviderDataRef.current,
			shouldShowUpFlow: false,
			touchpointId: '',
			flow: '',
			epMessageId: '',
		});
	}, [upFlowProviderDataRef, setUpFlowProviderData]);

	const onUpFlowOpen = () => {
		// no-op
	};

	const failExperienceOnError = useCallback(
		(error: Error) => {
			experienceTracker.stopOnError({
				name: UPSELL_EDITION_UPFLOW_EXPERIENCE,
				error,
			});
		},
		[experienceTracker],
	);

	const context: UpFlowContextType = useMemo(
		() => ({
			shouldShowUpFlow,
			isSiteAdmin,
			cloudId: tenantId,
			currentEdition: currentEdition || undefined,
			showUpFlow,
			hideUpFlow,
		}),
		[shouldShowUpFlow, isSiteAdmin, tenantId, currentEdition, showUpFlow, hideUpFlow],
	);

	return (
		<UpFlowContext.Provider value={context}>
			{children}
			{shouldShowUpFlow && targetEdition && flow ? (
				<TransparentErrorBoundary
					attribution={Attribution.EDITIONS}
					onError={failExperienceOnError}
				>
					<UpFlowCommerceRouterLoadable
						canChangeEdition={isSiteAdmin}
						cloudId={tenantId}
						currentEdition={currentEdition || undefined}
						atlassianAccountId={userId || undefined}
						flow={flow}
						product="confluence"
						targetEdition={targetEdition}
						touchpointId={touchpointId}
						onClose={hideUpFlow}
						onOpen={onUpFlowOpen}
						epMessageId={epMessageId}
						isExperienceTrackerEnabled
					/>
				</TransparentErrorBoundary>
			) : null}
		</UpFlowContext.Provider>
	);
};

export const { Consumer } = UpFlowContext;
