import { StorageClient } from '@atlaskit/frontend-utilities';

export const LOCAL_STORAGE_CLIENT_KEY = '@atlaskit/editor-plugin-ai';
export const LOCAL_STORAGE_PROACTIVE_AI_TOGGLED_KEY = 'proactiveAIToggled';
export const LOCAL_STORAGE_PROACTIVE_AI_SETTINGS_KEY = 'proactiveAISettings';

const storageClient = new StorageClient(LOCAL_STORAGE_CLIENT_KEY);

export const markLocalStorageProactiveAIToggled = (value: boolean, expiration?: number) => {
	storageClient.setItemWithExpiry(LOCAL_STORAGE_PROACTIVE_AI_TOGGLED_KEY, value, expiration);
};

export const isLocalStorageProactiveAIToggled = (defaultValue?: boolean) => {
	const localStorageValue =
		storageClient.getItem(LOCAL_STORAGE_PROACTIVE_AI_TOGGLED_KEY) ?? defaultValue;
	return !!localStorageValue;
};

export const getProactiveAISettings = (defaultValue?: { [key: string]: boolean }) => {
	const localStorageValue =
		storageClient.getItem(LOCAL_STORAGE_PROACTIVE_AI_SETTINGS_KEY) ?? defaultValue;
	return localStorageValue;
};

export const setProactiveAISettings = (value: { [key: string]: boolean }) => {
	storageClient.setItemWithExpiry(LOCAL_STORAGE_PROACTIVE_AI_SETTINGS_KEY, value);
};
