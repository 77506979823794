import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.agent.title',
		defaultMessage: 'Atlassian Intelligence Rovo Agent',
		description:
			'Title for the menu item of the Atlassian Intelligence agent without a predefined name.',
	},
	description: {
		id: 'fabric.editor.ai.config.item.range.agent.description',
		defaultMessage: 'No description',
		description:
			'Subtitle for the menu item of the Atlassian Intelligence agent without a predefined description.',
	},
	promptHint: {
		id: 'fabric.editor.ai.config.item.range.agent.prompt.hint',
		defaultMessage: 'Tell the Agent to write anything or choose from below',
		description: 'Hint text for the prompt input when interacting with the Atlassian Intelligence',
	},
});
