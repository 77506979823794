import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';

import { OperationsQuery } from './OperationsQuery.graphql';

export async function preloadOperations() {
	const { userId } = await getPreloaderFnContext();
	if (fg('confluence_operations_query_user_id_check')) {
		if (!userId) {
			return;
		}
	}

	return query({ query: OperationsQuery });
}
