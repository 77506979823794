/*
 * This function checks if the current selection is a partial copy of the target element.
 */
export function isPartialCopy(targetElement: HTMLElement) {
	const selection = window.getSelection();
	const selectionText = selection?.toString();

	// If there is no current selection, we don't want to send the event.
	if (!selectionText) {
		return false;
	}

	// Remove new lines and trailing spaces from the text to compare as innerText may have unecessary newlines.
	// Ignored via go/ees005
	// eslint-disable-next-line require-unicode-regexp
	const strippedPreviewResponseText = targetElement.innerText.replace(/\n/g, '').trim();
	// Ignored via go/ees005
	// eslint-disable-next-line require-unicode-regexp
	const strippedSelectionText = selectionText.replace(/\n/g, '').trim();

	let isSelectionOutsideTargetElement = false;
	let isSelectionWholeAndMore = false;
	if (selection) {
		const { anchorNode, focusNode } = selection;
		// Cases where the selection may be outside the node but are considered a whole copy:
		// Starts and ends outside the node.
		// Note: The anchor and focus nodes might be text nodes, not necessarily elements themselves,
		// but the `contains` method works for any node type.
		const selectionStartsOutside = !targetElement.contains(anchorNode);
		const selectionEndsOutside = !targetElement.contains(focusNode);
		isSelectionOutsideTargetElement = selectionStartsOutside && selectionEndsOutside;
		// Starts outside, ends at the end of the node.
		// OR Ends outside, starts at the start of the node.

		// Verify start and end locations.
		const selectionStartsAtStart = selection.anchorOffset === 0;
		const selectionEndsAtEnd = selection.focusOffset === focusNode?.textContent?.length;
		// Verify if the selection is a whole copy and more.
		const startsOutsideAndEndsAtEnd = selectionStartsOutside && selectionEndsAtEnd;
		const startsAtStartAndEndsOutside = selectionEndsOutside && selectionStartsAtStart;

		if (startsOutsideAndEndsAtEnd || startsAtStartAndEndsOutside) {
			isSelectionWholeAndMore = true;
		}
	}

	if (isSelectionOutsideTargetElement || isSelectionWholeAndMore) {
		return false;
	}

	return strippedPreviewResponseText !== strippedSelectionText;
}

/**
 * Checks if the current selection is within the target element.
 */
export function isSelectionWithinElement(element: HTMLElement): boolean {
	const selection = window.getSelection();

	if (!selection?.rangeCount || !selection.toString()) {
		return false;
	}

	const { anchorNode, focusNode } = selection;
	if (!anchorNode || !focusNode) {
		return false;
	}

	// if start or end of the selection is within element no need in further checks
	if ([anchorNode, focusNode].some((node) => element.contains(node))) {
		return true;
	}

	// detect start and end node based on the direction of the selection
	const isLTRSelection =
		anchorNode.compareDocumentPosition(focusNode) & Node.DOCUMENT_POSITION_FOLLOWING;
	const startNode = isLTRSelection ? selection.anchorNode : selection.focusNode;
	const endNode = isLTRSelection ? selection.focusNode : selection.anchorNode;

	if (!startNode || !endNode) {
		return false;
	}

	// Check if the selection starts before and ends after the element
	if (
		startNode.compareDocumentPosition(element) & Node.DOCUMENT_POSITION_FOLLOWING &&
		endNode.compareDocumentPosition(element) & Node.DOCUMENT_POSITION_PRECEDING
	) {
		return true;
	}

	// Check if the selection starts after and ends before the element
	if (
		startNode.compareDocumentPosition(element) & Node.DOCUMENT_POSITION_PRECEDING &&
		endNode.compareDocumentPosition(element) & Node.DOCUMENT_POSITION_FOLLOWING
	) {
		return true;
	}

	return false;
}
