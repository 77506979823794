import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { Screen } from '@confluence/screen';
import { MainLayout } from '@confluence/main-layout';
import { shouldShowMobileWeb } from '@confluence/mobile-detection';
import { BannerContextProvider } from '@confluence/banners';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

const routeWithNavigationMapping = new Map<string, any>();

type RouteWithNavParamsType = ((match: RouteMatch) => JSX.Element | null) & {
	NAVIGATION_PARAMS: (
		match: RouteMatch,
		isNav4Enabled: boolean,
	) =>
		| boolean
		| {
				Screen: any;
				MainLayout: any;
		  };
};

export type RouteType = (match: RouteMatch) => JSX.Element | null;
export type RoutesType = Record<string, RouteType>;

// export for testing
export function RouteWithNavigation(match: RouteMatch): JSX.Element | null {
	const isNav4Enabled = useIsNav4Enabled();

	// We need to ensure all the entries calling processNavigationParams are returning the same RouteWithNavigation instance
	// So that they won't get re-mounted when switching between routes
	const RouteWithNavParams = routeWithNavigationMapping.get(match.name);
	if (!RouteWithNavParams) {
		if (
			process.env.CLOUD_ENV === 'staging' ||
			process.env.CLOUD_ENV === 'branch' ||
			process.env.NODE_ENV === 'testing'
		) {
			throw new Error(
				`Matched with ${match.name} but no corresponding route handler. Please read patterns/routing.md`,
			);
		}
		return null;
	}

	const { NAVIGATION_PARAMS } = RouteWithNavParams;
	if (typeof NAVIGATION_PARAMS !== 'function') {
		if (
			process.env.CLOUD_ENV === 'staging' ||
			process.env.CLOUD_ENV === 'branch' ||
			process.env.NODE_ENV === 'testing'
		) {
			throw new Error(
				`Matched a route for ${match.name} but no NAVIGATION_PARAMS function. Please read patterns/routing.md`,
			);
		}
		return null;
	}

	const isMobile = shouldShowMobileWeb();
	const spaceNavParams = NAVIGATION_PARAMS(match, isNav4Enabled);
	const element = React.createElement(RouteWithNavParams, match);
	if (spaceNavParams === false) {
		return element;
	}

	const { Screen: screen, MainLayout: mainLayout, BannerContext: bannerContext } = spaceNavParams;
	if (
		process.env.CLOUD_ENV === 'staging' ||
		process.env.CLOUD_ENV === 'branch' ||
		process.env.NODE_ENV === 'testing'
	) {
		if (typeof screen !== 'object') {
			throw new Error(`NAVIGATION_PARAMS.Screen should return an object`);
		}
		if (screen?.screenEvent && typeof screen?.screenEvent !== 'object') {
			throw new Error(
				`NAVIGATION_PARAMS.Screen.screenEvent should be an object if provided. Please read patterns/routing.md`,
			);
		}
		if (screen?.pageState && typeof screen.pageState !== 'object') {
			throw new Error(
				`NAVIGATION_PARAMS.Screen.pageState should be an object if provided. Please read patterns/routing.md`,
			);
		}
		if (typeof mainLayout !== 'object' && mainLayout !== false) {
			throw new Error(
				`NAVIGATION_PARAMS.MainLayout should return an object or false. Please read patterns/routing.md`,
			);
		}
		if (bannerContext && typeof bannerContext?.includeTopNav !== 'boolean') {
			throw new Error(
				`NAVIGATION_PARAMS.BannerContext.includeTopNav should be a boolean if provided. Please read patterns/routing.md`,
			);
		}
	}

	return (
		<Screen {...screen}>
			{mainLayout === false ? (
				element
			) : (
				<MainLayout enableNavigation={!isMobile} {...mainLayout}>
					<BannerContextProvider includeTopNav={bannerContext?.includeTopNav}>
						{element}
					</BannerContextProvider>
				</MainLayout>
			)}
		</Screen>
	);
}

export function processNavigationParams(
	routes: Record<string, RouteWithNavParamsType | RouteType>,
): RoutesType {
	for (const name in routes) {
		const RouteWithNavParams = routes[name];
		if (typeof (RouteWithNavParams as RouteWithNavParamsType).NAVIGATION_PARAMS !== 'function') {
			throw new Error(
				`Route must define a static NAVIGATION_PARAMS function. ${name} is missing the NAVIGATION_PARAMS function. Please read patterns/routing.md`,
			);
		}
		routeWithNavigationMapping.set(name, RouteWithNavParams);
		routes[name] = RouteWithNavigation;
	}
	return routes;
}
