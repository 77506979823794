import React from 'react';

import { useIntl } from 'react-intl-next';

import Badge from '@atlaskit/badge';
import Heading from '@atlaskit/heading';
import TextSpellcheckIcon from '@atlaskit/icon/core/text-spellcheck';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import { Box, Inline, Pressable, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import type { TransformAction } from '../pm-plugins/ai-proactive/api';
import { getTransformActionIconMap } from '../pm-plugins/ai-proactive/suggestion-setting-map';
import { getTransformActionDescriptors } from '../utils/proactive/get-transform-action-descriptors';

import messages from './messages';

const headingStyles = xcss({
	flex: 1,
});

const pressableStyles = xcss({
	color: 'color.text.subtle',
	fontWeight: 'font.weight.medium',
	padding: 'space.0',
	backgroundColor: 'color.background.neutral.subtle',
	':active': {
		color: 'color.text',
	},
});

const pressableSelectedStyles = xcss({
	textDecoration: 'underline',
});

const containerStyles = xcss({
	display: 'flex',
	paddingInline: 'space.150',
	paddingBlock: 'space.150',
	flexDirection: 'column',
	gap: 'space.100',
	alignSelf: 'stretch',
	backgroundColor: 'elevation.surface',
	cursor: 'pointer',
	borderRadius: 'border.radius.100',
});

const extraPadding = xcss({
	paddingBlockStart: 'space.050',
});

const containerHoveredStyles = xcss({
	backgroundColor: 'elevation.surface.hovered',
});

const containerSelectedStyles = xcss({
	backgroundColor: 'color.background.selected',
});

type ListItemProps = {
	title: string;
	description?: string;
	onClick: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	beforeIcon: React.ReactNode;
	afterIcon: React.ReactNode;
	actionButtons?: React.ReactNode[];
	isSelected?: boolean;
	isHovered?: boolean;
};
const ListItem = (props: ListItemProps) => {
	const {
		title,
		description,
		onClick,
		onMouseEnter,
		onMouseLeave,
		isHovered,
		isSelected,
		beforeIcon,
		afterIcon,
		actionButtons,
	} = props;
	return (
		<Box onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<Stack
				xcss={[
					containerStyles,
					isSelected && containerSelectedStyles,
					isHovered && !isSelected && containerHoveredStyles,
				]}
			>
				<Inline space="space.150" alignBlock="center">
					{beforeIcon}
					<Box xcss={headingStyles}>
						<Heading size="small">{title}</Heading>
					</Box>
					{afterIcon}
				</Inline>
				{!!description && <Text color="color.text.subtle">{description}</Text>}
				{!!actionButtons?.length && (
					<Box xcss={extraPadding}>
						<Inline space="space.150" alignBlock="center">
							{actionButtons}
						</Inline>
					</Box>
				)}
			</Stack>
		</Box>
	);
};

type RecommendationItemProps = {
	id: string;
	action: TransformAction;
	isViewed: boolean;
	isHovered: boolean;
	isSelected: boolean;
	onApply: () => void;
	onDismiss: () => void;
	onClick: () => void;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	onPreview: () => void;
};

export const RecommendationItem = (props: RecommendationItemProps) => {
	const {
		action,
		isViewed,
		isHovered,
		isSelected,
		onApply,
		onDismiss,
		onClick,
		onMouseEnter,
		onMouseLeave,
		onPreview,
	} = props;
	const { formatMessage } = useIntl();
	const transformActionIconMap = getTransformActionIconMap();
	const Icon = transformActionIconMap[action] ?? PresenceActiveIcon;
	const { description: descriptionDescriptor, title: titleDescriptor } =
		getTransformActionDescriptors(action);

	const title = formatMessage(titleDescriptor);
	const description = formatMessage(descriptionDescriptor);

	const previewButton = (
		<Pressable
			key="preview"
			xcss={[pressableStyles, isHovered && pressableSelectedStyles]}
			onClick={onPreview}
		>
			{formatMessage(messages.recommendationPreviewButtonLabel)}
		</Pressable>
	);

	const insertButton = (
		<Pressable key="replace" xcss={pressableStyles} onClick={onApply}>
			{formatMessage(messages.recommendationReplaceButtonLabel)}
		</Pressable>
	);

	const dismissButton = (
		<Pressable key="dismiss" xcss={pressableStyles} onClick={onDismiss}>
			{formatMessage(messages.recommendationDismissButtonLabel)}
		</Pressable>
	);

	const actionButtons = isSelected ? [insertButton, dismissButton] : [previewButton, dismissButton];

	return (
		<ListItem
			title={title}
			description={description}
			isHovered={isHovered}
			isSelected={isSelected}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			beforeIcon={<Icon size="small" label={title} color={token('color.icon')} />}
			afterIcon={
				isViewed ? null : (
					<PresenceActiveIcon
						size="small"
						label="unread"
						primaryColor={token('color.icon.information')}
					/>
				)
			}
			actionButtons={actionButtons}
		/>
	);
};

type SpellingGrammarItemProps = {
	errorCount: number;
	onClick: () => void;
};

export const SpellingGrammarItem = (props: SpellingGrammarItemProps) => {
	const { errorCount, onClick } = props;
	const { formatMessage } = useIntl();
	return (
		<ListItem
			title={formatMessage(messages.spellingAndGrammarLabel)}
			onClick={onClick}
			beforeIcon={
				<TextSpellcheckIcon
					label={formatMessage(messages.spellingAndGrammarLabel)}
					color={token('color.icon')}
				/>
			}
			afterIcon={<Badge>{errorCount}</Badge>}
		/>
	);
};
