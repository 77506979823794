import type { ComponentType } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useMutation, useQuery } from '@apollo/react-hooks';

import PeopleGroupIcon from '@atlaskit/icon/core/migration/people-group';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import type { GlyphProps } from '@atlaskit/icon';
import { ConfluenceIcon } from '@atlaskit/logo';

import { PrincipalCell, RoleSelector } from '@confluence/space-roles/entry-points/TableRow';
import type {
	GroupCountsQueryResult,
	GroupCountsQueryVariables,
} from '@confluence/space-roles/entry-points/graphql';
import { GroupCountsQuery, useResponseHandler } from '@confluence/space-roles/entry-points/graphql';
import type {
	SpacePermission,
	SpaceRole,
} from '@confluence/space-roles/entry-points/space-role-types';
import { markErrorAsHandled } from '@confluence/graphql';
import {
	DEFAULT_RBAC_EDIT_EXPERIENCE,
	DEFAULT_RBAC_VIEW_EXPERIENCE,
} from '@confluence/experience-tracker';

import { DefaultSpaceRoleAssignments } from '../graphql/DefaultSpaceRoleAssignments.graphql';
import type {
	DefaultSpaceRoleAssignments as DefaultSpaceRoleAssignmentsType,
	DefaultSpaceRoleAssignments_defaultSpaceRoleAssignmentsAll_nodes as GroupRoleAssignmentNodes,
	DefaultSpaceRoleAssignments_defaultSpaceRoleAssignmentsAll_pageInfo as PageInfo,
} from '../graphql/__types__/DefaultSpaceRoleAssignments';
import type {
	SetDefaultSpaceRoleAssignmentsVariables,
	SetDefaultSpaceRoleAssignments as SetDefaultSpaceRoleAssignmentsType,
} from '../graphql/__types__/SetDefaultSpaceRoleAssignments';
import { RoleAssignmentPrincipalType } from '../graphql/__types__/SetDefaultSpaceRoleAssignments';
import { SetDefaultSpaceRoleAssignments } from '../graphql/SetDefaultSpaceRoleAssignments.graphql';

import { TableActionsMenu } from './TableActionsMenu';
import { TableRowInfoButton } from './table-popup/TableRowInfoButton';

const tableActionsMenuCSS = xcss({
	display: 'flex',
	justifyContent: 'flex-end',
});

const i18n = defineMessages({
	groupCount: {
		id: 'default-space-permissions.table.group-count',
		defaultMessage: '{count, plural, one {# person} other {# people}}',
		description: 'Display the number of people in the group.',
	},
	groupColumnHeader: {
		id: 'default-space-permissions.space-roles-column.group',
		defaultMessage: 'Group',
		description:
			'This is a column within a table that shows the type of principal associated with the row is a group',
	},
	accessClassColumnHeader: {
		id: 'default-space-permissions.space-roles-column.access-class',
		defaultMessage: 'Default access',
		description:
			'This is a column within a table that shows the type of principal associated with the row is by default',
	},
	noPermissionsDisplayName: {
		id: 'default-space-permissions.table.no-permissions-display-name',
		defaultMessage: 'No access',
		description: 'Display for access class role selector if they have no permissions',
	},
	allLicensedUsersDisplayName: {
		id: 'default-space-permissions.table.all-licensed-users-display-name',
		defaultMessage: 'All Confluence users',
		description:
			'Display for a set of users that appear within a table that manages their default space permissions',
	},
	allProductAdminsDisplayName: {
		id: 'default-space-permissions.table.all-product-admins-display-name',
		defaultMessage: 'All Confluence admins',
		description:
			'Display for a set of administrators that appear within a table that manages their default space permissions',
	},
	updateLastAdminError: {
		id: 'default-space-permissions.table.update-last-admin-error',
		defaultMessage:
			'The last admin within the default configurations must keep their current role.',
		description: 'Error message when trying to remove the last Confluence admin',
	},
	updateRoleSuccess: {
		id: 'default-space-permissions.table.update-role-success',
		defaultMessage: 'Access updated',
		description: 'Success message when a role is updated',
	},
});

const ALL_LICENSED_USERS_ID = 'all-licensed-users';
const ALL_PRODUCT_ADMINS_ID = 'all-product-admins';

const getDefaultAccessClasses = (
	formatMessage: ReturnType<typeof useIntl>['formatMessage'],
): GroupRoleAssignmentRecord => ({
	[ALL_LICENSED_USERS_ID]: {
		assignmentId: ALL_LICENSED_USERS_ID,
		principal: {
			id: ALL_LICENSED_USERS_ID,
			displayName: formatMessage(i18n.allLicensedUsersDisplayName),
			type: RoleAssignmentPrincipalType.ACCESS_CLASS,
			icon: AccessClassIcon,
		},
		roleId: null,
		roleLabel: null,
		permissions: [],
	},
	[ALL_PRODUCT_ADMINS_ID]: {
		assignmentId: ALL_PRODUCT_ADMINS_ID,
		principal: {
			id: ALL_PRODUCT_ADMINS_ID,
			displayName: formatMessage(i18n.allProductAdminsDisplayName),
			type: RoleAssignmentPrincipalType.ACCESS_CLASS,
			icon: AccessClassIcon,
		},
		roleId: null,
		roleLabel: null,
		permissions: [],
	},
});

const getPrincipalType = (typename: string): RoleAssignmentPrincipalType => {
	return typename === 'SpaceRoleAccessClassPrincipal'
		? RoleAssignmentPrincipalType.ACCESS_CLASS
		: RoleAssignmentPrincipalType.GROUP;
};

const AccessClassIcon = () => <ConfluenceIcon appearance="brand" />;

const getPrincipalIcon = (typename: string): ComponentType<GlyphProps> => {
	return typename === 'SpaceRoleAccessClassPrincipal'
		? AccessClassIcon
		: // https://atlassian.slack.com/archives/C06MN81H0R2/p1729700293830259?thread_ts=1729632262.610509&cid=C06MN81H0R2
			// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
			PeopleGroupIcon;
};

const getPrincipalHeader = (type: RoleAssignmentPrincipalType) => {
	return type === RoleAssignmentPrincipalType.ACCESS_CLASS
		? i18n.accessClassColumnHeader
		: i18n.groupColumnHeader;
};

const resolveDisplayName = (
	id: string,
	queryResultDisplayName: string,
	formatMessage: ReturnType<typeof useIntl>['formatMessage'],
) => {
	switch (id) {
		case ALL_LICENSED_USERS_ID:
			return formatMessage(i18n.allLicensedUsersDisplayName);
		case ALL_PRODUCT_ADMINS_ID:
			return formatMessage(i18n.allProductAdminsDisplayName);
		default:
			return queryResultDisplayName;
	}
};

const transformToGroupRoleAssignments = (
	nodes: GroupRoleAssignmentNodes[],
	formatMessage: ReturnType<typeof useIntl>['formatMessage'],
): GroupRoleAssignmentRecord => {
	return nodes.reduce((acc, assignment) => {
		return {
			...acc,
			[assignment.principal.principalId]: {
				assignmentId: assignment.principal.principalId,
				principal: {
					id: assignment.principal.principalId,
					displayName: resolveDisplayName(
						assignment.principal.principalId,
						assignment.principal.displayName,
						formatMessage,
					),
					// We are hydrating this separately
					groupCount: undefined,
					type: getPrincipalType(assignment.principal.__typename),
					icon: getPrincipalIcon(assignment.principal.__typename),
				},
				roleId: assignment.role?.roleId ?? null,
				roleLabel: assignment.role?.roleDisplayName ?? '',
				permissions:
					assignment?.permissions?.map((permission) => permission.id) ||
					assignment.role?.spacePermissionList.map((permission) => permission.id) ||
					[],
			},
		};
	}, {});
};

export type GroupRoleAssignment = {
	assignmentId: string;
	principal: {
		id: string;
		displayName: string;
		groupCount?: number;
		type: RoleAssignmentPrincipalType;
		icon: ComponentType<GlyphProps>;
	};
	roleId: string | null; // null - legacy role
	roleLabel: string | null;
	permissions: string[];
};

type GroupRoleAssignmentRecord = Record<string, GroupRoleAssignment>;

export const useDefaultRolesTableRows = ({
	allPermissions,
	roleOptions,
}: {
	allPermissions: SpacePermission[];
	roleOptions: SpaceRole[];
}) => {
	const { formatMessage } = useIntl();

	const DEFAULT_ACCESS_CLASSES = useMemo(
		() => getDefaultAccessClasses(formatMessage),
		[formatMessage],
	);

	const { handleResponse: handleMutationResponse, startExperience: startEditExperience } =
		useResponseHandler({
			experience: DEFAULT_RBAC_EDIT_EXPERIENCE,
			successMessage: formatMessage(i18n.updateRoleSuccess),
		});

	const { handleResponse: handleQueryResponse, startExperience: startViewExperience } =
		useResponseHandler({
			experience: DEFAULT_RBAC_VIEW_EXPERIENCE,
		});

	// Principal ID is used here to indicate loading states
	const [roleUpdateLoading, setRoleUpdateLoading] = useState<string | null>(null);
	const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
	const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);

	const [groupAssignmentRecord, setGroupAssignmentRecord] = useState<
		Record<string, GroupRoleAssignment>
	>({});

	const {
		data: groupsData,
		fetchMore,
		loading: isDefaultGroupsLoading,
		refetch,
	} = useQuery<DefaultSpaceRoleAssignmentsType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DefaultSpaceRoleAssignments,
		{
			onError: (error) => {
				handleQueryResponse({ error });
			},
			onCompleted: () => {
				handleQueryResponse({});
			},
		},
	);

	const refreshTableData = useCallback(async () => {
		startViewExperience();
		try {
			await refetch();
			handleQueryResponse({});
		} catch (error) {
			handleQueryResponse({ error });
		}
	}, [refetch, handleQueryResponse, startViewExperience]);

	useEffect(() => {
		if (groupsData) {
			const newGroupRecord = {
				...DEFAULT_ACCESS_CLASSES,
				...transformToGroupRoleAssignments(
					groupsData.defaultSpaceRoleAssignmentsAll.nodes,
					formatMessage,
				),
			};
			setGroupAssignmentRecord(newGroupRecord);
			setPageInfo(groupsData.defaultSpaceRoleAssignmentsAll.pageInfo);
		}
	}, [groupsData, DEFAULT_ACCESS_CLASSES, formatMessage]);

	const [setRoleAssignments] = useMutation<
		SetDefaultSpaceRoleAssignmentsType,
		SetDefaultSpaceRoleAssignmentsVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SetDefaultSpaceRoleAssignments,
		{
			onError: (error) =>
				handleMutationResponse({
					error,
					expectedErrors: [
						{
							400: formatMessage(i18n.updateLastAdminError),
						},
					],
				}),
			onCompleted: (response) =>
				handleMutationResponse({ errors: response.setDefaultSpaceRoleAssignments?.errors }),
		},
	);

	const { data: groupsCountsData } = useQuery<GroupCountsQueryResult, GroupCountsQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		GroupCountsQuery,
		{
			skip: isDefaultGroupsLoading,
			variables: {
				groupIds: Object.keys(groupAssignmentRecord),
			},
			onError: (error) => {
				// We shouldn't fail the experience, we just won't be able to show the group count
				markErrorAsHandled(error);
			},
		},
	);

	useEffect(() => {
		if (groupsCountsData?.groupCounts?.groupCounts) {
			const updatedGroupAssignmentRecord = cloneDeep(groupAssignmentRecord);

			groupsCountsData.groupCounts.groupCounts.forEach((groupCount) => {
				const assignment = groupCount?.key
					? updatedGroupAssignmentRecord[groupCount.key]
					: undefined;
				if (assignment) {
					assignment.principal.groupCount = groupCount?.value ?? undefined;
				}
			});

			if (!isEqual(updatedGroupAssignmentRecord, groupAssignmentRecord)) {
				setGroupAssignmentRecord(updatedGroupAssignmentRecord);
			}
		}
	}, [groupsCountsData, groupAssignmentRecord]);

	const fetchMoreRows = useCallback(async () => {
		startViewExperience();
		setIsFetchingMore(true);
		try {
			const { data } = await fetchMore({
				variables: {
					after: pageInfo?.endCursor,
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) {
						return prev;
					}
					return {
						defaultSpaceRoleAssignmentsAll: {
							...fetchMoreResult.defaultSpaceRoleAssignmentsAll,
							nodes: [
								...prev.defaultSpaceRoleAssignmentsAll.nodes,
								...fetchMoreResult.defaultSpaceRoleAssignmentsAll.nodes,
							],
							pageInfo: fetchMoreResult.defaultSpaceRoleAssignmentsAll.pageInfo,
						},
					};
				},
			});
			setPageInfo(data.defaultSpaceRoleAssignmentsAll.pageInfo);
			setGroupAssignmentRecord({
				...groupAssignmentRecord,
				...transformToGroupRoleAssignments(
					data.defaultSpaceRoleAssignmentsAll.nodes,
					formatMessage,
				),
			});
			setIsFetchingMore(false);
		} catch (error) {
			handleQueryResponse({ error });
			setIsFetchingMore(false);
		}
	}, [
		pageInfo,
		fetchMore,
		groupAssignmentRecord,
		formatMessage,
		handleQueryResponse,
		startViewExperience,
	]);

	/**
	 * Allow optimistic UI updates so that the entire table doesn't need to be updated on every change
	 */
	const updateRoleAndPermissions = useCallback(
		({
			principalId,
			roleId,
			roleLabel,
			permissions,
		}: {
			principalId: string;
			roleId: string | null;
			roleLabel: string | null;
			permissions: string[];
		}) => {
			const updatedGroupAssignmentRecord = {
				...groupAssignmentRecord,
				[principalId]: {
					...groupAssignmentRecord[principalId],
					roleId,
					roleLabel,
					permissions,
				},
			};
			setGroupAssignmentRecord(updatedGroupAssignmentRecord);
		},
		[groupAssignmentRecord],
	);
	const updateRole = useCallback(
		async ({
			principalId,
			roleId,
			roleLabel,
			rolePermissions,
			principalType,
		}: {
			principalId: string;
			roleId: string;
			roleLabel: string;
			rolePermissions: string[];
			principalType: RoleAssignmentPrincipalType;
		}) => {
			setRoleUpdateLoading(principalId);
			await setRoleAssignments({
				variables: {
					input: {
						spaceRoleAssignmentList: [
							{
								roleId,
								principal: {
									principalId,
									principalType,
								},
							},
						],
					},
				},
			});
			setRoleUpdateLoading(null);
			updateRoleAndPermissions({
				principalId,
				roleId,
				roleLabel,
				permissions: rolePermissions ?? [],
			});
		},
		[setRoleAssignments, updateRoleAndPermissions],
	);

	const updateCachedPermissions = useCallback(
		(principalId: string, permissions: string[]) => {
			updateRoleAndPermissions({ principalId, permissions, roleId: null, roleLabel: null });
		},
		[updateRoleAndPermissions],
	);

	const onOptionChange = useCallback(
		(principalId: string, principalType) => async (newRole: SpaceRole) => {
			startEditExperience();
			await updateRole({
				principalId,
				roleId: newRole.id,
				roleLabel: newRole.name,
				rolePermissions: newRole.permissions,
				principalType,
			});
		},
		[updateRole, startEditExperience],
	);

	const row_count = Object.values(groupAssignmentRecord).length;

	return {
		rows: Object.values(groupAssignmentRecord).map((assignment, index) => {
			const principalKey = `${assignment.principal.displayName}`;
			const principalId = assignment.principal.id;
			const principalType = assignment.principal.type;
			const groupCountText = assignment.principal.groupCount
				? formatMessage(i18n.groupCount, { count: assignment.principal.groupCount })
				: '';
			const isRowLoading = roleUpdateLoading === principalId;

			// Handle role selector display.
			// 1. Has roles - roleLabel
			// 2. No permissions & no roles - "No access"
			// 3. Has permissions but not mapped to roles - "Custom access" (null value)
			const roleSelectorValue = (() => {
				if (assignment.roleLabel && assignment.roleId) {
					return { label: assignment.roleLabel, value: assignment.roleId };
				} else if (assignment.permissions.length > 0) {
					return null;
				} else {
					return { label: formatMessage(i18n.noPermissionsDisplayName), value: 'invalid' };
				}
			})();

			// Non-anon access classes and groups can choose all roles
			const augmentedPrincipalAssignment = {
				...assignment,
				availableRoles: roleOptions,
			};

			return {
				key: `${assignment.assignmentId}`,
				cells: [
					{
						key: `group-details-${assignment.assignmentId}`,
						content: (
							<PrincipalCell
								key={principalKey}
								name={assignment.principal.displayName}
								subText={groupCountText}
								hasProfileBorder={false}
								Icon={assignment.principal.icon}
							/>
						),
					},
					{
						key: `group-${assignment.assignmentId}`,
						content: formatMessage(getPrincipalHeader(assignment.principal.type)),
					},
					{
						key: `role-${assignment.assignmentId}`,
						content: (
							<Inline alignBlock="baseline">
								<RoleSelector
									key={principalKey}
									value={roleSelectorValue}
									isLoading={isRowLoading}
									onChange={onOptionChange(principalId, principalType)}
									isTableSelect
									spaceRoleOptions={roleOptions}
									menuPlacement={
										// https://atlassian.slack.com/archives/CFJ9DU39U/p1728433102431099?thread_ts=1727991192.169569&cid=CFJ9DU39U
										// Adjust position for the last 5 rows, but only if there are more than 3 rows
										index + 1 > Math.max(row_count - 5, 3) ? 'top' : 'bottom'
									}
								/>
								<TableRowInfoButton
									allPermissions={allPermissions}
									principalRoleAssignment={augmentedPrincipalAssignment}
									spaceRoleOptions={roleOptions}
									updateRole={(id, roleId, roleLabel, rolePermissions) =>
										updateRole({
											principalId: id,
											roleId,
											roleLabel,
											rolePermissions,
											principalType: assignment.principal.type,
										})
									}
									updateCachedPermissions={updateCachedPermissions}
									refreshTableData={refreshTableData}
								/>
							</Inline>
						),
					},
					{
						key: `row-actions-${assignment.assignmentId}`,
						content: (
							<Box xcss={tableActionsMenuCSS}>
								<TableActionsMenu
									principalRoleAssignment={augmentedPrincipalAssignment}
									spaceRoleOptions={roleOptions}
									allPermissions={allPermissions}
									updateRole={(id, roleId, roleLabel, rolePermissions) =>
										updateRole({
											principalId: id,
											roleId,
											roleLabel,
											rolePermissions,
											principalType: assignment.principal.type,
										})
									}
									updateCachedPermissions={updateCachedPermissions}
									refreshTableData={refreshTableData}
								/>
							</Box>
						),
					},
				],
			};
		}),
		isLoading: isDefaultGroupsLoading || isFetchingMore,
		fetchMoreRows,
		hasNextPage: pageInfo?.hasNextPage ?? false,
	};
};
