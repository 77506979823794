import gql from 'graphql-tag';

export const HomeStarredPagesQuery = gql`
	query HomeStarredPagesQuery($limit: Int, $start: Int) {
		favoriteContent(limit: $limit, start: $start) {
			nodes {
				id
				subType
				type
				title
				excerpt
				links {
					editui
					webui
				}
				history {
					lastUpdated {
						when
					}
				}
				editorVersion: properties(key: "editor") {
					nodes {
						id
						key
						value
					}
				}
				emoji: properties(keys: ["emoji-title-published"]) {
					nodes {
						id
						key
						value
					}
				}
				metadata {
					currentuser {
						favourited {
							isFavourite
						}
						viewed {
							lastSeen
						}
					}
					likes {
						count
					}
					comments {
						commentsCount
					}
				}
				space {
					alias
					id
					key
					name
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
`;
