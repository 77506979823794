import { type EditorState } from '@atlaskit/editor-prosemirror/state';

import { getAIHighlightPositions } from '../../utils/selection';
import { getPluginState as getDecoPluginState } from '../decoration/decoration-plugin-factory';

export function isPseudoSelectionChanged(oldState: EditorState, newState: EditorState): boolean {
	const oldDecoSet = getDecoPluginState(oldState)?.modalDecorationSet;
	const newDecoSet = getDecoPluginState(newState)?.modalDecorationSet;

	const oldPositions = oldDecoSet
		? getAIHighlightPositions({ modalDecorationSet: oldDecoSet })
		: undefined;
	const newPositions = newDecoSet
		? getAIHighlightPositions({ modalDecorationSet: newDecoSet })
		: undefined;

	if ((oldPositions && !newPositions) || (!oldPositions && newPositions)) {
		return true;
	}

	if (oldPositions && newPositions) {
		return oldPositions[0] !== newPositions[0] || oldPositions[1] !== newPositions[1];
	}

	return false;
}
