import gql from 'graphql-tag';

export const SpaceRolesByCriteriaQuery = gql`
	query SpaceRolesByCriteriaQuery($spaceId: Long, $principal: RoleAssignmentPrincipalInput) {
		spaceRolesByCriteria(spaceId: $spaceId, first: 500, principal: $principal) {
			nodes {
				roleId
				roleDisplayName
				roleDescription
				roleType
				spacePermissionList {
					id
					priority
					displayName
					description
					requiredSpacePermissions
				}
			}
			pageInfo {
				endCursor
				hasNextPage
			}
		}
	}
`;
