import { defineMessages } from 'react-intl-next';

export default defineMessages({
	atlasGenerateContentConfigItemDescription: {
		id: 'fabric.editor.ai.atlas-prebuilt.atlasGenerateContentConfigItemDescription',
		defaultMessage: `Generates content to be inserted into the update`,
		description: 'Description for AI Generate content prompt',
	},
	atlasSummarizePageConfigItemDescription: {
		id: 'fabric.editor.ai.atlas-prebuilt.atlasSummarizePageConfigItemDescription',
		defaultMessage: `Summarizes the content of the update`,
		description: 'Description for AI Summarise content prompt',
	},
	confluenceChangeToneSelectionToolbarDropdownMenuTitle: {
		id: 'fabric.editor.ai.confluence-prebuilt.confluenceChangeToneSelectionToolbarDropdownMenuTitle',
		defaultMessage: 'Change tone',
		description:
			'Title for Atlassian Intelligence "Change Tone" dropdown in editor selection floating toolbar',
	},
	confluenceRewriteSelectionToolbarDropdownMenuTitle: {
		id: 'fabric.editor.ai.confluence-prebuilt.confluenceRewriteSelectionToolbarDropdownMenuTitle',
		defaultMessage: 'Rewrite',
		description:
			'Title for Atlassian Intelligence "Rewrite" dropdown in editor selection floating toolbar',
	},
	confluenceTranslateSelectionToolbarDropdownMenuTitle: {
		id: 'fabric.editor.ai.confluence-prebuilt.confluenceTranslateSelectionToolbarDropdownMenuTitle',
		defaultMessage: 'Translate',
		description:
			'Title for Atlassian Intelligence "Translate" dropdown in editor selection floating toolbar',
	},
});
