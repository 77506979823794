import type { HeadingProps as PlatformHeadingProps } from '@atlaskit/heading';
type HeadingTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type Sizes = 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | 'xxsmall';
/**
 * @deprecated
 * The original colors supported by the color prop.
 */
type TemporaryColors = 'default' | 'inverse';
//
/**
 * The new values supported by the platform color prop
 */
type NewColors = 'color.text' | 'color.text.inverse' | 'color.text.warning.inverse';

export type HeadingProps = Pick<PlatformHeadingProps, 'children' | 'id' | 'testId'> & {
	as: HeadingTags;
	color?: NewColors | TemporaryColors;
};

const onlyAllowHeadingTag = (asProp: string | undefined): HeadingTags | undefined => {
	if (asProp && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(asProp)) {
		return asProp as HeadingTags;
	}
	throw Error(`${asProp} is an invalid heading tag. Please use one of h1, h2, h3, h4, h5, h6.`);
};

const getSize = (asProp: HeadingTags) => {
	const sizeMap: Record<string, Sizes> = {
		h1: 'xlarge',
		h2: 'large',
		h3: 'medium',
		h4: 'small',
		h5: 'xsmall',
		h6: 'xxsmall',
	};
	return sizeMap[asProp];
};

// Exists to map old Heading color API to new API
const getColor = (colorProp?: TemporaryColors | NewColors) => {
	if (!colorProp) {
		return undefined;
	}

	const colorMap: Record<string, PlatformHeadingProps['color']> = {
		default: 'color.text',
		inverse: 'color.text.inverse',
		'color.text': 'color.text',
		'color.text.inverse': 'color.text.inverse',
		'color.text.warning.inverse': 'color.text.warning.inverse',
	};

	return colorMap[colorProp] as NewColors;
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::52df81cecc1e68a6ace11b5d3da4f48e>>
 * @codegenId #heading
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen heading
 * @codegenParams { "defineOwnType": true, "props": { "size": { "excludeFromDeconstruct": true, "value": "getSize(as)"}, "color": { "excludeFromDeconstruct": false, "value": "getColor(color)"}, "as": {"value": "onlyAllowHeadingTag(as)", "comment": "Other elements are not supported at this time"}, "children": true, "id": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/heading/src/types.tsx <<SignedSource::3fe039974b63733558793e67fa2ea113>>
 * @codegenDependency ../../../../../../../design-system/heading/src/heading-context.tsx <<SignedSource::51e0bf0ce8f470ed3926cfc68e1037ad>>
 * @codegenDependency ../../../../../../../design-system/heading/src/heading.partial.tsx <<SignedSource::68dbef89f8d208bfd75bad11bde96820>>
 * @codegenDependency ../../../../../../../design-system/heading/src/heading.tsx <<SignedSource::732b5366874bbb5025971e03a6769352>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformHeading from '@atlaskit/heading';

// Define the type for Heading as HeadingProps at the top of this file

export const Heading = (props: Parameters<RenderFn>[0]) => {
	const { color, as, id, testId } = props.forgeDoc.props as HeadingProps;
	return (
		<PlatformHeading
			size={getSize(as)}
			color={getColor(color)}
			// Other elements are not supported at this time
			as={onlyAllowHeadingTag(as)}
			children={props.forgeDoc.children.map((c) => (
				<React.Fragment key={c.key}>{props.render(c)}</React.Fragment>
			))}
			id={id}
			testId={testId}
		/>
	);
};
/**
 * @codegenEnd
 */
