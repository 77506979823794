/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { Y500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { Breakpoint } from '../../hooks/useElementBreakpoints';

const textContainerLayoutStyles = (breakpoint: Breakpoint) =>
	css({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		alignItems: breakpoint <= Breakpoint.Small ? 'flex-start' : 'center',
		flex: 1,
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		gap: '10px',
	});

export const getTextContainerStyles = (breakpoint: Breakpoint) => [
	textContainerLayoutStyles(breakpoint),
];

export const iconColor = token('color.icon.warning', Y500);

const ICON_TEXT_ALIGNMENT_OFFSET = '-2px';

export const getIconStyles = (breakpoint: Breakpoint) =>
	css({
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		lineHeight: 1,
		position: 'relative',
		flexShrink: 0,
		// 2px top offset at small breakpoints for better icon-text alignment
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		top: breakpoint <= Breakpoint.Small ? ICON_TEXT_ALIGNMENT_OFFSET : '0',
	});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const buttonContainerStyles = css({
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'row',
	justifyContent: 'flex-end',
	alignItems: 'center',
	gap: token('space.100', '8px'),
});

export const getContainerStyles = (breakpoint: Breakpoint) =>
	css({
		display: 'flex',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		flexDirection: breakpoint <= Breakpoint.Small ? 'column' : 'row',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		gap: breakpoint <= Breakpoint.Small ? '16px' : '8px',
	});
