import type { ApolloQueryResult } from 'apollo-client';

import type { Metadata } from '@atlaskit/editor-common/collab';

import { getApolloClient } from '@confluence/graphql';

import { CollabDraftQuery } from './CollabDraftQuery.graphql';
import type {
	CollabDraftQuery as CollabDraftQueryRes,
	CollabDraftQueryVariables,
} from './__types__/CollabDraftQuery';

export type InitialDraft = {
	document: any;
	version: number;
	metadata?: Metadata;
};

export type FetchDraft = {
	draft: InitialDraft | null;
	response?: Response | ApolloQueryResult<any>;
	error?: Error;
};

export async function fetchDraft(contentId: string): Promise<FetchDraft> {
	const response = await getApolloClient().query<CollabDraftQueryRes, CollabDraftQueryVariables>({
		query: CollabDraftQuery,
		variables: {
			id: contentId,
		},
		fetchPolicy: window.__SSR_RENDERED__ ? 'cache-first' : 'network-only',
	});
	const { data, errors } = response;

	if (errors || !data.collabDraft) {
		return { draft: null, response };
	}

	const { document, version, metadata } = data.collabDraft;

	const draft = {
		version,
		metadata: excludeTypename(metadata),
		document: JSON.parse(document || ''),
	} as InitialDraft;

	return { draft, response };
}

function excludeTypename(
	metadata?: Record<string, any> | null,
): Record<string, any> | null | undefined {
	if (!metadata) return metadata;
	const { __typename, ...rest } = metadata;
	return rest;
}
