import { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { RBAC_EDIT_EXPERIENCE } from '@confluence/experience-tracker';

import { normalizeSpaceRoleList } from '../../model/normalizers';
import type { SpaceRole, RoleAssignmentPrincipalType } from '../../model/space-roles-types';
import type { UserPickerPrincipalOption } from '../../page/principal-parser';
import { getIdFromPrincipal } from '../../page/principal-parser';
import { SpaceRolesByCriteriaQuery } from '../../graphql/SpaceRolesByCriteria.graphql';
import type {
	SpaceRolesByCriteriaQueryVariables,
	SpaceRolesByCriteriaQuery as SpaceRolesByCriteriaQueryResult,
} from '../../graphql/__types__/SpaceRolesByCriteriaQuery';
import { useResponseHandler } from '../../graphql/hooks/useResponseHandler';

import type { StagedPrincipal } from './addPrincipalModal.types';

type PrincipalId = string;

interface UseRoleCompatibilityCheckParams {
	spaceId: string | null;
	principalPickerOptions: UserPickerPrincipalOption[] | null;
	rolePickerValue: SpaceRole | null;
	stagedPrincipalList: StagedPrincipal[];
}

export const useRoleCompatibilityCheck = ({
	spaceId,
	principalPickerOptions,
	rolePickerValue,
	stagedPrincipalList,
}: UseRoleCompatibilityCheckParams) => {
	// - role compatibility check
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [roleOptionsError, setRoleOptionsError] = useState<Error | null>(null);
	const [spaceRoleOptionMap, setSpaceRoleOptionMap] = useState<Record<PrincipalId, SpaceRole[]>>(
		{},
	);
	const [incompatibleRoleWarning, setIncompatibleroleWarning] = useState<boolean>(false);
	const { handleResponse } = useResponseHandler({
		experience: RBAC_EDIT_EXPERIENCE,
	});
	const [requestRoleOptions, { loading: isRolesDataLoading, variables: requestRoleOptionsVars }] =
		useLazyQuery<SpaceRolesByCriteriaQueryResult, SpaceRolesByCriteriaQueryVariables>(
			SpaceRolesByCriteriaQuery,
			{
				fetchPolicy: 'cache-first',
				onError: (error) => {
					handleResponse({ error });
					setRoleOptionsError(error);
				},
				onCompleted: (data) => {
					const spaceRoleOptions = normalizeSpaceRoleList(data?.spaceRolesByCriteria.nodes || []);
					const principalId = requestRoleOptionsVars.principal?.principalId;

					if (!principalId) {
						throw new Error('Principal ID is unexpectedly null in the query parameters.');
					}

					const newMap = {
						...spaceRoleOptionMap,
						[principalId]: spaceRoleOptions,
					};
					// Save the results. Not planning to delete any items even when principal is removed.
					setSpaceRoleOptionMap(newMap);

					// Update the warning message
					// This method must be executed after the role options are loaded
					// Therefore useEffect is not an option
					updateIncompatibleRoleWarning(
						(principalPickerOptions || []).map((option) => getIdFromPrincipal(option)),
						rolePickerValue?.id,
						newMap,
					);
				},
			},
		);

	// Load role options and update the map
	const loadRoleOptionsForPrincipal = (
		principalId: PrincipalId,
		principalType: RoleAssignmentPrincipalType,
	) => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'loaded',
				actionSubject: 'roleOptions',
				source: 'spaceRoles',
			},
		}).fire();
		return requestRoleOptions({
			variables: {
				spaceId,
				principal: {
					principalId,
					principalType,
				},
			},
		});
	};

	// Verify if role assignment is compatible with the principal
	// This function is executed when the users are added to the staged list
	const checkRoleCompatibility = useCallback(
		(
			principalId: PrincipalId,
			roleId: string,
			referenceMap: Record<PrincipalId, SpaceRole[]> = spaceRoleOptionMap,
		): boolean => {
			// NOTE If we'd like to support several types of warning messages, we can
			// return a string to indicate the warning type in the future.

			// For the initial state, we'll not display the warning message.
			// It only indicates the role list is not loaded yet.
			if (!referenceMap[principalId]) return true;

			const roleOptions = referenceMap[principalId];
			return roleOptions.some((role) => role.id === roleId);
		},
		[spaceRoleOptionMap],
	);
	// Check if all role assignments are compatible with the principals
	const areRolesCompatible = stagedPrincipalList.every((principal) =>
		checkRoleCompatibility(principal.id, principal.role?.id || 'invalid-id'),
	);

	// Update the warning message status based on user picker and role picker
	const updateIncompatibleRoleWarning = useCallback(
		(
			principalIdList: PrincipalId[] | null | undefined,
			roleId: string | null | undefined,
			referenceMap: Record<PrincipalId, SpaceRole[]>,
		) => {
			if (!principalIdList || principalIdList.length === 0 || !roleId) {
				setIncompatibleroleWarning(false);
				return;
			}

			const isCompatible = principalIdList.every((id) =>
				checkRoleCompatibility(id, roleId, referenceMap),
			);
			setIncompatibleroleWarning(!isCompatible);
		},
		[setIncompatibleroleWarning, checkRoleCompatibility],
	);

	return {
		error: roleOptionsError,
		loading: isRolesDataLoading,
		areRolesCompatible,
		spaceRoleOptionMap,
		showIncompatibleRoleWarning: incompatibleRoleWarning,
		// Methods
		checkRoleCompatibility,
		loadRoleOptionsForPrincipal,
		updateIncompatibleRoleWarning,
		resetWarningMessage: () => setIncompatibleroleWarning(false),
	};
};
