import { useMemo } from 'react';

import { type JSONDocNode, JSONTransformer } from '@atlaskit/editor-json-transformer';
import { ProseMirrorContentStatistics } from '@atlassian/ai-model-io/utils/prosemirror-content-statistics';

export function usePromptStatistics({ userADFInput }: { userADFInput: JSONDocNode | undefined }) {
	return useMemo(() => {
		const jsonTransformer = new JSONTransformer();
		const promptStats = userADFInput
			? new ProseMirrorContentStatistics(jsonTransformer.parse(userADFInput))
			: undefined;

		return promptStats?.collectStatistics();
	}, [userADFInput]);
}
