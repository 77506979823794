import { ExperienceName } from '@atlassian/conversation-assistant-instrumentation';
import {
	type AssistanceServiceInjectedParams,
	createHookWithAssistanceService,
	useMutation,
} from '@atlassian/conversation-assistant-service';

type UseDeleteAgentProps = {
	onSuccess?: () => void;
	onError?: () => void;
};

export const useDeleteAgent = createHookWithAssistanceService(
	({
		onSuccess,
		onError,
		assistanceServiceInjectedParams: { assistanceService, experienceTracker },
	}: UseDeleteAgentProps & AssistanceServiceInjectedParams) => {
		const deleteAgent = useMutation({
			mutationFn: async (id: string) => {
				experienceTracker.start({
					id: ExperienceName.DELETE_AGENT,
					name: ExperienceName.DELETE_AGENT,
				});

				return await assistanceService.deleteAgent(id);
			},
			onSuccess: async (data) => {
				experienceTracker.succeed({
					name: ExperienceName.DELETE_AGENT,
				});

				onSuccess?.();

				return data;
			},
			onError: (error) => {
				experienceTracker.fail({
					name: ExperienceName.DELETE_AGENT,
					error,
				});

				onError?.();
			},
		});

		return { deleteAgent };
	},
);
