import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	aiToolbarTitle: {
		id: 'fabric.editor.ai.toolbar.titleText',
		defaultMessage: 'Atlassian Intelligence',
		description: 'Title for Atlassian Intelligence button in editor selection floating toolbar',
	},
	improveWritingToolbarIconTitle: {
		id: 'fabric.editor.ai.toolbar.improveWriting.title',
		defaultMessage: 'Improve writing',
		description:
			'Title for an option to use Atlassian Intellgience to improve the writing of content currently selected by the user',
	},
	improveWritingToolbarIconTooltip: {
		id: 'fabric.editor.ai.toolbar.improveWriting.tooltip',
		defaultMessage: 'Tell Atlassian Intelligence to improve the writing of your selection',
		description:
			'Tooltip text for an option to use Atlassian Intellgience to improve the writing of content currently selected by the user',
	},
	tryAIToolbarIconTitle: {
		id: 'fabric.editor.ai.toolbar.tryAI.title',
		defaultMessage: 'Try AI',
		description: 'Title for an option to use Atlassian Intellgience',
	},
	tryAIToolbarIconTooltip: {
		id: 'fabric.editor.ai.toolbar.tryAI.tooltip',
		defaultMessage: 'Open Atlassian Intelligence',
		description: 'Tooltip text for an option to use Atlassian Intellgience',
	},
});
