/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import { Flex, xcss } from '@atlaskit/primitives';

import { AnnouncementBannerItem } from './AnnouncementBannerItem';

const boxStyles = xcss({
	padding: 'space.100',
	borderTopStyle: 'solid',
	borderTopWidth: '1px',
	borderTopColor: 'color.border',
});

export interface AnnouncementBanner {
	id: string;
	text: string;
	onClose: () => void;
}

interface Props {
	banners: AnnouncementBanner[];
}

export function AnnouncementBanners({ banners }: Props) {
	if (banners.length === 0) {
		return null;
	}

	return (
		<Flex xcss={boxStyles} direction="column" gap="space.100">
			{banners.map((banner) => (
				<AnnouncementBannerItem key={banner.id} text={banner.text} onClose={banner.onClose} />
			))}
		</Flex>
	);
}
