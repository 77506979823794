import React, { createContext, useMemo, useCallback, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';

import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { expValEquals } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';
import { cfetch } from '@confluence/network';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { GetUserAccessQuery } from './GetUserAccessQuery.graphql';
import type {
	GetUserAccessQuery as GetUserAccessQueryType,
	GetUserAccessQueryVariables,
} from './__types__/GetUserAccessQuery';
import { LicenseStatus } from './__types__/GetUserAccessQuery';

const INVITE_CAPABILITIES_URL = '/gateway/api/v3/invitations/capabilities';

type XProductUserInviteContextType = {
	isEligibleXProductUserInvite?: () => Promise<boolean>;
	onXProductUserInviteClick?: (name: string, isSuccess: boolean) => void;
};

type XProductUserInviteProviderProps = {
	flags: FlagsStateContainer;
	children: React.ReactNode;
};

const i18n = defineMessages({
	inviteSuccessTitle: {
		id: 'fabric-media-support.mentions-provider.x-product-user-invite.success.title',
		description: 'Success title message when a user is invited to a product',
		defaultMessage: 'Successfully added',
	},
	inviteSuccessDescription: {
		id: 'fabric-media-support.mentions-provider.x-product-user-invite.success.description',
		description: 'Success message when a user is invited to a product',
		defaultMessage: 'We sent an email to <b>{name}</b> confirming you added them to Confluence.',
	},
});

export const XProductUserInviteContext = createContext<XProductUserInviteContextType>({});

const hasDirectInviteCapabilities = async (cloudId: string): Promise<boolean> => {
	try {
		const canUserAccessJira = await hasJiraSoftwareAccess(cloudId);
		if (canUserAccessJira) {
			const response = await cfetch(
				`${INVITE_CAPABILITIES_URL}?resource=ari:cloud:platform::site/${cloudId}`,
				{
					method: 'GET',
					headers: {
						ACCEPT: 'application/json',
					},
				},
			);

			if (!response.ok) {
				return false;
			}

			const data = await response.json();
			return data.some(
				(item) =>
					item?.resourceARI?.includes('confluence') && item?.directInvite?.mode === 'ANYONE',
			);
		}
		return false;
	} catch (e) {
		return false;
	}
};

const hasJiraSoftwareAccess = async (cloudId: string): Promise<boolean> => {
	return getApolloClient()
		.query<GetUserAccessQueryType, GetUserAccessQueryVariables>({
			query: GetUserAccessQuery,
			variables: {
				resourceId: `ari:cloud:jira::site/${cloudId}`,
				permissionId: 'write',
			},
		})
		.then(({ data }) => {
			const hasJira = data?.tenantContext?.licensedProducts.some(
				(product) =>
					product?.productKey?.toLowerCase().includes('jira-software') &&
					product?.licenseStatus === LicenseStatus.ACTIVE,
			);
			return !!hasJira && data?.permitted;
		})
		.catch((error) => {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.EDITIONS,
			});
			markErrorAsHandled(error);
			return false;
		});
};

export const XProductUserInviteProviderComponent = ({
	children,
	flags,
}: XProductUserInviteProviderProps) => {
	const { accessStatus, cloudId } = useSessionData();
	const { formatMessage } = useIntl();
	const promiseRef = useRef<Promise<boolean>>();

	const isEligibleXProductUserInvite = useCallback(() => {
		//check the eligibility criteria only once per SPA
		if (promiseRef.current) {
			return promiseRef.current;
		}

		promiseRef.current = (async () => {
			try {
				if (
					!fg('cc_virality_x_product_user_invite_kill_switch') ||
					!(
						accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
						accessStatus === AccessStatus.LICENSED_USE_ACCESS
					)
				) {
					return false;
				}
				const capabilities = await hasDirectInviteCapabilities(cloudId);
				const eligible =
					capabilities && expValEquals('cc_virality_x_product_user_invite', 'cohort', 'test');
				return eligible;
			} catch (e) {
				return false;
			}
		})();

		return promiseRef.current;
	}, [accessStatus, cloudId]);

	const showSuccessFlag = useCallback(
		(name: string, isSuccess: boolean): void => {
			if (isSuccess) {
				void flags.showFlag({
					id: `x-product-user-invite-success`,
					title: formatMessage(i18n.inviteSuccessTitle),
					description: formatMessage(i18n.inviteSuccessDescription, {
						name,
						b: (chunks: React.ReactNode[]) => <Text weight="bold">{chunks}</Text>,
					}),
					type: 'success-circle',
				});
			}
		},
		[flags, formatMessage],
	);

	const value = useMemo(
		() => ({
			isEligibleXProductUserInvite,
			onXProductUserInviteClick: (name: string, isSuccess: boolean) => {
				showSuccessFlag(name, isSuccess);
			},
		}),
		[isEligibleXProductUserInvite, showSuccessFlag],
	);

	return (
		<XProductUserInviteContext.Provider value={value}>
			{children}
		</XProductUserInviteContext.Provider>
	);
};

export const XProductUserInviteProvider = withFlags(XProductUserInviteProviderComponent);
