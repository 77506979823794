import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { Grid, Box, xcss } from '@atlaskit/primitives';

const principalHeaderStyle = xcss({
	width: '200px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PrincipalItem = styled.div({
	gridArea: 'principal',
});

type PrincipalHeaderProps = {
	headerName: string;
};

export const PrincipalHeader: FC<PrincipalHeaderProps> = ({ headerName }) => (
	<Box xcss={[principalHeaderStyle]}>
		<Grid
			templateColumns="1fr"
			templateRows="1fr 1fr 1fr"
			templateAreas={['empty1', 'empty2', 'principal']}
		>
			<PrincipalItem>{headerName}</PrincipalItem>
		</Grid>
	</Box>
);
