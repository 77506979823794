import { isPageId } from '../utils';

import { buildResolver as spacekeyResolver } from './spacekey-resolver';
import { confluenceContentResolver } from './confluence-content-resolver';
import { dedupeResolver } from './dedupe-resolver';

import { fg } from '@confluence/feature-gating';

const spacekeyWithCurrentSpace = spacekeyResolver(true);

export const spaceAndPageResolver = dedupeResolver(
	async (searchTerm?: string, selectedValue?: string | string[]) => {
		if (typeof selectedValue === 'string') {
			selectedValue = selectedValue.split(',');
		}

		let selectedSpaces: string[] | undefined;
		let selectedPages: string[] | undefined;

		if (selectedValue && selectedValue.length > 0) {
			selectedSpaces = selectedValue.filter((value) => !isPageId(value));
			selectedSpaces = selectedSpaces.length ? selectedSpaces : undefined;

			selectedPages = selectedValue.filter(isPageId);
			selectedPages = selectedPages.length ? selectedPages : undefined;
		}

		const spaceResults = await spacekeyWithCurrentSpace(searchTerm, selectedSpaces);

		// we don't want to find blogposts; this resolver is used for spaceAndPage field for task report macro which
		// does not support blog posts
		const taskReportTypes = `page${fg('folder_support_in_task_report_macro') ? ', folder' : ''}`;
		const contentResults = await confluenceContentResolver(searchTerm, selectedPages, {
			type: taskReportTypes,
		});

		return [spaceResults, contentResults].flat();
	},
);
