import React, { useCallback } from 'react';
import { useIntl } from 'react-intl-next';

import type { OptionData } from '@atlaskit/user-picker';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	SitePermissionTypeFilter,
	SitePermissionType,
	UserAndGroupSearchPicker,
} from '@confluence/user-and-group-search';

export interface PrincipalOption extends OptionData {
	extra?: any;
}

const groupPickerStyle = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: 'space.050',
	paddingTop: 'space.050',
});

export const GroupPicker = ({ onAdd }: { onAdd: (newGroups: PrincipalOption[]) => void }) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [stagedGroups, setStagedGroups] = React.useState<PrincipalOption[] | null>(null);

	const onButtonClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'groupPickerAdd',
				source: 'defaultSpacePermissionsScreen',
			},
		}).fire();
		onAdd(stagedGroups || []);
		setStagedGroups(null);
	}, [stagedGroups, onAdd, createAnalyticsEvent]);

	return (
		<Box xcss={groupPickerStyle}>
			<UserAndGroupSearchPicker
				data-test-id="user-and-group-search"
				fieldId="default-space-permissions-user-picker"
				includeGroup
				withUsers={false}
				isMulti
				smart={false}
				placeholder={formatMessage({
					id: 'default-space-permissions.default-tab.group-picker-placeholder',
					defaultMessage: 'Search for a group',
					description: 'Placeholder text for the group picker',
				})}
				sitePermissionTypeFilter={SitePermissionTypeFilter.NONE}
				// TODO: Refactor user picker to typescript and update this type https://product-fabric.atlassian.net/browse/CPE-2272
				// @ts-ignore - Type 'null' is not assignable to type 'string | ((input: { inputValue: string; }) => string | null)'
				noOptionsMessage={null}
				onChange={(received) => {
					// @ts-ignore - Argument of type 'Value' is not assignable to parameter of type 'SetStateAction<PrincipalOption[] | null>'
					setStagedGroups(received);
				}}
				value={stagedGroups || []}
				filterSearchResults={(value: any) =>
					value?.extra?.permissionType !== SitePermissionType.EXTERNAL
				}
			/>
			<Button
				appearance="primary"
				isDisabled={stagedGroups !== null && stagedGroups.length === 0}
				onClick={onButtonClick}
			>
				{formatMessage({
					id: 'default-space-permissions.default-tab.group-picker-add-button',
					defaultMessage: 'Add',
					description: 'Add button for the group picker',
				})}
			</Button>
		</Box>
	);
};
