import React from 'react';

import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import type { ExtractInjectionAPI, ToolbarUIComponentFactory } from '@atlaskit/editor-common/types';
import { WithPluginState } from '@atlaskit/editor-common/with-plugin-state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import { type AIPlugin } from '../editor-plugin-ai';
import type { AIButtonPluginState } from '../pm-plugins/ai-button/ai-button-plugin';
import { aiButtonPluginKey } from '../pm-plugins/ai-button/ai-button-plugin-key';
import { aiProactivePluginKey } from '../pm-plugins/ai-proactive/ai-proactive-plugin-key';
import { toggleProactiveAISuggestionDisplayWithAnalytics } from '../pm-plugins/ai-proactive/commands-with-analytics';
import { type AIProactivePluginState } from '../pm-plugins/ai-proactive/states';
import { getUnreadRecommendationsCount } from '../pm-plugins/ai-proactive/utils';
import { createOpenAIModalCommand } from '../pm-plugins/decoration/actions';
import { type AIGlobalOptIn, type EditorPluginAIProvider } from '../types';
import { AtlassianIntelligenceToolbarButton } from '../ui/components/AtlassianIntelligenceToolbarButton/AtlassianIntelligenceToolbarButton';

type PrimaryToolbarComponentType = {
	api: ExtractInjectionAPI<AIPlugin>;
	editorPluginAIProvider: EditorPluginAIProvider;
	aiGlobalOptIn: AIGlobalOptIn;
	isProactiveAISupported: boolean;
	editorView: EditorView;
	disabled: boolean;
	aiButtonPluginState: AIButtonPluginState;
	aiProactivePluginState: AIProactivePluginState;
};

const PrimaryToolbarComponent = ({
	editorView,
	api,
	editorPluginAIProvider,
	aiGlobalOptIn,
	isProactiveAISupported,
	disabled,
	aiButtonPluginState,
	aiProactivePluginState,
}: PrimaryToolbarComponentType) => {
	const { connectivityState } = useSharedPluginState(api, ['connectivity']);
	const onClick = () => {
		const { state, dispatch } = editorView;
		const configItem = editorPluginAIProvider.baseGenerate;
		const openAIModalCommand = createOpenAIModalCommand({
			state,
			configItem,
			lastTriggeredFrom: 'mainToolbar',
			aiGlobalOptIn,
			triggeredFor: aiButtonPluginState.triggeredFor,
		});

		openAIModalCommand(state, dispatch);
	};

	const onProactiveClick = () => {
		const { state, dispatch } = editorView;
		toggleProactiveAISuggestionDisplayWithAnalytics(api.analytics?.actions)('mainToolbar')(
			state,
			dispatch,
		);
	};

	const showLoading =
		!aiProactivePluginState?.isProactiveContextPanelOpen &&
		!!aiProactivePluginState?.showNewRecommendationsNotification;

	const unreadCount = !!aiProactivePluginState
		? getUnreadRecommendationsCount(aiProactivePluginState)
		: undefined;

	const isOffline = connectivityState?.mode === 'offline' || false;

	return (
		<>
			{isProactiveAISupported ? (
				<AtlassianIntelligenceToolbarButton
					api={api}
					ButtonWrapper={editorPluginAIProvider?.AIButtonWrapper}
					onClick={onProactiveClick}
					disabled={disabled || isOffline}
					selected={!!aiProactivePluginState?.isProactiveContextPanelOpen}
					loading={showLoading}
					unreadCount={unreadCount}
					view={editorView}
					proactive
				/>
			) : (
				<AtlassianIntelligenceToolbarButton
					api={api}
					ButtonWrapper={editorPluginAIProvider?.AIButtonWrapper}
					onClick={onClick}
					disabled={disabled || isOffline || !aiButtonPluginState?.isSelectionValidForAIExperience}
					view={editorView}
				/>
			)}
		</>
	);
};

export const getAIPrimaryToolbarComponent =
	({
		api,
		editorPluginAIProvider,
		aiGlobalOptIn,
		isProactiveAISupported,
	}: {
		api: ExtractInjectionAPI<AIPlugin>;
		editorPluginAIProvider: EditorPluginAIProvider;
		aiGlobalOptIn: AIGlobalOptIn;
		isProactiveAISupported: boolean;
	}): ToolbarUIComponentFactory =>
	({ editorView, disabled }) => {
		return (
			<WithPluginState
				plugins={{
					pluginState: aiButtonPluginKey,
					proactivePluginState: aiProactivePluginKey,
				}}
				render={({ pluginState, proactivePluginState }) => (
					<PrimaryToolbarComponent
						editorView={editorView}
						api={api}
						editorPluginAIProvider={editorPluginAIProvider}
						aiGlobalOptIn={aiGlobalOptIn}
						isProactiveAISupported={isProactiveAISupported}
						disabled={disabled}
						aiButtonPluginState={pluginState}
						aiProactivePluginState={proactivePluginState}
					/>
				)}
			/>
		);
	};
