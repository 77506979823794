import React, { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Textfield from '@atlaskit/textfield';
import { Label } from '@atlaskit/form';
import Button from '@atlaskit/button';
import { Box, Flex, xcss } from '@atlaskit/primitives';

import { useAllSpacesTableAnalytics } from './useAllSpacesTableAnalytics';

const filterStyle = xcss({
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
});

const i18n = defineMessages({
	filterDescription: {
		id: 'default-space-permissions.filter.description',
		defaultMessage: 'Filter by key',
		description: 'title of the individual spaces table',
	},
	filterButton: {
		id: 'default-space-permissions.filter.button',
		defaultMessage: 'Filter',
		description: 'description of the individual spaces table',
	},
});

interface IndividualSpacesTableFilterProps {
	onFilterChange: (value: string) => void;
	clearCursorStack: () => void;
}

export const IndividualSpacesTableFilter: React.FC<IndividualSpacesTableFilterProps> = ({
	onFilterChange,
	clearCursorStack,
}) => {
	const [inputValue, setInputValue] = useState('');
	const { sendEventFilterByKey } = useAllSpacesTableAnalytics();

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleSubmit = () => {
		sendEventFilterByKey();

		onFilterChange(inputValue);
		clearCursorStack();
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleSubmit();
		}
	};

	return (
		<Box xcss={filterStyle}>
			<Label htmlFor="key-filter-textfield">
				<FormattedMessage {...i18n.filterDescription} />
			</Label>
			<Flex direction="row" justifyContent="start" alignItems="center" gap="space.100">
				<Textfield
					name="basic"
					id="key-filter-textfield"
					width="250"
					value={inputValue}
					onChange={handleFilterChange}
					onKeyDown={handleKeyDown}
					isCompact
				/>
				<Button appearance="primary" onClick={handleSubmit}>
					<FormattedMessage {...i18n.filterButton} />
				</Button>
			</Flex>
		</Box>
	);
};
