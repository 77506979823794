import React from 'react';

import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import { type PublicPluginAPI } from '@atlaskit/editor-common/types';
import { type FocusPlugin } from '@atlaskit/editor-plugin-focus';
import { Fragment, type Node } from '@atlaskit/editor-prosemirror/model';
import type { Selection } from '@atlaskit/editor-prosemirror/state';
import type { DecorationSet, EditorView } from '@atlaskit/editor-prosemirror/view';
import { type MentionNameDetails } from '@atlaskit/mention';
import { fg } from '@atlaskit/platform-feature-flags';
import { usePublish } from '@atlaskit/rovo-triggers';
import { convertProsemirrorNodeToMarkdown } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown';
import { getMentionMap } from '@atlassian/ai-model-io/utils/mention-map';

import { type AIPlugin } from '../../../editor-plugin-ai';
import { getPluginState } from '../../../pm-plugins/decoration/decoration-plugin-factory';
import { updatePublish } from '../../../pm-plugins/rovo-agents/commands';
import { type EditorPluginAIProvider } from '../../../types';
import { getAIHighlightPositions } from '../../../utils/selection';

export const getSelectionContent = ({
	modalDecorationSet,
	doc,
}: {
	modalDecorationSet: DecorationSet;
	doc: Node;
}): Fragment | undefined => {
	if (fg('platform_editor_ai_send_rovo_selection_changes')) {
		const positions = getAIHighlightPositions({ modalDecorationSet });
		return positions ? doc.slice(positions[0], positions[1], true).content : undefined;
	}

	const existingDecoration = modalDecorationSet
		.find(undefined, undefined, (spec) => spec.key === 'inlineDecoration')
		?.shift();
	const startWidget = modalDecorationSet
		.find(undefined, undefined, (spec) => spec.key === 'generatedStartWidget')
		?.shift()?.from;
	const endWidget = modalDecorationSet
		.find(undefined, undefined, (spec) => spec.key === 'generatedEndWidget')
		?.shift()?.to;

	// If an AI decoration exists, use the content within the decoration range instead
	if (existingDecoration) {
		return doc.slice(existingDecoration.from, existingDecoration.to, true).content;
	}

	if (startWidget && endWidget) {
		return doc.slice(startWidget, endWidget, true).content;
	}

	return;
};

type PublishArgs = {
	doc?: Node;
	selection?: Selection;
};

/**
 *  This component is required so we can call the usePublish hook
 *  and store the publish function in the rovo-agents plugin on mount.
 */
export const PublishToRovo = ({
	editorView,
	getMentionNameDetails,
	editorPluginAIProvider,
	editorApi,
}: {
	editorView: EditorView;
	getMentionNameDetails?: (id: string) => Promise<MentionNameDetails | undefined>;
	editorPluginAIProvider: EditorPluginAIProvider;
	editorApi: PublicPluginAPI<[FocusPlugin, AIPlugin]> | undefined;
}) => {
	const publish = usePublish('ai-mate');
	const { product } = editorPluginAIProvider;
	const { focusState, aiExperienceState } = useSharedPluginState(editorApi, [
		'focus',
		'aiExperience',
	]);

	const publishDocAndSelection = React.useCallback(
		async ({ doc, selection }: PublishArgs) => {
			if (!doc || !selection) {
				publish({
					type: 'editor-context-payload',
					source: 'editor',
					product,
					data: undefined,
				});
				return;
			}

			// Retrieve the modal decoration set from the plugin state to access the selection state,
			// since the editor's selection is cleared when the modal opens.
			const { modalDecorationSet } = getPluginState(editorView.state);

			// Initially, assume the selected content from editorView will be used
			let selectionContent = selection.content().content;

			const selectionContentFromDecorations = getSelectionContent({ modalDecorationSet, doc });

			if (selectionContentFromDecorations) {
				selectionContent = selectionContentFromDecorations;
			}

			let mentionMap = {};
			if (getMentionNameDetails) {
				mentionMap = await getMentionMap({ node: doc, getMentionNameDetails });
			}

			const { markdown: selectionMD } = convertProsemirrorNodeToMarkdown({
				node: Fragment.from(selectionContent),
				featureToggles: {
					markdownPlus: false,
				},
				mentionMap,
			});

			publish({
				type: 'editor-context-payload',
				source: 'editor',
				product,
				data: {
					document: { type: 'text/adf', content: doc.toJSON() },
					selection: { type: 'text/markdown', content: selectionMD },
				},
			});
		},
		[editorView, getMentionNameDetails, product, publish],
	);

	React.useEffect(() => {
		// This is the same as onBlur
		if (!focusState?.hasFocus) {
			publishDocAndSelection({ doc: editorView.state.doc, selection: editorView.state.selection });
		}
	}, [editorView, focusState, publishDocAndSelection]);

	// When text is selected or deselected, publish the updated selection context to Rovo
	React.useEffect(() => {
		if (fg('platform_editor_ai_send_rovo_selection_changes')) {
			if ((aiExperienceState?.nonCursorSelectionChangeCount ?? 0) >= 1) {
				publishDocAndSelection({
					doc: editorView.state.doc,
					selection: editorView.state.selection,
				});
			}
		}
	}, [editorView, aiExperienceState?.nonCursorSelectionChangeCount, publishDocAndSelection]);

	React.useEffect(() => {
		return () => {
			publish({
				type: 'editor-context-payload',
				source: 'editor',
				product,
				data: undefined,
			});
		};
	}, [publish, product]);

	updatePublish(publish)(editorView.state, editorView.dispatch);

	return <></>;
};
