import { type EditorView } from '@atlaskit/editor-prosemirror/view';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import {
	type EditorPluginAIConfigItem,
	getAllEditorPluginAIConfigItems,
} from '../../../config-items/config-items';
import { suggestTitleForConfluenceTitleToolbar } from '../../../prebuilt/config-items/suggest-title/suggest-title';
import { type EditorPluginAIProvider } from '../../../types';
import {
	type AdjustSelectionInstruction,
	type ConfigType,
	EventHubPrompt,
	type StartPromptData,
} from '../types';

const getConfigFromProvider = (
	editorPluginAIProvider: EditorPluginAIProvider,
	configType: ConfigType,
	key: CONFIG_ITEM_KEYS,
) => {
	if (key === CONFIG_ITEM_KEYS.FREE_GENERATE) {
		return editorPluginAIProvider.baseGenerate;
	} else {
		const configItem = getAllEditorPluginAIConfigItems({
			configItemWithOptions: editorPluginAIProvider.configItemWithOptions,
		}).find((suggestion) => suggestion.key === key);
		return configItem;
	}
};

const getConfigTypeByAdjustSelection = (
	adjustSelection?: AdjustSelectionInstruction,
): ConfigType => {
	switch (adjustSelection) {
		case 'fullDocument': {
			return 'rangeConfig';
		}
		case 'keepCurrent': {
			return 'rangeConfig';
		}
		default: {
			return 'emptyConfig';
		}
	}
};

export const getConfigItem = (
	prompt: StartPromptData['prompt'],
	editorPluginAIProvider: EditorPluginAIProvider,
	adjustSelection?: AdjustSelectionInstruction,
): EditorPluginAIConfigItem | undefined => {
	switch (prompt) {
		case EventHubPrompt.SuggestTitleConfluenceTitleToolbar: {
			return suggestTitleForConfluenceTitleToolbar;
		}

		case EventHubPrompt.SuggestTitle: {
			const suggestTitle = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
			);
			return suggestTitle;
		}

		case EventHubPrompt.CustomPrompt: {
			const freeGenerate = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.FREE_GENERATE,
			);
			return freeGenerate;
		}

		case EventHubPrompt.IssueReformatter: {
			const issueReformatter = getConfigFromProvider(
				editorPluginAIProvider,
				'rangeConfig',
				CONFIG_ITEM_KEYS.ADD_STRUCTURE,
			);
			return issueReformatter;
		}

		case EventHubPrompt.Summarize: {
			const summarize = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
			);
			return summarize;
		}

		case EventHubPrompt.ImproveWriting: {
			const improveWriting = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.IMPROVE_WRITING,
			);
			return improveWriting;
		}

		case EventHubPrompt.MakeShorter: {
			const makeShorter = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.MAKE_SHORTER,
			);
			return makeShorter;
		}

		case EventHubPrompt.FixSpellingGrammar: {
			const fixSpellingGrammar = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.FIX_SPELLING_AND_GRAMMAR,
			);
			return fixSpellingGrammar;
		}

		case EventHubPrompt.ChangeToneToProfessional: {
			const professionalTone = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.CHANGE_TONE_TO_PROFESSIONAL,
			);
			return professionalTone;
		}

		case EventHubPrompt.ChangeToneToNeutral: {
			const neutralTone = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.CHANGE_TONE_TO_NEUTRAL,
			);
			return neutralTone;
		}

		case EventHubPrompt.ChangeToneToEmpathetic: {
			const empatheticTone = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.CHANGE_TONE_TO_EMPATHETIC,
			);
			return empatheticTone;
		}

		case EventHubPrompt.ChangeToneToCasual: {
			const empatheticTone = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.CHANGE_TONE_TO_CASUAL,
			);
			return empatheticTone;
		}

		case EventHubPrompt.ChangeToneToEducational: {
			const empatheticTone = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.CHANGE_TONE_TO_EDUCATIONAL,
			);
			return empatheticTone;
		}

		default: {
			return undefined;
		}
	}
};

export const shouldReturnFallbackSuggestedTitle = (
	configItem: ReturnType<typeof getConfigItem>,
	currentEditorView: EditorView,
) => {
	return (
		configItem?.key === CONFIG_ITEM_KEYS.SUGGEST_A_TITLE &&
		currentEditorView.state.doc.textContent.trim() === ''
	);
};
