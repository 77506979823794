import type { MessageDescriptor } from 'react-intl-next';

import type { MediaProvider } from '@atlaskit/editor-common/provider-factory';
import type { EditorAppearance } from '@atlaskit/editor-common/types';
import type { JSONNode } from '@atlaskit/editor-json-transformer/types';
import { type usePublish } from '@atlaskit/rovo-triggers';
import type { PromptEditorProps } from '@atlassian/generative-ai-modal/screens/UserInputCommandPalette';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import { type AiLifeCycleDynamicAttributesGetter } from './analytics/analytics-flow/analyticsFlowTypes';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
	SelectionType,
} from './config-items/config-items';
import type { AuditLogSettings } from './pm-plugins/ai-audit-logs/types';
import type { Recommendation } from './pm-plugins/ai-proactive/api';

/**
 * WARNING - consider removal or renames of these events breaking changes - as
 * they are now surfaced in the prebuilt
 */
export const EDITOR_PLUGIN_AI_PROVIDER_EVENTS = {
	/**
	 * @private
	 * @deprecated
	 * This could change within the next few weeks, as we are looking to rework
	 * the logic to better capture feedback collection when those requirements
	 * become clearer. The change is likely to be an (additive) breaking API
	 * change, in the form of a more specific, feedback-focused type on the
	 * provider with information about success/failure.
	 *
	 * When a thumbs up button is clicked
	 */
	THUMBS_UP: 'THUMBS_UP',
	/**
	 * @private
	 * @deprecated
	 * This could change within the next few weeks, as we are looking to rework
	 * the logic to better capture feedback collection when those requirements
	 * become clearer. The change is likely to be an (additive) breaking API
	 * change, in the form of a more specific, feedback-focused type on the
	 * provider with information about success/failure.
	 *
	 * When a thumbs down button is clicked
	 */
	THUMBS_DOWN: 'THUMBS_DOWN',

	/**
	 * Please check with Ethan/PM upon removing onExperienceEvent
	 * calls, to confirm it is no longer used in any ongoing experiments.
	 * Specifically `REVIEW_STATE_ENTERED` && `EXPERIENCE_COMPLETE`
	 */
	/**
	 * When a user enters ANY review state (initial/interrogate)
	 */
	REVIEW_STATE_ENTERED: 'REVIEW_STATE_ENTERED',
	/**
	 * When the experience is complete (e.g. clicked an action in the preview
	 * screen leading to insertion of ai content)
	 */
	EXPERIENCE_COMPLETE: 'EXPERIENCE_COMPLETE',
	/**
	 * Experimenting with prompt_with_user_input in the event we trigger a tour
	 * from deeper inside(?)
	 *
	 * At current stage, we may only have a single tour onload, and this may
	 * be unnecessary - but adding it in as flexible, and we can deprecate/leave
	 * unused if we don't need it.
	 *
	 * 2023.0530 - leaving this out for now, as we don't have a use case for it,
	 * and can add it with quick turnaround if it is needed.
	 */
	// PROMPT_WITH_USER_INPUT: 'PROMPT_WITH_USER_INPUT',
	/**
	 * Not surfacing a "STARTED_STATE" esque prompt, as we don't have a use case
	 * right now (triggering a tour from STARTED_STATE is not the right spot, as
	 * it could shortcut straight into loading from a summarise use case.)
	 */
	// STARTED_STATE: 'STARTED_STATE',
	// EXPERIENCE_START: 'EXPERIENCE_START',
	// EXPERIENCE_END: 'EXPERIENCE_END',
};
type OnExperienceEvents = typeof EDITOR_PLUGIN_AI_PROVIDER_EVENTS;

export type SelectionToolbarDropdownOption = {
	label: MessageDescriptor;
	configItem: EditorPluginAIConfigItem;
};

export type SelectionToolbarDropdownConfig = {
	id: string;
	label: MessageDescriptor;
	options: SelectionToolbarDropdownOption[];
	beta?: boolean;
};

export type SelectionToolbarPromptButton = {
	id: string;
	tooltip?: MessageDescriptor;
	configItem: EditorPluginAIConfigItem;
};

export type SelectionToolbarDropdownItem = {
	label: MessageDescriptor;
	icon?: () => React.ReactElement;
	configItem: EditorPluginAIConfigItem;
	beta?: boolean;
};

// EDF-1840
// Not used at the moment, but could be used in the future to signify
// users interacting with generic AI.
// type ConvoAIProvider = {
// 	type: 'convo-ai';
// };

type RovoAIProvider = {
	type: 'rovo';
	agent: Pick<EditorAgent, 'id' | 'name' | 'icon' | 'identityAccountId'>;
};

type AIProvider = RovoAIProvider;

export type AIGlobalOptIn = {
	/**
	 * Current status of editorPluginAI can be
	 * - 'enabled': AI features should be enabled
	 * - 'disabled-opt-in': AI features should show all entry points but when used, they should trigger an opt-in flow
	 * - 'disabled': AI features/ entry points should not be presented/available to users
	 */
	status: 'enabled' | 'disabled-opt-in' | 'disabled';
	/**
	 * Callback that triggers opt in flow when status is 'disabled-opt-in'
	 */
	triggerOptInFlow: () => void;
};

export type AiUsageDisclaimer = {
	text: MessageDescriptor;
	tooltip: MessageDescriptor;
	url?: string;
};

// remove this when cleanup ai-proactive
export type ProactiveAIConfig = {
	/**
	 * Determines whether the Proactive AI experience should be enabled or not.
	 * Will require proactiveAIApiUrl to be enabled.
	 */
	enabled: boolean;
	/**
	 * Endpoint for interacting with the proactive AI API
	 * Will default to fallback endpoint defined by the AI plugin
	 */
	apiUrl: string;

	/**
	 * The default state of whether proactive ai is toggled on/off
	 */
	defaultToggledState?: boolean;

	/**
	 * Provides debounced time and debounced maxWait for current chunks.
	 * And throttled time for non current chunks.
	 */
	timings: {
		currentChunks: number;
		currentChunksMaxWait: number;
		nonCurrentChunks: number;
	};

	/**
	 * Configuration for whole document S+G checker.
	 */
	documentChecker?: ProactiveAIDocumentCheckerConfig;
};

// remove this when cleanup ai-proactive
export type ProactiveAIDocumentCheckerConfig = {
	/**
	 * Identifies whether the proactive AI document scanner is enabled or not.
	 */
	enabled: boolean;

	/**
	 * The maximum number of blocks a single proactive request should contain.
	 */
	blocksPerRequest: number;
	timings: {
		delayBetweenChecks: number;
		numberOfRequestsForIncrease: number;
		delayIncrease: number;
		maxDelay: number;
	};
};

/**
 * Provide contextual information to control hashes of channelIDs for use with
 * assistance-service.
 */
export type ChannelVariableContext = {
	'rovo-agent': {
		cloudId: string;
		userId: string;
		contentURL: string;
	};
};

export type AIPLuginProviders = {
	mediaProvider: Promise<MediaProvider>;
};

// Ignored via go/ees005
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionSideEffects = { [action: string]: (args?: any) => void };
// Ignored via go/ees005
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionOverrides = { [action: string]: (args?: any) => void };

export type EditorPluginAIProvider = {
	/*
	 * This config item is used as the default ai experience.
	 *
	 * A user can end up on it via
	 * - starting via quick insert (slash command) or selection toolbar
	 * - starting another action, and clearing the action
	 */
	baseGenerate: EditorPluginAIConfigItem;

	configItemWithOptions: EditorPluginAIConfigItemWithOptions[];

	/**
	 * When disableQuickInsert is not enabled, the quick insert list will contain the
	 * `baseGenerate` config item and the config items in `configItemsWithTriggers`.
	 */
	disableQuickInsert?: boolean;

	getSelectionToolbarDropdowns?: () => SelectionToolbarDropdownConfig[];

	/**
	 * **Single endpoint** for interacting with the v2/intent schema based generative AI API
	 *
	 * Documented at
	 * https://hello.atlassian.net/wiki/spaces/CA3/pages/2631558196/Spec+Platformized+API+spec+for+generative+AI+endpoint
	 *
	 * @example
	 * ```ts
	 * const endpoint = `${window.location.origin}/gateway/api/editor-ai/v2/generative/ai`
	 * ```
	 */
	generativeAIApiUrl: string;

	/**
	 * This flag is used to determine whether the primary toolbar button should be hidden or not.
	 */
	hidePrimaryToolbarButton?: boolean;

	// remove this when cleanup ai-proactive
	/**
	 * This is a high-level toggle which product can use to define whether or not they allow Proactive AI features to
	 * be enabled on their platforms.
	 */
	allowProactiveAIFeatures?: boolean;

	// remove this when cleanup ai-proactive
	/**
	 * Provides configuration options for Proactive AI.
	 */
	proactiveAIConfig: ProactiveAIConfig;

	/**
	 * **Required field for v2 generative AI API**
	 *
	 * Documented at
	 * https://hello.atlassian.net/wiki/spaces/CA3/pages/2749958432/Open+question+for+Option+4
	 *
	 */
	product:
		| 'CONFLUENCE'
		| 'ATLAS'
		| 'BITBUCKET'
		| 'TRELLO'
		| 'JSW'
		| 'JWM'
		| 'JSM'
		| 'JPD'
		| 'ELEVATE'
		| 'MERCURY'
		| 'CALIBER'
		/**
		 * Generic string here to allow for products to insert their own via the
		 * generic prebuilt option. Note that the product **must** be whitelisted
		 * in the BE for this to work.
		 */
		| string;

	// should we remove this when cleanup ai-proactive?
	/**
	 * Because we can't infer the editor appearance in parts of the AI plugin,
	 * we will turn this on for any prebuilts that want to opt-in to
	 * experiments for full page editors.
	 */
	isFullPageExperimentsEnabled?: boolean;

	/**
	 * Method to grab custom headers to be passed when making api requests via fetch.
	 * Receives the params fetch has been called with and should return a map of custom
	 * headers which will be spread onto the request.
	 *
	 * ie.
	 * ```ts
	 * getFetchCustomHeaders: () => ({
	 *   'Magic-header': getMagicHeaderFromExternalStore()
	 * })
	 * ```
	 */
	// Provided as function based on patterns seen in common data frameworks,
	// generally they provide it in case you are providing something like an auth
	// token via a custom header, and the auth token can get changed after setting
	// up the data framework.
	// Our initial need is only expected to be static
	// (we are adding it to help debug/support accessing the apis from atlas).
	getFetchCustomHeaders?: (input: RequestInfo, init?: RequestInit | undefined) => HeadersInit;

	/**
	 * Function to return contextual information to be used when generating
	 * channelIDs for assistance-service.
	 *
	 * Receives a purpose arg to indicate what context it is expecting, so
	 * product can return contextual information accordingly.
	 */
	getChannelVariables?: <T extends keyof ChannelVariableContext>(
		purpose: T,
	) => ChannelVariableContext[T] | null;

	/**
	 * For Rovo agents only at the moment.
	 * Side effects that products can pass through to be performed
	 * with certain actions. For example, Jira, in a specific editor, may
	 * pass through:
	 *
	 * ```
	 * { continueInChat: () => minimizeIssueModal() }
	 * ```
	 *
	 * This would be executed alongside the continueInChat action. (For now,
	 * these are manually wired up to where they might need to occur in editor
	 * AI code).
	 */
	actionSideEffects?: ActionSideEffects;

	/**
	 * For Rovo agents only at the moment.
	 * Overrides that products can pass through to be performed
	 * instead of an actions normal behaviour. For example, Jira, in a
	 * specific editor, may pass through:
	 *
	 * ```
	 * { continueInChat: () => openRovoNewTab() }
	 * ```
	 *
	 * This would be executed instead of the continueInChat action. (For now,
	 * these are manually wired up to where they might need to occur in editor
	 * AI code).
	 */
	actionOverrides?: ActionOverrides;

	/**
	 * Callback to handle product specific actions after an assistance-service
	 * agent has performed actions on the Editor document.
	 *
	 * @argument agent EditorAgent The agent which performed this action.
	 */
	onDocChangeByAgent?: (agent: EditorAgent) => void;

	/**
	 * Callback to allow Editor AI implementors to determine when a user has
	 * started interacting with an AI provider.
	 *
	 * @argument source 'command-palette' | 'rovo-chat' The source of the change
	 * @argument provider AIProvider The provider being interacted with
	 */
	onAIProviderChanged?: (source: 'command-palette' | 'rovo-chat', provider?: AIProvider) => void;

	/**
	 * @experimental ** EXPERIMENTAL! **
	 *
	 * A list of callbacks which are fired upon those triggers/lifecycle events
	 * inside of editor-plugin-ai.
	 *
	 * Keeping it as a simple list for now without a complex event/messaging
	 * system.
	 *
	 * @private
	 * @deprecated
	 *
	 * We own and are managing the integrations.
	 * It is scoped as private as there's no risk of ecosystems making use of it.
	 *
	 * Please migrate to handleFeedbackSubmission.
	 */
	onExperienceEvent?: {
		[key in keyof OnExperienceEvents]: () => void;
	};

	/**
	 *
	 * This defines the handler for the feedback submission,
	 * which is triggered when a user clicks on the feedback button.
	 *
	 * This handler receives metadata relevant to the the feedback, AI experience, and editor attributes.
	 *
	 * The products create their implementation of this function which must return a promise,
	 * indicating whether the feedback has been handled or not.
	 *
	 * @param feedbackMetadata Metadata about the feedback submission
	 * @returns
	 * - `{ status: 'submitted' }` if the feedback submission was successful
	 * - `{ status: 'discarded' }` if the feedback submission was discarded
	 * - `{ status: 'failed' }` if the feedback submission failed
	 *
	 */
	handleFeedbackSubmission?: (
		feedbackMetadata: FeedbackMetadata,
	) => Promise<{ status: 'submitted' } | { status: 'failed' } | { status: 'discarded' }>;

	/**
	 * This prevents the AI selection toolbar entry point from being rendered.
	 * The toolbar is currently only available in Confluence full page editor,
	 * but this option is available for future use cases where the toolbar may
	 * be rendered in other products.
	 * @default false
	 */
	disableAISelectionToolbar?: boolean;

	/**
	 * This option allows the product to hide interrogation flow on the preview screen.
	 */
	disableInterrogation?: boolean;

	/**
	 * This allows product consumers to wrap the primary toolbar AI button with their
	 * own custom component. This is useful for products like Trello that need to wrap the button
	 * in SpotlightTarget, SpotlightPulse or other changeboarding components.
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	AIButtonWrapper?: React.FC<{ children: React.ReactNode }>;

	/**
	 * This allows product consumers to wrap the primary toolbar AI S+G button with their
	 * own custom component
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	proactiveAIToolbarButtonWrapper?: React.FC<{ children: React.ReactNode }>;

	/**
	 * Products must create PromptEditor using creators exported by @atlassian/editor-ai-injected-editors.
	 * And they MUST pass it to editor-plugin-ai thorugh this prop.
	 * It will be then passed to @atlassian/generative-ai-modal,
	 *  which will use to render Editor as prompt input.
	 */
	PromptEditor: (props: PromptEditorProps) => JSX.Element;

	/**
	 * Let products override the default AI disclaimer text/link on the Preview/Response screen footer.
	 */
	aiUsageDisclaimer?: AiUsageDisclaimer;

	/**
	 * If this is set to true, the Rovo Agents will be enabled for the product.
	 *
	 * The default value depends on the product.
	 */
	isRovoEnabled?: boolean;

	/**
	 * Providers
	 */
	providers?: AIPLuginProviders;

	/**
	 * Getter function to dynamically inject attributes to the AI lifecyle events such as `aiInteraction initiated`
	 */
	aiLifeCycleDynamicAttributesGetter?: AiLifeCycleDynamicAttributesGetter;

	/**
	 * Use this to enable sending audit log events and pass audit log event data.
	 * Intended to be used by:
	 * - confluence page
	 * - jira issue
	 */
	auditLogSettings?: AuditLogSettings;
};

type BaseAIExperienceMetadata = {
	browserLocale?: readonly string[];
	userLocale?: string;
};
type ProactiveAIExperienceMetadata = BaseAIExperienceMetadata & {
	/**
	 * The following properties will only be available
	 * if hasUserConsent is true
	 */
	originalParagraph?: string;
	suggestedParagraph?: string | JSONNode[];
	recommendationId?: string;
};

type ProactiveFeedbackMetadatas<T extends string, U, V> = {
	sentiment: T;
	getAIExperience?: (hasUserConsent?: boolean) => V;
	editorAttributes: {
		product: U;
	};
};

export type ProactiveFeedbackMetadata = ProactiveFeedbackMetadatas<
	'good-recommendation' | 'bad-recommendation' | 'dismiss-recommendation',
	EditorPluginAIProvider['product'],
	ProactiveAIExperienceMetadata
>;

export type ProactiveContextPanelFeedback = {
	sentiment: 'recommendation-feedback';
	getAIExperience?: undefined;
	editorAttributes: {
		product: EditorPluginAIProvider['product'];
	};
};

export type ProactiveTriggerSource = 'contextPanel' | 'preview';

type AIPanelFeedbackMetadata = {
	sentiment: 'unknown';
	getAIExperience?: (hasUserConsent?: boolean) => {
		hasAcceptableUseWarning?: boolean;
		/**
		 * markdownResponse will only be available
		 * if hasUserConsent is true
		 */
		markdownResponse?: string;
	};
	editorAttributes: {
		appearance: 'unknown';
		product: EditorPluginAIProvider['product'];
	};
};

export type PluginFeedbackMetadata = {
	sentiment: 'good' | 'bad';
	getAIExperience?: (hasUserConsent?: boolean) => {
		hasAcceptableUseWarning?: boolean;
		configItemTitle: string;
		lastTriggeredFrom?: string;
		/**
		 * userPrompt and markdownResponse will only be available
		 * if hasUserConsent is true
		 */
		userPrompt?: string;
		markdownResponse?: string;
		inputOutputDiffRatio?: string;
	};
	editorAttributes: {
		appearance: EditorAppearance;
		product: EditorPluginAIProvider['product'];
	};
};

/**
 * The `sentiment` parameter is being extracted to
 * avoid double typing in analyticsFlowTypes, specifically
 * userFeedbackSentiment
 */
export type FeedbackSentiment =
	| PluginFeedbackMetadata['sentiment']
	| AIPanelFeedbackMetadata['sentiment']
	| ProactiveFeedbackMetadata['sentiment']
	| ProactiveContextPanelFeedback['sentiment'];

export type FeedbackMetadata =
	| PluginFeedbackMetadata
	| AIPanelFeedbackMetadata
	| ProactiveFeedbackMetadata
	| ProactiveContextPanelFeedback;

export type EditorPluginAIPromptResponseMeta = {
	inputOutputDiffRatio: string;
	loadingStatus?: string;
};

export type ConvoAIResponseRovoAction = {
	key: string;
	invocationId: string;
	data: {
		suggestion: 'insert' | 'replace';
		content: string;
	};
};

export type EditorPluginAIPromptResponseShapeMarkdown = {
	type: 'markdown';
	content: string;
	meta?: EditorPluginAIPromptResponseMeta;
	rovoActions?: ConvoAIResponseRovoAction[];
};

export type ConvoAIKnownMessageTemplate =
	| 'ANALYSING_QUERY'
	| 'CONTENT_SEARCH'
	| 'PAGE_HYDRATION'
	| 'NEXT_BEST_TASK'
	| 'WRITING';

export function isConvoAIKnownMessageTemplate(type: string): type is ConvoAIKnownMessageTemplate {
	return [
		'ANALYSING_QUERY',
		'CONTENT_SEARCH',
		'PAGE_HYDRATION',
		'NEXT_BEST_TASK',
		'WRITING',
	].includes(type);
}

export type EndExperience = (options?: { preserveEditorSelectionOnComplete?: boolean }) => void;

export type RovoActions = Partial<Record<SelectionType, ConvoAIResponseRovoAction['data']>>;

export type RovoPublish = ReturnType<typeof usePublish>;

export type EditorPluginAISharedState = {
	isProactiveEnabled: boolean;
	isSpellingGrammarEnabled: boolean;
	spellingGrammarErrorCount: number;
	recommendations: Recommendation[];
	selectedRecommendationId?: string;
	hoveredRecommendationId?: string;
	displayAllSuggestions: boolean;
	isLoading: boolean;
	isEmptyDocument: boolean;
	hasNoMoreSuggestions: boolean;
	nonCursorSelectionChangeCount?: number;
};
