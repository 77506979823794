export const generateConversationName = () => {
	const date = new Date();

	const formattedDate = date.toLocaleDateString(undefined, {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
	});
	const formattedTime = date.toLocaleTimeString(undefined, {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	});

	const name = `${formattedDate} ${formattedTime}`;

	return name;
};
