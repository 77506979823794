import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { useMutation } from '@apollo/react-hooks';

import LinkComponent from '@atlaskit/link';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';
import { SPACE_OVERVIEW, SPACE_PERMISSIONS_SETTINGS } from '@confluence/named-routes';

import type { AllIndividualSpaces_allIndividualSpaces_nodes as IndividualSpacesDataType } from '../../graphql/__types__/AllIndividualSpacesQuery';
import type {
	RecoverSpaceAdminPermissionInput,
	RecoverSpaceAdminPermission as RecoverSpaceAdminPermissionType,
	RecoverSpaceAdminPermissionVariables,
} from '../../graphql/__types__/RecoverSpaceAdminPermissionMutation';
import type {
	RecoverSpaceAdminRole as RecoverSpaceAdminRoleResult,
	RecoverSpaceAdminRoleVariables,
} from '../../graphql/__types__/RecoverSpaceAdminRoleMutation';
import { RecoverSpaceAdminPermissionMutation } from '../../graphql/RecoverSpaceAdminPermissionMutation.graphqls';
import { RecoverSpaceAdminRoleMutation } from '../../graphql/RecoverSpaceAdminRoleMutation.graphql';

import { ColumnKeys } from './individualSpacesTableHeaders';
import { useAllSpacesTableAnalytics } from './useAllSpacesTableAnalytics';

const i18n = defineMessages({
	managePermissions: {
		id: 'default-space-permissions.manage.permissions',
		defaultMessage: 'Manage access',
		description: 'Link text for managing permissions for a space',
	},
	recoverPermissions: {
		id: 'default-space-permissions.recover.permissions',
		defaultMessage: 'Recover admin permission',
		description: 'Button text for recovering permissions for a space',
	},
	mutationErrorFlag: {
		id: 'default-space-permissions.recover-permission.mutation-error-message',
		defaultMessage: 'Unable to recover space admin permission for the selected space.',
		description:
			'Error message that appears when a user tries to recover admin permission for a certain space but the request fails.',
	},
	mutationSuccessFlag: {
		id: 'default-space-permissions.recover-permission.mutation-success-message',
		defaultMessage: 'Admin permission has been recovered for the selected space.',
		description:
			'Success message that appears when a user is able to recover admin permission for the selected space.',
	},
});

const recoverPermissionStyle = xcss({
	fontWeight: token('font.weight.regular'),
});

const createTableRow = (
	node: IndividualSpacesDataType,
	onRowManageAccessClick: () => void,
	onRowRecoverPermissionButtonClick: (node: IndividualSpacesDataType) => void,
) => {
	const cells = [
		{
			key: ColumnKeys.NAME,
			content: <Link to={SPACE_OVERVIEW.toUrl({ spaceKey: node.key })}>{node.name}</Link>,
		},
		{
			key: ColumnKeys.KEY,
			content: node.key,
		},
		{
			key: ColumnKeys.OPERATIONS,
			content: node.spaceAdminAccess ? (
				<Link
					to={SPACE_PERMISSIONS_SETTINGS.toUrl({ spaceKey: node.key, tab: 'users' })}
					onClick={onRowManageAccessClick}
				>
					<FormattedMessage {...i18n.managePermissions} />
				</Link>
			) : (
				<LinkComponent onClick={() => onRowRecoverPermissionButtonClick(node)} href="#">
					<Box xcss={recoverPermissionStyle}>
						<FormattedMessage {...i18n.recoverPermissions} />
					</Box>
				</LinkComponent>
			),
		},
	];
	return { cells };
};

export const useIndividualSpacesRows = ({ data, flags, onRowRecoverPermissionButtonClick }) => {
	const [error, setError] = useState<Error | null>(null);

	const { formatMessage } = useIntl();
	const { sendEventManageAccess } = useAllSpacesTableAnalytics();

	const [recoverSpaceAdminPermission] = useMutation<
		RecoverSpaceAdminPermissionType,
		RecoverSpaceAdminPermissionVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		RecoverSpaceAdminPermissionMutation,
	);
	const [recoverSpaceAdminRole] = useMutation<
		RecoverSpaceAdminRoleResult,
		RecoverSpaceAdminRoleVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		RecoverSpaceAdminRoleMutation,
	);

	const [rows, setRows] = useState<IndividualSpacesDataType[]>(data || []);

	useEffect(() => {
		setRows(data || []);
	}, [data]);

	const recoverPermissionAction = async (node: IndividualSpacesDataType) => {
		try {
			if (fg('cc_perms_exp_rbac_fe_milestone_2')) {
				await recoverSpaceAdminRole({
					variables: { spaceId: node.id },
				});
			} else {
				const input: RecoverSpaceAdminPermissionInput = { spaceKey: node.key };
				await recoverSpaceAdminPermission({
					variables: { input },
				});
			}

			void flags.showSuccessFlag({
				id: 'default-space-permissions.recover-permission.mutation.success.message',
				title: formatMessage(i18n.mutationSuccessFlag),
				isAutoDismiss: true,
			});

			setRows((prevRows) =>
				prevRows.map((row) => (row.key === node.key ? { ...row, spaceAdminAccess: true } : row)),
			);
		} catch (error) {
			setError(error);
			void flags.showErrorFlag({
				id: 'default-space-permissions.recover-permission.mutation.error.message',
				title: formatMessage(i18n.mutationErrorFlag),
				isAutoDismiss: true,
			});
		}
	};

	return {
		data: rows?.map((node: IndividualSpacesDataType) =>
			createTableRow(node, sendEventManageAccess, onRowRecoverPermissionButtonClick),
		),
		error,
		recoverPermissions: recoverPermissionAction,
	};
};
