import { pluginFactory } from '@atlaskit/editor-common/utils';
import type { EditorState, ReadonlyTransaction } from '@atlaskit/editor-prosemirror/state';

import { aiAuditLogsPluginKey } from './ai-audit-log-plugin-key';
import { MAX_RETIRES, RETRY_DELAY } from './constants';
import { reducer } from './reducer';
import { type AuditLogEventData, type AIAuditLogPluginState } from './types';

function onDocChanged(
	tr: ReadonlyTransaction,
	pluginState: AIAuditLogPluginState,
): AIAuditLogPluginState {
	const eventState = pluginState['ai-generated-content-inserted'];

	if (eventState.eventStatus === 'not-sent' && tr.getMeta('isAiContentTransformation')) {
		return {
			...pluginState,
			'ai-generated-content-inserted': {
				...eventState,
				eventStatus: 'send-event',
			},
		};
	}

	return pluginState;
}

export const createInitialState =
	(eventData: AuditLogEventData) =>
	(_state: EditorState): AIAuditLogPluginState => {
		return {
			'ai-generated-content-inserted': {
				eventStatus: 'not-sent',
				retries: 0,
			},
			'aup-violation': {
				eventStatus: 'not-sent',
				retries: 0,
			},
			maxRetries: MAX_RETIRES,
			retryDelay: RETRY_DELAY,
			eventData,
		};
	};

export const { createPluginState, createCommand, getPluginState } = pluginFactory(
	aiAuditLogsPluginKey,
	reducer,
	{
		onDocChanged,
	},
);
