/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::b6d4cf10a74b1d808a0959bcfa032e3e>>
 * @codegenId #lozenge
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen lozenge
 * @codegenParams { "props": { "appearance": true, "children": { "value": "props.forgeDoc.children[0]?.props?.text", "comment": "Lozenge should only accept plain text as children" }, "isBold": true, "maxWidth": true, "testId": true } }
 * @codegenDependency ../../../../../../../design-system/lozenge/src/Lozenge/index.tsx <<SignedSource::944bb60335b63b5751868f6bcc968520>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformLozenge from '@atlaskit/lozenge';

type PlatformLozengeProps = React.ComponentProps<typeof PlatformLozenge>;

export type LozengeProps = Pick<
  PlatformLozengeProps,
  'appearance' | 'children' | 'isBold' | 'maxWidth' | 'testId'
>;

export const Lozenge = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    isBold,
    maxWidth,
    testId,
  } = props.forgeDoc.props as LozengeProps;
  return (
    <PlatformLozenge
      appearance={appearance}
      // Lozenge should only accept plain text as children
      children={props.forgeDoc.children[0]?.props?.text}
      isBold={isBold}
      maxWidth={maxWidth}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
