/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import React from 'react';

const AccessLockSVG = () => {
	return (
		<svg
			width="191"
			height="222"
			viewBox="0 0 191 222"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0)">
				<path d="M0 0.400391H190.2V221.9H0V0.400391Z" fill="white" fillOpacity="0.01" />
				<path
					d="M122.7 119.9H49.4V36.9998C49.8906 27.6107 53.9659 18.7679 60.7854 12.2956C67.6048 5.82318 76.6481 2.21484 86.05 2.21484C95.4519 2.21484 104.495 5.82318 111.315 12.2956C118.134 18.7679 122.209 27.6107 122.7 36.9998V119.9ZM61.4 107.9H110.7V36.9998C110.301 30.7336 107.53 24.8546 102.951 20.5583C98.3722 16.2619 92.3289 13.8706 86.05 13.8706C79.7711 13.8706 73.7279 16.2619 69.149 20.5583C64.5701 24.8546 61.7993 30.7336 61.4 36.9998V107.9Z"
					fill="#0065FF"
				/>
				<path
					d="M160.3 111.601C152.848 97.4396 150.855 81.0347 154.7 65.501C154.883 64.7606 154.894 63.9882 154.733 63.2428C154.572 62.4974 154.242 61.7989 153.769 61.2005C153.296 60.6022 152.693 60.1199 152.005 59.7907C151.317 59.4615 150.563 59.2939 149.8 59.301H19.1C18.348 59.2976 17.605 59.4639 16.9262 59.7874C16.2474 60.111 15.6503 60.5834 15.1794 61.1697C14.7085 61.7559 14.3758 62.4408 14.2063 63.1734C14.0367 63.906 14.0346 64.6675 14.2 65.401C17.9782 81.0252 15.9154 97.4916 8.4 111.701C2.5112 123.071 -0.389112 135.752 -0.0282299 148.551C0.332652 161.351 3.94288 173.848 10.463 184.868C16.9831 195.888 26.1988 205.069 37.2436 211.548C48.2884 218.026 60.7993 221.589 73.6 221.901H91.5C101.565 222.141 111.577 220.365 120.946 216.679C130.315 212.992 138.851 207.469 146.054 200.434C153.257 193.399 158.979 184.995 162.886 175.715C166.792 166.436 168.803 156.469 168.8 146.401C168.86 134.28 165.941 122.33 160.3 111.601Z"
					fill="#FF991F"
				/>
				<path
					d="M154.7 65.501C154.883 64.7606 154.894 63.9882 154.733 63.2428C154.572 62.4974 154.242 61.7989 153.769 61.2005C153.296 60.6022 152.693 60.1199 152.005 59.7907C151.317 59.4615 150.563 59.2939 149.8 59.301H36.9C36.154 59.3157 35.4203 59.494 34.7507 59.8232C34.0812 60.1523 33.492 60.6244 33.0247 61.2061C32.5574 61.7878 32.2235 62.4649 32.0464 63.1897C31.8694 63.9146 31.8535 64.6694 32 65.401C35.8087 81.0131 33.7816 97.4789 26.3 111.701C20.3828 123.006 17.4891 135.648 17.9 148.401C18.9 188.401 51.5 220.901 91.5 221.901C101.565 222.141 111.577 220.365 120.946 216.679C130.314 212.992 138.851 207.469 146.054 200.434C153.257 193.399 158.979 184.995 162.886 175.715C166.792 166.436 168.803 156.469 168.8 146.401C168.86 134.28 165.941 122.33 160.3 111.601C152.848 97.4396 150.855 81.0347 154.7 65.501Z"
					fill="#FFAB00"
				/>
				<path
					d="M93.4001 214H91.7001C80.2465 213.718 69.0525 210.53 59.1692 204.735C49.2859 198.939 41.0377 190.727 35.1991 180.87C29.3605 171.012 26.1231 159.832 25.7909 148.38C25.4587 136.928 28.0426 125.579 33.3001 115.4C41.036 100.558 43.5711 83.5517 40.5001 67.0996H146.2C143.017 83.4736 145.522 100.444 153.3 115.2C158.352 124.82 160.962 135.534 160.9 146.4C160.926 155.276 159.199 164.07 155.818 172.277C152.436 180.484 147.467 187.942 141.195 194.223C134.923 200.505 127.472 205.485 119.27 208.879C111.068 212.273 102.276 214.013 93.4001 214Z"
					fill="#FFC400"
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
				<g style={{ mixBlendMode: 'multiply' }}>
					<path d="M152.8 178.6L84.5 119.6L80.8 123.9L149.9 183.5L152.8 178.6Z" fill="#FFE380" />
				</g>
				<path
					d="M90.4 170.601C88.3452 170.598 86.3155 170.15 84.4509 169.287C82.5862 168.424 80.9312 167.166 79.5999 165.601C78.2601 164.053 77.2786 162.229 76.7254 160.259C76.1722 158.288 76.0611 156.219 76.4 154.201L78.3 141.601C75.4643 138.69 73.5294 135.023 72.7277 131.039C71.926 127.055 72.2917 122.925 73.7808 119.144C75.2699 115.363 77.8191 112.093 81.122 109.726C84.4249 107.359 88.3411 105.996 92.4 105.801H93.4C98.996 105.801 104.363 108.024 108.32 111.981C112.277 115.938 114.5 121.305 114.5 126.901C114.496 132.416 112.302 137.703 108.4 141.601L110.4 154.201C110.692 156.226 110.548 158.291 109.979 160.257C109.41 162.222 108.428 164.044 107.1 165.601C105.769 167.166 104.114 168.424 102.249 169.287C100.384 170.15 98.3547 170.598 96.3 170.601H90.4Z"
					fill="#FFAB00"
				/>
				<path
					d="M106.6 126.9C106.586 128.711 106.196 130.499 105.456 132.151C104.716 133.803 103.641 135.284 102.3 136.5C101.574 137.222 101.032 138.107 100.719 139.081C100.405 140.056 100.33 141.091 100.5 142.1L102.6 155.4C102.728 156.305 102.662 157.226 102.405 158.103C102.147 158.979 101.706 159.79 101.109 160.482C100.512 161.173 99.7741 161.729 98.9446 162.112C98.1152 162.494 97.2134 162.695 96.3 162.7H90.4C89.4866 162.695 88.5848 162.494 87.7554 162.112C86.926 161.729 86.188 161.173 85.5912 160.482C84.9944 159.79 84.5526 158.979 84.2954 158.103C84.0382 157.226 83.9715 156.305 84.1 155.4L86.2 142C86.3747 140.983 86.2925 139.937 85.9607 138.959C85.6289 137.981 85.058 137.102 84.3 136.4C82.8962 135.116 81.7988 133.532 81.0892 131.767C80.3795 130.001 80.0756 128.099 80.2 126.2C80.2928 122.699 81.7726 119.379 84.3137 116.969C86.8548 114.559 90.2492 113.258 93.75 113.35C97.2509 113.443 100.571 114.923 102.981 117.464C105.391 120.005 106.693 123.399 106.6 126.9Z"
					fill="#253858"
				/>
				<path
					d="M149.2 130.901H93.4C92.8286 130.992 92.2443 130.959 91.6872 130.802C91.1302 130.645 90.6138 130.37 90.1736 129.994C89.7334 129.619 89.3799 129.152 89.1375 128.627C88.8951 128.102 88.7695 127.53 88.7695 126.951C88.7695 126.373 88.8951 125.801 89.1375 125.275C89.3799 124.75 89.7334 124.284 90.1736 123.908C90.6138 123.532 91.1302 123.257 91.6872 123.1C92.2443 122.944 92.8286 122.91 93.4 123.001H149.2C150.14 123.151 150.995 123.631 151.613 124.355C152.23 125.079 152.57 126 152.57 126.951C152.57 127.903 152.23 128.823 151.613 129.547C150.995 130.271 150.14 130.751 149.2 130.901Z"
					fill="#0065FF"
				/>
				<path
					d="M153.1 126.901C153.075 125.875 152.656 124.897 151.93 124.172C151.204 123.446 150.226 123.027 149.2 123.001H112.4C111.829 122.91 111.244 122.944 110.687 123.1C110.13 123.257 109.614 123.532 109.174 123.908C108.733 124.284 108.38 124.75 108.137 125.275C107.895 125.801 107.77 126.373 107.77 126.951C107.77 127.53 107.895 128.102 108.137 128.627C108.38 129.152 108.733 129.619 109.174 129.994C109.614 130.37 110.13 130.645 110.687 130.802C111.244 130.959 111.829 130.992 112.4 130.901H149.2C150.243 130.875 151.235 130.442 151.964 129.695C152.693 128.947 153.1 127.945 153.1 126.901Z"
					fill="#2684FF"
				/>
				<path
					d="M167.7 149.4C163.25 149.4 158.9 148.081 155.2 145.608C151.5 143.136 148.616 139.622 146.913 135.511C145.21 131.399 144.764 126.875 145.632 122.511C146.5 118.146 148.643 114.137 151.79 110.99C154.937 107.844 158.946 105.701 163.31 104.833C167.675 103.965 172.199 104.41 176.31 106.113C180.422 107.816 183.936 110.7 186.408 114.4C188.88 118.1 190.2 122.45 190.2 126.9C190.2 132.868 187.829 138.591 183.61 142.81C179.39 147.03 173.667 149.4 167.7 149.4ZM167.7 112.3C164.812 112.3 161.99 113.157 159.589 114.761C157.188 116.365 155.316 118.645 154.211 121.313C153.106 123.981 152.817 126.917 153.381 129.749C153.944 132.581 155.334 135.182 157.376 137.224C159.418 139.266 162.02 140.657 164.852 141.22C167.684 141.783 170.619 141.494 173.287 140.389C175.955 139.284 178.235 137.413 179.839 135.012C181.444 132.611 182.3 129.788 182.3 126.9C182.274 123.036 180.727 119.338 177.995 116.606C175.262 113.873 171.564 112.327 167.7 112.3Z"
					fill="#2684FF"
				/>
				<path
					d="M67.8 165.301H55.6C55.3423 165.278 55.1009 165.166 54.918 164.983C54.7351 164.8 54.6225 164.558 54.6 164.301C54.6 164.036 54.7053 163.781 54.8929 163.594C55.0804 163.406 55.3348 163.301 55.6 163.301H67.8C68.0652 163.301 68.3196 163.406 68.5071 163.594C68.6946 163.781 68.8 164.036 68.8 164.301C68.7775 164.558 68.6649 164.8 68.482 164.983C68.2991 165.166 68.0576 165.278 67.8 165.301Z"
					fill="#FFFAE5"
				/>
				<path
					d="M62.7999 182.5C62.6675 182.512 62.5342 182.491 62.412 182.438C62.2897 182.386 62.1825 182.304 62.0999 182.2C61.9167 182.013 61.8141 181.761 61.8141 181.5C61.8141 181.238 61.9167 180.987 62.0999 180.8L70.4999 172.3C70.6925 172.155 70.9307 172.085 71.1708 172.102C71.4109 172.119 71.6368 172.222 71.807 172.393C71.9773 172.563 72.0804 172.789 72.0974 173.029C72.1145 173.269 72.0444 173.507 71.8999 173.7L63.4999 182.2C63.3098 182.38 63.0616 182.486 62.7999 182.5Z"
					fill="#FFFAE5"
				/>
				<path
					d="M79.9 189.6C79.6424 189.577 79.4009 189.464 79.218 189.282C79.0351 189.099 78.9226 188.857 78.9 188.6V175.6C78.9226 175.342 79.0351 175.1 79.218 174.918C79.4009 174.735 79.6424 174.622 79.9 174.6C80.1652 174.6 80.4196 174.705 80.6071 174.893C80.7947 175.08 80.9 175.334 80.9 175.6V188.6C80.9 188.865 80.7947 189.119 80.6071 189.307C80.4196 189.494 80.1652 189.6 79.9 189.6Z"
					fill="#FFFAE5"
				/>
				<path
					d="M128.8 111.301H116.7C116.565 111.317 116.428 111.302 116.299 111.257C116.17 111.213 116.054 111.14 115.957 111.043C115.861 110.947 115.788 110.83 115.743 110.702C115.699 110.573 115.684 110.436 115.7 110.301C115.7 110.036 115.805 109.781 115.993 109.594C116.18 109.406 116.435 109.301 116.7 109.301H128.8C129.065 109.301 129.32 109.406 129.507 109.594C129.695 109.781 129.8 110.036 129.8 110.301C129.816 110.436 129.801 110.573 129.757 110.702C129.712 110.83 129.639 110.947 129.543 111.043C129.446 111.14 129.33 111.213 129.201 111.257C129.072 111.302 128.935 111.317 128.8 111.301Z"
					fill="#FFFAE5"
				/>
				<path
					d="M113.2 102.601C112.938 102.587 112.69 102.481 112.5 102.301C112.317 102.114 112.214 101.863 112.214 101.601C112.214 101.339 112.317 101.088 112.5 100.901L121 92.5008C121.193 92.3563 121.431 92.2862 121.671 92.3033C121.911 92.3204 122.137 92.4235 122.307 92.5937C122.477 92.7639 122.58 92.9898 122.597 93.2299C122.615 93.47 122.544 93.7082 122.4 93.9008L113.9 102.301C113.812 102.399 113.704 102.477 113.583 102.528C113.462 102.58 113.331 102.605 113.2 102.601Z"
					fill="#FFFAE5"
				/>
				<path
					d="M104.5 100C104.235 100 103.98 99.8947 103.793 99.7072C103.605 99.5196 103.5 99.2653 103.5 99.0001V86.1C103.5 85.8348 103.605 85.5805 103.793 85.3929C103.98 85.2054 104.235 85.1 104.5 85.1C104.635 85.084 104.772 85.0988 104.901 85.1433C105.03 85.1879 105.146 85.2611 105.243 85.3574C105.339 85.4536 105.412 85.5705 105.457 85.6991C105.501 85.8278 105.516 85.9649 105.5 86.1V99.0001C105.516 99.1352 105.501 99.2723 105.457 99.401C105.412 99.5296 105.339 99.6465 105.243 99.7428C105.146 99.839 105.03 99.9122 104.901 99.9568C104.772 100.001 104.635 100.016 104.5 100Z"
					fill="#FFFAE5"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<path d="M0 0.400391H190.2V221.9H0V0.400391Z" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default AccessLockSVG;
