import { LoadablePaint } from '@confluence/loadable';

export const CompanyHubAnalyticsPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CompanyHubAnalyticsPage" */ '../src/analytics/CompanyHubAnalyticsPage'
			)
		).CompanyHubAnalyticsPage,
});
