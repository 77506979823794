/* eslint-disable @atlaskit/design-system/no-unsafe-style-overrides */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';

import messages from './messages';
import { escTextStyles } from './styles';

type Props = {
	onCancel: () => void;
	autoFocus?: boolean;
};

export const CancelEscButton = ({ onCancel, autoFocus }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Button autoFocus={autoFocus} testId="cancel-esc-button" type="button" onClick={onCancel}>
			{formatMessage(messages.labelCancel)}
			{/* ignore use-primitives-text below as it affects focus and all styles inside and outside the button */}
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/design-system/use-primitives-text -- Ignored via go/DSP-18766 */}
			<span css={escTextStyles}>{formatMessage(messages.labelEsc)}</span>
		</Button>
	);
};
