import React, { useCallback } from 'react';

import type { PromptEditor } from '@atlassian/generative-ai-modal/screens/UserInputCommandPalette';

import type {
	AdvancedPromptValue,
	EditorPluginAIConfigItem,
} from '../../../config-items/config-items';
import type { CommandPaletteEvent } from '../../command-palette-state-machine';

interface Props {
	promptEditor: PromptEditor;
	configItem: EditorPluginAIConfigItem;
	advancedPromptValue: AdvancedPromptValue | undefined;
	sendToCommandPalette: (event: CommandPaletteEvent) => void;
	kickoffAiExperience: (props: {
		configItem: EditorPluginAIConfigItem;
		userInput: string;
		advancedPromptValue: AdvancedPromptValue;
	}) => void;
}

export function usePromptBuilder({
	promptEditor,
	configItem,
	advancedPromptValue,
	sendToCommandPalette,
	kickoffAiExperience,
}: Props) {
	const handleSubmit = useCallback(
		(promptValue: AdvancedPromptValue) => {
			sendToCommandPalette({
				type: 'onAdvancedPromptValueChanged',
				advancedPromptValue: promptValue,
			});

			kickoffAiExperience({
				configItem,
				userInput: '',
				advancedPromptValue: promptValue,
			});
		},
		[configItem, kickoffAiExperience, sendToCommandPalette],
	);

	if (!configItem.advancedPrompt) {
		return undefined;
	}

	const PromptBuilder = configItem.advancedPrompt.promptBuilder;

	return (
		<PromptBuilder
			promptEditor={promptEditor}
			advancedPromptValue={advancedPromptValue}
			onSubmit={handleSubmit}
		/>
	);
}
