type ExponentialRetryOptions<T> = {
	fn: () => Promise<T>;
	maxRetries?: number;
	retryCount?: number;
	delay?: number;
	/** Predicate which determines if the error should be retried */
	errorFilter?: (error: any) => boolean;
};

/** Retry the function using exponential backoff */
export const exponentialRetry = async <T>({
	fn,
	maxRetries = 3,
	retryCount = 0,
	delay = 1000,
	errorFilter,
}: ExponentialRetryOptions<T>): Promise<T> => {
	try {
		return await fn();
	} catch (error) {
		if (errorFilter && !errorFilter(error)) {
			throw error;
		}

		if (retryCount >= maxRetries) {
			throw error;
		}

		await new Promise((resolve) => setTimeout(resolve, delay));

		return await exponentialRetry({
			fn,
			maxRetries,
			retryCount: retryCount + 1,
			delay: delay * 2,
			errorFilter,
		});
	}
};
