import React, { useCallback, useContext, useEffect, useState, type FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import { Stack, Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import {
	ExperienceTrackerContext,
	ExperienceStart,
	VIEW_ALL_SPACES_TAB,
} from '@confluence/experience-tracker';
import { BulkSpacePermissionsButton } from '@confluence/bulk-permissions/src';
import { ErrorDisplay, ErrorBoundary, Attribution } from '@confluence/error-boundary';
import { type WithFlagsProps, withFlags } from '@confluence/flags';
import { markErrorAsHandled } from '@confluence/graphql-error-processor';
import {
	PaginationArrowsWrapper,
	usePagination,
} from '@confluence/space-permissions/entry-points/Pagination';

import type {
	AllIndividualSpaces_allIndividualSpaces_nodes as IndividualSpacesDataType,
	AllIndividualSpaces as AllIndividualSpacesQueryType,
	AllIndividualSpacesVariables,
} from '../../graphql/__types__/AllIndividualSpacesQuery';
import { AllIndividualSpacesQuery } from '../../graphql/AllIndividualSpacesQuery.graphql';

import { individualSpacesTableHeaders } from './individualSpacesTableHeaders';
import { IndividualSpacesTableFilter } from './IndividualSpacesTableFilter';
import { RecoverPermissionFrictionDialog } from './RecoverPermissionFrictionDialog';
import { useIndividualSpacesRows } from './useIndividualSpacesRows';
import { useAllSpacesTableAnalytics } from './useAllSpacesTableAnalytics';

const allSpacesTitleStyle = xcss({
	font: token('font.heading.medium'),
});

const allSpacesDescriptionStyle = xcss({
	margin: '0',
});

const bulkPermsButtonStyle = xcss({
	paddingBottom: 'space.300',
});

const stackPaddingStyle = xcss({
	width: '100%',
});

const i18n = defineMessages({
	allSpacesTitle: {
		id: 'default-space-permissions.allspaces.title',
		defaultMessage: 'Spaces',
		description: 'title of the individual spaces table',
	},
	allSpacesDescription: {
		id: 'default-space-permissions.allspaces.description',
		defaultMessage: 'These are all the current spaces on your site.',
		description: 'description of the individual spaces table',
	},
	queryErrorFlag: {
		id: 'default-space-permissions.individual-spaces.query-error-message',
		defaultMessage: 'Unable to retrieve your spaces.',
		description:
			'Error message that appears when space data for the current user cannot be returned.',
	},
});

export const IndividualSpacesComponent: FC<WithFlagsProps> = ({ flags }) => {
	const [nodeForFrictionDialog, setNodeForFrictionDialog] =
		useState<IndividualSpacesDataType | null>(null);
	const [filterKey, setFilterKey] = useState<string>('');

	const { formatMessage } = useIntl();
	const {
		sendEventRecoverPermissionsStart,
		sendEventRecoverPermissionsConfirm,
		sendEventRecoverPermissionsCancel,
	} = useAllSpacesTableAnalytics();
	const {
		currentPageCursor,
		setHasNextPage,
		setHasPreviousPage,
		setNextPageCursor,
		clearCursorStack,
	} = usePagination();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const {
		data,
		loading: isLoading,
		error: queryError,
	} = useQuery<AllIndividualSpacesQueryType, AllIndividualSpacesVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		AllIndividualSpacesQuery,
		{
			variables: { after: currentPageCursor || '', key: filterKey },
			onError: (err) => {
				experienceTracker.stopOnError({
					name: VIEW_ALL_SPACES_TAB,
					error: err,
				});
			},
			onCompleted: () => {
				experienceTracker.succeed({
					name: VIEW_ALL_SPACES_TAB,
				});
			},
		},
	);

	const onRowRecoverPermissionButtonClick = (node: IndividualSpacesDataType) => {
		sendEventRecoverPermissionsStart();
		setNodeForFrictionDialog(node);
	};

	const {
		data: rows,
		error,
		recoverPermissions,
	} = useIndividualSpacesRows({
		data: data?.allIndividualSpaces?.nodes,
		flags,
		onRowRecoverPermissionButtonClick,
	});
	const headers = individualSpacesTableHeaders;

	const onFrictionDialogCancel = () => {
		sendEventRecoverPermissionsCancel();
		setNodeForFrictionDialog(null);
	};

	const onFrictionDialogConfirm = async () => {
		if (!nodeForFrictionDialog) {
			throw new Error('Node for friction dialog is not set'); // Not possible
		}
		sendEventRecoverPermissionsConfirm();

		await recoverPermissions(nodeForFrictionDialog);
		setNodeForFrictionDialog(null);
	};

	const onQueryError = useCallback(
		(error) => {
			void flags.showErrorFlag({
				id: 'individual-spaces.query.error.flag',
				title: formatMessage(i18n.queryErrorFlag),
				isAutoDismiss: true,
			});
			markErrorAsHandled(error);
		},
		[flags, formatMessage],
	);

	useEffect(() => {
		if (queryError) {
			onQueryError(queryError);
		}
	}, [queryError, onQueryError]);

	useEffect(() => {
		const pageInfo = data?.allIndividualSpaces?.pageInfo;
		if (pageInfo) {
			setHasNextPage(Boolean(pageInfo.hasNextPage));
			setNextPageCursor(pageInfo.endCursor);
			setHasPreviousPage(Boolean(pageInfo.startCursor !== ''));
		}
	}, [data?.allIndividualSpaces?.pageInfo, setHasNextPage, setNextPageCursor, setHasPreviousPage]);

	return (
		<Stack xcss={stackPaddingStyle}>
			{nodeForFrictionDialog !== null && (
				<RecoverPermissionFrictionDialog
					onCancel={onFrictionDialogCancel}
					onConfirm={onFrictionDialogConfirm}
				/>
			)}
			{error && <ErrorDisplay error={error} />}
			<Box xcss={bulkPermsButtonStyle}>
				<BulkSpacePermissionsButton />
			</Box>
			<Flex direction="column" gap="space.100">
				<Box xcss={allSpacesTitleStyle}>
					<FormattedMessage {...i18n.allSpacesTitle} />
				</Box>
				<Box xcss={allSpacesDescriptionStyle}>
					<FormattedMessage {...i18n.allSpacesDescription} />
				</Box>
			</Flex>
			<IndividualSpacesTableFilter
				onFilterChange={setFilterKey}
				clearCursorStack={clearCursorStack}
			/>
			<PaginationArrowsWrapper>
				<DynamicTable head={headers} rows={rows} loadingSpinnerSize="large" isLoading={isLoading} />
			</PaginationArrowsWrapper>
			<ExperienceStart name={VIEW_ALL_SPACES_TAB} />
		</Stack>
	);
};

export const IndividualSpacesTableComponentWithFlags = withFlags(({ flags }: WithFlagsProps) => (
	<ErrorBoundary attribution={Attribution.PERMISSIONS_EXPERIENCE}>
		<IndividualSpacesComponent flags={flags} />
	</ErrorBoundary>
));
