import type { ClientBasedAuth } from '@atlaskit/media-core';

import { ResourceRecorder } from './ResourceRecorder';
import { ObjectRecorder } from './ObjectRecorder';
import { ThemeSSRManager } from './ThemeSSRManager';
import { SSRPrepareErrorsClient as errorClient } from './SSRPrepareErrorsClient';
import { SSRBannerManager } from './SSRBannerManager';

export { SSRInlineScriptsInit } from './SSRInlineScriptsInit';

export { getSSRFeatureFlag, initFeatureFlags, getAllSSRFeatureFlags } from './SSRFeatureFlags';

export { SSRActionLoadingSpinner } from './SSRActionLoadingSpinner';
export { sanitizeDataForSSR } from './sanitizeDataForSSR';
export { setSSRRenderInputs, getSSRRenderInputs } from './ssrRenderInputs';

export { FullCycleRender, fullCycleRenderRegistry } from './FullCycleRender';

export {
	getSSRPartialSuccess,
	setSSRPartialSuccess,
	checkSSRPartialSuccess,
	SSR_PARTIAL_COMPONENT,
	SetSSRPartialSuccess,
	clearSSRPartialSuccess,
} from './ssrPartialSuccess';

export { useRenderServerPlaceholder } from './useRenderServerPlaceholder';

export const ssrPrepareErrorsClient = new errorClient();
export const superbatchCSSRecorder = new ResourceRecorder();
export const superbatchJSRecorder = new ResourceRecorder();
export const mediaConfigRecorder = new ObjectRecorder<ClientBasedAuth>();
export const globalMacrosCSSRecorder = new ResourceRecorder();
export const globalMacrosJSRecorder = new ResourceRecorder();
export const themeSSRManager = new ThemeSSRManager();
export const ssrBannerManager = new SSRBannerManager();
