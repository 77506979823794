import { defineMessages } from 'react-intl-next';

export default defineMessages({
	documentInsertError: {
		id: 'fabric.editor.ai.experience-application.documentInsertError',
		defaultMessage: `We're having trouble inserting the response. Close the dialog and try again.`,
		description: 'Message to users that displays when error happens during document insertion.',
	},
	tokenLimitExceeded: {
		id: 'fabric.editor.ai.experience-application.tokenLimitExceeded',
		defaultMessage:
			"We couldn't get a response. Your prompt or response was over the limit for this request. Close the dialog and try again.",
		description:
			'Message to users that displays when their request has exceeded the maximum input or output limit.',
	},
	apiError: {
		id: 'fabric.editor.ai.experience.apiError',
		defaultMessage: `We couldn’t get a response, please try again.`,
		description: `We couldn't get a response due to an api error (ie. the backend responded with an error, or got a timeout)`,
	},
	inputTooShortError: {
		id: 'fabric.editor.ai.experience.inputTooShortError',
		defaultMessage: `The content is too short to summarize. Please add more and try again.`,
		description: `The input was too short to summarize`,
	},
	cancelLabel: {
		id: 'fabric.editor.ai.screens.error.cancelLabel',
		defaultMessage: 'Cancel',
		description:
			'Label for the "Cancel" button on the "Error" screen, which allows users to close the error message and return to the previous screen',
	},
	retryLabel: {
		id: 'fabric.editor.ai.screens.error.retryLabel',
		defaultMessage: 'Try again',
		description:
			'Label for the "Try again" button on the "Error" screen, which allows users to retry the failed action or process',
	},
	warningIconLabel: {
		id: 'fabric.editor.ai.screens.error.warningIconLabel',
		defaultMessage: 'Warning',
		description: 'Aria label for the warning icon on error screens',
	},
	ariaErrorTitle: {
		id: 'fabric.editor.ai.screens.error.ariaErrorTitle',
		defaultMessage: 'Error',
		description: 'Accessible title for the error screen',
	},
});
