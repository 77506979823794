import type { OptionData } from '@atlaskit/user-picker';

export interface UserPickerPrincipalOption extends OptionData {
	extra?: any;
}

export interface ValidPrincipalOption {
	id: string;
	name: string;
	// In fact guests won't be identified as external_user by UserPicker component
	type?: 'user' | 'team' | 'email' | 'group' | 'custom' | 'external_user';
	isGuest?: boolean;
	subText?: string;
	avatarUrl?: string;
}

export const parseUserPickerOptionIntoPrincipal = (
	option: UserPickerPrincipalOption,
): ValidPrincipalOption => {
	const { extra, avatarUrl, type } = option;
	const { accountId, id: groupId, name: groupName, displayName, permissionType } = extra;
	if (type === undefined) {
		// Note: The `type` should not be undefined in normal cases
		throw new Error('"type" is undefined in principal picker option');
	} else if (type === 'group') {
		return {
			id: groupId,
			name: groupName,
			type,
		};
	} else {
		return {
			id: accountId,
			name: displayName,
			type,
			isGuest: permissionType && permissionType === 'EXTERNAL',
			avatarUrl,
		};
	}
};

export const getIdFromPrincipal = (principal: UserPickerPrincipalOption): string => {
	if (!principal.type) {
		throw new Error('"type" is undefined in UserPicker component');
	}
	if (principal.type === 'group' && !principal.extra?.id) {
		throw new Error(
			'"extra.id" is unexpectedly undefined in UserPicker component when type is group.',
		);
	}
	if (principal.type !== 'group' && !principal.extra?.accountId) {
		throw new Error(
			'"extra.accountId" is unexpectedly undefined in UserPicker component when type is not group.',
		);
	}

	// When type is user, UserPickerPrincipalOption["id"] has a prefix and the format is "user-<userId>"
	// So extra.accountId is more reliable here
	return principal.type === 'group' ? principal.extra.id : principal.extra.accountId;
};
