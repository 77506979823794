// stateful version of DynamicTable from @atlaskit
import React, { type ReactElement } from 'react';
import type { ForgeDoc, Render, RenderFn } from '@atlassian/forge-ui-types';
import PlatformDynamicTable from '@atlaskit/dynamic-table';
import type { RowType, StatefulProps, HeadType } from '@atlaskit/dynamic-table/types';
import { elementToForgeDoc } from '../../../utils/elementToForgeDoc';
import { DynamicTable as CodeGenDynamicTable } from './__generated__/index.partial';

type NewRowType = Pick<RowType, 'cells' | 'key' | 'isHighlighted'>;

export type DynamicTableProps = Pick<
	StatefulProps,
	| 'defaultPage'
	| 'defaultSortKey'
	| 'defaultSortOrder'
	| 'emptyView'
	| 'head'
	| 'highlightedRowIndex'
	| 'isFixedSize'
	| 'isLoading'
	| 'isRankable'
	| 'label'
	| 'loadingSpinnerSize'
	| 'onRankEnd'
	| 'onRankStart'
	| 'onSetPage'
	| 'page'
	| 'paginationi18n'
	| 'rowsPerPage'
	| 'sortKey'
	| 'sortOrder'
	| 'testId'
> & {
	rows?: NewRowType[];
	caption?: string;
};

/**
 * Non working props excluded:
 * isRankingDisabled
 * onPageRowsUpdate
 * onSort
 */

const renderCellForgeDoc = (cells: ForgeDoc[], render: Render) =>
	cells
		.filter((cell) => cell.type === 'Cell')
		.map((cell) => {
			const { children, props: cellProps } = cell;
			const { cellKey, ...restProps } = cellProps || {};

			return {
				...restProps,
				key: cellKey,
				content: children.map(render).flat(),
			};
		});

export const DynamicTable = (props: Parameters<RenderFn>[0]) => {
	if (props.forgeDoc.children.length === 0) {
		return <CodeGenDynamicTable {...props} />;
	}

	const {
		caption,
		defaultPage,
		defaultSortKey,
		defaultSortOrder,
		emptyView,
		highlightedRowIndex,
		isFixedSize,
		isLoading,
		isRankable,
		label,
		loadingSpinnerSize,
		onRankEnd,
		onRankStart,
		onSetPage,
		page,
		paginationi18n,
		rowsPerPage,
		sortKey,
		sortOrder,
		testId,
	} = props.forgeDoc.props as DynamicTableProps;

	const headForgeDoc = props.forgeDoc.children.find(
		(child) => child.type === 'ContentWrapper' && child.props?.name === 'head',
	);
	const head: HeadType | undefined = headForgeDoc
		? {
				cells: renderCellForgeDoc(headForgeDoc?.children, props.render),
			}
		: undefined;

	const rowsForgeDoc = props.forgeDoc.children.find(
		(child) => child.type === 'ContentWrapper' && child.props?.name === 'rows',
	);
	const rows: NewRowType[] | undefined = rowsForgeDoc?.children
		.filter((row) => row.type === 'Row')
		.map((row) => {
			const { children, props: rowProps } = row;
			const { rowKey, ...restProps } = rowProps || {};

			return {
				...restProps,
				key: rowKey,
				cells: renderCellForgeDoc(children, props.render),
			};
		});

	return (
		<PlatformDynamicTable
			caption={caption}
			defaultPage={defaultPage}
			defaultSortKey={defaultSortKey}
			defaultSortOrder={defaultSortOrder}
			emptyView={
				emptyView ? (props.render(elementToForgeDoc(emptyView)) as ReactElement) : undefined
			}
			head={head}
			highlightedRowIndex={highlightedRowIndex}
			isFixedSize={isFixedSize}
			isLoading={isLoading}
			isRankable={isRankable}
			label={label}
			loadingSpinnerSize={loadingSpinnerSize}
			onRankEnd={onRankEnd}
			onRankStart={onRankStart}
			onSetPage={onSetPage}
			page={page}
			paginationi18n={paginationi18n}
			rowsPerPage={rowsPerPage}
			rows={rows}
			sortKey={sortKey}
			sortOrder={sortOrder}
			testId={testId}
		/>
	);
};
