import { pluginFactory } from '@atlaskit/editor-common/utils';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';

import { rovoAgentsPluginKey } from './plugin-key';
import { reducer } from './reducer';
import type { RovoAgentsPluginState } from './states';

export const { createPluginState, createCommand } = pluginFactory(rovoAgentsPluginKey, reducer);

export const getRovoAgentsPluginState = (state: EditorState): RovoAgentsPluginState | undefined => {
	const pluginState = rovoAgentsPluginKey.getState(state);
	return pluginState;
};
