// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import { css } from '@emotion/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const buttonAutoFocusRingFix = css({
	/**
	 * TODO AIFOLLOWUP
	 * This is a workaround for programmatic focus from autoFocus not using
	 * :focus-visible styling in the modal. (See: ED-18908).
	 * Styles taken from packages/design-system/focus-ring/src/focus-ring.tsx
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus:not(:focus-visible)': {
		outline: `2px solid ${token('color.border.focused', '#2684FF')}`,
		outlineOffset: token('space.025', '2px'),
	},
});
