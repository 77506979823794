import { type DocNode } from '@atlaskit/adf-schema';

import { isApiError } from '../../common/utils';

import { type AgentAction } from './agent-actions/types';
import {
	type MessageAction,
	type MessageActionPayload,
	type MessageActionResponse,
} from './message-actions/types';
import { type PluginInvocation, type PluginInvocationMessage } from './plugins';

export const CONVERSATION_CHANNEL_NOT_FOUND_ERROR = 'CONVERSATION_CHANNEL_NOT_FOUND' as const;

export const ACCEPTABLE_USE_VIOLATIONS_ERROR = 'ACCEPTABLE_USE_VIOLATIONS' as const;

export type HumanMessage = {
	role: 'HUMAN';
	content: string | DocNode;
	id: string;
	time_created: string;
	user_ari: string;
	plugin_invocations: PluginInvocationMessage[];
	isFromSeededConversation?: boolean;
	requestId?: string;
};

export type AssistantMessage = Message & {
	role: 'ASSISTANT';
};

// ************************************************************************************************
// Response data & metadata
// Sent by POST:/chat endpoint
// ************************************************************************************************

export type ModelUsage = {
	total_tokens: number;
	prompt_tokens: number;
	completion_tokens: number;
	request_count: number;
	total_cost: number;
	duration: number;
};

export type Usage = {
	model_usage: {
		[key: string]: ModelUsage;
	};
	total: ModelUsage;
};

export type Durations = {
	events: {
		[key: string]: number;
	};
	total: number;
};

export type DocSegment = {
	content: string;
	token_count: number;
	length: number;
	parent_ari: string;
	similarity_score: number;
};

export type DocSegments = Array<DocSegment>;

export type AgentResponse = {
	message: AssistantMessage;
	human_message?: HumanMessage;
	usage_data?: Usage;
	durations_data?: Durations;
};

// ************************************************************************************************
//  Streaming Messages
// Sent by POST:/chat/stream endpoint
// ************************************************************************************************

export type StreamResponse = {
	type: 'FINAL_RESPONSE';
	message: AgentResponse;
	millisOffset?: number;
	metadata?: {
		request_id?: string;
	};
	streamingComplete?: boolean;
};

export type StreamError = {
	type: 'ERROR';
	message: {
		content: string;
		status_code?: number;
		message_template?: string;
	};
	millisOffset: number;
	metadata: null | {
		error_message?: string;
		request_id?: string;
		timeout?: number;
	};
};

export type StreamAnswerPart = {
	type: 'ANSWER_PART';
	message: { content: string; role: 'ASSISTANT' };
	millisOffset: number;
	metadata?: {
		run_id?: string;
		request_id?: string;
	};
};

export type StreamTrace = {
	type: 'TRACE';
	message: { content: string };
	millisOffset: number;
	metadata?: {
		run_id: string;
		request_id: string;
		plugin_name?: string;
		plugin_input?: string;
	};
};

export type StreamPluginInvocation = {
	type: 'PLUGIN_INVOCATION';
	message: PluginInvocation;
	millisOffset: number;
	metadata?: { run_id: string; request_id: string };
};

type FollowUpObject = {
	type: 'SYNTHETIC' | 'LLM_GENERATED';
	text: string;
};

export type StreamFollowUp = {
	type: 'FOLLOW_UP_QUERIES';
	message: {
		follow_ups: FollowUpObject[] | null;
	};
	millisOffset: number;
	metadata?: { request_id: string };
};

export type StreamMessage =
	| StreamTrace
	| StreamPluginInvocation
	| StreamAnswerPart
	| StreamResponse
	| StreamError
	| StreamFollowUp
	| ConversationChannelDataMessage;

// ************************************************************************************************
// Other types
// ************************************************************************************************

export type Features = {
	account_id: string;
	cloud_id: string;
	env: string;
	env_type: string;
	model: string;
	ai_enabled_on_site: boolean;
	assistance_feature_enabled: boolean;
	debug_enabled: boolean;
};

// All responses should be converted into StreamResponse
// - AssistantMessage -> StreamResponse
// - AgentResponse -> StreamResponse
export type RawChatMessage = HumanMessage | AssistantMessage;
export type RawChat = RawChatMessage[];

export type ChatMessage = HumanMessage | StreamResponse;
export type Chat = ChatMessage[];

export type AssistanceServiceProduct =
	| 'confluence'
	| 'jira-software'
	| 'jira-core'
	| 'jsm'
	| 'jpd'
	| 'atlas'
	| 'jira'
	| 'unknown'
	| 'rovo'
	| 'bitbucket'
	| ''
	| string;

export type AssistanceServiceConfig = {
	baseUrl?: string | undefined;
	headers?: Record<string, string>;
	product: AssistanceServiceProduct;
	credentials?: RequestInit['credentials'];
	experienceId: string;
};

export type ExternalFile = {
	media_file_id: string;
	file_name: string;
	mime_type: string;
};

export type PostAgentPayload = Pick<
	Agent,
	| 'name'
	| 'description'
	| 'named_id'
	| 'system_prompt_template'
	| 'available_plugins'
	| 'visibility'
	| 'external_files'
	| 'user_defined_conversation_starters'
	| 'knowledge_sources'
	| 'action_config'
	| 'creator'
>;

export type ConversationChannelStatus = 'OPEN' | 'ENDED' | 'EXPIRED' | 'ESCALATED';

export type ConversationChannel = {
	id: string;
	experience_id: string;
	owner: string;
	time_created: string;
	name?: string;
	last_message_timestamp?: string;
	messages?: Message[];
	status: ConversationChannelStatus;
};

export interface Dialogues {
	human_message: {
		content: string;
	};
	agent_message: {
		content: string;
	};
}

export type SendMessageEditorContext =
	| {
			document: {
				type: 'text/markdown' | 'text/adf';
				content: string;
			};
			selection: {
				type: 'text/markdown' | 'text/plain';
				content: string;
			};
	  }
	| undefined;

export type SendMessageBrowserContext = {
	context:
		| {
				browserUrl: string;
				htmlBody?: string;
				canvasText?: string;
		  }
		| undefined;
};

type PermissionId =
	| 'AGENT_CREATE'
	| 'AGENT_READ'
	| 'AGENT_UPDATE'
	| 'AGENT_DEACTIVATE'
	| 'AGENT_DELETE';

export type GetAgentPermissionsPayload = {
	permission_ids: PermissionId[];
};

export type AgentPermissionResponse = {
	permissions: Partial<
		Record<
			PermissionId,
			{
				permitted: boolean;
			}
		>
	>;
};

/**
 * Interface for the Assistance Service.
 */
export interface AssistanceService {
	sendMessageStream: (payload: {
		conversationId: string;
		message: string | DocNode;
		/**
		 * This can be agentId or agentNamedId, and BE will resolve it correctly
		 * https://atlassian.slack.com/archives/C0677M19GU9/p1725952045906819?thread_ts=1725929736.588339&cid=C0677M19GU9
		 */
		agentNamedId: string;
		agentId?: string;
		controller?: AbortController;
		storeMessage?: boolean;
		/** Whether to keep the source indexes in the message e.g [^1^], [^2^] */
		citationsEnabled?: boolean;
		editorContext?: SendMessageEditorContext;
		browserContext?: SendMessageBrowserContext;
		additionalContext?: Record<string, unknown>;
	}) => Promise<FetchStreamReturn<StreamMessage>>;
	getConfig: () => AssistanceServiceConfig;
	getChat: (conversationId: string) => Promise<Chat>;
	deleteChat: (conversationId: string) => Promise<void>;
	getFeatures: () => Promise<Features>;
	getAgents: () => Promise<Agent[]>;
	getAgentDetails: (id: string) => Promise<AgentDetails>;
	getAgentDetailsByIdentityAccountId: (identityAccountId: string) => Promise<AgentDetails>;
	createAgent: (payload: PostAgentPayload) => Promise<Agent>;
	updateAgent: (id: string, payload: PostAgentPayload) => Promise<Agent>;
	deleteAgent: (id: string) => Promise<void>;
	favouriteAgent: (id: string) => Promise<void>;
	unfavouriteAgent: (id: string) => Promise<void>;
	getConversationChannels: () => Promise<ConversationChannel[]>;
	createConversationChannel: (options?: {
		name?: string;
		dialogues?: Dialogues[];
	}) => Promise<ConversationChannel & Required<Pick<ConversationChannel, 'name'>>>;
	updateConversationChannel: (
		id: string,
		payload: Partial<ConversationChannel>,
	) => Promise<ConversationChannel>;
	getAgentFileUploadToken: () => Promise<AgentFileUploadToken>;
	speechToText(blob: Blob): Promise<string>;
	resolveConversationAction: (
		conversationId: string,
		payload: MessageActionPayload,
	) => Promise<MessageActionResponse>;
	generateConversationStarters: (payload: {
		name?: string;
		description?: string;
		instructions?: string;
	}) => Promise<GeneratedConversationStarters>;
	generateContextualConversationStarters(payload: {
		recipient_agent_named_id: string;
		context: {
			browser_url: string;
		};
	}): Promise<FetchStreamReturn<StreamConversationStarter>>;
	getAgentActions: () => Promise<AgentAction[]>;
	getAgentKnowledgeConfiguration: () => Promise<AgentKnowledgeConfigurationResponse>;
	getAgentPermissions: (
		agentId: string,
		payload: GetAgentPermissionsPayload,
	) => Promise<AgentPermissionResponse>;
	getRecommendedAgents: (payload: { limit: number }) => Promise<Agent[]>;
	getConversationDebugLogs: (conversationId: string) => Promise<Chat>;
}

// ************************************************************************************************
// Type Guards
// ************************************************************************************************

export const isChatMessage = (message: any): message is ChatMessage => {
	return isHumanMessage(message) || isAssistantMessage(message) || isStreamResponse(message);
};

export const isHumanMessage = (message: any): message is HumanMessage =>
	message instanceof Object && message.role === 'HUMAN';

export const isAssistantMessage = (message: any): message is AssistantMessage =>
	message instanceof Object && message.role === 'ASSISTANT';

export const isAgentResponse = (message: any): message is AgentResponse =>
	message instanceof Object && isAssistantMessage(message.message);

export const isStreamResponse = (message: any): message is StreamResponse =>
	message instanceof Object && message.type === 'FINAL_RESPONSE';

export const isStreamError = (message: any): message is StreamError =>
	message instanceof Object && message.type === 'ERROR';

export const isStreamAUPError = (message: any): message is StreamError =>
	isStreamError(message) && message.message?.message_template === ACCEPTABLE_USE_VIOLATIONS_ERROR;

export const isStreamAnswerPart = (message: any): message is StreamAnswerPart =>
	message instanceof Object && message.type === 'ANSWER_PART';

export const isStreamTrace = (message: any): message is StreamTrace =>
	message instanceof Object && message.type === 'TRACE';

export const isStreamPluginInvocation = (message: any): message is StreamPluginInvocation =>
	message instanceof Object && message.type === 'PLUGIN_INVOCATION';

export const isPluginInvocationMessage = (message: any): message is PluginInvocationMessage =>
	message instanceof Object && message.role === 'PLUGIN_INVOCATION';

export const isFollowUpMessage = (message: unknown): message is StreamFollowUp =>
	message !== undefined &&
	message !== null &&
	typeof message === 'object' &&
	'type' in message &&
	message.type === 'FOLLOW_UP_QUERIES';

export const isChannelNotFoundError = (error: unknown): boolean =>
	isApiError(error) && error.errorAttributes?.errorCode === CONVERSATION_CHANNEL_NOT_FOUND_ERROR;

export const isRateLimitedError = (error: unknown): boolean =>
	isApiError(error) && error.errorAttributes.statusCode === 429;

export const isConversationChannelDataMessage = (
	message: any,
): message is ConversationChannelDataMessage =>
	message instanceof Object && message.type === 'CONVERSATION_CHANNEL_DATA';

export type Source = {
	ari: string;
	title: string;
	type: string;
	url: string;
	lastModified: string;
	message_id: number;
	id: number;
};

export type Sources = Array<Source>;

export type Metadata = {
	// Unified Help Only
	bm25Variant?: string;
	confidenceScore?: number;
	isFallbackMessage?: boolean;
	originalQuery?: string;
	semanticSearchLocationVariant?: string;
	semanticSearchVariant?: string;
};

export type Appendix = {
	type: 'requestForm' | 'helpDesk';
	content: string;
	appendix_sources?: Sources;
};

export type Appendices = Array<Appendix>;

export type Message = {
	role: string;
	content: string | DocNode;
	id: string;
	time_created: string;
	user_ari: string;
	plugin_invocations: PluginInvocationMessage[];
	sources?: Sources;
	message_metadata?: Metadata;
	author?: {
		actor_type: string;
		ari: string | null;
		id: string;
		name: string;
		named_id: string;
	};
	author_id?: string;
	conversation_channel_id?: string;
	appendices?: Appendices;
	actions?: MessageAction[];
};

export type AgentPlugins = {
	'Activity-Plugin': object;
	'Arithmetic-Plugin': object;
	'Content-Read-Plugin': object;
	'Jira-Issue-Create-Plugin': object;
	'Jira-JQL-Plugin': object;
	'Jira-Software-Plugin': object;
	'Next-Best-Task-Plugin': object;
	'Open-Toolchain-Plugin': object;
	'Profile-Plugin': object;
	'Search-QA-Plugin': {
		space_filter?: string[];
	};
	'Unified-Help-Plugin': object;
};

/* TODO: This will evolve as we enrich support for plugins, for the moment
we simply expect to make a direct replacement for `config.space_filters`
with `config.available_plugins['Your-Plugin'].space_filters`. Right now we only support
configuring one plugin, which is the Search-QA-Plugin, which can take the
space_filters configuration, per what our AgentForm expects

Related BE PR: https://bitbucket.org/atlassian/assistance-service/pull-requests/461/aim-367-allow-agents-to-use-plugin-config
*/
export type AgentAvailablePlugins = {
	[k in keyof AgentPlugins]?: AgentPlugins[k];
};

type AgentKnowledgeSourceBase<T extends string, U = undefined> = {
	enabled: boolean;
	source: T;
	configuration: U;
};

type ConfluenceKnowledgeSource = AgentKnowledgeSourceBase<
	'confluence',
	{
		space_filter: string[];
		parent_filter: string[];
	}
>;

type JiraKnowledgeSource = AgentKnowledgeSourceBase<
	'jira',
	{
		project_filter: string[];
	}
>;

type AtlasKnowledgeSource = AgentKnowledgeSourceBase<'atlas'>;
type GoogleDriveKnowledgeSource = AgentKnowledgeSourceBase<'google_drive'>;
type MicrosoftSharepointKnowledgeSource = AgentKnowledgeSourceBase<'microsoft_sharepoint'>;
type SlackKnowledgeSource = AgentKnowledgeSourceBase<'slack'>;
type LoomKnowledgeSource = AgentKnowledgeSourceBase<'loom'>;
type OtherKnowledgeSource = AgentKnowledgeSourceBase<string>;

export type AgentKnowledgeSource =
	| ConfluenceKnowledgeSource
	| JiraKnowledgeSource
	| AtlasKnowledgeSource
	| GoogleDriveKnowledgeSource
	| MicrosoftSharepointKnowledgeSource
	| SlackKnowledgeSource
	| LoomKnowledgeSource
	| OtherKnowledgeSource;

export type AgentKnowledgeConfiguration = {
	// Known sources
	source:
		| 'confluence'
		| 'jira'
		| 'atlas'
		| 'microsoft_sharepoint'
		| 'google_drive'
		| 'slack'
		| 'loom'
		| string;
};

export type MappedAgentKnowledgeConfiguration = AgentKnowledgeConfiguration & {
	checkboxFieldName: string;
	actionSubjectId: string;
	checkboxLabel: string;
};

export type AgentKnowledgeConfigurationResponse = {
	knowledgeSources: AgentKnowledgeConfiguration[];
};

export type Agent = {
	id: string;
	identity_account_id?: string | null;
	named_id: string;
	name: string;
	description: string | null;
	system_prompt_template?: string | null;
	creator_type: 'SYSTEM' | 'CUSTOMER' | 'THIRD_PARTY' | 'FORGE' | 'OOTB';
	creator?: string | null;
	available_plugins: AgentAvailablePlugins;
	visibility?: 'PUBLIC' | 'PRIVATE' | null;
	is_default: boolean;
	external_files?: ExternalFile[] | null;
	actor_type: 'AGENT';
	creator_cloud_id?: string | null;
	follow_up_prompt_template?: string | null;
	plugin_routing_type?: 'DEFAULT' | 'SKIP' | null;
	user_defined_conversation_starters: string[] | null;
	favourite: boolean;
	deactivated: boolean;
	deactivatedAt?: string;
	knowledge_sources?: {
		enabled: boolean;
		sources: AgentKnowledgeSource[];
	} | null;
	action_config?: AgentAction[] | null;
	external_config_reference?: string;
	icon?: string | null;
};

export type AgentDetails = Agent & {
	favourite_count: number;
};

export type AgentFileUploadToken = {
	baseUrl: string;
	client_id: string;
	target_collection: string;
	token: string;
};

export type ConversationChannelDataMessage = {
	type: 'CONVERSATION_CHANNEL_DATA';
} & Pick<ConversationChannel, 'name' | 'time_created' | 'last_message_timestamp'>;

export type GeneratedConversationStarters = {
	agent_contextual_starters: Array<{ text: string }>;
};

export type StreamConversationStarter = {
	type: 'CONVERSATION_STARTER';
	message: {
		text: string;
	};
};

export type FetchStreamReturn<T> = {
	responseData: {
		traceId?: string;
	};
	generator: AsyncGenerator<T>;
};
