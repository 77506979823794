import gql from 'graphql-tag';

export const TopNavigationQuery = gql`
	query TopNavigationQuery {
		siteConfiguration {
			customSiteLogo
			siteLogoUrl
			siteTitle
			showSiteTitle
		}
		user(current: true) {
			id
			displayName
			photos {
				value
			}
		}
	}
`;
