import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { defineMessages, useIntl } from 'react-intl-next';

import { Flex, Stack, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSpaceRolesLozenge } from '@confluence/space-roles';
import type {
	SpacePermissionListQueryResult,
	SpacePermissionListQueryVariables,
	SpaceRolesByCriteriaQueryVariables,
	SpaceRolesByCriteriaQueryResult,
} from '@confluence/space-roles/entry-points/graphql';
import {
	SpacePermissionListQuery,
	useResponseHandler,
	SpaceRolesByCriteriaQuery,
} from '@confluence/space-roles/entry-points/graphql';
import {
	normalizeSpacePermissionAll,
	normalizeSpaceRoleList,
} from '@confluence/space-roles/entry-points/normalizers';
import { DEFAULT_RBAC_VIEW_EXPERIENCE, ExperienceStart } from '@confluence/experience-tracker';

import { DefaultRolesTable } from '../table/DefaultRolesTable';

import { AddGroupsButton } from './AddGroupsButton';
import { SectionContent } from './SectionContent';

const i18n = defineMessages({
	permissionsErrorFLag: {
		id: 'default-space-permissions.default-roles-view.permissions-load.error-flag.non-final',
		defaultMessage: 'Something went wrong while loading permissions',
		description:
			'Error message when something goes wrong during a page load whenever an admin is viewing a permission table',
	},
	rolesErrorFlag: {
		id: 'default-space-permissions.default-roles-view.roles-load.error-flag.non-final',
		defaultMessage: 'Something went wrong while loading all roles',
		description:
			'Error message when something goes wrong during a page load whenever an admin is viewing a role table',
	},
});

const defaultRolesViewStyle = xcss({
	gap: 'space.200',
});

export const DefaultRolesView = () => {
	const { formatMessage } = useIntl();
	const { hideLozenge } = useSpaceRolesLozenge('default-roles-tab');
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { handleResponse: handlePermissionsResponse } = useResponseHandler({
		errorMessage: formatMessage(i18n.permissionsErrorFLag),
	});

	const { handleResponse: handleQueryResponse } = useResponseHandler({
		errorMessage: formatMessage(i18n.rolesErrorFlag),
	});

	const { data: permissionsData, loading: isPermissionsDataLoading } = useQuery<
		SpacePermissionListQueryResult,
		SpacePermissionListQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpacePermissionListQuery,
		{
			onError: (error) => {
				handlePermissionsResponse({ error });
			},
		},
	);

	const { data: spaceRoleOptions } = useQuery<
		SpaceRolesByCriteriaQueryResult,
		SpaceRolesByCriteriaQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceRolesByCriteriaQuery,
		{
			// spaceId = null indicates fetching all default roles
			variables: { spaceId: null, principal: null },
			fetchPolicy: 'cache-first',
			onError(error) {
				handleQueryResponse({ error });
			},
		},
	);

	const allPermissions = normalizeSpacePermissionAll(
		permissionsData?.spacePermissionsAll?.nodes || [],
	);

	const roleOptions = normalizeSpaceRoleList(spaceRoleOptions?.spaceRolesByCriteria?.nodes || []);

	useEffect(
		() => {
			hideLozenge();
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'defaultRolesView',
				},
			}).fire();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<Stack xcss={defaultRolesViewStyle}>
			<Flex justifyContent="space-between">
				<SectionContent />
				<AddGroupsButton roleOptions={roleOptions} />
			</Flex>
			<ExperienceStart name={DEFAULT_RBAC_VIEW_EXPERIENCE} />
			<DefaultRolesTable
				allPermissions={allPermissions}
				roleOptions={roleOptions}
				isRolesOrPermissionsLoading={isPermissionsDataLoading}
			/>
		</Stack>
	);
};
