import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import type { StatelessProps } from '@atlaskit/dynamic-table/types';
import { token } from '@atlaskit/tokens';

import type { SpacePermissionsTabKey } from '../tabs/spacePermissionKeys';
import type { SpacePermissionType } from '../graphql/__types__/SpacePermissionsUsersQuery';
import type { PermissionDisplayType } from '../graphql/__types__/SpacePermissionsPrincipalsMutation';
import { PaginationArrowsWrapper } from '../pagination/PaginationArrowsWrapper';

import { usePermissionsTableHeaders } from './usePermissionsTableHeaders';
import { usePermissionsTableRows } from './usePermissionsTableRows';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableWrapper = styled.div({
	overflowX: 'auto',
	marginTop: token('space.250', '20px'),
});

export type PrincipalCellProps = {
	name: string;
	subtext?: string;
	avatarSrc?: string;
	lozenge?: React.ReactNode;
	hasProfileBorder?: boolean;
};

export type TableDataType = PrincipalCellProps & {
	id: string;
	permissions: (SpacePermissionType | null)[];
	permissionDisplayType: PermissionDisplayType;
};

interface PermissionsTableProps extends StatelessProps {
	tab: SpacePermissionsTabKey;
	data: TableDataType[];
}

export const PermissionsTable: FC<PermissionsTableProps> = ({ tab, data, ...props }) => {
	const headers = usePermissionsTableHeaders(tab);
	const rows = usePermissionsTableRows({ data });

	return (
		<TableWrapper>
			<PaginationArrowsWrapper>
				<DynamicTable head={headers} rows={rows} testId="space-permissions-table" {...props} />
			</PaginationArrowsWrapper>
		</TableWrapper>
	);
};
