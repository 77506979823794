import { preloadContent } from '@confluence/content-body/entry-points/preloadContent';
import { preloadContentPrerequisites } from '@confluence/content-prerequisites/entry-points/preloadContentPrerequisites';
import { preloadCustomHeaderAndFooter } from '@confluence/custom-header-footer/entry-points/preloadCustomHeaderAndFooter';
import { preloadPageTitleContentProperties } from '@confluence/content-topper/entry-points/preloadPageTitleContentProperties';
import { preloadMacrosSSR } from '@confluence/fabric-extension-handlers/entry-points/preloadMacrosSSR';
import { preloadInlineComments } from '@confluence/inline-comments-queries/entry-points/preloadInlineComments';
import { preloadSpace } from '@confluence/load-edit-page/entry-points/preloadSpace';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';
import { preloadBlogTree } from '@confluence/blog-tree/entry-points/preloadBlogTree';
import {
	getPreloaderFnContext,
	prepareGuardExceptionTask,
	query,
} from '@confluence/query-preloader-tools';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { preloadScreen } from '@confluence/screen/entry-points/preloadScreen';
import { preloadSideNavigationNeedsContentId } from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import type {
	SpaceOverviewQueryType as Q,
	SpaceOverviewQueryVariables as V,
} from '@confluence/space-overview-query';
import { SpaceOverviewQuery } from '@confluence/space-overview-query';

import { preloadGroupedButtons } from './preloadGroupedButtons';

export const preloadSpaceOverviewQuery = (spaceKey: string) =>
	query<Q, V>({
		query: SpaceOverviewQuery,
		variables: {
			spaceKey,
			excludeOperations: Boolean(!isCompanyHubSpaceKey(spaceKey)),
		},
	});

type PreloadSpaceOverviewQueriesParameters = {
	spaceKey: string;
	homepageId?: string;
} & Pick<Parameters<typeof preloadContent>[0], 'versionOverride'>;

const preloadSpaceOverviewQueries = async ({
	homepageId = '',
	spaceKey,
	versionOverride,
}: PreloadSpaceOverviewQueriesParameters): Promise<any> => {
	const { userId, featureFlags, isLicensed } = await getPreloaderFnContext();

	const tasks: Promise<any>[] = [];

	if (homepageId) {
		// If we know the homepageId, preload early
		tasks.push(preloadSideNavigationNeedsContentId(spaceKey, homepageId));
	}

	tasks.push(
		prepareGuardExceptionTask('ContentAndMacros', () =>
			preloadContent({
				contentId: homepageId,
				spaceKey,
				isBlog: false,
				featureFlags,
				versionOverride,
			}).then(({ result, hasErrors }): Promise<any> | void => {
				const contentId = homepageId || result?.data?.content?.nodes[0]?.id;
				// in the event that there are errors (no id from result) and the homepageId is not valid (error when loading the space),
				// bypass preloadContentPrereqs since contentId is undefined
				if (!contentId) {
					return;
				}

				const promises: Promise<any>[] = [];
				if (!homepageId) {
					// If we don't know the homepageId, preloadContent will tell us the id
					promises.push(preloadSideNavigationNeedsContentId(spaceKey, contentId));
				}
				if (hasErrors) {
					promises.push(preloadContentPrerequisites(contentId, spaceKey, { versionOverride }));
				} else if (process.env.REACT_SSR) {
					promises.push(
						preloadMacrosSSR({
							contentId,
							useMultipleMacrosQuery: false,
							contentNodes: result?.data?.content?.nodes,
						}),
					);
				}
				return Promise.all(promises);
			}),
		),
		preloadGroupedButtons({
			contentId: homepageId,
			spaceKey,
			userId,
			isLicensed,
		}),
	);

	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	if (!isCompanyHub) {
		tasks.push(
			preloadInlineComments({
				pageId: homepageId,
				spaceKey,
			}),
		);
	}

	tasks.push(preloadPageTitleContentProperties({ contentId: homepageId, spaceKey }));

	if (process.env.REACT_SSR && !isCompanyHub) {
		tasks.push(
			preloadPageTree({
				contentId: homepageId,
				spaceKey,
				statuses: PAGE_TREE_STATUSES,
				isSuperAdmin: false,
			}),
			preloadBlogTree({ spaceKey }),
		);
	}
	return Promise.all(tasks);
};

type PreloadSpaceOverviewParameters = {
	spaceKey: string;
	homepageId: string | undefined;
} & Pick<PreloadSpaceOverviewQueriesParameters, 'versionOverride'>;

export async function preloadSpaceOverview({
	spaceKey,
	homepageId,
	versionOverride,
}: PreloadSpaceOverviewParameters): Promise<any> {
	const promises = [
		preloadScreen(spaceKey) as Promise<any>,
		preloadSpace(spaceKey) as Promise<any>,
		preloadCustomHeaderAndFooter(spaceKey),
	];

	if (homepageId) {
		// In some case we append homepageId as a query param. Then preload with it.
		// next/packages/space-overview/src/SpaceOverview.tsx still needs SpaceOverviewQuery but others are not depending on it
		promises.push(
			preloadSpaceOverviewQuery(spaceKey),
			preloadSpaceOverviewQueries({ homepageId, spaceKey, versionOverride }),
		);
	} else if (Boolean(process.env.REACT_SSR)) {
		// Use new query to preload as space key then transform to the old queries.
		// next/packages/space-overview/src/SpaceOverview.tsx still needs SpaceOverviewQuery but others are not depending on it
		promises.push(preloadSpaceOverviewQuery(spaceKey), preloadSpaceOverviewQueries({ spaceKey }));
	} else {
		// If we have no information on home id and yet using old query, we need to fetch it first.
		promises.push(
			preloadSpaceOverviewQuery(spaceKey).then((res) => {
				// Because homepageId was provided by the function caller, it may or may
				// not be out of sync with backend:
				const actualHomepageId = res?.data?.space?.homepage?.id;
				if (actualHomepageId) {
					return preloadSpaceOverviewQueries({
						homepageId: actualHomepageId,
						spaceKey,
						versionOverride,
					});
				}
			}),
		);
	}

	return Promise.all(promises);
}
