import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.convertToTable.title',
		defaultMessage: 'Convert to table',
		description:
			'Describes an option to use Atlassian Intelligence to reformat content currently selected by the user to a table with headings',
	},
	description: {
		id: 'fabric.editor.ai.config.item.convertToTable.description',
		defaultMessage: 'Reformats content to a table with headings',
		description: 'Description for the the Atlassian Intelligence "Convert to table".',
	},
});
