import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	tellAILabel: {
		id: 'fabric.editor.ai.ui.components.suggestions.tellAILabel',
		defaultMessage: 'Tell AI',
		description: 'Label for default suggestion option',
	},
	tellAgentLabel: {
		id: 'fabric.editor.ai.ui.components.suggestions.tellAgentLabel',
		defaultMessage: 'Tell Agent',
		description: 'Label for agent suggestion option',
	},
	suggestionStatusBeta: {
		id: 'fabric.editor.ai.ui.components.suggestions.betaLozenge',
		defaultMessage: 'BETA',
		description: 'Lozenge text to indicate that the feature is in beta stage',
	},
	suggestionStatusNew: {
		id: 'fabric.editor.ai.ui.components.suggestions.newLozenge',
		defaultMessage: 'NEW',
		description: 'Lozenge text to indicate that the feature is in new',
	},
});
