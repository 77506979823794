/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import type { SerializedStyles } from '@emotion/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, keyframes } from '@emotion/react';

import { N0, N60A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import type { AIThemeColor } from '../../styles/theme';

import type { PaddingAdjustments } from './types';

const isFirefox: boolean =
	typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const shadowStyles = css({
	boxShadow: token('elevation.shadow.overlay', `0 4px 8px -2px ${N60A}, 0 0 1px ${N60A}`),
});

const rotationAnimation = keyframes({
	'0%': {
		'--gradient-angle': '0deg',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isFirefox ? { backgroundPosition: '100%' } : {}),
	},
	'100%': {
		'--gradient-angle': '360deg',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isFirefox ? { backgroundPosition: '-100%' } : {}),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const prismBorderAnimationStyles = css({
	'&::before, &::after': {
		animation: `${rotationAnimation} linear 2s infinite`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isFirefox
			? {
					animationDirection: 'normal',
					animationDuration: '1s',
				}
			: {}),
		'@media (prefers-reduced-motion)': {
			animation: 'none',
		},
	},
});

// ED-20633, DSP-14045: Avoid space tokens for now as space tokens are currently using rem units
// and Safari has a bug where rem is incorrectly calculated when there are
// animations involved
// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/design-system/no-css-tagged-template-expression
export const prismBorderStyles = (aiThemeColor: AIThemeColor) => css`
	// This style is used to define gradient angle around the border,
	// used widely across every modals with a prism border
	@property --gradient-angle {
		syntax: '<angle>';
		initial-value: 270deg;
		inherits: false;
	}

	position: relative;
	border-radius: ${token('border.radius.200', '6px')};
	box-shadow: none;

	&::before,
	&::after {
		content: '';
		position: absolute;
		${isFirefox
			? `background: linear-gradient(90deg,
          ${aiThemeColor('prism.border.step.1')} 0%,
          ${aiThemeColor('prism.border.step.2')} 12%,
          ${aiThemeColor('prism.border.step.3')} 24%,
          ${aiThemeColor('prism.border.step.4')} 48%,
          ${aiThemeColor('prism.border.step.3')} 64%,
          ${aiThemeColor('prism.border.step.2')} 80%,
          ${aiThemeColor('prism.border.step.1')} 100%
        );
        background-size: 200%;`
			: `
      background: conic-gradient(
      from var(--gradient-angle, 270deg),
      ${aiThemeColor('prism.border.step.1')} 0%,
      ${aiThemeColor('prism.border.step.2')} 20%,
      ${aiThemeColor('prism.border.step.3')} 50%,
      ${aiThemeColor('prism.border.step.4')} 56%,
      ${aiThemeColor('prism.border.step.1')} 100%
    );`}
		z-index: -1;
		width: calc(100% + 2px);
		height: calc(100% + 2px);
		top: -1px;
		left: -1px;
		border-radius: calc(${token('border.radius.200', '6px')} + 1px);

		// Avoid css filter issues in Safari
		transform: translate3d(0, 0, 0);
	}

	&::after {
		filter: blur(8px);
		opacity: 0.5;
	}
`;

const borderStyles = css({
	borderRadius: token('border.radius.200', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> :first-child': {
		borderTopLeftRadius: 'inherit',
		borderTopRightRadius: 'inherit',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> :last-child': {
		borderBottomLeftRadius: 'inherit',
		borderBottomRightRadius: 'inherit',
	},
});

const dimensionStyles = css({
	height: '100%',
	width: '100%',
	boxSizing: 'border-box',
});

const backgroundStyles = css({
	backgroundColor: token('elevation.surface.overlay', N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const floatingContainerStyles = [
	shadowStyles,
	borderStyles,
	dimensionStyles,
	backgroundStyles,
];

const defaultPadding = {
	top: 12,
	bottom: 12,
	left: 12,
	right: 12,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const defaultPaddingStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${defaultPadding.top}px ${defaultPadding.left}px`,
});

export const getPaddingAdjustments = (
	adjustments?: PaddingAdjustments,
): null | SerializedStyles => {
	if (!adjustments) {
		return null;
	}
	const { top, right, bottom, left } = adjustments;
	return css({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		paddingTop: `${defaultPadding.top + (top ?? 0)}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		paddingRight: `${defaultPadding.right + (right ?? 0)}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		paddingBottom: `${defaultPadding.bottom + (bottom ?? 0)}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		paddingLeft: `${defaultPadding.left + (left ?? 0)}px`,
	});
};

export const scrollTopMargin = 32;
/**
 * Scroll margin is used to ensure that the modal won't be
 * too close to the top of the editor, which may sometimes
 * cover the modal slightly.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const scrollMarginStyles = css({
	scrollMarginTop: token('space.400', `${scrollTopMargin}px`),
});
