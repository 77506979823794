import React from 'react';

import type { ExtensionHandlers, ExtensionParams } from '@atlaskit/editor-common/extensions';

import { fg } from '@confluence/feature-gating';

import { linkCardsExtensionType } from '../link-cards/linkCardsExtensionType';
import { LinkCardsLoader } from '../link-cards/view/LinkCardsLoader';
import type { LinkCardsParameters } from '../link-cards/linkCardsTypes';
import { carouselExtensionType } from '../carousel/carouselConstants';
import { CarouselLoader } from '../carousel/view/CarouselLoader';

type getRendererCustomSitesExtensionHandlersProps = {
	contentId: string | undefined;
};

export const getRendererCustomSitesExtensionHandlers = ({
	contentId,
}: getRendererCustomSitesExtensionHandlersProps): ExtensionHandlers => {
	const extensionHandlers: ExtensionHandlers = {
		[linkCardsExtensionType]: () => <></>,
		[carouselExtensionType]: () => <></>,
	};

	if (contentId && fg('company_hub_beta')) {
		extensionHandlers[linkCardsExtensionType] = (
			extension: ExtensionParams<LinkCardsParameters>,
		) => <LinkCardsLoader extensionNode={extension} contentId={contentId} />;
		extensionHandlers[carouselExtensionType] = (
			extension: ExtensionParams<LinkCardsParameters>,
		) => <CarouselLoader extensionNode={extension} contentId={contentId} />;
	}
	return extensionHandlers;
};
