import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.summarize.title',
		defaultMessage: 'Summarize writing',
		description:
			'Describes an option to use Atlassian Intellgience to summarize the content currently selected by the user',
	},
	description: {
		id: 'fabric.editor.ai.config.item.summarize.description',
		defaultMessage: "Creates a summary of the text you're working on, highlighting key points",
		description:
			'Describes "Summarize writing" Atlassian Intelligence feature to summarize the content currently selected by the user',
	},
	selectionToolbarDropdownItemTitle: {
		id: 'fabric.editor.ai.config.item.summarize.dropdownItem.title',
		defaultMessage: 'Summarize writing',
		description:
			'Title for Atlassian Intelligence "Summarize writing" in editor selection floating toolbar',
	},
});
