/**
 * This function checks if a given markdown string ends with an incomplete "custom" tag,
 * ensuring that the tag is not closed by either a full closing tag (</custom>) or a self-closing tag (/>).
 *
 * Examples of matches:
 * - "Something something <"
 * - "Something something <c"
 * - "Something something <cust"
 * - "Something something <custom"
 *
 * It will not match strings where the "custom" tag is completed or closed:
 * - "Something something <custom/>"
 * - "Something something </custom>"
 * - Non-custom tags like:
 *   - "<custard"
 *   - "<status"
 */
export const isMarkdownEndingWithCustomTag = (markdown: string): boolean => {
	// Ignored via go/ees005
	// eslint-disable-next-line require-unicode-regexp
	return !!markdown.match(/<(c(u(s(t(o(m(?!.*<\/custom>|.*\/>).*?)?)?)?)?)?)?$/g);
};
