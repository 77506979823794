import { Decoration } from '@atlaskit/editor-prosemirror/view';

import {
	type DecorationTransformerParams,
	filterDecorationByKey,
	ProactiveDecorations,
} from './index';

export const removeHoveredRecommendationDecoration = ({
	decorationSet,
	pluginState,
}: DecorationTransformerParams) => {
	return decorationSet.remove(
		filterDecorationByKey(ProactiveDecorations.RECOMMENDATION_HOVERED, decorationSet),
	);
};

export const addHoveredRecommendationDecoration = ({
	decorationSet,
	tr,
	pluginState,
}: DecorationTransformerParams) => {
	const { proactiveAIBlocks, hoveredRecommendationId, selectedRecommendationId } = pluginState;

	if (!hoveredRecommendationId || !proactiveAIBlocks.length) {
		return decorationSet;
	}

	/*
      We are looking for a recommendation to hover, which is not selected.
      This will allow to have one recommendation selected and another one hovered.
      If the same recommendation is selected and hovered, it should be rendered as
      selected and not hovered.
    */
	const hoveredBlock = proactiveAIBlocks.find(
		(block) =>
			!!block.recommendations?.some(
				(r) => r.id === hoveredRecommendationId && r.id !== selectedRecommendationId,
			),
	);

	if (!!hoveredBlock) {
		const { from, to } = hoveredBlock;

		return decorationSet.add(tr.doc, [
			Decoration.inline(
				from,
				to,
				{
					class: `ai-proactive-recommendation-hovered`,
				},
				{
					key: ProactiveDecorations.RECOMMENDATION_HOVERED,
				},
			),
		]);
	}

	return decorationSet;
};
