import mean from 'lodash/mean';
import uuid from 'uuid/v4';

import {
	ACTION,
	ACTION_SUBJECT,
	ACTION_SUBJECT_ID,
	EVENT_TYPE,
	type AIUnifiedEventPayload,
	type AIProactiveEventPayload,
	type EditorAnalyticsAPI,
} from '@atlaskit/editor-common/analytics';
import { withAnalytics } from '@atlaskit/editor-common/editor-analytics';
import { median } from '@atlaskit/editor-common/median';
import type { Command } from '@atlaskit/editor-common/types';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { createUnifiedAnalyticsPayload } from '@atlassian/editor-ai-common/analytics/create-unified-analytics-payload';

import { type ResponseState } from './api';
import {
	closeProactiveAISuggestionDisplay,
	disableNeedProactiveRecommendations,
	selectRecommendation,
	toggleProactiveAISuggestionDisplay,
	updateFailedChunks,
} from './commands';
import { ANALYTICS_EXPERIENCE_NAME } from './constants';
import { getPluginState } from './plugin-factory';
import { getBlockFromRecommendationId } from './utils';

const getFireAPIReceivedAnalytics = () => {
	let apiReceivedCount = 0;
	const apiReceivedSamplingRate = 10;
	let durationArray: number[] = [];
	return ({
		analyticsApi,
		view,
		duration,
		totalSuggestions,
		totalAcceptedSuggestions,
		totalDismissedSuggestions,
	}: {
		analyticsApi: EditorAnalyticsAPI | undefined;
		view: EditorView;
		duration: number;
		totalSuggestions: number;
		totalAcceptedSuggestions: number;
		totalDismissedSuggestions: number;
	}) => {
		apiReceivedCount++;
		durationArray.push(duration);
		if (apiReceivedCount >= apiReceivedSamplingRate) {
			/**
			 * min / max will not be undefined as this function samples
			 * durations after X amount of times, and this is only calculated
			 * at the end of the sampling
			 */
			// Ignored via go/ees005
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const minDuration = Math.min(...durationArray)!;
			// Ignored via go/ees005
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const maxDuration = Math.max(...durationArray)!;

			const meanDuration = mean(durationArray);
			const medianDuration = median(durationArray);

			analyticsApi?.fireAnalyticsEvent({
				eventType: EVENT_TYPE.OPERATIONAL,
				action: ACTION.API_RECEIVED,
				actionSubject: ACTION_SUBJECT.EDITOR_PLUGIN_AI,
				actionSubjectId: ACTION_SUBJECT_ID.PROACTIVE_SUGGESTION,
				attributes: {
					meanDuration,
					medianDuration,
					minDuration,
					maxDuration,
					totalSuggestions,
					totalAcceptedSuggestions,
					totalDismissedSuggestions,
				},
			});

			// reset sampling
			apiReceivedCount = 0;
			durationArray = [];
		}
	};
};

export const fireAPIReceivedAnalytics = getFireAPIReceivedAnalytics();

function getProactiveSuggestionToggledPayload({
	state,
	triggeredFrom,
}: {
	state: EditorState;
	triggeredFrom: string;
}): AIProactiveEventPayload {
	const pluginState = getPluginState(state);
	const { isProactiveContextPanelOpen, availableRecommendationIds } = pluginState;
	return {
		eventType: EVENT_TYPE.TRACK,
		action: ACTION.TOGGLED,
		actionSubject: ACTION_SUBJECT.EDITOR_PLUGIN_AI,
		actionSubjectId: ACTION_SUBJECT_ID.PROACTIVE_SUGGESTION,
		attributes: {
			toggledToValue: !isProactiveContextPanelOpen,
			triggeredFrom,
			totalSuggestions: availableRecommendationIds.size,
		},
	};
}

export const toggleProactiveAISuggestionDisplayWithAnalytics =
	(analyticsApi: EditorAnalyticsAPI | undefined) =>
	(triggeredFrom: string): Command =>
		withAnalytics(analyticsApi, (state: EditorState): AIProactiveEventPayload => {
			return getProactiveSuggestionToggledPayload({ state, triggeredFrom });
		})(toggleProactiveAISuggestionDisplay());

export const closeProactiveAISuggestionDisplayWithAnalytics =
	(analyticsApi: EditorAnalyticsAPI | undefined) =>
	(triggeredFrom: string): Command =>
		withAnalytics(analyticsApi, (state: EditorState): AIProactiveEventPayload => {
			return getProactiveSuggestionToggledPayload({ state, triggeredFrom });
		})(closeProactiveAISuggestionDisplay());

export const disableNeedProactiveRecommendationsWithAnalytics =
	(analyticsApi: EditorAnalyticsAPI | undefined) =>
	(reason: string): Command =>
		withAnalytics(analyticsApi, (state: EditorState): AIProactiveEventPayload => {
			const pluginState = getPluginState(state);
			const { proactiveAIBlocks } = pluginState;
			const totalPurgedParts = proactiveAIBlocks.reduce(
				(sum, block) => sum + Number(block.invalidated || block.invalidatedForDocChecker),
				0,
			);
			return {
				eventType: EVENT_TYPE.OPERATIONAL,
				action: ACTION.API_PURGED,
				actionSubject: ACTION_SUBJECT.EDITOR_PLUGIN_AI,
				actionSubjectId: ACTION_SUBJECT_ID.PROACTIVE_SUGGESTION,
				attributes: {
					reason,
					totalParts: proactiveAIBlocks.length,
					totalPurgedParts,
				},
			};
		})(disableNeedProactiveRecommendations());

export const updateFailedChunksWithAnalytics =
	(analyticsApi: EditorAnalyticsAPI | undefined) =>
	(
		chunkIds: string[],
		failedResponseState?: Extract<ResponseState, { state: 'failed' }>,
	): Command => {
		if (!failedResponseState) {
			return updateFailedChunks(chunkIds);
		}

		return withAnalytics(analyticsApi, (state: EditorState): AIUnifiedEventPayload => {
			const pluginState = getPluginState(state);
			const { reason, statusCode } = failedResponseState;

			failedResponseState.errors.forEach((error) => {
				analyticsApi?.fireAnalyticsEvent({
					eventType: EVENT_TYPE.OPERATIONAL,
					action: ACTION.API_ERROR,
					actionSubject: ACTION_SUBJECT.EDITOR_PLUGIN_AI,
					actionSubjectId: ACTION_SUBJECT_ID.PROACTIVE_SUGGESTION,
					attributes: {
						error,
						reason,
						statusCode,
					},
				});
			});

			return createUnifiedAnalyticsPayload(
				ACTION.ERROR,
				pluginState.analyticsAIInteractionId,
				ANALYTICS_EXPERIENCE_NAME,
				true,
				{
					aiErrorMessage: reason,
					aiErrorCode: statusCode,
				},
			);
		})(updateFailedChunks(chunkIds));
	};

export const selectRecommendationWithAnalytics =
	(analyticsApi: EditorAnalyticsAPI | undefined) =>
	(recommendationId: string, analyticsAIInteractionId: string = uuid()): Command =>
		withAnalytics(analyticsApi, (state: EditorState): AIUnifiedEventPayload | undefined => {
			const pluginState = getPluginState(state);
			const { recommendation } = getBlockFromRecommendationId(pluginState, recommendationId);
			if (recommendation) {
				return createUnifiedAnalyticsPayload(
					ACTION.INITIATED,
					analyticsAIInteractionId,
					recommendation.transformAction,
					true,
				);
			}
			return;
		})(selectRecommendation(recommendationId, analyticsAIInteractionId));
