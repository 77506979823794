import React, { useCallback, useEffect, useMemo } from 'react';

import { useIntl } from 'react-intl-next';

import { doc, p } from '@atlaskit/adf-utils/builders';
import { reduce } from '@atlaskit/adf-utils/traverse';
import { type ADFEntity } from '@atlaskit/adf-utils/types';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import AiAgentIcon from '@atlaskit/icon/core/ai-agent';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { fg } from '@atlaskit/platform-feature-flags';
import { AgentAvatar } from '@atlaskit/rovo-agent-components';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';
import type {
	FocusInputRefType,
	Suggestion,
} from '@atlassian/generative-ai-modal/screens/UserInputCommandPalette';
import { UserInputCommandPalette } from '@atlassian/generative-ai-modal/screens/UserInputCommandPalette';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import { useAnalyticsFlowEventsQueue } from '../../analytics/analytics-flow/analyticsFlowUtils';
import {
	getTranslatedItemTitle,
	getTranslatedPromptLabel,
	getVisibleEditorPluginAIConfigItems,
	getEditorPluginAIConfigItemActions,
	type EditorPluginAIConfigItem,
	type AdvancedPromptValue,
} from '../../config-items/config-items';
import { openBrowseAgentsModal } from '../../pm-plugins/rovo-agents/commands';
import { useAgents } from '../../pm-plugins/rovo-agents/useAgents';
import { createContinueInChat } from '../../prebuilt/config-item-actions/chat';
import { createAgentConfigItem } from '../../prebuilt/config-item-agents/create-agent-config-item';
import { PreloadModalComponent } from '../../ui/components/BrowseRovoAgentsModal/BrowseRovoAgentsModal';
import { useRovoEnabled } from '../../ui/components/RovoEnabled/RovoEnabled';
import {
	BROWSE_AGENTS,
	USE_GENERAL_AI_KNOWLEDGE,
	USE_ROVO_ORGANISATION_KNOWLEDGE,
} from '../../utils/constants';
import type { AIExperienceMachineContext } from '../get-ai-experience-service';
import { messages } from '../messages';
import { useAIExperienceCommonDataContext } from '../useAIExperienceCommonData';
import { useCommandPaletteStateMachine } from '../useCommandPaletteStateMachine';
import { useOutsideClickHandler } from '../useOutsideClickHandler';
import { usePageKnowledgeToggle } from '../usePageKnowledge';

import { useAgentStarters } from './utils/use-agent-starters';
import { useEngagementPlatformAnnouncementBanner } from './utils/useEngagementPlatformAnnouncementBanner';
import { usePromptBuilder } from './utils/usePromptBuilder';

export type HandleHighlightCbType = (props: {
	configItem: EditorPluginAIConfigItem;
	promptLabel?: string;
}) => void;

/**
 * This component is responsible for the logic and rendering of the
 * Command Palette component, it also handles the corresponding state machine.
 */
export function UserInputCommandPaletteWithLogic({
	context: experienceAppContext,
	initialPrompt,
}: {
	context: AIExperienceMachineContext;
	initialPrompt?: string;
}) {
	const aiExperienceCommonData = useAIExperienceCommonDataContext();
	const { appearance, editorPluginAIProvider, endExperience, sendToAIExperienceMachine, modalRef } =
		aiExperienceCommonData;

	const { formatMessage } = useIntl();
	const {
		configItem: startingConfig,
		baseGenerate,
		userInput: startingUserInput,
		userADFInput: startingUserADFInput,
		advancedPromptValue: startingAdvancedPromptValue,
		parentPresetPromptLabel,
		editCount,
		positions,
		editorView,
		isEmpty,
		latestPromptResponse,
		aiProvider,
	} = experienceAppContext;

	const [initialPromptTriggered, setInitialPromptTriggered] = React.useState(false);

	// if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	// we want to use startingConfig and parentPresetPromptLabel to restore the selected parent
	const { context: commandPaletteContext, send: sendToCommandPalette } =
		useCommandPaletteStateMachine({
			baseGenerate,
			startingConfig,
			startingPresetPromptLabel:
				parentPresetPromptLabel || getTranslatedPromptLabel(startingConfig, formatMessage),
			startingUserInput,
			startingUserADFInput,
			startingAdvancedPromptValue,
		});

	const { textInput, adfInput, advancedPromptValue, configItem, presetPromptLabel } =
		commandPaletteContext;

	// if the user selects a parent with nesting submenus (`Change tone` or `Translate`)
	const isParentWithNestedSubmenusSelected = Boolean(presetPromptLabel && configItem.nestingConfig);

	const rovoAgentsPluginState = useAgents({ editorState: editorView.state });

	const { isBrowseModalOpen = false } = rovoAgentsPluginState ?? {};
	useOutsideClickHandler({
		disabled: isBrowseModalOpen,
		modalRef,
		onCancel: () => aiExperienceCommonData.endExperience(),
		onDiscard: () => {
			sendToAIExperienceMachine({
				type: 'update context',
				context: {
					configItem: configItem,
					userInput: textInput || '',
					userADFInput: adfInput,
				},
			});
			sendToAIExperienceMachine({ type: 'enable discard' });
		},
	});

	const focusInputRef = React.useRef<FocusInputRefType>(undefined);

	const handleCancel = () => {
		endExperience();
	};

	const kickoffAiExperience = useCallback(
		({
			configItem,
			userInput,
			userADFInput,
			advancedPromptValue,
		}: {
			configItem: EditorPluginAIConfigItem;
			userInput: string;
			userADFInput?: JSONDocNode;
			advancedPromptValue?: AdvancedPromptValue;
		}) => {
			// if the user selects a parent with nesting submenus (`Change tone` or `Translate`)
			// we need the parentPresetPromptLabel to restore the selected parent later when we edit prompt in `Preview` screen (onEditPromptClick)
			const parentPresetPromptLabel = isParentWithNestedSubmenusSelected ? presetPromptLabel : '';
			sendToAIExperienceMachine({
				type: 'kickoff with command palette input',
				context: {
					parentPresetPromptLabel,
					configItem,
					userInput,
					userADFInput,
					advancedPromptValue,
				},
			});
		},
		[isParentWithNestedSubmenusSelected, presetPromptLabel, sendToAIExperienceMachine],
	);

	const onPartialPresetSelected = useCallback(
		(
			configItem: EditorPluginAIConfigItem,
			{ presetPromptLabel }: { presetPromptLabel?: string } = {},
		) => {
			// If partial prompt is selected we want to update the command palette
			// And focus the text input
			sendToCommandPalette({
				type: 'onPartialPresetSelected',
				configItem,
				presetPromptLabel: presetPromptLabel ?? getTranslatedPromptLabel(configItem, formatMessage),
			});
			focusInputRef.current?.();
		},
		[sendToCommandPalette, formatMessage],
	);

	const getPromptTitle = useCallback(
		(configItem: EditorPluginAIConfigItem) => {
			if (configItem.agent?.creatorType === 'CUSTOMER') {
				return undefined;
			}

			if (configItem.agent?.creatorType === 'SYSTEM') {
				return configItem.agent?.name;
			}

			return configItem.promptLabel ? formatMessage(configItem.promptLabel) : undefined;
		},
		[formatMessage],
	);

	const analyticsFlow = useAnalyticsFlowEventsQueue({
		stepName: 'prompt',
		attributes: {
			experienceName: configItem.key,
			promptTitle: getPromptTitle(configItem),
			// !TODO AIFOLLOWUP - Remove this
			// This attribute only made sense when we had two different entry points
			// 'custom' or 'generate'
			// This should likely change to respresent the actual prompt types:
			// 'full-prompt' = when there is no user input (e.g. 'Summarize this page')
			// 'partial-prompt' = when there is user input and a prompt (e.g. 'Brainstorm: "Titles for this page"')
			// 'generate' = there is only user input, and no preset prompt
			promptType: 'generate',
			usingCommandPalette: true,
			retryPromptCount: experienceAppContext.retryPromptCount,
			editCount,
		},
	});

	const isRovoEnabled = useRovoEnabled({ editorPluginAIProvider });

	const updateAnalytics = useCallback(
		(configItem: EditorPluginAIConfigItem) => {
			/**
			 * Update the backendModel common attribute whenever the configItem changes
			 */
			analyticsFlow.updateCommonAttributes({
				backendModel: configItem?.getBackendModel?.(!!latestPromptResponse),
				...analyticsFlow.getUserFlowCommonAttributes(configItem),
			});

			const attributes: Parameters<typeof analyticsFlow.addAttributes>[0] = {
				experienceName: configItem.key,
				promptTitle: getPromptTitle(configItem),
			};

			if (fg('platform_editor_ai_prompt_input_statistics')) {
				let inputNodeCount = {};
				let characterCount = 0;
				if (adfInput) {
					inputNodeCount = reduce<{ [key: string]: number }>(
						adfInput as ADFEntity,
						(accum, node) => {
							if (node.type === 'text') {
								characterCount = characterCount + (node.text ?? '').length;
							}
							accum[node.type] = (accum[node.type] ?? 0) + 1;
							node?.marks?.forEach((mark) => {
								accum[mark.type] = (accum[mark.type] ?? 0) + 1;
							});
							return accum;
						},
						{},
					);
					attributes.inputNodeCount = inputNodeCount;
					attributes.characterCount = characterCount;
				}
			}

			// update experiencename and title as it changes on selecting suggestion
			analyticsFlow.addAttributes(attributes);
		},
		[analyticsFlow, latestPromptResponse, getPromptTitle, adfInput],
	);

	const updateTextInput = useCallback(
		(textInputValue: string) => {
			sendToCommandPalette({
				type: 'onTextEntered',
				input: textInputValue,
			} as { type: 'onTextEntered'; input: string });
		},
		[sendToCommandPalette],
	);

	const updateADFInput = React.useCallback(
		(text?: JSONDocNode) => {
			sendToCommandPalette({
				type: 'onADFEntered',
				adf: text,
			});
		},
		[sendToCommandPalette],
	);

	const onSubmit = useCallback(
		({ configItem: _configItem }: { configItem?: EditorPluginAIConfigItem } = {}) => {
			// if the user selects a parent with nesting submenus (`Change tone` or `Translate`)
			// they can only trigger the AI experience by selecting a submenu (which can be triggered by pressing enter)
			if (isParentWithNestedSubmenusSelected) {
				return;
			}

			kickoffAiExperience({
				configItem: _configItem || commandPaletteContext.configItem,
				userInput: commandPaletteContext.textInput || '',
				userADFInput: commandPaletteContext.adfInput,
			});
		},
		[
			isParentWithNestedSubmenusSelected,
			kickoffAiExperience,
			commandPaletteContext.configItem,
			commandPaletteContext.textInput,
			commandPaletteContext.adfInput,
		],
	);

	const __getSuggestions = useMemo((): ((_: {
		inputValue?: string | undefined;
	}) => EditorPluginAIConfigItem[]) => {
		const getConfigItems = () => {
			return getVisibleEditorPluginAIConfigItems({
				configItemWithOptions: editorPluginAIProvider.configItemWithOptions,
				editorView,
				positions,
				isEmpty,
			});
		};
		return getConfigItems;
	}, [editorView, positions, isEmpty, editorPluginAIProvider.configItemWithOptions]);

	/**
	 * This function is responsible for highlighting the entire document
	 * when particular prompts are selected.
	 *
	 * This only happens if the user has not already made a selection.
	 *
	 * Currently all prompts highlight the whole document except partial prompts like:
	 * - Brainstorm
	 * - Ask Search
	 */
	const handleHighlight = useCallback<HandleHighlightCbType>(
		({ configItem, promptLabel }) => {
			// Remove all decorations if the prompt is 'Tell AI' or that it is not defined (brainstorm)
			if (
				(configItem.promptLabel !== undefined && !configItem.agent) ||
				promptLabel === 'tell-ai' ||
				promptLabel === USE_GENERAL_AI_KNOWLEDGE ||
				promptLabel === USE_ROVO_ORGANISATION_KNOWLEDGE ||
				promptLabel === BROWSE_AGENTS
			) {
				const removeTransaction = editorView.state.tr;
				removeTransaction.setMeta('hideSelectionPreview', true);
				editorView.dispatch(removeTransaction);
				return;
			}

			// Highlight the entire document for all other prompts
			const setTransaction = editorView.state.tr;
			setTransaction.setMeta('showSelectionPreview', true);
			editorView.dispatch(setTransaction);
		},
		[editorView],
	);

	const onConversationStarterSelected = useCallback(
		(conversationStarter: string) => {
			const userADFInput = doc(p(conversationStarter));

			handleHighlight({ configItem });
			updateAnalytics(configItem);
			// Ignored via go/ees005
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			onPartialPresetSelected(configItem, { presetPromptLabel: `@${configItem.agent!.name}` });
			kickoffAiExperience({
				userInput: conversationStarter,
				configItem,
				userADFInput,
			});
		},
		[handleHighlight, updateAnalytics, onPartialPresetSelected, kickoffAiExperience, configItem],
	);

	useEffect(() => {
		if (initialPrompt && !initialPromptTriggered) {
			if (configItem.agent) {
				onConversationStarterSelected(initialPrompt);
			} else {
				if (!experienceAppContext.latestPrompt) {
					const userADFInput = doc(p(initialPrompt));
					updateADFInput(userADFInput);
					updateTextInput(initialPrompt);
					setInitialPromptTriggered(true);
					kickoffAiExperience({
						userInput: initialPrompt,
						configItem,
						userADFInput,
					});
				}
			}
			setInitialPromptTriggered(true);
		}
	}, [
		initialPrompt,
		onConversationStarterSelected,
		initialPromptTriggered,
		configItem,
		experienceAppContext.latestPrompt,
		updateADFInput,
		updateTextInput,
		kickoffAiExperience,
	]);

	const { starters: agentConversationStarters } = useAgentStarters({
		handleHighlight,
		configItem,
		onConversationStarterSelected,
		product: editorPluginAIProvider.product,
	});

	const getSuggestions = useCallback(
		(inputValue: string, agent?: EditorAgent): Suggestion[] => {
			// if like Brainstorm, return nothing
			if (presetPromptLabel && !configItem.nestingConfig && !agent) {
				return [];
			}

			/**
			 * knowledge source
			 */

			function makeUseRovoOrganisationKnowledgeSuggestion(defaultAgent: EditorAgent): Suggestion {
				const agentConfigItem = createAgentConfigItem(defaultAgent, editorPluginAIProvider);
				return {
					testId: USE_ROVO_ORGANISATION_KNOWLEDGE,
					value: USE_ROVO_ORGANISATION_KNOWLEDGE,
					label: formatMessage(messages.useRovoOrganisationKnowledgeLabel),
					groupHeading: formatMessage(messages.knowledgeSourceGroupHeading),
					rightText: formatMessage(messages.useRovoOrganisationKnowledgeRightText),
					hideIcon: true,
					onSelect: () => {
						handleHighlight({ configItem: agentConfigItem });
						updateAnalytics(agentConfigItem);
						onSubmit({ configItem: agentConfigItem });
					},
					onSuggestionFocused: () => {
						handleHighlight({
							configItem: agentConfigItem,
							promptLabel: USE_ROVO_ORGANISATION_KNOWLEDGE,
						});
					},
				};
			}

			// If the user input has more than 3 words, we should show all Rovo Agents suggestions,
			// and suggestions should run free prompt, instead of showing starters.
			// See https://product-fabric.atlassian.net/browse/EDF-1700 for details.
			const isRovoAgentsSuggestionsShouldRunFreePrompt = inputValue.trim().split(' ').length > 3;

			function getRovoAgentsGroupHeading(): string {
				const matchedAgentCount = getSuggestions(textInput || '').filter(
					(suggestion) => suggestion.isAgent,
				).length;

				if (matchedAgentCount === 0) {
					return formatMessage(messages.rovoAgentsSuggestionGroupHeading);
				} else if (isRovoAgentsSuggestionsShouldRunFreePrompt) {
					return formatMessage(messages.useRovoAgentsCountSuggestionGroupHeading, {
						numberOfAgents: matchedAgentCount.toString(),
					});
				} else {
					return formatMessage(messages.rovoAgentsCountSuggestionGroupHeading, {
						numberOfAgents: matchedAgentCount.toString(),
					});
				}
			}

			function makeKnowledgeSourceSuggestions(): Suggestion[] {
				const useRovoOrganisationKnowledgeSuggestions: Suggestion[] =
					!rovoAgentsPluginState?.enabled ||
					!rovoAgentsPluginState?.defaultAgent ||
					!aiProvider.getChannelVariables ||
					agent
						? []
						: // pass defaultAgent to avoid ts check again
							[makeUseRovoOrganisationKnowledgeSuggestion(rovoAgentsPluginState.defaultAgent)];

				const actions = getEditorPluginAIConfigItemActions({ isEmpty, configItem });
				if (
					isRovoEnabled &&
					!configItem.agent &&
					!actions.find((action) => action.type === 'generic-chat') &&
					fg('platform_editor_ai_open_chat_general_ai_fg')
				) {
					const chatAction = createContinueInChat({
						appearance: 'secondary',
						product: editorPluginAIProvider.product,
						actionSideEffect: editorPluginAIProvider.actionSideEffects?.['continueInChat'],
					});
					actions.unshift(chatAction);
				}

				const knowledgeSourceSuggestions: Suggestion[] = [
					{
						testId: USE_GENERAL_AI_KNOWLEDGE,
						value: USE_GENERAL_AI_KNOWLEDGE,
						label: formatMessage(messages.useGeneralAiKnowledgeLabel),
						groupHeading: formatMessage(messages.knowledgeSourceGroupHeading),
						rightText: formatMessage(messages.useGeneralAiKnowledgeRightText),
						hideIcon: true,
						onSelect: () => {
							handleHighlight({ configItem });
							updateAnalytics(configItem);
							onSubmit();
						},
						onSuggestionFocused: () => {
							handleHighlight({
								configItem,
								promptLabel: USE_GENERAL_AI_KNOWLEDGE,
							});
						},
					},
					...useRovoOrganisationKnowledgeSuggestions,
				];

				return knowledgeSourceSuggestions;
			}

			// use this flag to determine if we should show the knowledge source suggestions instead of the 'Tell AI' suggestion
			const showKnowledgeSourceSuggestionsInsteadOfTellAI =
				rovoAgentsPluginState?.enabled &&
				rovoAgentsPluginState?.defaultAgent &&
				aiProvider.getChannelVariables &&
				!agent &&
				!presetPromptLabel;

			/**
			 * do not show tell-ai
			 * - if there is no user input
			 * - if the user selects a parent with nesting submenus (`Change tone` or `Translate`)
			 *   - they can only trigger the AI experience by selecting a submenu (which can be triggered by pressing enter)
			 */
			const tellAiSuggestion: Suggestion[] =
				!inputValue || isParentWithNestedSubmenusSelected
					? []
					: showKnowledgeSourceSuggestionsInsteadOfTellAI
						? makeKnowledgeSourceSuggestions()
						: [
								{
									value: agent ? 'tell-agent' : 'tell-ai',
									label: inputValue,
									hideIcon: true,
									onSelect: () => {
										handleHighlight({ configItem });
										updateAnalytics(configItem);
										onSubmit();
									},
									onSuggestionFocused: () => {
										handleHighlight({
											configItem,
											promptLabel: agent ? 'tell-agent' : 'tell-ai',
										});
									},
								},
							];

			const showMoreText = formatMessage(messages.showMoreButton);
			const showMoreSuggestion = [
				{
					value: 'show-more',
					label: showMoreText,
					icon: <MoreIcon label={showMoreText} primaryColor={token('color.icon', '#44546F')} />,
					onSelect: () => {
						handleHighlight({ configItem });
						updateAnalytics(configItem);
					},
					onSuggestionFocused: () => {
						handleHighlight({
							configItem,
							promptLabel: showMoreText,
						});
					},
				},
			];
			// The browse agents option is only available when using the new <BrowseAgentsModal>
			// component as we need to use its state to send the selected agent to the command palette
			const browseAgentsSuggestion =
				!rovoAgentsPluginState?.enabled || !aiProvider.getChannelVariables || agent
					? []
					: {
							value: BROWSE_AGENTS,
							label: formatMessage(messages.agentsBrowseMore),
							icon: (
								<AiAgentIcon label="browse-more-agents" color={token('color.icon', '#44546F')} />
							),
							groupHeading: getRovoAgentsGroupHeading,
							onSelect: () => {
								openBrowseAgentsModal(true, positions)(editorView.state, editorView.dispatch);
							},
							onSuggestionFocused: () => {
								handleHighlight({
									configItem,
									promptLabel: BROWSE_AGENTS,
								});
							},
						};

			/**
			 * If an agent is selected, we want to show the agents conversation starters
			 * as suggestions in the command palette with the 'Tell Agent' suggestion
			 * when there is user input
			 */
			if (agent) {
				if (
					agentConversationStarters.filter((configItem) => typeof configItem.label !== 'string')
						.length
				) {
					return agentConversationStarters;
				}

				const filteredAgentSuggestions = agentConversationStarters.filter((configItem) =>
					(configItem.label as string).toLowerCase().includes((inputValue || '').toLowerCase()),
				);

				return filteredAgentSuggestions;
			}

			/**
			 * If an agent is not selected, we want to show the suggestions
			 */
			const output = __getSuggestions({ inputValue })
				.map<Suggestion>((configItem): Suggestion => {
					let icon = configItem.icon?.({ formatMessage, shownAt: 'suggestions' });

					// Show the icon for the parent config item if it exists
					if (
						configItem.nestingConfig &&
						configItem.nestingConfig.icon &&
						!presetPromptLabel &&
						!inputValue
					) {
						icon = configItem.nestingConfig.icon?.({ formatMessage, shownAt: 'suggestions' });
					}

					const actions = getEditorPluginAIConfigItemActions({ isEmpty, configItem });
					if (
						isRovoEnabled &&
						!configItem.agent &&
						!actions.find((action) => action.type === 'generic-chat') &&
						fg('platform_editor_ai_open_chat_general_ai_fg')
					) {
						const chatAction = createContinueInChat({
							appearance: 'secondary',
							product: editorPluginAIProvider.product,
							actionSideEffect: editorPluginAIProvider.actionSideEffects?.['continueInChat'],
						});
						actions.unshift(chatAction);
					}

					return {
						icon,
						label:
							configItem.nestingConfig && presetPromptLabel
								? formatMessage(configItem.nestingConfig.shortTitle)
								: getTranslatedItemTitle(configItem, formatMessage),
						statusLozengeType: configItem.statusLozengeType,
						value: configItem.key,
						nestingConfig: configItem.nestingConfig
							? {
									parentTestId: configItem.nestingConfig.parentTestId,
									parentTitle: formatMessage(configItem.nestingConfig.parentTitle),
								}
							: undefined,
						onSuggestionFocused: () => {
							handleHighlight({ configItem });
						},
						onSelect: () => {
							// A config item nested parent will have matching config but no prompt label
							// will be selected
							if (configItem.nestingConfig && !presetPromptLabel && !inputValue) {
								onPartialPresetSelected(configItem, {
									presetPromptLabel: formatMessage(configItem.nestingConfig.parentTitle),
								});
							} else {
								handleHighlight({ configItem });
								updateAnalytics(configItem);

								// If full prompt is selected we want to kickoff the AI experience
								if (!configItem.promptLabel) {
									kickoffAiExperience({
										userInput: '',
										userADFInput: undefined,
										configItem,
									});
								} else {
									onPartialPresetSelected(configItem);
								}
							}
						},
					};
				})
				.concat(showMoreSuggestion)
				.concat(!agent ? tellAiSuggestion : [])
				/**
				 * Only show the agents if the user hasn't already selected an agent
				 */
				.concat(
					!rovoAgentsPluginState?.enabled || !aiProvider.getChannelVariables || agent
						? []
						: rovoAgentsPluginState.agents.map((agent) => {
								const agentConfigItem = createAgentConfigItem(agent, editorPluginAIProvider);

								return {
									value: `agent:${agent.id}`,
									label: agent.name,
									description: agent.description ?? '',
									icon: (
										<>
											{/* FIXME: It's should not be a part of icon! It should be redone to hook!!! */}
											{editorPluginAIProvider.isRovoEnabled && <PreloadModalComponent />}

											<AgentAvatar
												agentId={agent.id}
												agentNamedId={agent.externalConfigReference ?? agent.namedId}
												agentIdentityAccountId={agent.identityAccountId}
												isForgeAgent={agent.creatorType === 'FORGE'}
												forgeAgentIconUrl={agent.icon}
											/>
										</>
									),
									groupHeading: getRovoAgentsGroupHeading,
									isAgent: true,
									onSelect: () => {
										handleHighlight({ configItem: agentConfigItem });
										updateAnalytics(agentConfigItem);
										onPartialPresetSelected(agentConfigItem, {
											// Ignored via go/ees005
											// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
											presetPromptLabel: `@${agentConfigItem.agent!.name}`,
										});

										if (isRovoAgentsSuggestionsShouldRunFreePrompt) {
											kickoffAiExperience({
												userInput: inputValue,
												userADFInput: undefined,
												configItem: agentConfigItem,
											});
										}
									},
									onSuggestionFocused: () => {
										handleHighlight({
											configItem: agentConfigItem,
										});
									},
								};
							}),
				)
				.concat(agent ? tellAiSuggestion : [])
				.filter((suggestion) => {
					if (!suggestion) {
						return false;
					}

					if (typeof suggestion.label !== 'string') {
						return false;
					}

					// Since we move `.concat(tellAiSuggestion)` above, we need to update the filter logic to include these suggestions
					if (
						suggestion.value === USE_GENERAL_AI_KNOWLEDGE ||
						suggestion.value === USE_ROVO_ORGANISATION_KNOWLEDGE
					) {
						return true;
					}

					// if the user selects a parent with nesting submenus (`Change tone` or `Translate`)
					// ignore those that don't belong to the parent
					if (
						isParentWithNestedSubmenusSelected &&
						suggestion.nestingConfig?.parentTitle !== presetPromptLabel
					) {
						return false;
					}

					// If Rovo Agents should process the user input and run free prompt,
					// show all agents suggestions.
					if (suggestion?.isAgent && isRovoAgentsSuggestionsShouldRunFreePrompt) {
						return true;
					}

					// text filter
					const searchValues = [suggestion.label.toLowerCase()];
					searchValues.push((suggestion.description ?? '').toLowerCase());

					if (suggestion?.isAgent) {
						searchValues.push('rovo', 'agent');
					}

					return searchValues.join(' ').includes((inputValue || '').toLowerCase());
				})
				.concat(!isParentWithNestedSubmenusSelected ? browseAgentsSuggestion : [])
				.map((suggestion) => {
					if (!suggestion.groupHeading) {
						// If the suggestion is in a submenu, we want to show the parent title as the group heading
						suggestion.groupHeading =
							isParentWithNestedSubmenusSelected && configItem?.nestingConfig?.parentTitle
								? formatMessage(configItem.nestingConfig.parentTitle)
								: formatMessage(messages.atlassianIntelligenceGroupHeading);
					}

					return suggestion;
				});

			if (output.length) {
				return output;
			}

			return isParentWithNestedSubmenusSelected
				? [
						{
							value: 'no-suggestions',
							label: formatMessage(messages.noResultsSuggestion),
							hideIcon: true,
							icon: undefined,
							onSelect: () => {},
							onSuggestionFocused: () => {},
						},
					]
				: [];
		},
		[
			presetPromptLabel,
			configItem,
			rovoAgentsPluginState?.enabled,
			rovoAgentsPluginState?.defaultAgent,
			rovoAgentsPluginState?.agents,
			aiProvider.getChannelVariables,
			isParentWithNestedSubmenusSelected,
			formatMessage,
			__getSuggestions,
			editorPluginAIProvider,
			handleHighlight,
			updateAnalytics,
			onSubmit,
			textInput,
			positions,
			editorView,
			isEmpty,
			agentConversationStarters,
			onPartialPresetSelected,
			kickoffAiExperience,
			isRovoEnabled,
		],
	);

	const aiToolbarButtonPulseAnnouncement = useEngagementPlatformAnnouncementBanner(
		'cc-editor-ai_ai-toolbar-button-pulse',
		formatMessage(messages.engagementBannerLinksText),
	);

	useEffect(() => {
		if (aiProvider.onAIProviderChanged) {
			aiProvider.onAIProviderChanged(
				'command-palette',
				configItem.agent
					? {
							type: 'rovo',
							agent: configItem.agent,
						}
					: undefined,
			);
		}
	}, [configItem.agent, aiProvider]);

	const removeRefinementTag = useCallback(() => {
		// Set experienceName and promptTitle to default values
		analyticsFlow.addAttributes({
			experienceName: baseGenerate?.key,
			promptTitle: baseGenerate ? getTranslatedPromptLabel(baseGenerate, formatMessage) : '',
		});
		analyticsFlow.updateCommonAttributes({
			backendModel: baseGenerate?.getBackendModel?.(!!latestPromptResponse),
		});
		sendToCommandPalette({ type: 'onPartialPresetRemoved' });
		focusInputRef.current?.();
	}, [analyticsFlow, baseGenerate, formatMessage, latestPromptResponse, sendToCommandPalette]);

	const onBack = useCallback(() => {
		removeRefinementTag();
		updateTextInput('');
		updateADFInput();
	}, [removeRefinementTag, updateTextInput, updateADFInput]);

	const pageKnowledgeProps = usePageKnowledgeToggle({
		hasInput: !!(textInput || adfInput),
		suggestions: getSuggestions(textInput || ''),
		agentVisible: !!agentConversationStarters.length,
		nestedMenuSelected: isParentWithNestedSubmenusSelected,
	});

	const promptBuilder = usePromptBuilder({
		promptEditor: editorPluginAIProvider.PromptEditor,
		configItem,
		advancedPromptValue,
		sendToCommandPalette,
		kickoffAiExperience,
	});

	return (
		<>
			<UserInputCommandPalette
				promptBuilder={
					// The experiment will be exposed when user sees the Command Palette
					editorExperiment('platform_editor_ai_advanced_prompts', true, { exposure: true })
						? promptBuilder
						: undefined
				}
				appearance={appearance}
				presetPromptLabel={presetPromptLabel}
				inputValue={textInput || ''}
				inputADFValue={adfInput}
				onInputUpdate={updateTextInput}
				onADFUpdate={updateADFInput}
				onEscapeKeyPressed={handleCancel}
				onSubmit={onSubmit}
				onRefinementTagRemove={removeRefinementTag}
				onBack={onBack}
				placeholder={configItem.promptHint ? formatMessage(configItem.promptHint) : ''}
				getSuggestions={getSuggestions}
				focusInputRef={focusInputRef}
				PromptEditor={editorPluginAIProvider.PromptEditor}
				agent={configItem.agent}
				hideLegacySubmitCancelButtons={isParentWithNestedSubmenusSelected}
				announcementBanners={
					aiToolbarButtonPulseAnnouncement ? [aiToolbarButtonPulseAnnouncement] : undefined
				}
				pageKnowledgeProps={pageKnowledgeProps}
			/>
		</>
	);
}
