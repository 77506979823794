import type { ReadonlyTransaction, Transaction } from '@atlaskit/editor-prosemirror/state';
import type { Decoration, DecorationSet } from '@atlaskit/editor-prosemirror/view';

import type { AIProactivePluginState } from '../states';

import { updateRecommendationDecoration } from './recommendation-decorations';
import {
	addHoveredRecommendationDecoration,
	removeHoveredRecommendationDecoration,
} from './recommendation-hovered-decoration';
import {
	addSelectedRecommendationDecoration,
	removeSelectedRecommendationDecoration,
} from './recommendation-selected-decoration';

export const ProactiveDecorations = {
	RECOMMENDATION: 'aiProactiveRecommendation',
	RECOMMENDATION_SELECTED: 'aiProactiveRecommendationSelected',
	RECOMMENDATION_HOVERED: 'aiProactiveRecommendationHovered',
} as const;

export type ProactiveDecorations = (typeof ProactiveDecorations)[keyof typeof ProactiveDecorations];

type DecorationTransformer = (params: DecorationTransformerParams) => DecorationSet;

export type DecorationTransformerParams = {
	decorationSet: DecorationSet;
	tr: Transaction | ReadonlyTransaction;
	pluginState: AIProactivePluginState;
};

type DecorationTransformers = Array<DecorationTransformer>;

const composeDecorations =
	(transformers: DecorationTransformers): DecorationTransformer =>
	({ decorationSet, tr, pluginState }: DecorationTransformerParams) =>
		transformers.reduce(
			(decorationSet, transform) => transform({ decorationSet, tr, pluginState }),
			decorationSet,
		);

export const filterDecorationByKey = (
	key: ProactiveDecorations,
	decorationSet: DecorationSet,
): Decoration[] => decorationSet.find(undefined, undefined, (spec) => spec.key.indexOf(key) > -1);

export const regenerateSelectionDecorations = (
	params: DecorationTransformerParams,
): DecorationSet => {
	return composeDecorations([
		removeSelectedRecommendationDecoration,
		addSelectedRecommendationDecoration,
	])(params);
};

export const regenerateHoverDecorations = (params: DecorationTransformerParams): DecorationSet => {
	return composeDecorations([
		removeHoveredRecommendationDecoration,
		addHoveredRecommendationDecoration,
	])(params);
};

export const regenerateAllDecorations = (params: DecorationTransformerParams): DecorationSet => {
	return composeDecorations([
		removeSelectedRecommendationDecoration,
		removeHoveredRecommendationDecoration,
		updateRecommendationDecoration,
		addSelectedRecommendationDecoration,
		addHoveredRecommendationDecoration,
	])(params);
};
