/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { N20, N40A, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const containerLayout = css({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const previewContainer = css({
	padding: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const previewContainerBorders = css({
	borderTop: `1px solid ${token('color.border', N40A)}`,
	borderBottom: `1px solid ${token('color.border', N40A)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const actionsContainer = css({
	display: 'flex',
	flexWrap: 'wrap-reverse',
	justifyContent: 'flex-end',
	gap: token('space.100', '8px'),
	paddingTop: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const actionsContainerNoPaddingTop = css({
	paddingTop: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const actionsButtonGroup = css({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'flex-end',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const columnLayout = css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const interrogationWrapper = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& .ak-editor-content-area .ProseMirror': {
		margin: 0,
	},
	// The 0.5px is from platform/packages/editor/generative-ai-modal/src/ui/components/PromptEditorWrapper/PromptEditorWrapper.tsx
	// promptEditorWrapperOverflowStyles
	minHeight: `calc(${token('space.600')} + 0.5px)`,
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const footerStyles = css({
	display: 'flex',
	flexWrap: 'wrap',
	padding: `${token('space.050', '4px')} ${token('space.150', '12px')}`,
	gap: token('space.050', '4px'),
	borderTopLeftRadius: 'unset',
	borderTopRightRadius: 'unset',
	backgroundColor: token('color.background.accent.gray.subtlest', N20),
	alignItems: 'center',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const footerInformationStyles = css({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	minWidth: 0,
	minHeight: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > p, & > div:first-child': {
		color: token('color.text.subtle', N500),
		font: token('font.body.small'),
		marginTop: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		marginTop: token('space.050', '4px'),
		marginRight: token('space.050', '4px'),
		marginBottom: token('space.050', '4px'),
	},
});
