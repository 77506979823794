import { useEffect, useState } from 'react';

/**
 * Check if the flex (`flex-direction: row`) element is displayed in two or more lines (wrapped).
 *
 * WARNING: Use this hook carefully!
 *          This hook works only for flex elements with `flex-direction: row` and
 *          if child elements have the same `offsetTop` position when they are in the one row.
 *          If you have a different layout, this hook may not work correctly.
 */
export function useIsFlexElementWrapped(): [(element: HTMLElement | null) => void, boolean] {
	const [element, setElement] = useState<HTMLElement | null>(null);
	const [result, setResult] = useState(false);

	useEffect(() => {
		if (!element) {
			return;
		}

		const updateResult = (entries: ResizeObserverEntry[]) => {
			for (const entry of entries) {
				const firstChild = entry.target.firstElementChild;
				if (!(firstChild instanceof HTMLElement)) {
					setResult(false);
					return;
				}

				const firstChildTop = firstChild.offsetTop;

				// Check that all elements in the same row, if not, set result to true.
				// We can't check the first and last element because the may have different visual position,
				// because of order CCS property.
				const isInTwoLine = Array.from(entry.target.children).some(
					(child) => child instanceof HTMLElement && child.offsetTop !== firstChildTop,
				);

				setResult(isInTwoLine);
			}
		};

		const resizeObserver = new ResizeObserver(updateResult);
		resizeObserver.observe(element);

		return () => {
			resizeObserver.disconnect();
		};
	}, [element]);

	return [setElement, result];
}
