/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessStatus {
  ANONYMOUS_ACCESS = "ANONYMOUS_ACCESS",
  EXTERNAL_COLLABORATOR_ACCESS = "EXTERNAL_COLLABORATOR_ACCESS",
  EXTERNAL_SHARE_ACCESS = "EXTERNAL_SHARE_ACCESS",
  LICENSED_ADMIN_ACCESS = "LICENSED_ADMIN_ACCESS",
  LICENSED_USE_ACCESS = "LICENSED_USE_ACCESS",
  NOT_PERMITTED = "NOT_PERMITTED",
  UNLICENSED_AUTHENTICATED_ACCESS = "UNLICENSED_AUTHENTICATED_ACCESS",
}

export enum ConfluenceEdition {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
  STANDARD = "STANDARD",
}

export enum Environment {
  DEVELOPMENT = "DEVELOPMENT",
  PRODUCTION = "PRODUCTION",
  STAGING = "STAGING",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteInformationQuery
// ====================================================

export interface SiteInformationQuery_tenant_editions {
  edition: ConfluenceEdition;
}

export interface SiteInformationQuery_tenant {
  shard: string;
  cloudId: string;
  environment: Environment;
  activationId: string | null;
  editions: SiteInformationQuery_tenant_editions | null;
}

export interface SiteInformationQuery_organization {
  orgId: string | null;
}

export interface SiteInformationQuery_userForAnalytics {
  id: string | null;
}

export interface SiteInformationQuery_user_confluence {
  accessStatus: AccessStatus;
  accountId: string | null;
  userKey: string | null;
  locale: string;
}

export interface SiteInformationQuery_user {
  id: string | null;
  displayName: string | null;
  confluence: SiteInformationQuery_user_confluence | null;
  timeZone: string | null;
}

export interface SiteInformationQuery_getAIConfig {
  isEnabled: boolean;
  isRovoEnabled: boolean | null;
}

export interface SiteInformationQuery_spaViewContext {
  userCanCreateContent: boolean | null;
  isAnonymous: boolean | null;
  homepageUri: string | null;
  homepageTitle: string | null;
}

export interface SiteInformationQuery_siteConfiguration {
  tenantId: string | null;
  siteLogoUrl: string;
  siteTitle: string;
  showSiteTitle: boolean;
  frontCoverState: string;
  companyHubName: string | null;
  ccpEntitlementId: string | null;
}

export interface SiteInformationQuery {
  tenant: SiteInformationQuery_tenant;
  organization: SiteInformationQuery_organization | null;
  userForAnalytics: SiteInformationQuery_userForAnalytics | null;
  user: SiteInformationQuery_user | null;
  getAIConfig: SiteInformationQuery_getAIConfig | null;
  spaViewContext: SiteInformationQuery_spaViewContext | null;
  siteConfiguration: SiteInformationQuery_siteConfiguration;
  isNewUser: boolean;
  isSiteAdmin: boolean;
  abTestCohorts: string;
  experimentFeatures: string | null;
}
