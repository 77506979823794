import { ApolloLink, Observable } from 'apollo-link';

import { EXTERNAL_SHARE } from '@confluence/named-routes';

/**
 * This custom link terminate queries without allowOnExternalShare context property
 * if query was fired from external page
 */
export const externalShareLink = new ApolloLink((operation, forward) => {
	const { allowOnExternalPage = false } = operation.getContext();

	const isExternalShareRequest = !!EXTERNAL_SHARE.match(window.location.pathname);

	// this issue has given developers a lot of gripe, since things fail silently
	if (isExternalShareRequest && !allowOnExternalPage) {
		// eslint-disable-next-line no-console
		console.warn('Trying to make a call on an external route without allowOnExternalPage');
	}
	if (allowOnExternalPage || !isExternalShareRequest) {
		return forward(operation);
	}

	return new Observable((observer) => {
		observer.complete();
	});
});
