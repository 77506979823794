import { useEffect, useState } from 'react';

export const useEditorToolbarChangeDetector = (
	ButtonWrapper: React.ComponentType<{ children: React.ReactNode }> | undefined,
) => {
	const [toolbarMutationCount, setToolbarMutationCount] = useState(0);

	useEffect(() => {
		if (!ButtonWrapper) {
			return;
		}

		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				const { addedNodes, removedNodes } = mutation;
				// @ts-ignore error TS2488: Type 'NodeList' must have a '[Symbol.iterator]()' method that returns an iterator.
				// This was required when enrolling this package into JFE local consumption
				const isRelevantMutation = [...addedNodes, ...removedNodes].some(
					(node) => node.nodeType === 1 && node.nodeName === 'DIV',
				);

				if (isRelevantMutation) {
					setToolbarMutationCount(toolbarMutationCount + 1);
				}
			});
		});

		const mainToolBar = document.querySelector(`[data-testid="ak-editor-main-toolbar"]`);

		if (mainToolBar) {
			observer.observe(mainToolBar, { childList: true, subtree: true });
		}

		return () => {
			observer.disconnect();
		};
	}, [toolbarMutationCount, ButtonWrapper]);

	return toolbarMutationCount;
};
