import gql from 'graphql-tag';

export const SetDefaultSpaceRoleAssignments = gql`
	mutation SetDefaultSpaceRoleAssignments($input: SetDefaultSpaceRoleAssignmentsInput!) {
		setDefaultSpaceRoleAssignments(input: $input) {
			errors {
				message
				extensions {
					errorType
					statusCode
				}
			}
			success
		}
	}
`;
