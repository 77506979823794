import { type Agent } from '@atlassian/conversation-assistant-service';

export const getOverridenAgents = (
	agents: Agent[],
	agentIdFiltersOverride?: string[],
	agentDefaultIdOverride?: string,
) => {
	const defaultOverrideAgentsIndex = agents.findIndex(
		(agent) => agentDefaultIdOverride === agent.id,
	);

	let overridenAgents: Agent[] = [];

	if (defaultOverrideAgentsIndex !== -1) {
		overridenAgents = agents.map((agent, index) => ({
			...agent,
			is_default: index === defaultOverrideAgentsIndex,
		}));
	} else {
		overridenAgents = agents;
	}

	if (Array.isArray(agentIdFiltersOverride)) {
		return overridenAgents.filter(
			(agent) => agentIdFiltersOverride?.includes(agent.id) || agent.is_default,
		);
	}

	return overridenAgents;
};
