import React from 'react';

import { useSessionData } from '@confluence/session-data';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { ADMIN_SPACE_PERMISSIONS } from '@confluence/named-routes';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { DefaultSpacePermissionsPage } from '@confluence/default-space-permissions/entry-points/DefaultSpacePermissionsPage';

export const IndividualSpacesRoute = () => {
	const { edition } = useSessionData();
	const isFreeEdition = edition === ConfluenceEdition.FREE;

	if (isFreeEdition) {
		return <Redirection name={ADMIN_SPACE_PERMISSIONS.name} />;
	}

	return <DefaultSpacePermissionsPage activeTab={'allspaces'} />;
};

IndividualSpacesRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'allSpacesPermissionsScreen' },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
