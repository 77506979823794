import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	insert: {
		id: 'fabric.editor.ai.config.item.action.insert',
		defaultMessage: 'Insert',
		description: 'Inserts the response at the current location',
	},
	insertAtTop: {
		id: 'fabric.editor.ai.config.item.action.insertAtTop',
		defaultMessage: 'Insert at top',
		description: 'Inserts the response at the start of the content',
	},
	insertBelow: {
		id: 'fabric.editor.ai.config.item.action.insertBelow',
		defaultMessage: 'Insert below',
		description: 'Inserts the response below the current location',
	},
	replace: {
		id: 'fabric.editor.ai.config.item.action.replace',
		defaultMessage: 'Replace',
		description: 'Replaces the existing or selected content with the response',
	},
	replaceTitle: {
		id: 'fabric.editor.ai.config.item.action.replaceTitle',
		defaultMessage: 'Replace title',
		description: 'Replaces the existing Confluence document title with the generated title',
	},
	replaceTitleTooLongDisabledTooltip: {
		id: 'fabric.editor.ai.config.item.action.replaceTitleTooLongDisabledTooltip',
		defaultMessage: 'Title cannot exceed {maxLength} characters',
		description:
			'Tooltip that appears over disabled action button when disabled because of title being too long',
	},
	continueInChatLabel: {
		id: 'fabric.editor.ai.config.item.action.rovoAgents.continueInChatLabel',
		defaultMessage: 'Open in Chat',
		description: 'Tooltip and label for Open in Chat button',
	},
});
