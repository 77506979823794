import { TableSharedCssClassName } from '@atlaskit/editor-common/styles';
import type { Schema } from '@atlaskit/editor-prosemirror/model';
import { findDomRefAtPos, findParentNodeClosestToPos } from '@atlaskit/editor-prosemirror/utils';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import { type InvokedFrom } from '../../analytics/analytics-flow/analyticsFlowTypes';

function getOffsetParent(element: HTMLElement) {
	const offsetParent = element.offsetParent;
	if (offsetParent) {
		return offsetParent;
	}

	// If offsetParent is null, we need to find the nearest ancestor element that has a position value other than static
	let tempOffsetParent = element.parentElement;
	while (tempOffsetParent) {
		const position = getComputedStyle(tempOffsetParent).position;
		if (position !== 'static') {
			return tempOffsetParent;
		}
		tempOffsetParent = tempOffsetParent.parentElement;
	}
	return document.body;
}

// Calculate offset for modal positioning
function getModalOffset({
	from,
	modalElement,
	direction,
}: {
	from: HTMLElement;
	modalElement: HTMLElement;
	direction: 'top' | 'left';
}) {
	const property = direction === 'top' ? 'offsetTop' : 'offsetLeft';
	let offset = from[property];
	let tempOffsetParent = from.offsetParent;
	const modalOffsetParent = getOffsetParent(modalElement);
	while (
		tempOffsetParent &&
		tempOffsetParent instanceof HTMLElement &&
		tempOffsetParent !== modalOffsetParent
	) {
		offset += tempOffsetParent[property];
		tempOffsetParent = tempOffsetParent.offsetParent;
	}
	return offset;
}

export function getModalLeft({
	relativePositionElement,
	modalElement,
}: {
	relativePositionElement: HTMLElement;
	modalElement: HTMLElement;
}) {
	return getModalOffset({ from: relativePositionElement, modalElement, direction: 'left' });
}

// Calculate offset top for decorationBottom positioning
function getDecorationBottom({
	modalDecorationElement,
	modalElement,
}: {
	modalDecorationElement: HTMLElement;
	modalElement: HTMLElement;
}) {
	const offset = getModalOffset({ from: modalDecorationElement, modalElement, direction: 'top' });
	return offset + modalDecorationElement.offsetHeight;
}

export function getModalTop({
	modalElement,
	editorRelativeWrapper,
	modalDecorationElement,
	lastTriggeredFrom,
}: {
	modalElement: HTMLElement;
	editorRelativeWrapper?: Element | null;
	modalDecorationElement: HTMLElement;
	lastTriggeredFrom?: InvokedFrom;
}) {
	const isSuggestTitleQuickAction = lastTriggeredFrom === 'confluenceTitleToolbar';
	return isSuggestTitleQuickAction
		? editorRelativeWrapper?.querySelector<HTMLElement>('.ProseMirror')?.offsetTop || 0
		: getDecorationBottom({ modalDecorationElement, modalElement });
}

/**
 * Gets wrapper of the nearest resizable element or the
 * wrapper of the nearest breakout element looking up from the given position
 * this wrapper is to be used as a reference point for ai modal positioning
 */
export function getResizableOrBreakoutWrapper(
	editorView: EditorView,
	position: number,
): HTMLElement | undefined {
	const resolvedPos = editorView.state.doc.resolve(position);
	const { nodes, marks } = editorView.state.schema as Schema;
	const domAtPos = editorView.domAtPos.bind(editorView);

	/**
	 * At the moment, the table node is the only resizable node
	 * which can trigger the AI modal.
	 */
	const resizableNode = findParentNodeClosestToPos(
		resolvedPos,
		(currentNode) => currentNode.type === nodes.table,
	);

	if (resizableNode) {
		const resizableDomNode = findDomRefAtPos(resizableNode.start, domAtPos);

		if (resizableDomNode instanceof HTMLElement) {
			/**
			 * At the moment this only resizable tables need to be handled
			 * hence why we use the table resizer class name specifically.
			 */
			const resizableWrapper = resizableDomNode.closest(
				`.${TableSharedCssClassName.TABLE_RESIZER_CONTAINER}`,
			);
			if (resizableWrapper instanceof HTMLElement) {
				return resizableWrapper;
			}
		}
	}
	const nodeWithBreakout = findParentNodeClosestToPos(resolvedPos, (currentNode) => {
		return (
			(currentNode.type === nodes.table && currentNode.attrs.layout !== 'default') ||
			currentNode.marks.some((mark) => mark.type === marks.breakout)
		);
	});
	if (!nodeWithBreakout) {
		return;
	}
	const domNodeWithBreakout = findDomRefAtPos(nodeWithBreakout.start, domAtPos);
	if (!(domNodeWithBreakout instanceof HTMLElement)) {
		return;
	}
	if (!domNodeWithBreakout) {
		return;
	}
	if ([nodes.layoutSection, nodes.expand].includes(nodeWithBreakout.node.type)) {
		return domNodeWithBreakout.parentElement || undefined;
	}

	if (nodeWithBreakout.node.type === nodes.table) {
		const closest = domNodeWithBreakout.closest(`.${TableSharedCssClassName.TABLE_CONTAINER}`);
		return closest instanceof HTMLElement ? closest : undefined;
	}
}
