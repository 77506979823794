import PanelNoteIcon from '@atlaskit/icon/core/discovery';
import PanelErrorIcon from '@atlaskit/icon/core/error';
import PanelInfoIcon from '@atlaskit/icon/core/information';
import FormatParagraphIcon from '@atlaskit/icon/core/magic-wand';
import TableIcon from '@atlaskit/icon/core/spreadsheet';
import PanelSuccessIcon from '@atlaskit/icon/core/success';
import PanelWarnIcon from '@atlaskit/icon/core/warning';
import { fg } from '@atlaskit/platform-feature-flags';

import SuggestedEditIcon from '../../context-panel/setting/assets/SuggestedEditIcon';
import SuggestedPanelIcon from '../../context-panel/setting/assets/SuggestedPanelIcon';
import TextBreakIcon from '../../context-panel/setting/assets/TextBreakIcon';

import messages from './messages';
import type { SuggestionItemType } from './suggestion-setting-map';

// TODO: Before enabling purging of chunks please ensure that the purged api handler has been implemented
// Maximum number of characters in a single part
export const PART_MAX_SIZE = Number.POSITIVE_INFINITY;
// Maximum number of characters in all parts in a queue
export const TOTAL_PARTS_MAX_SIZE = Number.POSITIVE_INFINITY;
// Maximum number of parts in a queue
export const MAX_PARTS = Number.POSITIVE_INFINITY;
// Maximium number of additional queues to request
export const QUEUE_MAX_ADDITIONAL_ATTEMPTS = 2;
// Limit until we stop tracking count of doc changes
export const DOC_CHANGE_COUNT_LIMIT = 8;
// Minimum size of document's text content to be eligible for proactive AI suggestions
export const DOC_MINIMUM_SIZE_THRESHOLD = 8;

export const START_PUNC_CHARS = [
	...Array.from(
		String.fromCharCode(
			39, // single quote
			145, // left single quote
			147, // left double quote
		),
	),
	'(',
	'[',
];

export const END_PUNC_CHARS = [
	Array.from(
		String.fromCharCode(
			34, // double quotes
			39, // single quote
			146, // right single quote
			148, // right double quote
		),
	),
	'.',
	',',
	'!',
	'?',
	')',
	']',
];

export const MIDDLE_PUNC_CHARS = Array.from(
	String.fromCharCode(
		// HYPHENS
		45, // hyphen-minus
		95, // underscore
		150, // en dash
		151, // em dash
		// SINGLE QUOTES
		39, // single quote
		146, // right single quote (apostrophe)
	),
);

export const ANALYTICS_ACTION_SUBJECT_ID = 'proactiveSuggestion';
export const ANALYTICS_FEATURE_NAME = 'Editor AI';
export const ANALYTICS_EXPERIENCE_NAME = 'Proactive AI';

// The number of retry attempts the rate limiter
export const RATE_LIMITER_ATTEMPTS = 3;
// The base delay in milliseconds the rate limiter will start from
export const RATE_LIMITER_DELAY = 1000;
//
export const RATE_LIMITER_JITTER = 2000;

/**
 * Setting keys for the suggestion settings
 */
export enum SETTING_KEYS {
	ALL_SUGGESTIONS = 'displayAllSuggestions',
	ALL_PANELS = 'displayAllPanelSuggestions',
	CONVERT_TO_ERROR_PANEL = 'displayErrorPanel',
	CONVERT_TO_INFO_PANEL = 'displayInfoPanel',
	CONVERT_TO_NOTE_PANEL = 'displayNotePanel',
	CONVERT_TO_SUCCESS_PANEL = 'displaySuccessPanel',
	CONVERT_TO_WARN_PANEL = 'displayWarningPanel',
	CONVERT_TO_TABLE = 'displayTable',
	SPLIT_PARAGRAPH = 'displaySplitParagraph',
	FORMAT_PARAGRAPH = 'displayFormatParagraph',
}

/**
 * Array of suggestion setting items
 */
const SUGGESTION_SETTING_ARRAY: SuggestionItemType[] = [
	{
		Icon: SuggestedEditIcon,
		settingKey: SETTING_KEYS.ALL_SUGGESTIONS,
		title: messages.toggleAllSuggestionsTitle,
		description: messages.toggleAllSuggestionsDescription,
	},
	{
		Icon: SuggestedPanelIcon,
		settingKey: SETTING_KEYS.ALL_PANELS,
		title: messages.toggleAllPanelSuggestionsTitle,
		description: messages.toggleAllPanelSuggestionsDescription,
		parentSetting: SETTING_KEYS.ALL_SUGGESTIONS,
	},
	{
		Icon: PanelErrorIcon,
		settingKey: SETTING_KEYS.CONVERT_TO_ERROR_PANEL,
		title: messages.toggleErrorPanelTitle,
		description: messages.toggleErrorPanelDescription,
		parentSetting: SETTING_KEYS.ALL_PANELS,
		transformationType: 'CONVERT_TO_ERROR_PANEL',
	},
	{
		Icon: PanelInfoIcon,
		settingKey: SETTING_KEYS.CONVERT_TO_INFO_PANEL,
		title: messages.toggleInfoPanelTitle,
		description: messages.toggleInfoPanelDescription,
		parentSetting: SETTING_KEYS.ALL_PANELS,
		transformationType: 'CONVERT_TO_INFO_PANEL',
	},
	{
		Icon: PanelNoteIcon,
		settingKey: SETTING_KEYS.CONVERT_TO_NOTE_PANEL,
		title: messages.toggleNotePanelTitle,
		description: messages.toggleNotePanelDescription,
		parentSetting: SETTING_KEYS.ALL_PANELS,
		transformationType: 'CONVERT_TO_NOTE_PANEL',
	},
	{
		Icon: PanelSuccessIcon,
		settingKey: SETTING_KEYS.CONVERT_TO_SUCCESS_PANEL,
		title: messages.toggleSuccessPanelTitle,
		description: messages.toggleSuccessPanelDescription,
		parentSetting: SETTING_KEYS.ALL_PANELS,
		transformationType: 'CONVERT_TO_SUCCESS_PANEL',
	},
	{
		Icon: PanelWarnIcon,
		settingKey: SETTING_KEYS.CONVERT_TO_WARN_PANEL,
		title: messages.toggleWarningPanelTitle,
		description: messages.toggleWarningPanelDescription,
		parentSetting: SETTING_KEYS.ALL_PANELS,
		transformationType: 'CONVERT_TO_WARN_PANEL',
	},
	{
		Icon: TextBreakIcon,
		settingKey: SETTING_KEYS.SPLIT_PARAGRAPH,
		title: messages.toggleSplitParagraphTitle,
		description: messages.toggleSplitParagraphDescription,
		parentSetting: SETTING_KEYS.ALL_SUGGESTIONS,
		transformationType: 'SPLIT_PARAGRAPH',
	},
	{
		Icon: TableIcon,
		settingKey: SETTING_KEYS.CONVERT_TO_TABLE,
		title: messages.toggleTableTitle,
		description: messages.toggleTableDescription,
		parentSetting: SETTING_KEYS.ALL_SUGGESTIONS,
		transformationType: 'CONVERT_TO_TABLE',
	},
];

/**
 * Add any new WIP/FF suggestion setting here
 * as you're not allowed to evaluate fg at a module level
 *
 *  aix_proactive_format_paragraph_feature_enabled -> add FORMAT_PARAGRAPH and get rid of SPLIT_PARAGRAPH
 * 	https://hello.atlassian.net/wiki/spaces/AM3/pages/edit-v2/4521721900?focusedCommentId=4690745524
 */
export const getSuggestionSettingArray = (): SuggestionItemType[] => {
	let newSuggestionArray = [...SUGGESTION_SETTING_ARRAY];
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	if (fg('aix_proactive_format_paragraph_feature_enabled')) {
		newSuggestionArray.push({
			Icon: FormatParagraphIcon,
			settingKey: SETTING_KEYS.FORMAT_PARAGRAPH,
			title: messages.toggleFormatParagraphTitle,
			description: messages.toggleFormatParagraphDescription,
			parentSetting: SETTING_KEYS.ALL_SUGGESTIONS,
			transformationType: 'FORMAT_PARAGRAPH',
		});

		newSuggestionArray = newSuggestionArray.filter(
			(sugg) => sugg.transformationType !== 'SPLIT_PARAGRAPH',
		);
	}

	return newSuggestionArray;
};
