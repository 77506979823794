import React from 'react';

import { useFlags } from '@atlaskit/flag';
import EditorWarningIcon from '@atlaskit/icon/core/migration/warning--editor-warning';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';

export type FlagType = 'success' | 'info' | 'warning' | 'error';

const getIcon = (type: FlagType) => {
	switch (type) {
		case 'success':
			return <CheckIcon spacing="spacious" color={token('color.icon.success')} label="success" />;
		case 'info':
			return (
				<EditorWarningIcon
					spacing="spacious"
					color={token('color.icon.information')}
					label="info"
				/>
			);
		case 'warning':
			return (
				<EditorWarningIcon spacing="spacious" color={token('color.icon.warning')} label="warning" />
			);
		case 'error':
			return <ErrorIcon spacing="spacious" color={token('color.icon.danger')} label="error" />;
	}
};

export const useStyledFlags = () => {
	const { showFlag } = useFlags();

	const generateHandler = (flagType: FlagType) => (id: string, title: string) => {
		showFlag({
			id,
			title,
			icon: getIcon(flagType),
		});
	};

	return {
		showSuccessFlag: generateHandler('success'),
		showInfoFlag: generateHandler('info'),
		showWarningFlag: generateHandler('warning'),
		showErrorFlag: generateHandler('error'),
	};
};
