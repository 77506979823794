import React from 'react';

import { useMachine } from '@xstate/react';

import { createAIExperienceMachine } from './get-ai-experience-service';
import type {
	AIExperienceMachineState,
	ExperienceMachineOptions,
} from './get-ai-experience-service';

export function useAIExperienceService(options: ExperienceMachineOptions) {
	const machineRef = React.useRef<ReturnType<typeof createAIExperienceMachine> | null>(null);

	if (machineRef.current === null) {
		machineRef.current = createAIExperienceMachine(options);
	}

	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const [state, send] = useMachine(machineRef.current!);

	return {
		// In a later version of xstate -- the type gen means the machine
		// has good types, so these assertions are not required
		state: state.value as AIExperienceMachineState,
		context: state.context,
		send,
	};
}
