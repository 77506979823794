import random from 'lodash/random';

type InitProps = {
	/**
	 * This is called on the completion of an enqueued retry timeout attempt. This can be used to trigger whatever is needed
	 * to perform another server request.
	 */
	onRetry?: (retries: number) => void;
	/**
	 * This is called immediately before a retry timeout is registered. Returning true from this handler will
	 * indicate that that the handler is going to manage the retry, thus no timeout will be registered.
	 */
	onRetryEnqueue?: (retries: number) => boolean | undefined | void;
	/**
	 * This is called when a retry attempt occurs and the rate limiter has already reach the maximum amount of retries allowed.
	 * This handler can return true to bypass the max retry limit and allow the enqueue retry attempt to continue as normal.
	 * Otherwise the attempt will be blocked until the rate limiter is reset.
	 */
	onRetriesExhausted?: (retries: number) => boolean | undefined | void;
};

export class RateLimiter {
	private active: boolean = false;
	private _retries: number = 0;

	private onRetry?: InitProps['onRetry'];
	private onRetryEnqueue?: InitProps['onRetryEnqueue'];
	private onRetriesExhausted?: InitProps['onRetriesExhausted'];
	private timerId?: ReturnType<typeof setTimeout>;

	/**
	 * @param delay The base delay value in milliseconds. This value will be exponentially increased on each subsequent retry attempt.
	 * @param jitter The maximum jitter value in milliseconds. This will apply a random jitter amount between 0 and this value to each rerty attempt.
	 */
	constructor(
		readonly delay: number,
		readonly jitter: number = 2000,
		readonly maxRetries: number = 3,
	) {}

	get retries() {
		return this._retries;
	}

	isActive(): boolean {
		return this.active;
	}

	isExhausted(): boolean {
		return this._retries >= this.maxRetries;
	}

	/**
	 *
	 * @param param0
	 * @returns A destroy method which can be called to cleanup and reset and pending timers.
	 */
	init({ onRetry, onRetryEnqueue, onRetriesExhausted }: InitProps): () => void {
		this.onRetry = onRetry;
		this.onRetryEnqueue = onRetryEnqueue;
		this.onRetriesExhausted = onRetriesExhausted;

		return () => {
			this.reset();
			this.onRetry = undefined;
			this.onRetryEnqueue = undefined;
			this.onRetriesExhausted = undefined;
		};
	}

	enqueueRetry(delay?: number) {
		if (this.isExhausted() && !this.onRetriesExhausted?.(this._retries)) {
			return false;
		}

		if (!this.active) {
			this.active = true;
			// The next delay is either an explicit value define by the server, or it's an exponent of the limiter retries
			const expDelay = delay ?? this.delay * 2 ** this._retries;
			// We ensure there's a slight bit of jitter on the retries, as this will slow the flow from the traffic jam
			// once the servers start to recover. This value will also increase with the retry count, just not as much.
			const jitter = random(0, this.jitter * 1.3 ** this._retries);
			// Callback handler for any actions which need to be handled before we enqueue a retry.
			if (!this.onRetryEnqueue?.(this._retries)) {
				this.timerId = setTimeout(this.onComplete.bind(this), expDelay + jitter);
				return true;
			}
		}
		return false;
	}

	private onComplete() {
		this.active = false;
		this.timerId = undefined;
		this._retries++;
		this.onRetry?.(this._retries);
	}

	reset() {
		clearTimeout(this.timerId);
		this.timerId = undefined;
		this._retries = 0;
		this.active = false;
	}
}
