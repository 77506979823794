import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.brainstorm.title',
		defaultMessage: 'Brainstorm',
		description:
			'Title for "Brainstorm" Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
	description: {
		id: 'fabric.editor.ai.config.item.brainstorm.description',
		defaultMessage: 'Generates a list of ideas for a topic you provide',
		description:
			'Describes the "Brainstorm" Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
	promptLabel: {
		id: 'fabric.editor.ai.config.item.brainstorm.promptLabel',
		defaultMessage: 'Brainstorm',
		description: 'Label text in the prompt input.',
	},
	promptHint: {
		id: 'fabric.editor.ai.config.item.brainstorm.promptHint',
		defaultMessage: 'Tell me the topic...',
		description: 'Placeholder text in the prompt input guiding user interaction.',
	},
});
