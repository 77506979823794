/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import debounce from 'lodash/debounce';

import type { EditorAppearance } from '@atlaskit/editor-common/types';
import { findOverflowScrollParent } from '@atlaskit/editor-common/ui';
import type { Decoration, EditorView } from '@atlaskit/editor-prosemirror/view';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';
import { scrollTopMargin } from '@atlassian/generative-ai-modal/styles/FloatingContainer';

import { type InvokedFrom } from '../../analytics/analytics-flow/analyticsFlowTypes';
import { type EndExperience } from '../../types';

import { modalStyles } from './styles';
import useUpdateEditorHeight from './useUpdateEditorHeight';
import { getModalLeft, getModalTop, getResizableOrBreakoutWrapper } from './utils';

interface ModalProps {
	children: ReactNode;
	decoration: Decoration;
	editorView: EditorView;
	editorRelativeWrapper?: Element | null;
	modalDecorationElement: HTMLElement;
	appearance: EditorAppearance;
	endExperience: EndExperience;
	lastTriggeredFrom?: InvokedFrom;
	decorationSet: DecorationSet;
	autoScroll?: boolean;
}

export const ModalRegion = ({
	children,
	decoration,
	editorView,
	editorRelativeWrapper,
	modalDecorationElement,
	appearance,
	endExperience,
	lastTriggeredFrom,
	decorationSet,
	autoScroll,
}: ModalProps) => {
	// Ignored via go/ees005
	// eslint-disable-next-line @atlaskit/editor/no-as-casting
	const editorViewElement = editorView.dom as HTMLElement;

	const modalRef = React.useRef<HTMLDivElement>(null);

	const [modalLeft, setModalLeft] = React.useState(0);

	const [modalTop, setModalTop] = React.useState(0);
	const [modalWidth, setModalWidth] = React.useState(0);
	const [transition, setTransition] = React.useState('');

	// get nearest resizable wrapper or breakout container looking up from the ai decoration position
	const resizableOrBreakoutWrapper = useMemo(
		() => getResizableOrBreakoutWrapper(editorView, decoration.to),
		[editorView, decoration],
	);

	// EDF-161: https://product-fabric.atlassian.net/browse/EDF-161
	// If the editor has a max-height set, we need to set position relative for .ak-editor-content-area
	React.useEffect(() => {
		if (appearance === 'comment') {
			// editorRelativeWrapper is the closest but can be null
			// fallback to modalDecorationElement if editorRelativeWrapper is null
			let editorContentArea: HTMLElement | null = null;
			if (editorRelativeWrapper) {
				editorContentArea =
					editorRelativeWrapper.querySelector<HTMLElement>('.ak-editor-content-area');
			} else {
				editorContentArea = modalDecorationElement.closest<HTMLElement>('.ak-editor-content-area');
			}

			if (editorContentArea) {
				const computedStyle = getComputedStyle(editorContentArea);
				const editorHasMaxHeight = computedStyle.maxHeight !== 'none';
				const originalPosition = computedStyle.position;

				if (editorHasMaxHeight) {
					editorContentArea.style.position = 'relative';
				}

				return () => {
					if (editorHasMaxHeight && editorContentArea) {
						editorContentArea.style.position = originalPosition;
					}
				};
			}
		}
	}, [modalDecorationElement, appearance, editorRelativeWrapper]);

	// listen for editor resize and update modal size and position
	React.useEffect(() => {
		const setModalPosition = () => {
			if (!modalRef.current) {
				return;
			}

			// When layout resizes / breakout changes, resizableOrBreakoutWrapper remounts
			// We need to wait for resizableOrBreakoutWrapper to update before we can calculate modal positions
			// Return if resizableOrBreakoutWrapper doesn't exist in view as it hasn't been updated yet to avoid flickering
			if (resizableOrBreakoutWrapper && !editorViewElement.contains(resizableOrBreakoutWrapper)) {
				return;
			}
			// position relative to breakout element when its available, or otherwise relative to editor view
			const relativePositionElement = resizableOrBreakoutWrapper || editorViewElement;

			const top = getModalTop({
				modalElement: modalRef.current,
				editorRelativeWrapper,
				modalDecorationElement,
				lastTriggeredFrom,
			});
			const left = getModalLeft({ relativePositionElement, modalElement: modalRef.current });

			setModalLeft(left);
			setModalTop(top);
			setModalWidth(relativePositionElement.offsetWidth);
			//setting width to be animated here to make animation happen only after first positioning
			if (!transition) {
				setTransition('all 0.1s ease-in');
			}
		};
		setModalPosition();
		const setModalPositionDebounced = debounce(() => {
			setModalPosition();
		}, 20);
		const resizeObserver = new ResizeObserver(setModalPositionDebounced);

		resizeObserver.observe(editorViewElement);

		// editorViewOffsetParent can be undefined when editor is set to display: none
		// (when Confluence switches to Preview mode)
		const editorViewOffsetParent = editorViewElement.offsetParent;
		if (editorViewOffsetParent) {
			// This resize observer is intended to fix the position of the modal region
			// when the config panel (for extensions or templates) is opened/closed
			resizeObserver.observe(editorViewOffsetParent);
		}

		// listen to any changes in the resizableOrBreakoutWrapper if modal was triggered in
		// layout or table with breakout / resizable table
		if (resizableOrBreakoutWrapper) {
			resizeObserver.observe(resizableOrBreakoutWrapper);
		}

		return () => {
			resizeObserver.disconnect();
			//clearing any potentially running debounced reposition
			setModalPositionDebounced.cancel();
		};
		// no need to rerun due to transition change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		editorViewElement,
		modalDecorationElement,
		editorRelativeWrapper,
		resizableOrBreakoutWrapper,
	]);

	React.useEffect(() => {
		if (!autoScroll || !modalTop) {
			return;
		}

		const modalEl = modalRef.current;
		if (!modalEl) {
			return;
		}

		const scrollParentEl: HTMLElement | false = findOverflowScrollParent(modalEl);
		if (!scrollParentEl) {
			return;
		}

		// The following values are all absoltue values this way they can all be easily compared to one another.
		const viewportTop = scrollParentEl.scrollTop;
		const viewportBottom =
			scrollParentEl.scrollTop + (scrollParentEl.offsetHeight - scrollTopMargin);
		const modalTippyTop = Math.max(0, modalTop - modalDecorationElement.offsetHeight);
		const modalBottom = modalTop + modalEl.offsetHeight;

		// Check if modal is already fully visible within the viewport.
		const isModalWithinViewport = modalTippyTop >= viewportTop && modalBottom <= viewportBottom;
		const isModalAboveViewport = modalTippyTop < viewportTop;
		// We should be able to surmise that if the modal is not within the viewport or above it, then it's below it.

		/**
		 * Check if modal height exceeds scroll parent's height
		 * Scroll margin is used to prevent the modal from being scrolled to the top
		 * and should match scroll margin in other modals e.g. from
		 * packages/editor/editor-plugin-ai/src/ui/components/FloatingContainer/styles.ts
		 */
		const isModalFullHeight = scrollParentEl.offsetHeight - scrollTopMargin < modalEl.scrollHeight;

		if (isModalWithinViewport) {
			// If the model fits in the view then we should not scroll at all
			return;
		}

		if (isModalFullHeight || isModalAboveViewport) {
			// If the modal is bigger then the viewport, or above it, then we will scroll to the top of it
			scrollParentEl.scroll({
				top: Math.max(0, modalTippyTop - scrollTopMargin),
				// @ts-ignore Type '"instant"' is not assignable to type 'ScrollBehavior | undefined'.
				behavior: 'instant',
			});
			return;
		}

		// If the modal is not within the viewport, or above it, then it must off the bottom or below it. So we will
		// want to scroll the page down, just enough to bring the bottom of the modal into view. This should just be
		// a matter of gettting the delta between the btm of the modal and the btm of the viewport and adding that
		// onto the current viewport top.
		scrollParentEl.scroll({
			top: viewportTop + Math.max(modalBottom - viewportBottom, 0) + scrollTopMargin,
			// @ts-ignore Type '"instant"' is not assignable to type 'ScrollBehavior | undefined'.
			behavior: 'instant',
		});
	}, [autoScroll, modalTop, modalDecorationElement]);

	// sync modal height with editor content area height
	useUpdateEditorHeight({ modalElement: modalRef.current, editorViewElement });

	// Check for the modal decoration which is used for positioning
	// If there is none the modal should be removed from the DOM & return early
	// This is intended to make the code more resilient to bugs caused by the
	// editor AI plugin not being reinstantiated correctly when the editor is closed/opened
	// See https://product-fabric.atlassian.net/browse/EDITOR-52

	if (decorationSet === DecorationSet.empty) {
		endExperience();
		return null;
	}

	const style = {
		top: modalTop,
		left: modalLeft,
		'--content-width': `${modalWidth}px`,
		transition,
		display: modalWidth ? 'block' : 'none',
	};

	return (
		<div
			data-testid="ai-plugin-modal"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			css={modalStyles}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={style}
			ref={modalRef}
			// data-editor-popup is required so that clicks within the modal will be detected as clicks inside the editor,
			// Otherwise, content selection will be prevented in packages/editor/editor-core/src/ui/Addon/click-area-helper.ts
			data-editor-popup={true}
		>
			{children}
		</div>
	);
};
