import React from 'react';

const Enter = () => (
	<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.63477 11.2256C5.12012 11.2256 5.44141 10.8906 5.44141 10.4326C5.44141 10.1729 5.33203 9.98828 5.18164 9.83789L4.18359 8.86719L3.41113 8.24512L4.61426 8.2998H10.9102C12.4688 8.2998 13.1387 7.62988 13.1387 6.07129V2.89258C13.1387 1.33398 12.4688 0.657227 10.9102 0.657227H8.06641C7.58105 0.657227 7.21875 1.0332 7.21875 1.49121C7.21875 1.95605 7.58105 2.33203 8.06641 2.33203H10.835C11.3135 2.33203 11.5049 2.5166 11.5049 3.00195V5.96191C11.5049 6.44043 11.3135 6.63184 10.835 6.63184H4.61426L3.41113 6.68652L4.18359 6.06445L5.18164 5.10059C5.33203 4.94336 5.44141 4.76562 5.44141 4.50586C5.44141 4.04785 5.12012 3.70605 4.63477 3.70605C4.42285 3.70605 4.19727 3.80176 4.0332 3.96582L1.10742 6.85059C0.950195 7.00781 0.861328 7.24023 0.861328 7.46582C0.861328 7.69141 0.950195 7.93066 1.10742 8.08105L4.0332 10.9658C4.19727 11.1367 4.42285 11.2256 4.63477 11.2256Z"
			fill="currentColor"
		/>
	</svg>
);
export default Enter;
