/* eslint-disable @atlaskit/design-system/no-unsafe-design-token-usage */
import React from 'react';

import Icon, { type CustomGlyphProps } from '@atlaskit/icon';
import type { NewCoreIconProps } from '@atlaskit/icon';

// FIXME: When "@atlaskit/icon-lab/text-lengthen" icon is available via the icons package we should replace this
const CustomGlyph = (props: CustomGlyphProps) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		// Ignored via go/ees005
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		<path
			d="M1 1.75L15 1.75M1 4.91667L8 4.91667M1 11.0833L15 11.0833M1 14.25L8 14.25"
			stroke="currentColor"
			strokeWidth="1.5"
		/>
	</svg>
);

const TextBreakIcon = (props: NewCoreIconProps) => {
	const { color } = props;
	return (
		<Icon
			glyph={CustomGlyph}
			primaryColor={color}
			// Ignored via go/ees005
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

export default TextBreakIcon;
