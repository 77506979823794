import React from 'react';

import Loadable from 'react-loadable';

import type { BrowseRovoAgentsModalProps } from './index';

export const LoadableBrowseRovoAgentsModal = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_editor-ai-browse-rovo-agents-modal" */
			'./index'
		).then((mod) => mod.default) as Promise<
			React.ComponentType<React.PropsWithChildren<BrowseRovoAgentsModalProps>>
		>,
	loading: () => null,
});

export const PreloadModalComponent = () => {
	React.useEffect(() => {
		LoadableBrowseRovoAgentsModal.preload();
	}, []);

	return null;
};
