import { type AssistanceServiceProduct } from '@atlassian/conversation-assistant-service';

export const DEFAULT_AGENT = {
	id: 'ai_mate_agent',
	named_id: 'ai_mate_agent',
	name: 'AI Mate',
	description: 'AI Mate',
	creator_type: 'SYSTEM',
	available_plugins: {},
	is_default: true,
	visibility: 'PUBLIC',
} as const;

export const DEFAULT_AGENTS_NAMED_ID_LIST = new Set([
	'decision_director_agent',
	'user_manual_writer_agent',
	'product_requirements_expert_agent',
]);

export const DEFAULT_OOTB_AGENTS_NAMED_IDS: { [key in AssistanceServiceProduct]: Set<string> } = {
	['confluence']: new Set([
		'decision_director_agent',
		'product_requirements_expert_agent',
		'rovo_expert_agent',
	]),
	['jira']: new Set(['rovo_expert_agent', 'issue_organizer_agent', 'jira_theme_analyzer_agent']),
	['atlas']: new Set([
		'progress_tracker_agent',
		'product_requirements_expert_agent',
		'rovo_expert_agent',
	]),
};

const AGENT_AUP_ERROR_CODE = `convoai.agent.responsible-ai.failure`;

export const isCreateOrEditAgentAUPError = (error: any): boolean =>
	Boolean(error) &&
	typeof error === 'object' &&
	error.errorAttributes &&
	error.errorAttributes.errorCode === AGENT_AUP_ERROR_CODE;
