import React from 'react';

import SpreadsheetIcon from '@atlaskit/icon/core/spreadsheet';
import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<SpreadsheetIcon label="" color={token('color.icon', '#44546F')} />
		</RainbowBorder>
	);
};
