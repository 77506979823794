import { defineMessages } from 'react-intl-next';

export default defineMessages({
	footerText: {
		id: 'fabric.editor.ai.screens.UserInputCommamdPalette.footerText',
		defaultMessage: 'Atlassian Intelligence',
		description: 'Atlassian Intelligence footer text for user input command palette screen',
	},
	rovoProductLabel: {
		id: 'fabric.editor.ai.experience.rovoAgents.productLabel',
		defaultMessage: 'Rovo',
		description: 'Name of the Atlassian service and product that provides AI agents',
	},
	rovoProductLogoAltText: {
		id: 'fabric.editor.ai.experience.rovoAgents.productLogoAltText',
		defaultMessage: 'Rovo Logo',
		description: 'Rovo logo alt text',
	},
	rovoAgentsPalettePlaceholder: {
		id: 'fabric.editor.ai.experience.rovoAgents.agentPalette.placeholder',
		defaultMessage: 'Tell Agent to write anything or choose from the below',
		description: 'Placeholder text visible when input field in the Agent command palette is empty',
	},
	commandPaletteAriaName: {
		id: 'fabric.editor.ai.experience.commandPaletteAriaName',
		defaultMessage: 'Atlassian Intelligence',
		description: 'Accessible label for the user input dialog',
	},
});
