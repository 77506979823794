import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import { createEndAIExperienceCommand } from '../actions';
import { getPluginState } from '../decoration-plugin-factory';

import { hasGeneratedContentDecorations } from './hasGeneratedContentDecorations';

export const onClearGeneratedContentDecorator = (view: EditorView, event: Event) => {
	const pluginState = getPluginState(view.state);

	if (hasGeneratedContentDecorations(pluginState)) {
		const endAIExperienceCommand = createEndAIExperienceCommand();
		endAIExperienceCommand(view.state, view.dispatch);

		return true;
	}
	return false;
};
