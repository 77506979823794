import gql from 'graphql-tag';

export const AllIndividualSpacesQuery = gql`
	query AllIndividualSpaces($after: String!, $key: String) {
		allIndividualSpaces(first: 14, after: $after, key: $key) {
			nodes {
				id
				name
				key
				spaceAdminAccess
			}
			pageInfo {
				hasNextPage
				startCursor
				endCursor
			}
		}
	}
`;
