// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { N40A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { commonTextStyles } from '../../styles/text';

/**
 * @see packages/editor/editor-plugin-ai/src/ui/screens/Preview/styles.ts
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const previewContainer = css({
	borderTop: `1px solid ${token('color.border', N40A)}`,
	borderBottom: `1px solid ${token('color.border', N40A)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const layoutStyles = css({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'left',
	alignItems: 'center',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const logoStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '5px',
	display: 'flex',
	alignItems: 'center',
});

const titleLayoutStyles = css({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const titleStyles = [titleLayoutStyles, commonTextStyles];
