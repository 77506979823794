import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	summarizePageTitle: {
		id: 'fabric.editor.ai.ai-panels.summarizePageTitle',
		defaultMessage: `Page summary panel (Beta)`,
		description: 'Title for AI summary panel',
	},
	summarisePageDescription: {
		id: 'fabric.editor.ai.ai-panels.summarizePageDescription',
		defaultMessage: `Use Atlassian Intelligence to summarize this page and display in a panel`,
		description: 'Description for AI summary panel',
	},
});
